import _ from 'lodash';
import { StepSetup } from './steps_order';
import { mergeDigitalData, trackEvent } from "../helpers/analytics";

// HASH for translation between our steps to the result.
const STEP_TRANSLATION = {
  'splash': 'Step 0 - Splash',
  'shingles': 'Step 1 - Shingles',
  'ice-water': 'Step 2 - Ice & Water',
  'underlayment': 'Step 3 - Underlayment',
  'starter-shingles': 'Step 4 - Starter Shingles',
  'hip-ridge': 'Step 5 - Hip & Ridge',
  'ventilation': 'Step 6 - Ventilation',
  'summary': 'Step 7 - Summary'

}

// Data translation steps
const DATA_TRANSLATION = {
  'shingles': ({shingle, color}) => (
    {
      page: {
        selectionDetails: {
          shingleLine: shingle.name,
          shingleColor: color.name
        }
      }
    }
  ),
  'ice-water': (value) => ({
    page: {
      selectionDetails: {
        iceAndWaterBarrier: value.name
      }
    }
  }),
  'underlayment': (value) => ({
    page: {
      selectionDetails: {
        underlayment: value.name
      }
    }
  }),
  'starter-shingles': (value) => ({
    page: {
      selectionDetails: {
        starterShingles: value.name
      }
    }
  }),
  'hip-ridge': (value) => ({
    page: {
      selectionDetails: {
        hipAndRidgeShingles: value.name
      }
    }
  }),
  'ventilation': (value) => ({
    page: {
      selectionDetails: {
        ventilation: value
      }
    }
  }),
}

// That's the step order, we use it to figure out what's the next step

export const logFailure = (reason) => {
  trackEvent('byr-error', {errorType: '500 Error', errorMessage: reason});
}
export const logChangeStep = (step, slug = null) => {
  const STEP_ORDER = StepSetup();
  let nextStep = STEP_TRANSLATION[STEP_ORDER[STEP_ORDER.indexOf(step) + 1]];
  let pageNameBase = ['oc', 'roofing', 'build-your-roof'];
  let custom = (slug ? ['custom', slug] : []);
  const pageName = [...pageNameBase, ...custom, step].join(' | ')

  let updateObj = {
    page: {
      pageName: pageName,
      toolCurrentStep: STEP_TRANSLATION[step],
      toolNextStep: nextStep || ''
    },
    global: {
      pageName: pageName,
    }
  }

  mergeDigitalData(updateObj);

  trackEvent('tool-load-step', {toolName: 'Build Your Roof®', action:'Step Loaded', stepName:'INSERT STEP # - NAME' });
}

export const logViewStep = () => {
  let { project: { projectID = null } = {} }  = window.digitalData;
  let pageName = ['oc', 'roofing', 'build-your-roof', 'view', projectID].join(' | ')

  let updateObj = {
    page: {
      pageName
    },
    global: {
      pageName
    }
  }

  mergeDigitalData(updateObj);
  trackEvent('tool-load-step', {toolName: 'Build Your Roof®', action:'Step Loaded', stepName:'INSERT STEP # - NAME' });
}

// Function that provides selected data to Analytics
export function logChangeData(what, value) {
  // We need to translate incoming data into format that Analytics expects
  // and then we merge it into digitalData
  mergeDigitalData(DATA_TRANSLATION[what](value));
}


// Function that keeps the "project" id (i.e. saved roof data id)
export function logProjectId(id, includeDate = true) {
  let updateObj = {
    project: {
      projectID: id,
    }
  };

  let dateObject = {
    project: {
      creationDate: Date.now()
    }
  };

  if(!includeDate)
    dateObject = {};

  mergeDigitalData(updateObj, dateObject);

  // If you need to debug, this is summary of selection
  // console.log(window.digitalData)
}

export function fireByrConfigurationEvent(data, zip, byrConfig) {
  const configuration = prepareConfiguration(data, zip, byrConfig);
  // On save only
  trackEvent('byr-configuration', configuration);
}

function prepareConfiguration(data, zip, byrConfig) {
  let configuration = _.mapValues(data, v => ({
    included: _.chain(v.products).filter(p => !_.get(v, 'hidden', []).includes(p.uid)).map(p => p.name).join(':').value(),
    recommended: _.chain(v.products).find(['uid', v.recommended]).get('name').value()
  }));
  configuration.location = zip;
  if (byrConfig.slug) {
    configuration.edit = true;
    configuration.configurationID = byrConfig.slug;
  } else {
    configuration.edit = false;
    configuration.configurationID = '';
  }
  return configuration;
}

export function logByrConfiguration(data, zip, byrConfig = {}) {
  let configuration = prepareConfiguration(data, zip, byrConfig);

  let updateObj = {
    page: {
      configuration
    }
  };
  mergeDigitalData(updateObj);
}

import { Component, useState } from "react";
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";

const HeaderRow = styled.tr`
    border-top: 3px solid #BCBCBC;
`;


const Offset = styled.tr`
    background-color: #f8f8f8;
    border-top: 1px solid #bcbcbc;
    border-bottom: 1px solid #bcbcbc;
    padding: 13px 8px 7px 8px;
    text-align: center;
    width: 20%;
    min-width: 115px;
`;

const InfoTable = styled.table`
  border-collapse: collapse;
  font-size: 14px;
  overflow-x: auto;
  display: block;
 td {
    padding: 9px 8px 7px 8px;
    text-align: center;
    width: 20%;
    min-width: 115px;
    border-top: 2px solid #BCBCBC;
    border-bottom: 2px solid #BCBCBC;
    border-right: 1px solid #BCBCBC;
  }
  tr > :last-child{
    border-right: none;
  }

`;

class PersonalInformationTable extends Component {

  render() {

    return (

      <InfoTable>
        <tbody>
          <HeaderRow>
            <th>CATEGORY OF PERSONAL INFORMATION WE COLLECT</th>
            <th>CATEGORIES OF THIRD PARTIES WITH WHICH WE SHARE THAT INFORMATION</th>
          </HeaderRow>
        <tr>
          <td>Identifiers (such as name, address, email address)</td>
          <td>- Third parties (such as our service providers) <br></br>
            - Data aggregators (such as analytics services)</td>
        </tr>
        <Offset>
          <td>Commercial Information (such as transaction data, or purchasing history)</td>
          <td>- Third parties (such as our service providers) <br></br>
            - Data aggregators (such as analytics services)</td>
        </Offset>
        <tr>
          <td>Financial Data (such as billing information)</td>
          <td>- Third parties (such as our service providers)<br></br>
            - Data aggregators (such as analytics services)</td>
        </tr>
        <Offset>
          <td>Internet or Other Network or Device Activity (such as browsing history, interaction with a website)</td>
          <td>- Third parties (such as our service providers)<br></br>
            - Data aggregators (such as analytics services)</td>
        </Offset>
          <tr>
            <td>Location Information (for example, inferred from your IP address)</td>
            <td>- Third parties (such as our service providers)<br></br>
              - Data aggregators (such as analytics services)</td>
          </tr>
          <Offset>
            <td>Location Information (for example, inferred from your IP address)</td>
            <td>- Third parties (such as our service providers)<br></br>
              - Data aggregators (such as analytics services)</td>
          </Offset>
          <tr>
            <td>Location Information (for example, inferred from your IP address)</td>
            <td>- Third parties (such as our service providers)<br></br>
              - Data aggregators (such as analytics services)</td>
          </tr>
        </tbody>
      </InfoTable>
    );
  };
};

export default PersonalInformationTable;

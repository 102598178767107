import { parseAnalytics } from '../../OC/utilities/analytics';
export default {
  Parse: (subject) => {
    const site = /www\.(\w+)\.com/.exec(PB_SITE)[1];
    const isInternal = new RegExp(`^(https?:\/\/|)((w\.)|(www\.)|(w-devel\.)|(w-stage\.)|(w-prod\.)|)(${site})\.com`);

    let url = subject || '';
    let valid = true;
    let relative = true;

    const analytics = parseAnalytics(PB_LINK_META[url]?.analytics || {});

    // Check if it's an anchor link
    if (url.startsWith('#')) {
      return { subject, url, valid, relative, analytics }; // No transformation needed for anchor links
    }

    if (!url.startsWith('mailto:') && !url.startsWith('tel:')) {
      if (!url.match(/^[a-zA-Z]+:\/\//) && url.split('/')[0].includes('.')) url = `http://${ url }`;
      if (!url.match(/^[a-zA-Z]+:\/\//) && !url.startsWith('/')) url = `/${ url }`;
      relative = url.includes(window.location.hostname) || url.match(isInternal) || !url.match(/^[a-zA-Z]+:\/\//);
      if (url.includes('dms/')) relative = false;
    }
    if (location.host === PB_SITE) {
      url = url.replace(isInternal, '') || `https://${PB_SITE}/`;
    }

    try { new URL(`${ relative && 'https://www.owenscorning.com' }${ url }`); } catch (_) { valid = false; }
    return { subject, url, valid, relative, analytics };
  }
};

import _ from 'lodash';

export const themes = {
  oc: {
    colors: {
      brand: '#D40F7D',
      greyShade1: '#6D6D6D',
    },
    fonts: {
      heading: 'Oswald, sans-serif',
      displaySmall: 'OCRoboto, sans-serif',
      body: 'OCRoboto, sans-serif',
    },
    text: {
      headingTextTransform: 'uppercase',
    }
  },
  paroc: {
    colors: {
      brand: '#CE1126',
    },
    fonts: {
      heading: 'Oswald, sans-serif',
      displaySmall: 'OCRoboto, sans-serif',
      body: 'OCRoboto, sans-serif',
    },
    text: {
      headingTextTransform: 'uppercase',
    }
  },
  vidawool: {
    colors: {
      brand: '#005640',
    },
    fonts: {
      heading: 'Roboto Slab, sans-serif',
      displaySmall: 'OCRoboto, sans-serif',
      body: 'OCRoboto, sans-serif',
    },
    text: {
      headingTextTransform: 'none',
    }
  },
  naturalPolymers: {
    colors: {
      brand: '#33642A',
    },
    fonts: {
      heading: 'Oswald, sans-serif',
      displaySmall: 'OCRoboto, sans-serif',
      body: 'OCRoboto, sans-serif',
    },
    text: {
      headingTextTransform: 'uppercase',
    }
  },
};

const keys = _.reduce(themes, (result, theme) => _.union(result, Object.keys(theme)), []);
export default _.reduce(keys, (result, key) => Object.defineProperty(result, key, {
  get: () => {
    let name = 'oc';
    if ((typeof(PB_PAGE) !== 'undefined' && /vidawool/.exec( PB_PAGE || '' )) || (typeof(PB_SITE) !== 'undefined' && /vidawool/.exec( PB_SITE || '' ))) name = 'vidawool';
    if ((typeof(PB_PAGE) !== 'undefined' && /paroc/.exec( PB_PAGE || '' )) || (typeof(PB_SITE) !== 'undefined' && /paroc/.exec( PB_SITE || '' ))) name = 'paroc';
    if (typeof(PB_PAGE) !== 'undefined' && /spray-foam/.exec( PB_PAGE || '' )) name = 'naturalPolymers';
    return themes[name][key];
  }
}), {});

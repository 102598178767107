export const proCatData = {
	R13: {	
		coverage: 198.0,
		thickness: 4.75,
		numberOfBags: 5.1
	},
	R14: {
		coverage: 177.4,
		thickness: 5.25,
		numberOfBags:	5.6
	},
	R15: {
		coverage: 168.5,
		thickness: 5.50,
		numberOfBags:	5.9
	},
	R16: {
		coverage: 160.5,
		thickness: 5.75,
		numberOfBags:	6.2
	},
	R17: {
		coverage: 146.2,
		thickness: 6.25,
		numberOfBags:	6.8
	},
	R18: {
		coverage: 139.9,
		thickness: 6.50,
		numberOfBags:	7.1
	},
	R19: {
		coverage: 128.7,
		thickness: 7.00,
		numberOfBags: 7.8
	},
	R20: {
		coverage: 123.7,
		thickness: 7.25,
		numberOfBags: 8.1
	},
	R21: {
		coverage: 119.0,
		thickness: 7.50,
		numberOfBags: 8.4
	},
	R22: {
		coverage: 110.6,
		thickness: 8.00,
		numberOfBags: 9.0
	},
	R23: {
		coverage: 106.7,
		thickness: 8.25,
		numberOfBags: 9.4
	},
	R24: {
		coverage: 103.1,
		thickness: 8.50,
		numberOfBags: 9.7
	},
	R25: {
		coverage: 96.5,
		thickness: 9.00,
		numberOfBags: 10.4
	},
	R26: {
		coverage: 93.5,
		thickness: 9.25,
		numberOfBags: 10.7
	},
	R27: {
		coverage: 90.6,
		thickness: 9.50,
		numberOfBags: 11.0
	},
	R28: {
		coverage: 85.4,
		thickness: 10.00,
		numberOfBags: 11.7
	},
	R29: {
		coverage: 82.9,
		thickness: 10.25,
		numberOfBags: 12.1
	},
	R30: {
		coverage: 80.6,
		thickness: 10.50,
		numberOfBags: 12.4
	},
	R31: {
		coverage: 76.2,
		thickness: 11.00,
		numberOfBags: 13.1
	},
	R32: {
		coverage: 74.2,
		thickness: 11.25,
		numberOfBags: 13.5
	},
	R33: {
		coverage: 72.3,
		thickness: 11.50,
		numberOfBags: 13.8
	},
	R34: {
		coverage: 70.5,
		thickness: 11.75,
		numberOfBags: 14.2
	},
	R35: {
		coverage: 67.0,
		thickness: 12.25,
		numberOfBags: 14.9
	},
	R36: {
		coverage: 65.4,
		thickness: 12.50,
		numberOfBags: 15.3
	},
	R37: {
		coverage: 63.8,
		thickness: 12.75,
		numberOfBags: 15.7
	},
	R38: {
		coverage: 60.9,
		thickness: 13.25,
		numberOfBags: 16.4
	},
	R39: {
		coverage: 59.5,
		thickness: 13.50,
		numberOfBags: 16.8
	},
	R40: {
		coverage: 58.2,
		thickness: 13.75,
		numberOfBags: 17.2
	},
	R41: {
		coverage: 57.0,
		thickness: 14.00,
		numberOfBags: 17.6
	},
	R42: {
		coverage: 54.5,
		thickness: 14.50,
		numberOfBags: 18.3
	},
	R43: {
		coverage: 53.4,
		thickness: 14.75,
		numberOfBags: 18.7
	},
	R44: {
		coverage: 52.3,
		thickness: 15.00,
		numberOfBags: 19.1
	},
	R45: {
		coverage: 51.2,
		thickness: 15.25,
		numberOfBags: 19.5
	},
	R46: {
		coverage: 49.2,
		thickness: 15.75,
		numberOfBags: 20.3
	},
	R47: {
		coverage: 48.2,
		thickness: 16.00,
		numberOfBags: 20.7
	},
	R48: {
		coverage: 47.3,
		thickness: 16.25,
		numberOfBags: 21.1
	},
	R49: {
		coverage: 46.4,
		thickness: 16.50,
		numberOfBags: 21.5
	},
	R50: {
		coverage: 45.5,
		thickness: 16.75,
		numberOfBags:	22.0
	},
	R51: {
		coverage: 43.9,
		thickness: 17.25,
		numberOfBags:	22.8
	},
	R52: {
		coverage: 43.1,
		thickness: 17.50,
		numberOfBags:	23.2
	},
	R53: {
		coverage: 42.3,
		thickness: 17.75,
		numberOfBags:	23.6
	},
	R54: {
		coverage: 41.6,
		thickness: 18.00,
		numberOfBags:	24.1
	},
	R55: {
		coverage: 40.8,
		thickness: 18.25,
		numberOfBags:	24.5
	},
	R56: {
		coverage: 39.4,
		thickness: 18.75,
		numberOfBags:	25.4
	},
	R57: {
		coverage: 38.8,
		thickness: 19.00,
		numberOfBags:	25.8
	},
	R58: {
		coverage: 38.1,
		thickness: 19.25,
		numberOfBags:	26.2
	},
	R59: {
		coverage: 37.5,
		thickness: 19.50,
		numberOfBags:	26.7
	},
	R60: {
		coverage: 36.9,
		thickness: 19.75,
		numberOfBags: 27.1
	}
}
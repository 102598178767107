import Image from './oc-image.jsx';
import Picture from './oc-picture.jsx';
import { jsx, css } from '@emotion/react';
import Link from './oc-link';

const blockLinkContainerStyles = ({lgHeader, mobileTwoCol, fullWidth, noMargin}) => css`
	max-width: 400px;
	margin: 0 auto 70px;

  @media (min-width: 768px) {
		width: 100%;
		max-width: none;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
  }

	${ mobileTwoCol && css`
		@media(max-width: 767px) {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0;
    }
	`}

  ${ lgHeader && css`
    .text {
      padding: 12px 25px 12px 0 !important;
      display: flex;
      align-items: center;
      height: 75px;
    }
  `}

  ${ lgHeader && css`
    .text {
      padding: 12px 25px 12px 0 !important;
      display: flex;
      align-items: center;
      height: 75px;
    }
  `}

  ${ fullWidth && css`
    width: 100% !important;
    max-width: none !important;
  `}

  ${ noMargin && css`
    margin: 0 !important;

    @media(max-width: 767px) {
      & a:last-child {
        margin-bottom: 0 !important;
      }
    }
  `}
`;

const blockLinkStyles = ({mobileTwoCol, fullWidth}) => css`
	flex-basis: 0%;
	flex-shrink: 1;
	color: black;
	border-top: 2px solid black;
	font-size: 18px;
	font-weight: 700;
	text-decoration: none;

	margin-bottom: 35px;


	  display: flex;
    flex-wrap: wrap;
    flex-direction: column;



	${ mobileTwoCol && css`
		@media(max-width: 767px) {
			flex-basis: 48%;
	    margin-right: 4%;

	    &:nth-child(even) {
	      margin-right: 0;
	    }
		}
	`}

	@media (min-width: 768px) {
		flex-grow: 1;
		margin: 0 15px;
	}

	@media (min-width: 1200px), screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
		font-size: 21px;
		margin: 0 15px;
		min-width: ${ fullWidth ? 0 : 270 }px;
	}

	&:first-child {
		margin-left: 0;
	}

	&:last-child {
		margin-right: 0;
	}

	&:hover,
	&:focus,
	&:active {
		text-decoration: none;
	}

	img {
		width: 100%;
		max-width: 100%;
	}

	.text {
		color: black;
		padding: 10px 0;
		padding-right: 30px;
		padding-left: 0;
		position: relative;
		overflow: hidden;
		flex-grow: 1;
		display: flex;
		align-items: center;

    @media (hover: none), (-moz-touch-enabled: 1) {
			transition: none;
		}

		@media screen and (min-width: $screen-lg), screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
			padding: 12px 0;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: -1px;
			background-color: black;
			transform: translateX(-100%);
			z-index: -1;
			transition: 0.25s ease-in-out;

			@media (hover: none), (-moz-touch-enabled: 1) {
				transition: none;
			}
		}

		.text-mover {
			display: inline-block;
			transition: 0.25s ease-in-out;

			@media (hover: none), (-moz-touch-enabled: 1) {
				transition: none;
			}
		}

		.react-chevron-right {
			position: absolute;
			top: 50%;
			right: 10px;
			font-size: 20px;
			transform: translateY(-50%);
			transition: 0.25s ease-in-out;
		}
	}

	&:hover .text,
	&:focus .text,
	&:active .text {
		text-decoration: none;
		color: white;

		&:before {
			right: 0;
			transform: translateX(0);
			transition: 0.25s ease-in-out;

			@media (hover: none), (-moz-touch-enabled: 1) {
				transition: none;
			}
		}

		.text-mover {
			transform: translateX(10px);
		}

		.react-chevron-right {
			color: white;
		}
	}

	img {
		display: block;
		max-width: 100%;
	}
`;

export const BlockLinks = ({children, lgHeader, mobileTwoCol, fullWidth, noMargin}) => {
  return (
    <div css={[blockLinkContainerStyles ({lgHeader, mobileTwoCol, fullWidth, noMargin})]}>
    	{children}
    </div>
  )
};

export const BlockLink = ({url, selectOption, text, img, mobileImg, imgAlt, mobileTwoCol, fullWidth, ...props}) => {
	return (
		<Link href={url} css={blockLinkStyles ({mobileTwoCol, fullWidth})} {...props}>

	    <div className="text">
				<span className="text-mover">
					{text}
				</span>
				<span className='react-chevron-right'></span>
			</div>

	    {mobileImg ?
        <Picture source={img}
                 alt={imgAlt}
                 variants={[
                   {
                     media: '(max-width: 767px)',
                     title: imgAlt,
                     source: mobileImg,
                   },
                   {
                     media: '(min-width: 768px)',
                     title: imgAlt,
                     source: img,
                   }
                 ]} />
		    :

		    <Image src={img} alt={imgAlt} />
	    }

	  </Link>
	)
};

import { Component } from 'react';
import { jsx, css } from '@emotion/react';

export default class Boundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  static getDerivedStateFromError(error) { return { error }; }
  componentDidCatch(error, errorInfo) { globalThis.preventOverlay?.(error); }

  render() {
    if (!this.state.error) return this.props.children;

    return <div
      css={ css`
        margin-top: 1rem;
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
        position: relative;
        padding: .75rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: .25rem;
      ` }
    >
      { this.state.error.message }
    </div>;
  }
};

import api from '../../mdms_api';
import qs from 'qs';

export default async ({ category, slug, language }) => {
  if (!category || !slug || !language) {
    throw "(category, slug, language) must be specified"
  }
  const query = {
    lang: language
  }
  if (typeof(window) !== 'undefined' && typeof(window.PB_ZONE) !== 'undefined') {
    query.zone = window.PB_ZONE;
  }
  const response = await api.get(`/api/v1/product/${category}/${slug}?${qs.stringify(query)}`)
  return response.status === 200 ? await response.json() : Promise.resolve(null);
};

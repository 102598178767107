import React from 'react';
import { jsx, css } from '@emotion/react';
import LabelWrapper from '../../LabelWrapper';
import Text from '../../../OC/oc-text';
import { CountryAndStates } from './country_state';
import SelectField from './select_field';
import { getRequiredSuffix } from "../../../../helpers/form_libs";

const addressField = css`
  input, select {
    margin-bottom: 3px;
  }

  .twoFieldsRow {
    display: flex;
    flex-direction: row;

    @media (max-width: 700px) {
      flex-direction: column;
    }

    .fieldItem {
      width: calc(50% - 10px);

      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
`

const mr20 = css`
  margin-right: 20px;
`

export default function AddressField({formData, onChange, schema, uiSchema, formContext}) {
  const optionalFocused = formContext?.optionalFocused;

  return (
    <div css={addressField}>
      <LabelWrapper htmlFor="address-line1">
        <Text content={ schema.properties.addressLine1.title?.trim?.() } />{getRequiredSuffix(optionalFocused, (schema.required || []).includes('addressLine1'), schema.properties.addressLine1.type)}
      </LabelWrapper>
      <input
        id="address-line1"
        className="form-control"
        type="text"
        placeholder={uiSchema?.addressLine1?.placeholder}
        onChange={(e) => onChange({...formData, addressLine1: e.target.value})}
        name={schema.properties.addressLine1.title}
        value={formData.addressLine1 || ''}
        required={(schema.required || []).includes('addressLine1')}
      />
      {/* Making address line 2 optional */}
      { schema.properties?.addressLine2 &&
        <>
          <LabelWrapper htmlFor="address-line2">
            <Text content={ schema.properties.addressLine2.title?.trim?.() } />{getRequiredSuffix(optionalFocused, (schema.required || []).includes('addressLine2'), schema.properties.addressLine2.type)}
          </LabelWrapper>
          <input
            id="address-line2"
            className="form-control"
            type="text"
            placeholder={uiSchema?.addressLine2?.placeholder}
            name={schema.properties.addressLine2.title}
            onChange={(e) => onChange({...formData, addressLine2: e.target.value})}
            value={formData.addressLine2 || ''}
          />
        </>
      }
      <div className="twoFieldsRow">
        <div className='fieldItem' css={mr20}>
          <LabelWrapper htmlFor="city">
            <Text content={ schema.properties.city.title?.trim?.() } />{getRequiredSuffix(optionalFocused, (schema.required || []).includes('city'), schema.properties.city.type)}
          </LabelWrapper>
          <input
            name={schema.properties.city.title}
            id="city"
            className="form-control"
            type="text"
            placeholder={uiSchema?.city?.placeholder}
            onChange={(e) => onChange({...formData, city: e.target.value})}
            value={formData.city || ''}
            required={(schema.required || []).includes('city')}
          />
        </div>
        <div className='fieldItem'>
          <LabelWrapper htmlFor="state">
            <Text content={ schema.properties.state.title?.trim?.() } />{getRequiredSuffix(optionalFocused, (schema.required || []).includes('state'), schema.properties.state.type)}
          </LabelWrapper>
          <input
            name={schema.properties.state.title}
            id="state"
            className="form-control"
            type="text"
            placeholder={uiSchema?.state?.placeholder}
            onChange={(e) => onChange({...formData, state: e.target.value})}
            value={formData.state || ''}
            required={(schema.required || []).includes('state')}
          />
        </div>
      </div>
      <div className="twoFieldsRow">
        <span className="zip-code fieldItem" css={mr20}>
          <LabelWrapper htmlFor="zip">
            <Text content={ schema.properties.zip.title?.trim?.() } />{getRequiredSuffix(optionalFocused, (schema.required || []).includes('zip'), schema.properties.zip.type)}
          </LabelWrapper>
          <input
            name={schema.properties.zip.title}
            id="zip"
            className="form-control"
            type="text"
            placeholder={uiSchema?.zip?.placeholder}
            onChange={(e) => onChange({...formData, zip: e.target.value})}
            value={formData.zip || ''}
            required={(schema.required || []).includes('zip')}
          />
        </span>
        <SelectField
          options={CountryAndStates}
          selectTitle='Select Country / Region'
          value={formData.country || ''}
          required={(schema.required || []).includes('country')}
          handleChange={(e) => onChange({...formData, country: e.target.value})}
          name={schema.properties.country.title?.trim?.()}
          elementType={schema.properties.country.type}
          formContext={formContext}
        />
      </div>
    </div>
  );
}

import { Component } from 'react';

import GroupTheme from './Group';

class Theming extends Component {
  themed(selector, ...args) { return this.constructor.Theme.get(this.props?.theme ? this.props.theme : this.theme, selector, ...args); }
};

export default {
  Group: class Group extends Theming { static Theme = GroupTheme; }
};

import styled from "@emotion/styled";
import _ from "lodash";

// Components
import { connectLocation } from "../../location/LocationConnectors";
import Text from "../../OC/oc-text";

// Styled Components
const ProductContainer = styled.div`
  align-items: flex-start;
  border-bottom: 1px solid #BCBCBC;
  display: flex;
  padding: 16px 0;
  position: relative;

  &:last-of-type {
    border-bottom: 0;
  }

  h4, p {
    margin-bottom: 8px;
  }

  h4 {
    font-size: 18px;

    @media screen and (min-width: 1200px) {
      font-size: 20px;
    }
  }

  p {
    margin-top: 0;
  }
`;

const ProductImage = styled.img`
  display: inline-block;
  flex-shrink: 0;
  height: 96px;
  margin-right: 16px;
  position: relative;
  width: 96px;

  @media screen and (min-width: 1200px) {
    height: 120px;
    width: 120px;
  }
`;

const ProductData = styled.div`
  display: inline-block;
  flex-grow: 1;
  position: relative;
`;

const ProductPrehead = styled.span`
  display: inline-block;
  font-size: 14px;
  margin-bottom: 4px;
`;

const ProductName = styled.h4``;

const ProductDetail = styled.p``;

const ProductLink = styled.a`
  align-items: center;
  display: inline-flex;
  position: relative;
  text-decoration: none;

  @media screen and (min-width: 1200px) {
    font-size: 18px;
  }

  &:after {
    align-self: center;
    content: "";
    font-family: "icomoon";
    margin-left: 10px;
    position: relative;
    transition: margin 0.35s ease 0s;
  }

  &:hover {
    &:after {
      margin-left: 25px;
    }
  }
`;

// Helper functions
const pluralize = (unit, number) => {
  let quantity = Number(number);
  let variants = {
    feet: { singular: "lineal foot", plural: "lineal feet" },
    unit: { singular: unit, plural: `${unit}s` }
  };

  if (["foot", "feet", "lineal feet"].includes(unit)) {
    return (quantity == 1) ? variants.feet.singular : variants.feet.plural;
  } else {
    return (quantity == 1) ? variants.unit.singular : variants.unit.plural;
  }
};

const formatQuantityPerUnit = (object) => {
  return `${object.quantityPerUnit}${object.descriptor}`;
};

const getMinMax = (quantities) => {
  return {
    min: _.minBy(quantities, object => object.quantity)?.quantity,
    max: _.maxBy(quantities, object => object.quantity)?.quantity
  };
};

function calculateSolarExhaustQuantities(area) {
  return {
    quantity: ((area) => {
      if (area <= 1200) {
        return 1;
      } else if (area <= 2400) {
        return '1-2';
      } else {
        return '1-3';
      }
    })(area)
  };
}

const calculateQuantities = (calculatorData, formResult) => {
  let nfvaValues = calculatorData.nfva; // Array
  let nfvaLength = nfvaValues.length;
  let quantity_per_unit = calculatorData.application_quantity_per_unit;
  let unit = calculatorData.nfva_application;
  let unitType = calculatorData.unit;

  return nfvaValues.map((nfvaObject) => {
    let nfvaValue = nfvaObject.nfva;
    let nfvaDescription = `${nfvaObject.description || quantity_per_unit}`;
    let quantity = Math.ceil(formResult.sqin / nfvaValue);
    let quantityPerUnit = Math.ceil(quantity / quantity_per_unit);
    let description = nfvaLength > 1 ? nfvaDescription : '';
    let unitText = (unit == 'foot') ? "'" : '';

    return {
      quantity,
      descriptor: `${nfvaLength > 1 ? ' ' + description + ' ' + unitText : ''} ${pluralize(unitType, quantityPerUnit)}`,
      quantityPerUnit
    };
  });
};

const Product = connectLocation(({ t, product, formResult }) => {
  // Product Data
  let settings = product.metadata.settings;
  let defaultImage = {
    alt: "Arrows depicting airflow up through and out of the peak of a triangle representing a roof",
    file: "https://imagecdn.owenscorning.com/ocimages/image/upload/v1661545059/pagebuilder/uploads/prod/10458537/breathe.png"
  };
  let imageIndex = settings.images.list_item_image;
  let imageObject = settings.images.images[imageIndex];
  let image = imageObject?.file ? imageObject : defaultImage;
  let prehead = settings.general.prehead;
  let properName = settings.general.proper_name;
  let slug = _.last(product.route.split('/'));
  // Calculation Data
  let calculatorData = settings.attributes.calculator;
  let nfvaValues = calculatorData.nfva; // Array
  let nfvaLength = nfvaValues.length;
  let application_quantity_per_unit = calculatorData.application_quantity_per_unit;
  let unit = calculatorData.nfva_application;

  const quantities = (slug == 'solar-attic-exhaust-fan') ?
      calculateSolarExhaustQuantities(formResult.sqft) :
      calculateQuantities(calculatorData, formResult);
  let { min: minQty, max: maxQty } = getMinMax(quantities);
  let quantityData = quantities.length > 1 ? { quantity: `${minQty}-${maxQty}` } : quantities[0];
  let mainQty = quantityData && `${quantityData?.quantity} ${pluralize(unit, quantityData?.quantity)}`;
  let breakdownQty = (application_quantity_per_unit == 1 && quantities.length == 1) ? '' : `${quantities.map(
    quantity => formatQuantityPerUnit(quantity)).join(' | ')
  }`;

  return (
    <ProductContainer>
      <ProductImage alt={image?.alt} src={image?.file} />
      <ProductData>
        {prehead &&
          <ProductPrehead>
            <Text content={prehead} />
          </ProductPrehead>
        }
        <ProductName>
          <Text content={properName} />
        </ProductName>
        <ProductDetail>{t('roofing.ventilation_calculator.product.amount_required')}: <strong>{mainQty}</strong></ProductDetail>
        {!(unit === 'piece' && nfvaLength === 1) &&
          <ProductDetail>{t('roofing.ventilation_calculator.product.order_qty')}: <strong>{breakdownQty}</strong></ProductDetail>
        }
        {['undereave-intake-vent', 'soffit-vent'].includes(slug) &&
          <ProductDetail>*These products are not offered by Owens Corning.</ProductDetail>
        }
        {!['undereave-intake-vent', 'soffit-vent'].includes(slug) &&
          <ProductLink
            data-track="view-product"
            data-track-product-name={properName}
            href={`https://www.owenscorning.com${product.route}`}
            target="_blank"
          >
            {t('roofing.ventilation_calculator.product.product_details')}
          </ProductLink>
        }
      </ProductData>
    </ProductContainer>
  );
});

export default Product;

import { jsx, css } from '@emotion/react';
import { define, ui } from '@owenscorning/pcb.alpha';


import Renderer from './../../../../OC/PageBuilder/ContractorNetworkTable.jsx';

export default define`Contractor Network Details Table`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/NetworkTable`,
  category: ui`Modules/Categories/Page/Prebuilt`,
  view: ({ value }) => <Renderer { ...value } />,
  edit: {},
});

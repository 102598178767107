import styled from "@emotion/styled";

// Components
import { H3 } from "../text-elements/oc-h";
import Image from '../oc-image';
import PillCallout from "../oc-pill-callout";
import FeatureList from "../oc-feature-list";
import Button from "../oc-button";

// Styled Components
const ResultContainer = styled.div`
  display: flex;
  gap: 16px;
  position: relative;
  width: 100%;

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

const ResultImage = styled(Image)`
  display: inline-block;
  flex-shrink: 0;
  height: 120px;
  position: relative;
  width: 120px;
`;

const ResultContent = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

const Prehead = styled.span`
  display: inline-block;
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 4px;
`;

const Title = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: flex-start;
  margin-bottom: 16px;
  position: relative;

  &.pointer {
    cursor: pointer;
  }

  h3.title-heading {
    margin-bottom: 0px;
    text-transform: uppercase;
  }
`;

const CalloutContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  position: relative;

  @media screen and (max-width: 767px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

const PdpLink = styled.a`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  font-size: 18px;
  justify-content: space-between;
  line-height: 22px;
  position: relative;
  text-decoration: none;
  transition: all 0.35s ease-in-out;

  &:after {
    content: "";
    font-family: icomoon;
    margin-left: 12px;
    position: relative;
  }
`;

const BimResultBlock = ({
  availableProducts,
  compressiveStrengthStats,
  coverageAreaStats,
  ctaOnClick,
  depthStats,
  image,
  label,
  material,
  pdpLink,
  thermalResistanceStats,
}) => {
  const features = {
    compressiveStrength: compressiveStrengthStats,
    thermalResistance: thermalResistanceStats,
    depth: depthStats,
    coverageArea: coverageAreaStats
  };

  return (
    <ResultContainer>
      <ResultImage src={image} transform={{crop: "pad", aspectRatio: "1:1"}} />
      <ResultContent>
        <Prehead>{material}</Prehead>
        <Title
          className={ctaOnClick ? "pointer" : null}
          data-track="open-modal"
          data-track-destination={`${label} Download Modal`}
          onClick={ctaOnClick && ctaOnClick}
        >
          <H3 className="title-heading" font="body">{label}</H3>
          <PillCallout className="title-callout" copy={`${availableProducts} Products Available`} />
        </Title>

        <FeatureList features={features} />

        <CalloutContainer>
          {ctaOnClick &&
            <Button
              data-track="open-modal"
              data-track-destination={`${label} Download Modal`}
              onClick={ctaOnClick}
            >
              Download BIM
            </Button>
          }
          {pdpLink &&
            <PdpLink
              data-track="learn-more"
              data-track-destination={`${label} PDP`}
              href={pdpLink}
              target="_blank"
              title={`${label} Product Page`}
            >
              View Product Details
            </PdpLink>
          }
        </CalloutContainer>
      </ResultContent>
    </ResultContainer>
  );
};

export default BimResultBlock;

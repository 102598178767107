import { useState, useRef } from "react";
import { jsx, css } from "@emotion/react";
import styled from '@emotion/styled'
import { useAccessibleInteractivity } from "../../hooks"
import IconMinus from "./icons/icon-minus";
import IconPlus from "./icons/icon-plus";
import PropTypes from 'prop-types';
import innerText from 'react-innertext';

const Container = styled.div`
  width: 100%;
`

const Header = styled.div`
  align-items: center;
  background-color: ${props => props.headColor ? props.headColor : "#F8F8F8"};
  border-bottom: 1px solid #BCBCBC;
  cursor: pointer;
  display: flex;
  padding: 10px 35px 10px 16px;
  position: relative;
  transition: background-color 0.5s, padding-left 0.4s;
  user-select: none;
  width: 100%;

  &:hover {
    background-color: #F2F2F2;
    padding-left: 20px;
  }

  .icon {
    position: absolute;
    right: 13px;
    top: ${props => props.hasImage ? '22px' : '11px'} ;
  }
`

const Content = styled.div`
  display: ${props => props.isOpen ? 'block' : 'none'};
  padding: 16px;
  background-color: ${props => props.color ? props.color : 'white'};
`

const ImageWrapper = styled.img`
  height: 40px;
  margin-right: .5rem;
`;

const Accordion = ({open: defaultOpen, label, dataTrackAccordion, children, id, imageSrc, className, labelClassName, onToggle, color, headColor = null}) => {
  const labelText = innerText(label);
  const [isOpen, toggleOpen] = useState(!!defaultOpen)
  const ref = useRef()
  const onDropdownToggle = () => {
    if (onToggle) {
      onToggle(!isOpen)
    }
    toggleOpen(!isOpen)
  }

  useAccessibleInteractivity(ref, onDropdownToggle)

  return (
    <Container
      role="region"
    >
      <Header
        ref={ref}
        data-track={isOpen ? "conceal" : "reveal"}
        data-track-accordion={dataTrackAccordion || labelText}
        aria-expanded={isOpen}
        aria-label={labelText}
        aria-controls={id}
        role="button"
        headColor={headColor}
        hasImage={imageSrc}
        className={className}
      >
        {imageSrc &&
          <ImageWrapper src={imageSrc} />
        }
        <span className={labelClassName}>{ label }</span>
        {isOpen ? <IconMinus width='15' className='icon' /> : <IconPlus width='15' className='icon' />}
      </Header>

      <Content
        id={id}
        isOpen={isOpen}
        aria-hidden={!isOpen}
        color={color}
      >
        { children }
      </Content>
    </Container>
  );
};

export default Accordion;

Accordion.propTypes = {
  /** You can add an id */
  id: PropTypes.node,
  /** If you want a certain accordion open by default */
  open: PropTypes.bool,
  /** This is the heading for the accordion */
  label: PropTypes.node,
  /** This is where you put the content of the accordion */
  children: PropTypes.node,
  /** This is an analytics value for the data-track accordion attribute  */
  dataTrackAccordion: PropTypes.string,
  /** This is a prop to control the background-color of the content */
  color: PropTypes.string,
  /** This is a prop to control the fill color of the accordion header */
  headColor: PropTypes.string,
}


import { Component, useEffect } from 'react';

import {jsx} from '@emotion/react';
import _ from 'lodash';
import {connectLocation} from '../location/LocationConnectors';

import styles from './styles_step.jsx';
import { MoveNext, PrevNext, HeaderIcon, mapBuilderData } from './utilities';
import {CATEGORIES, RECOMMENDED, IGNORED, TRANSFORMATIONS} from './hardcode';
import {logChangeStep} from './analytics';
import {ConfigurationConnector} from "./BYRConnectors";
import { getNextStep, getPrevStep } from './steps_order';

import {store} from '../Store';
import {If} from "../Utilities";

export const GENERIC_LOADER = (t, products, shingle_line = '') => {
  const uniqueItems = [];

  return (
    _.chain(CATEGORIES)
      .map((category) => ({
        category: t(category.label),
        items:_.chain(products)
          .map(mapBuilderData)
          .filter(p => (
            (_.get(category, 'items[0]') === -1 && !_.chain(CATEGORIES).map(c => c.items).flatten().includes(p.uid).value()) || category.items.includes(p.uid)
          ))
          // Compatibility rejection
          .thru((items) => {
            const product_compatibility = _.get(store.getState(), ['productsCompatibility', shingle_line], null);
            if (product_compatibility) {
              let rejected = _.filter(product_compatibility, {compatible: false}).map(pc => pc.product);
              return items.filter(i => !_.includes(rejected, i.uid));
            }
            return items;
          })
          // Recommendation rules: Configuration / Compatibility
          .thru((items) => {
            const product_compatibility = _.get(store.getState(), ['productsCompatibility', shingle_line], null);
            let configurationProducts = _.get(store.getState(), 'byrConfig.products');
            let cfgProductsCount = _.countBy(configurationProducts, 'recommended').true;

            let mapFunc;
            let recommended;

            if(cfgProductsCount > 0) {
              mapFunc = (p) => {

                p.recommended = _.chain(configurationProducts)
                  .filter('recommended')
                  .map('uid')
                  .includes(p.uid)
                  .value();
                return p;
              }
            }
            else if(product_compatibility) {
              // Product Configuration always take precedence
              recommended = _.filter(product_compatibility, ['recommended', true]).map(pc => pc.product);
              mapFunc = (p) => {
                p.recommended = _.includes(recommended, p.uid);
                return p;
              }
            } else {
              // USE Hardcoded values
              mapFunc = (p) => {
                p.recommended = _.includes(RECOMMENDED, p.uid)
                return p;
              }
            }
            return items.map(mapFunc);
          })
          .map(value => {
            if(_.has(TRANSFORMATIONS, value.uid))
              return TRANSFORMATIONS[value.uid](value);
            else
              return value;
          })
          // Hide products hidden by default
          .reject(p => _.includes(IGNORED, p.uid))
          // Additionally hide products hidden by the configuration
          .reject(p =>
            _.chain(store.getState())
              .get('byrConfig.products', [])
              .filter('hidden')
              .map('uid').includes(p.uid)
              .value()
          )
          // Remove duplicates
          .thru((items) => {
            return items.filter((item) => {
              if (!uniqueItems.includes(item.uid)) {
                uniqueItems.push(item.uid);
                return true;
              }
              return false;
            });
          })
          // add a sort key for recommended true at top of list
          .map(p => ({ ...p, notrecommended: !p.recommended }))
          .sortBy(['notrecommended', 'name'])
          .value()
      }))
      .filter(p => p.items.length > 0)
      // If there is only one item available, make it recommended
      .tap(obj => {
        if(obj.length === 1 && obj[0].items.length === 1)
          _.set(obj, '[0].items[0].recommended', true);
        return obj;
      })
      .value()
  )
}


const STEP_SETUP = {
  'ice-water': {
    type: 'seal',
    loaderFunction: GENERIC_LOADER,
  },

  underlayment: {
    type: 'seal',
    loaderFunction: GENERIC_LOADER,
  },
  'starter-shingles': {
    type: 'defend',
    loaderFunction: GENERIC_LOADER,
  },
  'hip-ridge': {
    loaderFunction: GENERIC_LOADER,
    type: 'defend',
  },
}

// Translation hash, from JS categories into provided data
const HASH_PRODUCTS = {
  'ice-water': 'ice_water_barrier',
  underlayment: 'underlayment',
  'starter-shingles': 'starter_shingles',
  'hip-ridge': 'hip_ridge_shingles',
  ventilation: 'exhaust_ventilation',
}

class XSelectStep extends Component {
  componentDidMount() {
    logChangeStep(this.props.step, this.props.configuration.slug);

    const data = STEP_SETUP[this.props.step];
    let shingle_line = _.get(this.props.state, 'selected_shingle[0].uid', '');
    if(data.loaderFunction) {
      data.options = data.loaderFunction(this.props.t, this.props.products[HASH_PRODUCTS[this.props.step]], shingle_line);
    }
    this.state = {data}

    window.model3d.api.changeStep(this.props.step);
  }
  constructor(props) {
    super()

    let {step, handlers, state, products, t} = props;

    let next = getNextStep(step);
    const data = STEP_SETUP[step];


    let shingle_line = _.get(props.state, 'selected_shingle[0].uid', '');
    if(data.loaderFunction) {
      data.options = data.loaderFunction(t, products[HASH_PRODUCTS[step]], shingle_line);
    }
    this.state = {data}

    let selectedItem = state[`selected_${step}`] || {}

    if(_.isEmpty(selectedItem)) {
      let options =_.chain(data.options)
          .map(category => category.items)
          .flatten()

      // Find if we have recommended option provided for the options
      let recommended = options.filter('recommended').first().value();


      if(!_.isEmpty(recommended)) {
        handlers.set(step, recommended)();
      } else if (options.size().value() === 1) {
        // If only one option possible pre-select it
        handlers.set(step, options.first().value())();
      }
    }

  }
  render() {
    let data = this.state.data;
    let {t, step, handlers} = this.props;
    let next = getNextStep(step);
    let prev = getPrevStep(step);
    let selectedItem = this.props.state[`selected_${step}`] || {}

    return (
      <div css={styles.step}>
        <div css={styles.stepHeadingIntro}>
          <HeaderIcon what={data.type} stepHeading={true} />

          <h2 className="stepHeading">{t(`build_your_roof.${_.snakeCase(step)}`)}</h2>

          <p>{t(`build_your_roof.${_.snakeCase(step)}_description`)}</p>
        </div>

        <If condition={_.isEmpty(data.options)}>
          <p>{t(`build_your_roof.ask_contractor`)}</p>
        </If>
        <OptionsCategories categories={data.options} step={step} handlers={handlers} selectedItem={selectedItem} />
        <MoveNext next={next} styles={styles.nextBtn} />
        <PrevNext prev={prev} />
      </div>
    )
  }
}
export const SelectStep = connectLocation(ConfigurationConnector(XSelectStep));

SelectStep.displayName = 'SelectStep';
const OptionsCategories = ({categories, handlers, selectedItem, step}) => categories.map((category, idx) => (
  <div key={idx} css={styles.optionCategories}>
    <h3 className="categoryHeading">{category.category}</h3>

    <ul>
      <OptionItems step={step} handlers={handlers} selectedItem={selectedItem} items={category.items}/>
    </ul>
  </div>
));
OptionsCategories.displayName = 'OptionsCategories';


const OptionItems = connectLocation((({t, what, items, selectedItem, handlers, step}) => items.map((item, idx) => (
  <li key={idx} css={selectedItem.uid == item.uid ? styles.active_step_item : styles.step_item}
      onClick={handlers.set(step, item)}
      onKeyPress={(event) => {
        if (event.charCode == "13") {
          handlers.set(step, item);
        }
      }}
      tabIndex="0"
      data-track="tool-select"
      data-track-option={item.name}
  >
    <div>{item.image ? <img src={item.image} css={styles.stepItemImage} /> : null}</div>
    <div css={styles.stepItemContent}>
      <div>
        <div css={styles.headingInfo}>
          <div css={item.recommended == true ? styles.badgeContainer : null}>{item.recommended == true ? <span className='badge'>{t('build_your_roof.recommended')}</span> : ''}</div>
          <span css={styles.optionName}>{item.name}</span>
          <span className="headingPricingContainer"></span>
        </div>
        <p>{item.description}</p>
      </div>

      <div className="info"/>
    </div>
  </li>
))));
OptionItems.WrappedComponent.displayName = 'OptionItems';

import { define, ui } from '@owenscorning/pcb.alpha';
import Renderer from './../../../../OC/PageBuilder/Gallery.jsx';

export default define`Gallery`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/Gallery`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value }) => <Renderer { ...value } />,
  edit: {
    copy: ui`Content/Basic`({ label: 'Content' }),
    settings: ui`Form`.of({
      thumbnail: ui`Choices`.of({
        small: 'Small',
        large: 'Large'
      })({
        default: 'small',
        label: 'Thumbnail Size'
      }),
      galleryCarousel: ui`Switch`({
        label: 'View Items in Carousel',
        default: false
      }),
      containerSize: ui`Form`.of({
        selection: ui`Choices`.of({
          'xl': 'XL',
          'l': 'Large',
          'm': 'Medium',
        })({
          label: 'Container Size',
          default: 'xl',
          mode: ui`Choices/Mode/Dropdown`
        })
      }),
      alignment: ui`Choices`.of(['Left', 'Center'])({
        label: 'Alignment',
        default: 'Left'
      }),
      aspectRatio: ui`AspectRatio`,
    })({
      label: 'Gallery Settings'
    }),
    socialShare: ui`Switch`({ label: 'Modal Image Social Sharing', default: false }),
    socialShareTip: ui`Tip`.of("By enabling image social share we will be showing share options on this module's modal images."),
    items: ui`List`.of({
      uploader: {
        imageSizeInfo: ui`ImageSizeInfo`(
          {
            moduleName: 'Gallery'
          },
        ),
        media: ui`Media`({ label: 'Uploader Name/Description', choices: { image: 'Image', youtube: 'YouTube' }}),
        thumbnail: ui`Text`({ label: 'Thumbnail Title', max: 60 }),
        prehead: ui`Text`({ label: 'Modal Prehead', max: 60 }),
        heading: ui`Text`({ label: 'Modal Heading', max: 60 })
      }
    })({
      max: 24,
      label: 'Gallery Items',
      singular: 'Gallery Item',
      title: 'uploader/thumbnail',
    })
  },
});

export default function placeholder_url(w,h,additionalTransform = '') {
  const fontSize = Math.floor(Math.min(0.2*Math.min(w,h), 60));
  return `https://imagecdn.owenscorning.com/ocimages/image/upload/w_${w},h_${h}/${additionalTransform ? additionalTransform + '/' : ''}fl_no_overflow,l_text:Arial_${fontSize}:${w}x${h}/placeholder`
}

const placeholderRegex = /^https:\/\/res\.cloudinary\.com\/ocimages\/image\/upload\/w_(\d+),h_(\d+)\/([\w.,]+\/)?fl_no_overflow,l_text:Arial_\d+:\1x\2\/placeholder$/;

export function is_placeholder_url(url) {
  return url && typeof url === 'string' && url.match(placeholderRegex) !== null
}

export function placeholder_params(url) {
  return url && is_placeholder_url(url) ? url.match(placeholderRegex).slice(1).map((p, i) => i < 2 ? Number(p) : (p ? p.replace(/\/$/, '') : p)) : []
}

export function placeholder_aspect(url, aspectRatio) {
  if (!aspectRatio) {
    return url;
  }
  const [w, h] = placeholder_params(url);
  const dimension = Math.max(w, h);
  return placeholder_url(Math.floor(aspectRatio * dimension), dimension);
}

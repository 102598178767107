import styled from "@emotion/styled";

// Components
import ImageShareLinks from "../OC/oc-image-share-links";
import Picture from "../OC/oc-picture";
import Video from "../OC/oc-video";

// Assets
import IconChevron from "./icons/icon-chevron";

// Styled Components
const GalleryCarouselContainer = styled.div`
  display: inline-block;
  margin-bottom: 40px;
  position: relative;
  width: 100%;

  img {
    display: inline-block;
    width: 100%;
  }
`;

const CarouselMediaContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

const Media = styled.div`
  position: relative;
`;

const CarouselControls = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;

  a {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    padding: 40px;
    pointer-events: auto;
    position: relative;

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
      transition: 0.1s ease-in-out;
    }

    &.previous {
      svg {
        transform: rotate(90deg);
      }
    }

    &.next {
      svg {
        transform: rotate(-90deg);
      }
    }

    svg {
      height: 30px;
      width: 30px;
    }
  }
`;

const CarouselCaption = styled.div`
  background-color: #000000;
  bottom: 4px;
  color: #FFFFFF;
  display: inline-block;
  font-size: 32px;
  font-weight: bold;
  line-height: 32px;
  padding: 24px;
  position: relative;
  width: 100%;
`;

// PB-495: This component is currently specific to the PB Gallery module;
// we may rework in the future to reduce/remove dependency on the module
// or to support more carousel behaviors, e.g. autoscroll or touch gestures.
const GalleryCarousel = ({ carouselItem, navigateCarouselMedia, socialShare }) => {
  const uploader = carouselItem?.uploader;
  if (!uploader) return null;

  const media = uploader.media;
  const type = media.type;
  const isImage = (type === "image");
  const isVideo = (type === "video" || type === "youtube");
  const heading = uploader.heading || uploader.thumbnail || media.resources?.image?.alt;
  const mediaUrl = isImage ? media.resources?.image?.file : media.resources?.youtube?.url;

  if (type === 'video') {
    console.error('Gallery & GalleryCarousel components only support YouTube videos for now')
    return null;
  }

  return (
    <GalleryCarouselContainer>
      <CarouselMediaContainer>
        {isVideo &&
          <Video src={mediaUrl} />
        }
        {isImage &&
          <Media>
            <Picture source={mediaUrl} alt={heading} />
            {socialShare && <ImageShareLinks imageUrl={mediaUrl} />}
          </Media>
        }

        <CarouselControls>
          <a className="previous" onClick={() => navigateCarouselMedia("previous")}>
            <IconChevron fill="#FFFFFF" height="30px" width="30px" />
          </a>
          <a className="next" onClick={() => navigateCarouselMedia()}>
            <IconChevron fill="#FFFFFF" height="30px" width="30px" />
          </a>
        </CarouselControls>
      </CarouselMediaContainer>

      <CarouselCaption>{heading}</CarouselCaption>
    </GalleryCarouselContainer>
  );
};

export default GalleryCarousel;

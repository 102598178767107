import { mediaToMediaNew } from '../../../migrations';

export default {
  displayName: 'Content & Image',
  allowableParents: ['Section'],
  migrator: (data) => {
    if (data === undefined || data === null) {
      return {}
    }
    const { media, ...rest } = data;
    return { ...rest, media: mediaToMediaNew(media) };
  },
};

const delimiter = '|';
export const wrapRef = (type, value, data) => (
  {
    __ref: `${type}${delimiter}${JSON.stringify(value)}`,
    ...(data ? { __data: data } : {})
  }
);

// TIL JS string.split(delimiter, limit) behaves differently than other languages string.split(delimiter, limit)
// with regards to limit.  In JS, it splits entire string on delimiter and only returns limit entries.  In other
// languages, it stops splitting and returns rest of string.  JS string.split is not appropriate for refs where we
// expect "string|json", the json may have a | in it
export const limitSplit = (str, del, lim = 0) =>
{
  const elements = str.split(del);
  if (lim > 0) {
    return [ ...elements.slice(0,lim-1), elements.slice(lim-1).join(del)]
  }
  return elements;
}

export const refComponents = (ref) => (
  limitSplit(ref, delimiter, 2).map((v, index) => index === 1 ? JSON.parse(v) : v)
);

export const unwrapRef = (value) => (
  value && value.hasOwnProperty('__ref') ? refComponents(value.__ref).concat([value.__data]) : null
);

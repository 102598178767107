import { define } from '@owenscorning/pcb.alpha';
import _ from 'lodash';

const Identifier = 'feature_flags_v2';
export default define`Features`('0.0.1')({
  batch: {
    Features: {
      All: () => UI.Feature.Flags(),
      Enabled: () => UI.Feature.Flags().enabled,
      Disabled: () => UI.Feature.Flags().disabled
    },
    Feature: {
      Flags: () => JSON.parse(UI.Cookies.Get(Identifier) || '{ "enabled": [], "disabled": [] }'),
      Enabled: (flag) => _.includes(UI.Features.Enabled(), flag),
      Disabled: (flag) => _.includes(UI.Features.Disabled(), flag),
      Enable: (flag) => UI.Cookies.Set(Identifier, JSON.stringify({
        enabled: _.uniq([ ..._.castArray(UI.Features.Enabled()), flag ]),
        disabled: _.without(UI.Features.Disabled(), flag)
      })),
      Disable: (flag) => UI.Cookies.Set(Identifier, JSON.stringify({
        enabled: _.without(UI.Features.Enabled(), flag),
        disabled: _.uniq([ ..._.castArray(UI.Features.Disabled()), flag ])
      }))
    }
  }
});

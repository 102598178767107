import { define, ui, select } from '@owenscorning/pcb.alpha';
import React from 'react';

export default define`Filters`('0.0.1')({
  view: () => <div> </div>,
  edit: ({ filters }) => (
    <Subschema>
      {ui`List`.of(
        ui`Form`.of({
          title: ui`Text`({ label: 'Title', default: select`../filterId` }),
          showFilter: ui`Switch`({
            label: 'Show Filter',
            default: true,
          }),
          options: ui`List`.of(
            ui`Form`.of({
              label: ui`Text`({ label: 'Label' }),
              key: ui`Hidden`(),
            })
          )({
            title: 'key',
            label: 'Options',
            immutable: true,
            visible: select`../data_type`.from({
              select: true,
              multi_select: true,
              bool: false,
            }),
            templated: ui`Hidden`({ value: true }),
            filterId: ui`Hidden`(),
            data_type: ui`Hidden`(),
          }),
        })
      )({
        title: 'filterId',
        max: filters.length, // reorderable, but cannot add/remove
        min: filters.length,
        visible: filters.length > 0,
      })}
    </Subschema>
  ),
});

import { define, ui, when } from '@owenscorning/pcb.alpha';
import _ from 'lodash';

export default define`InsulationBasicContent`('0.0.1')({
  // TODO: can't really use this view as the font-weight ends up from theme as 600, which is too bold
  view: ({ prehead, heading, bodyCopy, banner={}, ctas=[], bodyFont, theme, children, ...props }) => <>
    <UI.Box margin={ _.isEmpty(ctas) ? ['4', '0'] : ['2', '0'] } >
      <UI.Prehead>{ prehead }</UI.Prehead>
      <UI.H2>{ heading }</UI.H2>
      <UI.Text>{ bodyCopy }</UI.Text>
    </UI.Box>
  </>,
  edit: ({ contentOptions = {}, value, onChange }) => {
    contentOptions = _.merge({
      prehead: true,
      heading: true,
      bodyCopy: true
    }, contentOptions)

    return (
      <UI.List.Item title="Basic Content" hideable value={value} onChange={onChange}>
        <UI.Form label="Content">
          {{
            bodyFont: ui`Switch`({ label: 'Use Roboto for Heading Font' }),
            prehead: ui`Text`({ label: 'Prehead', default: 'Prehead', visible: contentOptions.prehead }),
            heading: ui`Text/Rich/Header`({ label: 'Main Heading', default: 'Heading', visible: contentOptions.heading }),
            bodyCopy: ui`Text/Rich`({
              label: 'Body Copy',
              default: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus facere laborum vel earum placeat tempora dolorem doloribus.',
              visible: contentOptions.bodyCopy
            }),
            bodyCopyPlaceholder: ui`Product/PimConnected`({
              label: "Body Copy",
              noPadding: false,
              visible: when`~linked_object`.is.present.and.when`~linked_object/source`.is.equal.to("pim_insulation")
            }),
            imageSizeInfo: ui`ImageSizeInfo`(
              { moduleName: 'BasicContent' },
            ),
            banner: ui`Picture`,
            ctas: ui`CTA/List`({
              visible: when`~linked_object`.is.present.and.when`~linked_object/source`.is.equal.to("pim_insulation").then(false).otherwise(true)
            }),
            ctasPlaceholder: ui`Product/PimConnected`({
              label: "CTAs",
              visible: when`~linked_object`.is.present.and.when`~linked_object/source`.is.equal.to("pim_insulation")
            }),
          }}
        </UI.Form>
      </UI.List.Item>
    )
  }
})

import { Fragment, useState } from "react";
import Modal from "../../Modal";
import { connectLocation } from "../../location/LocationConnectors";
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import withDefaultPrevented from "../../../withDefaultPrevented";
import RichText from '../../../components/OC/PageBuilder/RichText';

const ScrollSection = styled.div`
  overflow-x: auto;
`;

const WarrantyTable = styled.table`
  border-collapse: collapse;
  font-size: 14px;

  a {
    text-decoration: none;
  }

  sup {
    font-size: xx-small;
  }

  img {
    width: 136px;
    vertical-align: middle;
  }

  tr {
    border-bottom: 1px solid #bcbcbc;
  }

  thead tr:nth-child(2) > * {
    padding: 16px 0;
  }

  thead tr {
    border-bottom: 2px solid #bcbcbc;
  }

  tbody td[scope="colspan"] {
    background-color: #e6e6e6;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    text-align: left;
    font-weight: 700;
  }

  td,
  th {
    padding: 9px 8px 7px 8px;
    text-align: center;
    width: 20%;
    min-width: 115px;
  }

  tr > * {
    border-left: 1px solid #bcbcbc;
  }

  tr > :first-child {
    border-left: 0;
  }

  tr > :nth-child(2) {
    border-left: 2px solid black;
    border-right: 2px solid black;
  }

  tbody td[scope="row"] {
    text-align: right;
  }

  tbody tr:nth-child(odd) {
    background-color: #f8f8f8;
  }

  thead tr:first-of-type {
    text-transform: uppercase;
    font-size: 15px;
  }
`;

const InfoTextSpan = styled.span`
  vertical-align: middle;
  display: inline-block;

  &.warranty-name {
    max-width: 110px;
  }

  &.coverage {
    font-weight: bold;
  }

  &.limited {
    width: calc(100% - 18px);
  }
`;

const MoreInfoDiv = styled.div`
  display: inline;

  i {
    margin-left: 6px;
    color: #65656a;
    cursor: pointer;
    &:hover {
      color: rgb(206, 17, 38);
    }
    &:before {
      font: normal normal normal 14px/1 FontAwesome;
      content: "\f05a";
    }
  }
`;

const DisclaimerContainer = styled.div`
  font-size: 12px;
  margin-top: 32px;

  a {
    text-decoration: none;
  }
  ol {
    padding: 0 0 0 12px;
  }
  li {
    margin-bottom: 4px;
    outline: none;
  }
`;

const TableModal = styled(Modal)`
  .modal-body {
    max-width: 500px;
  }
`;

const interceptLinkEvent = (e) => {
  if (e.target.localName !== "a") return;

  e.preventDefault();
  const ele = document.getElementById(e.target.href.split("#")[1]);
  ele.scrollIntoView({ inline: "start", block: "center", behavior: "smooth" });
  setTimeout(() => {
    ele.focus();
  }, 500); // delay for the smooth scroll
};

const CMSWarrantyTable = connectLocation(({ t }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalHeader, setModalHeader] = useState(null);
  const [modalContent, setModalContent] = useState(null);

  const openModal = (header, content) => {
    setModalHeader(header);
    setModalContent(content);
    setModalOpen(true);
  };

  const moreInfo = (rowHeader, header, text) => {
    const tooltipName = `${rowHeader} Tooltip`;
    return (
      <MoreInfoDiv>
        <i
          class="fa fa-info-circle"
          aria-hidden="true"
          data-track="tooltip"
          tabIndex="0"
          title="Get more information"
          data-track-destination={tooltipName}
          aria-label={tooltipName}
          onClick={() => openModal(header || rowHeader, text)}
          onKeyPress={() => openModal(header || rowHeader, text)}
        />
      </MoreInfoDiv>
    );
  };

  /* Table helper variables */

  const coverageHeaderValues = Object.values(
    t("roofing.warranty.table.coverage_headers")
  );
  const columnLength =
    coverageHeaderValues.reduce((total, current) => {
      return (
        (isNaN(total) ? Object.values(total).length : total) +
        Object.values(current).length
      );
    }) + 1;
  // The idea here is to have an object of headers for each column for the cells to use
  const mainHeaderMapping = [{}];
  const idIfy = (label) => {
    return label.toLowerCase().replace(/[^a-z]/g, "");
  };
  let colCounter = 0;

  return <>
    <TableModal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      name={modalHeader}
    >
      <h3
        className="heading"
        dangerouslySetInnerHTML={{ __html: modalHeader }}
      ></h3>
      <div dangerouslySetInnerHTML={{ __html: modalContent }}></div>
    </TableModal>

    <ScrollSection>
      <WarrantyTable data-testid="warranty-table" onClick={interceptLinkEvent}>
        <colgroup>
          <col />
          {coverageHeaderValues.map((ch, ind) => {
            return <col key={ind} span={Object.keys(ch).length} />;
          })}
        </colgroup>
        <thead>
          <tr>
            <td id="blank">
              &nbsp; <span className="sr-only">empty</span>
            </td>
            {Object.entries(t("roofing.warranty.table.coverage_groups")).map(
              ([key, obj]) => {
                const coverageHeaders = Object.keys(
                  t(`roofing.warranty.table.coverage_headers.${key}`)
                ).length;

                const tKey = idIfy(key);
                for (let i = 0; i < coverageHeaders; i++) {
                  mainHeaderMapping.push({ [tKey]: tKey });
                }

                return (
                  <td
                    colSpan={coverageHeaders}
                    scope={coverageHeaders == 1 ? "col" : "colspan"}
                    key={tKey}
                    id={tKey}
                  >
                    <InfoTextSpan aria-hidden="true" className="coverage">{obj["text"]}</InfoTextSpan>
                    {moreInfo(obj["text"], obj["info_header"], obj["info"])}
                  </td>
                );
              }
            )}
          </tr>
          <tr>
            <td id="blank-2">
              &nbsp; <span className="sr-only">Coverage Attributes</span>
            </td>
            {Object.entries(t("roofing.warranty.table.coverage_headers")).map(
              ([cKey, coverageHeader]) => {
                return Object.values(coverageHeader).map((header) => {
                  const colLabel = header["text"] || header["image_alt"];
                  const tLabel = idIfy(colLabel);
                  mainHeaderMapping[colCounter + 1][tLabel] = tLabel;
                  colCounter++;

                  return (
                    <th
                      scope="col"
                      key={tLabel}
                      id={tLabel}
                      headers={idIfy(cKey)}
                    >
                      {(header["image"] && (
                        <img
                          src={header["image"]}
                          alt={header["image_alt"]}
                        />
                      )) || <InfoTextSpan className="warranty-name">{header["text"]}</InfoTextSpan>}
                      {moreInfo(
                        colLabel,
                        header["info_header"],
                        header["info"]
                      )}
                    </th>
                  );
                });
              }
            )}
          </tr>
        </thead>
        <tbody>
          {Object.entries(t("roofing.warranty.table.sections")).map(
            ([key, obj]) => {
              const rows = Object.entries(obj["rows"]).map(
                ([rowKey, row]) => {
                  let rowHeaderKey;
                  const cols = Object.entries(row).map(
                    ([colKey, rowCol], ind) => {
                      const cellKey = idIfy(`${rowKey}-${colKey}`);

                      if (ind === 0) rowHeaderKey = cellKey;

                      const Cell = "td";

                      const cellProps =
                        ind === 0
                          ? { id: cellKey, scope: "row" }
                          : {
                              headers: `${key} ${rowHeaderKey} ${Object.values(
                                mainHeaderMapping[ind]
                              ).join(" ")}`,
                            };

                      return (
                        <Cell key={`${rowKey}-${colKey}`} {...cellProps}>
                          <InfoTextSpan className="limited">
                            <RichText content={rowCol["text"]} />
                          </InfoTextSpan>
                          {rowCol["info"] &&
                            moreInfo(
                              rowCol["text"],
                              rowCol["info_header"],
                              rowCol["info"]
                            )}
                        </Cell>
                      );
                    }
                  );
                  return <tr key={`${rowKey}`}>{cols}</tr>;
                }
              );

              return (
                <Fragment key={key}>
                  <tr>
                    <td
                      id={key}
                      headers="blank"
                      scope="colspan"
                      colSpan={columnLength}
                    >
                      {obj["header"]}
                    </td>
                  </tr>
                  {rows}
                </Fragment>
              );
            }
          )}
        </tbody>
      </WarrantyTable>
    </ScrollSection>
    <DisclaimerContainer onClick={interceptLinkEvent}>
      {t("roofing.warranty.table.see_warranty")}
      <ol>
        {Object.entries(t("roofing.warranty.table.disclaimers")).map(
          ([key, str]) => {
            return (
              <li key={key} id={key} tabIndex="-1" dangerouslySetInnerHTML={{__html: str}} />
            );
          }
        )}
      </ol>
    </DisclaimerContainer>
  </>;
});

export default CMSWarrantyTable;

import { define, ui } from '@owenscorning/pcb.alpha';

import Renderer from '../../../../OC/PageBuilder/L77Loosefill';

export default define`Submittal Sheet - l77 Loosefill`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/L77Loosefill`,
  category: ui`Modules/Categories/Page/Prebuilt`,
  view: ({ value }) => <Renderer {...value} />,
  edit: {
    content: ui`Form`.of({
      copy: ui`Content/Basic`,
    })({ label: 'Content' }),
  },
});

import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

// Components
import IconInfo from "../icons/icon-info";

// Styled Components
const FormLabel = styled.label`
  align-items: center;
  color: ${props => props.color};
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
  width: 100%;
`;

const Text = styled.span`
  display: inline-block;
  margin-bottom: 4px;
  margin-right: 8px;
  position: relative;
`;

const LabelExtras = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 4px;
  position: relative;
`;

const HelperText = styled.span`
  color: ${props => props.helperTextColor ? props.helperTextColor : (props.required ? "#940420" : "#585858")};
  font-size: 12px;
  font-weight: normal;
  margin-right: 8px;
`;

// Container for click event binding, can't bind directly to icon SVG
const IconContainer = styled.span`
  display: inline-flex;
  position: relative;

  &:hover, &:focus {
    .tooltip {
      display: inline-block;
    }
  }
`;

const Icon = styled(IconInfo)`
  cursor: pointer;
`;

const ToolTip = styled.span`
  background-color: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.22);
  display: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  left: 50%;
  padding: 16px;
  position: absolute;
  top: -16px;
  transform: translate(-50%, -100%);
  width: 204px;

  &:after {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 9px solid #FFFFFF;
    bottom: -9px;
    content: "";
    display: inline-block;
    filter: drop-shadow(0px 1px 1px rgb(0 0 0 / 22%));
    height: 0px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 0px;
  }
`;

const Label = ({ text, helperText, helperTextColor = null, forId, disabled = false, valid = true, infoFunction, required = false, tooltip }) => {
  const color = disabled ? "#666666" : (valid ? "#000000" : "#940420");

  const infoClick = () => {
    infoFunction && infoFunction();
  }

  const infoKeyDown = (event) => {
    if (event.key == " " || event.key == "Enter") {
      event.preventDefault();
      infoFunction && infoFunction();
    }
  }

  return (
    <FormLabel color={color} htmlFor={forId}>
      <Text>{text}</Text>

      <LabelExtras>
        {helperText && <HelperText required={required} helperTextColor={helperTextColor}>{helperText}</HelperText>}
        {(infoFunction || tooltip) &&
          <IconContainer
            data-track="tooltip"
            data-track-destination={text}
            onClick={infoClick}
            onKeyDown={(event) => infoKeyDown(event)}
            tabIndex="0"
          >
            <Icon />
            {tooltip &&
              <ToolTip className="tooltip" dangerouslySetInnerHTML={{ __html: tooltip }} />
            }
          </IconContainer>
        }
      </LabelExtras>
    </FormLabel>
  )
};

export default Label;

Label.propTypes = {
  /* HTML: The primary label text */
  text: PropTypes.string,
  /* HTML: Label helper text, ex: 'Required' */
  helperText: PropTypes.string,
  /* HTML: (Optional) The id of the input that the label corresponds to */
  forId: PropTypes.string,
  /* CSS: Determines the display of the error state presentation */
  valid: PropTypes.bool
}

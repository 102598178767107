import styled from '@emotion/styled';

import { InputWrapper } from './InputWrapper';
import { SelectDropDown } from '../../Builder/Library/Choices';
import Label from '../../ComponentLibrary/input-elements/oc-label';
import Image from '../../OC/oc-image';

const CountryOption = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: left;

  img {
    width: 38px;
    margin-right: 15px;
  }
`;

export function makeCountryOption({ code, countryName, flag }) {
  return {
    value: code.split('-')[1],
    label: (
      <CountryOption>
        <Image src={flag} alt={code} style={{ marginRight: '15px' }} />
        <span>{countryName}</span>
      </CountryOption>
    ),
  };
}

export const CountryPicker = ({ value, options, setCountry, label }) => (
  <InputWrapper>
    <Label text={label} />
    <SelectDropDown
      value={value}
      options={options}
      onChange={option => setCountry(option.value)}
      validated
    />
  </InputWrapper>
);

import { ui, define, select, when } from '@owenscorning/pcb.alpha';
import _ from 'lodash';

// const BasicContent = ({ prehead, heading, bodyCopy, banner, ctas, bodyFont, theme, children, ...props }) => {
//   return (
//     <>
//       { ( !_.isEmpty(prehead) || !_.isEmpty(heading) || !_.isEmpty(bodyCopy) || !_.isEmpty(ctas) ) &&
//         <Spacer double={!_.isEmpty(ctas)}>
//           <Heading
//             prehead={ prehead }
//             mainHeading={ heading }
//             head_class={ bodyFont && 'h2 body-font' }
//             headingFont={ bodyFont && 'body' }
//             theme={ theme && 'vidawool' }
//           />
//           <RichText content={ bodyCopy } />
//           { banner?.file && <Image
//             css={ css` width: 100%; height: auto; ` }
//             src={ banner.file }
//             alt={ banner.alt }
//           /> }
//           { children }
//           <CtaList ctas={ ctas } />
//         </Spacer>
//       }
//     </>
//   )
// }

export default define`Basic`('0.0.1')({
  // TODO: can't really use this view as the font-weight ends up from theme as 600, which is too bold
  view: ({ prehead, heading, bodyCopy, banner={}, ctas=[], bodyFont, theme, children, ...props }) => <>
    <UI.Box margin={ _.isEmpty(ctas) ? ['4', '0'] : ['2', '0'] } >
      <UI.Prehead>{ prehead }</UI.Prehead>
      <UI.H2>{ heading }</UI.H2>
      <UI.Text>{ bodyCopy }</UI.Text>
    </UI.Box>
  </>,
  edit: ({ contentOptions = {}, value, onChange }) => {
    contentOptions = _.merge({
      prehead: true,
      heading: true,
      bodyCopy: true
    }, contentOptions)

    return (
      <UI.List.Item title="Basic Content" hideable value={value} onChange={onChange}>
        <UI.Form label="Content">
          {{
            bodyFont: ui`Switch`({ label: 'Use Roboto for Heading Font' }),
            prehead: ui`Text`({ label: 'Prehead', default: 'Prehead', visible: contentOptions.prehead }),
            heading: ui`Text/Rich/Header`({ label: 'Main Heading', default: 'Heading', visible: contentOptions.heading }),
            bodyCopy: ui`Text/Rich`({
              label: 'Body Copy',
              default: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus facere laborum vel earum placeat tempora dolorem doloribus.',
              visible: contentOptions.bodyCopy
            }),
            imageSizeInfo: ui`ImageSizeInfo`(
              { moduleName: 'BasicContent' },
            ),
            banner: ui`Picture`,
            crop: ui`Cropper`({
              image: select`../banner/source`,
              startAspectLocked: true,
              visible: when`../banner/source`.is.present.then(true),
              default: { x: 0, y: 0, width: 1438, height: 900 }
            }),
            ctas: ui`CTA/List`
          }}
        </UI.Form>
      </UI.List.Item>
    )
  }
})

import _ from 'lodash';
import Flags from './Flags';
import { formatDatetime } from '../../../PageBuilder/helpers/time';
import { define } from '@owenscorning/pcb.alpha';

const Status = {
  Warning: (language) => !!language.warning,
  Redirected: (language) => language.edited_redirect || language.published_redirect,
  Published: (language) => language.published_at,
  Draft: (language) => (language.edited_at && !language.published_at) || language.edited_at > language.published_at,
  Flags: (language) => [
    Status.Redirected(language) && Flags.Status.Redirected,
    !Status.Redirected(language) && Status.Published(language) && Flags.Status.Published,
    Status.Draft(language) && Flags.Status.Draft,
    Status.Warning(language) && Flags.Status.Warning,
  ].filter(Boolean),
  Count: (summary) => _.reduce(
    summary, (result, language) => {
      if (Status.Draft(language)) result.Draft++;
      if (Status.Redirected(language)) { result.Redirected++; return result }
      if (Status.Published(language)) result.Published++;
      return result;
    },
    { Redirected: 0, Published: 0, Draft: 0, Warning: 0 }
  ),
  Presence: (summary) => _.map(Status.Count(summary), (value, key) => ( value ? key : false )).filter(Boolean),
  Stamp: {
    Edit: (language) => formatDatetime(language.edited_at),
    Publish: (language) => formatDatetime(language.published_at)
  }
};

export default define`Status`({
  raw: () => Status
});

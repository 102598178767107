import { define, ui } from '@owenscorning/pcb.alpha';

import Renderer from '../../../../OC/PageBuilder/SprayFoamVentilationCalculator.jsx';


export default define`Spray Foam Ventilation Calculator`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/RainbarrierComparisonTable`,
  category: ui`Modules/Categories/Page/Prebuilt`,
  view: ({ value }) => <Renderer { ...value } />,
  edit: {
    content: ui`Form`.of({
      copy: ui`Content/Basic`,
      footnotes: ui`Text/Rich`({ label: 'Footnotes' }),
    })({ label: 'Content' }),
    calculator: ui`Form`.of({
      laborRate: ui`Text`({ label: 'Default ACH' }),
    })({ label: 'Calculator Defaults' })
  },
});

import * as React from 'react';
import { jsx, css } from '@emotion/react';
import Theme from '../../themes/index';
import Link from './oc-link';
import { navigate } from '../helpers/sticky_nav';
import { useHistory } from 'react-router-dom';

const ctaStyles = css`
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  background: ${Theme.colors.brand};
  color: white;
  font-family: ${Theme.fonts.body}, sans-serif;
  font-weight: 400;
  text-decoration: none;
  padding: 0 1rem;
  min-width: 170px;
  min-height: 35px;
  cursor: pointer;
  font-size: 18px;
  border: 1px solid ${Theme.colors.brand};
  overflow: hidden;
  z-index: 1;
  transition: 0.25s ease-in-out;

  // Prevent transition on touch devices
  @media (hover: none), (-moz-touch-enabled: 1) {
    transition: none;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -1%;
    background-color: white;
    transform: translateX(-100%);
    z-index: -1;
    transition: 0.25s ease-in-out;

    /* Prevent transition on touch devices*/
    @media (hover: none), (-moz-touch-enabled: 1) {
      transition: none;
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: ${Theme.colors.brand};
    outline: 0;

    &:before {
      /* display: block; */
      right: 0;
      transform: translateX(0);
      transition: 0.25s ease-in-out;

      /* Prevent transition on touch devices */
      @media (hover: none), (-moz-touch-enabled: 1) {
        transition: none;
      }
    }
  }
`;

var ctaColors = {
  black: css`
    background: black;
    border-color: white;
    color: white;

    &:before {
      background-color: white;
    }

    &:hover, &:focus {
      color: ${Theme.colors.brand}!important;
    }
  `,
  white: css`
    background: white;
    border-color: black;
    color: black;

    &:before {
      background-color: black;
    }

    &:hover, &:focus {
      color: white!important;
    }
  `,

  grey: css`
    background: #ddd;
    border-color: #333;
    color: black;

    &:before {
      background-color: black;
    }

    &:hover, &:focus {
      color: white;
    }
  `,

  transparent: css`
    background: transparent;
    border-color: white;
    color: white;

    &:before {
      background-color: white;
    }

    &:hover, &:focus {
      color: black!important;
    }
  `,

  borderless: css`
    background: transparent;
    border-color: transparent;
    color: black;

    &:before {
      background-color: black;
    }

    &:hover, &:focus {
      color: white!important;
    }
  `,
};

const disabledStyle = css`
  opacity: 0.5;
  pointer-events: none;
  &:before {
    transition: none;
  }
`;

const Cta = ({ href, navigateTo, children, color, disabled, forwardRef, ...props }) => {
  const history = useHistory()
  const scrollTo = (e, key) => {
    if(key) {
      navigate(key);
      history.push(`#${key}`, {});
      e.preventDefault();  
    }
  }

  return(
    <Link 
      onClick={navigateTo ? ((e) => scrollTo(e, navigateTo)) : null}
      css={[ctaStyles, color ? ctaColors[color] : '', disabled ? disabledStyle : '']}
      forwardRef={forwardRef}
      href={href}
      {...props}
    >
      {children}
    </Link>   
  );
};

export default Cta;

import { fetchUsingJWT, jwtPromise } from './jwt';
import { formDataFromObject } from './components/helpers/form_data';
import { upload } from 'xhr-file';

const xhrUpload = (url, data) => {
  console.log('xhrUpload', url, data)
  return upload(url, data)
}

const defineApi = (baseUrlFactory, baseUrlEnvName, cdnBaseUrlFactory, cdnBaseUrlEnvName) => {
  cdnBaseUrlFactory = cdnBaseUrlFactory || baseUrlFactory;

  const validateBaseUrl = () => {
    const base = baseUrlFactory();
    if (typeof(base) === 'undefined' || !base) {
      const message = `${baseUrlEnvName} must be set in process.env, check your \`.env\` file`;
      console.error(message);
      if (process.env.NODE_ENV === 'development') { alert(message); }
      return Promise.reject(new Error(message));
    }
    return Promise.resolve(base);
  }

  const validateCdnBaseUrl = () => {
    const base = cdnBaseUrlFactory() || baseUrlFactory();
    if (typeof(base) === 'undefined' || !base) {
      const message = `${cdnBaseUrlEnvName} or ${baseUrlEnvName} must be set in process.env, check your \`.env\` file`;
      console.error(message);
      if (process.env.NODE_ENV === 'development') { alert(message); }
      return Promise.reject(new Error(message));
    }
    return Promise.resolve(base);
  }

  return {
    secure_post: (url, data, options = {}) => (
      validateBaseUrl().then(base => fetchUsingJWT(base + url, {
        ...(options || {}),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...(options?.headers || {}) },
        body: JSON.stringify(data)
      }))
    ),
    post: (url, data, options = {}) => (
      validateBaseUrl().then(base => fetch(base + url, {
        ...(options || {}),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...(options?.headers || {}) },
        body: JSON.stringify(data)
      }))
    ),
    secure_put: (url, data, options = {}) => (
      validateBaseUrl().then(base => fetchUsingJWT(base + url, {
        ...(options || {}),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', ...(options?.headers || {}) },
        body: JSON.stringify(data)
      }))
    ),
    secure_get: (url, options = {}) => (
      validateBaseUrl().then(base => fetchUsingJWT(base + url, {
        ...(options || {}),
        method: 'GET',
        headers: { ...(options?.headers || {}) }
      }))
    ),
    get: (url, options = {}) => (
      validateCdnBaseUrl().then(base => fetch(base + url, {
        ...(options || {}),
        method: 'GET',
        headers: { ...(options?.headers || {}) }
      }))
    ),
    secure_delete: (url, options = {}) => (
      validateBaseUrl().then(base => fetchUsingJWT(base + url, {
        ...(options || {}),
        method: 'DELETE',
        headers: { ...(options?.headers || {}) }
      }))
    ),
    secure_upload: (url, file, onProgress, options = {}) => (
      Promise.all(
        [jwtPromise(), validateBaseUrl()]
      ).then(
        ([jwt, base]) =>
          // TODO merge in options?
          xhrUpload(base + url,
            _.merge(
              {},
              {
                onProgress,
                headers: { ...(options?.headers || {}), Authorization: `Bearer ${ jwt }` }
              },
              _.isPlainObject(file) ? file : { file },
              _.isPlainObject(file) && file.data ? { data: formDataFromObject(file.data) } : {}
            )
          ).then(response =>
            ({ ok: response.ok, json: () => Promise.resolve(response.body), status: response.status })
          )
      )
    )
  };
};

export const mdms = defineApi(
  () => MDMS_URL, 'MDMS_URL',
  () => MDMS_API_CDN_HOST_AND_PROTOCOL, 'MDMS_API_CDN_HOST_AND_PROTOCOL',
)

// SAMPLE
// import { mdms } from 'api'
// ...
// mdms.get('/api/v1/...')

import { useState, useEffect } from 'react';
import { debounce, get } from 'lodash';
import { v4 as uuid } from 'uuid';
import { connectLocation } from './location/LocationConnectors';
import displayName from './helpers/displayName';

// this is unique per page load
const id = uuid();

export const calculatorTracker = (category, WrappedComponent) => {

  const HOC = connectLocation((props) => {
    const [currentData, setCurrentData] = useState(null);
    const [sentStringData, setSentStringData] = useState(null);

    useEffect(() => {
      const currentStringData = JSON.stringify(currentData);
      const timer1 = setTimeout(() => {
        if (currentStringData !== sentStringData) {
          setSentStringData(currentStringData);
          // don't track initial load, only first change
          if (sentStringData !== null) {
            fetch(MDMS_URL + '/api/v1/calculator-data',
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: currentStringData
              }
            );
          }
        }
      }, 5000);
      return () => {
        clearTimeout(timer1);
      };
    }, [currentData])

    const logData = data => {
      setCurrentData({
        uuid: id,
        category,
        data,
        zip: props?.zip,
        c_code: props?.locale?.code
      });
    }

    return <WrappedComponent { ...props } logData={ logData } />;
  });

  HOC.displayName = `calculatorRecorder(${displayName(WrappedComponent)})`

  return HOC;
};

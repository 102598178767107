import { ui, when, select } from '@owenscorning/pcb.alpha';
import { useEffect } from 'react';
import _ from 'lodash';

const sizesHash = {
  '1': ['Square (1.0) cards', '1360x1360px'],
  '2': ['Wide (2.0) cards', '1360x680px'],
  none: ['Freeform cards', '1360px'],
  '2.5': ['Extra Wide (2.5) cards', '1360x544px'],
  '0.77': ['Tall (0.77) cards', '1360x1774px'],
  custom: ['Custom cards', '1360px']
};

export const definedCardsPerRowDesktop = (cardSize) => {
  const cardLimits = {
    xl: ['2'],
    lg: ['2', '3'],
    md: ['2', '3', '4'],
    sm: ['2', '3', '4', '5'],
    xs: ['2', '3', '4', '5', '6']
  };
  return cardLimits[cardSize] || ['2'];
};

export const definedCardsPerRowTablet = (cardSize) => {
  const cardLimits = {
    xl: ['2'],
    lg: ['2'],
    md: ['2', '3'],
    sm: ['2', '3'],
    xs: ['2', '3', '4']
  };
  return cardLimits[cardSize] || ['2'];
};

export const definedCardsPerRowMobile = (cardSize) => {
  const cardLimits = {
    xl: ['1'],
    lg: ['1'],
    md: ['1'],
    sm: ['1', '2'],
    xs: ['1', '2']
  };
  return cardLimits[cardSize] || ['1'];
};

const defaultCardsPerRow = {
  itemsPerRow: ui`Choices`.of(['2', '3', '4', '5', '6'])({
    label: 'Number of items per row (at desktop)',
    default: '3',
    mode: ui`Choices/Mode/Dropdown`
  }),
  itemsPerRowTablet: ui`Choices`.of(['2', '3', '4'])({
    label: 'Number of items per row (at tablet)',
    default: '2',
    mode: ui`Choices/Mode/Dropdown`
  }),
  itemsPerRowMobile: ui`Choices`.of(['1', '2'])({
    label: 'Number of items per row (at mobile)',
    default: '1',
    mode: ui`Choices/Mode/Dropdown`
  }),
};

const definedCardsPerRow = (cardSize) => {
  const desktopOptions = definedCardsPerRowDesktop(cardSize);
  const tabletOptions = definedCardsPerRowTablet(cardSize);
  const mobileOptions = definedCardsPerRowMobile(cardSize);

  return ({
    itemsPerRow: ui`Choices`.of(desktopOptions)({
      label: 'Number of items per row (at desktop)',
      mode: ui`Choices/Mode/Dropdown`
    }),
    itemsPerRowTablet: ui`Choices`.of(tabletOptions)({
      label: 'Number of items per row (at tablet)',
      mode: ui`Choices/Mode/Dropdown`
    }),
    itemsPerRowMobile: ui`Choices`.of(mobileOptions)({
      label: 'Number of items per row (at mobile)',
      mode: ui`Choices/Mode/Dropdown`
    })
  });
};

const cardItemFields = (format, cardSize) => {
  if (!format || format == 'auto') {
    return defaultCardsPerRow;
  } else {
    return definedCardsPerRow(cardSize);
  }
};

export default ({ value }) => {
  const format = value?.format;
  const cardSize = value?.cardSize;
  const settings = ({
    cardType: ui`Choices`.of({
      'standard': 'Standard',
      'comparison': 'Comparison'
    })({
      label: 'Card Style',
      default: 'standard',
      visible: when`../../type/source`.is.equal.to('custom').then(true).otherwise(false)
    }),
    textAlignment: ui`Choices`.of({
      'left': 'Left',
      'center': 'Center'
    })({
      label: 'Text Alignment',
      default: 'left'
    }),
    format: ui`Choices`.of({
      'auto': 'Auto Size',
      'defined': 'Defined Card Size'
    })({
      label: 'Format',
      default: 'auto'
    }),
    cardSize: ui`Choices`.of({
      xl: 'XL',
      lg: 'Large',
      md: 'Medium',
      sm: 'Small',
      xs: 'XS'
    })({
      label: 'Card Size',
      default: 'xl',
      visible: when`../format`.is.equal.to('defined')
    }),
    ...cardItemFields(format, cardSize),
    imageSizeInfo: ui`ImageSizeInfo`(
      {
        moduleName: 'CardSet',
        sizesHash: sizesHash,
        selectedSize: select`../../settings/aspectRatio`
      },
    ),
    aspectRatio: ui`AspectRatio`,
    alignment: ui`Choices`.of(['Left', 'Center'])({
      label: 'Container Alignment',
      default: 'Left'
    }),
    containerSize: ui`Form`.of({
      selection: ui`Choices`.of({
        'xl': 'XL',
        'l': 'Large',
        'm': 'Medium',
      })({
        label: 'Container Size',
        default: 'xl',
        mode: ui`Choices/Mode/Dropdown`,
        visible: when`../../format`.is.equal.to('auto')
      })
    }),
  });

  // PB-783: When format or cardSize changes, we update itemsPerRow selections
  // to next-closest value if selected value is unavailable to selected cardSize
  useEffect(() => {
    if (format == 'defined') {
      const desktopOptions = definedCardsPerRowDesktop(cardSize);
      const tabletOptions = definedCardsPerRowTablet(cardSize);
      const mobileOptions = definedCardsPerRowMobile(cardSize);

      value.itemsPerRow = desktopOptions.includes(value.itemsPerRow) ? value.itemsPerRow : _.last(desktopOptions);
      value.itemsPerRowTablet = tabletOptions.includes(value.itemsPerRowTablet) ? value.itemsPerRowTablet : _.last(tabletOptions);
      value.itemsPerRowMobile = mobileOptions.includes(value.itemsPerRowMobile) ? value.itemsPerRowMobile : _.last(mobileOptions);
      value.containerSize.selection = 'xl';
    }
  }, [format, cardSize]);

  return(
    <Subschema>{[
      ui`Form`.of(settings)({
        label: 'Layout'
      })
    ]}</Subschema>
  );
};

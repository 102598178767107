import styled from '@emotion/styled';
import NumberFormat from "react-number-format";

// Components
import {H3, H4} from '../../ComponentLibrary/text-elements/oc-h'
import Form from '../../ComponentLibrary/forms/oc-form';
import Label from '../../ComponentLibrary/input-elements/oc-label';
import Fieldset from '../../ComponentLibrary/input-elements/oc-fieldset';
import TextInput from '../../ComponentLibrary/input-elements/oc-text-input';
import RadioButton from '../../ComponentLibrary/input-elements/oc-radio-button';
import RadioGroup from '../../ComponentLibrary/input-elements/oc-radio-group';
import FormButton from '../../ComponentLibrary/input-elements/oc-form-button';

const InputContainer = styled.div`
	display: flex;
	margin: 0!important;

	span {
		margin: 20px 8px 0;
	}
`;

const NumberInput = styled(NumberFormat, { displayType: "input" })`
  appearance: none;
  border-radius: 0;
  border: 1px solid #585858;
  display: inline-block;
  font-size: 16px;
  padding: 14px 8px;
  position: relative;
  width: 100%;
`;

const ErrorContainer = styled.span`
	margin: 0!important;

	span {
 		margin: 4px 0 0!important;
	}
`;

const LaborCost = styled.div`
	width: 46%!important;
`;

const ProCatForm = ({formSubmit, inputChange, input}) => {

	return (
		<>
			<Form onSubmit={formSubmit}>
				<H3 font="body" underline>Calculating Inputs</H3>

			  <Fieldset margin="16px 0">
			  	<TextInput name="atticSqFt" label="Attic Sq. Ft." touched={true} error={input.sqFtError} onChange={inputChange} value={input.atticSqFt} />
			  </Fieldset>

			  <Fieldset margin="0 0 16px">
			  	<TextInput name="currentDepth" label="Current Insulation Depth (in)" touched={true} error={input.currentDepthError} onChange={inputChange} value={input.currentDepth} />
			  </Fieldset>

			  <Fieldset margin="0 0 32px" horizontal={true} legend="DOE Recommended R-Value" onChange={inputChange}>
			    <RadioButton name="rvalue" value="R38" checked={input.rvalue == 'R38'} >R38</RadioButton>
			    <RadioButton name="rvalue" value="R49" checked={input.rvalue == 'R49'} >R49</RadioButton>
			    <RadioButton name="rvalue" value="R60" checked={input.rvalue == 'R60'} >R60</RadioButton>
			  </Fieldset>

			  <H3 font="body" underline>Calculating Costs</H3>

			  <Fieldset margin="16px 0">
			  	<TextInput name="costPerBag" inputType="number" label="Cost Per Bag" touched={true} onChange={inputChange} value={input.costPerBag} />
			  </Fieldset>

			  <Fieldset margin="0 0 16px">
			  	<TextInput name="installers" inputType="number" label="Installers" touched={true} error="" onChange={inputChange} value={input.installers} />
			  </Fieldset>

			  <Fieldset margin="0 0 16px">
			  	<TextInput name="laborCost" inputType="number" label="Labor Cost (per hour)" touched={true} error="" onChange={inputChange} value={input.laborCost} />
			  </Fieldset>

			  <Fieldset margin="0 0 16px">
			  	<TextInput name="markup" inputType="number" label="Desired Markup (%)" touched={true} error="" onChange={inputChange} value={input.markup} />
			  </Fieldset>

			  <Fieldset margin="0 0 16px">
			  	<FormButton buttonType="submit" data-track="form-submit" data-track-form-name="PinkBar vs Steel" data-track-form-type="Calculator" data-track-form-details="[laborCost]">
			  		Calculate Amount
			  	</FormButton>
			  </Fieldset>
			</Form>
		</>
	)
};

export default ProCatForm;
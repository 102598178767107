
import { useRef, useCallback, useEffect } from 'react';
import { jsx, css } from '@emotion/react';
import { throttle, debounce } from 'lodash';

const TextInput = ({ focus, onChange, name, value, label, validated=true, multiline=false, throttled=true, debounced }) => {
  const input = useRef();
  useEffect(() => {
    if (focus && input.current) {
      input.current.focus();
    }
  }, [])
  const onChangeCallback = debounced ? useCallback(debounce(e => onChange(input.current.value), isNaN(parseInt(debounced)) ? 100 : parseInt(debounced), { leading: false }), [])
                         : throttled ? useCallback(throttle(e => onChange(input.current.value), isNaN(parseInt(throttled)) ? 100 : parseInt(throttled), { leading: false }), [])
                                     : useCallback(e => onChange(input.current.value), []);

  const Tag = multiline ? 'textarea' : 'input'

  return (
    <>
      {label && <label>{label}</label>}
      <Tag
        ref={input}
        id={name}
        className="inpt"
        type="text"
        defaultValue={value}
        onChange={onChangeCallback}
        css={css`
          padding: 14px 8px;
          width: 100%;
          color: #585858;
          border: 1px solid #585858;
          font-size: 16px;
          font-family: roboto;
          ${ !validated && css` border-color: #940420; color: #940420; ` }
        `}
      />
    </>
  );
}

export default TextInput;

import PropTypes from 'prop-types';

import { Notes } from '../result-block-styled-components';

const Feature = ({ title, items }) => {
  if (items?.length > 0) {
    return (
      <Notes>
        <strong>{title}:</strong> {items.join(', ')}
      </Notes>
    );
  }
  return null;
};

const Features = ({ features = {} }) => (
  <>
    {Object.entries(features).map(([key, { title, items }]) => (
      <Feature title={title} items={items} key={key} />
    ))}
  </>
);

Features.propTypes = {
  features: PropTypes.object,
};

Feature.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Features;

import styled from "@emotion/styled";
import Theme from "../../../themes";
import IconLink from "./icon-link";

// Styled Components
const PIMLink = styled.span`
  align-items: center;
  background-color: ${Theme.colors.brand};
  color: white;
  display: inline-flex;
  font-family: "Oswald";
  font-size: 10px;
  line-height: 13px;
  padding: 0 2px;
  position: relative;

  svg {
    display: inline-block;
    margin-left: 2px;
  }
`;

const IconPimLink = ({ className }) => (
  <PIMLink className={className}>PIM <IconLink /></PIMLink>
);

export default IconPimLink;

import { define, ui } from '@owenscorning/pcb.alpha';
import ContentAndImage from '../Page/ContentAndImage';
import SealDefendBreathe from '../../../../ComponentLibrary/icons/roofing/SealDefendBreathe';

export default define`Content & Image`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/ContentAndImage`,
  view: ({ value }) => {
    const tprsArea = Board.settings.attributes?.tprs_area;
    const props = _.merge({},
      value,
      {
        content: { prehead: tprsArea ? <SealDefendBreathe display="prehead" name={ tprsArea } /> : '' }
      }
    );
    return <ContentAndImage.modes.view value={ props } />
  },
  edit: ui`Modules/Page/ContentAndImage`({ contentOptions: { prehead: false } })
});

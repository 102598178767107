import { css } from '@emotion/react';
import Themes from './Themes';

export default new Themes(
  {
    Label: css`
      color: #000;
      display: block;
      font-weight: 700;
      margin-bottom: 8px;
    `,
    Container: css`
      margin-bottom: -24px;
    `,
    Horizontal: css`
      display: flex;
      gap: 30px;
      & > div { flex-grow: 1; flex-basis: 0; }
    `,
    Wrapper: css`
      padding-bottom: 24px;
    `
  },
  {
    Standard: {

    },
    Embedded: {
      Container: css`
        background-color: #FFF;
        border: 1px solid #959595;
        border-radius: 8px;
        padding: 16px;
        margin-top: 4px;
        margin-bottom: 0px;
        position: relative;

        div:first-child label {
          padding-top: 0px;
        }
      `
    },
    Builder: {
      Container: css`
        margin-bottom: 0px;
      `,
      Editor: css`
        &:first-child > div {
          border-top: 0;
          & > label {
            padding-top: 30px;
          }
        }
      `,
      Wrapper: css`
        padding-bottom: 40px;
      `,
      NoLabel: css`
        padding-top: 40px;
      `,
      Labels: css`
        border-top: 1px solid #000;
      `,
      Label: css`
        padding: 34px 0 24px;
        font-size: 24px;
        font-weight: bold;
      `
    },
    Settings: {
      Editor: css`
        &:first-child > div {
          border-top: 0;
          padding-top: 4px;
        }
      `,
      Label: css`
        border-top: 1px solid #000;
        padding: 34px 0 24px;
        font-size: 24px;
        font-weight: bold;
      `
    },
    Form: {
      Container: css`
        margin-bottom: 0px;
      `
    },
    Modal: {
      Container: css`
        margin-bottom: 0px;
      `
    }
  }
);

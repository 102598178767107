import displayName from '../helpers/displayName'
import { Provider } from 'react-redux';
import { store } from '../Store'

export default function(WrappedComponent) {
  function wrappedInReduxProvider(props) {
    return (
      <Provider store={store}>
        <WrappedComponent {...props} />
      </Provider>
    )
  }

  wrappedInReduxProvider.displayName = `wrappedInReduxProvider(${displayName(WrappedComponent)})`

  return wrappedInReduxProvider
}

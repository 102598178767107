import getYouTubeID from 'get-youtube-id';
import _ from 'lodash';
import { unwrapRef, wrapRef } from '../../../data';

export const mediaToMediaNew = (media) => {
  const { img, video, alt } = media || {};
  if (img) {
    // old media
    return { type: 'image', resources: { image: { file: img, alt: alt } } };
  } else if (typeof(video) === 'string' && video) {
    // old media
    const youtubeId = getYouTubeID(video, { fuzzy: false });

    return youtubeId || video.indexOf('cloudinary') === -1 ? { type: 'youtube', resources: { youtube: { url: video } } } : { type: 'video', resources: { video: { url: video } } }
  }
  return media;
}

export const imageObjectFromProps = (data, newProp, oldPropImage, oldPropAlt) => {
  const newPropVal = data?.[newProp];
  if (!newPropVal) {
    data = _.merge(_.omit(data||{}, [oldPropImage, oldPropAlt]), { [newProp]: { file: data?.[oldPropImage], alt: data?.[oldPropAlt] } });
  }
  return data;
}

export const appendAspectRatio = (data, fieldName = 'aspectRatio') => {
  if (!data?.settings?.[fieldName]?.selection) {
    data = _.set(_.cloneDeep(data), `settings.${fieldName}.selection`, 'none')
  }
  return data;
}

export const migrateDataSetParameters = (data, dataset, fieldName = 'parameters') => {
  if (dataset && !data?.[fieldName]?.[dataset]) {
    data = _.merge({}, _.omit(data||{}, [fieldName]), { [fieldName]: { [dataset]: data?.[fieldName] } });
  }
  return data;
}

export const migrateArticleDataSetParameters = (contentsData, dataset) => {
  contentsData.parameters = contentsData.parameters || {};
  contentsData.parameters[dataset] = contentsData.parameters[dataset] || {}
  const parameters = contentsData.parameters[dataset];
  if (!parameters?.attributeSet) {
    parameters.showFilters = false
    parameters.limit = false
    if (parameters.source === 'recent') {
      parameters.source = 'all'
      if (parameters.recentCount !== 'All') {
        parameters.limitCount = parseInt(parameters.recentCount)
        parameters.limit = true
      }
    }
    if (parameters.category !== 'All') {
      parameters.source = 'filtered'
      const key = dataset === 'iss' ? "solution_centers" : "categories"
      const primaryKey = dataset === 'iss' ? "primary_solution_center" : "primary_category"
      parameters.filters = parameters.onlyPrimary ? {
        [`metadata.settings.details.taxonomies.${primaryKey}`]: parameters.category
      } : {
        [`metadata.settings.details.taxonomies.${key}`]: [ parameters.category ]
      }
    }
    if (dataset === 'iss') {
      parameters.attributeSet = {
        name: 'ISS Article Attributes',
        path: 'insulation/insulation-solutions-suite/article'
      }
    } else {
      parameters.attributeSet = {
        name: 'Roofing Article Attributes',
        path: 'roofing/blog'
      }
    }
    if (contentsData.reference) {
      const [type, value, data] = unwrapRef(contentsData.reference) || [];
      value.parameters.attributeSet = parameters.attributeSet;
      value.parameters.showFilters = parameters.showFilters
      value.parameters.limit = parameters.limit
      value.parameters.source = parameters.source
      value.parameters.filters = parameters.filters
      contentsData.reference = wrapRef(type, value, data);
    }
  }
  return contentsData;
}

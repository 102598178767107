export default {
  displayName: 'Sticky Nav',
  allowableParents: [],
  migrator: (data) => {
    if (data?.cta?.destination === 'modal' && !data.cta.modalSource) {
      const { cta, ...rest } = data;
      const { modal, ...restCta } = cta;
      data = { ...rest, cta: { ...restCta, modalSource: 'form', form: {
            'PinkNextGenSampleRequest': {
              form_uid: 'next_gen_sample2'
            },
            'VidawoolEmailCollection': {
              form_uid: 'vidawool_email_collection'
            }
          }[modal] } }
    }
    return data;
  },
}

import { Children } from 'react';
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';

const GridContainer = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(${props => props.colMobile}, 1fr);

  @media (min-width: 768px) {
    grid-template-columns: repeat(${props => props.colTablet}, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(${props => props.colDesktop}, 1fr);
  }
`

const Grid = ({ children, selectedSize, selectedSizeTablet, selectedSizeMobile, className }) => {
  let cl = Children.toArray(children).length;
  //console.log(cl);

  let gridSize = () => {
    // Manual override
    if ( selectedSize != null) {
      return selectedSize;
    }

    if (cl === 1 || cl === 5) {
      return 3;
    }
    if (cl >= 2 && cl <= 4) {
      return cl;
    }
    if (cl % 4 === 0) {
      return 4;
    }
    if (cl % 3 === 0) {
      return 3;
    }
    return 4;
  }

  let gridSizeTablet = () => {
    if ( selectedSizeTablet != null) {
      return selectedSizeTablet;
    }

    if (cl === 1 || cl === 5) {
      return 3;
    }
    if (cl % 3 === 0) {
      return 3;
    }
    if (cl % 2 === 0) {
      return 2;
    }
    return 3;
  }

  let gridSizeMobile = () => {
    if ( selectedSizeMobile != null) {
      return selectedSizeMobile;
    }

    if (cl >= 5) {
      return 2;
    }

    if (cl <= 4) {
      return 1;
    }

    return 1;
  }

  return (
    <GridContainer colDesktop={gridSize()} colTablet={gridSizeTablet()} colMobile={gridSizeMobile()} className={className}>
      {children}
    </GridContainer>
  )
}

export default Grid;

import Link from '../../OC/oc-link';
import * as React from "react";
import { css } from "@emotion/react";

export default ({ href, onClick, forwardRef, styles, icon, noTopMargin, ...props }) => {
  return (
    <a
      css={css`
        ${ noTopMargin ? '' : 'margin-top: -18px;'}
        padding-left: ${ icon ? 24 : 0 }px;
        position: relative;
        color: #585858;
        text-decoration: none;
        cursor: pointer;
      `}
      href={onClick ? null : href}
      onClick={onClick ? (e) => onClick?.(e) : null}
      ref={forwardRef}
      {...props}
    >
      <div css={css`
        position: absolute;
        top: 2px;
        left: 1px;
        font-size: 18px;
      `} >
        { icon ? <UI.Icon type={ icon } /> : null }
      </div>
      <span css={css`
        font-size: 12px;
        line-height: 17px;
      `} >
        { contents }
      </span>
    </a>
  );
};

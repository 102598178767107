import _ from 'lodash';
import { connect } from 'react-redux';
import { anchor as stickyNavAnchor } from '../../helpers/sticky_nav';
import { filterEntity, isItemVisible } from '../helpers/content';

// shape of redux cms state:
/*
{
  displayContents: [  // all hidden objects filtered
    {
       __uuid: '1',
       ...
       children: [
         {
           __uuid: '2',
           ...
         }
       ]
    }
  ],
  stickyItems: [{anchor:,heading:}],
}
 */

export const renderConnector = connect(
  (state) => ({
    contentType: state.cms.content.contentType,
    metadata: state.cms.content.metadata,
    displayContents: filterEntity(state.cms.content.contents || [], isItemVisible),
    content_info: state.cms.content_info,
    linkedObject: state.cms.content.linkedObject,
    previousVersions: state.cms.previousVersions,
    zipFile: state.cms.zipFile,
  }),
  null,
  (stateProps, dispatchProps, ownProps) => (
    {
      ...ownProps,
      //stickyItems: (stateProps?.displayContents?.sections||stateProps?.displayContents||[]).filter(v => v.component === 'Section' && v.sticky?.show).map(v => ({ anchor: stickyNavAnchor(v.sticky.name), heading: v.sticky.name })),
      ...stateProps,
      ...dispatchProps
    }
  )
);

import GroupInfoDropdown from '../../../ComponentLibrary/oc-group-info-dropdown';
import RichText from '../RichText';

const ResultHeaderComponent = ({ ...props }) => {
  const { hideHeading } = props;
  if (hideHeading) {
    return null;
  }
  return (
    <GroupInfoDropdown {...props}>
      <RichText content={props.text} />
    </GroupInfoDropdown>
  );
};

export default ResultHeaderComponent;


function toPrecision(num, precision, strip_zeroes) {
	let val = new Number(num).toFixed(precision).toString();
	if (strip_zeroes)
		val = val.replace(/\.$/, '').replace(/\.0+$/, '').replace(/(\.\d*?[1-9])0+$/g, '$1');
	return val;
}

export default function formatNumericRange(min, max, suffix) {
	let formatted = '';
	if (min) {
		formatted += toPrecision(min, 2, true);
		if (max && min != max)
			formatted += '-';
		else
			formatted += suffix || '';
	}
	if (max && min != max)
		formatted += toPrecision(max, 2, true) + (suffix || '');
	return formatted;
}

import { useCallback } from 'react';

import useReference from '../../../../../hooks/use_reference';
import connectStyleBoard from '../../../../../redux/connectors/StyleBoardConnector';
import Text from '../../../../OC/PageBuilder/utilities/Text';
import Card from '../../../../OC/oc-card-new';

const RoofingShingle = connectStyleBoard(
  ({ shingle = {}, index, colorSelected, setColorSelected }) => {
    const item = useReference(shingle.color);
    const { results = {} } = item || {};
    const { data = {} } = results;
    const shingleColor = data.shingle_colors?.length
      ? data.shingle_colors[0]
      : null;

    const cardClassName = useCallback(
      color =>
        (!colorSelected && index === 0) ||
        (colorSelected &&
          colorSelected.results.data.shingle_colors[0].name === color.name)
          ? 'selectedCard'
          : '',
      [colorSelected]
    );

    if (!shingleColor) return null;

    return (
      <Card
        img={shingleColor.swatch_768x768_url}
        aspectRatio={{ selection: '1.0' }}
        onClick={() => setColorSelected(item)}
        className={cardClassName(shingleColor, index)}
      >
        <p className="shingle-name-prehead">
          <Text content={data.full_proper_name} />
        </p>
        <h4 className="h4 body-font">
          <Text content={shingleColor.name} />
        </h4>
      </Card>
    );
  }
);

export default RoofingShingle;

const gwpCalculatorDataEnUS = {
  gwpImpactOverBoardFeetProduct: {
    foamularNgx: 0.800767591,
    foamular: 4.739818541,
    duPontStyrofoam: 9.332912048,
  },
  hospital: {
    boardFeetUsedInProject: 2135956.35,
    reductionTypeOne: 45221558,
    reductionTypeTwo: 6199,
    reductionTypeThree: 301342,
    reductionTypeFour: 2324183946,
  },
  small_hotel: {
    boardFeetUsedInProject: 410832,
    reductionTypeOne: 8697960,
    reductionTypeTwo: 1192,
    reductionTypeThree: 57961,
    reductionTypeFour: 447035893,
  },
  large_hotel: {
    boardFeetUsedInProject: 924448.4,
    reductionTypeOne: 19572027,
    reductionTypeTwo: 2683,
    reductionTypeThree: 130422,
    reductionTypeFour: 1005913846,
  },
  apartment_building: {
    boardFeetUsedInProject: 308721,
    reductionTypeOne: 6536109,
    reductionTypeTwo: 896,
    reductionTypeThree: 43555,
    reductionTypeFour: 335926505,
  },
  small_office: {
    boardFeetUsedInProject: 72105,
    reductionTypeOne: 1526576,
    reductionTypeTwo: 209,
    reductionTypeThree: 10173,
    reductionTypeFour: 78459098,
  },
  medium_office: {
    boardFeetUsedInProject: 430632.84,
    reductionTypeOne: 9117176,
    reductionTypeTwo: 1250,
    reductionTypeThree: 60754,
    reductionTypeFour: 468581696,
  },
  large_office: {
    boardFeetUsedInProject: 2957864.732,
    reductionTypeOne: 62622653,
    reductionTypeTwo: 8584,
    reductionTypeThree: 417298,
    reductionTypeFour: 3218521635,
  },
  elementary_school: {
    boardFeetUsedInProject: 876426,
    reductionTypeOne: 18555318,
    reductionTypeTwo: 2543,
    reductionTypeThree: 123647,
    reductionTypeFour: 953659558,
  },
  high_school: {
    boardFeetUsedInProject: 1904309.61,
    reductionTypeOne: 40317233,
    reductionTypeTwo: 5526,
    reductionTypeThree: 268662,
    reductionTypeFour: 2072123778,
  },
  ten_boards: {
    boardFeetUsedInProject: 640,
    reductionTypeOne: 13551,
    reductionTypeTwo: 2,
    reductionTypeThree: 90,
    reductionTypeFour: 696453,
  },
};

const gwpCalculatorDataEnCA = {
  gwpImpactOverBoardFeetProduct: {
    foamularNgx: 0.800767591,
    foamular: 4.739818541,
    duPontStyrofoam: 5.548459951,
  },
  hospital: {
    boardFeetUsedInProject: 2135956.35,
    reductionTypeOne: 3107,
    reductionTypeTwo: 4320070,
    reductionTypeThree: 6780,
    reductionTypeFour: 2375,
  },
  small_hotel: {
    boardFeetUsedInProject: 410832,
    reductionTypeOne: 598,
    reductionTypeTwo: 830927,
    reductionTypeThree: 1304,
    reductionTypeFour: 457,
  },
  large_hotel: {
    boardFeetUsedInProject: 924448.4,
    reductionTypeOne: 1345,
    reductionTypeTwo: 1869739,
    reductionTypeThree: 2935,
    reductionTypeFour: 1028,
  },
  apartment_building: {
    boardFeetUsedInProject: 308721,
    reductionTypeOne: 449,
    reductionTypeTwo: 624402,
    reductionTypeThree: 980,
    reductionTypeFour: 343,
  },
  small_office: {
    boardFeetUsedInProject: 72105,
    reductionTypeOne: 105,
    reductionTypeTwo: 145836,
    reductionTypeThree: 229,
    reductionTypeFour: 80.2,
  },
  medium_office: {
    boardFeetUsedInProject: 430632.84,
    reductionTypeOne: 626,
    reductionTypeTwo: 870975,
    reductionTypeThree: 1367,
    reductionTypeFour: 479,
  },
  large_office: {
    boardFeetUsedInProject: 2957864.732,
    reductionTypeOne: 4302,
    reductionTypeTwo: 5982417,
    reductionTypeThree: 9309,
    reductionTypeFour: 3289,
  },
  elementary_school: {
    boardFeetUsedInProject: 876426,
    reductionTypeOne: 1275,
    reductionTypeTwo: 1772612,
    reductionTypeThree: 2782,
    reductionTypeFour: 974,
  },
  high_school: {
    boardFeetUsedInProject: 1904309.61,
    reductionTypeOne: 2770,
    reductionTypeTwo: 3851822,
    reductionTypeThree: 6046,
    reductionTypeFour: 2117,
  },
  ten_boards: {
    boardFeetUsedInProject: 640,
    reductionTypeOne: 0.931,
    reductionTypeTwo: 1294,
    reductionTypeThree: 2,
    reductionTypeFour: 0.712,
  },
};

const gwpCalculatorDataFrCA = gwpCalculatorDataEnCA;

export const gwpCalculatorData = {
  'en-US': gwpCalculatorDataEnUS,
  'en-CA': gwpCalculatorDataEnCA,
  'fr-CA': gwpCalculatorDataFrCA,
};

//https://stackoverflow.com/a/6234804
export default function escapeHtml(unsafe) {
  return unsafe ? unsafe
    .replace(/&/g, "&amp;")
    .replace(/ /g, "&nbsp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;") : unsafe;
}

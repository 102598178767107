import { Component, useState } from "react";
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import Cta from "../oc-cta";
import Image from "../oc-image";

const HeaderRow = styled.tr`
    text-align: left;
    width: 20%;
    min-width: 115px;
    font-weight: 700;
    border-bottom: 2px solid #BCBCBC;
    border-top: 2px solid #BCBCBC;
    font-size: 20px;
    text-transform: uppercase;
`;

const Offset = styled.tr`
    background-color: #f8f8f8;
    border-top: 1px solid #bcbcbc;
    border-bottom: 1px solid #bcbcbc;
    padding: 12px 8px 7px 8px;
    text-align: center;
    width: 20%;
    min-width: 115px;
`;

const DisplayTable = styled.table`
  border-collapse: collapse;
  font-size: 14px;
  overflow-x: auto;
  display: block;

  thead th {
    padding: 20px 15px;
    font-size: 30px
  }

  tbody td[scope="colspan"] {
    background-color: #e6e6e6;
    text-align: left;
    font-weight: 700;
  }

  td, th {
    padding: 12px 8px 7px 8px;
    text-align: center;
    width: 20%;
    min-width: 115px;
  }

   thead th:first-of-type {
    text-align: left;
  }

  tr > * {
    border-left: 1px solid #bcbcbc;
  }

  tr > :first-of-type {
    border-left: 0;
    text-align:left;
    width: 35%;
    min-width: 115px;
  }

`;

class ContractorNetworkTableES extends Component {
  constructor(props) {
    super(props);
    this.modals = {
        preferred: {
          buttons: [],
          title: "ACTUALIZACIÓN DE LA MEMBRESÍA DE SU PROGRAMA",
          body: () => (
            <>
              <p>La inscripción en la Red de Contratistas Preferidos de Owens Corning Roofing es solo por invitación. Una vez inscritos como miembros de Contractor Rewards, los contratistas que cumplan con los siguientes requisitos de elegibilidad pueden ser invitados por su Gerente de Ventas del Área de Techos de Owens Corning local a convertirse en un Contratista Preferido:</p>
              <ul>
                <li>Llevar al menos $1,000,000 en seguro de responsabilidad civil general</li>
                <li>Han sido evaluados para la estabilidad financiera y el servicio al cliente</li>
              </ul>
            </>
          )
        },
        platinum: {
          buttons: [],
          title: "ACTUALIZACIÓN DE LA MEMBRESÍA DE SU PROGRAMA",
          body: () => (
            <>
              <p>La inscripción en la Red de Contratistas Platino de Owens Corning Roofing es solo por invitación. Como Contratista Platino, los contratistas que cumplan con los siguientes requisitos de elegibilidad pueden ser invitados por su Gerente de Ventas del Área de Techos Owens Corning local a convertirse en contratistas Platino:</p>
              <ul>
                <li>Pasó una inspección de mano de obra de instalación</li>
                <li>Llevar al menos $1,000,000 en seguro de responsabilidad civil general</li>
                <li>Han sido evaluados para la estabilidad financiera y el servicio al cliente</li>
              </ul>
            </>
          )
        }
    }
  }


  render() {

    return (

       <DisplayTable>
         <thead>
         <tr>
           <th>Niveles</th>
           <th>
             <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,q_auto,w_160/v1647436874/roofing/badges/contractor-rewards-spanish.png" alt="Owens Corning Contractor Rewards" style={{width: 160, margin: 10}}/>
             <Cta href = "https://www.owenscorning.com/en-us/roofing/rewards-program/enroll" analytics = {{track: "learn-more", destination: "Rewards Program Enroll"}} >Únase Hoy</Cta>
           </th>
           <th>
             <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,q_auto,w_130/v1643741530/roofing/badges/preferred-contractor-spanish.png" alt="Owens Corning Preferred Contractor" style={{width: 130, margin: 10}}/>
             <Cta  onKeyPress={() => Board.modal.open(this.modals.preferred)} tabindex = "0" color = "white" onClick = {() =>  Board.modal.open(this.modals.preferred)} analytics = {{track: "learn-more", destination: "Preferred Pop UP"}} >Consulte Hoy</Cta>
           </th>
           <th>
             <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,q_auto,w_195/v1643741530/roofing/badges/platinum-contractor-spanish.png" alt="Owens Corning Platinum Contractor" style={{width: 195, margin: 10}}/>
             <Cta onKeyPress = {() => Board.modal.open(this.modals.platinum)} tabindex = "0" color = "white" onClick = {() => Board.modal.open(this.modals.platinum)} analytics = {{track: "learn-more", destination: "Preferred Pop UP"}} >Consulte Hoy</Cta>
           </th>
         </tr>
         </thead>

         <tbody>
         <HeaderRow>
           <td><h4>Recompensas</h4></td>
           <td></td>
           <td></td>
           <td></td>
         </HeaderRow>
          <tr>
            <td>Obtenga recompensas con las compras de Owens Corning</td>
            <td><i className="fa fa-check" aria-hidden="true"></i></td>
            <td><i className="fa fa-check" aria-hidden="true"></i></td>
            <td><i className="fa fa-check" aria-hidden="true"></i></td>
          </tr>
         <Offset>
           <td>Acceso a descuentos en productos de temporada</td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
         <HeaderRow>
           <td>Marketing</td>
           <td></td>
           <td></td>
           <td></td>
         </HeaderRow>
         <tr>
           <td>Pedidos en línea de información sobre el producto y muestras</td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <Offset>
           <td>Acceso a productos promocionales de marca Owens Corning</td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
         <tr>
           <td>Actualizaciones de noticias por mensaje de texto</td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <Offset>
           <td>Boletines informativos electrónicos mensuales sobre los puntos destacados de Owens Corning Roofing con las noticias más recientes</td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
         <tr>
           <td>Acceso al servicio integral de marketing con el que se aprovecha la fuerte presencia de las marcas Owens Corning y Pink Panther™</td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <Offset>
           <td>Lista de contratistas en owenscorning.com</td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
          <HeaderRow>
          <td>Educación</td>
            <td></td>
            <td></td>
            <td></td>
          </HeaderRow>
         <tr>
           <td>Acceso a Owens Corning University</td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <Offset>
           <td>Capacitación en persona con el experto en ventas Rodney Webb y otros presentadores notables</td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
         <tr>
           <td>Acceso a capacitación para contratistas a través del Centro de Recursos OCConnect™ con la NRCA</td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <HeaderRow>
          <td>Ofertas Exclusivas</td>
           <td></td>
           <td></td>
           <td></td>
         </HeaderRow>
         <tr>
           <td>Capacidad de ofrecer financiación para consumidores con tarifas negociadas a través de Service Finance Company</td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <Offset>
           <td>Oportunidades de generación de clientes potenciales con ofertas de servicios comerciales clave</td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
         <tr>
          <td>Capacidad de ofrecer la Garantía Limitada de Protección de Sistemas</td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <Offset>
           <td>Oportunidades de generación de clientes potenciales con el Sistema de Gestión de Clientes Potenciales de Owens Corning Roofing</td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
         <tr>
           <td>Capacidad de ofrecer la Garantía Limitada de Protección de Sistemas de Techos Preferencial</td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <Offset>
           <td>Recursos de generación de negocios para hacer crecer su empresa</td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
         <tr>
           <td>Capacidad exclusiva de ofrecer la Garantía Limitada de Protección de Sistemas de Techos Platinum</td>
           <td></td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <Offset>
           <td>Lista de contratistas prioritarios en owenscorning.com</td>
           <td></td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
         <tr>
           <td>Oportunidad de asistir a la conferencia nivel Platinum</td>
           <td></td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <Offset>
           <td>Elegible para Certificación Solar PROtect™</td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
         <HeaderRow>
           <td>Requisitos Mínimos Para La Membresía</td>
           <td></td>
           <td></td>
           <td></td>
         </HeaderRow>
         <tr>
           <td>Vender Owens Corning techos y accesorios para techos</td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <Offset>
           <td>Formar parte de la Red de contratistas de Owens Corning</td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
         <tr>
           <td>Disponer de un seguro de responsabilidad general de al menos USD 1 000 000</td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <Offset>
           <td>Contar con licencias y otros seguros obligatorios según las leyes locales, estatales o federales</td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
         <tr>
           <td>Contar con una clasificación crediticia suficiente para Owens Corning y sus entidades afiliadas</td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <Offset>
           <td>Contar con una inspección de mano de obra de instalación aprobada</td>
           <td></td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
         <tr>
           <td></td>
           <td>
             <Cta href = "https://www.owenscorning.com/en-us/roofing/rewards-program/enroll" analytics = {{track: "learn-more", destination: "Rewards Program Enroll"}} >Únase Hoy</Cta>
           </td>
           <td>
             <Cta onKeyPress = {() => Board.modal.open(this.modals.preferred)} tabindex = "0" color= "white" onClick = {() => Board.modal.open(this.modals.preferred)} analytics = {{track: "learn-more", destination: "Preferred Pop UP"}} >Consulte Hoy</Cta>
           </td>
           <td>
             <Cta onKeyPress = {() => Board.modal.open(this.modals.platinum)} tabindex = "0" color = "white" onClick = {() => Board.modal.open(this.modals.platinum)} analytics = {{track: "learn-more", destination: "Preferred Pop UP"}} >Consulte Hoy</Cta>
           </td>
         </tr>
         </tbody>

       </DisplayTable>
    );
  };
};

export default ContractorNetworkTableES;

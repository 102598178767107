import { css } from '@emotion/react';
import styled from '@emotion/styled';

const Spacer = styled.span`
  flex-grow: 1;
  max-width: 18px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    background-color: #D1D1D1;
  }
`;

const Detail = styled.span`
  font-size: 12px;
  vertical-align: top;
  padding-left: 1px;
`;

const MenuTool = styled.span`
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  margin: 3px 0px;
  padding: 3px 4px;
  position: relative;
  user-select: none;

  &:hover {
    background-color: #E6E6E6;
  }
`;

const ImageIcon = styled.div`
  width: 100%;
  height: 22px;
  background-image: url(${ (props) => props.icon });
  background-position: center;
  background-size: contain;
`;

const textColorIcon = (UI) => {
  let icon = UI.Theme.icons?.tools?.textColor || "Pink";
  return UI.Images.Tools[icon];
};

const Toolbar = ({ identifier, tools, bare }) => (
  <div
    id={ `toolbar-${ identifier }` }
    css={ bare ? css` display: flex; ` : css`
      position: sticky;
      top: 57px;
      z-index: 999;
      display: flex;
      background-color: #FFF;
      border: 1px solid #585858;
      padding: 0px 5px;
    ` }
  >
    { tools.map((tool) => Toolbar.Tools(UI)[tool]) }
  </div>
);

Toolbar.Tool = ({ action, type, title, children }) => (
  <button
    className={ `ql-${ action }` }
    value={ type }
    title={title}
    css={css`
      display: inline-block;
      color: #000;
      width: auto;
      height: 31px;
      margin: 0 1px;
      line-height: 31px;
      cursor: pointer;
      min-width: 26px;
      text-align: center;
      position: relative;

      * {
        pointer-events: none;
        user-select: none;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 0px;
        background-color: #585858;
        transition: height 0.2s;
        opacity: 0.6;
      }

      &:hover {
        &:after {
          height: 2px;
        }
      }

      &.ql-active:after {
        opacity: 1.0;
        height: 2px;
      }
    `} >
    <div>{ children }</div>
  </button>
);

Toolbar.Tools = (UI) => ({
  spacer: <Spacer />,
  styles: (
    <UI.Belt
      hides
      openCSS={css` background-color: #585858; `}
      beltCSS={css`
        top: 28px;
        right: auto;
        left: -7px;
      `}
    >
      <MenuTool>
        <img css={{ height: 12 }} src={ UI.Images.Tools.Styles } /> <UI.Icon type="angle-down" />
      </MenuTool>
      <div>
        {
          _.map({
            clean: <div css={css` font-size: 16px; `} >Body Copy</div>,
            'header_two_oswald': <h2>H2 Oswald</h2>,
            'header_two_roboto': <h2 className="body-font" >H2 Roboto</h2>,
            'header_three_oswald': <h3>H3 Oswald</h3>,
            'header_three_roboto': <h3 className="body-font" >H3 Roboto</h3>,
            'header_four_roboto': <h4>H4 Roboto</h4>,
            'header_five_roboto': <h5>H5 Roboto</h5>,
            'header_six_roboto': <h6>H6 Roboto</h6>,
            smaller: <div className="smaller" >Small Body Copy</div>,
            disclaimer: <div className="disclaimer" >Disclaimer</div>
          }, (label, name) => (
            <UI.Belt.Action styling={css`
              * {
                display: block;
                margin: 0 !important;
              }

              button {
                padding: 7px 11px !important;
                cursor: pointer;
                text-align: left;
                width: 100%;
              }
            `} >
              <button className={ `ql-${ name }` } >{ label }</button>
            </UI.Belt.Action>
          ))
        }
      </div>
    </UI.Belt>
  ),
  bold: <Toolbar.Tool action="bold" title="Bold"><UI.Icon type="bold" /></Toolbar.Tool>,
  italic: <Toolbar.Tool action="italic" title="Italic"><UI.Icon type="italic" /></Toolbar.Tool>,
  superscript: <Toolbar.Tool action="script" type="super" title="Super Script"><UI.Icon type="superscript" /></Toolbar.Tool>,
  subscript: <Toolbar.Tool action="script" type="sub" title="Sub Script"><UI.Icon type="subscript" /></Toolbar.Tool>,
  pink: <Toolbar.Tool action="pink" title="Brand Color"><ImageIcon icon={ textColorIcon(UI) } /></Toolbar.Tool>,
  lists: (
    <UI.Belt
      beltCSS={css`
        left: -7px;
        right: auto;
        top: 28px;
      `}
      hides
      openCSS={css`
        background-color: #585858;
      `}
    >
      <MenuTool>
        <UI.Icon type="list-ul" />
      </MenuTool>
      <div>
        {_.map([
          { action: "list", type: "bullet", title: "Unordered List", label: <><UI.Icon type="list-ul" /> Unordered</> },
          { action: "list", type: "ordered", title: "Ordered List", label: <><UI.Icon type="list-ol" /> Ordered</> },
          { action: "indent", type: "+1", title: "Indent", label: <><UI.Icon type="indent" /> Indent</> },
          { action: "indent", type: "-1", title: "Unindent", label: <><UI.Icon type="outdent" /> Unindent</> }
        ], (object) => (
          <UI.Belt.Action styling={css`
            button {
              align-items: center;
              cursor: pointer;
              display: flex;
              justify-content: flex-start;
              padding: 6px 8px;
              width: 100%;
            }
          `}>
            <Toolbar.Tool action={object.action} type={object.type} title={object.title}>{object.label}</Toolbar.Tool>
          </UI.Belt.Action>
        ))}
      </div>
    </UI.Belt>
  ),
  link: <Toolbar.Tool action="link" title="Link"><ImageIcon icon={ UI.Images.Tools.Link } /></Toolbar.Tool>,
  document: <Toolbar.Tool action="document" title="Document"><ImageIcon icon={ UI.Images.Tools.Document } /></Toolbar.Tool>
});

export default Toolbar;

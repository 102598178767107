import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Theme from '../../themes';

// Components
import { connectLocation } from '../location/LocationConnectors';
import { H3, H5 } from './oc-h';
import Container from './oc-container';
import Cta from './PageBuilder/Cta';

// Styled Components
const NewsContainer = styled.div`
  display: block;
  padding: 0;
  margin-bottom: 35px;

  @media screen and (min-width: 768px),
  screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
    margin-bottom: 70px;
  }

  .recent-news-heading-grid {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;

    @media screen and (min-width: 768px) {
      margin-bottom: 15px;
    }

    @media screen and (min-width: 1200px) {
      margin-bottom: 35px;
    }

    .news-heading {
      margin-bottom: 0;
    }

    .more-news-container {
      display: inline-flex;
      flex-grow: 1;
      justify-content: flex-end;
    }

    .more-news {
      color: black;
      font-size: 14px;
      line-height: 14px;
      margin-right: 15px;
      text-decoration: none;

      @media screen and (min-width: 1200px),
      screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
        font-size: 18px;
        line-height: 18px;
      }

      .react-chevron-right {
        font-size: 15px;
        margin-left: 10px;
        text-decoration: none;
      }

      &:hover {
        color: ${Theme.colors.brand};
        text-decoration: underline;

        &:after {
          text-decoration: none;
        }
      }
    }
  }
`;

const newsItemsGridStyles = css`
  display: none;

  @supports (display: contents) {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 30px;
    grid-row-gap: 16px;
    grid-template-columns: repeat(3, 100%);
    grid-template-rows: auto auto auto auto;
    margin: 0 auto;
    max-width: 100%;

    @media (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (min-width: 1200px) {
      grid-row-gap: 24px;
    }
  }
`;

const newsItemStyles = css`
  color: black;
  display: block;
  flex: 0 0 290px;
  font-size: 14px;
  margin: 20px 0;
  position: relative;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  @media screen and (min-width: 768px) {
    flex: 1;
    margin: 15px;

    @supports (display: contents) {
      display: contents;
    }
  }

  @media screen and (min-width: 1200px), screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    font-size: 16px;
    margin: 35px 15px;
  }

  .news-item-prehead {
    border-bottom: 1px solid black;
    border-top: 2px solid black;
    display: block;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 8px;
    padding: 15px 0;
    width: 100%;

    @media screen and (min-width: 1200px), screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      font-size: 21px;
      line-height: 21px;
      padding: 20px 0;
    }
  }

  a {
    justify-self: start;
  }

  p {
    margin: 0;
  }
`;

const NewsItem = ({ prehead, heading, text, cta }) => (
  <div css={newsItemStyles}>
    <span className="news-item-prehead">{prehead}</span>
    <H3>{heading}</H3>
    <p>{text}</p>
    <Cta {...cta} />
  </div>
);

const News = ({ cta, ctaText, ctaUrl, news, newsHeading }) => {
  return (
    <Container>
      <NewsContainer>
        <div className="recent-news-heading-grid">
          {newsHeading?.display &&
            <H5 className="news-heading" tag="h2">{newsHeading?.text}</H5>
          }

          {cta && (
            <div className="more-news-container">
              <a
                href={ctaUrl}
                className="more-news"
                data-track="view-more"
                data-track-content="More News"
              >
                {ctaText}
                <span className="react-chevron-right"></span>
              </a>
            </div>
          )}
        </div>

        <div css={newsItemsGridStyles}>
          {news ? (
            <>
              {news.map((item, index) => (
                <NewsItem
                  key={index}
                  prehead={item.prehead}
                  heading={item.heading}
                  text={item.text}
                  cta={item.cta}
                />
              ))}
            </>
          ) : (
            <p>There are no news items yet.</p>
          )}
        </div>
      </NewsContainer>
    </Container>
  );
};

export default News;

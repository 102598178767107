import { jsx } from '@emotion/react';
import styled from '@emotion/styled';

const InputUnorderedList = styled.ul`
  list-style: none;
  margin: 0;
  padding-left: 0px;

  li {
    margin-bottom: 10px;

    ${props => props.inline && `
      display: inline-block;
      margin-right: 4em;
    `}
  }

  li:last-of-type {
    margin-bottom: 0;
  }
`;

const InputList = ({ children, inline=false }) => (
  <InputUnorderedList inline={inline}>
    { children.map((child, index) => <li key={index}>{ child }</li> ) }
  </InputUnorderedList>
);

export default InputList;

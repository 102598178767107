export default {
  multiplier: 8,
  colors: {
    brand: '#D40F7D',
    black: '#000',
    white: '#FFF',
    red: '#CE1126',
    redShade1: '#940420',
    redShade2: '#660014',
    redShade3: '#F7DBDB',
    grey: '#333',
    greyTint1: '#D1D1D1',
    greyTint2: '#BCBCBC',
    greyTint3: '#A8A8A8',
    greyShade1: '#6D6D6D',
    greyShade2: '#585858',
    greyShade3: '#595959',
    greyBack: '#E6E6E6',
    greyBackWeb: '#F8F8F8',
    blue: '#009ACA',
    blueTint1: '#B9EEFF',
    blueTint2: '#78DDFF',
    blueTint3: '#3DCDFF',
    blueShade1: '#00688C',
    blueShade2: '#00445A',
    green: '#3C9748',
    greenTint1: '#CFF3DC',
    greenTint2: '#A2E8B9',
    greenTint3: '#7CDD98',
    greenShade1: '#336C40',
    greenShade2: '#21482A',
    published: '#085D0F',
    warning: '#F1C40F',
  },
  foreground: {
    default: 'black',
    brand: 'white',
    red: 'white',
    black: 'white',
    grey: 'white',
    greyShade1: 'white',
    greyShade2: 'white',
    blue: 'white',
    blueShade1: 'white',
    blueShade2: 'white',
    green: 'white',
    greenShade1: 'white',
    greenShade2: 'white'
  },
  fonts: {
    heading: 'Oswald, sans-serif',
    body: 'OCRoboto, sans-serif'
  },
  icons: { tools: { textColor: 'Pink' } },
  intent: {
    interactive: ` cursor-pointer unselectable `,
    draggable: ` interactive cursor-grab `,
    dragging: ` interactive cursor-grabbing `,
    editable: ` cursor-text `
  },
  global: `
    font-body weight-400 size-16 line-18
    tablet size-14
  `,
  copy: {
    small: `
      font-body weight-400 size-14 line-18
    `,
    disclaimer: `
      font-body weight-400 size-12 line-17
    `
  },
  paragraph: ` body margin-2-0 `,
  headings: {
    Prehead: {
      T1: `
        font-heading size-32 line-34 weight-600 uppercase margin-0-0-1 greyShade1
        tablet size-21 line-21 weight-700
        mobile size-14 line-14
      `,
      T2: `
        font-heading size-32 line-32 weight-600 uppercase margin-0-0-2 greyShade1
        tablet size-21 line-21 weight-700
        mobile size-14 line-14
      `,
      T3: `
        font-body size-16 line-18 weight-400 margin-0-0-1 grey
        tablet size-14 line-18
      `,
      T4: `
        font-body size-13 line-15 weight-500 margin-0-0-1 grey
      `
    },
    Posthead: `
      font-heading size-18 line-21 weight-400 uppercase margin-2-0-3 greyShade1
      tablet size-16 line-18
    `,
    H1: {
      T1: `
        font-heading size-98 line-98 weight-600 uppercase margin-0-0-3
        tablet size-60 line-60
        mobile size-36 line-36
      `,
      T2: `
        font-heading size-45 line-48 weight-600 uppercase margin-0-0-3
        tablet size-45 line-45
        mobile size-36 line-36
      `,
      T3: `
        font-heading size-45 line-48 weight-600 uppercase margin-0-0-3
        tablet size-36 line-39
        mobile size-36 line-39
      `
    },
    H2: {
      T1: `
        font-heading size-40 line-42 weight-500 uppercase margin-0-0-3
        tablet size-32 line-35
      `,
      T2: `
        font-body size-24 line-26 weight-500 margin-0-0-2
        tablet size-21 line-24
      `
    },
    H3: {
      T1: `
        font-heading size-28 line-30 weight-600 uppercase margin-0-0-3
        tablet size-24 line-26
      `,
      T2: `
        font-body size-21 line-23 weight-700 margin-0-0-2
        tablet size-18 line-22
      `
    },
    H4: `
      font-body size-18 line-21 weight-700 margin-0-0-2
      tablet size-16 line-18
    `,
    H5: `
      font-body size-18 line-20 weight-400 uppercase margin-0-0-2 greyShade1
      tablet size-16 line-18
    `,
    H6: `
      font-body size-16 line-18 weight-700 uppercase margin-0-0-2
    `
  }
};

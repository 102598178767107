import { ui, define } from '@owenscorning/pcb.alpha';
import styled from '@emotion/styled';
import Theme from '../../../themes';
import _ from 'lodash';

const Tag = styled.a`
  color: inherit;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: ${Theme.colors.brand};
  }

  svg {
    fill: currentColor;
  }
`;

export default define`Button`('0.0.1')({
  view: ({ action, analytics, popup, parent, text, children, ...props }) => (
    <Tag
      href={ _.isString(action) ? action : undefined }
      onClick={
        _.isFunction(action) && ((event) => {
          const result = action(event);
          if (_.isString(result)) window.open(result, popup ? '_blank' : '_self').focus();
        })
      }
      analytics={ analytics }
      target={ _.isString(action) && popup ? '_blank' : undefined }
      { ...props }
    >
      { text }
      { children }
    </Tag>
  ),
  edit: ui`Text`
});

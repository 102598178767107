import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';

import { H3 } from '../../../ComponentLibrary/text-elements/oc-h';

const Container = styled.div`
  margin-top: 30px;
`;

const DescriptionWrapper = styled.div`
  > p {
    font-size: 14px;
  }
`;

const ComplianceWrapper = styled.div`
  margin-top: 20px;

  > p,
  li {
    font-size: 14px;
  }
`;

function SheetOverview({ description, compliance = {} }) {
  return (
    <Container>
      {description &&
      description.paragraphs &&
      description.paragraphs.length > 0 ? (
        <DescriptionWrapper>
          <H3>Description</H3>
          {description.paragraphs.map(paragraphText => (
            <p key={paragraphText}>{paragraphText}</p>
          ))}
        </DescriptionWrapper>
      ) : null}
      <ComplianceWrapper>
        <H3>Compliance</H3>
        {compliance.description ? <p>{compliance.description}</p> : null}
        {compliance.standards && compliance.standards.length > 0 ? (
          <ul>
            {compliance.standards.map(standard => (
              <li key={standard}>{standard}</li>
            ))}
          </ul>
        ) : null}
        {compliance.addendum ? <p>{compliance.addendum}</p> : null}
      </ComplianceWrapper>
    </Container>
  );
}

SheetOverview.propTypes = {
  description: PropTypes.shape({
    paragraphs: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  compliance: PropTypes.shape({
    description: PropTypes.string,
    standards: PropTypes.arrayOf(PropTypes.string),
  }),
};

SheetOverview.defaultProps = {
  compliance: {},
};

export default SheetOverview;

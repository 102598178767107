export const CountryAndStates = [
  {
    id: '231',
    sortname: 'US',
    label: 'United States',
    states: [
      {
        id: '3919',
        label: 'Alabama',
        country_id: '231',
      },
      {
        id: '3920',
        label: 'Alaska',
        country_id: '231',
      },
      {
        id: '3921',
        label: 'Arizona',
        country_id: '231',
      },
      {
        id: '3922',
        label: 'Arkansas',
        country_id: '231',
      },
      {
        id: '3923',
        label: 'Byram',
        country_id: '231',
      },
      {
        id: '3924',
        label: 'California',
        country_id: '231',
      },
      {
        id: '3925',
        label: 'Cokato',
        country_id: '231',
      },
      {
        id: '3926',
        label: 'Colorado',
        country_id: '231',
      },
      {
        id: '3927',
        label: 'Connecticut',
        country_id: '231',
      },
      {
        id: '3928',
        label: 'Delaware',
        country_id: '231',
      },
      {
        id: '3929',
        label: 'District of Columbia',
        country_id: '231',
      },
      {
        id: '3930',
        label: 'Florida',
        country_id: '231',
      },
      {
        id: '3931',
        label: 'Georgia',
        country_id: '231',
      },
      {
        id: '3932',
        label: 'Hawaii',
        country_id: '231',
      },
      {
        id: '3933',
        label: 'Idaho',
        country_id: '231',
      },
      {
        id: '3934',
        label: 'Illinois',
        country_id: '231',
      },
      {
        id: '3935',
        label: 'Indiana',
        country_id: '231',
      },
      {
        id: '3936',
        label: 'Iowa',
        country_id: '231',
      },
      {
        id: '3937',
        label: 'Kansas',
        country_id: '231',
      },
      {
        id: '3938',
        label: 'Kentucky',
        country_id: '231',
      },
      {
        id: '3939',
        label: 'Louisiana',
        country_id: '231',
      },
      {
        id: '3940',
        label: 'Lowa',
        country_id: '231',
      },
      {
        id: '3941',
        label: 'Maine',
        country_id: '231',
      },
      {
        id: '3942',
        label: 'Maryland',
        country_id: '231',
      },
      {
        id: '3943',
        label: 'Massachusetts',
        country_id: '231',
      },
      {
        id: '3944',
        label: 'Medfield',
        country_id: '231',
      },
      {
        id: '3945',
        label: 'Michigan',
        country_id: '231',
      },
      {
        id: '3946',
        label: 'Minnesota',
        country_id: '231',
      },
      {
        id: '3947',
        label: 'Mississippi',
        country_id: '231',
      },
      {
        id: '3948',
        label: 'Missouri',
        country_id: '231',
      },
      {
        id: '3949',
        label: 'Montana',
        country_id: '231',
      },
      {
        id: '3950',
        label: 'Nebraska',
        country_id: '231',
      },
      {
        id: '3951',
        label: 'Nevada',
        country_id: '231',
      },
      {
        id: '3952',
        label: 'New Hampshire',
        country_id: '231',
      },
      {
        id: '3953',
        label: 'New Jersey',
        country_id: '231',
      },
      {
        id: '3954',
        label: 'New Jersy',
        country_id: '231',
      },
      {
        id: '3955',
        label: 'New Mexico',
        country_id: '231',
      },
      {
        id: '3956',
        label: 'New York',
        country_id: '231',
      },
      {
        id: '3957',
        label: 'North Carolina',
        country_id: '231',
      },
      {
        id: '3958',
        label: 'North Dakota',
        country_id: '231',
      },
      {
        id: '3959',
        label: 'Ohio',
        country_id: '231',
      },
      {
        id: '3960',
        label: 'Oklahoma',
        country_id: '231',
      },
      {
        id: '3961',
        label: 'Ontario',
        country_id: '231',
      },
      {
        id: '3962',
        label: 'Oregon',
        country_id: '231',
      },
      {
        id: '3963',
        label: 'Pennsylvania',
        country_id: '231',
      },
      {
        id: '3964',
        label: 'Ramey',
        country_id: '231',
      },
      {
        id: '3965',
        label: 'Rhode Island',
        country_id: '231',
      },
      {
        id: '3966',
        label: 'South Carolina',
        country_id: '231',
      },
      {
        id: '3967',
        label: 'South Dakota',
        country_id: '231',
      },
      {
        id: '3968',
        label: 'Sublimity',
        country_id: '231',
      },
      {
        id: '3969',
        label: 'Tennessee',
        country_id: '231',
      },
      {
        id: '3970',
        label: 'Texas',
        country_id: '231',
      },
      {
        id: '3971',
        label: 'Trimble',
        country_id: '231',
      },
      {
        id: '3972',
        label: 'Utah',
        country_id: '231',
      },
      {
        id: '3973',
        label: 'Vermont',
        country_id: '231',
      },
      {
        id: '3974',
        label: 'Virginia',
        country_id: '231',
      },
      {
        id: '3975',
        label: 'Washington',
        country_id: '231',
      },
      {
        id: '3976',
        label: 'West Virginia',
        country_id: '231',
      },
      {
        id: '3977',
        label: 'Wisconsin',
        country_id: '231',
      },
      {
        id: '3978',
        label: 'Wyoming',
        country_id: '231',
      },
    ],
  },
  {
    id: '38',
    sortname: 'CA',
    label: 'Canada',
    states: [
      {
        id: '663',
        label: 'Alberta',
        country_id: '38',
      },
      {
        id: '664',
        label: 'British Columbia',
        country_id: '38',
      },
      {
        id: '665',
        label: 'Manitoba',
        country_id: '38',
      },
      {
        id: '666',
        label: 'New Brunswick',
        country_id: '38',
      },
      {
        id: '667',
        label: 'Newfoundland and Labrador',
        country_id: '38',
      },
      {
        id: '668',
        label: 'Northwest Territories',
        country_id: '38',
      },
      {
        id: '669',
        label: 'Nova Scotia',
        country_id: '38',
      },
      {
        id: '670',
        label: 'Nunavut',
        country_id: '38',
      },
      {
        id: '671',
        label: 'Ontario',
        country_id: '38',
      },
      {
        id: '672',
        label: 'Prince Edward Island',
        country_id: '38',
      },
      {
        id: '673',
        label: 'Quebec',
        country_id: '38',
      },
      {
        id: '674',
        label: 'Saskatchewan',
        country_id: '38',
      },
      {
        id: '675',
        label: 'Yukon',
        country_id: '38',
      },
    ],
  },
  {
    id: '1',
    sortname: 'AF',
    label: 'Afghanistan',
    states: [
      {
        id: '42',
        label: 'Badakhshan',
        country_id: '1',
      },
      {
        id: '43',
        label: 'Badgis',
        country_id: '1',
      },
      {
        id: '44',
        label: 'Baglan',
        country_id: '1',
      },
      {
        id: '45',
        label: 'Balkh',
        country_id: '1',
      },
      {
        id: '46',
        label: 'Bamiyan',
        country_id: '1',
      },
      {
        id: '47',
        label: 'Farah',
        country_id: '1',
      },
      {
        id: '48',
        label: 'Faryab',
        country_id: '1',
      },
      {
        id: '49',
        label: 'Gawr',
        country_id: '1',
      },
      {
        id: '50',
        label: 'Gazni',
        country_id: '1',
      },
      {
        id: '51',
        label: 'Herat',
        country_id: '1',
      },
      {
        id: '52',
        label: 'Hilmand',
        country_id: '1',
      },
      {
        id: '53',
        label: 'Jawzjan',
        country_id: '1',
      },
      {
        id: '54',
        label: 'Kabul',
        country_id: '1',
      },
      {
        id: '55',
        label: 'Kapisa',
        country_id: '1',
      },
      {
        id: '56',
        label: 'Khawst',
        country_id: '1',
      },
      {
        id: '57',
        label: 'Kunar',
        country_id: '1',
      },
      {
        id: '58',
        label: 'Lagman',
        country_id: '1',
      },
      {
        id: '59',
        label: 'Lawghar',
        country_id: '1',
      },
      {
        id: '60',
        label: 'Nangarhar',
        country_id: '1',
      },
      {
        id: '61',
        label: 'Nimruz',
        country_id: '1',
      },
      {
        id: '62',
        label: 'Nuristan',
        country_id: '1',
      },
      {
        id: '63',
        label: 'Paktika',
        country_id: '1',
      },
      {
        id: '64',
        label: 'Paktiya',
        country_id: '1',
      },
      {
        id: '65',
        label: 'Parwan',
        country_id: '1',
      },
      {
        id: '66',
        label: 'Qandahar',
        country_id: '1',
      },
      {
        id: '67',
        label: 'Qunduz',
        country_id: '1',
      },
      {
        id: '68',
        label: 'Samangan',
        country_id: '1',
      },
      {
        id: '69',
        label: 'Sar-e Pul',
        country_id: '1',
      },
      {
        id: '70',
        label: 'Takhar',
        country_id: '1',
      },
      {
        id: '71',
        label: 'Uruzgan',
        country_id: '1',
      },
      {
        id: '72',
        label: 'Wardag',
        country_id: '1',
      },
      {
        id: '73',
        label: 'Zabul',
        country_id: '1',
      },
    ],
  },
  {
    id: '2',
    sortname: 'AL',
    label: 'Albania',
    states: [
      {
        id: '74',
        label: 'Berat',
        country_id: '2',
      },
      {
        id: '75',
        label: 'Bulqize',
        country_id: '2',
      },
      {
        id: '76',
        label: 'Delvine',
        country_id: '2',
      },
      {
        id: '77',
        label: 'Devoll',
        country_id: '2',
      },
      {
        id: '78',
        label: 'Dibre',
        country_id: '2',
      },
      {
        id: '79',
        label: 'Durres',
        country_id: '2',
      },
      {
        id: '80',
        label: 'Elbasan',
        country_id: '2',
      },
      {
        id: '81',
        label: 'Fier',
        country_id: '2',
      },
      {
        id: '82',
        label: 'Gjirokaster',
        country_id: '2',
      },
      {
        id: '83',
        label: 'Gramsh',
        country_id: '2',
      },
      {
        id: '84',
        label: 'Has',
        country_id: '2',
      },
      {
        id: '85',
        label: 'Kavaje',
        country_id: '2',
      },
      {
        id: '86',
        label: 'Kolonje',
        country_id: '2',
      },
      {
        id: '87',
        label: 'Korce',
        country_id: '2',
      },
      {
        id: '88',
        label: 'Kruje',
        country_id: '2',
      },
      {
        id: '89',
        label: 'Kucove',
        country_id: '2',
      },
      {
        id: '90',
        label: 'Kukes',
        country_id: '2',
      },
      {
        id: '91',
        label: 'Kurbin',
        country_id: '2',
      },
      {
        id: '92',
        label: 'Lezhe',
        country_id: '2',
      },
      {
        id: '93',
        label: 'Librazhd',
        country_id: '2',
      },
      {
        id: '94',
        label: 'Lushnje',
        country_id: '2',
      },
      {
        id: '95',
        label: 'Mallakaster',
        country_id: '2',
      },
      {
        id: '96',
        label: 'Malsi e Madhe',
        country_id: '2',
      },
      {
        id: '97',
        label: 'Mat',
        country_id: '2',
      },
      {
        id: '98',
        label: 'Mirdite',
        country_id: '2',
      },
      {
        id: '99',
        label: 'Peqin',
        country_id: '2',
      },
      {
        id: '100',
        label: 'Permet',
        country_id: '2',
      },
      {
        id: '101',
        label: 'Pogradec',
        country_id: '2',
      },
      {
        id: '102',
        label: 'Puke',
        country_id: '2',
      },
      {
        id: '103',
        label: 'Sarande',
        country_id: '2',
      },
      {
        id: '104',
        label: 'Shkoder',
        country_id: '2',
      },
      {
        id: '105',
        label: 'Skrapar',
        country_id: '2',
      },
      {
        id: '106',
        label: 'Tepelene',
        country_id: '2',
      },
      {
        id: '107',
        label: 'Tirane',
        country_id: '2',
      },
      {
        id: '108',
        label: 'Tropoje',
        country_id: '2',
      },
      {
        id: '109',
        label: 'Vlore',
        country_id: '2',
      },
    ],
  },
  {
    id: '3',
    sortname: 'DZ',
    label: 'Algeria',
    states: [
      {
        id: '110',
        label: 'Ayn Daflah',
        country_id: '3',
      },
      {
        id: '111',
        label: 'Ayn Tamushanat',
        country_id: '3',
      },
      {
        id: '112',
        label: 'Adrar',
        country_id: '3',
      },
      {
        id: '113',
        label: 'Algiers',
        country_id: '3',
      },
      {
        id: '114',
        label: 'Annabah',
        country_id: '3',
      },
      {
        id: '115',
        label: 'Bashshar',
        country_id: '3',
      },
      {
        id: '116',
        label: 'Batnah',
        country_id: '3',
      },
      {
        id: '117',
        label: 'Bijayah',
        country_id: '3',
      },
      {
        id: '118',
        label: 'Biskrah',
        country_id: '3',
      },
      {
        id: '119',
        label: 'Blidah',
        country_id: '3',
      },
      {
        id: '120',
        label: 'Buirah',
        country_id: '3',
      },
      {
        id: '121',
        label: 'Bumardas',
        country_id: '3',
      },
      {
        id: '122',
        label: 'Burj Bu Arririj',
        country_id: '3',
      },
      {
        id: '123',
        label: 'Ghalizan',
        country_id: '3',
      },
      {
        id: '124',
        label: 'Ghardayah',
        country_id: '3',
      },
      {
        id: '125',
        label: 'Ilizi',
        country_id: '3',
      },
      {
        id: '126',
        label: 'Jijili',
        country_id: '3',
      },
      {
        id: '127',
        label: 'Jilfah',
        country_id: '3',
      },
      {
        id: '128',
        label: 'Khanshalah',
        country_id: '3',
      },
      {
        id: '129',
        label: 'Masilah',
        country_id: '3',
      },
      {
        id: '130',
        label: 'Midyah',
        country_id: '3',
      },
      {
        id: '131',
        label: 'Milah',
        country_id: '3',
      },
      {
        id: '132',
        label: 'Muaskar',
        country_id: '3',
      },
      {
        id: '133',
        label: 'Mustaghanam',
        country_id: '3',
      },
      {
        id: '134',
        label: 'Naama',
        country_id: '3',
      },
      {
        id: '135',
        label: 'Oran',
        country_id: '3',
      },
      {
        id: '136',
        label: 'Ouargla',
        country_id: '3',
      },
      {
        id: '137',
        label: 'Qalmah',
        country_id: '3',
      },
      {
        id: '138',
        label: 'Qustantinah',
        country_id: '3',
      },
      {
        id: '139',
        label: 'Sakikdah',
        country_id: '3',
      },
      {
        id: '140',
        label: 'Satif',
        country_id: '3',
      },
      {
        id: '141',
        label: "Sayda'",
        country_id: '3',
      },
      {
        id: '142',
        label: "Sidi ban-al-'Abbas",
        country_id: '3',
      },
      {
        id: '143',
        label: 'Suq Ahras',
        country_id: '3',
      },
      {
        id: '144',
        label: 'Tamanghasat',
        country_id: '3',
      },
      {
        id: '145',
        label: 'Tibazah',
        country_id: '3',
      },
      {
        id: '146',
        label: 'Tibissah',
        country_id: '3',
      },
      {
        id: '147',
        label: 'Tilimsan',
        country_id: '3',
      },
      {
        id: '148',
        label: 'Tinduf',
        country_id: '3',
      },
      {
        id: '149',
        label: 'Tisamsilt',
        country_id: '3',
      },
      {
        id: '150',
        label: 'Tiyarat',
        country_id: '3',
      },
      {
        id: '151',
        label: 'Tizi Wazu',
        country_id: '3',
      },
      {
        id: '152',
        label: 'Umm-al-Bawaghi',
        country_id: '3',
      },
      {
        id: '153',
        label: 'Wahran',
        country_id: '3',
      },
      {
        id: '154',
        label: 'Warqla',
        country_id: '3',
      },
      {
        id: '155',
        label: 'Wilaya d Alger',
        country_id: '3',
      },
      {
        id: '156',
        label: 'Wilaya de Bejaia',
        country_id: '3',
      },
      {
        id: '157',
        label: 'Wilaya de Constantine',
        country_id: '3',
      },
      {
        id: '158',
        label: 'al-Aghwat',
        country_id: '3',
      },
      {
        id: '159',
        label: 'al-Bayadh',
        country_id: '3',
      },
      {
        id: '160',
        label: "al-Jaza'ir",
        country_id: '3',
      },
      {
        id: '161',
        label: 'al-Wad',
        country_id: '3',
      },
      {
        id: '162',
        label: 'ash-Shalif',
        country_id: '3',
      },
      {
        id: '163',
        label: 'at-Tarif',
        country_id: '3',
      },
    ],
  },
  {
    id: '4',
    sortname: 'AS',
    label: 'American Samoa',
    states: [
      {
        id: '164',
        label: 'Eastern',
        country_id: '4',
      },
      {
        id: '165',
        label: "Manu'a",
        country_id: '4',
      },
      {
        id: '166',
        label: 'Swains Island',
        country_id: '4',
      },
      {
        id: '167',
        label: 'Western',
        country_id: '4',
      },
    ],
  },
  {
    id: '5',
    sortname: 'AD',
    label: 'Andorra',
    states: [
      {
        id: '168',
        label: 'Andorra la Vella',
        country_id: '5',
      },
      {
        id: '169',
        label: 'Canillo',
        country_id: '5',
      },
      {
        id: '170',
        label: 'Encamp',
        country_id: '5',
      },
      {
        id: '171',
        label: 'La Massana',
        country_id: '5',
      },
      {
        id: '172',
        label: 'Les Escaldes',
        country_id: '5',
      },
      {
        id: '173',
        label: 'Ordino',
        country_id: '5',
      },
      {
        id: '174',
        label: 'Sant Julia de Loria',
        country_id: '5',
      },
    ],
  },
  {
    id: '6',
    sortname: 'AO',
    label: 'Angola',
    states: [
      {
        id: '175',
        label: 'Bengo',
        country_id: '6',
      },
      {
        id: '176',
        label: 'Benguela',
        country_id: '6',
      },
      {
        id: '177',
        label: 'Bie',
        country_id: '6',
      },
      {
        id: '178',
        label: 'Cabinda',
        country_id: '6',
      },
      {
        id: '179',
        label: 'Cunene',
        country_id: '6',
      },
      {
        id: '180',
        label: 'Huambo',
        country_id: '6',
      },
      {
        id: '181',
        label: 'Huila',
        country_id: '6',
      },
      {
        id: '182',
        label: 'Kuando-Kubango',
        country_id: '6',
      },
      {
        id: '183',
        label: 'Kwanza Norte',
        country_id: '6',
      },
      {
        id: '184',
        label: 'Kwanza Sul',
        country_id: '6',
      },
      {
        id: '185',
        label: 'Luanda',
        country_id: '6',
      },
      {
        id: '186',
        label: 'Lunda Norte',
        country_id: '6',
      },
      {
        id: '187',
        label: 'Lunda Sul',
        country_id: '6',
      },
      {
        id: '188',
        label: 'Malanje',
        country_id: '6',
      },
      {
        id: '189',
        label: 'Moxico',
        country_id: '6',
      },
      {
        id: '190',
        label: 'Namibe',
        country_id: '6',
      },
      {
        id: '191',
        label: 'Uige',
        country_id: '6',
      },
      {
        id: '192',
        label: 'Zaire',
        country_id: '6',
      },
    ],
  },
  {
    id: '7',
    sortname: 'AI',
    label: 'Anguilla',
    states: [
      {
        id: '193',
        label: 'Other Provinces',
        country_id: '7',
      },
    ],
  },
  {
    id: '8',
    sortname: 'AQ',
    label: 'Antarctica',
    states: [
      {
        id: '194',
        label: 'Sector claimed by Argentina/Ch',
        country_id: '8',
      },
      {
        id: '195',
        label: 'Sector claimed by Argentina/UK',
        country_id: '8',
      },
      {
        id: '196',
        label: 'Sector claimed by Australia',
        country_id: '8',
      },
      {
        id: '197',
        label: 'Sector claimed by France',
        country_id: '8',
      },
      {
        id: '198',
        label: 'Sector claimed by New Zealand',
        country_id: '8',
      },
      {
        id: '199',
        label: 'Sector claimed by Norway',
        country_id: '8',
      },
      {
        id: '200',
        label: 'Unclaimed Sector',
        country_id: '8',
      },
    ],
  },
  {
    id: '9',
    sortname: 'AG',
    label: 'Antigua And Barbuda',
    states: [
      {
        id: '201',
        label: 'Barbuda',
        country_id: '9',
      },
      {
        id: '202',
        label: 'Saint George',
        country_id: '9',
      },
      {
        id: '203',
        label: 'Saint John',
        country_id: '9',
      },
      {
        id: '204',
        label: 'Saint Mary',
        country_id: '9',
      },
      {
        id: '205',
        label: 'Saint Paul',
        country_id: '9',
      },
      {
        id: '206',
        label: 'Saint Peter',
        country_id: '9',
      },
      {
        id: '207',
        label: 'Saint Philip',
        country_id: '9',
      },
    ],
  },
  {
    id: '10',
    sortname: 'AR',
    label: 'Argentina',
    states: [
      {
        id: '208',
        label: 'Buenos Aires',
        country_id: '10',
      },
      {
        id: '209',
        label: 'Catamarca',
        country_id: '10',
      },
      {
        id: '210',
        label: 'Chaco',
        country_id: '10',
      },
      {
        id: '211',
        label: 'Chubut',
        country_id: '10',
      },
      {
        id: '212',
        label: 'Cordoba',
        country_id: '10',
      },
      {
        id: '213',
        label: 'Corrientes',
        country_id: '10',
      },
      {
        id: '214',
        label: 'Distrito Federal',
        country_id: '10',
      },
      {
        id: '215',
        label: 'Entre Rios',
        country_id: '10',
      },
      {
        id: '216',
        label: 'Formosa',
        country_id: '10',
      },
      {
        id: '217',
        label: 'Jujuy',
        country_id: '10',
      },
      {
        id: '218',
        label: 'La Pampa',
        country_id: '10',
      },
      {
        id: '219',
        label: 'La Rioja',
        country_id: '10',
      },
      {
        id: '220',
        label: 'Mendoza',
        country_id: '10',
      },
      {
        id: '221',
        label: 'Misiones',
        country_id: '10',
      },
      {
        id: '222',
        label: 'Neuquen',
        country_id: '10',
      },
      {
        id: '223',
        label: 'Rio Negro',
        country_id: '10',
      },
      {
        id: '224',
        label: 'Salta',
        country_id: '10',
      },
      {
        id: '225',
        label: 'San Juan',
        country_id: '10',
      },
      {
        id: '226',
        label: 'San Luis',
        country_id: '10',
      },
      {
        id: '227',
        label: 'Santa Cruz',
        country_id: '10',
      },
      {
        id: '228',
        label: 'Santa Fe',
        country_id: '10',
      },
      {
        id: '229',
        label: 'Santiago del Estero',
        country_id: '10',
      },
      {
        id: '230',
        label: 'Tierra del Fuego',
        country_id: '10',
      },
      {
        id: '231',
        label: 'Tucuman',
        country_id: '10',
      },
    ],
  },
  {
    id: '11',
    sortname: 'AM',
    label: 'Armenia',
    states: [
      {
        id: '232',
        label: 'Aragatsotn',
        country_id: '11',
      },
      {
        id: '233',
        label: 'Ararat',
        country_id: '11',
      },
      {
        id: '234',
        label: 'Armavir',
        country_id: '11',
      },
      {
        id: '235',
        label: 'Gegharkunik',
        country_id: '11',
      },
      {
        id: '236',
        label: 'Kotaik',
        country_id: '11',
      },
      {
        id: '237',
        label: 'Lori',
        country_id: '11',
      },
      {
        id: '238',
        label: 'Shirak',
        country_id: '11',
      },
      {
        id: '239',
        label: 'Stepanakert',
        country_id: '11',
      },
      {
        id: '240',
        label: 'Syunik',
        country_id: '11',
      },
      {
        id: '241',
        label: 'Tavush',
        country_id: '11',
      },
      {
        id: '242',
        label: 'Vayots Dzor',
        country_id: '11',
      },
      {
        id: '243',
        label: 'Yerevan',
        country_id: '11',
      },
    ],
  },
  {
    id: '12',
    sortname: 'AW',
    label: 'Aruba',
    states: [
      {
        id: '244',
        label: 'Aruba',
        country_id: '12',
      },
    ],
  },
  {
    id: '13',
    sortname: 'AU',
    label: 'Australia',
    states: [
      {
        id: '245',
        label: 'Auckland',
        country_id: '13',
      },
      {
        id: '246',
        label: 'Australian Capital Territory',
        country_id: '13',
      },
      {
        id: '247',
        label: 'Balgowlah',
        country_id: '13',
      },
      {
        id: '248',
        label: 'Balmain',
        country_id: '13',
      },
      {
        id: '249',
        label: 'Bankstown',
        country_id: '13',
      },
      {
        id: '250',
        label: 'Baulkham Hills',
        country_id: '13',
      },
      {
        id: '251',
        label: 'Bonnet Bay',
        country_id: '13',
      },
      {
        id: '252',
        label: 'Camberwell',
        country_id: '13',
      },
      {
        id: '253',
        label: 'Carole Park',
        country_id: '13',
      },
      {
        id: '254',
        label: 'Castle Hill',
        country_id: '13',
      },
      {
        id: '255',
        label: 'Caulfield',
        country_id: '13',
      },
      {
        id: '256',
        label: 'Chatswood',
        country_id: '13',
      },
      {
        id: '257',
        label: 'Cheltenham',
        country_id: '13',
      },
      {
        id: '258',
        label: 'Cherrybrook',
        country_id: '13',
      },
      {
        id: '259',
        label: 'Clayton',
        country_id: '13',
      },
      {
        id: '260',
        label: 'Collingwood',
        country_id: '13',
      },
      {
        id: '261',
        label: 'Frenchs Forest',
        country_id: '13',
      },
      {
        id: '262',
        label: 'Hawthorn',
        country_id: '13',
      },
      {
        id: '263',
        label: 'Jannnali',
        country_id: '13',
      },
      {
        id: '264',
        label: 'Knoxfield',
        country_id: '13',
      },
      {
        id: '265',
        label: 'Melbourne',
        country_id: '13',
      },
      {
        id: '266',
        label: 'New South Wales',
        country_id: '13',
      },
      {
        id: '267',
        label: 'Northern Territory',
        country_id: '13',
      },
      {
        id: '268',
        label: 'Perth',
        country_id: '13',
      },
      {
        id: '269',
        label: 'Queensland',
        country_id: '13',
      },
      {
        id: '270',
        label: 'South Australia',
        country_id: '13',
      },
      {
        id: '271',
        label: 'Tasmania',
        country_id: '13',
      },
      {
        id: '272',
        label: 'Templestowe',
        country_id: '13',
      },
      {
        id: '273',
        label: 'Victoria',
        country_id: '13',
      },
      {
        id: '274',
        label: 'Werribee south',
        country_id: '13',
      },
      {
        id: '275',
        label: 'Western Australia',
        country_id: '13',
      },
      {
        id: '276',
        label: 'Wheeler',
        country_id: '13',
      },
    ],
  },
  {
    id: '14',
    sortname: 'AT',
    label: 'Austria',
    states: [
      {
        id: '277',
        label: 'Bundesland Salzburg',
        country_id: '14',
      },
      {
        id: '278',
        label: 'Bundesland Steiermark',
        country_id: '14',
      },
      {
        id: '279',
        label: 'Bundesland Tirol',
        country_id: '14',
      },
      {
        id: '280',
        label: 'Burgenland',
        country_id: '14',
      },
      {
        id: '281',
        label: 'Carinthia',
        country_id: '14',
      },
      {
        id: '282',
        label: 'Karnten',
        country_id: '14',
      },
      {
        id: '283',
        label: 'Liezen',
        country_id: '14',
      },
      {
        id: '284',
        label: 'Lower Austria',
        country_id: '14',
      },
      {
        id: '285',
        label: 'Niederosterreich',
        country_id: '14',
      },
      {
        id: '286',
        label: 'Oberosterreich',
        country_id: '14',
      },
      {
        id: '287',
        label: 'Salzburg',
        country_id: '14',
      },
      {
        id: '288',
        label: 'Schleswig-Holstein',
        country_id: '14',
      },
      {
        id: '289',
        label: 'Steiermark',
        country_id: '14',
      },
      {
        id: '290',
        label: 'Styria',
        country_id: '14',
      },
      {
        id: '291',
        label: 'Tirol',
        country_id: '14',
      },
      {
        id: '292',
        label: 'Upper Austria',
        country_id: '14',
      },
      {
        id: '293',
        label: 'Vorarlberg',
        country_id: '14',
      },
      {
        id: '294',
        label: 'Wien',
        country_id: '14',
      },
    ],
  },
  {
    id: '15',
    sortname: 'AZ',
    label: 'Azerbaijan',
    states: [
      {
        id: '295',
        label: 'Abseron',
        country_id: '15',
      },
      {
        id: '296',
        label: 'Baki Sahari',
        country_id: '15',
      },
      {
        id: '297',
        label: 'Ganca',
        country_id: '15',
      },
      {
        id: '298',
        label: 'Ganja',
        country_id: '15',
      },
      {
        id: '299',
        label: 'Kalbacar',
        country_id: '15',
      },
      {
        id: '300',
        label: 'Lankaran',
        country_id: '15',
      },
      {
        id: '301',
        label: 'Mil-Qarabax',
        country_id: '15',
      },
      {
        id: '302',
        label: 'Mugan-Salyan',
        country_id: '15',
      },
      {
        id: '303',
        label: 'Nagorni-Qarabax',
        country_id: '15',
      },
      {
        id: '304',
        label: 'Naxcivan',
        country_id: '15',
      },
      {
        id: '305',
        label: 'Priaraks',
        country_id: '15',
      },
      {
        id: '306',
        label: 'Qazax',
        country_id: '15',
      },
      {
        id: '307',
        label: 'Saki',
        country_id: '15',
      },
      {
        id: '308',
        label: 'Sirvan',
        country_id: '15',
      },
      {
        id: '309',
        label: 'Xacmaz',
        country_id: '15',
      },
    ],
  },
  {
    id: '16',
    sortname: 'BS',
    label: 'Bahamas The',
    states: [
      {
        id: '310',
        label: 'Abaco',
        country_id: '16',
      },
      {
        id: '311',
        label: 'Acklins Island',
        country_id: '16',
      },
      {
        id: '312',
        label: 'Andros',
        country_id: '16',
      },
      {
        id: '313',
        label: 'Berry Islands',
        country_id: '16',
      },
      {
        id: '314',
        label: 'Biminis',
        country_id: '16',
      },
      {
        id: '315',
        label: 'Cat Island',
        country_id: '16',
      },
      {
        id: '316',
        label: 'Crooked Island',
        country_id: '16',
      },
      {
        id: '317',
        label: 'Eleuthera',
        country_id: '16',
      },
      {
        id: '318',
        label: 'Exuma and Cays',
        country_id: '16',
      },
      {
        id: '319',
        label: 'Grand Bahama',
        country_id: '16',
      },
      {
        id: '320',
        label: 'Inagua Islands',
        country_id: '16',
      },
      {
        id: '321',
        label: 'Long Island',
        country_id: '16',
      },
      {
        id: '322',
        label: 'Mayaguana',
        country_id: '16',
      },
      {
        id: '323',
        label: 'New Providence',
        country_id: '16',
      },
      {
        id: '324',
        label: 'Ragged Island',
        country_id: '16',
      },
      {
        id: '325',
        label: 'Rum Cay',
        country_id: '16',
      },
      {
        id: '326',
        label: 'San Salvador',
        country_id: '16',
      },
    ],
  },
  {
    id: '17',
    sortname: 'BH',
    label: 'Bahrain',
    states: [
      {
        id: '327',
        label: "'Isa",
        country_id: '17',
      },
      {
        id: '328',
        label: 'Badiyah',
        country_id: '17',
      },
      {
        id: '329',
        label: 'Hidd',
        country_id: '17',
      },
      {
        id: '330',
        label: 'Jidd Hafs',
        country_id: '17',
      },
      {
        id: '331',
        label: 'Mahama',
        country_id: '17',
      },
      {
        id: '332',
        label: 'Manama',
        country_id: '17',
      },
      {
        id: '333',
        label: 'Sitrah',
        country_id: '17',
      },
      {
        id: '334',
        label: 'al-Manamah',
        country_id: '17',
      },
      {
        id: '335',
        label: 'al-Muharraq',
        country_id: '17',
      },
      {
        id: '336',
        label: "ar-Rifa'a",
        country_id: '17',
      },
    ],
  },
  {
    id: '18',
    sortname: 'BD',
    label: 'Bangladesh',
    states: [
      {
        id: '337',
        label: 'Bagar Hat',
        country_id: '18',
      },
      {
        id: '338',
        label: 'Bandarban',
        country_id: '18',
      },
      {
        id: '339',
        label: 'Barguna',
        country_id: '18',
      },
      {
        id: '340',
        label: 'Barisal',
        country_id: '18',
      },
      {
        id: '341',
        label: 'Bhola',
        country_id: '18',
      },
      {
        id: '342',
        label: 'Bogora',
        country_id: '18',
      },
      {
        id: '343',
        label: 'Brahman Bariya',
        country_id: '18',
      },
      {
        id: '344',
        label: 'Chandpur',
        country_id: '18',
      },
      {
        id: '345',
        label: 'Chattagam',
        country_id: '18',
      },
      {
        id: '346',
        label: 'Chittagong Division',
        country_id: '18',
      },
      {
        id: '347',
        label: 'Chuadanga',
        country_id: '18',
      },
      {
        id: '348',
        label: 'Dhaka',
        country_id: '18',
      },
      {
        id: '349',
        label: 'Dinajpur',
        country_id: '18',
      },
      {
        id: '350',
        label: 'Faridpur',
        country_id: '18',
      },
      {
        id: '351',
        label: 'Feni',
        country_id: '18',
      },
      {
        id: '352',
        label: 'Gaybanda',
        country_id: '18',
      },
      {
        id: '353',
        label: 'Gazipur',
        country_id: '18',
      },
      {
        id: '354',
        label: 'Gopalganj',
        country_id: '18',
      },
      {
        id: '355',
        label: 'Habiganj',
        country_id: '18',
      },
      {
        id: '356',
        label: 'Jaipur Hat',
        country_id: '18',
      },
      {
        id: '357',
        label: 'Jamalpur',
        country_id: '18',
      },
      {
        id: '358',
        label: 'Jessor',
        country_id: '18',
      },
      {
        id: '359',
        label: 'Jhalakati',
        country_id: '18',
      },
      {
        id: '360',
        label: 'Jhanaydah',
        country_id: '18',
      },
      {
        id: '361',
        label: 'Khagrachhari',
        country_id: '18',
      },
      {
        id: '362',
        label: 'Khulna',
        country_id: '18',
      },
      {
        id: '363',
        label: 'Kishorganj',
        country_id: '18',
      },
      {
        id: '364',
        label: 'Koks Bazar',
        country_id: '18',
      },
      {
        id: '365',
        label: 'Komilla',
        country_id: '18',
      },
      {
        id: '366',
        label: 'Kurigram',
        country_id: '18',
      },
      {
        id: '367',
        label: 'Kushtiya',
        country_id: '18',
      },
      {
        id: '368',
        label: 'Lakshmipur',
        country_id: '18',
      },
      {
        id: '369',
        label: 'Lalmanir Hat',
        country_id: '18',
      },
      {
        id: '370',
        label: 'Madaripur',
        country_id: '18',
      },
      {
        id: '371',
        label: 'Magura',
        country_id: '18',
      },
      {
        id: '372',
        label: 'Maimansingh',
        country_id: '18',
      },
      {
        id: '373',
        label: 'Manikganj',
        country_id: '18',
      },
      {
        id: '374',
        label: 'Maulvi Bazar',
        country_id: '18',
      },
      {
        id: '375',
        label: 'Meherpur',
        country_id: '18',
      },
      {
        id: '376',
        label: 'Munshiganj',
        country_id: '18',
      },
      {
        id: '377',
        label: 'Naral',
        country_id: '18',
      },
      {
        id: '378',
        label: 'Narayanganj',
        country_id: '18',
      },
      {
        id: '379',
        label: 'Narsingdi',
        country_id: '18',
      },
      {
        id: '380',
        label: 'Nator',
        country_id: '18',
      },
      {
        id: '381',
        label: 'Naugaon',
        country_id: '18',
      },
      {
        id: '382',
        label: 'Nawabganj',
        country_id: '18',
      },
      {
        id: '383',
        label: 'Netrakona',
        country_id: '18',
      },
      {
        id: '384',
        label: 'Nilphamari',
        country_id: '18',
      },
      {
        id: '385',
        label: 'Noakhali',
        country_id: '18',
      },
      {
        id: '386',
        label: 'Pabna',
        country_id: '18',
      },
      {
        id: '387',
        label: 'Panchagarh',
        country_id: '18',
      },
      {
        id: '388',
        label: 'Patuakhali',
        country_id: '18',
      },
      {
        id: '389',
        label: 'Pirojpur',
        country_id: '18',
      },
      {
        id: '390',
        label: 'Rajbari',
        country_id: '18',
      },
      {
        id: '391',
        label: 'Rajshahi',
        country_id: '18',
      },
      {
        id: '392',
        label: 'Rangamati',
        country_id: '18',
      },
      {
        id: '393',
        label: 'Rangpur',
        country_id: '18',
      },
      {
        id: '394',
        label: 'Satkhira',
        country_id: '18',
      },
      {
        id: '395',
        label: 'Shariatpur',
        country_id: '18',
      },
      {
        id: '396',
        label: 'Sherpur',
        country_id: '18',
      },
      {
        id: '397',
        label: 'Silhat',
        country_id: '18',
      },
      {
        id: '398',
        label: 'Sirajganj',
        country_id: '18',
      },
      {
        id: '399',
        label: 'Sunamganj',
        country_id: '18',
      },
      {
        id: '400',
        label: 'Tangayal',
        country_id: '18',
      },
      {
        id: '401',
        label: 'Thakurgaon',
        country_id: '18',
      },
    ],
  },
  {
    id: '19',
    sortname: 'BB',
    label: 'Barbados',
    states: [
      {
        id: '402',
        label: 'Christ Church',
        country_id: '19',
      },
      {
        id: '403',
        label: 'Saint Andrew',
        country_id: '19',
      },
      {
        id: '404',
        label: 'Saint George',
        country_id: '19',
      },
      {
        id: '405',
        label: 'Saint James',
        country_id: '19',
      },
      {
        id: '406',
        label: 'Saint John',
        country_id: '19',
      },
      {
        id: '407',
        label: 'Saint Joseph',
        country_id: '19',
      },
      {
        id: '408',
        label: 'Saint Lucy',
        country_id: '19',
      },
      {
        id: '409',
        label: 'Saint Michael',
        country_id: '19',
      },
      {
        id: '410',
        label: 'Saint Peter',
        country_id: '19',
      },
      {
        id: '411',
        label: 'Saint Philip',
        country_id: '19',
      },
      {
        id: '412',
        label: 'Saint Thomas',
        country_id: '19',
      },
    ],
  },
  {
    id: '20',
    sortname: 'BY',
    label: 'Belarus',
    states: [
      {
        id: '413',
        label: 'Brest',
        country_id: '20',
      },
      {
        id: '414',
        label: "Homjel'",
        country_id: '20',
      },
      {
        id: '415',
        label: 'Hrodna',
        country_id: '20',
      },
      {
        id: '416',
        label: 'Mahiljow',
        country_id: '20',
      },
      {
        id: '417',
        label: 'Mahilyowskaya Voblasts',
        country_id: '20',
      },
      {
        id: '418',
        label: 'Minsk',
        country_id: '20',
      },
      {
        id: '419',
        label: "Minskaja Voblasts'",
        country_id: '20',
      },
      {
        id: '420',
        label: 'Petrik',
        country_id: '20',
      },
      {
        id: '421',
        label: 'Vicebsk',
        country_id: '20',
      },
    ],
  },
  {
    id: '21',
    sortname: 'BE',
    label: 'Belgium',
    states: [
      {
        id: '422',
        label: 'Antwerpen',
        country_id: '21',
      },
      {
        id: '423',
        label: 'Berchem',
        country_id: '21',
      },
      {
        id: '424',
        label: 'Brabant',
        country_id: '21',
      },
      {
        id: '425',
        label: 'Brabant Wallon',
        country_id: '21',
      },
      {
        id: '426',
        label: 'Brussel',
        country_id: '21',
      },
      {
        id: '427',
        label: 'East Flanders',
        country_id: '21',
      },
      {
        id: '428',
        label: 'Hainaut',
        country_id: '21',
      },
      {
        id: '429',
        label: 'Liege',
        country_id: '21',
      },
      {
        id: '430',
        label: 'Limburg',
        country_id: '21',
      },
      {
        id: '431',
        label: 'Luxembourg',
        country_id: '21',
      },
      {
        id: '432',
        label: 'Namur',
        country_id: '21',
      },
      {
        id: '433',
        label: 'Ontario',
        country_id: '21',
      },
      {
        id: '434',
        label: 'Oost-Vlaanderen',
        country_id: '21',
      },
      {
        id: '435',
        label: 'Provincie Brabant',
        country_id: '21',
      },
      {
        id: '436',
        label: 'Vlaams-Brabant',
        country_id: '21',
      },
      {
        id: '437',
        label: 'Wallonne',
        country_id: '21',
      },
      {
        id: '438',
        label: 'West-Vlaanderen',
        country_id: '21',
      },
    ],
  },
  {
    id: '22',
    sortname: 'BZ',
    label: 'Belize',
    states: [
      {
        id: '439',
        label: 'Belize',
        country_id: '22',
      },
      {
        id: '440',
        label: 'Cayo',
        country_id: '22',
      },
      {
        id: '441',
        label: 'Corozal',
        country_id: '22',
      },
      {
        id: '442',
        label: 'Orange Walk',
        country_id: '22',
      },
      {
        id: '443',
        label: 'Stann Creek',
        country_id: '22',
      },
      {
        id: '444',
        label: 'Toledo',
        country_id: '22',
      },
    ],
  },
  {
    id: '23',
    sortname: 'BJ',
    label: 'Benin',
    states: [
      {
        id: '445',
        label: 'Alibori',
        country_id: '23',
      },
      {
        id: '446',
        label: 'Atacora',
        country_id: '23',
      },
      {
        id: '447',
        label: 'Atlantique',
        country_id: '23',
      },
      {
        id: '448',
        label: 'Borgou',
        country_id: '23',
      },
      {
        id: '449',
        label: 'Collines',
        country_id: '23',
      },
      {
        id: '450',
        label: 'Couffo',
        country_id: '23',
      },
      {
        id: '451',
        label: 'Donga',
        country_id: '23',
      },
      {
        id: '452',
        label: 'Littoral',
        country_id: '23',
      },
      {
        id: '453',
        label: 'Mono',
        country_id: '23',
      },
      {
        id: '454',
        label: 'Oueme',
        country_id: '23',
      },
      {
        id: '455',
        label: 'Plateau',
        country_id: '23',
      },
      {
        id: '456',
        label: 'Zou',
        country_id: '23',
      },
    ],
  },
  {
    id: '24',
    sortname: 'BM',
    label: 'Bermuda',
    states: [
      {
        id: '457',
        label: 'Hamilton',
        country_id: '24',
      },
      {
        id: '458',
        label: 'Saint George',
        country_id: '24',
      },
    ],
  },
  {
    id: '25',
    sortname: 'BT',
    label: 'Bhutan',
    states: [
      {
        id: '459',
        label: 'Bumthang',
        country_id: '25',
      },
      {
        id: '460',
        label: 'Chhukha',
        country_id: '25',
      },
      {
        id: '461',
        label: 'Chirang',
        country_id: '25',
      },
      {
        id: '462',
        label: 'Daga',
        country_id: '25',
      },
      {
        id: '463',
        label: 'Geylegphug',
        country_id: '25',
      },
      {
        id: '464',
        label: 'Ha',
        country_id: '25',
      },
      {
        id: '465',
        label: 'Lhuntshi',
        country_id: '25',
      },
      {
        id: '466',
        label: 'Mongar',
        country_id: '25',
      },
      {
        id: '467',
        label: 'Pemagatsel',
        country_id: '25',
      },
      {
        id: '468',
        label: 'Punakha',
        country_id: '25',
      },
      {
        id: '469',
        label: 'Rinpung',
        country_id: '25',
      },
      {
        id: '470',
        label: 'Samchi',
        country_id: '25',
      },
      {
        id: '471',
        label: 'Samdrup Jongkhar',
        country_id: '25',
      },
      {
        id: '472',
        label: 'Shemgang',
        country_id: '25',
      },
      {
        id: '473',
        label: 'Tashigang',
        country_id: '25',
      },
      {
        id: '474',
        label: 'Timphu',
        country_id: '25',
      },
      {
        id: '475',
        label: 'Tongsa',
        country_id: '25',
      },
      {
        id: '476',
        label: 'Wangdiphodrang',
        country_id: '25',
      },
    ],
  },
  {
    id: '26',
    sortname: 'BO',
    label: 'Bolivia',
    states: [
      {
        id: '477',
        label: 'Beni',
        country_id: '26',
      },
      {
        id: '478',
        label: 'Chuquisaca',
        country_id: '26',
      },
      {
        id: '479',
        label: 'Cochabamba',
        country_id: '26',
      },
      {
        id: '480',
        label: 'La Paz',
        country_id: '26',
      },
      {
        id: '481',
        label: 'Oruro',
        country_id: '26',
      },
      {
        id: '482',
        label: 'Pando',
        country_id: '26',
      },
      {
        id: '483',
        label: 'Potosi',
        country_id: '26',
      },
      {
        id: '484',
        label: 'Santa Cruz',
        country_id: '26',
      },
      {
        id: '485',
        label: 'Tarija',
        country_id: '26',
      },
    ],
  },
  {
    id: '27',
    sortname: 'BA',
    label: 'Bosnia and Herzegovina',
    states: [
      {
        id: '486',
        label: 'Federacija Bosna i Hercegovina',
        country_id: '27',
      },
      {
        id: '487',
        label: 'Republika Srpska',
        country_id: '27',
      },
    ],
  },
  {
    id: '28',
    sortname: 'BW',
    label: 'Botswana',
    states: [
      {
        id: '488',
        label: 'Central Bobonong',
        country_id: '28',
      },
      {
        id: '489',
        label: 'Central Boteti',
        country_id: '28',
      },
      {
        id: '490',
        label: 'Central Mahalapye',
        country_id: '28',
      },
      {
        id: '491',
        label: 'Central Serowe-Palapye',
        country_id: '28',
      },
      {
        id: '492',
        label: 'Central Tutume',
        country_id: '28',
      },
      {
        id: '493',
        label: 'Chobe',
        country_id: '28',
      },
      {
        id: '494',
        label: 'Francistown',
        country_id: '28',
      },
      {
        id: '495',
        label: 'Gaborone',
        country_id: '28',
      },
      {
        id: '496',
        label: 'Ghanzi',
        country_id: '28',
      },
      {
        id: '497',
        label: 'Jwaneng',
        country_id: '28',
      },
      {
        id: '498',
        label: 'Kgalagadi North',
        country_id: '28',
      },
      {
        id: '499',
        label: 'Kgalagadi South',
        country_id: '28',
      },
      {
        id: '500',
        label: 'Kgatleng',
        country_id: '28',
      },
      {
        id: '501',
        label: 'Kweneng',
        country_id: '28',
      },
      {
        id: '502',
        label: 'Lobatse',
        country_id: '28',
      },
      {
        id: '503',
        label: 'Ngamiland',
        country_id: '28',
      },
      {
        id: '504',
        label: 'Ngwaketse',
        country_id: '28',
      },
      {
        id: '505',
        label: 'North East',
        country_id: '28',
      },
      {
        id: '506',
        label: 'Okavango',
        country_id: '28',
      },
      {
        id: '507',
        label: 'Orapa',
        country_id: '28',
      },
      {
        id: '508',
        label: 'Selibe Phikwe',
        country_id: '28',
      },
      {
        id: '509',
        label: 'South East',
        country_id: '28',
      },
      {
        id: '510',
        label: 'Sowa',
        country_id: '28',
      },
    ],
  },
  {
    id: '29',
    sortname: 'BV',
    label: 'Bouvet Island',
    states: [
      {
        id: '511',
        label: 'Bouvet Island',
        country_id: '29',
      },
    ],
  },
  {
    id: '30',
    sortname: 'BR',
    label: 'Brazil',
    states: [
      {
        id: '512',
        label: 'Acre',
        country_id: '30',
      },
      {
        id: '513',
        label: 'Alagoas',
        country_id: '30',
      },
      {
        id: '514',
        label: 'Amapa',
        country_id: '30',
      },
      {
        id: '515',
        label: 'Amazonas',
        country_id: '30',
      },
      {
        id: '516',
        label: 'Bahia',
        country_id: '30',
      },
      {
        id: '517',
        label: 'Ceara',
        country_id: '30',
      },
      {
        id: '518',
        label: 'Distrito Federal',
        country_id: '30',
      },
      {
        id: '519',
        label: 'Espirito Santo',
        country_id: '30',
      },
      {
        id: '520',
        label: 'Estado de Sao Paulo',
        country_id: '30',
      },
      {
        id: '521',
        label: 'Goias',
        country_id: '30',
      },
      {
        id: '522',
        label: 'Maranhao',
        country_id: '30',
      },
      {
        id: '523',
        label: 'Mato Grosso',
        country_id: '30',
      },
      {
        id: '524',
        label: 'Mato Grosso do Sul',
        country_id: '30',
      },
      {
        id: '525',
        label: 'Minas Gerais',
        country_id: '30',
      },
      {
        id: '526',
        label: 'Para',
        country_id: '30',
      },
      {
        id: '527',
        label: 'Paraiba',
        country_id: '30',
      },
      {
        id: '528',
        label: 'Parana',
        country_id: '30',
      },
      {
        id: '529',
        label: 'Pernambuco',
        country_id: '30',
      },
      {
        id: '530',
        label: 'Piaui',
        country_id: '30',
      },
      {
        id: '531',
        label: 'Rio Grande do Norte',
        country_id: '30',
      },
      {
        id: '532',
        label: 'Rio Grande do Sul',
        country_id: '30',
      },
      {
        id: '533',
        label: 'Rio de Janeiro',
        country_id: '30',
      },
      {
        id: '534',
        label: 'Rondonia',
        country_id: '30',
      },
      {
        id: '535',
        label: 'Roraima',
        country_id: '30',
      },
      {
        id: '536',
        label: 'Santa Catarina',
        country_id: '30',
      },
      {
        id: '537',
        label: 'Sao Paulo',
        country_id: '30',
      },
      {
        id: '538',
        label: 'Sergipe',
        country_id: '30',
      },
      {
        id: '539',
        label: 'Tocantins',
        country_id: '30',
      },
    ],
  },
  {
    id: '31',
    sortname: 'IO',
    label: 'British Indian Ocean Territory',
    states: [
      {
        id: '540',
        label: 'British Indian Ocean Territory',
        country_id: '31',
      },
    ],
  },
  {
    id: '32',
    sortname: 'BN',
    label: 'Brunei',
    states: [
      {
        id: '541',
        label: 'Belait',
        country_id: '32',
      },
      {
        id: '542',
        label: 'Brunei-Muara',
        country_id: '32',
      },
      {
        id: '543',
        label: 'Temburong',
        country_id: '32',
      },
      {
        id: '544',
        label: 'Tutong',
        country_id: '32',
      },
    ],
  },
  {
    id: '33',
    sortname: 'BG',
    label: 'Bulgaria',
    states: [
      {
        id: '545',
        label: 'Blagoevgrad',
        country_id: '33',
      },
      {
        id: '546',
        label: 'Burgas',
        country_id: '33',
      },
      {
        id: '547',
        label: 'Dobrich',
        country_id: '33',
      },
      {
        id: '548',
        label: 'Gabrovo',
        country_id: '33',
      },
      {
        id: '549',
        label: 'Haskovo',
        country_id: '33',
      },
      {
        id: '550',
        label: 'Jambol',
        country_id: '33',
      },
      {
        id: '551',
        label: 'Kardzhali',
        country_id: '33',
      },
      {
        id: '552',
        label: 'Kjustendil',
        country_id: '33',
      },
      {
        id: '553',
        label: 'Lovech',
        country_id: '33',
      },
      {
        id: '554',
        label: 'Montana',
        country_id: '33',
      },
      {
        id: '555',
        label: 'Oblast Sofiya-Grad',
        country_id: '33',
      },
      {
        id: '556',
        label: 'Pazardzhik',
        country_id: '33',
      },
      {
        id: '557',
        label: 'Pernik',
        country_id: '33',
      },
      {
        id: '558',
        label: 'Pleven',
        country_id: '33',
      },
      {
        id: '559',
        label: 'Plovdiv',
        country_id: '33',
      },
      {
        id: '560',
        label: 'Razgrad',
        country_id: '33',
      },
      {
        id: '561',
        label: 'Ruse',
        country_id: '33',
      },
      {
        id: '562',
        label: 'Shumen',
        country_id: '33',
      },
      {
        id: '563',
        label: 'Silistra',
        country_id: '33',
      },
      {
        id: '564',
        label: 'Sliven',
        country_id: '33',
      },
      {
        id: '565',
        label: 'Smoljan',
        country_id: '33',
      },
      {
        id: '566',
        label: 'Sofija grad',
        country_id: '33',
      },
      {
        id: '567',
        label: 'Sofijska oblast',
        country_id: '33',
      },
      {
        id: '568',
        label: 'Stara Zagora',
        country_id: '33',
      },
      {
        id: '569',
        label: 'Targovishte',
        country_id: '33',
      },
      {
        id: '570',
        label: 'Varna',
        country_id: '33',
      },
      {
        id: '571',
        label: 'Veliko Tarnovo',
        country_id: '33',
      },
      {
        id: '572',
        label: 'Vidin',
        country_id: '33',
      },
      {
        id: '573',
        label: 'Vraca',
        country_id: '33',
      },
      {
        id: '574',
        label: 'Yablaniza',
        country_id: '33',
      },
    ],
  },
  {
    id: '34',
    sortname: 'BF',
    label: 'Burkina Faso',
    states: [
      {
        id: '575',
        label: 'Bale',
        country_id: '34',
      },
      {
        id: '576',
        label: 'Bam',
        country_id: '34',
      },
      {
        id: '577',
        label: 'Bazega',
        country_id: '34',
      },
      {
        id: '578',
        label: 'Bougouriba',
        country_id: '34',
      },
      {
        id: '579',
        label: 'Boulgou',
        country_id: '34',
      },
      {
        id: '580',
        label: 'Boulkiemde',
        country_id: '34',
      },
      {
        id: '581',
        label: 'Comoe',
        country_id: '34',
      },
      {
        id: '582',
        label: 'Ganzourgou',
        country_id: '34',
      },
      {
        id: '583',
        label: 'Gnagna',
        country_id: '34',
      },
      {
        id: '584',
        label: 'Gourma',
        country_id: '34',
      },
      {
        id: '585',
        label: 'Houet',
        country_id: '34',
      },
      {
        id: '586',
        label: 'Ioba',
        country_id: '34',
      },
      {
        id: '587',
        label: 'Kadiogo',
        country_id: '34',
      },
      {
        id: '588',
        label: 'Kenedougou',
        country_id: '34',
      },
      {
        id: '589',
        label: 'Komandjari',
        country_id: '34',
      },
      {
        id: '590',
        label: 'Kompienga',
        country_id: '34',
      },
      {
        id: '591',
        label: 'Kossi',
        country_id: '34',
      },
      {
        id: '592',
        label: 'Kouritenga',
        country_id: '34',
      },
      {
        id: '593',
        label: 'Kourweogo',
        country_id: '34',
      },
      {
        id: '594',
        label: 'Leraba',
        country_id: '34',
      },
      {
        id: '595',
        label: 'Mouhoun',
        country_id: '34',
      },
      {
        id: '596',
        label: 'Nahouri',
        country_id: '34',
      },
      {
        id: '597',
        label: 'Namentenga',
        country_id: '34',
      },
      {
        id: '598',
        label: 'Noumbiel',
        country_id: '34',
      },
      {
        id: '599',
        label: 'Oubritenga',
        country_id: '34',
      },
      {
        id: '600',
        label: 'Oudalan',
        country_id: '34',
      },
      {
        id: '601',
        label: 'Passore',
        country_id: '34',
      },
      {
        id: '602',
        label: 'Poni',
        country_id: '34',
      },
      {
        id: '603',
        label: 'Sanguie',
        country_id: '34',
      },
      {
        id: '604',
        label: 'Sanmatenga',
        country_id: '34',
      },
      {
        id: '605',
        label: 'Seno',
        country_id: '34',
      },
      {
        id: '606',
        label: 'Sissili',
        country_id: '34',
      },
      {
        id: '607',
        label: 'Soum',
        country_id: '34',
      },
      {
        id: '608',
        label: 'Sourou',
        country_id: '34',
      },
      {
        id: '609',
        label: 'Tapoa',
        country_id: '34',
      },
      {
        id: '610',
        label: 'Tuy',
        country_id: '34',
      },
      {
        id: '611',
        label: 'Yatenga',
        country_id: '34',
      },
      {
        id: '612',
        label: 'Zondoma',
        country_id: '34',
      },
      {
        id: '613',
        label: 'Zoundweogo',
        country_id: '34',
      },
    ],
  },
  {
    id: '35',
    sortname: 'BI',
    label: 'Burundi',
    states: [
      {
        id: '614',
        label: 'Bubanza',
        country_id: '35',
      },
      {
        id: '615',
        label: 'Bujumbura',
        country_id: '35',
      },
      {
        id: '616',
        label: 'Bururi',
        country_id: '35',
      },
      {
        id: '617',
        label: 'Cankuzo',
        country_id: '35',
      },
      {
        id: '618',
        label: 'Cibitoke',
        country_id: '35',
      },
      {
        id: '619',
        label: 'Gitega',
        country_id: '35',
      },
      {
        id: '620',
        label: 'Karuzi',
        country_id: '35',
      },
      {
        id: '621',
        label: 'Kayanza',
        country_id: '35',
      },
      {
        id: '622',
        label: 'Kirundo',
        country_id: '35',
      },
      {
        id: '623',
        label: 'Makamba',
        country_id: '35',
      },
      {
        id: '624',
        label: 'Muramvya',
        country_id: '35',
      },
      {
        id: '625',
        label: 'Muyinga',
        country_id: '35',
      },
      {
        id: '626',
        label: 'Ngozi',
        country_id: '35',
      },
      {
        id: '627',
        label: 'Rutana',
        country_id: '35',
      },
      {
        id: '628',
        label: 'Ruyigi',
        country_id: '35',
      },
    ],
  },
  {
    id: '36',
    sortname: 'KH',
    label: 'Cambodia',
    states: [
      {
        id: '629',
        label: 'Banteay Mean Chey',
        country_id: '36',
      },
      {
        id: '630',
        label: 'Bat Dambang',
        country_id: '36',
      },
      {
        id: '631',
        label: 'Kampong Cham',
        country_id: '36',
      },
      {
        id: '632',
        label: 'Kampong Chhnang',
        country_id: '36',
      },
      {
        id: '633',
        label: 'Kampong Spoeu',
        country_id: '36',
      },
      {
        id: '634',
        label: 'Kampong Thum',
        country_id: '36',
      },
      {
        id: '635',
        label: 'Kampot',
        country_id: '36',
      },
      {
        id: '636',
        label: 'Kandal',
        country_id: '36',
      },
      {
        id: '637',
        label: 'Kaoh Kong',
        country_id: '36',
      },
      {
        id: '638',
        label: 'Kracheh',
        country_id: '36',
      },
      {
        id: '639',
        label: 'Krong Kaeb',
        country_id: '36',
      },
      {
        id: '640',
        label: 'Krong Pailin',
        country_id: '36',
      },
      {
        id: '641',
        label: 'Krong Preah Sihanouk',
        country_id: '36',
      },
      {
        id: '642',
        label: 'Mondol Kiri',
        country_id: '36',
      },
      {
        id: '643',
        label: 'Otdar Mean Chey',
        country_id: '36',
      },
      {
        id: '644',
        label: 'Phnum Penh',
        country_id: '36',
      },
      {
        id: '645',
        label: 'Pousat',
        country_id: '36',
      },
      {
        id: '646',
        label: 'Preah Vihear',
        country_id: '36',
      },
      {
        id: '647',
        label: 'Prey Veaeng',
        country_id: '36',
      },
      {
        id: '648',
        label: 'Rotanak Kiri',
        country_id: '36',
      },
      {
        id: '649',
        label: 'Siem Reab',
        country_id: '36',
      },
      {
        id: '650',
        label: 'Stueng Traeng',
        country_id: '36',
      },
      {
        id: '651',
        label: 'Svay Rieng',
        country_id: '36',
      },
      {
        id: '652',
        label: 'Takaev',
        country_id: '36',
      },
    ],
  },
  {
    id: '37',
    sortname: 'CM',
    label: 'Cameroon',
    states: [
      {
        id: '653',
        label: 'Adamaoua',
        country_id: '37',
      },
      {
        id: '654',
        label: 'Centre',
        country_id: '37',
      },
      {
        id: '655',
        label: 'Est',
        country_id: '37',
      },
      {
        id: '656',
        label: 'Littoral',
        country_id: '37',
      },
      {
        id: '657',
        label: 'Nord',
        country_id: '37',
      },
      {
        id: '658',
        label: 'Nord Extreme',
        country_id: '37',
      },
      {
        id: '659',
        label: 'Nordouest',
        country_id: '37',
      },
      {
        id: '660',
        label: 'Ouest',
        country_id: '37',
      },
      {
        id: '661',
        label: 'Sud',
        country_id: '37',
      },
      {
        id: '662',
        label: 'Sudouest',
        country_id: '37',
      },
    ],
  },
  {
    id: '39',
    sortname: 'CV',
    label: 'Cape Verde',
    states: [
      {
        id: '676',
        label: 'Boavista',
        country_id: '39',
      },
      {
        id: '677',
        label: 'Brava',
        country_id: '39',
      },
      {
        id: '678',
        label: 'Fogo',
        country_id: '39',
      },
      {
        id: '679',
        label: 'Maio',
        country_id: '39',
      },
      {
        id: '680',
        label: 'Sal',
        country_id: '39',
      },
      {
        id: '681',
        label: 'Santo Antao',
        country_id: '39',
      },
      {
        id: '682',
        label: 'Sao Nicolau',
        country_id: '39',
      },
      {
        id: '683',
        label: 'Sao Tiago',
        country_id: '39',
      },
      {
        id: '684',
        label: 'Sao Vicente',
        country_id: '39',
      },
    ],
  },
  {
    id: '40',
    sortname: 'KY',
    label: 'Cayman Islands',
    states: [
      {
        id: '685',
        label: 'Grand Cayman',
        country_id: '40',
      },
    ],
  },
  {
    id: '41',
    sortname: 'CF',
    label: 'Central African Republic',
    states: [
      {
        id: '686',
        label: 'Bamingui-Bangoran',
        country_id: '41',
      },
      {
        id: '687',
        label: 'Bangui',
        country_id: '41',
      },
      {
        id: '688',
        label: 'Basse-Kotto',
        country_id: '41',
      },
      {
        id: '689',
        label: 'Haut-Mbomou',
        country_id: '41',
      },
      {
        id: '690',
        label: 'Haute-Kotto',
        country_id: '41',
      },
      {
        id: '691',
        label: 'Kemo',
        country_id: '41',
      },
      {
        id: '692',
        label: 'Lobaye',
        country_id: '41',
      },
      {
        id: '693',
        label: 'Mambere-Kadei',
        country_id: '41',
      },
      {
        id: '694',
        label: 'Mbomou',
        country_id: '41',
      },
      {
        id: '695',
        label: 'Nana-Gribizi',
        country_id: '41',
      },
      {
        id: '696',
        label: 'Nana-Mambere',
        country_id: '41',
      },
      {
        id: '697',
        label: 'Ombella Mpoko',
        country_id: '41',
      },
      {
        id: '698',
        label: 'Ouaka',
        country_id: '41',
      },
      {
        id: '699',
        label: 'Ouham',
        country_id: '41',
      },
      {
        id: '700',
        label: 'Ouham-Pende',
        country_id: '41',
      },
      {
        id: '701',
        label: 'Sangha-Mbaere',
        country_id: '41',
      },
      {
        id: '702',
        label: 'Vakaga',
        country_id: '41',
      },
    ],
  },
  {
    id: '42',
    sortname: 'TD',
    label: 'Chad',
    states: [
      {
        id: '703',
        label: 'Batha',
        country_id: '42',
      },
      {
        id: '704',
        label: 'Biltine',
        country_id: '42',
      },
      {
        id: '705',
        label: 'Bourkou-Ennedi-Tibesti',
        country_id: '42',
      },
      {
        id: '706',
        label: 'Chari-Baguirmi',
        country_id: '42',
      },
      {
        id: '707',
        label: 'Guera',
        country_id: '42',
      },
      {
        id: '708',
        label: 'Kanem',
        country_id: '42',
      },
      {
        id: '709',
        label: 'Lac',
        country_id: '42',
      },
      {
        id: '710',
        label: 'Logone Occidental',
        country_id: '42',
      },
      {
        id: '711',
        label: 'Logone Oriental',
        country_id: '42',
      },
      {
        id: '712',
        label: 'Mayo-Kebbi',
        country_id: '42',
      },
      {
        id: '713',
        label: 'Moyen-Chari',
        country_id: '42',
      },
      {
        id: '714',
        label: 'Ouaddai',
        country_id: '42',
      },
      {
        id: '715',
        label: 'Salamat',
        country_id: '42',
      },
      {
        id: '716',
        label: 'Tandjile',
        country_id: '42',
      },
    ],
  },
  {
    id: '43',
    sortname: 'CL',
    label: 'Chile',
    states: [
      {
        id: '717',
        label: 'Aisen',
        country_id: '43',
      },
      {
        id: '718',
        label: 'Antofagasta',
        country_id: '43',
      },
      {
        id: '719',
        label: 'Araucania',
        country_id: '43',
      },
      {
        id: '720',
        label: 'Atacama',
        country_id: '43',
      },
      {
        id: '721',
        label: 'Bio Bio',
        country_id: '43',
      },
      {
        id: '722',
        label: 'Coquimbo',
        country_id: '43',
      },
      {
        id: '723',
        label: "Libertador General Bernardo O'",
        country_id: '43',
      },
      {
        id: '724',
        label: 'Los Lagos',
        country_id: '43',
      },
      {
        id: '725',
        label: 'Magellanes',
        country_id: '43',
      },
      {
        id: '726',
        label: 'Maule',
        country_id: '43',
      },
      {
        id: '727',
        label: 'Metropolitana',
        country_id: '43',
      },
      {
        id: '728',
        label: 'Metropolitana de Santiago',
        country_id: '43',
      },
      {
        id: '729',
        label: 'Tarapaca',
        country_id: '43',
      },
      {
        id: '730',
        label: 'Valparaiso',
        country_id: '43',
      },
    ],
  },
  {
    id: '44',
    sortname: 'CN',
    label: 'China',
    states: [
      {
        id: '731',
        label: 'Anhui',
        country_id: '44',
      },
      {
        id: '732',
        label: 'Anhui Province',
        country_id: '44',
      },
      {
        id: '733',
        label: 'Anhui Sheng',
        country_id: '44',
      },
      {
        id: '734',
        label: 'Aomen',
        country_id: '44',
      },
      {
        id: '735',
        label: 'Beijing',
        country_id: '44',
      },
      {
        id: '736',
        label: 'Beijing Shi',
        country_id: '44',
      },
      {
        id: '737',
        label: 'Chongqing',
        country_id: '44',
      },
      {
        id: '738',
        label: 'Fujian',
        country_id: '44',
      },
      {
        id: '739',
        label: 'Fujian Sheng',
        country_id: '44',
      },
      {
        id: '740',
        label: 'Gansu',
        country_id: '44',
      },
      {
        id: '741',
        label: 'Guangdong',
        country_id: '44',
      },
      {
        id: '742',
        label: 'Guangdong Sheng',
        country_id: '44',
      },
      {
        id: '743',
        label: 'Guangxi',
        country_id: '44',
      },
      {
        id: '744',
        label: 'Guizhou',
        country_id: '44',
      },
      {
        id: '745',
        label: 'Hainan',
        country_id: '44',
      },
      {
        id: '746',
        label: 'Hebei',
        country_id: '44',
      },
      {
        id: '747',
        label: 'Heilongjiang',
        country_id: '44',
      },
      {
        id: '748',
        label: 'Henan',
        country_id: '44',
      },
      {
        id: '749',
        label: 'Hubei',
        country_id: '44',
      },
      {
        id: '750',
        label: 'Hunan',
        country_id: '44',
      },
      {
        id: '751',
        label: 'Jiangsu',
        country_id: '44',
      },
      {
        id: '752',
        label: 'Jiangsu Sheng',
        country_id: '44',
      },
      {
        id: '753',
        label: 'Jiangxi',
        country_id: '44',
      },
      {
        id: '754',
        label: 'Jilin',
        country_id: '44',
      },
      {
        id: '755',
        label: 'Liaoning',
        country_id: '44',
      },
      {
        id: '756',
        label: 'Liaoning Sheng',
        country_id: '44',
      },
      {
        id: '757',
        label: 'Nei Monggol',
        country_id: '44',
      },
      {
        id: '758',
        label: 'Ningxia Hui',
        country_id: '44',
      },
      {
        id: '759',
        label: 'Qinghai',
        country_id: '44',
      },
      {
        id: '760',
        label: 'Shaanxi',
        country_id: '44',
      },
      {
        id: '761',
        label: 'Shandong',
        country_id: '44',
      },
      {
        id: '762',
        label: 'Shandong Sheng',
        country_id: '44',
      },
      {
        id: '763',
        label: 'Shanghai',
        country_id: '44',
      },
      {
        id: '764',
        label: 'Shanxi',
        country_id: '44',
      },
      {
        id: '765',
        label: 'Sichuan',
        country_id: '44',
      },
      {
        id: '766',
        label: 'Tianjin',
        country_id: '44',
      },
      {
        id: '767',
        label: 'Xianggang',
        country_id: '44',
      },
      {
        id: '768',
        label: 'Xinjiang',
        country_id: '44',
      },
      {
        id: '769',
        label: 'Xizang',
        country_id: '44',
      },
      {
        id: '770',
        label: 'Yunnan',
        country_id: '44',
      },
      {
        id: '771',
        label: 'Zhejiang',
        country_id: '44',
      },
      {
        id: '772',
        label: 'Zhejiang Sheng',
        country_id: '44',
      },
    ],
  },
  {
    id: '45',
    sortname: 'CX',
    label: 'Christmas Island',
    states: [
      {
        id: '773',
        label: 'Christmas Island',
        country_id: '45',
      },
    ],
  },
  {
    id: '46',
    sortname: 'CC',
    label: 'Cocos (Keeling) Islands',
    states: [
      {
        id: '774',
        label: 'Cocos (Keeling) Islands',
        country_id: '46',
      },
    ],
  },
  {
    id: '47',
    sortname: 'CO',
    label: 'Colombia',
    states: [
      {
        id: '775',
        label: 'Amazonas',
        country_id: '47',
      },
      {
        id: '776',
        label: 'Antioquia',
        country_id: '47',
      },
      {
        id: '777',
        label: 'Arauca',
        country_id: '47',
      },
      {
        id: '778',
        label: 'Atlantico',
        country_id: '47',
      },
      {
        id: '779',
        label: 'Bogota',
        country_id: '47',
      },
      {
        id: '780',
        label: 'Bolivar',
        country_id: '47',
      },
      {
        id: '781',
        label: 'Boyaca',
        country_id: '47',
      },
      {
        id: '782',
        label: 'Caldas',
        country_id: '47',
      },
      {
        id: '783',
        label: 'Caqueta',
        country_id: '47',
      },
      {
        id: '784',
        label: 'Casanare',
        country_id: '47',
      },
      {
        id: '785',
        label: 'Cauca',
        country_id: '47',
      },
      {
        id: '786',
        label: 'Cesar',
        country_id: '47',
      },
      {
        id: '787',
        label: 'Choco',
        country_id: '47',
      },
      {
        id: '788',
        label: 'Cordoba',
        country_id: '47',
      },
      {
        id: '789',
        label: 'Cundinamarca',
        country_id: '47',
      },
      {
        id: '790',
        label: 'Guainia',
        country_id: '47',
      },
      {
        id: '791',
        label: 'Guaviare',
        country_id: '47',
      },
      {
        id: '792',
        label: 'Huila',
        country_id: '47',
      },
      {
        id: '793',
        label: 'La Guajira',
        country_id: '47',
      },
      {
        id: '794',
        label: 'Magdalena',
        country_id: '47',
      },
      {
        id: '795',
        label: 'Meta',
        country_id: '47',
      },
      {
        id: '796',
        label: 'Narino',
        country_id: '47',
      },
      {
        id: '797',
        label: 'Norte de Santander',
        country_id: '47',
      },
      {
        id: '798',
        label: 'Putumayo',
        country_id: '47',
      },
      {
        id: '799',
        label: 'Quindio',
        country_id: '47',
      },
      {
        id: '800',
        label: 'Risaralda',
        country_id: '47',
      },
      {
        id: '801',
        label: 'San Andres y Providencia',
        country_id: '47',
      },
      {
        id: '802',
        label: 'Santander',
        country_id: '47',
      },
      {
        id: '803',
        label: 'Sucre',
        country_id: '47',
      },
      {
        id: '804',
        label: 'Tolima',
        country_id: '47',
      },
      {
        id: '805',
        label: 'Valle del Cauca',
        country_id: '47',
      },
      {
        id: '806',
        label: 'Vaupes',
        country_id: '47',
      },
      {
        id: '807',
        label: 'Vichada',
        country_id: '47',
      },
    ],
  },
  {
    id: '48',
    sortname: 'KM',
    label: 'Comoros',
    states: [
      {
        id: '808',
        label: 'Mwali',
        country_id: '48',
      },
      {
        id: '809',
        label: 'Njazidja',
        country_id: '48',
      },
      {
        id: '810',
        label: 'Nzwani',
        country_id: '48',
      },
    ],
  },
  {
    id: '49',
    sortname: 'CG',
    label: 'Congo',
    states: [
      {
        id: '811',
        label: 'Bouenza',
        country_id: '49',
      },
      {
        id: '812',
        label: 'Brazzaville',
        country_id: '49',
      },
      {
        id: '813',
        label: 'Cuvette',
        country_id: '49',
      },
      {
        id: '814',
        label: 'Kouilou',
        country_id: '49',
      },
      {
        id: '815',
        label: 'Lekoumou',
        country_id: '49',
      },
      {
        id: '816',
        label: 'Likouala',
        country_id: '49',
      },
      {
        id: '817',
        label: 'Niari',
        country_id: '49',
      },
      {
        id: '818',
        label: 'Plateaux',
        country_id: '49',
      },
      {
        id: '819',
        label: 'Pool',
        country_id: '49',
      },
      {
        id: '820',
        label: 'Sangha',
        country_id: '49',
      },
    ],
  },
  {
    id: '50',
    sortname: 'CD',
    label: 'Congo The Democratic Republic Of The',
    states: [
      {
        id: '821',
        label: 'Bandundu',
        country_id: '50',
      },
      {
        id: '822',
        label: 'Bas-Congo',
        country_id: '50',
      },
      {
        id: '823',
        label: 'Equateur',
        country_id: '50',
      },
      {
        id: '824',
        label: 'Haut-Congo',
        country_id: '50',
      },
      {
        id: '825',
        label: 'Kasai-Occidental',
        country_id: '50',
      },
      {
        id: '826',
        label: 'Kasai-Oriental',
        country_id: '50',
      },
      {
        id: '827',
        label: 'Katanga',
        country_id: '50',
      },
      {
        id: '828',
        label: 'Kinshasa',
        country_id: '50',
      },
      {
        id: '829',
        label: 'Maniema',
        country_id: '50',
      },
      {
        id: '830',
        label: 'Nord-Kivu',
        country_id: '50',
      },
      {
        id: '831',
        label: 'Sud-Kivu',
        country_id: '50',
      },
    ],
  },
  {
    id: '51',
    sortname: 'CK',
    label: 'Cook Islands',
    states: [
      {
        id: '832',
        label: 'Aitutaki',
        country_id: '51',
      },
      {
        id: '833',
        label: 'Atiu',
        country_id: '51',
      },
      {
        id: '834',
        label: 'Mangaia',
        country_id: '51',
      },
      {
        id: '835',
        label: 'Manihiki',
        country_id: '51',
      },
      {
        id: '836',
        label: 'Mauke',
        country_id: '51',
      },
      {
        id: '837',
        label: 'Mitiaro',
        country_id: '51',
      },
      {
        id: '838',
        label: 'Nassau',
        country_id: '51',
      },
      {
        id: '839',
        label: 'Pukapuka',
        country_id: '51',
      },
      {
        id: '840',
        label: 'Rakahanga',
        country_id: '51',
      },
      {
        id: '841',
        label: 'Rarotonga',
        country_id: '51',
      },
      {
        id: '842',
        label: 'Tongareva',
        country_id: '51',
      },
    ],
  },
  {
    id: '52',
    sortname: 'CR',
    label: 'Costa Rica',
    states: [
      {
        id: '843',
        label: 'Alajuela',
        country_id: '52',
      },
      {
        id: '844',
        label: 'Cartago',
        country_id: '52',
      },
      {
        id: '845',
        label: 'Guanacaste',
        country_id: '52',
      },
      {
        id: '846',
        label: 'Heredia',
        country_id: '52',
      },
      {
        id: '847',
        label: 'Limon',
        country_id: '52',
      },
      {
        id: '848',
        label: 'Puntarenas',
        country_id: '52',
      },
      {
        id: '849',
        label: 'San Jose',
        country_id: '52',
      },
    ],
  },
  {
    id: '53',
    sortname: 'CI',
    label: "Cote D'Ivoire (Ivory Coast)",
    states: [
      {
        id: '850',
        label: 'Abidjan',
        country_id: '53',
      },
      {
        id: '851',
        label: 'Agneby',
        country_id: '53',
      },
      {
        id: '852',
        label: 'Bafing',
        country_id: '53',
      },
      {
        id: '853',
        label: 'Denguele',
        country_id: '53',
      },
      {
        id: '854',
        label: 'Dix-huit Montagnes',
        country_id: '53',
      },
      {
        id: '855',
        label: 'Fromager',
        country_id: '53',
      },
      {
        id: '856',
        label: 'Haut-Sassandra',
        country_id: '53',
      },
      {
        id: '857',
        label: 'Lacs',
        country_id: '53',
      },
      {
        id: '858',
        label: 'Lagunes',
        country_id: '53',
      },
      {
        id: '859',
        label: 'Marahoue',
        country_id: '53',
      },
      {
        id: '860',
        label: 'Moyen-Cavally',
        country_id: '53',
      },
      {
        id: '861',
        label: 'Moyen-Comoe',
        country_id: '53',
      },
      {
        id: '862',
        label: "N'zi-Comoe",
        country_id: '53',
      },
      {
        id: '863',
        label: 'Sassandra',
        country_id: '53',
      },
      {
        id: '864',
        label: 'Savanes',
        country_id: '53',
      },
      {
        id: '865',
        label: 'Sud-Bandama',
        country_id: '53',
      },
      {
        id: '866',
        label: 'Sud-Comoe',
        country_id: '53',
      },
      {
        id: '867',
        label: 'Vallee du Bandama',
        country_id: '53',
      },
      {
        id: '868',
        label: 'Worodougou',
        country_id: '53',
      },
      {
        id: '869',
        label: 'Zanzan',
        country_id: '53',
      },
    ],
  },
  {
    id: '54',
    sortname: 'HR',
    label: 'Croatia (Hrvatska)',
    states: [
      {
        id: '870',
        label: 'Bjelovar-Bilogora',
        country_id: '54',
      },
      {
        id: '871',
        label: 'Dubrovnik-Neretva',
        country_id: '54',
      },
      {
        id: '872',
        label: 'Grad Zagreb',
        country_id: '54',
      },
      {
        id: '873',
        label: 'Istra',
        country_id: '54',
      },
      {
        id: '874',
        label: 'Karlovac',
        country_id: '54',
      },
      {
        id: '875',
        label: 'Koprivnica-Krizhevci',
        country_id: '54',
      },
      {
        id: '876',
        label: 'Krapina-Zagorje',
        country_id: '54',
      },
      {
        id: '877',
        label: 'Lika-Senj',
        country_id: '54',
      },
      {
        id: '878',
        label: 'Medhimurje',
        country_id: '54',
      },
      {
        id: '879',
        label: 'Medimurska Zupanija',
        country_id: '54',
      },
      {
        id: '880',
        label: 'Osijek-Baranja',
        country_id: '54',
      },
      {
        id: '881',
        label: 'Osjecko-Baranjska Zupanija',
        country_id: '54',
      },
      {
        id: '882',
        label: 'Pozhega-Slavonija',
        country_id: '54',
      },
      {
        id: '883',
        label: 'Primorje-Gorski Kotar',
        country_id: '54',
      },
      {
        id: '884',
        label: 'Shibenik-Knin',
        country_id: '54',
      },
      {
        id: '885',
        label: 'Sisak-Moslavina',
        country_id: '54',
      },
      {
        id: '886',
        label: 'Slavonski Brod-Posavina',
        country_id: '54',
      },
      {
        id: '887',
        label: 'Split-Dalmacija',
        country_id: '54',
      },
      {
        id: '888',
        label: 'Varazhdin',
        country_id: '54',
      },
      {
        id: '889',
        label: 'Virovitica-Podravina',
        country_id: '54',
      },
      {
        id: '890',
        label: 'Vukovar-Srijem',
        country_id: '54',
      },
      {
        id: '891',
        label: 'Zadar',
        country_id: '54',
      },
      {
        id: '892',
        label: 'Zagreb',
        country_id: '54',
      },
    ],
  },
  {
    id: '55',
    sortname: 'CU',
    label: 'Cuba',
    states: [
      {
        id: '893',
        label: 'Camaguey',
        country_id: '55',
      },
      {
        id: '894',
        label: 'Ciego de Avila',
        country_id: '55',
      },
      {
        id: '895',
        label: 'Cienfuegos',
        country_id: '55',
      },
      {
        id: '896',
        label: 'Ciudad de la Habana',
        country_id: '55',
      },
      {
        id: '897',
        label: 'Granma',
        country_id: '55',
      },
      {
        id: '898',
        label: 'Guantanamo',
        country_id: '55',
      },
      {
        id: '899',
        label: 'Habana',
        country_id: '55',
      },
      {
        id: '900',
        label: 'Holguin',
        country_id: '55',
      },
      {
        id: '901',
        label: 'Isla de la Juventud',
        country_id: '55',
      },
      {
        id: '902',
        label: 'La Habana',
        country_id: '55',
      },
      {
        id: '903',
        label: 'Las Tunas',
        country_id: '55',
      },
      {
        id: '904',
        label: 'Matanzas',
        country_id: '55',
      },
      {
        id: '905',
        label: 'Pinar del Rio',
        country_id: '55',
      },
      {
        id: '906',
        label: 'Sancti Spiritus',
        country_id: '55',
      },
      {
        id: '907',
        label: 'Santiago de Cuba',
        country_id: '55',
      },
      {
        id: '908',
        label: 'Villa Clara',
        country_id: '55',
      },
    ],
  },
  {
    id: '56',
    sortname: 'CY',
    label: 'Cyprus',
    states: [
      {
        id: '909',
        label: 'Government controlled area',
        country_id: '56',
      },
      {
        id: '910',
        label: 'Limassol',
        country_id: '56',
      },
      {
        id: '911',
        label: 'Nicosia District',
        country_id: '56',
      },
      {
        id: '912',
        label: 'Paphos',
        country_id: '56',
      },
      {
        id: '913',
        label: 'Turkish controlled area',
        country_id: '56',
      },
    ],
  },
  {
    id: '57',
    sortname: 'CZ',
    label: 'Czech Republic',
    states: [
      {
        id: '914',
        label: 'Central Bohemian',
        country_id: '57',
      },
      {
        id: '915',
        label: 'Frycovice',
        country_id: '57',
      },
      {
        id: '916',
        label: 'Jihocesky Kraj',
        country_id: '57',
      },
      {
        id: '917',
        label: 'Jihochesky',
        country_id: '57',
      },
      {
        id: '918',
        label: 'Jihomoravsky',
        country_id: '57',
      },
      {
        id: '919',
        label: 'Karlovarsky',
        country_id: '57',
      },
      {
        id: '920',
        label: 'Klecany',
        country_id: '57',
      },
      {
        id: '921',
        label: 'Kralovehradecky',
        country_id: '57',
      },
      {
        id: '922',
        label: 'Liberecky',
        country_id: '57',
      },
      {
        id: '923',
        label: 'Lipov',
        country_id: '57',
      },
      {
        id: '924',
        label: 'Moravskoslezsky',
        country_id: '57',
      },
      {
        id: '925',
        label: 'Olomoucky',
        country_id: '57',
      },
      {
        id: '926',
        label: 'Olomoucky Kraj',
        country_id: '57',
      },
      {
        id: '927',
        label: 'Pardubicky',
        country_id: '57',
      },
      {
        id: '928',
        label: 'Plzensky',
        country_id: '57',
      },
      {
        id: '929',
        label: 'Praha',
        country_id: '57',
      },
      {
        id: '930',
        label: 'Rajhrad',
        country_id: '57',
      },
      {
        id: '931',
        label: 'Smirice',
        country_id: '57',
      },
      {
        id: '932',
        label: 'South Moravian',
        country_id: '57',
      },
      {
        id: '933',
        label: 'Straz nad Nisou',
        country_id: '57',
      },
      {
        id: '934',
        label: 'Stredochesky',
        country_id: '57',
      },
      {
        id: '935',
        label: 'Unicov',
        country_id: '57',
      },
      {
        id: '936',
        label: 'Ustecky',
        country_id: '57',
      },
      {
        id: '937',
        label: 'Valletta',
        country_id: '57',
      },
      {
        id: '938',
        label: 'Velesin',
        country_id: '57',
      },
      {
        id: '939',
        label: 'Vysochina',
        country_id: '57',
      },
      {
        id: '940',
        label: 'Zlinsky',
        country_id: '57',
      },
    ],
  },
  {
    id: '58',
    sortname: 'DK',
    label: 'Denmark',
    states: [
      {
        id: '941',
        label: 'Arhus',
        country_id: '58',
      },
      {
        id: '942',
        label: 'Bornholm',
        country_id: '58',
      },
      {
        id: '943',
        label: 'Frederiksborg',
        country_id: '58',
      },
      {
        id: '944',
        label: 'Fyn',
        country_id: '58',
      },
      {
        id: '945',
        label: 'Hovedstaden',
        country_id: '58',
      },
      {
        id: '946',
        label: 'Kobenhavn',
        country_id: '58',
      },
      {
        id: '947',
        label: 'Kobenhavns Amt',
        country_id: '58',
      },
      {
        id: '948',
        label: 'Kobenhavns Kommune',
        country_id: '58',
      },
      {
        id: '949',
        label: 'Nordjylland',
        country_id: '58',
      },
      {
        id: '950',
        label: 'Ribe',
        country_id: '58',
      },
      {
        id: '951',
        label: 'Ringkobing',
        country_id: '58',
      },
      {
        id: '952',
        label: 'Roervig',
        country_id: '58',
      },
      {
        id: '953',
        label: 'Roskilde',
        country_id: '58',
      },
      {
        id: '954',
        label: 'Roslev',
        country_id: '58',
      },
      {
        id: '955',
        label: 'Sjaelland',
        country_id: '58',
      },
      {
        id: '956',
        label: 'Soeborg',
        country_id: '58',
      },
      {
        id: '957',
        label: 'Sonderjylland',
        country_id: '58',
      },
      {
        id: '958',
        label: 'Storstrom',
        country_id: '58',
      },
      {
        id: '959',
        label: 'Syddanmark',
        country_id: '58',
      },
      {
        id: '960',
        label: 'Toelloese',
        country_id: '58',
      },
      {
        id: '961',
        label: 'Vejle',
        country_id: '58',
      },
      {
        id: '962',
        label: 'Vestsjalland',
        country_id: '58',
      },
      {
        id: '963',
        label: 'Viborg',
        country_id: '58',
      },
    ],
  },
  {
    id: '59',
    sortname: 'DJ',
    label: 'Djibouti',
    states: [
      {
        id: '964',
        label: "'Ali Sabih",
        country_id: '59',
      },
      {
        id: '965',
        label: 'Dikhil',
        country_id: '59',
      },
      {
        id: '966',
        label: 'Jibuti',
        country_id: '59',
      },
      {
        id: '967',
        label: 'Tajurah',
        country_id: '59',
      },
      {
        id: '968',
        label: 'Ubuk',
        country_id: '59',
      },
    ],
  },
  {
    id: '60',
    sortname: 'DM',
    label: 'Dominica',
    states: [
      {
        id: '969',
        label: 'Saint Andrew',
        country_id: '60',
      },
      {
        id: '970',
        label: 'Saint David',
        country_id: '60',
      },
      {
        id: '971',
        label: 'Saint George',
        country_id: '60',
      },
      {
        id: '972',
        label: 'Saint John',
        country_id: '60',
      },
      {
        id: '973',
        label: 'Saint Joseph',
        country_id: '60',
      },
      {
        id: '974',
        label: 'Saint Luke',
        country_id: '60',
      },
      {
        id: '975',
        label: 'Saint Mark',
        country_id: '60',
      },
      {
        id: '976',
        label: 'Saint Patrick',
        country_id: '60',
      },
      {
        id: '977',
        label: 'Saint Paul',
        country_id: '60',
      },
      {
        id: '978',
        label: 'Saint Peter',
        country_id: '60',
      },
    ],
  },
  {
    id: '61',
    sortname: 'DO',
    label: 'Dominican Republic',
    states: [
      {
        id: '979',
        label: 'Azua',
        country_id: '61',
      },
      {
        id: '980',
        label: 'Bahoruco',
        country_id: '61',
      },
      {
        id: '981',
        label: 'Barahona',
        country_id: '61',
      },
      {
        id: '982',
        label: 'Dajabon',
        country_id: '61',
      },
      {
        id: '983',
        label: 'Distrito Nacional',
        country_id: '61',
      },
      {
        id: '984',
        label: 'Duarte',
        country_id: '61',
      },
      {
        id: '985',
        label: 'El Seybo',
        country_id: '61',
      },
      {
        id: '986',
        label: 'Elias Pina',
        country_id: '61',
      },
      {
        id: '987',
        label: 'Espaillat',
        country_id: '61',
      },
      {
        id: '988',
        label: 'Hato Mayor',
        country_id: '61',
      },
      {
        id: '989',
        label: 'Independencia',
        country_id: '61',
      },
      {
        id: '990',
        label: 'La Altagracia',
        country_id: '61',
      },
      {
        id: '991',
        label: 'La Romana',
        country_id: '61',
      },
      {
        id: '992',
        label: 'La Vega',
        country_id: '61',
      },
      {
        id: '993',
        label: 'Maria Trinidad Sanchez',
        country_id: '61',
      },
      {
        id: '994',
        label: 'Monsenor Nouel',
        country_id: '61',
      },
      {
        id: '995',
        label: 'Monte Cristi',
        country_id: '61',
      },
      {
        id: '996',
        label: 'Monte Plata',
        country_id: '61',
      },
      {
        id: '997',
        label: 'Pedernales',
        country_id: '61',
      },
      {
        id: '998',
        label: 'Peravia',
        country_id: '61',
      },
      {
        id: '999',
        label: 'Puerto Plata',
        country_id: '61',
      },
      {
        id: '1000',
        label: 'Salcedo',
        country_id: '61',
      },
      {
        id: '1001',
        label: 'Samana',
        country_id: '61',
      },
      {
        id: '1002',
        label: 'San Cristobal',
        country_id: '61',
      },
      {
        id: '1003',
        label: 'San Juan',
        country_id: '61',
      },
      {
        id: '1004',
        label: 'San Pedro de Macoris',
        country_id: '61',
      },
      {
        id: '1005',
        label: 'Sanchez Ramirez',
        country_id: '61',
      },
      {
        id: '1006',
        label: 'Santiago',
        country_id: '61',
      },
      {
        id: '1007',
        label: 'Santiago Rodriguez',
        country_id: '61',
      },
      {
        id: '1008',
        label: 'Valverde',
        country_id: '61',
      },
    ],
  },
  {
    id: '62',
    sortname: 'TP',
    label: 'East Timor',
    states: [
      {
        id: '1009',
        label: 'Aileu',
        country_id: '62',
      },
      {
        id: '1010',
        label: 'Ainaro',
        country_id: '62',
      },
      {
        id: '1011',
        label: 'Ambeno',
        country_id: '62',
      },
      {
        id: '1012',
        label: 'Baucau',
        country_id: '62',
      },
      {
        id: '1013',
        label: 'Bobonaro',
        country_id: '62',
      },
      {
        id: '1014',
        label: 'Cova Lima',
        country_id: '62',
      },
      {
        id: '1015',
        label: 'Dili',
        country_id: '62',
      },
      {
        id: '1016',
        label: 'Ermera',
        country_id: '62',
      },
      {
        id: '1017',
        label: 'Lautem',
        country_id: '62',
      },
      {
        id: '1018',
        label: 'Liquica',
        country_id: '62',
      },
      {
        id: '1019',
        label: 'Manatuto',
        country_id: '62',
      },
      {
        id: '1020',
        label: 'Manufahi',
        country_id: '62',
      },
      {
        id: '1021',
        label: 'Viqueque',
        country_id: '62',
      },
    ],
  },
  {
    id: '63',
    sortname: 'EC',
    label: 'Ecuador',
    states: [
      {
        id: '1022',
        label: 'Azuay',
        country_id: '63',
      },
      {
        id: '1023',
        label: 'Bolivar',
        country_id: '63',
      },
      {
        id: '1024',
        label: 'Canar',
        country_id: '63',
      },
      {
        id: '1025',
        label: 'Carchi',
        country_id: '63',
      },
      {
        id: '1026',
        label: 'Chimborazo',
        country_id: '63',
      },
      {
        id: '1027',
        label: 'Cotopaxi',
        country_id: '63',
      },
      {
        id: '1028',
        label: 'El Oro',
        country_id: '63',
      },
      {
        id: '1029',
        label: 'Esmeraldas',
        country_id: '63',
      },
      {
        id: '1030',
        label: 'Galapagos',
        country_id: '63',
      },
      {
        id: '1031',
        label: 'Guayas',
        country_id: '63',
      },
      {
        id: '1032',
        label: 'Imbabura',
        country_id: '63',
      },
      {
        id: '1033',
        label: 'Loja',
        country_id: '63',
      },
      {
        id: '1034',
        label: 'Los Rios',
        country_id: '63',
      },
      {
        id: '1035',
        label: 'Manabi',
        country_id: '63',
      },
      {
        id: '1036',
        label: 'Morona Santiago',
        country_id: '63',
      },
      {
        id: '1037',
        label: 'Napo',
        country_id: '63',
      },
      {
        id: '1038',
        label: 'Orellana',
        country_id: '63',
      },
      {
        id: '1039',
        label: 'Pastaza',
        country_id: '63',
      },
      {
        id: '1040',
        label: 'Pichincha',
        country_id: '63',
      },
      {
        id: '1041',
        label: 'Sucumbios',
        country_id: '63',
      },
      {
        id: '1042',
        label: 'Tungurahua',
        country_id: '63',
      },
      {
        id: '1043',
        label: 'Zamora Chinchipe',
        country_id: '63',
      },
    ],
  },
  {
    id: '64',
    sortname: 'EG',
    label: 'Egypt',
    states: [
      {
        id: '1044',
        label: 'Aswan',
        country_id: '64',
      },
      {
        id: '1045',
        label: 'Asyut',
        country_id: '64',
      },
      {
        id: '1046',
        label: 'Bani Suwayf',
        country_id: '64',
      },
      {
        id: '1047',
        label: "Bur Sa'id",
        country_id: '64',
      },
      {
        id: '1048',
        label: 'Cairo',
        country_id: '64',
      },
      {
        id: '1049',
        label: 'Dumyat',
        country_id: '64',
      },
      {
        id: '1050',
        label: 'Kafr-ash-Shaykh',
        country_id: '64',
      },
      {
        id: '1051',
        label: 'Matruh',
        country_id: '64',
      },
      {
        id: '1052',
        label: 'Muhafazat ad Daqahliyah',
        country_id: '64',
      },
      {
        id: '1053',
        label: 'Muhafazat al Fayyum',
        country_id: '64',
      },
      {
        id: '1054',
        label: 'Muhafazat al Gharbiyah',
        country_id: '64',
      },
      {
        id: '1055',
        label: 'Muhafazat al Iskandariyah',
        country_id: '64',
      },
      {
        id: '1056',
        label: 'Muhafazat al Qahirah',
        country_id: '64',
      },
      {
        id: '1057',
        label: 'Qina',
        country_id: '64',
      },
      {
        id: '1058',
        label: 'Sawhaj',
        country_id: '64',
      },
      {
        id: '1059',
        label: 'Sina al-Janubiyah',
        country_id: '64',
      },
      {
        id: '1060',
        label: 'Sina ash-Shamaliyah',
        country_id: '64',
      },
      {
        id: '1061',
        label: 'ad-Daqahliyah',
        country_id: '64',
      },
      {
        id: '1062',
        label: 'al-Bahr-al-Ahmar',
        country_id: '64',
      },
      {
        id: '1063',
        label: 'al-Buhayrah',
        country_id: '64',
      },
      {
        id: '1064',
        label: 'al-Fayyum',
        country_id: '64',
      },
      {
        id: '1065',
        label: 'al-Gharbiyah',
        country_id: '64',
      },
      {
        id: '1066',
        label: 'al-Iskandariyah',
        country_id: '64',
      },
      {
        id: '1067',
        label: 'al-Ismailiyah',
        country_id: '64',
      },
      {
        id: '1068',
        label: 'al-Jizah',
        country_id: '64',
      },
      {
        id: '1069',
        label: 'al-Minufiyah',
        country_id: '64',
      },
      {
        id: '1070',
        label: 'al-Minya',
        country_id: '64',
      },
      {
        id: '1071',
        label: 'al-Qahira',
        country_id: '64',
      },
      {
        id: '1072',
        label: 'al-Qalyubiyah',
        country_id: '64',
      },
      {
        id: '1073',
        label: 'al-Uqsur',
        country_id: '64',
      },
      {
        id: '1074',
        label: 'al-Wadi al-Jadid',
        country_id: '64',
      },
      {
        id: '1075',
        label: 'as-Suways',
        country_id: '64',
      },
      {
        id: '1076',
        label: 'ash-Sharqiyah',
        country_id: '64',
      },
    ],
  },
  {
    id: '65',
    sortname: 'SV',
    label: 'El Salvador',
    states: [
      {
        id: '1077',
        label: 'Ahuachapan',
        country_id: '65',
      },
      {
        id: '1078',
        label: 'Cabanas',
        country_id: '65',
      },
      {
        id: '1079',
        label: 'Chalatenango',
        country_id: '65',
      },
      {
        id: '1080',
        label: 'Cuscatlan',
        country_id: '65',
      },
      {
        id: '1081',
        label: 'La Libertad',
        country_id: '65',
      },
      {
        id: '1082',
        label: 'La Paz',
        country_id: '65',
      },
      {
        id: '1083',
        label: 'La Union',
        country_id: '65',
      },
      {
        id: '1084',
        label: 'Morazan',
        country_id: '65',
      },
      {
        id: '1085',
        label: 'San Miguel',
        country_id: '65',
      },
      {
        id: '1086',
        label: 'San Salvador',
        country_id: '65',
      },
      {
        id: '1087',
        label: 'San Vicente',
        country_id: '65',
      },
      {
        id: '1088',
        label: 'Santa Ana',
        country_id: '65',
      },
      {
        id: '1089',
        label: 'Sonsonate',
        country_id: '65',
      },
      {
        id: '1090',
        label: 'Usulutan',
        country_id: '65',
      },
    ],
  },
  {
    id: '66',
    sortname: 'GQ',
    label: 'Equatorial Guinea',
    states: [
      {
        id: '1091',
        label: 'Annobon',
        country_id: '66',
      },
      {
        id: '1092',
        label: 'Bioko Norte',
        country_id: '66',
      },
      {
        id: '1093',
        label: 'Bioko Sur',
        country_id: '66',
      },
      {
        id: '1094',
        label: 'Centro Sur',
        country_id: '66',
      },
      {
        id: '1095',
        label: 'Kie-Ntem',
        country_id: '66',
      },
      {
        id: '1096',
        label: 'Litoral',
        country_id: '66',
      },
      {
        id: '1097',
        label: 'Wele-Nzas',
        country_id: '66',
      },
    ],
  },
  {
    id: '67',
    sortname: 'ER',
    label: 'Eritrea',
    states: [
      {
        id: '1098',
        label: 'Anseba',
        country_id: '67',
      },
      {
        id: '1099',
        label: 'Debub',
        country_id: '67',
      },
      {
        id: '1100',
        label: 'Debub-Keih-Bahri',
        country_id: '67',
      },
      {
        id: '1101',
        label: 'Gash-Barka',
        country_id: '67',
      },
      {
        id: '1102',
        label: 'Maekel',
        country_id: '67',
      },
      {
        id: '1103',
        label: 'Semien-Keih-Bahri',
        country_id: '67',
      },
    ],
  },
  {
    id: '68',
    sortname: 'EE',
    label: 'Estonia',
    states: [
      {
        id: '1104',
        label: 'Harju',
        country_id: '68',
      },
      {
        id: '1105',
        label: 'Hiiu',
        country_id: '68',
      },
      {
        id: '1106',
        label: 'Ida-Viru',
        country_id: '68',
      },
      {
        id: '1107',
        label: 'Jarva',
        country_id: '68',
      },
      {
        id: '1108',
        label: 'Jogeva',
        country_id: '68',
      },
      {
        id: '1109',
        label: 'Laane',
        country_id: '68',
      },
      {
        id: '1110',
        label: 'Laane-Viru',
        country_id: '68',
      },
      {
        id: '1111',
        label: 'Parnu',
        country_id: '68',
      },
      {
        id: '1112',
        label: 'Polva',
        country_id: '68',
      },
      {
        id: '1113',
        label: 'Rapla',
        country_id: '68',
      },
      {
        id: '1114',
        label: 'Saare',
        country_id: '68',
      },
      {
        id: '1115',
        label: 'Tartu',
        country_id: '68',
      },
      {
        id: '1116',
        label: 'Valga',
        country_id: '68',
      },
      {
        id: '1117',
        label: 'Viljandi',
        country_id: '68',
      },
      {
        id: '1118',
        label: 'Voru',
        country_id: '68',
      },
    ],
  },
  {
    id: '69',
    sortname: 'ET',
    label: 'Ethiopia',
    states: [
      {
        id: '1119',
        label: 'Addis Abeba',
        country_id: '69',
      },
      {
        id: '1120',
        label: 'Afar',
        country_id: '69',
      },
      {
        id: '1121',
        label: 'Amhara',
        country_id: '69',
      },
      {
        id: '1122',
        label: 'Benishangul',
        country_id: '69',
      },
      {
        id: '1123',
        label: 'Diredawa',
        country_id: '69',
      },
      {
        id: '1124',
        label: 'Gambella',
        country_id: '69',
      },
      {
        id: '1125',
        label: 'Harar',
        country_id: '69',
      },
      {
        id: '1126',
        label: 'Jigjiga',
        country_id: '69',
      },
      {
        id: '1127',
        label: 'Mekele',
        country_id: '69',
      },
      {
        id: '1128',
        label: 'Oromia',
        country_id: '69',
      },
      {
        id: '1129',
        label: 'Somali',
        country_id: '69',
      },
      {
        id: '1130',
        label: 'Southern',
        country_id: '69',
      },
      {
        id: '1131',
        label: 'Tigray',
        country_id: '69',
      },
    ],
  },
  {
    id: '70',
    sortname: 'XA',
    label: 'External Territories of Australia',
    states: [
      {
        id: '1132',
        label: 'Christmas Island',
        country_id: '70',
      },
      {
        id: '1133',
        label: 'Cocos Islands',
        country_id: '70',
      },
      {
        id: '1134',
        label: 'Coral Sea Islands',
        country_id: '70',
      },
    ],
  },
  {
    id: '71',
    sortname: 'FK',
    label: 'Falkland Islands',
    states: [
      {
        id: '1135',
        label: 'Falkland Islands',
        country_id: '71',
      },
      {
        id: '1136',
        label: 'South Georgia',
        country_id: '71',
      },
    ],
  },
  {
    id: '72',
    sortname: 'FO',
    label: 'Faroe Islands',
    states: [
      {
        id: '1137',
        label: 'Klaksvik',
        country_id: '72',
      },
      {
        id: '1138',
        label: 'Nor ara Eysturoy',
        country_id: '72',
      },
      {
        id: '1139',
        label: 'Nor oy',
        country_id: '72',
      },
      {
        id: '1140',
        label: 'Sandoy',
        country_id: '72',
      },
      {
        id: '1141',
        label: 'Streymoy',
        country_id: '72',
      },
      {
        id: '1142',
        label: 'Su uroy',
        country_id: '72',
      },
      {
        id: '1143',
        label: 'Sy ra Eysturoy',
        country_id: '72',
      },
      {
        id: '1144',
        label: 'Torshavn',
        country_id: '72',
      },
      {
        id: '1145',
        label: 'Vaga',
        country_id: '72',
      },
    ],
  },
  {
    id: '73',
    sortname: 'FJ',
    label: 'Fiji Islands',
    states: [
      {
        id: '1146',
        label: 'Central',
        country_id: '73',
      },
      {
        id: '1147',
        label: 'Eastern',
        country_id: '73',
      },
      {
        id: '1148',
        label: 'Northern',
        country_id: '73',
      },
      {
        id: '1149',
        label: 'South Pacific',
        country_id: '73',
      },
      {
        id: '1150',
        label: 'Western',
        country_id: '73',
      },
    ],
  },
  {
    id: '74',
    sortname: 'FI',
    label: 'Finland',
    states: [
      {
        id: '1151',
        label: 'Ahvenanmaa',
        country_id: '74',
      },
      {
        id: '1152',
        label: 'Etela-Karjala',
        country_id: '74',
      },
      {
        id: '1153',
        label: 'Etela-Pohjanmaa',
        country_id: '74',
      },
      {
        id: '1154',
        label: 'Etela-Savo',
        country_id: '74',
      },
      {
        id: '1155',
        label: 'Etela-Suomen Laani',
        country_id: '74',
      },
      {
        id: '1156',
        label: 'Ita-Suomen Laani',
        country_id: '74',
      },
      {
        id: '1157',
        label: 'Ita-Uusimaa',
        country_id: '74',
      },
      {
        id: '1158',
        label: 'Kainuu',
        country_id: '74',
      },
      {
        id: '1159',
        label: 'Kanta-Hame',
        country_id: '74',
      },
      {
        id: '1160',
        label: 'Keski-Pohjanmaa',
        country_id: '74',
      },
      {
        id: '1161',
        label: 'Keski-Suomi',
        country_id: '74',
      },
      {
        id: '1162',
        label: 'Kymenlaakso',
        country_id: '74',
      },
      {
        id: '1163',
        label: 'Lansi-Suomen Laani',
        country_id: '74',
      },
      {
        id: '1164',
        label: 'Lappi',
        country_id: '74',
      },
      {
        id: '1165',
        label: 'Northern Savonia',
        country_id: '74',
      },
      {
        id: '1166',
        label: 'Ostrobothnia',
        country_id: '74',
      },
      {
        id: '1167',
        label: 'Oulun Laani',
        country_id: '74',
      },
      {
        id: '1168',
        label: 'Paijat-Hame',
        country_id: '74',
      },
      {
        id: '1169',
        label: 'Pirkanmaa',
        country_id: '74',
      },
      {
        id: '1170',
        label: 'Pohjanmaa',
        country_id: '74',
      },
      {
        id: '1171',
        label: 'Pohjois-Karjala',
        country_id: '74',
      },
      {
        id: '1172',
        label: 'Pohjois-Pohjanmaa',
        country_id: '74',
      },
      {
        id: '1173',
        label: 'Pohjois-Savo',
        country_id: '74',
      },
      {
        id: '1174',
        label: 'Saarijarvi',
        country_id: '74',
      },
      {
        id: '1175',
        label: 'Satakunta',
        country_id: '74',
      },
      {
        id: '1176',
        label: 'Southern Savonia',
        country_id: '74',
      },
      {
        id: '1177',
        label: 'Tavastia Proper',
        country_id: '74',
      },
      {
        id: '1178',
        label: 'Uleaborgs Lan',
        country_id: '74',
      },
      {
        id: '1179',
        label: 'Uusimaa',
        country_id: '74',
      },
      {
        id: '1180',
        label: 'Varsinais-Suomi',
        country_id: '74',
      },
    ],
  },
  {
    id: '75',
    sortname: 'FR',
    label: 'France',
    states: [
      {
        id: '1181',
        label: 'Ain',
        country_id: '75',
      },
      {
        id: '1182',
        label: 'Aisne',
        country_id: '75',
      },
      {
        id: '1183',
        label: 'Albi Le Sequestre',
        country_id: '75',
      },
      {
        id: '1184',
        label: 'Allier',
        country_id: '75',
      },
      {
        id: '1185',
        label: 'Alpes-Cote dAzur',
        country_id: '75',
      },
      {
        id: '1186',
        label: 'Alpes-Maritimes',
        country_id: '75',
      },
      {
        id: '1187',
        label: 'Alpes-de-Haute-Provence',
        country_id: '75',
      },
      {
        id: '1188',
        label: 'Alsace',
        country_id: '75',
      },
      {
        id: '1189',
        label: 'Aquitaine',
        country_id: '75',
      },
      {
        id: '1190',
        label: 'Ardeche',
        country_id: '75',
      },
      {
        id: '1191',
        label: 'Ardennes',
        country_id: '75',
      },
      {
        id: '1192',
        label: 'Ariege',
        country_id: '75',
      },
      {
        id: '1193',
        label: 'Aube',
        country_id: '75',
      },
      {
        id: '1194',
        label: 'Aude',
        country_id: '75',
      },
      {
        id: '1195',
        label: 'Auvergne',
        country_id: '75',
      },
      {
        id: '1196',
        label: 'Aveyron',
        country_id: '75',
      },
      {
        id: '1197',
        label: 'Bas-Rhin',
        country_id: '75',
      },
      {
        id: '1198',
        label: 'Basse-Normandie',
        country_id: '75',
      },
      {
        id: '1199',
        label: 'Bouches-du-Rhone',
        country_id: '75',
      },
      {
        id: '1200',
        label: 'Bourgogne',
        country_id: '75',
      },
      {
        id: '1201',
        label: 'Bretagne',
        country_id: '75',
      },
      {
        id: '1202',
        label: 'Brittany',
        country_id: '75',
      },
      {
        id: '1203',
        label: 'Burgundy',
        country_id: '75',
      },
      {
        id: '1204',
        label: 'Calvados',
        country_id: '75',
      },
      {
        id: '1205',
        label: 'Cantal',
        country_id: '75',
      },
      {
        id: '1206',
        label: 'Cedex',
        country_id: '75',
      },
      {
        id: '1207',
        label: 'Centre',
        country_id: '75',
      },
      {
        id: '1208',
        label: 'Charente',
        country_id: '75',
      },
      {
        id: '1209',
        label: 'Charente-Maritime',
        country_id: '75',
      },
      {
        id: '1210',
        label: 'Cher',
        country_id: '75',
      },
      {
        id: '1211',
        label: 'Correze',
        country_id: '75',
      },
      {
        id: '1212',
        label: 'Corse-du-Sud',
        country_id: '75',
      },
      {
        id: '1213',
        label: "Cote-d'Or",
        country_id: '75',
      },
      {
        id: '1214',
        label: "Cotes-d'Armor",
        country_id: '75',
      },
      {
        id: '1215',
        label: 'Creuse',
        country_id: '75',
      },
      {
        id: '1216',
        label: 'Crolles',
        country_id: '75',
      },
      {
        id: '1217',
        label: 'Deux-Sevres',
        country_id: '75',
      },
      {
        id: '1218',
        label: 'Dordogne',
        country_id: '75',
      },
      {
        id: '1219',
        label: 'Doubs',
        country_id: '75',
      },
      {
        id: '1220',
        label: 'Drome',
        country_id: '75',
      },
      {
        id: '1221',
        label: 'Essonne',
        country_id: '75',
      },
      {
        id: '1222',
        label: 'Eure',
        country_id: '75',
      },
      {
        id: '1223',
        label: 'Eure-et-Loir',
        country_id: '75',
      },
      {
        id: '1224',
        label: 'Feucherolles',
        country_id: '75',
      },
      {
        id: '1225',
        label: 'Finistere',
        country_id: '75',
      },
      {
        id: '1226',
        label: 'Franche-Comte',
        country_id: '75',
      },
      {
        id: '1227',
        label: 'Gard',
        country_id: '75',
      },
      {
        id: '1228',
        label: 'Gers',
        country_id: '75',
      },
      {
        id: '1229',
        label: 'Gironde',
        country_id: '75',
      },
      {
        id: '1230',
        label: 'Haut-Rhin',
        country_id: '75',
      },
      {
        id: '1231',
        label: 'Haute-Corse',
        country_id: '75',
      },
      {
        id: '1232',
        label: 'Haute-Garonne',
        country_id: '75',
      },
      {
        id: '1233',
        label: 'Haute-Loire',
        country_id: '75',
      },
      {
        id: '1234',
        label: 'Haute-Marne',
        country_id: '75',
      },
      {
        id: '1235',
        label: 'Haute-Saone',
        country_id: '75',
      },
      {
        id: '1236',
        label: 'Haute-Savoie',
        country_id: '75',
      },
      {
        id: '1237',
        label: 'Haute-Vienne',
        country_id: '75',
      },
      {
        id: '1238',
        label: 'Hautes-Alpes',
        country_id: '75',
      },
      {
        id: '1239',
        label: 'Hautes-Pyrenees',
        country_id: '75',
      },
      {
        id: '1240',
        label: 'Hauts-de-Seine',
        country_id: '75',
      },
      {
        id: '1241',
        label: 'Herault',
        country_id: '75',
      },
      {
        id: '1242',
        label: 'Ile-de-France',
        country_id: '75',
      },
      {
        id: '1243',
        label: 'Ille-et-Vilaine',
        country_id: '75',
      },
      {
        id: '1244',
        label: 'Indre',
        country_id: '75',
      },
      {
        id: '1245',
        label: 'Indre-et-Loire',
        country_id: '75',
      },
      {
        id: '1246',
        label: 'Isere',
        country_id: '75',
      },
      {
        id: '1247',
        label: 'Jura',
        country_id: '75',
      },
      {
        id: '1248',
        label: 'Klagenfurt',
        country_id: '75',
      },
      {
        id: '1249',
        label: 'Landes',
        country_id: '75',
      },
      {
        id: '1250',
        label: 'Languedoc-Roussillon',
        country_id: '75',
      },
      {
        id: '1251',
        label: 'Larcay',
        country_id: '75',
      },
      {
        id: '1252',
        label: 'Le Castellet',
        country_id: '75',
      },
      {
        id: '1253',
        label: 'Le Creusot',
        country_id: '75',
      },
      {
        id: '1254',
        label: 'Limousin',
        country_id: '75',
      },
      {
        id: '1255',
        label: 'Loir-et-Cher',
        country_id: '75',
      },
      {
        id: '1256',
        label: 'Loire',
        country_id: '75',
      },
      {
        id: '1257',
        label: 'Loire-Atlantique',
        country_id: '75',
      },
      {
        id: '1258',
        label: 'Loiret',
        country_id: '75',
      },
      {
        id: '1259',
        label: 'Lorraine',
        country_id: '75',
      },
      {
        id: '1260',
        label: 'Lot',
        country_id: '75',
      },
      {
        id: '1261',
        label: 'Lot-et-Garonne',
        country_id: '75',
      },
      {
        id: '1262',
        label: 'Lower Normandy',
        country_id: '75',
      },
      {
        id: '1263',
        label: 'Lozere',
        country_id: '75',
      },
      {
        id: '1264',
        label: 'Maine-et-Loire',
        country_id: '75',
      },
      {
        id: '1265',
        label: 'Manche',
        country_id: '75',
      },
      {
        id: '1266',
        label: 'Marne',
        country_id: '75',
      },
      {
        id: '1267',
        label: 'Mayenne',
        country_id: '75',
      },
      {
        id: '1268',
        label: 'Meurthe-et-Moselle',
        country_id: '75',
      },
      {
        id: '1269',
        label: 'Meuse',
        country_id: '75',
      },
      {
        id: '1270',
        label: 'Midi-Pyrenees',
        country_id: '75',
      },
      {
        id: '1271',
        label: 'Morbihan',
        country_id: '75',
      },
      {
        id: '1272',
        label: 'Moselle',
        country_id: '75',
      },
      {
        id: '1273',
        label: 'Nievre',
        country_id: '75',
      },
      {
        id: '1274',
        label: 'Nord',
        country_id: '75',
      },
      {
        id: '1275',
        label: 'Nord-Pas-de-Calais',
        country_id: '75',
      },
      {
        id: '1276',
        label: 'Oise',
        country_id: '75',
      },
      {
        id: '1277',
        label: 'Orne',
        country_id: '75',
      },
      {
        id: '1278',
        label: 'Paris',
        country_id: '75',
      },
      {
        id: '1279',
        label: 'Pas-de-Calais',
        country_id: '75',
      },
      {
        id: '1280',
        label: 'Pays de la Loire',
        country_id: '75',
      },
      {
        id: '1281',
        label: 'Pays-de-la-Loire',
        country_id: '75',
      },
      {
        id: '1282',
        label: 'Picardy',
        country_id: '75',
      },
      {
        id: '1283',
        label: 'Puy-de-Dome',
        country_id: '75',
      },
      {
        id: '1284',
        label: 'Pyrenees-Atlantiques',
        country_id: '75',
      },
      {
        id: '1285',
        label: 'Pyrenees-Orientales',
        country_id: '75',
      },
      {
        id: '1286',
        label: 'Quelmes',
        country_id: '75',
      },
      {
        id: '1287',
        label: 'Rhone',
        country_id: '75',
      },
      {
        id: '1288',
        label: 'Rhone-Alpes',
        country_id: '75',
      },
      {
        id: '1289',
        label: 'Saint Ouen',
        country_id: '75',
      },
      {
        id: '1290',
        label: 'Saint Viatre',
        country_id: '75',
      },
      {
        id: '1291',
        label: 'Saone-et-Loire',
        country_id: '75',
      },
      {
        id: '1292',
        label: 'Sarthe',
        country_id: '75',
      },
      {
        id: '1293',
        label: 'Savoie',
        country_id: '75',
      },
      {
        id: '1294',
        label: 'Seine-Maritime',
        country_id: '75',
      },
      {
        id: '1295',
        label: 'Seine-Saint-Denis',
        country_id: '75',
      },
      {
        id: '1296',
        label: 'Seine-et-Marne',
        country_id: '75',
      },
      {
        id: '1297',
        label: 'Somme',
        country_id: '75',
      },
      {
        id: '1298',
        label: 'Sophia Antipolis',
        country_id: '75',
      },
      {
        id: '1299',
        label: 'Souvans',
        country_id: '75',
      },
      {
        id: '1300',
        label: 'Tarn',
        country_id: '75',
      },
      {
        id: '1301',
        label: 'Tarn-et-Garonne',
        country_id: '75',
      },
      {
        id: '1302',
        label: 'Territoire de Belfort',
        country_id: '75',
      },
      {
        id: '1303',
        label: 'Treignac',
        country_id: '75',
      },
      {
        id: '1304',
        label: 'Upper Normandy',
        country_id: '75',
      },
      {
        id: '1305',
        label: "Val-d'Oise",
        country_id: '75',
      },
      {
        id: '1306',
        label: 'Val-de-Marne',
        country_id: '75',
      },
      {
        id: '1307',
        label: 'Var',
        country_id: '75',
      },
      {
        id: '1308',
        label: 'Vaucluse',
        country_id: '75',
      },
      {
        id: '1309',
        label: 'Vellise',
        country_id: '75',
      },
      {
        id: '1310',
        label: 'Vendee',
        country_id: '75',
      },
      {
        id: '1311',
        label: 'Vienne',
        country_id: '75',
      },
      {
        id: '1312',
        label: 'Vosges',
        country_id: '75',
      },
      {
        id: '1313',
        label: 'Yonne',
        country_id: '75',
      },
      {
        id: '1314',
        label: 'Yvelines',
        country_id: '75',
      },
    ],
  },
  {
    id: '76',
    sortname: 'GF',
    label: 'French Guiana',
    states: [
      {
        id: '1315',
        label: 'Cayenne',
        country_id: '76',
      },
      {
        id: '1316',
        label: 'Saint-Laurent-du-Maroni',
        country_id: '76',
      },
    ],
  },
  {
    id: '77',
    sortname: 'PF',
    label: 'French Polynesia',
    states: [
      {
        id: '1317',
        label: 'Iles du Vent',
        country_id: '77',
      },
      {
        id: '1318',
        label: 'Iles sous le Vent',
        country_id: '77',
      },
      {
        id: '1319',
        label: 'Marquesas',
        country_id: '77',
      },
      {
        id: '1320',
        label: 'Tuamotu',
        country_id: '77',
      },
      {
        id: '1321',
        label: 'Tubuai',
        country_id: '77',
      },
    ],
  },
  {
    id: '78',
    sortname: 'TF',
    label: 'French Southern Territories',
    states: [
      {
        id: '1322',
        label: 'Amsterdam',
        country_id: '78',
      },
      {
        id: '1323',
        label: 'Crozet Islands',
        country_id: '78',
      },
      {
        id: '1324',
        label: 'Kerguelen',
        country_id: '78',
      },
    ],
  },
  {
    id: '79',
    sortname: 'GA',
    label: 'Gabon',
    states: [
      {
        id: '1325',
        label: 'Estuaire',
        country_id: '79',
      },
      {
        id: '1326',
        label: 'Haut-Ogooue',
        country_id: '79',
      },
      {
        id: '1327',
        label: 'Moyen-Ogooue',
        country_id: '79',
      },
      {
        id: '1328',
        label: 'Ngounie',
        country_id: '79',
      },
      {
        id: '1329',
        label: 'Nyanga',
        country_id: '79',
      },
      {
        id: '1330',
        label: 'Ogooue-Ivindo',
        country_id: '79',
      },
      {
        id: '1331',
        label: 'Ogooue-Lolo',
        country_id: '79',
      },
      {
        id: '1332',
        label: 'Ogooue-Maritime',
        country_id: '79',
      },
      {
        id: '1333',
        label: 'Woleu-Ntem',
        country_id: '79',
      },
    ],
  },
  {
    id: '80',
    sortname: 'GM',
    label: 'Gambia The',
    states: [
      {
        id: '1334',
        label: 'Banjul',
        country_id: '80',
      },
      {
        id: '1335',
        label: 'Basse',
        country_id: '80',
      },
      {
        id: '1336',
        label: 'Brikama',
        country_id: '80',
      },
      {
        id: '1337',
        label: 'Janjanbureh',
        country_id: '80',
      },
      {
        id: '1338',
        label: 'Kanifing',
        country_id: '80',
      },
      {
        id: '1339',
        label: 'Kerewan',
        country_id: '80',
      },
      {
        id: '1340',
        label: 'Kuntaur',
        country_id: '80',
      },
      {
        id: '1341',
        label: 'Mansakonko',
        country_id: '80',
      },
    ],
  },
  {
    id: '81',
    sortname: 'GE',
    label: 'Georgia',
    states: [
      {
        id: '1342',
        label: 'Abhasia',
        country_id: '81',
      },
      {
        id: '1343',
        label: 'Ajaria',
        country_id: '81',
      },
      {
        id: '1344',
        label: 'Guria',
        country_id: '81',
      },
      {
        id: '1345',
        label: 'Imereti',
        country_id: '81',
      },
      {
        id: '1346',
        label: 'Kaheti',
        country_id: '81',
      },
      {
        id: '1347',
        label: 'Kvemo Kartli',
        country_id: '81',
      },
      {
        id: '1348',
        label: 'Mcheta-Mtianeti',
        country_id: '81',
      },
      {
        id: '1349',
        label: 'Racha',
        country_id: '81',
      },
      {
        id: '1350',
        label: 'Samagrelo-Zemo Svaneti',
        country_id: '81',
      },
      {
        id: '1351',
        label: 'Samche-Zhavaheti',
        country_id: '81',
      },
      {
        id: '1352',
        label: 'Shida Kartli',
        country_id: '81',
      },
      {
        id: '1353',
        label: 'Tbilisi',
        country_id: '81',
      },
    ],
  },
  {
    id: '82',
    sortname: 'DE',
    label: 'Germany',
    states: [
      {
        id: '1354',
        label: 'Auvergne',
        country_id: '82',
      },
      {
        id: '1355',
        label: 'Baden-Wurttemberg',
        country_id: '82',
      },
      {
        id: '1356',
        label: 'Bavaria',
        country_id: '82',
      },
      {
        id: '1357',
        label: 'Bayern',
        country_id: '82',
      },
      {
        id: '1358',
        label: 'Beilstein Wurtt',
        country_id: '82',
      },
      {
        id: '1359',
        label: 'Berlin',
        country_id: '82',
      },
      {
        id: '1360',
        label: 'Brandenburg',
        country_id: '82',
      },
      {
        id: '1361',
        label: 'Bremen',
        country_id: '82',
      },
      {
        id: '1362',
        label: 'Dreisbach',
        country_id: '82',
      },
      {
        id: '1363',
        label: 'Freistaat Bayern',
        country_id: '82',
      },
      {
        id: '1364',
        label: 'Hamburg',
        country_id: '82',
      },
      {
        id: '1365',
        label: 'Hannover',
        country_id: '82',
      },
      {
        id: '1366',
        label: 'Heroldstatt',
        country_id: '82',
      },
      {
        id: '1367',
        label: 'Hessen',
        country_id: '82',
      },
      {
        id: '1368',
        label: 'Kortenberg',
        country_id: '82',
      },
      {
        id: '1369',
        label: 'Laasdorf',
        country_id: '82',
      },
      {
        id: '1370',
        label: 'Land Baden-Wurttemberg',
        country_id: '82',
      },
      {
        id: '1371',
        label: 'Land Bayern',
        country_id: '82',
      },
      {
        id: '1372',
        label: 'Land Brandenburg',
        country_id: '82',
      },
      {
        id: '1373',
        label: 'Land Hessen',
        country_id: '82',
      },
      {
        id: '1374',
        label: 'Land Mecklenburg-Vorpommern',
        country_id: '82',
      },
      {
        id: '1375',
        label: 'Land Nordrhein-Westfalen',
        country_id: '82',
      },
      {
        id: '1376',
        label: 'Land Rheinland-Pfalz',
        country_id: '82',
      },
      {
        id: '1377',
        label: 'Land Sachsen',
        country_id: '82',
      },
      {
        id: '1378',
        label: 'Land Sachsen-Anhalt',
        country_id: '82',
      },
      {
        id: '1379',
        label: 'Land Thuringen',
        country_id: '82',
      },
      {
        id: '1380',
        label: 'Lower Saxony',
        country_id: '82',
      },
      {
        id: '1381',
        label: 'Mecklenburg-Vorpommern',
        country_id: '82',
      },
      {
        id: '1382',
        label: 'Mulfingen',
        country_id: '82',
      },
      {
        id: '1383',
        label: 'Munich',
        country_id: '82',
      },
      {
        id: '1384',
        label: 'Neubeuern',
        country_id: '82',
      },
      {
        id: '1385',
        label: 'Niedersachsen',
        country_id: '82',
      },
      {
        id: '1386',
        label: 'Noord-Holland',
        country_id: '82',
      },
      {
        id: '1387',
        label: 'Nordrhein-Westfalen',
        country_id: '82',
      },
      {
        id: '1388',
        label: 'North Rhine-Westphalia',
        country_id: '82',
      },
      {
        id: '1389',
        label: 'Osterode',
        country_id: '82',
      },
      {
        id: '1390',
        label: 'Rheinland-Pfalz',
        country_id: '82',
      },
      {
        id: '1391',
        label: 'Rhineland-Palatinate',
        country_id: '82',
      },
      {
        id: '1392',
        label: 'Saarland',
        country_id: '82',
      },
      {
        id: '1393',
        label: 'Sachsen',
        country_id: '82',
      },
      {
        id: '1394',
        label: 'Sachsen-Anhalt',
        country_id: '82',
      },
      {
        id: '1395',
        label: 'Saxony',
        country_id: '82',
      },
      {
        id: '1396',
        label: 'Schleswig-Holstein',
        country_id: '82',
      },
      {
        id: '1397',
        label: 'Thuringia',
        country_id: '82',
      },
      {
        id: '1398',
        label: 'Webling',
        country_id: '82',
      },
      {
        id: '1399',
        label: 'Weinstrabe',
        country_id: '82',
      },
      {
        id: '1400',
        label: 'schlobborn',
        country_id: '82',
      },
    ],
  },
  {
    id: '83',
    sortname: 'GH',
    label: 'Ghana',
    states: [
      {
        id: '1401',
        label: 'Ashanti',
        country_id: '83',
      },
      {
        id: '1402',
        label: 'Brong-Ahafo',
        country_id: '83',
      },
      {
        id: '1403',
        label: 'Central',
        country_id: '83',
      },
      {
        id: '1404',
        label: 'Eastern',
        country_id: '83',
      },
      {
        id: '1405',
        label: 'Greater Accra',
        country_id: '83',
      },
      {
        id: '1406',
        label: 'Northern',
        country_id: '83',
      },
      {
        id: '1407',
        label: 'Upper East',
        country_id: '83',
      },
      {
        id: '1408',
        label: 'Upper West',
        country_id: '83',
      },
      {
        id: '1409',
        label: 'Volta',
        country_id: '83',
      },
      {
        id: '1410',
        label: 'Western',
        country_id: '83',
      },
    ],
  },
  {
    id: '84',
    sortname: 'GI',
    label: 'Gibraltar',
    states: [
      {
        id: '1411',
        label: 'Gibraltar',
        country_id: '84',
      },
    ],
  },
  {
    id: '85',
    sortname: 'GR',
    label: 'Greece',
    states: [
      {
        id: '1412',
        label: 'Acharnes',
        country_id: '85',
      },
      {
        id: '1413',
        label: 'Ahaia',
        country_id: '85',
      },
      {
        id: '1414',
        label: 'Aitolia kai Akarnania',
        country_id: '85',
      },
      {
        id: '1415',
        label: 'Argolis',
        country_id: '85',
      },
      {
        id: '1416',
        label: 'Arkadia',
        country_id: '85',
      },
      {
        id: '1417',
        label: 'Arta',
        country_id: '85',
      },
      {
        id: '1418',
        label: 'Attica',
        country_id: '85',
      },
      {
        id: '1419',
        label: 'Attiki',
        country_id: '85',
      },
      {
        id: '1420',
        label: 'Ayion Oros',
        country_id: '85',
      },
      {
        id: '1421',
        label: 'Crete',
        country_id: '85',
      },
      {
        id: '1422',
        label: 'Dodekanisos',
        country_id: '85',
      },
      {
        id: '1423',
        label: 'Drama',
        country_id: '85',
      },
      {
        id: '1424',
        label: 'Evia',
        country_id: '85',
      },
      {
        id: '1425',
        label: 'Evritania',
        country_id: '85',
      },
      {
        id: '1426',
        label: 'Evros',
        country_id: '85',
      },
      {
        id: '1427',
        label: 'Evvoia',
        country_id: '85',
      },
      {
        id: '1428',
        label: 'Florina',
        country_id: '85',
      },
      {
        id: '1429',
        label: 'Fokis',
        country_id: '85',
      },
      {
        id: '1430',
        label: 'Fthiotis',
        country_id: '85',
      },
      {
        id: '1431',
        label: 'Grevena',
        country_id: '85',
      },
      {
        id: '1432',
        label: 'Halandri',
        country_id: '85',
      },
      {
        id: '1433',
        label: 'Halkidiki',
        country_id: '85',
      },
      {
        id: '1434',
        label: 'Hania',
        country_id: '85',
      },
      {
        id: '1435',
        label: 'Heraklion',
        country_id: '85',
      },
      {
        id: '1436',
        label: 'Hios',
        country_id: '85',
      },
      {
        id: '1437',
        label: 'Ilia',
        country_id: '85',
      },
      {
        id: '1438',
        label: 'Imathia',
        country_id: '85',
      },
      {
        id: '1439',
        label: 'Ioannina',
        country_id: '85',
      },
      {
        id: '1440',
        label: 'Iraklion',
        country_id: '85',
      },
      {
        id: '1441',
        label: 'Karditsa',
        country_id: '85',
      },
      {
        id: '1442',
        label: 'Kastoria',
        country_id: '85',
      },
      {
        id: '1443',
        label: 'Kavala',
        country_id: '85',
      },
      {
        id: '1444',
        label: 'Kefallinia',
        country_id: '85',
      },
      {
        id: '1445',
        label: 'Kerkira',
        country_id: '85',
      },
      {
        id: '1446',
        label: 'Kiklades',
        country_id: '85',
      },
      {
        id: '1447',
        label: 'Kilkis',
        country_id: '85',
      },
      {
        id: '1448',
        label: 'Korinthia',
        country_id: '85',
      },
      {
        id: '1449',
        label: 'Kozani',
        country_id: '85',
      },
      {
        id: '1450',
        label: 'Lakonia',
        country_id: '85',
      },
      {
        id: '1451',
        label: 'Larisa',
        country_id: '85',
      },
      {
        id: '1452',
        label: 'Lasithi',
        country_id: '85',
      },
      {
        id: '1453',
        label: 'Lesvos',
        country_id: '85',
      },
      {
        id: '1454',
        label: 'Levkas',
        country_id: '85',
      },
      {
        id: '1455',
        label: 'Magnisia',
        country_id: '85',
      },
      {
        id: '1456',
        label: 'Messinia',
        country_id: '85',
      },
      {
        id: '1457',
        label: 'Nomos Attikis',
        country_id: '85',
      },
      {
        id: '1458',
        label: 'Nomos Zakynthou',
        country_id: '85',
      },
      {
        id: '1459',
        label: 'Pella',
        country_id: '85',
      },
      {
        id: '1460',
        label: 'Pieria',
        country_id: '85',
      },
      {
        id: '1461',
        label: 'Piraios',
        country_id: '85',
      },
      {
        id: '1462',
        label: 'Preveza',
        country_id: '85',
      },
      {
        id: '1463',
        label: 'Rethimni',
        country_id: '85',
      },
      {
        id: '1464',
        label: 'Rodopi',
        country_id: '85',
      },
      {
        id: '1465',
        label: 'Samos',
        country_id: '85',
      },
      {
        id: '1466',
        label: 'Serrai',
        country_id: '85',
      },
      {
        id: '1467',
        label: 'Thesprotia',
        country_id: '85',
      },
      {
        id: '1468',
        label: 'Thessaloniki',
        country_id: '85',
      },
      {
        id: '1469',
        label: 'Trikala',
        country_id: '85',
      },
      {
        id: '1470',
        label: 'Voiotia',
        country_id: '85',
      },
      {
        id: '1471',
        label: 'West Greece',
        country_id: '85',
      },
      {
        id: '1472',
        label: 'Xanthi',
        country_id: '85',
      },
      {
        id: '1473',
        label: 'Zakinthos',
        country_id: '85',
      },
    ],
  },
  {
    id: '86',
    sortname: 'GL',
    label: 'Greenland',
    states: [
      {
        id: '1474',
        label: 'Aasiaat',
        country_id: '86',
      },
      {
        id: '1475',
        label: 'Ammassalik',
        country_id: '86',
      },
      {
        id: '1476',
        label: 'Illoqqortoormiut',
        country_id: '86',
      },
      {
        id: '1477',
        label: 'Ilulissat',
        country_id: '86',
      },
      {
        id: '1478',
        label: 'Ivittuut',
        country_id: '86',
      },
      {
        id: '1479',
        label: 'Kangaatsiaq',
        country_id: '86',
      },
      {
        id: '1480',
        label: 'Maniitsoq',
        country_id: '86',
      },
      {
        id: '1481',
        label: 'Nanortalik',
        country_id: '86',
      },
      {
        id: '1482',
        label: 'Narsaq',
        country_id: '86',
      },
      {
        id: '1483',
        label: 'Nuuk',
        country_id: '86',
      },
      {
        id: '1484',
        label: 'Paamiut',
        country_id: '86',
      },
      {
        id: '1485',
        label: 'Qaanaaq',
        country_id: '86',
      },
      {
        id: '1486',
        label: 'Qaqortoq',
        country_id: '86',
      },
      {
        id: '1487',
        label: 'Qasigiannguit',
        country_id: '86',
      },
      {
        id: '1488',
        label: 'Qeqertarsuaq',
        country_id: '86',
      },
      {
        id: '1489',
        label: 'Sisimiut',
        country_id: '86',
      },
      {
        id: '1490',
        label: 'Udenfor kommunal inddeling',
        country_id: '86',
      },
      {
        id: '1491',
        label: 'Upernavik',
        country_id: '86',
      },
      {
        id: '1492',
        label: 'Uummannaq',
        country_id: '86',
      },
    ],
  },
  {
    id: '87',
    sortname: 'GD',
    label: 'Grenada',
    states: [
      {
        id: '1493',
        label: 'Carriacou-Petite Martinique',
        country_id: '87',
      },
      {
        id: '1494',
        label: 'Saint Andrew',
        country_id: '87',
      },
      {
        id: '1495',
        label: 'Saint Davids',
        country_id: '87',
      },
      {
        id: '1496',
        label: "Saint George's",
        country_id: '87',
      },
      {
        id: '1497',
        label: 'Saint John',
        country_id: '87',
      },
      {
        id: '1498',
        label: 'Saint Mark',
        country_id: '87',
      },
      {
        id: '1499',
        label: 'Saint Patrick',
        country_id: '87',
      },
    ],
  },
  {
    id: '88',
    sortname: 'GP',
    label: 'Guadeloupe',
    states: [
      {
        id: '1500',
        label: 'Basse-Terre',
        country_id: '88',
      },
      {
        id: '1501',
        label: 'Grande-Terre',
        country_id: '88',
      },
      {
        id: '1502',
        label: 'Iles des Saintes',
        country_id: '88',
      },
      {
        id: '1503',
        label: 'La Desirade',
        country_id: '88',
      },
      {
        id: '1504',
        label: 'Marie-Galante',
        country_id: '88',
      },
      {
        id: '1505',
        label: 'Saint Barthelemy',
        country_id: '88',
      },
      {
        id: '1506',
        label: 'Saint Martin',
        country_id: '88',
      },
    ],
  },
  {
    id: '89',
    sortname: 'GU',
    label: 'Guam',
    states: [
      {
        id: '1507',
        label: 'Agana Heights',
        country_id: '89',
      },
      {
        id: '1508',
        label: 'Agat',
        country_id: '89',
      },
      {
        id: '1509',
        label: 'Barrigada',
        country_id: '89',
      },
      {
        id: '1510',
        label: 'Chalan-Pago-Ordot',
        country_id: '89',
      },
      {
        id: '1511',
        label: 'Dededo',
        country_id: '89',
      },
      {
        id: '1512',
        label: 'Hagatna',
        country_id: '89',
      },
      {
        id: '1513',
        label: 'Inarajan',
        country_id: '89',
      },
      {
        id: '1514',
        label: 'Mangilao',
        country_id: '89',
      },
      {
        id: '1515',
        label: 'Merizo',
        country_id: '89',
      },
      {
        id: '1516',
        label: 'Mongmong-Toto-Maite',
        country_id: '89',
      },
      {
        id: '1517',
        label: 'Santa Rita',
        country_id: '89',
      },
      {
        id: '1518',
        label: 'Sinajana',
        country_id: '89',
      },
      {
        id: '1519',
        label: 'Talofofo',
        country_id: '89',
      },
      {
        id: '1520',
        label: 'Tamuning',
        country_id: '89',
      },
      {
        id: '1521',
        label: 'Yigo',
        country_id: '89',
      },
      {
        id: '1522',
        label: 'Yona',
        country_id: '89',
      },
    ],
  },
  {
    id: '90',
    sortname: 'GT',
    label: 'Guatemala',
    states: [
      {
        id: '1523',
        label: 'Alta Verapaz',
        country_id: '90',
      },
      {
        id: '1524',
        label: 'Baja Verapaz',
        country_id: '90',
      },
      {
        id: '1525',
        label: 'Chimaltenango',
        country_id: '90',
      },
      {
        id: '1526',
        label: 'Chiquimula',
        country_id: '90',
      },
      {
        id: '1527',
        label: 'El Progreso',
        country_id: '90',
      },
      {
        id: '1528',
        label: 'Escuintla',
        country_id: '90',
      },
      {
        id: '1529',
        label: 'Guatemala',
        country_id: '90',
      },
      {
        id: '1530',
        label: 'Huehuetenango',
        country_id: '90',
      },
      {
        id: '1531',
        label: 'Izabal',
        country_id: '90',
      },
      {
        id: '1532',
        label: 'Jalapa',
        country_id: '90',
      },
      {
        id: '1533',
        label: 'Jutiapa',
        country_id: '90',
      },
      {
        id: '1534',
        label: 'Peten',
        country_id: '90',
      },
      {
        id: '1535',
        label: 'Quezaltenango',
        country_id: '90',
      },
      {
        id: '1536',
        label: 'Quiche',
        country_id: '90',
      },
      {
        id: '1537',
        label: 'Retalhuleu',
        country_id: '90',
      },
      {
        id: '1538',
        label: 'Sacatepequez',
        country_id: '90',
      },
      {
        id: '1539',
        label: 'San Marcos',
        country_id: '90',
      },
      {
        id: '1540',
        label: 'Santa Rosa',
        country_id: '90',
      },
      {
        id: '1541',
        label: 'Solola',
        country_id: '90',
      },
      {
        id: '1542',
        label: 'Suchitepequez',
        country_id: '90',
      },
      {
        id: '1543',
        label: 'Totonicapan',
        country_id: '90',
      },
      {
        id: '1544',
        label: 'Zacapa',
        country_id: '90',
      },
    ],
  },
  {
    id: '91',
    sortname: 'XU',
    label: 'Guernsey and Alderney',
    states: [
      {
        id: '1545',
        label: 'Alderney',
        country_id: '91',
      },
      {
        id: '1546',
        label: 'Castel',
        country_id: '91',
      },
      {
        id: '1547',
        label: 'Forest',
        country_id: '91',
      },
      {
        id: '1548',
        label: 'Saint Andrew',
        country_id: '91',
      },
      {
        id: '1549',
        label: 'Saint Martin',
        country_id: '91',
      },
      {
        id: '1550',
        label: 'Saint Peter Port',
        country_id: '91',
      },
      {
        id: '1551',
        label: 'Saint Pierre du Bois',
        country_id: '91',
      },
      {
        id: '1552',
        label: 'Saint Sampson',
        country_id: '91',
      },
      {
        id: '1553',
        label: 'Saint Saviour',
        country_id: '91',
      },
      {
        id: '1554',
        label: 'Sark',
        country_id: '91',
      },
      {
        id: '1555',
        label: 'Torteval',
        country_id: '91',
      },
      {
        id: '1556',
        label: 'Vale',
        country_id: '91',
      },
    ],
  },
  {
    id: '92',
    sortname: 'GN',
    label: 'Guinea',
    states: [
      {
        id: '1557',
        label: 'Beyla',
        country_id: '92',
      },
      {
        id: '1558',
        label: 'Boffa',
        country_id: '92',
      },
      {
        id: '1559',
        label: 'Boke',
        country_id: '92',
      },
      {
        id: '1560',
        label: 'Conakry',
        country_id: '92',
      },
      {
        id: '1561',
        label: 'Coyah',
        country_id: '92',
      },
      {
        id: '1562',
        label: 'Dabola',
        country_id: '92',
      },
      {
        id: '1563',
        label: 'Dalaba',
        country_id: '92',
      },
      {
        id: '1564',
        label: 'Dinguiraye',
        country_id: '92',
      },
      {
        id: '1565',
        label: 'Faranah',
        country_id: '92',
      },
      {
        id: '1566',
        label: 'Forecariah',
        country_id: '92',
      },
      {
        id: '1567',
        label: 'Fria',
        country_id: '92',
      },
      {
        id: '1568',
        label: 'Gaoual',
        country_id: '92',
      },
      {
        id: '1569',
        label: 'Gueckedou',
        country_id: '92',
      },
      {
        id: '1570',
        label: 'Kankan',
        country_id: '92',
      },
      {
        id: '1571',
        label: 'Kerouane',
        country_id: '92',
      },
      {
        id: '1572',
        label: 'Kindia',
        country_id: '92',
      },
      {
        id: '1573',
        label: 'Kissidougou',
        country_id: '92',
      },
      {
        id: '1574',
        label: 'Koubia',
        country_id: '92',
      },
      {
        id: '1575',
        label: 'Koundara',
        country_id: '92',
      },
      {
        id: '1576',
        label: 'Kouroussa',
        country_id: '92',
      },
      {
        id: '1577',
        label: 'Labe',
        country_id: '92',
      },
      {
        id: '1578',
        label: 'Lola',
        country_id: '92',
      },
      {
        id: '1579',
        label: 'Macenta',
        country_id: '92',
      },
      {
        id: '1580',
        label: 'Mali',
        country_id: '92',
      },
      {
        id: '1581',
        label: 'Mamou',
        country_id: '92',
      },
      {
        id: '1582',
        label: 'Mandiana',
        country_id: '92',
      },
      {
        id: '1583',
        label: 'Nzerekore',
        country_id: '92',
      },
      {
        id: '1584',
        label: 'Pita',
        country_id: '92',
      },
      {
        id: '1585',
        label: 'Siguiri',
        country_id: '92',
      },
      {
        id: '1586',
        label: 'Telimele',
        country_id: '92',
      },
      {
        id: '1587',
        label: 'Tougue',
        country_id: '92',
      },
      {
        id: '1588',
        label: 'Yomou',
        country_id: '92',
      },
    ],
  },
  {
    id: '93',
    sortname: 'GW',
    label: 'Guinea-Bissau',
    states: [
      {
        id: '1589',
        label: 'Bafata',
        country_id: '93',
      },
      {
        id: '1590',
        label: 'Bissau',
        country_id: '93',
      },
      {
        id: '1591',
        label: 'Bolama',
        country_id: '93',
      },
      {
        id: '1592',
        label: 'Cacheu',
        country_id: '93',
      },
      {
        id: '1593',
        label: 'Gabu',
        country_id: '93',
      },
      {
        id: '1594',
        label: 'Oio',
        country_id: '93',
      },
      {
        id: '1595',
        label: 'Quinara',
        country_id: '93',
      },
      {
        id: '1596',
        label: 'Tombali',
        country_id: '93',
      },
    ],
  },
  {
    id: '94',
    sortname: 'GY',
    label: 'Guyana',
    states: [
      {
        id: '1597',
        label: 'Barima-Waini',
        country_id: '94',
      },
      {
        id: '1598',
        label: 'Cuyuni-Mazaruni',
        country_id: '94',
      },
      {
        id: '1599',
        label: 'Demerara-Mahaica',
        country_id: '94',
      },
      {
        id: '1600',
        label: 'East Berbice-Corentyne',
        country_id: '94',
      },
      {
        id: '1601',
        label: 'Essequibo Islands-West Demerar',
        country_id: '94',
      },
      {
        id: '1602',
        label: 'Mahaica-Berbice',
        country_id: '94',
      },
      {
        id: '1603',
        label: 'Pomeroon-Supenaam',
        country_id: '94',
      },
      {
        id: '1604',
        label: 'Potaro-Siparuni',
        country_id: '94',
      },
      {
        id: '1605',
        label: 'Upper Demerara-Berbice',
        country_id: '94',
      },
      {
        id: '1606',
        label: 'Upper Takutu-Upper Essequibo',
        country_id: '94',
      },
    ],
  },
  {
    id: '95',
    sortname: 'HT',
    label: 'Haiti',
    states: [
      {
        id: '1607',
        label: 'Artibonite',
        country_id: '95',
      },
      {
        id: '1608',
        label: 'Centre',
        country_id: '95',
      },
      {
        id: '1609',
        label: "Grand'Anse",
        country_id: '95',
      },
      {
        id: '1610',
        label: 'Nord',
        country_id: '95',
      },
      {
        id: '1611',
        label: 'Nord-Est',
        country_id: '95',
      },
      {
        id: '1612',
        label: 'Nord-Ouest',
        country_id: '95',
      },
      {
        id: '1613',
        label: 'Ouest',
        country_id: '95',
      },
      {
        id: '1614',
        label: 'Sud',
        country_id: '95',
      },
      {
        id: '1615',
        label: 'Sud-Est',
        country_id: '95',
      },
    ],
  },
  {
    id: '96',
    sortname: 'HM',
    label: 'Heard and McDonald Islands',
    states: [
      {
        id: '1616',
        label: 'Heard and McDonald Islands',
        country_id: '96',
      },
    ],
  },
  {
    id: '97',
    sortname: 'HN',
    label: 'Honduras',
    states: [
      {
        id: '1617',
        label: 'Atlantida',
        country_id: '97',
      },
      {
        id: '1618',
        label: 'Choluteca',
        country_id: '97',
      },
      {
        id: '1619',
        label: 'Colon',
        country_id: '97',
      },
      {
        id: '1620',
        label: 'Comayagua',
        country_id: '97',
      },
      {
        id: '1621',
        label: 'Copan',
        country_id: '97',
      },
      {
        id: '1622',
        label: 'Cortes',
        country_id: '97',
      },
      {
        id: '1623',
        label: 'Distrito Central',
        country_id: '97',
      },
      {
        id: '1624',
        label: 'El Paraiso',
        country_id: '97',
      },
      {
        id: '1625',
        label: 'Francisco Morazan',
        country_id: '97',
      },
      {
        id: '1626',
        label: 'Gracias a Dios',
        country_id: '97',
      },
      {
        id: '1627',
        label: 'Intibuca',
        country_id: '97',
      },
      {
        id: '1628',
        label: 'Islas de la Bahia',
        country_id: '97',
      },
      {
        id: '1629',
        label: 'La Paz',
        country_id: '97',
      },
      {
        id: '1630',
        label: 'Lempira',
        country_id: '97',
      },
      {
        id: '1631',
        label: 'Ocotepeque',
        country_id: '97',
      },
      {
        id: '1632',
        label: 'Olancho',
        country_id: '97',
      },
      {
        id: '1633',
        label: 'Santa Barbara',
        country_id: '97',
      },
      {
        id: '1634',
        label: 'Valle',
        country_id: '97',
      },
      {
        id: '1635',
        label: 'Yoro',
        country_id: '97',
      },
    ],
  },
  {
    id: '98',
    sortname: 'HK',
    label: 'Hong Kong S.A.R.',
    states: [
      {
        id: '1636',
        label: 'Hong Kong',
        country_id: '98',
      },
    ],
  },
  {
    id: '99',
    sortname: 'HU',
    label: 'Hungary',
    states: [
      {
        id: '1637',
        label: 'Bacs-Kiskun',
        country_id: '99',
      },
      {
        id: '1638',
        label: 'Baranya',
        country_id: '99',
      },
      {
        id: '1639',
        label: 'Bekes',
        country_id: '99',
      },
      {
        id: '1640',
        label: 'Borsod-Abauj-Zemplen',
        country_id: '99',
      },
      {
        id: '1641',
        label: 'Budapest',
        country_id: '99',
      },
      {
        id: '1642',
        label: 'Csongrad',
        country_id: '99',
      },
      {
        id: '1643',
        label: 'Fejer',
        country_id: '99',
      },
      {
        id: '1644',
        label: 'Gyor-Moson-Sopron',
        country_id: '99',
      },
      {
        id: '1645',
        label: 'Hajdu-Bihar',
        country_id: '99',
      },
      {
        id: '1646',
        label: 'Heves',
        country_id: '99',
      },
      {
        id: '1647',
        label: 'Jasz-Nagykun-Szolnok',
        country_id: '99',
      },
      {
        id: '1648',
        label: 'Komarom-Esztergom',
        country_id: '99',
      },
      {
        id: '1649',
        label: 'Nograd',
        country_id: '99',
      },
      {
        id: '1650',
        label: 'Pest',
        country_id: '99',
      },
      {
        id: '1651',
        label: 'Somogy',
        country_id: '99',
      },
      {
        id: '1652',
        label: 'Szabolcs-Szatmar-Bereg',
        country_id: '99',
      },
      {
        id: '1653',
        label: 'Tolna',
        country_id: '99',
      },
      {
        id: '1654',
        label: 'Vas',
        country_id: '99',
      },
      {
        id: '1655',
        label: 'Veszprem',
        country_id: '99',
      },
      {
        id: '1656',
        label: 'Zala',
        country_id: '99',
      },
    ],
  },
  {
    id: '100',
    sortname: 'IS',
    label: 'Iceland',
    states: [
      {
        id: '1657',
        label: 'Austurland',
        country_id: '100',
      },
      {
        id: '1658',
        label: 'Gullbringusysla',
        country_id: '100',
      },
      {
        id: '1659',
        label: 'Hofu borgarsva i',
        country_id: '100',
      },
      {
        id: '1660',
        label: 'Nor urland eystra',
        country_id: '100',
      },
      {
        id: '1661',
        label: 'Nor urland vestra',
        country_id: '100',
      },
      {
        id: '1662',
        label: 'Su urland',
        country_id: '100',
      },
      {
        id: '1663',
        label: 'Su urnes',
        country_id: '100',
      },
      {
        id: '1664',
        label: 'Vestfir ir',
        country_id: '100',
      },
      {
        id: '1665',
        label: 'Vesturland',
        country_id: '100',
      },
    ],
  },
  {
    id: '101',
    sortname: 'IN',
    label: 'India',
    states: [
      {
        id: '1',
        label: 'Andaman and Nicobar Islands',
        country_id: '101',
      },
      {
        id: '2',
        label: 'Andhra Pradesh',
        country_id: '101',
      },
      {
        id: '3',
        label: 'Arunachal Pradesh',
        country_id: '101',
      },
      {
        id: '4',
        label: 'Assam',
        country_id: '101',
      },
      {
        id: '5',
        label: 'Bihar',
        country_id: '101',
      },
      {
        id: '6',
        label: 'Chandigarh',
        country_id: '101',
      },
      {
        id: '7',
        label: 'Chhattisgarh',
        country_id: '101',
      },
      {
        id: '8',
        label: 'Dadra and Nagar Haveli',
        country_id: '101',
      },
      {
        id: '9',
        label: 'Daman and Diu',
        country_id: '101',
      },
      {
        id: '10',
        label: 'Delhi',
        country_id: '101',
      },
      {
        id: '11',
        label: 'Goa',
        country_id: '101',
      },
      {
        id: '12',
        label: 'Gujarat',
        country_id: '101',
      },
      {
        id: '13',
        label: 'Haryana',
        country_id: '101',
      },
      {
        id: '14',
        label: 'Himachal Pradesh',
        country_id: '101',
      },
      {
        id: '15',
        label: 'Jammu and Kashmir',
        country_id: '101',
      },
      {
        id: '16',
        label: 'Jharkhand',
        country_id: '101',
      },
      {
        id: '17',
        label: 'Karnataka',
        country_id: '101',
      },
      {
        id: '18',
        label: 'Kenmore',
        country_id: '101',
      },
      {
        id: '19',
        label: 'Kerala',
        country_id: '101',
      },
      {
        id: '20',
        label: 'Lakshadweep',
        country_id: '101',
      },
      {
        id: '21',
        label: 'Madhya Pradesh',
        country_id: '101',
      },
      {
        id: '22',
        label: 'Maharashtra',
        country_id: '101',
      },
      {
        id: '23',
        label: 'Manipur',
        country_id: '101',
      },
      {
        id: '24',
        label: 'Meghalaya',
        country_id: '101',
      },
      {
        id: '25',
        label: 'Mizoram',
        country_id: '101',
      },
      {
        id: '26',
        label: 'Nagaland',
        country_id: '101',
      },
      {
        id: '27',
        label: 'Narora',
        country_id: '101',
      },
      {
        id: '28',
        label: 'Natwar',
        country_id: '101',
      },
      {
        id: '29',
        label: 'Odisha',
        country_id: '101',
      },
      {
        id: '30',
        label: 'Paschim Medinipur',
        country_id: '101',
      },
      {
        id: '31',
        label: 'Pondicherry',
        country_id: '101',
      },
      {
        id: '32',
        label: 'Punjab',
        country_id: '101',
      },
      {
        id: '33',
        label: 'Rajasthan',
        country_id: '101',
      },
      {
        id: '34',
        label: 'Sikkim',
        country_id: '101',
      },
      {
        id: '35',
        label: 'Tamil Nadu',
        country_id: '101',
      },
      {
        id: '36',
        label: 'Telangana',
        country_id: '101',
      },
      {
        id: '37',
        label: 'Tripura',
        country_id: '101',
      },
      {
        id: '38',
        label: 'Uttar Pradesh',
        country_id: '101',
      },
      {
        id: '39',
        label: 'Uttarakhand',
        country_id: '101',
      },
      {
        id: '40',
        label: 'Vaishali',
        country_id: '101',
      },
      {
        id: '41',
        label: 'West Bengal',
        country_id: '101',
      },
    ],
  },
  {
    id: '102',
    sortname: 'ID',
    label: 'Indonesia',
    states: [
      {
        id: '1666',
        label: 'Aceh',
        country_id: '102',
      },
      {
        id: '1667',
        label: 'Bali',
        country_id: '102',
      },
      {
        id: '1668',
        label: 'Bangka-Belitung',
        country_id: '102',
      },
      {
        id: '1669',
        label: 'Banten',
        country_id: '102',
      },
      {
        id: '1670',
        label: 'Bengkulu',
        country_id: '102',
      },
      {
        id: '1671',
        label: 'Gandaria',
        country_id: '102',
      },
      {
        id: '1672',
        label: 'Gorontalo',
        country_id: '102',
      },
      {
        id: '1673',
        label: 'Jakarta',
        country_id: '102',
      },
      {
        id: '1674',
        label: 'Jambi',
        country_id: '102',
      },
      {
        id: '1675',
        label: 'Jawa Barat',
        country_id: '102',
      },
      {
        id: '1676',
        label: 'Jawa Tengah',
        country_id: '102',
      },
      {
        id: '1677',
        label: 'Jawa Timur',
        country_id: '102',
      },
      {
        id: '1678',
        label: 'Kalimantan Barat',
        country_id: '102',
      },
      {
        id: '1679',
        label: 'Kalimantan Selatan',
        country_id: '102',
      },
      {
        id: '1680',
        label: 'Kalimantan Tengah',
        country_id: '102',
      },
      {
        id: '1681',
        label: 'Kalimantan Timur',
        country_id: '102',
      },
      {
        id: '1682',
        label: 'Kendal',
        country_id: '102',
      },
      {
        id: '1683',
        label: 'Lampung',
        country_id: '102',
      },
      {
        id: '1684',
        label: 'Maluku',
        country_id: '102',
      },
      {
        id: '1685',
        label: 'Maluku Utara',
        country_id: '102',
      },
      {
        id: '1686',
        label: 'Nusa Tenggara Barat',
        country_id: '102',
      },
      {
        id: '1687',
        label: 'Nusa Tenggara Timur',
        country_id: '102',
      },
      {
        id: '1688',
        label: 'Papua',
        country_id: '102',
      },
      {
        id: '1689',
        label: 'Riau',
        country_id: '102',
      },
      {
        id: '1690',
        label: 'Riau Kepulauan',
        country_id: '102',
      },
      {
        id: '1691',
        label: 'Solo',
        country_id: '102',
      },
      {
        id: '1692',
        label: 'Sulawesi Selatan',
        country_id: '102',
      },
      {
        id: '1693',
        label: 'Sulawesi Tengah',
        country_id: '102',
      },
      {
        id: '1694',
        label: 'Sulawesi Tenggara',
        country_id: '102',
      },
      {
        id: '1695',
        label: 'Sulawesi Utara',
        country_id: '102',
      },
      {
        id: '1696',
        label: 'Sumatera Barat',
        country_id: '102',
      },
      {
        id: '1697',
        label: 'Sumatera Selatan',
        country_id: '102',
      },
      {
        id: '1698',
        label: 'Sumatera Utara',
        country_id: '102',
      },
      {
        id: '1699',
        label: 'Yogyakarta',
        country_id: '102',
      },
    ],
  },
  {
    id: '103',
    sortname: 'IR',
    label: 'Iran',
    states: [
      {
        id: '1700',
        label: 'Ardabil',
        country_id: '103',
      },
      {
        id: '1701',
        label: 'Azarbayjan-e Bakhtari',
        country_id: '103',
      },
      {
        id: '1702',
        label: 'Azarbayjan-e Khavari',
        country_id: '103',
      },
      {
        id: '1703',
        label: 'Bushehr',
        country_id: '103',
      },
      {
        id: '1704',
        label: 'Chahar Mahal-e Bakhtiari',
        country_id: '103',
      },
      {
        id: '1705',
        label: 'Esfahan',
        country_id: '103',
      },
      {
        id: '1706',
        label: 'Fars',
        country_id: '103',
      },
      {
        id: '1707',
        label: 'Gilan',
        country_id: '103',
      },
      {
        id: '1708',
        label: 'Golestan',
        country_id: '103',
      },
      {
        id: '1709',
        label: 'Hamadan',
        country_id: '103',
      },
      {
        id: '1710',
        label: 'Hormozgan',
        country_id: '103',
      },
      {
        id: '1711',
        label: 'Ilam',
        country_id: '103',
      },
      {
        id: '1712',
        label: 'Kerman',
        country_id: '103',
      },
      {
        id: '1713',
        label: 'Kermanshah',
        country_id: '103',
      },
      {
        id: '1714',
        label: 'Khorasan',
        country_id: '103',
      },
      {
        id: '1715',
        label: 'Khuzestan',
        country_id: '103',
      },
      {
        id: '1716',
        label: 'Kohgiluyeh-e Boyerahmad',
        country_id: '103',
      },
      {
        id: '1717',
        label: 'Kordestan',
        country_id: '103',
      },
      {
        id: '1718',
        label: 'Lorestan',
        country_id: '103',
      },
      {
        id: '1719',
        label: 'Markazi',
        country_id: '103',
      },
      {
        id: '1720',
        label: 'Mazandaran',
        country_id: '103',
      },
      {
        id: '1721',
        label: 'Ostan-e Esfahan',
        country_id: '103',
      },
      {
        id: '1722',
        label: 'Qazvin',
        country_id: '103',
      },
      {
        id: '1723',
        label: 'Qom',
        country_id: '103',
      },
      {
        id: '1724',
        label: 'Semnan',
        country_id: '103',
      },
      {
        id: '1725',
        label: 'Sistan-e Baluchestan',
        country_id: '103',
      },
      {
        id: '1726',
        label: 'Tehran',
        country_id: '103',
      },
      {
        id: '1727',
        label: 'Yazd',
        country_id: '103',
      },
      {
        id: '1728',
        label: 'Zanjan',
        country_id: '103',
      },
    ],
  },
  {
    id: '104',
    sortname: 'IQ',
    label: 'Iraq',
    states: [
      {
        id: '1729',
        label: 'Babil',
        country_id: '104',
      },
      {
        id: '1730',
        label: 'Baghdad',
        country_id: '104',
      },
      {
        id: '1731',
        label: 'Dahuk',
        country_id: '104',
      },
      {
        id: '1732',
        label: 'Dhi Qar',
        country_id: '104',
      },
      {
        id: '1733',
        label: 'Diyala',
        country_id: '104',
      },
      {
        id: '1734',
        label: 'Erbil',
        country_id: '104',
      },
      {
        id: '1735',
        label: 'Irbil',
        country_id: '104',
      },
      {
        id: '1736',
        label: 'Karbala',
        country_id: '104',
      },
      {
        id: '1737',
        label: 'Kurdistan',
        country_id: '104',
      },
      {
        id: '1738',
        label: 'Maysan',
        country_id: '104',
      },
      {
        id: '1739',
        label: 'Ninawa',
        country_id: '104',
      },
      {
        id: '1740',
        label: 'Salah-ad-Din',
        country_id: '104',
      },
      {
        id: '1741',
        label: 'Wasit',
        country_id: '104',
      },
      {
        id: '1742',
        label: 'al-Anbar',
        country_id: '104',
      },
      {
        id: '1743',
        label: 'al-Basrah',
        country_id: '104',
      },
      {
        id: '1744',
        label: 'al-Muthanna',
        country_id: '104',
      },
      {
        id: '1745',
        label: 'al-Qadisiyah',
        country_id: '104',
      },
      {
        id: '1746',
        label: 'an-Najaf',
        country_id: '104',
      },
      {
        id: '1747',
        label: 'as-Sulaymaniyah',
        country_id: '104',
      },
      {
        id: '1748',
        label: "at-Ta'mim",
        country_id: '104',
      },
    ],
  },
  {
    id: '105',
    sortname: 'IE',
    label: 'Ireland',
    states: [
      {
        id: '1749',
        label: 'Armagh',
        country_id: '105',
      },
      {
        id: '1750',
        label: 'Carlow',
        country_id: '105',
      },
      {
        id: '1751',
        label: 'Cavan',
        country_id: '105',
      },
      {
        id: '1752',
        label: 'Clare',
        country_id: '105',
      },
      {
        id: '1753',
        label: 'Cork',
        country_id: '105',
      },
      {
        id: '1754',
        label: 'Donegal',
        country_id: '105',
      },
      {
        id: '1755',
        label: 'Dublin',
        country_id: '105',
      },
      {
        id: '1756',
        label: 'Galway',
        country_id: '105',
      },
      {
        id: '1757',
        label: 'Kerry',
        country_id: '105',
      },
      {
        id: '1758',
        label: 'Kildare',
        country_id: '105',
      },
      {
        id: '1759',
        label: 'Kilkenny',
        country_id: '105',
      },
      {
        id: '1760',
        label: 'Laois',
        country_id: '105',
      },
      {
        id: '1761',
        label: 'Leinster',
        country_id: '105',
      },
      {
        id: '1762',
        label: 'Leitrim',
        country_id: '105',
      },
      {
        id: '1763',
        label: 'Limerick',
        country_id: '105',
      },
      {
        id: '1764',
        label: 'Loch Garman',
        country_id: '105',
      },
      {
        id: '1765',
        label: 'Longford',
        country_id: '105',
      },
      {
        id: '1766',
        label: 'Louth',
        country_id: '105',
      },
      {
        id: '1767',
        label: 'Mayo',
        country_id: '105',
      },
      {
        id: '1768',
        label: 'Meath',
        country_id: '105',
      },
      {
        id: '1769',
        label: 'Monaghan',
        country_id: '105',
      },
      {
        id: '1770',
        label: 'Offaly',
        country_id: '105',
      },
      {
        id: '1771',
        label: 'Roscommon',
        country_id: '105',
      },
      {
        id: '1772',
        label: 'Sligo',
        country_id: '105',
      },
      {
        id: '1773',
        label: 'Tipperary North Riding',
        country_id: '105',
      },
      {
        id: '1774',
        label: 'Tipperary South Riding',
        country_id: '105',
      },
      {
        id: '1775',
        label: 'Ulster',
        country_id: '105',
      },
      {
        id: '1776',
        label: 'Waterford',
        country_id: '105',
      },
      {
        id: '1777',
        label: 'Westmeath',
        country_id: '105',
      },
      {
        id: '1778',
        label: 'Wexford',
        country_id: '105',
      },
      {
        id: '1779',
        label: 'Wicklow',
        country_id: '105',
      },
    ],
  },
  {
    id: '106',
    sortname: 'IL',
    label: 'Israel',
    states: [
      {
        id: '1780',
        label: 'Beit Hanania',
        country_id: '106',
      },
      {
        id: '1781',
        label: 'Ben Gurion Airport',
        country_id: '106',
      },
      {
        id: '1782',
        label: 'Bethlehem',
        country_id: '106',
      },
      {
        id: '1783',
        label: 'Caesarea',
        country_id: '106',
      },
      {
        id: '1784',
        label: 'Centre',
        country_id: '106',
      },
      {
        id: '1785',
        label: 'Gaza',
        country_id: '106',
      },
      {
        id: '1786',
        label: 'Hadaron',
        country_id: '106',
      },
      {
        id: '1787',
        label: 'Haifa District',
        country_id: '106',
      },
      {
        id: '1788',
        label: 'Hamerkaz',
        country_id: '106',
      },
      {
        id: '1789',
        label: 'Hazafon',
        country_id: '106',
      },
      {
        id: '1790',
        label: 'Hebron',
        country_id: '106',
      },
      {
        id: '1791',
        label: 'Jaffa',
        country_id: '106',
      },
      {
        id: '1792',
        label: 'Jerusalem',
        country_id: '106',
      },
      {
        id: '1793',
        label: 'Khefa',
        country_id: '106',
      },
      {
        id: '1794',
        label: 'Kiryat Yam',
        country_id: '106',
      },
      {
        id: '1795',
        label: 'Lower Galilee',
        country_id: '106',
      },
      {
        id: '1796',
        label: 'Qalqilya',
        country_id: '106',
      },
      {
        id: '1797',
        label: 'Talme Elazar',
        country_id: '106',
      },
      {
        id: '1798',
        label: 'Tel Aviv',
        country_id: '106',
      },
      {
        id: '1799',
        label: 'Tsafon',
        country_id: '106',
      },
      {
        id: '1800',
        label: 'Umm El Fahem',
        country_id: '106',
      },
      {
        id: '1801',
        label: 'Yerushalayim',
        country_id: '106',
      },
    ],
  },
  {
    id: '107',
    sortname: 'IT',
    label: 'Italy',
    states: [
      {
        id: '1802',
        label: 'Abruzzi',
        country_id: '107',
      },
      {
        id: '1803',
        label: 'Abruzzo',
        country_id: '107',
      },
      {
        id: '1804',
        label: 'Agrigento',
        country_id: '107',
      },
      {
        id: '1805',
        label: 'Alessandria',
        country_id: '107',
      },
      {
        id: '1806',
        label: 'Ancona',
        country_id: '107',
      },
      {
        id: '1807',
        label: 'Arezzo',
        country_id: '107',
      },
      {
        id: '1808',
        label: 'Ascoli Piceno',
        country_id: '107',
      },
      {
        id: '1809',
        label: 'Asti',
        country_id: '107',
      },
      {
        id: '1810',
        label: 'Avellino',
        country_id: '107',
      },
      {
        id: '1811',
        label: 'Bari',
        country_id: '107',
      },
      {
        id: '1812',
        label: 'Basilicata',
        country_id: '107',
      },
      {
        id: '1813',
        label: 'Belluno',
        country_id: '107',
      },
      {
        id: '1814',
        label: 'Benevento',
        country_id: '107',
      },
      {
        id: '1815',
        label: 'Bergamo',
        country_id: '107',
      },
      {
        id: '1816',
        label: 'Biella',
        country_id: '107',
      },
      {
        id: '1817',
        label: 'Bologna',
        country_id: '107',
      },
      {
        id: '1818',
        label: 'Bolzano',
        country_id: '107',
      },
      {
        id: '1819',
        label: 'Brescia',
        country_id: '107',
      },
      {
        id: '1820',
        label: 'Brindisi',
        country_id: '107',
      },
      {
        id: '1821',
        label: 'Calabria',
        country_id: '107',
      },
      {
        id: '1822',
        label: 'Campania',
        country_id: '107',
      },
      {
        id: '1823',
        label: 'Cartoceto',
        country_id: '107',
      },
      {
        id: '1824',
        label: 'Caserta',
        country_id: '107',
      },
      {
        id: '1825',
        label: 'Catania',
        country_id: '107',
      },
      {
        id: '1826',
        label: 'Chieti',
        country_id: '107',
      },
      {
        id: '1827',
        label: 'Como',
        country_id: '107',
      },
      {
        id: '1828',
        label: 'Cosenza',
        country_id: '107',
      },
      {
        id: '1829',
        label: 'Cremona',
        country_id: '107',
      },
      {
        id: '1830',
        label: 'Cuneo',
        country_id: '107',
      },
      {
        id: '1831',
        label: 'Emilia-Romagna',
        country_id: '107',
      },
      {
        id: '1832',
        label: 'Ferrara',
        country_id: '107',
      },
      {
        id: '1833',
        label: 'Firenze',
        country_id: '107',
      },
      {
        id: '1834',
        label: 'Florence',
        country_id: '107',
      },
      {
        id: '1835',
        label: 'Forli-Cesena ',
        country_id: '107',
      },
      {
        id: '1836',
        label: 'Friuli-Venezia Giulia',
        country_id: '107',
      },
      {
        id: '1837',
        label: 'Frosinone',
        country_id: '107',
      },
      {
        id: '1838',
        label: 'Genoa',
        country_id: '107',
      },
      {
        id: '1839',
        label: 'Gorizia',
        country_id: '107',
      },
      {
        id: '1840',
        label: "L'Aquila",
        country_id: '107',
      },
      {
        id: '1841',
        label: 'Lazio',
        country_id: '107',
      },
      {
        id: '1842',
        label: 'Lecce',
        country_id: '107',
      },
      {
        id: '1843',
        label: 'Lecco',
        country_id: '107',
      },
      {
        id: '1844',
        label: 'Lecco Province',
        country_id: '107',
      },
      {
        id: '1845',
        label: 'Liguria',
        country_id: '107',
      },
      {
        id: '1846',
        label: 'Lodi',
        country_id: '107',
      },
      {
        id: '1847',
        label: 'Lombardia',
        country_id: '107',
      },
      {
        id: '1848',
        label: 'Lombardy',
        country_id: '107',
      },
      {
        id: '1849',
        label: 'Macerata',
        country_id: '107',
      },
      {
        id: '1850',
        label: 'Mantova',
        country_id: '107',
      },
      {
        id: '1851',
        label: 'Marche',
        country_id: '107',
      },
      {
        id: '1852',
        label: 'Messina',
        country_id: '107',
      },
      {
        id: '1853',
        label: 'Milan',
        country_id: '107',
      },
      {
        id: '1854',
        label: 'Modena',
        country_id: '107',
      },
      {
        id: '1855',
        label: 'Molise',
        country_id: '107',
      },
      {
        id: '1856',
        label: 'Molteno',
        country_id: '107',
      },
      {
        id: '1857',
        label: 'Montenegro',
        country_id: '107',
      },
      {
        id: '1858',
        label: 'Monza and Brianza',
        country_id: '107',
      },
      {
        id: '1859',
        label: 'Naples',
        country_id: '107',
      },
      {
        id: '1860',
        label: 'Novara',
        country_id: '107',
      },
      {
        id: '1861',
        label: 'Padova',
        country_id: '107',
      },
      {
        id: '1862',
        label: 'Parma',
        country_id: '107',
      },
      {
        id: '1863',
        label: 'Pavia',
        country_id: '107',
      },
      {
        id: '1864',
        label: 'Perugia',
        country_id: '107',
      },
      {
        id: '1865',
        label: 'Pesaro-Urbino',
        country_id: '107',
      },
      {
        id: '1866',
        label: 'Piacenza',
        country_id: '107',
      },
      {
        id: '1867',
        label: 'Piedmont',
        country_id: '107',
      },
      {
        id: '1868',
        label: 'Piemonte',
        country_id: '107',
      },
      {
        id: '1869',
        label: 'Pisa',
        country_id: '107',
      },
      {
        id: '1870',
        label: 'Pordenone',
        country_id: '107',
      },
      {
        id: '1871',
        label: 'Potenza',
        country_id: '107',
      },
      {
        id: '1872',
        label: 'Puglia',
        country_id: '107',
      },
      {
        id: '1873',
        label: 'Reggio Emilia',
        country_id: '107',
      },
      {
        id: '1874',
        label: 'Rimini',
        country_id: '107',
      },
      {
        id: '1875',
        label: 'Roma',
        country_id: '107',
      },
      {
        id: '1876',
        label: 'Salerno',
        country_id: '107',
      },
      {
        id: '1877',
        label: 'Sardegna',
        country_id: '107',
      },
      {
        id: '1878',
        label: 'Sassari',
        country_id: '107',
      },
      {
        id: '1879',
        label: 'Savona',
        country_id: '107',
      },
      {
        id: '1880',
        label: 'Sicilia',
        country_id: '107',
      },
      {
        id: '1881',
        label: 'Siena',
        country_id: '107',
      },
      {
        id: '1882',
        label: 'Sondrio',
        country_id: '107',
      },
      {
        id: '1883',
        label: 'South Tyrol',
        country_id: '107',
      },
      {
        id: '1884',
        label: 'Taranto',
        country_id: '107',
      },
      {
        id: '1885',
        label: 'Teramo',
        country_id: '107',
      },
      {
        id: '1886',
        label: 'Torino',
        country_id: '107',
      },
      {
        id: '1887',
        label: 'Toscana',
        country_id: '107',
      },
      {
        id: '1888',
        label: 'Trapani',
        country_id: '107',
      },
      {
        id: '1889',
        label: 'Trentino-Alto Adige',
        country_id: '107',
      },
      {
        id: '1890',
        label: 'Trento',
        country_id: '107',
      },
      {
        id: '1891',
        label: 'Treviso',
        country_id: '107',
      },
      {
        id: '1892',
        label: 'Udine',
        country_id: '107',
      },
      {
        id: '1893',
        label: 'Umbria',
        country_id: '107',
      },
      {
        id: '1894',
        label: "Valle d'Aosta",
        country_id: '107',
      },
      {
        id: '1895',
        label: 'Varese',
        country_id: '107',
      },
      {
        id: '1896',
        label: 'Veneto',
        country_id: '107',
      },
      {
        id: '1897',
        label: 'Venezia',
        country_id: '107',
      },
      {
        id: '1898',
        label: 'Verbano-Cusio-Ossola',
        country_id: '107',
      },
      {
        id: '1899',
        label: 'Vercelli',
        country_id: '107',
      },
      {
        id: '1900',
        label: 'Verona',
        country_id: '107',
      },
      {
        id: '1901',
        label: 'Vicenza',
        country_id: '107',
      },
      {
        id: '1902',
        label: 'Viterbo',
        country_id: '107',
      },
    ],
  },
  {
    id: '108',
    sortname: 'JM',
    label: 'Jamaica',
    states: [
      {
        id: '1903',
        label: 'Buxoro Viloyati',
        country_id: '108',
      },
      {
        id: '1904',
        label: 'Clarendon',
        country_id: '108',
      },
      {
        id: '1905',
        label: 'Hanover',
        country_id: '108',
      },
      {
        id: '1906',
        label: 'Kingston',
        country_id: '108',
      },
      {
        id: '1907',
        label: 'Manchester',
        country_id: '108',
      },
      {
        id: '1908',
        label: 'Portland',
        country_id: '108',
      },
      {
        id: '1909',
        label: 'Saint Andrews',
        country_id: '108',
      },
      {
        id: '1910',
        label: 'Saint Ann',
        country_id: '108',
      },
      {
        id: '1911',
        label: 'Saint Catherine',
        country_id: '108',
      },
      {
        id: '1912',
        label: 'Saint Elizabeth',
        country_id: '108',
      },
      {
        id: '1913',
        label: 'Saint James',
        country_id: '108',
      },
      {
        id: '1914',
        label: 'Saint Mary',
        country_id: '108',
      },
      {
        id: '1915',
        label: 'Saint Thomas',
        country_id: '108',
      },
      {
        id: '1916',
        label: 'Trelawney',
        country_id: '108',
      },
      {
        id: '1917',
        label: 'Westmoreland',
        country_id: '108',
      },
    ],
  },
  {
    id: '109',
    sortname: 'JP',
    label: 'Japan',
    states: [
      {
        id: '1918',
        label: 'Aichi',
        country_id: '109',
      },
      {
        id: '1919',
        label: 'Akita',
        country_id: '109',
      },
      {
        id: '1920',
        label: 'Aomori',
        country_id: '109',
      },
      {
        id: '1921',
        label: 'Chiba',
        country_id: '109',
      },
      {
        id: '1922',
        label: 'Ehime',
        country_id: '109',
      },
      {
        id: '1923',
        label: 'Fukui',
        country_id: '109',
      },
      {
        id: '1924',
        label: 'Fukuoka',
        country_id: '109',
      },
      {
        id: '1925',
        label: 'Fukushima',
        country_id: '109',
      },
      {
        id: '1926',
        label: 'Gifu',
        country_id: '109',
      },
      {
        id: '1927',
        label: 'Gumma',
        country_id: '109',
      },
      {
        id: '1928',
        label: 'Hiroshima',
        country_id: '109',
      },
      {
        id: '1929',
        label: 'Hokkaido',
        country_id: '109',
      },
      {
        id: '1930',
        label: 'Hyogo',
        country_id: '109',
      },
      {
        id: '1931',
        label: 'Ibaraki',
        country_id: '109',
      },
      {
        id: '1932',
        label: 'Ishikawa',
        country_id: '109',
      },
      {
        id: '1933',
        label: 'Iwate',
        country_id: '109',
      },
      {
        id: '1934',
        label: 'Kagawa',
        country_id: '109',
      },
      {
        id: '1935',
        label: 'Kagoshima',
        country_id: '109',
      },
      {
        id: '1936',
        label: 'Kanagawa',
        country_id: '109',
      },
      {
        id: '1937',
        label: 'Kanto',
        country_id: '109',
      },
      {
        id: '1938',
        label: 'Kochi',
        country_id: '109',
      },
      {
        id: '1939',
        label: 'Kumamoto',
        country_id: '109',
      },
      {
        id: '1940',
        label: 'Kyoto',
        country_id: '109',
      },
      {
        id: '1941',
        label: 'Mie',
        country_id: '109',
      },
      {
        id: '1942',
        label: 'Miyagi',
        country_id: '109',
      },
      {
        id: '1943',
        label: 'Miyazaki',
        country_id: '109',
      },
      {
        id: '1944',
        label: 'Nagano',
        country_id: '109',
      },
      {
        id: '1945',
        label: 'Nagasaki',
        country_id: '109',
      },
      {
        id: '1946',
        label: 'Nara',
        country_id: '109',
      },
      {
        id: '1947',
        label: 'Niigata',
        country_id: '109',
      },
      {
        id: '1948',
        label: 'Oita',
        country_id: '109',
      },
      {
        id: '1949',
        label: 'Okayama',
        country_id: '109',
      },
      {
        id: '1950',
        label: 'Okinawa',
        country_id: '109',
      },
      {
        id: '1951',
        label: 'Osaka',
        country_id: '109',
      },
      {
        id: '1952',
        label: 'Saga',
        country_id: '109',
      },
      {
        id: '1953',
        label: 'Saitama',
        country_id: '109',
      },
      {
        id: '1954',
        label: 'Shiga',
        country_id: '109',
      },
      {
        id: '1955',
        label: 'Shimane',
        country_id: '109',
      },
      {
        id: '1956',
        label: 'Shizuoka',
        country_id: '109',
      },
      {
        id: '1957',
        label: 'Tochigi',
        country_id: '109',
      },
      {
        id: '1958',
        label: 'Tokushima',
        country_id: '109',
      },
      {
        id: '1959',
        label: 'Tokyo',
        country_id: '109',
      },
      {
        id: '1960',
        label: 'Tottori',
        country_id: '109',
      },
      {
        id: '1961',
        label: 'Toyama',
        country_id: '109',
      },
      {
        id: '1962',
        label: 'Wakayama',
        country_id: '109',
      },
      {
        id: '1963',
        label: 'Yamagata',
        country_id: '109',
      },
      {
        id: '1964',
        label: 'Yamaguchi',
        country_id: '109',
      },
      {
        id: '1965',
        label: 'Yamanashi',
        country_id: '109',
      },
    ],
  },
  {
    id: '110',
    sortname: 'XJ',
    label: 'Jersey',
    states: [
      {
        id: '1966',
        label: 'Grouville',
        country_id: '110',
      },
      {
        id: '1967',
        label: 'Saint Brelade',
        country_id: '110',
      },
      {
        id: '1968',
        label: 'Saint Clement',
        country_id: '110',
      },
      {
        id: '1969',
        label: 'Saint Helier',
        country_id: '110',
      },
      {
        id: '1970',
        label: 'Saint John',
        country_id: '110',
      },
      {
        id: '1971',
        label: 'Saint Lawrence',
        country_id: '110',
      },
      {
        id: '1972',
        label: 'Saint Martin',
        country_id: '110',
      },
      {
        id: '1973',
        label: 'Saint Mary',
        country_id: '110',
      },
      {
        id: '1974',
        label: 'Saint Peter',
        country_id: '110',
      },
      {
        id: '1975',
        label: 'Saint Saviour',
        country_id: '110',
      },
      {
        id: '1976',
        label: 'Trinity',
        country_id: '110',
      },
    ],
  },
  {
    id: '111',
    sortname: 'JO',
    label: 'Jordan',
    states: [
      {
        id: '1977',
        label: "'Ajlun",
        country_id: '111',
      },
      {
        id: '1978',
        label: 'Amman',
        country_id: '111',
      },
      {
        id: '1979',
        label: 'Irbid',
        country_id: '111',
      },
      {
        id: '1980',
        label: 'Jarash',
        country_id: '111',
      },
      {
        id: '1981',
        label: "Ma'an",
        country_id: '111',
      },
      {
        id: '1982',
        label: 'Madaba',
        country_id: '111',
      },
      {
        id: '1983',
        label: "al-'Aqabah",
        country_id: '111',
      },
      {
        id: '1984',
        label: "al-Balqa'",
        country_id: '111',
      },
      {
        id: '1985',
        label: 'al-Karak',
        country_id: '111',
      },
      {
        id: '1986',
        label: 'al-Mafraq',
        country_id: '111',
      },
      {
        id: '1987',
        label: 'at-Tafilah',
        country_id: '111',
      },
      {
        id: '1988',
        label: "az-Zarqa'",
        country_id: '111',
      },
    ],
  },
  {
    id: '112',
    sortname: 'KZ',
    label: 'Kazakhstan',
    states: [
      {
        id: '1989',
        label: 'Akmecet',
        country_id: '112',
      },
      {
        id: '1990',
        label: 'Akmola',
        country_id: '112',
      },
      {
        id: '1991',
        label: 'Aktobe',
        country_id: '112',
      },
      {
        id: '1992',
        label: 'Almati',
        country_id: '112',
      },
      {
        id: '1993',
        label: 'Atirau',
        country_id: '112',
      },
      {
        id: '1994',
        label: 'Batis Kazakstan',
        country_id: '112',
      },
      {
        id: '1995',
        label: 'Burlinsky Region',
        country_id: '112',
      },
      {
        id: '1996',
        label: 'Karagandi',
        country_id: '112',
      },
      {
        id: '1997',
        label: 'Kostanay',
        country_id: '112',
      },
      {
        id: '1998',
        label: 'Mankistau',
        country_id: '112',
      },
      {
        id: '1999',
        label: 'Ontustik Kazakstan',
        country_id: '112',
      },
      {
        id: '2000',
        label: 'Pavlodar',
        country_id: '112',
      },
      {
        id: '2001',
        label: 'Sigis Kazakstan',
        country_id: '112',
      },
      {
        id: '2002',
        label: 'Soltustik Kazakstan',
        country_id: '112',
      },
      {
        id: '2003',
        label: 'Taraz',
        country_id: '112',
      },
    ],
  },
  {
    id: '113',
    sortname: 'KE',
    label: 'Kenya',
    states: [
      {
        id: '2004',
        label: 'Central',
        country_id: '113',
      },
      {
        id: '2005',
        label: 'Coast',
        country_id: '113',
      },
      {
        id: '2006',
        label: 'Eastern',
        country_id: '113',
      },
      {
        id: '2007',
        label: 'Nairobi',
        country_id: '113',
      },
      {
        id: '2008',
        label: 'North Eastern',
        country_id: '113',
      },
      {
        id: '2009',
        label: 'Nyanza',
        country_id: '113',
      },
      {
        id: '2010',
        label: 'Rift Valley',
        country_id: '113',
      },
      {
        id: '2011',
        label: 'Western',
        country_id: '113',
      },
    ],
  },
  {
    id: '114',
    sortname: 'KI',
    label: 'Kiribati',
    states: [
      {
        id: '2012',
        label: 'Abaiang',
        country_id: '114',
      },
      {
        id: '2013',
        label: 'Abemana',
        country_id: '114',
      },
      {
        id: '2014',
        label: 'Aranuka',
        country_id: '114',
      },
      {
        id: '2015',
        label: 'Arorae',
        country_id: '114',
      },
      {
        id: '2016',
        label: 'Banaba',
        country_id: '114',
      },
      {
        id: '2017',
        label: 'Beru',
        country_id: '114',
      },
      {
        id: '2018',
        label: 'Butaritari',
        country_id: '114',
      },
      {
        id: '2019',
        label: 'Kiritimati',
        country_id: '114',
      },
      {
        id: '2020',
        label: 'Kuria',
        country_id: '114',
      },
      {
        id: '2021',
        label: 'Maiana',
        country_id: '114',
      },
      {
        id: '2022',
        label: 'Makin',
        country_id: '114',
      },
      {
        id: '2023',
        label: 'Marakei',
        country_id: '114',
      },
      {
        id: '2024',
        label: 'Nikunau',
        country_id: '114',
      },
      {
        id: '2025',
        label: 'Nonouti',
        country_id: '114',
      },
      {
        id: '2026',
        label: 'Onotoa',
        country_id: '114',
      },
      {
        id: '2027',
        label: 'Phoenix Islands',
        country_id: '114',
      },
      {
        id: '2028',
        label: 'Tabiteuea North',
        country_id: '114',
      },
      {
        id: '2029',
        label: 'Tabiteuea South',
        country_id: '114',
      },
      {
        id: '2030',
        label: 'Tabuaeran',
        country_id: '114',
      },
      {
        id: '2031',
        label: 'Tamana',
        country_id: '114',
      },
      {
        id: '2032',
        label: 'Tarawa North',
        country_id: '114',
      },
      {
        id: '2033',
        label: 'Tarawa South',
        country_id: '114',
      },
      {
        id: '2034',
        label: 'Teraina',
        country_id: '114',
      },
    ],
  },
  {
    id: '115',
    sortname: 'KP',
    label: 'Korea North',
    states: [
      {
        id: '2035',
        label: 'Chagangdo',
        country_id: '115',
      },
      {
        id: '2036',
        label: 'Hamgyeongbukto',
        country_id: '115',
      },
      {
        id: '2037',
        label: 'Hamgyeongnamdo',
        country_id: '115',
      },
      {
        id: '2038',
        label: 'Hwanghaebukto',
        country_id: '115',
      },
      {
        id: '2039',
        label: 'Hwanghaenamdo',
        country_id: '115',
      },
      {
        id: '2040',
        label: 'Kaeseong',
        country_id: '115',
      },
      {
        id: '2041',
        label: 'Kangweon',
        country_id: '115',
      },
      {
        id: '2042',
        label: 'Nampo',
        country_id: '115',
      },
      {
        id: '2043',
        label: 'Pyeonganbukto',
        country_id: '115',
      },
      {
        id: '2044',
        label: 'Pyeongannamdo',
        country_id: '115',
      },
      {
        id: '2045',
        label: 'Pyeongyang',
        country_id: '115',
      },
      {
        id: '2046',
        label: 'Yanggang',
        country_id: '115',
      },
    ],
  },
  {
    id: '116',
    sortname: 'KR',
    label: 'Korea South',
    states: [
      {
        id: '2047',
        label: 'Busan',
        country_id: '116',
      },
      {
        id: '2048',
        label: 'Cheju',
        country_id: '116',
      },
      {
        id: '2049',
        label: 'Chollabuk',
        country_id: '116',
      },
      {
        id: '2050',
        label: 'Chollanam',
        country_id: '116',
      },
      {
        id: '2051',
        label: 'Chungbuk',
        country_id: '116',
      },
      {
        id: '2052',
        label: 'Chungcheongbuk',
        country_id: '116',
      },
      {
        id: '2053',
        label: 'Chungcheongnam',
        country_id: '116',
      },
      {
        id: '2054',
        label: 'Chungnam',
        country_id: '116',
      },
      {
        id: '2055',
        label: 'Daegu',
        country_id: '116',
      },
      {
        id: '2056',
        label: 'Gangwon-do',
        country_id: '116',
      },
      {
        id: '2057',
        label: 'Goyang-si',
        country_id: '116',
      },
      {
        id: '2058',
        label: 'Gyeonggi-do',
        country_id: '116',
      },
      {
        id: '2059',
        label: 'Gyeongsang ',
        country_id: '116',
      },
      {
        id: '2060',
        label: 'Gyeongsangnam-do',
        country_id: '116',
      },
      {
        id: '2061',
        label: 'Incheon',
        country_id: '116',
      },
      {
        id: '2062',
        label: 'Jeju-Si',
        country_id: '116',
      },
      {
        id: '2063',
        label: 'Jeonbuk',
        country_id: '116',
      },
      {
        id: '2064',
        label: 'Kangweon',
        country_id: '116',
      },
      {
        id: '2065',
        label: 'Kwangju',
        country_id: '116',
      },
      {
        id: '2066',
        label: 'Kyeonggi',
        country_id: '116',
      },
      {
        id: '2067',
        label: 'Kyeongsangbuk',
        country_id: '116',
      },
      {
        id: '2068',
        label: 'Kyeongsangnam',
        country_id: '116',
      },
      {
        id: '2069',
        label: 'Kyonggi-do',
        country_id: '116',
      },
      {
        id: '2070',
        label: 'Kyungbuk-Do',
        country_id: '116',
      },
      {
        id: '2071',
        label: 'Kyunggi-Do',
        country_id: '116',
      },
      {
        id: '2072',
        label: 'Kyunggi-do',
        country_id: '116',
      },
      {
        id: '2073',
        label: 'Pusan',
        country_id: '116',
      },
      {
        id: '2074',
        label: 'Seoul',
        country_id: '116',
      },
      {
        id: '2075',
        label: 'Sudogwon',
        country_id: '116',
      },
      {
        id: '2076',
        label: 'Taegu',
        country_id: '116',
      },
      {
        id: '2077',
        label: 'Taejeon',
        country_id: '116',
      },
      {
        id: '2078',
        label: 'Taejon-gwangyoksi',
        country_id: '116',
      },
      {
        id: '2079',
        label: 'Ulsan',
        country_id: '116',
      },
      {
        id: '2080',
        label: 'Wonju',
        country_id: '116',
      },
      {
        id: '2081',
        label: 'gwangyoksi',
        country_id: '116',
      },
    ],
  },
  {
    id: '117',
    sortname: 'KW',
    label: 'Kuwait',
    states: [
      {
        id: '2082',
        label: 'Al Asimah',
        country_id: '117',
      },
      {
        id: '2083',
        label: 'Hawalli',
        country_id: '117',
      },
      {
        id: '2084',
        label: 'Mishref',
        country_id: '117',
      },
      {
        id: '2085',
        label: 'Qadesiya',
        country_id: '117',
      },
      {
        id: '2086',
        label: 'Safat',
        country_id: '117',
      },
      {
        id: '2087',
        label: 'Salmiya',
        country_id: '117',
      },
      {
        id: '2088',
        label: 'al-Ahmadi',
        country_id: '117',
      },
      {
        id: '2089',
        label: 'al-Farwaniyah',
        country_id: '117',
      },
      {
        id: '2090',
        label: 'al-Jahra',
        country_id: '117',
      },
      {
        id: '2091',
        label: 'al-Kuwayt',
        country_id: '117',
      },
    ],
  },
  {
    id: '118',
    sortname: 'KG',
    label: 'Kyrgyzstan',
    states: [
      {
        id: '2092',
        label: 'Batken',
        country_id: '118',
      },
      {
        id: '2093',
        label: 'Bishkek',
        country_id: '118',
      },
      {
        id: '2094',
        label: 'Chui',
        country_id: '118',
      },
      {
        id: '2095',
        label: 'Issyk-Kul',
        country_id: '118',
      },
      {
        id: '2096',
        label: 'Jalal-Abad',
        country_id: '118',
      },
      {
        id: '2097',
        label: 'Naryn',
        country_id: '118',
      },
      {
        id: '2098',
        label: 'Osh',
        country_id: '118',
      },
      {
        id: '2099',
        label: 'Talas',
        country_id: '118',
      },
    ],
  },
  {
    id: '119',
    sortname: 'LA',
    label: 'Laos',
    states: [
      {
        id: '2100',
        label: 'Attopu',
        country_id: '119',
      },
      {
        id: '2101',
        label: 'Bokeo',
        country_id: '119',
      },
      {
        id: '2102',
        label: 'Bolikhamsay',
        country_id: '119',
      },
      {
        id: '2103',
        label: 'Champasak',
        country_id: '119',
      },
      {
        id: '2104',
        label: 'Houaphanh',
        country_id: '119',
      },
      {
        id: '2105',
        label: 'Khammouane',
        country_id: '119',
      },
      {
        id: '2106',
        label: 'Luang Nam Tha',
        country_id: '119',
      },
      {
        id: '2107',
        label: 'Luang Prabang',
        country_id: '119',
      },
      {
        id: '2108',
        label: 'Oudomxay',
        country_id: '119',
      },
      {
        id: '2109',
        label: 'Phongsaly',
        country_id: '119',
      },
      {
        id: '2110',
        label: 'Saravan',
        country_id: '119',
      },
      {
        id: '2111',
        label: 'Savannakhet',
        country_id: '119',
      },
      {
        id: '2112',
        label: 'Sekong',
        country_id: '119',
      },
      {
        id: '2113',
        label: 'Viangchan Prefecture',
        country_id: '119',
      },
      {
        id: '2114',
        label: 'Viangchan Province',
        country_id: '119',
      },
      {
        id: '2115',
        label: 'Xaignabury',
        country_id: '119',
      },
      {
        id: '2116',
        label: 'Xiang Khuang',
        country_id: '119',
      },
    ],
  },
  {
    id: '120',
    sortname: 'LV',
    label: 'Latvia',
    states: [
      {
        id: '2117',
        label: 'Aizkraukles',
        country_id: '120',
      },
      {
        id: '2118',
        label: 'Aluksnes',
        country_id: '120',
      },
      {
        id: '2119',
        label: 'Balvu',
        country_id: '120',
      },
      {
        id: '2120',
        label: 'Bauskas',
        country_id: '120',
      },
      {
        id: '2121',
        label: 'Cesu',
        country_id: '120',
      },
      {
        id: '2122',
        label: 'Daugavpils',
        country_id: '120',
      },
      {
        id: '2123',
        label: 'Daugavpils City',
        country_id: '120',
      },
      {
        id: '2124',
        label: 'Dobeles',
        country_id: '120',
      },
      {
        id: '2125',
        label: 'Gulbenes',
        country_id: '120',
      },
      {
        id: '2126',
        label: 'Jekabspils',
        country_id: '120',
      },
      {
        id: '2127',
        label: 'Jelgava',
        country_id: '120',
      },
      {
        id: '2128',
        label: 'Jelgavas',
        country_id: '120',
      },
      {
        id: '2129',
        label: 'Jurmala City',
        country_id: '120',
      },
      {
        id: '2130',
        label: 'Kraslavas',
        country_id: '120',
      },
      {
        id: '2131',
        label: 'Kuldigas',
        country_id: '120',
      },
      {
        id: '2132',
        label: 'Liepaja',
        country_id: '120',
      },
      {
        id: '2133',
        label: 'Liepajas',
        country_id: '120',
      },
      {
        id: '2134',
        label: 'Limbazhu',
        country_id: '120',
      },
      {
        id: '2135',
        label: 'Ludzas',
        country_id: '120',
      },
      {
        id: '2136',
        label: 'Madonas',
        country_id: '120',
      },
      {
        id: '2137',
        label: 'Ogres',
        country_id: '120',
      },
      {
        id: '2138',
        label: 'Preilu',
        country_id: '120',
      },
      {
        id: '2139',
        label: 'Rezekne',
        country_id: '120',
      },
      {
        id: '2140',
        label: 'Rezeknes',
        country_id: '120',
      },
      {
        id: '2141',
        label: 'Riga',
        country_id: '120',
      },
      {
        id: '2142',
        label: 'Rigas',
        country_id: '120',
      },
      {
        id: '2143',
        label: 'Saldus',
        country_id: '120',
      },
      {
        id: '2144',
        label: 'Talsu',
        country_id: '120',
      },
      {
        id: '2145',
        label: 'Tukuma',
        country_id: '120',
      },
      {
        id: '2146',
        label: 'Valkas',
        country_id: '120',
      },
      {
        id: '2147',
        label: 'Valmieras',
        country_id: '120',
      },
      {
        id: '2148',
        label: 'Ventspils',
        country_id: '120',
      },
      {
        id: '2149',
        label: 'Ventspils City',
        country_id: '120',
      },
    ],
  },
  {
    id: '121',
    sortname: 'LB',
    label: 'Lebanon',
    states: [
      {
        id: '2150',
        label: 'Beirut',
        country_id: '121',
      },
      {
        id: '2151',
        label: 'Jabal Lubnan',
        country_id: '121',
      },
      {
        id: '2152',
        label: 'Mohafazat Liban-Nord',
        country_id: '121',
      },
      {
        id: '2153',
        label: 'Mohafazat Mont-Liban',
        country_id: '121',
      },
      {
        id: '2154',
        label: 'Sidon',
        country_id: '121',
      },
      {
        id: '2155',
        label: 'al-Biqa',
        country_id: '121',
      },
      {
        id: '2156',
        label: 'al-Janub',
        country_id: '121',
      },
      {
        id: '2157',
        label: 'an-Nabatiyah',
        country_id: '121',
      },
      {
        id: '2158',
        label: 'ash-Shamal',
        country_id: '121',
      },
    ],
  },
  {
    id: '122',
    sortname: 'LS',
    label: 'Lesotho',
    states: [
      {
        id: '2159',
        label: 'Berea',
        country_id: '122',
      },
      {
        id: '2160',
        label: 'Butha-Buthe',
        country_id: '122',
      },
      {
        id: '2161',
        label: 'Leribe',
        country_id: '122',
      },
      {
        id: '2162',
        label: 'Mafeteng',
        country_id: '122',
      },
      {
        id: '2163',
        label: 'Maseru',
        country_id: '122',
      },
      {
        id: '2164',
        label: "Mohale's Hoek",
        country_id: '122',
      },
      {
        id: '2165',
        label: 'Mokhotlong',
        country_id: '122',
      },
      {
        id: '2166',
        label: "Qacha's Nek",
        country_id: '122',
      },
      {
        id: '2167',
        label: 'Quthing',
        country_id: '122',
      },
      {
        id: '2168',
        label: 'Thaba-Tseka',
        country_id: '122',
      },
    ],
  },
  {
    id: '123',
    sortname: 'LR',
    label: 'Liberia',
    states: [
      {
        id: '2169',
        label: 'Bomi',
        country_id: '123',
      },
      {
        id: '2170',
        label: 'Bong',
        country_id: '123',
      },
      {
        id: '2171',
        label: 'Grand Bassa',
        country_id: '123',
      },
      {
        id: '2172',
        label: 'Grand Cape Mount',
        country_id: '123',
      },
      {
        id: '2173',
        label: 'Grand Gedeh',
        country_id: '123',
      },
      {
        id: '2174',
        label: 'Loffa',
        country_id: '123',
      },
      {
        id: '2175',
        label: 'Margibi',
        country_id: '123',
      },
      {
        id: '2176',
        label: 'Maryland and Grand Kru',
        country_id: '123',
      },
      {
        id: '2177',
        label: 'Montserrado',
        country_id: '123',
      },
      {
        id: '2178',
        label: 'Nimba',
        country_id: '123',
      },
      {
        id: '2179',
        label: 'Rivercess',
        country_id: '123',
      },
      {
        id: '2180',
        label: 'Sinoe',
        country_id: '123',
      },
    ],
  },
  {
    id: '124',
    sortname: 'LY',
    label: 'Libya',
    states: [
      {
        id: '2181',
        label: 'Ajdabiya',
        country_id: '124',
      },
      {
        id: '2182',
        label: 'Fezzan',
        country_id: '124',
      },
      {
        id: '2183',
        label: 'Banghazi',
        country_id: '124',
      },
      {
        id: '2184',
        label: 'Darnah',
        country_id: '124',
      },
      {
        id: '2185',
        label: 'Ghadamis',
        country_id: '124',
      },
      {
        id: '2186',
        label: 'Gharyan',
        country_id: '124',
      },
      {
        id: '2187',
        label: 'Misratah',
        country_id: '124',
      },
      {
        id: '2188',
        label: 'Murzuq',
        country_id: '124',
      },
      {
        id: '2189',
        label: 'Sabha',
        country_id: '124',
      },
      {
        id: '2190',
        label: 'Sawfajjin',
        country_id: '124',
      },
      {
        id: '2191',
        label: 'Surt',
        country_id: '124',
      },
      {
        id: '2192',
        label: 'Tarabulus',
        country_id: '124',
      },
      {
        id: '2193',
        label: 'Tarhunah',
        country_id: '124',
      },
      {
        id: '2194',
        label: 'Tripolitania',
        country_id: '124',
      },
      {
        id: '2195',
        label: 'Tubruq',
        country_id: '124',
      },
      {
        id: '2196',
        label: 'Yafran',
        country_id: '124',
      },
      {
        id: '2197',
        label: 'Zlitan',
        country_id: '124',
      },
      {
        id: '2198',
        label: "al-'Aziziyah",
        country_id: '124',
      },
      {
        id: '2199',
        label: 'al-Fatih',
        country_id: '124',
      },
      {
        id: '2200',
        label: 'al-Jabal al Akhdar',
        country_id: '124',
      },
      {
        id: '2201',
        label: 'al-Jufrah',
        country_id: '124',
      },
      {
        id: '2202',
        label: 'al-Khums',
        country_id: '124',
      },
      {
        id: '2203',
        label: 'al-Kufrah',
        country_id: '124',
      },
      {
        id: '2204',
        label: 'an-Nuqat al-Khams',
        country_id: '124',
      },
      {
        id: '2205',
        label: "ash-Shati'",
        country_id: '124',
      },
      {
        id: '2206',
        label: 'az-Zawiyah',
        country_id: '124',
      },
    ],
  },
  {
    id: '125',
    sortname: 'LI',
    label: 'Liechtenstein',
    states: [
      {
        id: '2207',
        label: 'Balzers',
        country_id: '125',
      },
      {
        id: '2208',
        label: 'Eschen',
        country_id: '125',
      },
      {
        id: '2209',
        label: 'Gamprin',
        country_id: '125',
      },
      {
        id: '2210',
        label: 'Mauren',
        country_id: '125',
      },
      {
        id: '2211',
        label: 'Planken',
        country_id: '125',
      },
      {
        id: '2212',
        label: 'Ruggell',
        country_id: '125',
      },
      {
        id: '2213',
        label: 'Schaan',
        country_id: '125',
      },
      {
        id: '2214',
        label: 'Schellenberg',
        country_id: '125',
      },
      {
        id: '2215',
        label: 'Triesen',
        country_id: '125',
      },
      {
        id: '2216',
        label: 'Triesenberg',
        country_id: '125',
      },
      {
        id: '2217',
        label: 'Vaduz',
        country_id: '125',
      },
    ],
  },
  {
    id: '126',
    sortname: 'LT',
    label: 'Lithuania',
    states: [
      {
        id: '2218',
        label: 'Alytaus',
        country_id: '126',
      },
      {
        id: '2219',
        label: 'Anyksciai',
        country_id: '126',
      },
      {
        id: '2220',
        label: 'Kauno',
        country_id: '126',
      },
      {
        id: '2221',
        label: 'Klaipedos',
        country_id: '126',
      },
      {
        id: '2222',
        label: 'Marijampoles',
        country_id: '126',
      },
      {
        id: '2223',
        label: 'Panevezhio',
        country_id: '126',
      },
      {
        id: '2224',
        label: 'Panevezys',
        country_id: '126',
      },
      {
        id: '2225',
        label: 'Shiauliu',
        country_id: '126',
      },
      {
        id: '2226',
        label: 'Taurages',
        country_id: '126',
      },
      {
        id: '2227',
        label: 'Telshiu',
        country_id: '126',
      },
      {
        id: '2228',
        label: 'Telsiai',
        country_id: '126',
      },
      {
        id: '2229',
        label: 'Utenos',
        country_id: '126',
      },
      {
        id: '2230',
        label: 'Vilniaus',
        country_id: '126',
      },
    ],
  },
  {
    id: '127',
    sortname: 'LU',
    label: 'Luxembourg',
    states: [
      {
        id: '2231',
        label: 'Capellen',
        country_id: '127',
      },
      {
        id: '2232',
        label: 'Clervaux',
        country_id: '127',
      },
      {
        id: '2233',
        label: 'Diekirch',
        country_id: '127',
      },
      {
        id: '2234',
        label: 'Echternach',
        country_id: '127',
      },
      {
        id: '2235',
        label: 'Esch-sur-Alzette',
        country_id: '127',
      },
      {
        id: '2236',
        label: 'Grevenmacher',
        country_id: '127',
      },
      {
        id: '2237',
        label: 'Luxembourg',
        country_id: '127',
      },
      {
        id: '2238',
        label: 'Mersch',
        country_id: '127',
      },
      {
        id: '2239',
        label: 'Redange',
        country_id: '127',
      },
      {
        id: '2240',
        label: 'Remich',
        country_id: '127',
      },
      {
        id: '2241',
        label: 'Vianden',
        country_id: '127',
      },
      {
        id: '2242',
        label: 'Wiltz',
        country_id: '127',
      },
    ],
  },
  {
    id: '128',
    sortname: 'MO',
    label: 'Macau S.A.R.',
    states: [
      {
        id: '2243',
        label: 'Macau',
        country_id: '128',
      },
    ],
  },
  {
    id: '129',
    sortname: 'MK',
    label: 'Macedonia',
    states: [
      {
        id: '2244',
        label: 'Berovo',
        country_id: '129',
      },
      {
        id: '2245',
        label: 'Bitola',
        country_id: '129',
      },
      {
        id: '2246',
        label: 'Brod',
        country_id: '129',
      },
      {
        id: '2247',
        label: 'Debar',
        country_id: '129',
      },
      {
        id: '2248',
        label: 'Delchevo',
        country_id: '129',
      },
      {
        id: '2249',
        label: 'Demir Hisar',
        country_id: '129',
      },
      {
        id: '2250',
        label: 'Gevgelija',
        country_id: '129',
      },
      {
        id: '2251',
        label: 'Gostivar',
        country_id: '129',
      },
      {
        id: '2252',
        label: 'Kavadarci',
        country_id: '129',
      },
      {
        id: '2253',
        label: 'Kichevo',
        country_id: '129',
      },
      {
        id: '2254',
        label: 'Kochani',
        country_id: '129',
      },
      {
        id: '2255',
        label: 'Kratovo',
        country_id: '129',
      },
      {
        id: '2256',
        label: 'Kriva Palanka',
        country_id: '129',
      },
      {
        id: '2257',
        label: 'Krushevo',
        country_id: '129',
      },
      {
        id: '2258',
        label: 'Kumanovo',
        country_id: '129',
      },
      {
        id: '2259',
        label: 'Negotino',
        country_id: '129',
      },
      {
        id: '2260',
        label: 'Ohrid',
        country_id: '129',
      },
      {
        id: '2261',
        label: 'Prilep',
        country_id: '129',
      },
      {
        id: '2262',
        label: 'Probishtip',
        country_id: '129',
      },
      {
        id: '2263',
        label: 'Radovish',
        country_id: '129',
      },
      {
        id: '2264',
        label: 'Resen',
        country_id: '129',
      },
      {
        id: '2265',
        label: 'Shtip',
        country_id: '129',
      },
      {
        id: '2266',
        label: 'Skopje',
        country_id: '129',
      },
      {
        id: '2267',
        label: 'Struga',
        country_id: '129',
      },
      {
        id: '2268',
        label: 'Strumica',
        country_id: '129',
      },
      {
        id: '2269',
        label: 'Sveti Nikole',
        country_id: '129',
      },
      {
        id: '2270',
        label: 'Tetovo',
        country_id: '129',
      },
      {
        id: '2271',
        label: 'Valandovo',
        country_id: '129',
      },
      {
        id: '2272',
        label: 'Veles',
        country_id: '129',
      },
      {
        id: '2273',
        label: 'Vinica',
        country_id: '129',
      },
    ],
  },
  {
    id: '130',
    sortname: 'MG',
    label: 'Madagascar',
    states: [
      {
        id: '2274',
        label: 'Antananarivo',
        country_id: '130',
      },
      {
        id: '2275',
        label: 'Antsiranana',
        country_id: '130',
      },
      {
        id: '2276',
        label: 'Fianarantsoa',
        country_id: '130',
      },
      {
        id: '2277',
        label: 'Mahajanga',
        country_id: '130',
      },
      {
        id: '2278',
        label: 'Toamasina',
        country_id: '130',
      },
      {
        id: '2279',
        label: 'Toliary',
        country_id: '130',
      },
    ],
  },
  {
    id: '131',
    sortname: 'MW',
    label: 'Malawi',
    states: [
      {
        id: '2280',
        label: 'Balaka',
        country_id: '131',
      },
      {
        id: '2281',
        label: 'Blantyre City',
        country_id: '131',
      },
      {
        id: '2282',
        label: 'Chikwawa',
        country_id: '131',
      },
      {
        id: '2283',
        label: 'Chiradzulu',
        country_id: '131',
      },
      {
        id: '2284',
        label: 'Chitipa',
        country_id: '131',
      },
      {
        id: '2285',
        label: 'Dedza',
        country_id: '131',
      },
      {
        id: '2286',
        label: 'Dowa',
        country_id: '131',
      },
      {
        id: '2287',
        label: 'Karonga',
        country_id: '131',
      },
      {
        id: '2288',
        label: 'Kasungu',
        country_id: '131',
      },
      {
        id: '2289',
        label: 'Lilongwe City',
        country_id: '131',
      },
      {
        id: '2290',
        label: 'Machinga',
        country_id: '131',
      },
      {
        id: '2291',
        label: 'Mangochi',
        country_id: '131',
      },
      {
        id: '2292',
        label: 'Mchinji',
        country_id: '131',
      },
      {
        id: '2293',
        label: 'Mulanje',
        country_id: '131',
      },
      {
        id: '2294',
        label: 'Mwanza',
        country_id: '131',
      },
      {
        id: '2295',
        label: 'Mzimba',
        country_id: '131',
      },
      {
        id: '2296',
        label: 'Mzuzu City',
        country_id: '131',
      },
      {
        id: '2297',
        label: 'Nkhata Bay',
        country_id: '131',
      },
      {
        id: '2298',
        label: 'Nkhotakota',
        country_id: '131',
      },
      {
        id: '2299',
        label: 'Nsanje',
        country_id: '131',
      },
      {
        id: '2300',
        label: 'Ntcheu',
        country_id: '131',
      },
      {
        id: '2301',
        label: 'Ntchisi',
        country_id: '131',
      },
      {
        id: '2302',
        label: 'Phalombe',
        country_id: '131',
      },
      {
        id: '2303',
        label: 'Rumphi',
        country_id: '131',
      },
      {
        id: '2304',
        label: 'Salima',
        country_id: '131',
      },
      {
        id: '2305',
        label: 'Thyolo',
        country_id: '131',
      },
      {
        id: '2306',
        label: 'Zomba Municipality',
        country_id: '131',
      },
    ],
  },
  {
    id: '132',
    sortname: 'MY',
    label: 'Malaysia',
    states: [
      {
        id: '2307',
        label: 'Johor',
        country_id: '132',
      },
      {
        id: '2308',
        label: 'Kedah',
        country_id: '132',
      },
      {
        id: '2309',
        label: 'Kelantan',
        country_id: '132',
      },
      {
        id: '2310',
        label: 'Kuala Lumpur',
        country_id: '132',
      },
      {
        id: '2311',
        label: 'Labuan',
        country_id: '132',
      },
      {
        id: '2312',
        label: 'Melaka',
        country_id: '132',
      },
      {
        id: '2313',
        label: 'Negeri Johor',
        country_id: '132',
      },
      {
        id: '2314',
        label: 'Negeri Sembilan',
        country_id: '132',
      },
      {
        id: '2315',
        label: 'Pahang',
        country_id: '132',
      },
      {
        id: '2316',
        label: 'Penang',
        country_id: '132',
      },
      {
        id: '2317',
        label: 'Perak',
        country_id: '132',
      },
      {
        id: '2318',
        label: 'Perlis',
        country_id: '132',
      },
      {
        id: '2319',
        label: 'Pulau Pinang',
        country_id: '132',
      },
      {
        id: '2320',
        label: 'Sabah',
        country_id: '132',
      },
      {
        id: '2321',
        label: 'Sarawak',
        country_id: '132',
      },
      {
        id: '2322',
        label: 'Selangor',
        country_id: '132',
      },
      {
        id: '2323',
        label: 'Sembilan',
        country_id: '132',
      },
      {
        id: '2324',
        label: 'Terengganu',
        country_id: '132',
      },
    ],
  },
  {
    id: '133',
    sortname: 'MV',
    label: 'Maldives',
    states: [
      {
        id: '2325',
        label: 'Alif Alif',
        country_id: '133',
      },
      {
        id: '2326',
        label: 'Alif Dhaal',
        country_id: '133',
      },
      {
        id: '2327',
        label: 'Baa',
        country_id: '133',
      },
      {
        id: '2328',
        label: 'Dhaal',
        country_id: '133',
      },
      {
        id: '2329',
        label: 'Faaf',
        country_id: '133',
      },
      {
        id: '2330',
        label: 'Gaaf Alif',
        country_id: '133',
      },
      {
        id: '2331',
        label: 'Gaaf Dhaal',
        country_id: '133',
      },
      {
        id: '2332',
        label: 'Ghaviyani',
        country_id: '133',
      },
      {
        id: '2333',
        label: 'Haa Alif',
        country_id: '133',
      },
      {
        id: '2334',
        label: 'Haa Dhaal',
        country_id: '133',
      },
      {
        id: '2335',
        label: 'Kaaf',
        country_id: '133',
      },
      {
        id: '2336',
        label: 'Laam',
        country_id: '133',
      },
      {
        id: '2337',
        label: 'Lhaviyani',
        country_id: '133',
      },
      {
        id: '2338',
        label: 'Male',
        country_id: '133',
      },
      {
        id: '2339',
        label: 'Miim',
        country_id: '133',
      },
      {
        id: '2340',
        label: 'Nuun',
        country_id: '133',
      },
      {
        id: '2341',
        label: 'Raa',
        country_id: '133',
      },
      {
        id: '2342',
        label: 'Shaviyani',
        country_id: '133',
      },
      {
        id: '2343',
        label: 'Siin',
        country_id: '133',
      },
      {
        id: '2344',
        label: 'Thaa',
        country_id: '133',
      },
      {
        id: '2345',
        label: 'Vaav',
        country_id: '133',
      },
    ],
  },
  {
    id: '134',
    sortname: 'ML',
    label: 'Mali',
    states: [
      {
        id: '2346',
        label: 'Bamako',
        country_id: '134',
      },
      {
        id: '2347',
        label: 'Gao',
        country_id: '134',
      },
      {
        id: '2348',
        label: 'Kayes',
        country_id: '134',
      },
      {
        id: '2349',
        label: 'Kidal',
        country_id: '134',
      },
      {
        id: '2350',
        label: 'Koulikoro',
        country_id: '134',
      },
      {
        id: '2351',
        label: 'Mopti',
        country_id: '134',
      },
      {
        id: '2352',
        label: 'Segou',
        country_id: '134',
      },
      {
        id: '2353',
        label: 'Sikasso',
        country_id: '134',
      },
      {
        id: '2354',
        label: 'Tombouctou',
        country_id: '134',
      },
    ],
  },
  {
    id: '135',
    sortname: 'MT',
    label: 'Malta',
    states: [
      {
        id: '2355',
        label: 'Gozo and Comino',
        country_id: '135',
      },
      {
        id: '2356',
        label: 'Inner Harbour',
        country_id: '135',
      },
      {
        id: '2357',
        label: 'Northern',
        country_id: '135',
      },
      {
        id: '2358',
        label: 'Outer Harbour',
        country_id: '135',
      },
      {
        id: '2359',
        label: 'South Eastern',
        country_id: '135',
      },
      {
        id: '2360',
        label: 'Valletta',
        country_id: '135',
      },
      {
        id: '2361',
        label: 'Western',
        country_id: '135',
      },
    ],
  },
  {
    id: '136',
    sortname: 'XM',
    label: 'Man (Isle of)',
    states: [
      {
        id: '2362',
        label: 'Castletown',
        country_id: '136',
      },
      {
        id: '2363',
        label: 'Douglas',
        country_id: '136',
      },
      {
        id: '2364',
        label: 'Laxey',
        country_id: '136',
      },
      {
        id: '2365',
        label: 'Onchan',
        country_id: '136',
      },
      {
        id: '2366',
        label: 'Peel',
        country_id: '136',
      },
      {
        id: '2367',
        label: 'Port Erin',
        country_id: '136',
      },
      {
        id: '2368',
        label: 'Port Saint Mary',
        country_id: '136',
      },
      {
        id: '2369',
        label: 'Ramsey',
        country_id: '136',
      },
    ],
  },
  {
    id: '137',
    sortname: 'MH',
    label: 'Marshall Islands',
    states: [
      {
        id: '2370',
        label: 'Ailinlaplap',
        country_id: '137',
      },
      {
        id: '2371',
        label: 'Ailuk',
        country_id: '137',
      },
      {
        id: '2372',
        label: 'Arno',
        country_id: '137',
      },
      {
        id: '2373',
        label: 'Aur',
        country_id: '137',
      },
      {
        id: '2374',
        label: 'Bikini',
        country_id: '137',
      },
      {
        id: '2375',
        label: 'Ebon',
        country_id: '137',
      },
      {
        id: '2376',
        label: 'Enewetak',
        country_id: '137',
      },
      {
        id: '2377',
        label: 'Jabat',
        country_id: '137',
      },
      {
        id: '2378',
        label: 'Jaluit',
        country_id: '137',
      },
      {
        id: '2379',
        label: 'Kili',
        country_id: '137',
      },
      {
        id: '2380',
        label: 'Kwajalein',
        country_id: '137',
      },
      {
        id: '2381',
        label: 'Lae',
        country_id: '137',
      },
      {
        id: '2382',
        label: 'Lib',
        country_id: '137',
      },
      {
        id: '2383',
        label: 'Likiep',
        country_id: '137',
      },
      {
        id: '2384',
        label: 'Majuro',
        country_id: '137',
      },
      {
        id: '2385',
        label: 'Maloelap',
        country_id: '137',
      },
      {
        id: '2386',
        label: 'Mejit',
        country_id: '137',
      },
      {
        id: '2387',
        label: 'Mili',
        country_id: '137',
      },
      {
        id: '2388',
        label: 'Namorik',
        country_id: '137',
      },
      {
        id: '2389',
        label: 'Namu',
        country_id: '137',
      },
      {
        id: '2390',
        label: 'Rongelap',
        country_id: '137',
      },
      {
        id: '2391',
        label: 'Ujae',
        country_id: '137',
      },
      {
        id: '2392',
        label: 'Utrik',
        country_id: '137',
      },
      {
        id: '2393',
        label: 'Wotho',
        country_id: '137',
      },
      {
        id: '2394',
        label: 'Wotje',
        country_id: '137',
      },
    ],
  },
  {
    id: '138',
    sortname: 'MQ',
    label: 'Martinique',
    states: [
      {
        id: '2395',
        label: 'Fort-de-France',
        country_id: '138',
      },
      {
        id: '2396',
        label: 'La Trinite',
        country_id: '138',
      },
      {
        id: '2397',
        label: 'Le Marin',
        country_id: '138',
      },
      {
        id: '2398',
        label: 'Saint-Pierre',
        country_id: '138',
      },
    ],
  },
  {
    id: '139',
    sortname: 'MR',
    label: 'Mauritania',
    states: [
      {
        id: '2399',
        label: 'Adrar',
        country_id: '139',
      },
      {
        id: '2400',
        label: 'Assaba',
        country_id: '139',
      },
      {
        id: '2401',
        label: 'Brakna',
        country_id: '139',
      },
      {
        id: '2402',
        label: 'Dhakhlat Nawadibu',
        country_id: '139',
      },
      {
        id: '2403',
        label: 'Hudh-al-Gharbi',
        country_id: '139',
      },
      {
        id: '2404',
        label: 'Hudh-ash-Sharqi',
        country_id: '139',
      },
      {
        id: '2405',
        label: 'Inshiri',
        country_id: '139',
      },
      {
        id: '2406',
        label: 'Nawakshut',
        country_id: '139',
      },
      {
        id: '2407',
        label: 'Qidimagha',
        country_id: '139',
      },
      {
        id: '2408',
        label: 'Qurqul',
        country_id: '139',
      },
      {
        id: '2409',
        label: 'Taqant',
        country_id: '139',
      },
      {
        id: '2410',
        label: 'Tiris Zammur',
        country_id: '139',
      },
      {
        id: '2411',
        label: 'Trarza',
        country_id: '139',
      },
    ],
  },
  {
    id: '140',
    sortname: 'MU',
    label: 'Mauritius',
    states: [
      {
        id: '2412',
        label: 'Black River',
        country_id: '140',
      },
      {
        id: '2413',
        label: 'Eau Coulee',
        country_id: '140',
      },
      {
        id: '2414',
        label: 'Flacq',
        country_id: '140',
      },
      {
        id: '2415',
        label: 'Floreal',
        country_id: '140',
      },
      {
        id: '2416',
        label: 'Grand Port',
        country_id: '140',
      },
      {
        id: '2417',
        label: 'Moka',
        country_id: '140',
      },
      {
        id: '2418',
        label: 'Pamplempousses',
        country_id: '140',
      },
      {
        id: '2419',
        label: 'Plaines Wilhelm',
        country_id: '140',
      },
      {
        id: '2420',
        label: 'Port Louis',
        country_id: '140',
      },
      {
        id: '2421',
        label: 'Riviere du Rempart',
        country_id: '140',
      },
      {
        id: '2422',
        label: 'Rodrigues',
        country_id: '140',
      },
      {
        id: '2423',
        label: 'Rose Hill',
        country_id: '140',
      },
      {
        id: '2424',
        label: 'Savanne',
        country_id: '140',
      },
    ],
  },
  {
    id: '141',
    sortname: 'YT',
    label: 'Mayotte',
    states: [
      {
        id: '2425',
        label: 'Mayotte',
        country_id: '141',
      },
      {
        id: '2426',
        label: 'Pamanzi',
        country_id: '141',
      },
    ],
  },
  {
    id: '142',
    sortname: 'MX',
    label: 'Mexico',
    states: [
      {
        id: '2427',
        label: 'Aguascalientes',
        country_id: '142',
      },
      {
        id: '2428',
        label: 'Baja California',
        country_id: '142',
      },
      {
        id: '2429',
        label: 'Baja California Sur',
        country_id: '142',
      },
      {
        id: '2430',
        label: 'Campeche',
        country_id: '142',
      },
      {
        id: '2431',
        label: 'Chiapas',
        country_id: '142',
      },
      {
        id: '2432',
        label: 'Chihuahua',
        country_id: '142',
      },
      {
        id: '2433',
        label: 'Coahuila',
        country_id: '142',
      },
      {
        id: '2434',
        label: 'Colima',
        country_id: '142',
      },
      {
        id: '2435',
        label: 'Distrito Federal',
        country_id: '142',
      },
      {
        id: '2436',
        label: 'Durango',
        country_id: '142',
      },
      {
        id: '2437',
        label: 'Estado de Mexico',
        country_id: '142',
      },
      {
        id: '2438',
        label: 'Guanajuato',
        country_id: '142',
      },
      {
        id: '2439',
        label: 'Guerrero',
        country_id: '142',
      },
      {
        id: '2440',
        label: 'Hidalgo',
        country_id: '142',
      },
      {
        id: '2441',
        label: 'Jalisco',
        country_id: '142',
      },
      {
        id: '2442',
        label: 'Mexico',
        country_id: '142',
      },
      {
        id: '2443',
        label: 'Michoacan',
        country_id: '142',
      },
      {
        id: '2444',
        label: 'Morelos',
        country_id: '142',
      },
      {
        id: '2445',
        label: 'Nayarit',
        country_id: '142',
      },
      {
        id: '2446',
        label: 'Nuevo Leon',
        country_id: '142',
      },
      {
        id: '2447',
        label: 'Oaxaca',
        country_id: '142',
      },
      {
        id: '2448',
        label: 'Puebla',
        country_id: '142',
      },
      {
        id: '2449',
        label: 'Queretaro',
        country_id: '142',
      },
      {
        id: '2450',
        label: 'Quintana Roo',
        country_id: '142',
      },
      {
        id: '2451',
        label: 'San Luis Potosi',
        country_id: '142',
      },
      {
        id: '2452',
        label: 'Sinaloa',
        country_id: '142',
      },
      {
        id: '2453',
        label: 'Sonora',
        country_id: '142',
      },
      {
        id: '2454',
        label: 'Tabasco',
        country_id: '142',
      },
      {
        id: '2455',
        label: 'Tamaulipas',
        country_id: '142',
      },
      {
        id: '2456',
        label: 'Tlaxcala',
        country_id: '142',
      },
      {
        id: '2457',
        label: 'Veracruz',
        country_id: '142',
      },
      {
        id: '2458',
        label: 'Yucatan',
        country_id: '142',
      },
      {
        id: '2459',
        label: 'Zacatecas',
        country_id: '142',
      },
    ],
  },
  {
    id: '143',
    sortname: 'FM',
    label: 'Micronesia',
    states: [
      {
        id: '2460',
        label: 'Chuuk',
        country_id: '143',
      },
      {
        id: '2461',
        label: 'Kusaie',
        country_id: '143',
      },
      {
        id: '2462',
        label: 'Pohnpei',
        country_id: '143',
      },
      {
        id: '2463',
        label: 'Yap',
        country_id: '143',
      },
    ],
  },
  {
    id: '144',
    sortname: 'MD',
    label: 'Moldova',
    states: [
      {
        id: '2464',
        label: 'Balti',
        country_id: '144',
      },
      {
        id: '2465',
        label: 'Cahul',
        country_id: '144',
      },
      {
        id: '2466',
        label: 'Chisinau',
        country_id: '144',
      },
      {
        id: '2467',
        label: 'Chisinau Oras',
        country_id: '144',
      },
      {
        id: '2468',
        label: 'Edinet',
        country_id: '144',
      },
      {
        id: '2469',
        label: 'Gagauzia',
        country_id: '144',
      },
      {
        id: '2470',
        label: 'Lapusna',
        country_id: '144',
      },
      {
        id: '2471',
        label: 'Orhei',
        country_id: '144',
      },
      {
        id: '2472',
        label: 'Soroca',
        country_id: '144',
      },
      {
        id: '2473',
        label: 'Taraclia',
        country_id: '144',
      },
      {
        id: '2474',
        label: 'Tighina',
        country_id: '144',
      },
      {
        id: '2475',
        label: 'Transnistria',
        country_id: '144',
      },
      {
        id: '2476',
        label: 'Ungheni',
        country_id: '144',
      },
    ],
  },
  {
    id: '145',
    sortname: 'MC',
    label: 'Monaco',
    states: [
      {
        id: '2477',
        label: 'Fontvieille',
        country_id: '145',
      },
      {
        id: '2478',
        label: 'La Condamine',
        country_id: '145',
      },
      {
        id: '2479',
        label: 'Monaco-Ville',
        country_id: '145',
      },
      {
        id: '2480',
        label: 'Monte Carlo',
        country_id: '145',
      },
    ],
  },
  {
    id: '146',
    sortname: 'MN',
    label: 'Mongolia',
    states: [
      {
        id: '2481',
        label: 'Arhangaj',
        country_id: '146',
      },
      {
        id: '2482',
        label: 'Bajan-Olgij',
        country_id: '146',
      },
      {
        id: '2483',
        label: 'Bajanhongor',
        country_id: '146',
      },
      {
        id: '2484',
        label: 'Bulgan',
        country_id: '146',
      },
      {
        id: '2485',
        label: 'Darhan-Uul',
        country_id: '146',
      },
      {
        id: '2486',
        label: 'Dornod',
        country_id: '146',
      },
      {
        id: '2487',
        label: 'Dornogovi',
        country_id: '146',
      },
      {
        id: '2488',
        label: 'Dundgovi',
        country_id: '146',
      },
      {
        id: '2489',
        label: 'Govi-Altaj',
        country_id: '146',
      },
      {
        id: '2490',
        label: 'Govisumber',
        country_id: '146',
      },
      {
        id: '2491',
        label: 'Hentij',
        country_id: '146',
      },
      {
        id: '2492',
        label: 'Hovd',
        country_id: '146',
      },
      {
        id: '2493',
        label: 'Hovsgol',
        country_id: '146',
      },
      {
        id: '2494',
        label: 'Omnogovi',
        country_id: '146',
      },
      {
        id: '2495',
        label: 'Orhon',
        country_id: '146',
      },
      {
        id: '2496',
        label: 'Ovorhangaj',
        country_id: '146',
      },
      {
        id: '2497',
        label: 'Selenge',
        country_id: '146',
      },
      {
        id: '2498',
        label: 'Suhbaatar',
        country_id: '146',
      },
      {
        id: '2499',
        label: 'Tov',
        country_id: '146',
      },
      {
        id: '2500',
        label: 'Ulaanbaatar',
        country_id: '146',
      },
      {
        id: '2501',
        label: 'Uvs',
        country_id: '146',
      },
      {
        id: '2502',
        label: 'Zavhan',
        country_id: '146',
      },
    ],
  },
  {
    id: '147',
    sortname: 'MS',
    label: 'Montserrat',
    states: [
      {
        id: '2503',
        label: 'Montserrat',
        country_id: '147',
      },
    ],
  },
  {
    id: '148',
    sortname: 'MA',
    label: 'Morocco',
    states: [
      {
        id: '2504',
        label: 'Agadir',
        country_id: '148',
      },
      {
        id: '2505',
        label: 'Casablanca',
        country_id: '148',
      },
      {
        id: '2506',
        label: 'Chaouia-Ouardigha',
        country_id: '148',
      },
      {
        id: '2507',
        label: 'Doukkala-Abda',
        country_id: '148',
      },
      {
        id: '2508',
        label: 'Fes-Boulemane',
        country_id: '148',
      },
      {
        id: '2509',
        label: 'Gharb-Chrarda-Beni Hssen',
        country_id: '148',
      },
      {
        id: '2510',
        label: 'Guelmim',
        country_id: '148',
      },
      {
        id: '2511',
        label: 'Kenitra',
        country_id: '148',
      },
      {
        id: '2512',
        label: 'Marrakech-Tensift-Al Haouz',
        country_id: '148',
      },
      {
        id: '2513',
        label: 'Meknes-Tafilalet',
        country_id: '148',
      },
      {
        id: '2514',
        label: 'Oriental',
        country_id: '148',
      },
      {
        id: '2515',
        label: 'Oujda',
        country_id: '148',
      },
      {
        id: '2516',
        label: 'Province de Tanger',
        country_id: '148',
      },
      {
        id: '2517',
        label: 'Rabat-Sale-Zammour-Zaer',
        country_id: '148',
      },
      {
        id: '2518',
        label: 'Sala Al Jadida',
        country_id: '148',
      },
      {
        id: '2519',
        label: 'Settat',
        country_id: '148',
      },
      {
        id: '2520',
        label: 'Souss Massa-Draa',
        country_id: '148',
      },
      {
        id: '2521',
        label: 'Tadla-Azilal',
        country_id: '148',
      },
      {
        id: '2522',
        label: 'Tangier-Tetouan',
        country_id: '148',
      },
      {
        id: '2523',
        label: 'Taza-Al Hoceima-Taounate',
        country_id: '148',
      },
      {
        id: '2524',
        label: 'Wilaya de Casablanca',
        country_id: '148',
      },
      {
        id: '2525',
        label: 'Wilaya de Rabat-Sale',
        country_id: '148',
      },
    ],
  },
  {
    id: '149',
    sortname: 'MZ',
    label: 'Mozambique',
    states: [
      {
        id: '2526',
        label: 'Cabo Delgado',
        country_id: '149',
      },
      {
        id: '2527',
        label: 'Gaza',
        country_id: '149',
      },
      {
        id: '2528',
        label: 'Inhambane',
        country_id: '149',
      },
      {
        id: '2529',
        label: 'Manica',
        country_id: '149',
      },
      {
        id: '2530',
        label: 'Maputo',
        country_id: '149',
      },
      {
        id: '2531',
        label: 'Maputo Provincia',
        country_id: '149',
      },
      {
        id: '2532',
        label: 'Nampula',
        country_id: '149',
      },
      {
        id: '2533',
        label: 'Niassa',
        country_id: '149',
      },
      {
        id: '2534',
        label: 'Sofala',
        country_id: '149',
      },
      {
        id: '2535',
        label: 'Tete',
        country_id: '149',
      },
      {
        id: '2536',
        label: 'Zambezia',
        country_id: '149',
      },
    ],
  },
  {
    id: '150',
    sortname: 'MM',
    label: 'Myanmar',
    states: [
      {
        id: '2537',
        label: 'Ayeyarwady',
        country_id: '150',
      },
      {
        id: '2538',
        label: 'Bago',
        country_id: '150',
      },
      {
        id: '2539',
        label: 'Chin',
        country_id: '150',
      },
      {
        id: '2540',
        label: 'Kachin',
        country_id: '150',
      },
      {
        id: '2541',
        label: 'Kayah',
        country_id: '150',
      },
      {
        id: '2542',
        label: 'Kayin',
        country_id: '150',
      },
      {
        id: '2543',
        label: 'Magway',
        country_id: '150',
      },
      {
        id: '2544',
        label: 'Mandalay',
        country_id: '150',
      },
      {
        id: '2545',
        label: 'Mon',
        country_id: '150',
      },
      {
        id: '2546',
        label: 'Nay Pyi Taw',
        country_id: '150',
      },
      {
        id: '2547',
        label: 'Rakhine',
        country_id: '150',
      },
      {
        id: '2548',
        label: 'Sagaing',
        country_id: '150',
      },
      {
        id: '2549',
        label: 'Shan',
        country_id: '150',
      },
      {
        id: '2550',
        label: 'Tanintharyi',
        country_id: '150',
      },
      {
        id: '2551',
        label: 'Yangon',
        country_id: '150',
      },
    ],
  },
  {
    id: '151',
    sortname: 'NA',
    label: 'Namibia',
    states: [
      {
        id: '2552',
        label: 'Caprivi',
        country_id: '151',
      },
      {
        id: '2553',
        label: 'Erongo',
        country_id: '151',
      },
      {
        id: '2554',
        label: 'Hardap',
        country_id: '151',
      },
      {
        id: '2555',
        label: 'Karas',
        country_id: '151',
      },
      {
        id: '2556',
        label: 'Kavango',
        country_id: '151',
      },
      {
        id: '2557',
        label: 'Khomas',
        country_id: '151',
      },
      {
        id: '2558',
        label: 'Kunene',
        country_id: '151',
      },
      {
        id: '2559',
        label: 'Ohangwena',
        country_id: '151',
      },
      {
        id: '2560',
        label: 'Omaheke',
        country_id: '151',
      },
      {
        id: '2561',
        label: 'Omusati',
        country_id: '151',
      },
      {
        id: '2562',
        label: 'Oshana',
        country_id: '151',
      },
      {
        id: '2563',
        label: 'Oshikoto',
        country_id: '151',
      },
      {
        id: '2564',
        label: 'Otjozondjupa',
        country_id: '151',
      },
    ],
  },
  {
    id: '152',
    sortname: 'NR',
    label: 'Nauru',
    states: [
      {
        id: '2565',
        label: 'Yaren',
        country_id: '152',
      },
    ],
  },
  {
    id: '153',
    sortname: 'NP',
    label: 'Nepal',
    states: [
      {
        id: '2566',
        label: 'Bagmati',
        country_id: '153',
      },
      {
        id: '2567',
        label: 'Bheri',
        country_id: '153',
      },
      {
        id: '2568',
        label: 'Dhawalagiri',
        country_id: '153',
      },
      {
        id: '2569',
        label: 'Gandaki',
        country_id: '153',
      },
      {
        id: '2570',
        label: 'Janakpur',
        country_id: '153',
      },
      {
        id: '2571',
        label: 'Karnali',
        country_id: '153',
      },
      {
        id: '2572',
        label: 'Koshi',
        country_id: '153',
      },
      {
        id: '2573',
        label: 'Lumbini',
        country_id: '153',
      },
      {
        id: '2574',
        label: 'Mahakali',
        country_id: '153',
      },
      {
        id: '2575',
        label: 'Mechi',
        country_id: '153',
      },
      {
        id: '2576',
        label: 'Narayani',
        country_id: '153',
      },
      {
        id: '2577',
        label: 'Rapti',
        country_id: '153',
      },
      {
        id: '2578',
        label: 'Sagarmatha',
        country_id: '153',
      },
      {
        id: '2579',
        label: 'Seti',
        country_id: '153',
      },
    ],
  },
  {
    id: '154',
    sortname: 'AN',
    label: 'Netherlands Antilles',
    states: [
      {
        id: '2580',
        label: 'Bonaire',
        country_id: '154',
      },
      {
        id: '2581',
        label: 'Curacao',
        country_id: '154',
      },
      {
        id: '2582',
        label: 'Saba',
        country_id: '154',
      },
      {
        id: '2583',
        label: 'Sint Eustatius',
        country_id: '154',
      },
      {
        id: '2584',
        label: 'Sint Maarten',
        country_id: '154',
      },
    ],
  },
  {
    id: '155',
    sortname: 'NL',
    label: 'Netherlands The',
    states: [
      {
        id: '2585',
        label: 'Amsterdam',
        country_id: '155',
      },
      {
        id: '2586',
        label: 'Benelux',
        country_id: '155',
      },
      {
        id: '2587',
        label: 'Drenthe',
        country_id: '155',
      },
      {
        id: '2588',
        label: 'Flevoland',
        country_id: '155',
      },
      {
        id: '2589',
        label: 'Friesland',
        country_id: '155',
      },
      {
        id: '2590',
        label: 'Gelderland',
        country_id: '155',
      },
      {
        id: '2591',
        label: 'Groningen',
        country_id: '155',
      },
      {
        id: '2592',
        label: 'Limburg',
        country_id: '155',
      },
      {
        id: '2593',
        label: 'Noord-Brabant',
        country_id: '155',
      },
      {
        id: '2594',
        label: 'Noord-Holland',
        country_id: '155',
      },
      {
        id: '2595',
        label: 'Overijssel',
        country_id: '155',
      },
      {
        id: '2596',
        label: 'South Holland',
        country_id: '155',
      },
      {
        id: '2597',
        label: 'Utrecht',
        country_id: '155',
      },
      {
        id: '2598',
        label: 'Zeeland',
        country_id: '155',
      },
      {
        id: '2599',
        label: 'Zuid-Holland',
        country_id: '155',
      },
    ],
  },
  {
    id: '156',
    sortname: 'NC',
    label: 'New Caledonia',
    states: [
      {
        id: '2600',
        label: 'Iles',
        country_id: '156',
      },
      {
        id: '2601',
        label: 'Nord',
        country_id: '156',
      },
      {
        id: '2602',
        label: 'Sud',
        country_id: '156',
      },
    ],
  },
  {
    id: '157',
    sortname: 'NZ',
    label: 'New Zealand',
    states: [
      {
        id: '2603',
        label: 'Area Outside Region',
        country_id: '157',
      },
      {
        id: '2604',
        label: 'Auckland',
        country_id: '157',
      },
      {
        id: '2605',
        label: 'Bay of Plenty',
        country_id: '157',
      },
      {
        id: '2606',
        label: 'Canterbury',
        country_id: '157',
      },
      {
        id: '2607',
        label: 'Christchurch',
        country_id: '157',
      },
      {
        id: '2608',
        label: 'Gisborne',
        country_id: '157',
      },
      {
        id: '2609',
        label: "Hawke's Bay",
        country_id: '157',
      },
      {
        id: '2610',
        label: 'Manawatu-Wanganui',
        country_id: '157',
      },
      {
        id: '2611',
        label: 'Marlborough',
        country_id: '157',
      },
      {
        id: '2612',
        label: 'Nelson',
        country_id: '157',
      },
      {
        id: '2613',
        label: 'Northland',
        country_id: '157',
      },
      {
        id: '2614',
        label: 'Otago',
        country_id: '157',
      },
      {
        id: '2615',
        label: 'Rodney',
        country_id: '157',
      },
      {
        id: '2616',
        label: 'Southland',
        country_id: '157',
      },
      {
        id: '2617',
        label: 'Taranaki',
        country_id: '157',
      },
      {
        id: '2618',
        label: 'Tasman',
        country_id: '157',
      },
      {
        id: '2619',
        label: 'Waikato',
        country_id: '157',
      },
      {
        id: '2620',
        label: 'Wellington',
        country_id: '157',
      },
      {
        id: '2621',
        label: 'West Coast',
        country_id: '157',
      },
    ],
  },
  {
    id: '158',
    sortname: 'NI',
    label: 'Nicaragua',
    states: [
      {
        id: '2622',
        label: 'Atlantico Norte',
        country_id: '158',
      },
      {
        id: '2623',
        label: 'Atlantico Sur',
        country_id: '158',
      },
      {
        id: '2624',
        label: 'Boaco',
        country_id: '158',
      },
      {
        id: '2625',
        label: 'Carazo',
        country_id: '158',
      },
      {
        id: '2626',
        label: 'Chinandega',
        country_id: '158',
      },
      {
        id: '2627',
        label: 'Chontales',
        country_id: '158',
      },
      {
        id: '2628',
        label: 'Esteli',
        country_id: '158',
      },
      {
        id: '2629',
        label: 'Granada',
        country_id: '158',
      },
      {
        id: '2630',
        label: 'Jinotega',
        country_id: '158',
      },
      {
        id: '2631',
        label: 'Leon',
        country_id: '158',
      },
      {
        id: '2632',
        label: 'Madriz',
        country_id: '158',
      },
      {
        id: '2633',
        label: 'Managua',
        country_id: '158',
      },
      {
        id: '2634',
        label: 'Masaya',
        country_id: '158',
      },
      {
        id: '2635',
        label: 'Matagalpa',
        country_id: '158',
      },
      {
        id: '2636',
        label: 'Nueva Segovia',
        country_id: '158',
      },
      {
        id: '2637',
        label: 'Rio San Juan',
        country_id: '158',
      },
      {
        id: '2638',
        label: 'Rivas',
        country_id: '158',
      },
    ],
  },
  {
    id: '159',
    sortname: 'NE',
    label: 'Niger',
    states: [
      {
        id: '2639',
        label: 'Agadez',
        country_id: '159',
      },
      {
        id: '2640',
        label: 'Diffa',
        country_id: '159',
      },
      {
        id: '2641',
        label: 'Dosso',
        country_id: '159',
      },
      {
        id: '2642',
        label: 'Maradi',
        country_id: '159',
      },
      {
        id: '2643',
        label: 'Niamey',
        country_id: '159',
      },
      {
        id: '2644',
        label: 'Tahoua',
        country_id: '159',
      },
      {
        id: '2645',
        label: 'Tillabery',
        country_id: '159',
      },
      {
        id: '2646',
        label: 'Zinder',
        country_id: '159',
      },
    ],
  },
  {
    id: '160',
    sortname: 'NG',
    label: 'Nigeria',
    states: [
      {
        id: '2647',
        label: 'Abia',
        country_id: '160',
      },
      {
        id: '2648',
        label: 'Abuja Federal Capital Territor',
        country_id: '160',
      },
      {
        id: '2649',
        label: 'Adamawa',
        country_id: '160',
      },
      {
        id: '2650',
        label: 'Akwa Ibom',
        country_id: '160',
      },
      {
        id: '2651',
        label: 'Anambra',
        country_id: '160',
      },
      {
        id: '2652',
        label: 'Bauchi',
        country_id: '160',
      },
      {
        id: '2653',
        label: 'Bayelsa',
        country_id: '160',
      },
      {
        id: '2654',
        label: 'Benue',
        country_id: '160',
      },
      {
        id: '2655',
        label: 'Borno',
        country_id: '160',
      },
      {
        id: '2656',
        label: 'Cross River',
        country_id: '160',
      },
      {
        id: '2657',
        label: 'Delta',
        country_id: '160',
      },
      {
        id: '2658',
        label: 'Ebonyi',
        country_id: '160',
      },
      {
        id: '2659',
        label: 'Edo',
        country_id: '160',
      },
      {
        id: '2660',
        label: 'Ekiti',
        country_id: '160',
      },
      {
        id: '2661',
        label: 'Enugu',
        country_id: '160',
      },
      {
        id: '2662',
        label: 'Gombe',
        country_id: '160',
      },
      {
        id: '2663',
        label: 'Imo',
        country_id: '160',
      },
      {
        id: '2664',
        label: 'Jigawa',
        country_id: '160',
      },
      {
        id: '2665',
        label: 'Kaduna',
        country_id: '160',
      },
      {
        id: '2666',
        label: 'Kano',
        country_id: '160',
      },
      {
        id: '2667',
        label: 'Katsina',
        country_id: '160',
      },
      {
        id: '2668',
        label: 'Kebbi',
        country_id: '160',
      },
      {
        id: '2669',
        label: 'Kogi',
        country_id: '160',
      },
      {
        id: '2670',
        label: 'Kwara',
        country_id: '160',
      },
      {
        id: '2671',
        label: 'Lagos',
        country_id: '160',
      },
      {
        id: '2672',
        label: 'Nassarawa',
        country_id: '160',
      },
      {
        id: '2673',
        label: 'Niger',
        country_id: '160',
      },
      {
        id: '2674',
        label: 'Ogun',
        country_id: '160',
      },
      {
        id: '2675',
        label: 'Ondo',
        country_id: '160',
      },
      {
        id: '2676',
        label: 'Osun',
        country_id: '160',
      },
      {
        id: '2677',
        label: 'Oyo',
        country_id: '160',
      },
      {
        id: '2678',
        label: 'Plateau',
        country_id: '160',
      },
      {
        id: '2679',
        label: 'Rivers',
        country_id: '160',
      },
      {
        id: '2680',
        label: 'Sokoto',
        country_id: '160',
      },
      {
        id: '2681',
        label: 'Taraba',
        country_id: '160',
      },
      {
        id: '2682',
        label: 'Yobe',
        country_id: '160',
      },
      {
        id: '2683',
        label: 'Zamfara',
        country_id: '160',
      },
    ],
  },
  {
    id: '161',
    sortname: 'NU',
    label: 'Niue',
    states: [
      {
        id: '2684',
        label: 'Niue',
        country_id: '161',
      },
    ],
  },
  {
    id: '162',
    sortname: 'NF',
    label: 'Norfolk Island',
    states: [
      {
        id: '2685',
        label: 'Norfolk Island',
        country_id: '162',
      },
    ],
  },
  {
    id: '163',
    sortname: 'MP',
    label: 'Northern Mariana Islands',
    states: [
      {
        id: '2686',
        label: 'Northern Islands',
        country_id: '163',
      },
      {
        id: '2687',
        label: 'Rota',
        country_id: '163',
      },
      {
        id: '2688',
        label: 'Saipan',
        country_id: '163',
      },
      {
        id: '2689',
        label: 'Tinian',
        country_id: '163',
      },
    ],
  },
  {
    id: '164',
    sortname: 'NO',
    label: 'Norway',
    states: [
      {
        id: '2690',
        label: 'Akershus',
        country_id: '164',
      },
      {
        id: '2691',
        label: 'Aust Agder',
        country_id: '164',
      },
      {
        id: '2692',
        label: 'Bergen',
        country_id: '164',
      },
      {
        id: '2693',
        label: 'Buskerud',
        country_id: '164',
      },
      {
        id: '2694',
        label: 'Finnmark',
        country_id: '164',
      },
      {
        id: '2695',
        label: 'Hedmark',
        country_id: '164',
      },
      {
        id: '2696',
        label: 'Hordaland',
        country_id: '164',
      },
      {
        id: '2697',
        label: 'Moere og Romsdal',
        country_id: '164',
      },
      {
        id: '2698',
        label: 'Nord Trondelag',
        country_id: '164',
      },
      {
        id: '2699',
        label: 'Nordland',
        country_id: '164',
      },
      {
        id: '2700',
        label: 'Oestfold',
        country_id: '164',
      },
      {
        id: '2701',
        label: 'Oppland',
        country_id: '164',
      },
      {
        id: '2702',
        label: 'Oslo',
        country_id: '164',
      },
      {
        id: '2703',
        label: 'Rogaland',
        country_id: '164',
      },
      {
        id: '2704',
        label: 'Soer Troendelag',
        country_id: '164',
      },
      {
        id: '2705',
        label: 'Sogn og Fjordane',
        country_id: '164',
      },
      {
        id: '2706',
        label: 'Stavern',
        country_id: '164',
      },
      {
        id: '2707',
        label: 'Sykkylven',
        country_id: '164',
      },
      {
        id: '2708',
        label: 'Telemark',
        country_id: '164',
      },
      {
        id: '2709',
        label: 'Troms',
        country_id: '164',
      },
      {
        id: '2710',
        label: 'Vest Agder',
        country_id: '164',
      },
      {
        id: '2711',
        label: 'Vestfold',
        country_id: '164',
      },
      {
        id: '2712',
        label: 'ÃƒÂ˜stfold',
        country_id: '164',
      },
    ],
  },
  {
    id: '165',
    sortname: 'OM',
    label: 'Oman',
    states: [
      {
        id: '2713',
        label: 'Al Buraimi',
        country_id: '165',
      },
      {
        id: '2714',
        label: 'Dhufar',
        country_id: '165',
      },
      {
        id: '2715',
        label: 'Masqat',
        country_id: '165',
      },
      {
        id: '2716',
        label: 'Musandam',
        country_id: '165',
      },
      {
        id: '2717',
        label: 'Rusayl',
        country_id: '165',
      },
      {
        id: '2718',
        label: 'Wadi Kabir',
        country_id: '165',
      },
      {
        id: '2719',
        label: 'ad-Dakhiliyah',
        country_id: '165',
      },
      {
        id: '2720',
        label: 'adh-Dhahirah',
        country_id: '165',
      },
      {
        id: '2721',
        label: 'al-Batinah',
        country_id: '165',
      },
      {
        id: '2722',
        label: 'ash-Sharqiyah',
        country_id: '165',
      },
    ],
  },
  {
    id: '166',
    sortname: 'PK',
    label: 'Pakistan',
    states: [
      {
        id: '2723',
        label: 'Baluchistan',
        country_id: '166',
      },
      {
        id: '2724',
        label: 'Federal Capital Area',
        country_id: '166',
      },
      {
        id: '2725',
        label: 'Federally administered Tribal ',
        country_id: '166',
      },
      {
        id: '2726',
        label: 'North-West Frontier',
        country_id: '166',
      },
      {
        id: '2727',
        label: 'Northern Areas',
        country_id: '166',
      },
      {
        id: '2728',
        label: 'Punjab',
        country_id: '166',
      },
      {
        id: '2729',
        label: 'Sind',
        country_id: '166',
      },
    ],
  },
  {
    id: '167',
    sortname: 'PW',
    label: 'Palau',
    states: [
      {
        id: '2730',
        label: 'Aimeliik',
        country_id: '167',
      },
      {
        id: '2731',
        label: 'Airai',
        country_id: '167',
      },
      {
        id: '2732',
        label: 'Angaur',
        country_id: '167',
      },
      {
        id: '2733',
        label: 'Hatobohei',
        country_id: '167',
      },
      {
        id: '2734',
        label: 'Kayangel',
        country_id: '167',
      },
      {
        id: '2735',
        label: 'Koror',
        country_id: '167',
      },
      {
        id: '2736',
        label: 'Melekeok',
        country_id: '167',
      },
      {
        id: '2737',
        label: 'Ngaraard',
        country_id: '167',
      },
      {
        id: '2738',
        label: 'Ngardmau',
        country_id: '167',
      },
      {
        id: '2739',
        label: 'Ngaremlengui',
        country_id: '167',
      },
      {
        id: '2740',
        label: 'Ngatpang',
        country_id: '167',
      },
      {
        id: '2741',
        label: 'Ngchesar',
        country_id: '167',
      },
      {
        id: '2742',
        label: 'Ngerchelong',
        country_id: '167',
      },
      {
        id: '2743',
        label: 'Ngiwal',
        country_id: '167',
      },
      {
        id: '2744',
        label: 'Peleliu',
        country_id: '167',
      },
      {
        id: '2745',
        label: 'Sonsorol',
        country_id: '167',
      },
    ],
  },
  {
    id: '168',
    sortname: 'PS',
    label: 'Palestinian Territory Occupied',
    states: [
      {
        id: '2746',
        label: 'Ariha',
        country_id: '168',
      },
      {
        id: '2747',
        label: 'Bayt Lahm',
        country_id: '168',
      },
      {
        id: '2748',
        label: 'Bethlehem',
        country_id: '168',
      },
      {
        id: '2749',
        label: 'Dayr-al-Balah',
        country_id: '168',
      },
      {
        id: '2750',
        label: 'Ghazzah',
        country_id: '168',
      },
      {
        id: '2751',
        label: 'Ghazzah ash-Shamaliyah',
        country_id: '168',
      },
      {
        id: '2752',
        label: 'Janin',
        country_id: '168',
      },
      {
        id: '2753',
        label: 'Khan Yunis',
        country_id: '168',
      },
      {
        id: '2754',
        label: 'Nabulus',
        country_id: '168',
      },
      {
        id: '2755',
        label: 'Qalqilyah',
        country_id: '168',
      },
      {
        id: '2756',
        label: 'Rafah',
        country_id: '168',
      },
      {
        id: '2757',
        label: 'Ram Allah wal-Birah',
        country_id: '168',
      },
      {
        id: '2758',
        label: 'Salfit',
        country_id: '168',
      },
      {
        id: '2759',
        label: 'Tubas',
        country_id: '168',
      },
      {
        id: '2760',
        label: 'Tulkarm',
        country_id: '168',
      },
      {
        id: '2761',
        label: 'al-Khalil',
        country_id: '168',
      },
      {
        id: '2762',
        label: 'al-Quds',
        country_id: '168',
      },
    ],
  },
  {
    id: '169',
    sortname: 'PA',
    label: 'Panama',
    states: [
      {
        id: '2763',
        label: 'Bocas del Toro',
        country_id: '169',
      },
      {
        id: '2764',
        label: 'Chiriqui',
        country_id: '169',
      },
      {
        id: '2765',
        label: 'Cocle',
        country_id: '169',
      },
      {
        id: '2766',
        label: 'Colon',
        country_id: '169',
      },
      {
        id: '2767',
        label: 'Darien',
        country_id: '169',
      },
      {
        id: '2768',
        label: 'Embera',
        country_id: '169',
      },
      {
        id: '2769',
        label: 'Herrera',
        country_id: '169',
      },
      {
        id: '2770',
        label: 'Kuna Yala',
        country_id: '169',
      },
      {
        id: '2771',
        label: 'Los Santos',
        country_id: '169',
      },
      {
        id: '2772',
        label: 'Ngobe Bugle',
        country_id: '169',
      },
      {
        id: '2773',
        label: 'Panama',
        country_id: '169',
      },
      {
        id: '2774',
        label: 'Veraguas',
        country_id: '169',
      },
    ],
  },
  {
    id: '170',
    sortname: 'PG',
    label: 'Papua new Guinea',
    states: [
      {
        id: '2775',
        label: 'East New Britain',
        country_id: '170',
      },
      {
        id: '2776',
        label: 'East Sepik',
        country_id: '170',
      },
      {
        id: '2777',
        label: 'Eastern Highlands',
        country_id: '170',
      },
      {
        id: '2778',
        label: 'Enga',
        country_id: '170',
      },
      {
        id: '2779',
        label: 'Fly River',
        country_id: '170',
      },
      {
        id: '2780',
        label: 'Gulf',
        country_id: '170',
      },
      {
        id: '2781',
        label: 'Madang',
        country_id: '170',
      },
      {
        id: '2782',
        label: 'Manus',
        country_id: '170',
      },
      {
        id: '2783',
        label: 'Milne Bay',
        country_id: '170',
      },
      {
        id: '2784',
        label: 'Morobe',
        country_id: '170',
      },
      {
        id: '2785',
        label: 'National Capital District',
        country_id: '170',
      },
      {
        id: '2786',
        label: 'New Ireland',
        country_id: '170',
      },
      {
        id: '2787',
        label: 'North Solomons',
        country_id: '170',
      },
      {
        id: '2788',
        label: 'Oro',
        country_id: '170',
      },
      {
        id: '2789',
        label: 'Sandaun',
        country_id: '170',
      },
      {
        id: '2790',
        label: 'Simbu',
        country_id: '170',
      },
      {
        id: '2791',
        label: 'Southern Highlands',
        country_id: '170',
      },
      {
        id: '2792',
        label: 'West New Britain',
        country_id: '170',
      },
      {
        id: '2793',
        label: 'Western Highlands',
        country_id: '170',
      },
    ],
  },
  {
    id: '171',
    sortname: 'PY',
    label: 'Paraguay',
    states: [
      {
        id: '2794',
        label: 'Alto Paraguay',
        country_id: '171',
      },
      {
        id: '2795',
        label: 'Alto Parana',
        country_id: '171',
      },
      {
        id: '2796',
        label: 'Amambay',
        country_id: '171',
      },
      {
        id: '2797',
        label: 'Asuncion',
        country_id: '171',
      },
      {
        id: '2798',
        label: 'Boqueron',
        country_id: '171',
      },
      {
        id: '2799',
        label: 'Caaguazu',
        country_id: '171',
      },
      {
        id: '2800',
        label: 'Caazapa',
        country_id: '171',
      },
      {
        id: '2801',
        label: 'Canendiyu',
        country_id: '171',
      },
      {
        id: '2802',
        label: 'Central',
        country_id: '171',
      },
      {
        id: '2803',
        label: 'Concepcion',
        country_id: '171',
      },
      {
        id: '2804',
        label: 'Cordillera',
        country_id: '171',
      },
      {
        id: '2805',
        label: 'Guaira',
        country_id: '171',
      },
      {
        id: '2806',
        label: 'Itapua',
        country_id: '171',
      },
      {
        id: '2807',
        label: 'Misiones',
        country_id: '171',
      },
      {
        id: '2808',
        label: 'Neembucu',
        country_id: '171',
      },
      {
        id: '2809',
        label: 'Paraguari',
        country_id: '171',
      },
      {
        id: '2810',
        label: 'Presidente Hayes',
        country_id: '171',
      },
      {
        id: '2811',
        label: 'San Pedro',
        country_id: '171',
      },
    ],
  },
  {
    id: '172',
    sortname: 'PE',
    label: 'Peru',
    states: [
      {
        id: '2812',
        label: 'Amazonas',
        country_id: '172',
      },
      {
        id: '2813',
        label: 'Ancash',
        country_id: '172',
      },
      {
        id: '2814',
        label: 'Apurimac',
        country_id: '172',
      },
      {
        id: '2815',
        label: 'Arequipa',
        country_id: '172',
      },
      {
        id: '2816',
        label: 'Ayacucho',
        country_id: '172',
      },
      {
        id: '2817',
        label: 'Cajamarca',
        country_id: '172',
      },
      {
        id: '2818',
        label: 'Cusco',
        country_id: '172',
      },
      {
        id: '2819',
        label: 'Huancavelica',
        country_id: '172',
      },
      {
        id: '2820',
        label: 'Huanuco',
        country_id: '172',
      },
      {
        id: '2821',
        label: 'Ica',
        country_id: '172',
      },
      {
        id: '2822',
        label: 'Junin',
        country_id: '172',
      },
      {
        id: '2823',
        label: 'La Libertad',
        country_id: '172',
      },
      {
        id: '2824',
        label: 'Lambayeque',
        country_id: '172',
      },
      {
        id: '2825',
        label: 'Lima y Callao',
        country_id: '172',
      },
      {
        id: '2826',
        label: 'Loreto',
        country_id: '172',
      },
      {
        id: '2827',
        label: 'Madre de Dios',
        country_id: '172',
      },
      {
        id: '2828',
        label: 'Moquegua',
        country_id: '172',
      },
      {
        id: '2829',
        label: 'Pasco',
        country_id: '172',
      },
      {
        id: '2830',
        label: 'Piura',
        country_id: '172',
      },
      {
        id: '2831',
        label: 'Puno',
        country_id: '172',
      },
      {
        id: '2832',
        label: 'San Martin',
        country_id: '172',
      },
      {
        id: '2833',
        label: 'Tacna',
        country_id: '172',
      },
      {
        id: '2834',
        label: 'Tumbes',
        country_id: '172',
      },
      {
        id: '2835',
        label: 'Ucayali',
        country_id: '172',
      },
    ],
  },
  {
    id: '173',
    sortname: 'PH',
    label: 'Philippines',
    states: [
      {
        id: '2836',
        label: 'Batangas',
        country_id: '173',
      },
      {
        id: '2837',
        label: 'Bicol',
        country_id: '173',
      },
      {
        id: '2838',
        label: 'Bulacan',
        country_id: '173',
      },
      {
        id: '2839',
        label: 'Cagayan',
        country_id: '173',
      },
      {
        id: '2840',
        label: 'Caraga',
        country_id: '173',
      },
      {
        id: '2841',
        label: 'Central Luzon',
        country_id: '173',
      },
      {
        id: '2842',
        label: 'Central Mindanao',
        country_id: '173',
      },
      {
        id: '2843',
        label: 'Central Visayas',
        country_id: '173',
      },
      {
        id: '2844',
        label: 'Cordillera',
        country_id: '173',
      },
      {
        id: '2845',
        label: 'Davao',
        country_id: '173',
      },
      {
        id: '2846',
        label: 'Eastern Visayas',
        country_id: '173',
      },
      {
        id: '2847',
        label: 'Greater Metropolitan Area',
        country_id: '173',
      },
      {
        id: '2848',
        label: 'Ilocos',
        country_id: '173',
      },
      {
        id: '2849',
        label: 'Laguna',
        country_id: '173',
      },
      {
        id: '2850',
        label: 'Luzon',
        country_id: '173',
      },
      {
        id: '2851',
        label: 'Mactan',
        country_id: '173',
      },
      {
        id: '2852',
        label: 'Metropolitan Manila Area',
        country_id: '173',
      },
      {
        id: '2853',
        label: 'Muslim Mindanao',
        country_id: '173',
      },
      {
        id: '2854',
        label: 'Northern Mindanao',
        country_id: '173',
      },
      {
        id: '2855',
        label: 'Southern Mindanao',
        country_id: '173',
      },
      {
        id: '2856',
        label: 'Southern Tagalog',
        country_id: '173',
      },
      {
        id: '2857',
        label: 'Western Mindanao',
        country_id: '173',
      },
      {
        id: '2858',
        label: 'Western Visayas',
        country_id: '173',
      },
    ],
  },
  {
    id: '174',
    sortname: 'PN',
    label: 'Pitcairn Island',
    states: [
      {
        id: '2859',
        label: 'Pitcairn Island',
        country_id: '174',
      },
    ],
  },
  {
    id: '175',
    sortname: 'PL',
    label: 'Poland',
    states: [
      {
        id: '2860',
        label: 'Biale Blota',
        country_id: '175',
      },
      {
        id: '2861',
        label: 'Dobroszyce',
        country_id: '175',
      },
      {
        id: '2862',
        label: 'Dolnoslaskie',
        country_id: '175',
      },
      {
        id: '2863',
        label: 'Dziekanow Lesny',
        country_id: '175',
      },
      {
        id: '2864',
        label: 'Hopowo',
        country_id: '175',
      },
      {
        id: '2865',
        label: 'Kartuzy',
        country_id: '175',
      },
      {
        id: '2866',
        label: 'Koscian',
        country_id: '175',
      },
      {
        id: '2867',
        label: 'Krakow',
        country_id: '175',
      },
      {
        id: '2868',
        label: 'Kujawsko-Pomorskie',
        country_id: '175',
      },
      {
        id: '2869',
        label: 'Lodzkie',
        country_id: '175',
      },
      {
        id: '2870',
        label: 'Lubelskie',
        country_id: '175',
      },
      {
        id: '2871',
        label: 'Lubuskie',
        country_id: '175',
      },
      {
        id: '2872',
        label: 'Malomice',
        country_id: '175',
      },
      {
        id: '2873',
        label: 'Malopolskie',
        country_id: '175',
      },
      {
        id: '2874',
        label: 'Mazowieckie',
        country_id: '175',
      },
      {
        id: '2875',
        label: 'Mirkow',
        country_id: '175',
      },
      {
        id: '2876',
        label: 'Opolskie',
        country_id: '175',
      },
      {
        id: '2877',
        label: 'Ostrowiec',
        country_id: '175',
      },
      {
        id: '2878',
        label: 'Podkarpackie',
        country_id: '175',
      },
      {
        id: '2879',
        label: 'Podlaskie',
        country_id: '175',
      },
      {
        id: '2880',
        label: 'Polska',
        country_id: '175',
      },
      {
        id: '2881',
        label: 'Pomorskie',
        country_id: '175',
      },
      {
        id: '2882',
        label: 'Poznan',
        country_id: '175',
      },
      {
        id: '2883',
        label: 'Pruszkow',
        country_id: '175',
      },
      {
        id: '2884',
        label: 'Rymanowska',
        country_id: '175',
      },
      {
        id: '2885',
        label: 'Rzeszow',
        country_id: '175',
      },
      {
        id: '2886',
        label: 'Slaskie',
        country_id: '175',
      },
      {
        id: '2887',
        label: 'Stare Pole',
        country_id: '175',
      },
      {
        id: '2888',
        label: 'Swietokrzyskie',
        country_id: '175',
      },
      {
        id: '2889',
        label: 'Warminsko-Mazurskie',
        country_id: '175',
      },
      {
        id: '2890',
        label: 'Warsaw',
        country_id: '175',
      },
      {
        id: '2891',
        label: 'Wejherowo',
        country_id: '175',
      },
      {
        id: '2892',
        label: 'Wielkopolskie',
        country_id: '175',
      },
      {
        id: '2893',
        label: 'Wroclaw',
        country_id: '175',
      },
      {
        id: '2894',
        label: 'Zachodnio-Pomorskie',
        country_id: '175',
      },
      {
        id: '2895',
        label: 'Zukowo',
        country_id: '175',
      },
    ],
  },
  {
    id: '176',
    sortname: 'PT',
    label: 'Portugal',
    states: [
      {
        id: '2896',
        label: 'Abrantes',
        country_id: '176',
      },
      {
        id: '2897',
        label: 'Acores',
        country_id: '176',
      },
      {
        id: '2898',
        label: 'Alentejo',
        country_id: '176',
      },
      {
        id: '2899',
        label: 'Algarve',
        country_id: '176',
      },
      {
        id: '2900',
        label: 'Braga',
        country_id: '176',
      },
      {
        id: '2901',
        label: 'Centro',
        country_id: '176',
      },
      {
        id: '2902',
        label: 'Distrito de Leiria',
        country_id: '176',
      },
      {
        id: '2903',
        label: 'Distrito de Viana do Castelo',
        country_id: '176',
      },
      {
        id: '2904',
        label: 'Distrito de Vila Real',
        country_id: '176',
      },
      {
        id: '2905',
        label: 'Distrito do Porto',
        country_id: '176',
      },
      {
        id: '2906',
        label: 'Lisboa e Vale do Tejo',
        country_id: '176',
      },
      {
        id: '2907',
        label: 'Madeira',
        country_id: '176',
      },
      {
        id: '2908',
        label: 'Norte',
        country_id: '176',
      },
      {
        id: '2909',
        label: 'Paivas',
        country_id: '176',
      },
    ],
  },
  {
    id: '177',
    sortname: 'PR',
    label: 'Puerto Rico',
    states: [
      {
        id: '2910',
        label: 'Arecibo',
        country_id: '177',
      },
      {
        id: '2911',
        label: 'Bayamon',
        country_id: '177',
      },
      {
        id: '2912',
        label: 'Carolina',
        country_id: '177',
      },
      {
        id: '2913',
        label: 'Florida',
        country_id: '177',
      },
      {
        id: '2914',
        label: 'Guayama',
        country_id: '177',
      },
      {
        id: '2915',
        label: 'Humacao',
        country_id: '177',
      },
      {
        id: '2916',
        label: 'Mayaguez-Aguadilla',
        country_id: '177',
      },
      {
        id: '2917',
        label: 'Ponce',
        country_id: '177',
      },
      {
        id: '2918',
        label: 'Salinas',
        country_id: '177',
      },
      {
        id: '2919',
        label: 'San Juan',
        country_id: '177',
      },
    ],
  },
  {
    id: '178',
    sortname: 'QA',
    label: 'Qatar',
    states: [
      {
        id: '2920',
        label: 'Doha',
        country_id: '178',
      },
      {
        id: '2921',
        label: 'Jarian-al-Batnah',
        country_id: '178',
      },
      {
        id: '2922',
        label: 'Umm Salal',
        country_id: '178',
      },
      {
        id: '2923',
        label: 'ad-Dawhah',
        country_id: '178',
      },
      {
        id: '2924',
        label: 'al-Ghuwayriyah',
        country_id: '178',
      },
      {
        id: '2925',
        label: 'al-Jumayliyah',
        country_id: '178',
      },
      {
        id: '2926',
        label: 'al-Khawr',
        country_id: '178',
      },
      {
        id: '2927',
        label: 'al-Wakrah',
        country_id: '178',
      },
      {
        id: '2928',
        label: 'ar-Rayyan',
        country_id: '178',
      },
      {
        id: '2929',
        label: 'ash-Shamal',
        country_id: '178',
      },
    ],
  },
  {
    id: '179',
    sortname: 'RE',
    label: 'Reunion',
    states: [
      {
        id: '2930',
        label: 'Saint-Benoit',
        country_id: '179',
      },
      {
        id: '2931',
        label: 'Saint-Denis',
        country_id: '179',
      },
      {
        id: '2932',
        label: 'Saint-Paul',
        country_id: '179',
      },
      {
        id: '2933',
        label: 'Saint-Pierre',
        country_id: '179',
      },
    ],
  },
  {
    id: '180',
    sortname: 'RO',
    label: 'Romania',
    states: [
      {
        id: '2934',
        label: 'Alba',
        country_id: '180',
      },
      {
        id: '2935',
        label: 'Arad',
        country_id: '180',
      },
      {
        id: '2936',
        label: 'Arges',
        country_id: '180',
      },
      {
        id: '2937',
        label: 'Bacau',
        country_id: '180',
      },
      {
        id: '2938',
        label: 'Bihor',
        country_id: '180',
      },
      {
        id: '2939',
        label: 'Bistrita-Nasaud',
        country_id: '180',
      },
      {
        id: '2940',
        label: 'Botosani',
        country_id: '180',
      },
      {
        id: '2941',
        label: 'Braila',
        country_id: '180',
      },
      {
        id: '2942',
        label: 'Brasov',
        country_id: '180',
      },
      {
        id: '2943',
        label: 'Bucuresti',
        country_id: '180',
      },
      {
        id: '2944',
        label: 'Buzau',
        country_id: '180',
      },
      {
        id: '2945',
        label: 'Calarasi',
        country_id: '180',
      },
      {
        id: '2946',
        label: 'Caras-Severin',
        country_id: '180',
      },
      {
        id: '2947',
        label: 'Cluj',
        country_id: '180',
      },
      {
        id: '2948',
        label: 'Constanta',
        country_id: '180',
      },
      {
        id: '2949',
        label: 'Covasna',
        country_id: '180',
      },
      {
        id: '2950',
        label: 'Dambovita',
        country_id: '180',
      },
      {
        id: '2951',
        label: 'Dolj',
        country_id: '180',
      },
      {
        id: '2952',
        label: 'Galati',
        country_id: '180',
      },
      {
        id: '2953',
        label: 'Giurgiu',
        country_id: '180',
      },
      {
        id: '2954',
        label: 'Gorj',
        country_id: '180',
      },
      {
        id: '2955',
        label: 'Harghita',
        country_id: '180',
      },
      {
        id: '2956',
        label: 'Hunedoara',
        country_id: '180',
      },
      {
        id: '2957',
        label: 'Ialomita',
        country_id: '180',
      },
      {
        id: '2958',
        label: 'Iasi',
        country_id: '180',
      },
      {
        id: '2959',
        label: 'Ilfov',
        country_id: '180',
      },
      {
        id: '2960',
        label: 'Maramures',
        country_id: '180',
      },
      {
        id: '2961',
        label: 'Mehedinti',
        country_id: '180',
      },
      {
        id: '2962',
        label: 'Mures',
        country_id: '180',
      },
      {
        id: '2963',
        label: 'Neamt',
        country_id: '180',
      },
      {
        id: '2964',
        label: 'Olt',
        country_id: '180',
      },
      {
        id: '2965',
        label: 'Prahova',
        country_id: '180',
      },
      {
        id: '2966',
        label: 'Salaj',
        country_id: '180',
      },
      {
        id: '2967',
        label: 'Satu Mare',
        country_id: '180',
      },
      {
        id: '2968',
        label: 'Sibiu',
        country_id: '180',
      },
      {
        id: '2969',
        label: 'Sondelor',
        country_id: '180',
      },
      {
        id: '2970',
        label: 'Suceava',
        country_id: '180',
      },
      {
        id: '2971',
        label: 'Teleorman',
        country_id: '180',
      },
      {
        id: '2972',
        label: 'Timis',
        country_id: '180',
      },
      {
        id: '2973',
        label: 'Tulcea',
        country_id: '180',
      },
      {
        id: '2974',
        label: 'Valcea',
        country_id: '180',
      },
      {
        id: '2975',
        label: 'Vaslui',
        country_id: '180',
      },
      {
        id: '2976',
        label: 'Vrancea',
        country_id: '180',
      },
    ],
  },
  {
    id: '181',
    sortname: 'RU',
    label: 'Russia',
    states: [
      {
        id: '2977',
        label: 'Adygeja',
        country_id: '181',
      },
      {
        id: '2978',
        label: 'Aga',
        country_id: '181',
      },
      {
        id: '2979',
        label: 'Alanija',
        country_id: '181',
      },
      {
        id: '2980',
        label: 'Altaj',
        country_id: '181',
      },
      {
        id: '2981',
        label: 'Amur',
        country_id: '181',
      },
      {
        id: '2982',
        label: 'Arhangelsk',
        country_id: '181',
      },
      {
        id: '2983',
        label: 'Astrahan',
        country_id: '181',
      },
      {
        id: '2984',
        label: 'Bashkortostan',
        country_id: '181',
      },
      {
        id: '2985',
        label: 'Belgorod',
        country_id: '181',
      },
      {
        id: '2986',
        label: 'Brjansk',
        country_id: '181',
      },
      {
        id: '2987',
        label: 'Burjatija',
        country_id: '181',
      },
      {
        id: '2988',
        label: 'Chechenija',
        country_id: '181',
      },
      {
        id: '2989',
        label: 'Cheljabinsk',
        country_id: '181',
      },
      {
        id: '2990',
        label: 'Chita',
        country_id: '181',
      },
      {
        id: '2991',
        label: 'Chukotka',
        country_id: '181',
      },
      {
        id: '2992',
        label: 'Chuvashija',
        country_id: '181',
      },
      {
        id: '2993',
        label: 'Dagestan',
        country_id: '181',
      },
      {
        id: '2994',
        label: 'Evenkija',
        country_id: '181',
      },
      {
        id: '2995',
        label: 'Gorno-Altaj',
        country_id: '181',
      },
      {
        id: '2996',
        label: 'Habarovsk',
        country_id: '181',
      },
      {
        id: '2997',
        label: 'Hakasija',
        country_id: '181',
      },
      {
        id: '2998',
        label: 'Hanty-Mansija',
        country_id: '181',
      },
      {
        id: '2999',
        label: 'Ingusetija',
        country_id: '181',
      },
      {
        id: '3000',
        label: 'Irkutsk',
        country_id: '181',
      },
      {
        id: '3001',
        label: 'Ivanovo',
        country_id: '181',
      },
      {
        id: '3002',
        label: 'Jamalo-Nenets',
        country_id: '181',
      },
      {
        id: '3003',
        label: 'Jaroslavl',
        country_id: '181',
      },
      {
        id: '3004',
        label: 'Jevrej',
        country_id: '181',
      },
      {
        id: '3005',
        label: 'Kabardino-Balkarija',
        country_id: '181',
      },
      {
        id: '3006',
        label: 'Kaliningrad',
        country_id: '181',
      },
      {
        id: '3007',
        label: 'Kalmykija',
        country_id: '181',
      },
      {
        id: '3008',
        label: 'Kaluga',
        country_id: '181',
      },
      {
        id: '3009',
        label: 'Kamchatka',
        country_id: '181',
      },
      {
        id: '3010',
        label: 'Karachaj-Cherkessija',
        country_id: '181',
      },
      {
        id: '3011',
        label: 'Karelija',
        country_id: '181',
      },
      {
        id: '3012',
        label: 'Kemerovo',
        country_id: '181',
      },
      {
        id: '3013',
        label: 'Khabarovskiy Kray',
        country_id: '181',
      },
      {
        id: '3014',
        label: 'Kirov',
        country_id: '181',
      },
      {
        id: '3015',
        label: 'Komi',
        country_id: '181',
      },
      {
        id: '3016',
        label: 'Komi-Permjakija',
        country_id: '181',
      },
      {
        id: '3017',
        label: 'Korjakija',
        country_id: '181',
      },
      {
        id: '3018',
        label: 'Kostroma',
        country_id: '181',
      },
      {
        id: '3019',
        label: 'Krasnodar',
        country_id: '181',
      },
      {
        id: '3020',
        label: 'Krasnojarsk',
        country_id: '181',
      },
      {
        id: '3021',
        label: 'Krasnoyarskiy Kray',
        country_id: '181',
      },
      {
        id: '3022',
        label: 'Kurgan',
        country_id: '181',
      },
      {
        id: '3023',
        label: 'Kursk',
        country_id: '181',
      },
      {
        id: '3024',
        label: 'Leningrad',
        country_id: '181',
      },
      {
        id: '3025',
        label: 'Lipeck',
        country_id: '181',
      },
      {
        id: '3026',
        label: 'Magadan',
        country_id: '181',
      },
      {
        id: '3027',
        label: 'Marij El',
        country_id: '181',
      },
      {
        id: '3028',
        label: 'Mordovija',
        country_id: '181',
      },
      {
        id: '3029',
        label: 'Moscow',
        country_id: '181',
      },
      {
        id: '3030',
        label: 'Moskovskaja Oblast',
        country_id: '181',
      },
      {
        id: '3031',
        label: 'Moskovskaya Oblast',
        country_id: '181',
      },
      {
        id: '3032',
        label: 'Moskva',
        country_id: '181',
      },
      {
        id: '3033',
        label: 'Murmansk',
        country_id: '181',
      },
      {
        id: '3034',
        label: 'Nenets',
        country_id: '181',
      },
      {
        id: '3035',
        label: 'Nizhnij Novgorod',
        country_id: '181',
      },
      {
        id: '3036',
        label: 'Novgorod',
        country_id: '181',
      },
      {
        id: '3037',
        label: 'Novokusnezk',
        country_id: '181',
      },
      {
        id: '3038',
        label: 'Novosibirsk',
        country_id: '181',
      },
      {
        id: '3039',
        label: 'Omsk',
        country_id: '181',
      },
      {
        id: '3040',
        label: 'Orenburg',
        country_id: '181',
      },
      {
        id: '3041',
        label: 'Orjol',
        country_id: '181',
      },
      {
        id: '3042',
        label: 'Penza',
        country_id: '181',
      },
      {
        id: '3043',
        label: 'Perm',
        country_id: '181',
      },
      {
        id: '3044',
        label: 'Primorje',
        country_id: '181',
      },
      {
        id: '3045',
        label: 'Pskov',
        country_id: '181',
      },
      {
        id: '3046',
        label: 'Pskovskaya Oblast',
        country_id: '181',
      },
      {
        id: '3047',
        label: 'Rjazan',
        country_id: '181',
      },
      {
        id: '3048',
        label: 'Rostov',
        country_id: '181',
      },
      {
        id: '3049',
        label: 'Saha',
        country_id: '181',
      },
      {
        id: '3050',
        label: 'Sahalin',
        country_id: '181',
      },
      {
        id: '3051',
        label: 'Samara',
        country_id: '181',
      },
      {
        id: '3052',
        label: 'Samarskaya',
        country_id: '181',
      },
      {
        id: '3053',
        label: 'Sankt-Peterburg',
        country_id: '181',
      },
      {
        id: '3054',
        label: 'Saratov',
        country_id: '181',
      },
      {
        id: '3055',
        label: 'Smolensk',
        country_id: '181',
      },
      {
        id: '3056',
        label: 'Stavropol',
        country_id: '181',
      },
      {
        id: '3057',
        label: 'Sverdlovsk',
        country_id: '181',
      },
      {
        id: '3058',
        label: 'Tajmyrija',
        country_id: '181',
      },
      {
        id: '3059',
        label: 'Tambov',
        country_id: '181',
      },
      {
        id: '3060',
        label: 'Tatarstan',
        country_id: '181',
      },
      {
        id: '3061',
        label: 'Tjumen',
        country_id: '181',
      },
      {
        id: '3062',
        label: 'Tomsk',
        country_id: '181',
      },
      {
        id: '3063',
        label: 'Tula',
        country_id: '181',
      },
      {
        id: '3064',
        label: 'Tver',
        country_id: '181',
      },
      {
        id: '3065',
        label: 'Tyva',
        country_id: '181',
      },
      {
        id: '3066',
        label: 'Udmurtija',
        country_id: '181',
      },
      {
        id: '3067',
        label: 'Uljanovsk',
        country_id: '181',
      },
      {
        id: '3068',
        label: 'Ulyanovskaya Oblast',
        country_id: '181',
      },
      {
        id: '3069',
        label: 'Ust-Orda',
        country_id: '181',
      },
      {
        id: '3070',
        label: 'Vladimir',
        country_id: '181',
      },
      {
        id: '3071',
        label: 'Volgograd',
        country_id: '181',
      },
      {
        id: '3072',
        label: 'Vologda',
        country_id: '181',
      },
      {
        id: '3073',
        label: 'Voronezh',
        country_id: '181',
      },
    ],
  },
  {
    id: '182',
    sortname: 'RW',
    label: 'Rwanda',
    states: [
      {
        id: '3074',
        label: 'Butare',
        country_id: '182',
      },
      {
        id: '3075',
        label: 'Byumba',
        country_id: '182',
      },
      {
        id: '3076',
        label: 'Cyangugu',
        country_id: '182',
      },
      {
        id: '3077',
        label: 'Gikongoro',
        country_id: '182',
      },
      {
        id: '3078',
        label: 'Gisenyi',
        country_id: '182',
      },
      {
        id: '3079',
        label: 'Gitarama',
        country_id: '182',
      },
      {
        id: '3080',
        label: 'Kibungo',
        country_id: '182',
      },
      {
        id: '3081',
        label: 'Kibuye',
        country_id: '182',
      },
      {
        id: '3082',
        label: 'Kigali-ngali',
        country_id: '182',
      },
      {
        id: '3083',
        label: 'Ruhengeri',
        country_id: '182',
      },
    ],
  },
  {
    id: '183',
    sortname: 'SH',
    label: 'Saint Helena',
    states: [
      {
        id: '3084',
        label: 'Ascension',
        country_id: '183',
      },
      {
        id: '3085',
        label: 'Gough Island',
        country_id: '183',
      },
      {
        id: '3086',
        label: 'Saint Helena',
        country_id: '183',
      },
      {
        id: '3087',
        label: 'Tristan da Cunha',
        country_id: '183',
      },
    ],
  },
  {
    id: '184',
    sortname: 'KN',
    label: 'Saint Kitts And Nevis',
    states: [
      {
        id: '3088',
        label: 'Christ Church Nichola Town',
        country_id: '184',
      },
      {
        id: '3089',
        label: 'Saint Anne Sandy Point',
        country_id: '184',
      },
      {
        id: '3090',
        label: 'Saint George Basseterre',
        country_id: '184',
      },
      {
        id: '3091',
        label: 'Saint George Gingerland',
        country_id: '184',
      },
      {
        id: '3092',
        label: 'Saint James Windward',
        country_id: '184',
      },
      {
        id: '3093',
        label: 'Saint John Capesterre',
        country_id: '184',
      },
      {
        id: '3094',
        label: 'Saint John Figtree',
        country_id: '184',
      },
      {
        id: '3095',
        label: 'Saint Mary Cayon',
        country_id: '184',
      },
      {
        id: '3096',
        label: 'Saint Paul Capesterre',
        country_id: '184',
      },
      {
        id: '3097',
        label: 'Saint Paul Charlestown',
        country_id: '184',
      },
      {
        id: '3098',
        label: 'Saint Peter Basseterre',
        country_id: '184',
      },
      {
        id: '3099',
        label: 'Saint Thomas Lowland',
        country_id: '184',
      },
      {
        id: '3100',
        label: 'Saint Thomas Middle Island',
        country_id: '184',
      },
      {
        id: '3101',
        label: 'Trinity Palmetto Point',
        country_id: '184',
      },
    ],
  },
  {
    id: '185',
    sortname: 'LC',
    label: 'Saint Lucia',
    states: [
      {
        id: '3102',
        label: 'Anse-la-Raye',
        country_id: '185',
      },
      {
        id: '3103',
        label: 'Canaries',
        country_id: '185',
      },
      {
        id: '3104',
        label: 'Castries',
        country_id: '185',
      },
      {
        id: '3105',
        label: 'Choiseul',
        country_id: '185',
      },
      {
        id: '3106',
        label: 'Dennery',
        country_id: '185',
      },
      {
        id: '3107',
        label: 'Gros Inlet',
        country_id: '185',
      },
      {
        id: '3108',
        label: 'Laborie',
        country_id: '185',
      },
      {
        id: '3109',
        label: 'Micoud',
        country_id: '185',
      },
      {
        id: '3110',
        label: 'Soufriere',
        country_id: '185',
      },
      {
        id: '3111',
        label: 'Vieux Fort',
        country_id: '185',
      },
    ],
  },
  {
    id: '186',
    sortname: 'PM',
    label: 'Saint Pierre and Miquelon',
    states: [
      {
        id: '3112',
        label: 'Miquelon-Langlade',
        country_id: '186',
      },
      {
        id: '3113',
        label: 'Saint-Pierre',
        country_id: '186',
      },
    ],
  },
  {
    id: '187',
    sortname: 'VC',
    label: 'Saint Vincent And The Grenadines',
    states: [
      {
        id: '3114',
        label: 'Charlotte',
        country_id: '187',
      },
      {
        id: '3115',
        label: 'Grenadines',
        country_id: '187',
      },
      {
        id: '3116',
        label: 'Saint Andrew',
        country_id: '187',
      },
      {
        id: '3117',
        label: 'Saint David',
        country_id: '187',
      },
      {
        id: '3118',
        label: 'Saint George',
        country_id: '187',
      },
      {
        id: '3119',
        label: 'Saint Patrick',
        country_id: '187',
      },
    ],
  },
  {
    id: '188',
    sortname: 'WS',
    label: 'Samoa',
    states: [
      {
        id: '3120',
        label: "A'ana",
        country_id: '188',
      },
      {
        id: '3121',
        label: 'Aiga-i-le-Tai',
        country_id: '188',
      },
      {
        id: '3122',
        label: 'Atua',
        country_id: '188',
      },
      {
        id: '3123',
        label: "Fa'asaleleaga",
        country_id: '188',
      },
      {
        id: '3124',
        label: "Gaga'emauga",
        country_id: '188',
      },
      {
        id: '3125',
        label: 'Gagaifomauga',
        country_id: '188',
      },
      {
        id: '3126',
        label: 'Palauli',
        country_id: '188',
      },
      {
        id: '3127',
        label: "Satupa'itea",
        country_id: '188',
      },
      {
        id: '3128',
        label: 'Tuamasaga',
        country_id: '188',
      },
      {
        id: '3129',
        label: "Va'a-o-Fonoti",
        country_id: '188',
      },
      {
        id: '3130',
        label: 'Vaisigano',
        country_id: '188',
      },
    ],
  },
  {
    id: '189',
    sortname: 'SM',
    label: 'San Marino',
    states: [
      {
        id: '3131',
        label: 'Acquaviva',
        country_id: '189',
      },
      {
        id: '3132',
        label: 'Borgo Maggiore',
        country_id: '189',
      },
      {
        id: '3133',
        label: 'Chiesanuova',
        country_id: '189',
      },
      {
        id: '3134',
        label: 'Domagnano',
        country_id: '189',
      },
      {
        id: '3135',
        label: 'Faetano',
        country_id: '189',
      },
      {
        id: '3136',
        label: 'Fiorentino',
        country_id: '189',
      },
      {
        id: '3137',
        label: 'Montegiardino',
        country_id: '189',
      },
      {
        id: '3138',
        label: 'San Marino',
        country_id: '189',
      },
      {
        id: '3139',
        label: 'Serravalle',
        country_id: '189',
      },
    ],
  },
  {
    id: '190',
    sortname: 'ST',
    label: 'Sao Tome and Principe',
    states: [
      {
        id: '3140',
        label: 'Agua Grande',
        country_id: '190',
      },
      {
        id: '3141',
        label: 'Cantagalo',
        country_id: '190',
      },
      {
        id: '3142',
        label: 'Lemba',
        country_id: '190',
      },
      {
        id: '3143',
        label: 'Lobata',
        country_id: '190',
      },
      {
        id: '3144',
        label: 'Me-Zochi',
        country_id: '190',
      },
      {
        id: '3145',
        label: 'Pague',
        country_id: '190',
      },
    ],
  },
  {
    id: '191',
    sortname: 'SA',
    label: 'Saudi Arabia',
    states: [
      {
        id: '3146',
        label: 'Al Khobar',
        country_id: '191',
      },
      {
        id: '3147',
        label: 'Aseer',
        country_id: '191',
      },
      {
        id: '3148',
        label: 'Ash Sharqiyah',
        country_id: '191',
      },
      {
        id: '3149',
        label: 'Asir',
        country_id: '191',
      },
      {
        id: '3150',
        label: 'Central Province',
        country_id: '191',
      },
      {
        id: '3151',
        label: 'Eastern Province',
        country_id: '191',
      },
      {
        id: '3152',
        label: "Ha'il",
        country_id: '191',
      },
      {
        id: '3153',
        label: 'Jawf',
        country_id: '191',
      },
      {
        id: '3154',
        label: 'Jizan',
        country_id: '191',
      },
      {
        id: '3155',
        label: 'Makkah',
        country_id: '191',
      },
      {
        id: '3156',
        label: 'Najran',
        country_id: '191',
      },
      {
        id: '3157',
        label: 'Qasim',
        country_id: '191',
      },
      {
        id: '3158',
        label: 'Tabuk',
        country_id: '191',
      },
      {
        id: '3159',
        label: 'Western Province',
        country_id: '191',
      },
      {
        id: '3160',
        label: 'al-Bahah',
        country_id: '191',
      },
      {
        id: '3161',
        label: 'al-Hudud-ash-Shamaliyah',
        country_id: '191',
      },
      {
        id: '3162',
        label: 'al-Madinah',
        country_id: '191',
      },
      {
        id: '3163',
        label: 'ar-Riyad',
        country_id: '191',
      },
    ],
  },
  {
    id: '192',
    sortname: 'SN',
    label: 'Senegal',
    states: [
      {
        id: '3164',
        label: 'Dakar',
        country_id: '192',
      },
      {
        id: '3165',
        label: 'Diourbel',
        country_id: '192',
      },
      {
        id: '3166',
        label: 'Fatick',
        country_id: '192',
      },
      {
        id: '3167',
        label: 'Kaolack',
        country_id: '192',
      },
      {
        id: '3168',
        label: 'Kolda',
        country_id: '192',
      },
      {
        id: '3169',
        label: 'Louga',
        country_id: '192',
      },
      {
        id: '3170',
        label: 'Saint-Louis',
        country_id: '192',
      },
      {
        id: '3171',
        label: 'Tambacounda',
        country_id: '192',
      },
      {
        id: '3172',
        label: 'Thies',
        country_id: '192',
      },
      {
        id: '3173',
        label: 'Ziguinchor',
        country_id: '192',
      },
    ],
  },
  {
    id: '193',
    sortname: 'RS',
    label: 'Serbia',
    states: [
      {
        id: '3174',
        label: 'Central Serbia',
        country_id: '193',
      },
      {
        id: '3175',
        label: 'Kosovo and Metohija',
        country_id: '193',
      },
      {
        id: '3176',
        label: 'Vojvodina',
        country_id: '193',
      },
    ],
  },
  {
    id: '194',
    sortname: 'SC',
    label: 'Seychelles',
    states: [
      {
        id: '3177',
        label: 'Anse Boileau',
        country_id: '194',
      },
      {
        id: '3178',
        label: 'Anse Royale',
        country_id: '194',
      },
      {
        id: '3179',
        label: 'Cascade',
        country_id: '194',
      },
      {
        id: '3180',
        label: 'Takamaka',
        country_id: '194',
      },
      {
        id: '3181',
        label: 'Victoria',
        country_id: '194',
      },
    ],
  },
  {
    id: '195',
    sortname: 'SL',
    label: 'Sierra Leone',
    states: [
      {
        id: '3182',
        label: 'Eastern',
        country_id: '195',
      },
      {
        id: '3183',
        label: 'Northern',
        country_id: '195',
      },
      {
        id: '3184',
        label: 'Southern',
        country_id: '195',
      },
      {
        id: '3185',
        label: 'Western',
        country_id: '195',
      },
    ],
  },
  {
    id: '196',
    sortname: 'SG',
    label: 'Singapore',
    states: [
      {
        id: '3186',
        label: 'Singapore',
        country_id: '196',
      },
    ],
  },
  {
    id: '197',
    sortname: 'SK',
    label: 'Slovakia',
    states: [
      {
        id: '3187',
        label: 'Banskobystricky',
        country_id: '197',
      },
      {
        id: '3188',
        label: 'Bratislavsky',
        country_id: '197',
      },
      {
        id: '3189',
        label: 'Kosicky',
        country_id: '197',
      },
      {
        id: '3190',
        label: 'Nitriansky',
        country_id: '197',
      },
      {
        id: '3191',
        label: 'Presovsky',
        country_id: '197',
      },
      {
        id: '3192',
        label: 'Trenciansky',
        country_id: '197',
      },
      {
        id: '3193',
        label: 'Trnavsky',
        country_id: '197',
      },
      {
        id: '3194',
        label: 'Zilinsky',
        country_id: '197',
      },
    ],
  },
  {
    id: '198',
    sortname: 'SI',
    label: 'Slovenia',
    states: [
      {
        id: '3195',
        label: 'Benedikt',
        country_id: '198',
      },
      {
        id: '3196',
        label: 'Gorenjska',
        country_id: '198',
      },
      {
        id: '3197',
        label: 'Gorishka',
        country_id: '198',
      },
      {
        id: '3198',
        label: 'Jugovzhodna Slovenija',
        country_id: '198',
      },
      {
        id: '3199',
        label: 'Koroshka',
        country_id: '198',
      },
      {
        id: '3200',
        label: 'Notranjsko-krashka',
        country_id: '198',
      },
      {
        id: '3201',
        label: 'Obalno-krashka',
        country_id: '198',
      },
      {
        id: '3202',
        label: 'Obcina Domzale',
        country_id: '198',
      },
      {
        id: '3203',
        label: 'Obcina Vitanje',
        country_id: '198',
      },
      {
        id: '3204',
        label: 'Osrednjeslovenska',
        country_id: '198',
      },
      {
        id: '3205',
        label: 'Podravska',
        country_id: '198',
      },
      {
        id: '3206',
        label: 'Pomurska',
        country_id: '198',
      },
      {
        id: '3207',
        label: 'Savinjska',
        country_id: '198',
      },
      {
        id: '3208',
        label: 'Slovenian Littoral',
        country_id: '198',
      },
      {
        id: '3209',
        label: 'Spodnjeposavska',
        country_id: '198',
      },
      {
        id: '3210',
        label: 'Zasavska',
        country_id: '198',
      },
    ],
  },
  {
    id: '199',
    sortname: 'XG',
    label: 'Smaller Territories of the UK',
    states: [
      {
        id: '3211',
        label: 'Pitcairn',
        country_id: '199',
      },
    ],
  },
  {
    id: '200',
    sortname: 'SB',
    label: 'Solomon Islands',
    states: [
      {
        id: '3212',
        label: 'Central',
        country_id: '200',
      },
      {
        id: '3213',
        label: 'Choiseul',
        country_id: '200',
      },
      {
        id: '3214',
        label: 'Guadalcanal',
        country_id: '200',
      },
      {
        id: '3215',
        label: 'Isabel',
        country_id: '200',
      },
      {
        id: '3216',
        label: 'Makira and Ulawa',
        country_id: '200',
      },
      {
        id: '3217',
        label: 'Malaita',
        country_id: '200',
      },
      {
        id: '3218',
        label: 'Rennell and Bellona',
        country_id: '200',
      },
      {
        id: '3219',
        label: 'Temotu',
        country_id: '200',
      },
      {
        id: '3220',
        label: 'Western',
        country_id: '200',
      },
    ],
  },
  {
    id: '201',
    sortname: 'SO',
    label: 'Somalia',
    states: [
      {
        id: '3221',
        label: 'Awdal',
        country_id: '201',
      },
      {
        id: '3222',
        label: 'Bakol',
        country_id: '201',
      },
      {
        id: '3223',
        label: 'Banadir',
        country_id: '201',
      },
      {
        id: '3224',
        label: 'Bari',
        country_id: '201',
      },
      {
        id: '3225',
        label: 'Bay',
        country_id: '201',
      },
      {
        id: '3226',
        label: 'Galgudug',
        country_id: '201',
      },
      {
        id: '3227',
        label: 'Gedo',
        country_id: '201',
      },
      {
        id: '3228',
        label: 'Hiran',
        country_id: '201',
      },
      {
        id: '3229',
        label: 'Jubbada Hose',
        country_id: '201',
      },
      {
        id: '3230',
        label: 'Jubbadha Dexe',
        country_id: '201',
      },
      {
        id: '3231',
        label: 'Mudug',
        country_id: '201',
      },
      {
        id: '3232',
        label: 'Nugal',
        country_id: '201',
      },
      {
        id: '3233',
        label: 'Sanag',
        country_id: '201',
      },
      {
        id: '3234',
        label: 'Shabellaha Dhexe',
        country_id: '201',
      },
      {
        id: '3235',
        label: 'Shabellaha Hose',
        country_id: '201',
      },
      {
        id: '3236',
        label: 'Togdher',
        country_id: '201',
      },
      {
        id: '3237',
        label: 'Woqoyi Galbed',
        country_id: '201',
      },
    ],
  },
  {
    id: '202',
    sortname: 'ZA',
    label: 'South Africa',
    states: [
      {
        id: '3238',
        label: 'Eastern Cape',
        country_id: '202',
      },
      {
        id: '3239',
        label: 'Free State',
        country_id: '202',
      },
      {
        id: '3240',
        label: 'Gauteng',
        country_id: '202',
      },
      {
        id: '3241',
        label: 'Kempton Park',
        country_id: '202',
      },
      {
        id: '3242',
        label: 'Kramerville',
        country_id: '202',
      },
      {
        id: '3243',
        label: 'KwaZulu Natal',
        country_id: '202',
      },
      {
        id: '3244',
        label: 'Limpopo',
        country_id: '202',
      },
      {
        id: '3245',
        label: 'Mpumalanga',
        country_id: '202',
      },
      {
        id: '3246',
        label: 'North West',
        country_id: '202',
      },
      {
        id: '3247',
        label: 'Northern Cape',
        country_id: '202',
      },
      {
        id: '3248',
        label: 'Parow',
        country_id: '202',
      },
      {
        id: '3249',
        label: 'Table View',
        country_id: '202',
      },
      {
        id: '3250',
        label: 'Umtentweni',
        country_id: '202',
      },
      {
        id: '3251',
        label: 'Western Cape',
        country_id: '202',
      },
    ],
  },
  {
    id: '203',
    sortname: 'GS',
    label: 'South Georgia',
    states: [
      {
        id: '3252',
        label: 'South Georgia',
        country_id: '203',
      },
    ],
  },
  {
    id: '204',
    sortname: 'SS',
    label: 'South Sudan',
    states: [
      {
        id: '3253',
        label: 'Central Equatoria',
        country_id: '204',
      },
    ],
  },
  {
    id: '205',
    sortname: 'ES',
    label: 'Spain',
    states: [
      {
        id: '3254',
        label: 'A Coruna',
        country_id: '205',
      },
      {
        id: '3255',
        label: 'Alacant',
        country_id: '205',
      },
      {
        id: '3256',
        label: 'Alava',
        country_id: '205',
      },
      {
        id: '3257',
        label: 'Albacete',
        country_id: '205',
      },
      {
        id: '3258',
        label: 'Almeria',
        country_id: '205',
      },
      {
        id: '3259',
        label: 'Andalucia',
        country_id: '205',
      },
      {
        id: '3260',
        label: 'Asturias',
        country_id: '205',
      },
      {
        id: '3261',
        label: 'Avila',
        country_id: '205',
      },
      {
        id: '3262',
        label: 'Badajoz',
        country_id: '205',
      },
      {
        id: '3263',
        label: 'Balears',
        country_id: '205',
      },
      {
        id: '3264',
        label: 'Barcelona',
        country_id: '205',
      },
      {
        id: '3265',
        label: 'Bertamirans',
        country_id: '205',
      },
      {
        id: '3266',
        label: 'Biscay',
        country_id: '205',
      },
      {
        id: '3267',
        label: 'Burgos',
        country_id: '205',
      },
      {
        id: '3268',
        label: 'Caceres',
        country_id: '205',
      },
      {
        id: '3269',
        label: 'Cadiz',
        country_id: '205',
      },
      {
        id: '3270',
        label: 'Cantabria',
        country_id: '205',
      },
      {
        id: '3271',
        label: 'Castello',
        country_id: '205',
      },
      {
        id: '3272',
        label: 'Catalunya',
        country_id: '205',
      },
      {
        id: '3273',
        label: 'Ceuta',
        country_id: '205',
      },
      {
        id: '3274',
        label: 'Ciudad Real',
        country_id: '205',
      },
      {
        id: '3275',
        label: 'Comunidad Autonoma de Canarias',
        country_id: '205',
      },
      {
        id: '3276',
        label: 'Comunidad Autonoma de Cataluna',
        country_id: '205',
      },
      {
        id: '3277',
        label: 'Comunidad Autonoma de Galicia',
        country_id: '205',
      },
      {
        id: '3278',
        label: 'Comunidad Autonoma de las Isla',
        country_id: '205',
      },
      {
        id: '3279',
        label: 'Comunidad Autonoma del Princip',
        country_id: '205',
      },
      {
        id: '3280',
        label: 'Comunidad Valenciana',
        country_id: '205',
      },
      {
        id: '3281',
        label: 'Cordoba',
        country_id: '205',
      },
      {
        id: '3282',
        label: 'Cuenca',
        country_id: '205',
      },
      {
        id: '3283',
        label: 'Gipuzkoa',
        country_id: '205',
      },
      {
        id: '3284',
        label: 'Girona',
        country_id: '205',
      },
      {
        id: '3285',
        label: 'Granada',
        country_id: '205',
      },
      {
        id: '3286',
        label: 'Guadalajara',
        country_id: '205',
      },
      {
        id: '3287',
        label: 'Guipuzcoa',
        country_id: '205',
      },
      {
        id: '3288',
        label: 'Huelva',
        country_id: '205',
      },
      {
        id: '3289',
        label: 'Huesca',
        country_id: '205',
      },
      {
        id: '3290',
        label: 'Jaen',
        country_id: '205',
      },
      {
        id: '3291',
        label: 'La Rioja',
        country_id: '205',
      },
      {
        id: '3292',
        label: 'Las Palmas',
        country_id: '205',
      },
      {
        id: '3293',
        label: 'Leon',
        country_id: '205',
      },
      {
        id: '3294',
        label: 'Lerida',
        country_id: '205',
      },
      {
        id: '3295',
        label: 'Lleida',
        country_id: '205',
      },
      {
        id: '3296',
        label: 'Lugo',
        country_id: '205',
      },
      {
        id: '3297',
        label: 'Madrid',
        country_id: '205',
      },
      {
        id: '3298',
        label: 'Malaga',
        country_id: '205',
      },
      {
        id: '3299',
        label: 'Melilla',
        country_id: '205',
      },
      {
        id: '3300',
        label: 'Murcia',
        country_id: '205',
      },
      {
        id: '3301',
        label: 'Navarra',
        country_id: '205',
      },
      {
        id: '3302',
        label: 'Ourense',
        country_id: '205',
      },
      {
        id: '3303',
        label: 'Pais Vasco',
        country_id: '205',
      },
      {
        id: '3304',
        label: 'Palencia',
        country_id: '205',
      },
      {
        id: '3305',
        label: 'Pontevedra',
        country_id: '205',
      },
      {
        id: '3306',
        label: 'Salamanca',
        country_id: '205',
      },
      {
        id: '3307',
        label: 'Santa Cruz de Tenerife',
        country_id: '205',
      },
      {
        id: '3308',
        label: 'Segovia',
        country_id: '205',
      },
      {
        id: '3309',
        label: 'Sevilla',
        country_id: '205',
      },
      {
        id: '3310',
        label: 'Soria',
        country_id: '205',
      },
      {
        id: '3311',
        label: 'Tarragona',
        country_id: '205',
      },
      {
        id: '3312',
        label: 'Tenerife',
        country_id: '205',
      },
      {
        id: '3313',
        label: 'Teruel',
        country_id: '205',
      },
      {
        id: '3314',
        label: 'Toledo',
        country_id: '205',
      },
      {
        id: '3315',
        label: 'Valencia',
        country_id: '205',
      },
      {
        id: '3316',
        label: 'Valladolid',
        country_id: '205',
      },
      {
        id: '3317',
        label: 'Vizcaya',
        country_id: '205',
      },
      {
        id: '3318',
        label: 'Zamora',
        country_id: '205',
      },
      {
        id: '3319',
        label: 'Zaragoza',
        country_id: '205',
      },
    ],
  },
  {
    id: '206',
    sortname: 'LK',
    label: 'Sri Lanka',
    states: [
      {
        id: '3320',
        label: 'Amparai',
        country_id: '206',
      },
      {
        id: '3321',
        label: 'Anuradhapuraya',
        country_id: '206',
      },
      {
        id: '3322',
        label: 'Badulla',
        country_id: '206',
      },
      {
        id: '3323',
        label: 'Boralesgamuwa',
        country_id: '206',
      },
      {
        id: '3324',
        label: 'Colombo',
        country_id: '206',
      },
      {
        id: '3325',
        label: 'Galla',
        country_id: '206',
      },
      {
        id: '3326',
        label: 'Gampaha',
        country_id: '206',
      },
      {
        id: '3327',
        label: 'Hambantota',
        country_id: '206',
      },
      {
        id: '3328',
        label: 'Kalatura',
        country_id: '206',
      },
      {
        id: '3329',
        label: 'Kegalla',
        country_id: '206',
      },
      {
        id: '3330',
        label: 'Kilinochchi',
        country_id: '206',
      },
      {
        id: '3331',
        label: 'Kurunegala',
        country_id: '206',
      },
      {
        id: '3332',
        label: 'Madakalpuwa',
        country_id: '206',
      },
      {
        id: '3333',
        label: 'Maha Nuwara',
        country_id: '206',
      },
      {
        id: '3334',
        label: 'Malwana',
        country_id: '206',
      },
      {
        id: '3335',
        label: 'Mannarama',
        country_id: '206',
      },
      {
        id: '3336',
        label: 'Matale',
        country_id: '206',
      },
      {
        id: '3337',
        label: 'Matara',
        country_id: '206',
      },
      {
        id: '3338',
        label: 'Monaragala',
        country_id: '206',
      },
      {
        id: '3339',
        label: 'Mullaitivu',
        country_id: '206',
      },
      {
        id: '3340',
        label: 'North Eastern Province',
        country_id: '206',
      },
      {
        id: '3341',
        label: 'North Western Province',
        country_id: '206',
      },
      {
        id: '3342',
        label: 'Nuwara Eliya',
        country_id: '206',
      },
      {
        id: '3343',
        label: 'Polonnaruwa',
        country_id: '206',
      },
      {
        id: '3344',
        label: 'Puttalama',
        country_id: '206',
      },
      {
        id: '3345',
        label: 'Ratnapuraya',
        country_id: '206',
      },
      {
        id: '3346',
        label: 'Southern Province',
        country_id: '206',
      },
      {
        id: '3347',
        label: 'Tirikunamalaya',
        country_id: '206',
      },
      {
        id: '3348',
        label: 'Tuscany',
        country_id: '206',
      },
      {
        id: '3349',
        label: 'Vavuniyawa',
        country_id: '206',
      },
      {
        id: '3350',
        label: 'Western Province',
        country_id: '206',
      },
      {
        id: '3351',
        label: 'Yapanaya',
        country_id: '206',
      },
      {
        id: '3352',
        label: 'kadawatha',
        country_id: '206',
      },
    ],
  },
  {
    id: '207',
    sortname: 'SD',
    label: 'Sudan',
    states: [
      {
        id: '3353',
        label: "A'ali-an-Nil",
        country_id: '207',
      },
      {
        id: '3354',
        label: 'Bahr-al-Jabal',
        country_id: '207',
      },
      {
        id: '3355',
        label: 'Central Equatoria',
        country_id: '207',
      },
      {
        id: '3356',
        label: 'Gharb Bahr-al-Ghazal',
        country_id: '207',
      },
      {
        id: '3357',
        label: 'Gharb Darfur',
        country_id: '207',
      },
      {
        id: '3358',
        label: 'Gharb Kurdufan',
        country_id: '207',
      },
      {
        id: '3359',
        label: "Gharb-al-Istiwa'iyah",
        country_id: '207',
      },
      {
        id: '3360',
        label: 'Janub Darfur',
        country_id: '207',
      },
      {
        id: '3361',
        label: 'Janub Kurdufan',
        country_id: '207',
      },
      {
        id: '3362',
        label: 'Junqali',
        country_id: '207',
      },
      {
        id: '3363',
        label: 'Kassala',
        country_id: '207',
      },
      {
        id: '3364',
        label: 'Nahr-an-Nil',
        country_id: '207',
      },
      {
        id: '3365',
        label: 'Shamal Bahr-al-Ghazal',
        country_id: '207',
      },
      {
        id: '3366',
        label: 'Shamal Darfur',
        country_id: '207',
      },
      {
        id: '3367',
        label: 'Shamal Kurdufan',
        country_id: '207',
      },
      {
        id: '3368',
        label: "Sharq-al-Istiwa'iyah",
        country_id: '207',
      },
      {
        id: '3369',
        label: 'Sinnar',
        country_id: '207',
      },
      {
        id: '3370',
        label: 'Warab',
        country_id: '207',
      },
      {
        id: '3371',
        label: 'Wilayat al Khartum',
        country_id: '207',
      },
      {
        id: '3372',
        label: 'al-Bahr-al-Ahmar',
        country_id: '207',
      },
      {
        id: '3373',
        label: 'al-Buhayrat',
        country_id: '207',
      },
      {
        id: '3374',
        label: 'al-Jazirah',
        country_id: '207',
      },
      {
        id: '3375',
        label: 'al-Khartum',
        country_id: '207',
      },
      {
        id: '3376',
        label: 'al-Qadarif',
        country_id: '207',
      },
      {
        id: '3377',
        label: 'al-Wahdah',
        country_id: '207',
      },
      {
        id: '3378',
        label: 'an-Nil-al-Abyad',
        country_id: '207',
      },
      {
        id: '3379',
        label: 'an-Nil-al-Azraq',
        country_id: '207',
      },
      {
        id: '3380',
        label: 'ash-Shamaliyah',
        country_id: '207',
      },
    ],
  },
  {
    id: '208',
    sortname: 'SR',
    label: 'Suriname',
    states: [
      {
        id: '3381',
        label: 'Brokopondo',
        country_id: '208',
      },
      {
        id: '3382',
        label: 'Commewijne',
        country_id: '208',
      },
      {
        id: '3383',
        label: 'Coronie',
        country_id: '208',
      },
      {
        id: '3384',
        label: 'Marowijne',
        country_id: '208',
      },
      {
        id: '3385',
        label: 'Nickerie',
        country_id: '208',
      },
      {
        id: '3386',
        label: 'Para',
        country_id: '208',
      },
      {
        id: '3387',
        label: 'Paramaribo',
        country_id: '208',
      },
      {
        id: '3388',
        label: 'Saramacca',
        country_id: '208',
      },
      {
        id: '3389',
        label: 'Wanica',
        country_id: '208',
      },
    ],
  },
  {
    id: '209',
    sortname: 'SJ',
    label: 'Svalbard And Jan Mayen Islands',
    states: [
      {
        id: '3390',
        label: 'Svalbard',
        country_id: '209',
      },
    ],
  },
  {
    id: '210',
    sortname: 'SZ',
    label: 'Swaziland',
    states: [
      {
        id: '3391',
        label: 'Hhohho',
        country_id: '210',
      },
      {
        id: '3392',
        label: 'Lubombo',
        country_id: '210',
      },
      {
        id: '3393',
        label: 'Manzini',
        country_id: '210',
      },
      {
        id: '3394',
        label: 'Shiselweni',
        country_id: '210',
      },
    ],
  },
  {
    id: '211',
    sortname: 'SE',
    label: 'Sweden',
    states: [
      {
        id: '3395',
        label: 'Alvsborgs Lan',
        country_id: '211',
      },
      {
        id: '3396',
        label: 'Angermanland',
        country_id: '211',
      },
      {
        id: '3397',
        label: 'Blekinge',
        country_id: '211',
      },
      {
        id: '3398',
        label: 'Bohuslan',
        country_id: '211',
      },
      {
        id: '3399',
        label: 'Dalarna',
        country_id: '211',
      },
      {
        id: '3400',
        label: 'Gavleborg',
        country_id: '211',
      },
      {
        id: '3401',
        label: 'Gaza',
        country_id: '211',
      },
      {
        id: '3402',
        label: 'Gotland',
        country_id: '211',
      },
      {
        id: '3403',
        label: 'Halland',
        country_id: '211',
      },
      {
        id: '3404',
        label: 'Jamtland',
        country_id: '211',
      },
      {
        id: '3405',
        label: 'Jonkoping',
        country_id: '211',
      },
      {
        id: '3406',
        label: 'Kalmar',
        country_id: '211',
      },
      {
        id: '3407',
        label: 'Kristianstads',
        country_id: '211',
      },
      {
        id: '3408',
        label: 'Kronoberg',
        country_id: '211',
      },
      {
        id: '3409',
        label: 'Norrbotten',
        country_id: '211',
      },
      {
        id: '3410',
        label: 'Orebro',
        country_id: '211',
      },
      {
        id: '3411',
        label: 'Ostergotland',
        country_id: '211',
      },
      {
        id: '3412',
        label: 'Saltsjo-Boo',
        country_id: '211',
      },
      {
        id: '3413',
        label: 'Skane',
        country_id: '211',
      },
      {
        id: '3414',
        label: 'Smaland',
        country_id: '211',
      },
      {
        id: '3415',
        label: 'Sodermanland',
        country_id: '211',
      },
      {
        id: '3416',
        label: 'Stockholm',
        country_id: '211',
      },
      {
        id: '3417',
        label: 'Uppsala',
        country_id: '211',
      },
      {
        id: '3418',
        label: 'Varmland',
        country_id: '211',
      },
      {
        id: '3419',
        label: 'Vasterbotten',
        country_id: '211',
      },
      {
        id: '3420',
        label: 'Vastergotland',
        country_id: '211',
      },
      {
        id: '3421',
        label: 'Vasternorrland',
        country_id: '211',
      },
      {
        id: '3422',
        label: 'Vastmanland',
        country_id: '211',
      },
      {
        id: '3423',
        label: 'Vastra Gotaland',
        country_id: '211',
      },
    ],
  },
  {
    id: '212',
    sortname: 'CH',
    label: 'Switzerland',
    states: [
      {
        id: '3424',
        label: 'Aargau',
        country_id: '212',
      },
      {
        id: '3425',
        label: 'Appenzell Inner-Rhoden',
        country_id: '212',
      },
      {
        id: '3426',
        label: 'Appenzell-Ausser Rhoden',
        country_id: '212',
      },
      {
        id: '3427',
        label: 'Basel-Landschaft',
        country_id: '212',
      },
      {
        id: '3428',
        label: 'Basel-Stadt',
        country_id: '212',
      },
      {
        id: '3429',
        label: 'Bern',
        country_id: '212',
      },
      {
        id: '3430',
        label: 'Canton Ticino',
        country_id: '212',
      },
      {
        id: '3431',
        label: 'Fribourg',
        country_id: '212',
      },
      {
        id: '3432',
        label: 'Geneve',
        country_id: '212',
      },
      {
        id: '3433',
        label: 'Glarus',
        country_id: '212',
      },
      {
        id: '3434',
        label: 'Graubunden',
        country_id: '212',
      },
      {
        id: '3435',
        label: 'Heerbrugg',
        country_id: '212',
      },
      {
        id: '3436',
        label: 'Jura',
        country_id: '212',
      },
      {
        id: '3437',
        label: 'Kanton Aargau',
        country_id: '212',
      },
      {
        id: '3438',
        label: 'Luzern',
        country_id: '212',
      },
      {
        id: '3439',
        label: 'Morbio Inferiore',
        country_id: '212',
      },
      {
        id: '3440',
        label: 'Muhen',
        country_id: '212',
      },
      {
        id: '3441',
        label: 'Neuchatel',
        country_id: '212',
      },
      {
        id: '3442',
        label: 'Nidwalden',
        country_id: '212',
      },
      {
        id: '3443',
        label: 'Obwalden',
        country_id: '212',
      },
      {
        id: '3444',
        label: 'Sankt Gallen',
        country_id: '212',
      },
      {
        id: '3445',
        label: 'Schaffhausen',
        country_id: '212',
      },
      {
        id: '3446',
        label: 'Schwyz',
        country_id: '212',
      },
      {
        id: '3447',
        label: 'Solothurn',
        country_id: '212',
      },
      {
        id: '3448',
        label: 'Thurgau',
        country_id: '212',
      },
      {
        id: '3449',
        label: 'Ticino',
        country_id: '212',
      },
      {
        id: '3450',
        label: 'Uri',
        country_id: '212',
      },
      {
        id: '3451',
        label: 'Valais',
        country_id: '212',
      },
      {
        id: '3452',
        label: 'Vaud',
        country_id: '212',
      },
      {
        id: '3453',
        label: 'Vauffelin',
        country_id: '212',
      },
      {
        id: '3454',
        label: 'Zug',
        country_id: '212',
      },
      {
        id: '3455',
        label: 'Zurich',
        country_id: '212',
      },
    ],
  },
  {
    id: '213',
    sortname: 'SY',
    label: 'Syria',
    states: [
      {
        id: '3456',
        label: 'Aleppo',
        country_id: '213',
      },
      {
        id: '3457',
        label: "Dar'a",
        country_id: '213',
      },
      {
        id: '3458',
        label: 'Dayr-az-Zawr',
        country_id: '213',
      },
      {
        id: '3459',
        label: 'Dimashq',
        country_id: '213',
      },
      {
        id: '3460',
        label: 'Halab',
        country_id: '213',
      },
      {
        id: '3461',
        label: 'Hamah',
        country_id: '213',
      },
      {
        id: '3462',
        label: 'Hims',
        country_id: '213',
      },
      {
        id: '3463',
        label: 'Idlib',
        country_id: '213',
      },
      {
        id: '3464',
        label: 'Madinat Dimashq',
        country_id: '213',
      },
      {
        id: '3465',
        label: 'Tartus',
        country_id: '213',
      },
      {
        id: '3466',
        label: 'al-Hasakah',
        country_id: '213',
      },
      {
        id: '3467',
        label: 'al-Ladhiqiyah',
        country_id: '213',
      },
      {
        id: '3468',
        label: 'al-Qunaytirah',
        country_id: '213',
      },
      {
        id: '3469',
        label: 'ar-Raqqah',
        country_id: '213',
      },
      {
        id: '3470',
        label: 'as-Suwayda',
        country_id: '213',
      },
    ],
  },
  {
    id: '214',
    sortname: 'TW',
    label: 'Taiwan',
    states: [
      {
        id: '3471',
        label: 'Changhwa',
        country_id: '214',
      },
      {
        id: '3472',
        label: 'Chiayi Hsien',
        country_id: '214',
      },
      {
        id: '3473',
        label: 'Chiayi Shih',
        country_id: '214',
      },
      {
        id: '3474',
        label: 'Eastern Taipei',
        country_id: '214',
      },
      {
        id: '3475',
        label: 'Hsinchu Hsien',
        country_id: '214',
      },
      {
        id: '3476',
        label: 'Hsinchu Shih',
        country_id: '214',
      },
      {
        id: '3477',
        label: 'Hualien',
        country_id: '214',
      },
      {
        id: '3478',
        label: 'Ilan',
        country_id: '214',
      },
      {
        id: '3479',
        label: 'Kaohsiung Hsien',
        country_id: '214',
      },
      {
        id: '3480',
        label: 'Kaohsiung Shih',
        country_id: '214',
      },
      {
        id: '3481',
        label: 'Keelung Shih',
        country_id: '214',
      },
      {
        id: '3482',
        label: 'Kinmen',
        country_id: '214',
      },
      {
        id: '3483',
        label: 'Miaoli',
        country_id: '214',
      },
      {
        id: '3484',
        label: 'Nantou',
        country_id: '214',
      },
      {
        id: '3485',
        label: 'Northern Taiwan',
        country_id: '214',
      },
      {
        id: '3486',
        label: 'Penghu',
        country_id: '214',
      },
      {
        id: '3487',
        label: 'Pingtung',
        country_id: '214',
      },
      {
        id: '3488',
        label: 'Taichung',
        country_id: '214',
      },
      {
        id: '3489',
        label: 'Taichung Hsien',
        country_id: '214',
      },
      {
        id: '3490',
        label: 'Taichung Shih',
        country_id: '214',
      },
      {
        id: '3491',
        label: 'Tainan Hsien',
        country_id: '214',
      },
      {
        id: '3492',
        label: 'Tainan Shih',
        country_id: '214',
      },
      {
        id: '3493',
        label: 'Taipei Hsien',
        country_id: '214',
      },
      {
        id: '3494',
        label: 'Taipei Shih / Taipei Hsien',
        country_id: '214',
      },
      {
        id: '3495',
        label: 'Taitung',
        country_id: '214',
      },
      {
        id: '3496',
        label: 'Taoyuan',
        country_id: '214',
      },
      {
        id: '3497',
        label: 'Yilan',
        country_id: '214',
      },
      {
        id: '3498',
        label: 'Yun-Lin Hsien',
        country_id: '214',
      },
      {
        id: '3499',
        label: 'Yunlin',
        country_id: '214',
      },
    ],
  },
  {
    id: '215',
    sortname: 'TJ',
    label: 'Tajikistan',
    states: [
      {
        id: '3500',
        label: 'Dushanbe',
        country_id: '215',
      },
      {
        id: '3501',
        label: 'Gorno-Badakhshan',
        country_id: '215',
      },
      {
        id: '3502',
        label: 'Karotegin',
        country_id: '215',
      },
      {
        id: '3503',
        label: 'Khatlon',
        country_id: '215',
      },
      {
        id: '3504',
        label: 'Sughd',
        country_id: '215',
      },
    ],
  },
  {
    id: '216',
    sortname: 'TZ',
    label: 'Tanzania',
    states: [
      {
        id: '3505',
        label: 'Arusha',
        country_id: '216',
      },
      {
        id: '3506',
        label: 'Dar es Salaam',
        country_id: '216',
      },
      {
        id: '3507',
        label: 'Dodoma',
        country_id: '216',
      },
      {
        id: '3508',
        label: 'Iringa',
        country_id: '216',
      },
      {
        id: '3509',
        label: 'Kagera',
        country_id: '216',
      },
      {
        id: '3510',
        label: 'Kigoma',
        country_id: '216',
      },
      {
        id: '3511',
        label: 'Kilimanjaro',
        country_id: '216',
      },
      {
        id: '3512',
        label: 'Lindi',
        country_id: '216',
      },
      {
        id: '3513',
        label: 'Mara',
        country_id: '216',
      },
      {
        id: '3514',
        label: 'Mbeya',
        country_id: '216',
      },
      {
        id: '3515',
        label: 'Morogoro',
        country_id: '216',
      },
      {
        id: '3516',
        label: 'Mtwara',
        country_id: '216',
      },
      {
        id: '3517',
        label: 'Mwanza',
        country_id: '216',
      },
      {
        id: '3518',
        label: 'Pwani',
        country_id: '216',
      },
      {
        id: '3519',
        label: 'Rukwa',
        country_id: '216',
      },
      {
        id: '3520',
        label: 'Ruvuma',
        country_id: '216',
      },
      {
        id: '3521',
        label: 'Shinyanga',
        country_id: '216',
      },
      {
        id: '3522',
        label: 'Singida',
        country_id: '216',
      },
      {
        id: '3523',
        label: 'Tabora',
        country_id: '216',
      },
      {
        id: '3524',
        label: 'Tanga',
        country_id: '216',
      },
      {
        id: '3525',
        label: 'Zanzibar and Pemba',
        country_id: '216',
      },
    ],
  },
  {
    id: '217',
    sortname: 'TH',
    label: 'Thailand',
    states: [
      {
        id: '3526',
        label: 'Amnat Charoen',
        country_id: '217',
      },
      {
        id: '3527',
        label: 'Ang Thong',
        country_id: '217',
      },
      {
        id: '3528',
        label: 'Bangkok',
        country_id: '217',
      },
      {
        id: '3529',
        label: 'Buri Ram',
        country_id: '217',
      },
      {
        id: '3530',
        label: 'Chachoengsao',
        country_id: '217',
      },
      {
        id: '3531',
        label: 'Chai Nat',
        country_id: '217',
      },
      {
        id: '3532',
        label: 'Chaiyaphum',
        country_id: '217',
      },
      {
        id: '3533',
        label: 'Changwat Chaiyaphum',
        country_id: '217',
      },
      {
        id: '3534',
        label: 'Chanthaburi',
        country_id: '217',
      },
      {
        id: '3535',
        label: 'Chiang Mai',
        country_id: '217',
      },
      {
        id: '3536',
        label: 'Chiang Rai',
        country_id: '217',
      },
      {
        id: '3537',
        label: 'Chon Buri',
        country_id: '217',
      },
      {
        id: '3538',
        label: 'Chumphon',
        country_id: '217',
      },
      {
        id: '3539',
        label: 'Kalasin',
        country_id: '217',
      },
      {
        id: '3540',
        label: 'Kamphaeng Phet',
        country_id: '217',
      },
      {
        id: '3541',
        label: 'Kanchanaburi',
        country_id: '217',
      },
      {
        id: '3542',
        label: 'Khon Kaen',
        country_id: '217',
      },
      {
        id: '3543',
        label: 'Krabi',
        country_id: '217',
      },
      {
        id: '3544',
        label: 'Krung Thep',
        country_id: '217',
      },
      {
        id: '3545',
        label: 'Lampang',
        country_id: '217',
      },
      {
        id: '3546',
        label: 'Lamphun',
        country_id: '217',
      },
      {
        id: '3547',
        label: 'Loei',
        country_id: '217',
      },
      {
        id: '3548',
        label: 'Lop Buri',
        country_id: '217',
      },
      {
        id: '3549',
        label: 'Mae Hong Son',
        country_id: '217',
      },
      {
        id: '3550',
        label: 'Maha Sarakham',
        country_id: '217',
      },
      {
        id: '3551',
        label: 'Mukdahan',
        country_id: '217',
      },
      {
        id: '3552',
        label: 'Nakhon Nayok',
        country_id: '217',
      },
      {
        id: '3553',
        label: 'Nakhon Pathom',
        country_id: '217',
      },
      {
        id: '3554',
        label: 'Nakhon Phanom',
        country_id: '217',
      },
      {
        id: '3555',
        label: 'Nakhon Ratchasima',
        country_id: '217',
      },
      {
        id: '3556',
        label: 'Nakhon Sawan',
        country_id: '217',
      },
      {
        id: '3557',
        label: 'Nakhon Si Thammarat',
        country_id: '217',
      },
      {
        id: '3558',
        label: 'Nan',
        country_id: '217',
      },
      {
        id: '3559',
        label: 'Narathiwat',
        country_id: '217',
      },
      {
        id: '3560',
        label: 'Nong Bua Lam Phu',
        country_id: '217',
      },
      {
        id: '3561',
        label: 'Nong Khai',
        country_id: '217',
      },
      {
        id: '3562',
        label: 'Nonthaburi',
        country_id: '217',
      },
      {
        id: '3563',
        label: 'Pathum Thani',
        country_id: '217',
      },
      {
        id: '3564',
        label: 'Pattani',
        country_id: '217',
      },
      {
        id: '3565',
        label: 'Phangnga',
        country_id: '217',
      },
      {
        id: '3566',
        label: 'Phatthalung',
        country_id: '217',
      },
      {
        id: '3567',
        label: 'Phayao',
        country_id: '217',
      },
      {
        id: '3568',
        label: 'Phetchabun',
        country_id: '217',
      },
      {
        id: '3569',
        label: 'Phetchaburi',
        country_id: '217',
      },
      {
        id: '3570',
        label: 'Phichit',
        country_id: '217',
      },
      {
        id: '3571',
        label: 'Phitsanulok',
        country_id: '217',
      },
      {
        id: '3572',
        label: 'Phra Nakhon Si Ayutthaya',
        country_id: '217',
      },
      {
        id: '3573',
        label: 'Phrae',
        country_id: '217',
      },
      {
        id: '3574',
        label: 'Phuket',
        country_id: '217',
      },
      {
        id: '3575',
        label: 'Prachin Buri',
        country_id: '217',
      },
      {
        id: '3576',
        label: 'Prachuap Khiri Khan',
        country_id: '217',
      },
      {
        id: '3577',
        label: 'Ranong',
        country_id: '217',
      },
      {
        id: '3578',
        label: 'Ratchaburi',
        country_id: '217',
      },
      {
        id: '3579',
        label: 'Rayong',
        country_id: '217',
      },
      {
        id: '3580',
        label: 'Roi Et',
        country_id: '217',
      },
      {
        id: '3581',
        label: 'Sa Kaeo',
        country_id: '217',
      },
      {
        id: '3582',
        label: 'Sakon Nakhon',
        country_id: '217',
      },
      {
        id: '3583',
        label: 'Samut Prakan',
        country_id: '217',
      },
      {
        id: '3584',
        label: 'Samut Sakhon',
        country_id: '217',
      },
      {
        id: '3585',
        label: 'Samut Songkhran',
        country_id: '217',
      },
      {
        id: '3586',
        label: 'Saraburi',
        country_id: '217',
      },
      {
        id: '3587',
        label: 'Satun',
        country_id: '217',
      },
      {
        id: '3588',
        label: 'Si Sa Ket',
        country_id: '217',
      },
      {
        id: '3589',
        label: 'Sing Buri',
        country_id: '217',
      },
      {
        id: '3590',
        label: 'Songkhla',
        country_id: '217',
      },
      {
        id: '3591',
        label: 'Sukhothai',
        country_id: '217',
      },
      {
        id: '3592',
        label: 'Suphan Buri',
        country_id: '217',
      },
      {
        id: '3593',
        label: 'Surat Thani',
        country_id: '217',
      },
      {
        id: '3594',
        label: 'Surin',
        country_id: '217',
      },
      {
        id: '3595',
        label: 'Tak',
        country_id: '217',
      },
      {
        id: '3596',
        label: 'Trang',
        country_id: '217',
      },
      {
        id: '3597',
        label: 'Trat',
        country_id: '217',
      },
      {
        id: '3598',
        label: 'Ubon Ratchathani',
        country_id: '217',
      },
      {
        id: '3599',
        label: 'Udon Thani',
        country_id: '217',
      },
      {
        id: '3600',
        label: 'Uthai Thani',
        country_id: '217',
      },
      {
        id: '3601',
        label: 'Uttaradit',
        country_id: '217',
      },
      {
        id: '3602',
        label: 'Yala',
        country_id: '217',
      },
      {
        id: '3603',
        label: 'Yasothon',
        country_id: '217',
      },
    ],
  },
  {
    id: '218',
    sortname: 'TG',
    label: 'Togo',
    states: [
      {
        id: '3604',
        label: 'Centre',
        country_id: '218',
      },
      {
        id: '3605',
        label: 'Kara',
        country_id: '218',
      },
      {
        id: '3606',
        label: 'Maritime',
        country_id: '218',
      },
      {
        id: '3607',
        label: 'Plateaux',
        country_id: '218',
      },
      {
        id: '3608',
        label: 'Savanes',
        country_id: '218',
      },
    ],
  },
  {
    id: '219',
    sortname: 'TK',
    label: 'Tokelau',
    states: [
      {
        id: '3609',
        label: 'Atafu',
        country_id: '219',
      },
      {
        id: '3610',
        label: 'Fakaofo',
        country_id: '219',
      },
      {
        id: '3611',
        label: 'Nukunonu',
        country_id: '219',
      },
    ],
  },
  {
    id: '220',
    sortname: 'TO',
    label: 'Tonga',
    states: [
      {
        id: '3612',
        label: 'Eua',
        country_id: '220',
      },
      {
        id: '3613',
        label: "Ha'apai",
        country_id: '220',
      },
      {
        id: '3614',
        label: 'Niuas',
        country_id: '220',
      },
      {
        id: '3615',
        label: 'Tongatapu',
        country_id: '220',
      },
      {
        id: '3616',
        label: "Vava'u",
        country_id: '220',
      },
    ],
  },
  {
    id: '221',
    sortname: 'TT',
    label: 'Trinidad And Tobago',
    states: [
      {
        id: '3617',
        label: 'Arima-Tunapuna-Piarco',
        country_id: '221',
      },
      {
        id: '3618',
        label: 'Caroni',
        country_id: '221',
      },
      {
        id: '3619',
        label: 'Chaguanas',
        country_id: '221',
      },
      {
        id: '3620',
        label: 'Couva-Tabaquite-Talparo',
        country_id: '221',
      },
      {
        id: '3621',
        label: 'Diego Martin',
        country_id: '221',
      },
      {
        id: '3622',
        label: 'Glencoe',
        country_id: '221',
      },
      {
        id: '3623',
        label: 'Penal Debe',
        country_id: '221',
      },
      {
        id: '3624',
        label: 'Point Fortin',
        country_id: '221',
      },
      {
        id: '3625',
        label: 'Port of Spain',
        country_id: '221',
      },
      {
        id: '3626',
        label: 'Princes Town',
        country_id: '221',
      },
      {
        id: '3627',
        label: 'Saint George',
        country_id: '221',
      },
      {
        id: '3628',
        label: 'San Fernando',
        country_id: '221',
      },
      {
        id: '3629',
        label: 'San Juan',
        country_id: '221',
      },
      {
        id: '3630',
        label: 'Sangre Grande',
        country_id: '221',
      },
      {
        id: '3631',
        label: 'Siparia',
        country_id: '221',
      },
      {
        id: '3632',
        label: 'Tobago',
        country_id: '221',
      },
    ],
  },
  {
    id: '222',
    sortname: 'TN',
    label: 'Tunisia',
    states: [
      {
        id: '3633',
        label: 'Aryanah',
        country_id: '222',
      },
      {
        id: '3634',
        label: 'Bajah',
        country_id: '222',
      },
      {
        id: '3635',
        label: "Bin 'Arus",
        country_id: '222',
      },
      {
        id: '3636',
        label: 'Binzart',
        country_id: '222',
      },
      {
        id: '3637',
        label: 'Gouvernorat de Ariana',
        country_id: '222',
      },
      {
        id: '3638',
        label: 'Gouvernorat de Nabeul',
        country_id: '222',
      },
      {
        id: '3639',
        label: 'Gouvernorat de Sousse',
        country_id: '222',
      },
      {
        id: '3640',
        label: 'Hammamet Yasmine',
        country_id: '222',
      },
      {
        id: '3641',
        label: 'Jundubah',
        country_id: '222',
      },
      {
        id: '3642',
        label: 'Madaniyin',
        country_id: '222',
      },
      {
        id: '3643',
        label: 'Manubah',
        country_id: '222',
      },
      {
        id: '3644',
        label: 'Monastir',
        country_id: '222',
      },
      {
        id: '3645',
        label: 'Nabul',
        country_id: '222',
      },
      {
        id: '3646',
        label: 'Qabis',
        country_id: '222',
      },
      {
        id: '3647',
        label: 'Qafsah',
        country_id: '222',
      },
      {
        id: '3648',
        label: 'Qibili',
        country_id: '222',
      },
      {
        id: '3649',
        label: 'Safaqis',
        country_id: '222',
      },
      {
        id: '3650',
        label: 'Sfax',
        country_id: '222',
      },
      {
        id: '3651',
        label: 'Sidi Bu Zayd',
        country_id: '222',
      },
      {
        id: '3652',
        label: 'Silyanah',
        country_id: '222',
      },
      {
        id: '3653',
        label: 'Susah',
        country_id: '222',
      },
      {
        id: '3654',
        label: 'Tatawin',
        country_id: '222',
      },
      {
        id: '3655',
        label: 'Tawzar',
        country_id: '222',
      },
      {
        id: '3656',
        label: 'Tunis',
        country_id: '222',
      },
      {
        id: '3657',
        label: 'Zaghwan',
        country_id: '222',
      },
      {
        id: '3658',
        label: 'al-Kaf',
        country_id: '222',
      },
      {
        id: '3659',
        label: 'al-Mahdiyah',
        country_id: '222',
      },
      {
        id: '3660',
        label: 'al-Munastir',
        country_id: '222',
      },
      {
        id: '3661',
        label: 'al-Qasrayn',
        country_id: '222',
      },
      {
        id: '3662',
        label: 'al-Qayrawan',
        country_id: '222',
      },
    ],
  },
  {
    id: '223',
    sortname: 'TR',
    label: 'Turkey',
    states: [
      {
        id: '3663',
        label: 'Adana',
        country_id: '223',
      },
      {
        id: '3664',
        label: 'Adiyaman',
        country_id: '223',
      },
      {
        id: '3665',
        label: 'Afyon',
        country_id: '223',
      },
      {
        id: '3666',
        label: 'Agri',
        country_id: '223',
      },
      {
        id: '3667',
        label: 'Aksaray',
        country_id: '223',
      },
      {
        id: '3668',
        label: 'Amasya',
        country_id: '223',
      },
      {
        id: '3669',
        label: 'Ankara',
        country_id: '223',
      },
      {
        id: '3670',
        label: 'Antalya',
        country_id: '223',
      },
      {
        id: '3671',
        label: 'Ardahan',
        country_id: '223',
      },
      {
        id: '3672',
        label: 'Artvin',
        country_id: '223',
      },
      {
        id: '3673',
        label: 'Aydin',
        country_id: '223',
      },
      {
        id: '3674',
        label: 'Balikesir',
        country_id: '223',
      },
      {
        id: '3675',
        label: 'Bartin',
        country_id: '223',
      },
      {
        id: '3676',
        label: 'Batman',
        country_id: '223',
      },
      {
        id: '3677',
        label: 'Bayburt',
        country_id: '223',
      },
      {
        id: '3678',
        label: 'Bilecik',
        country_id: '223',
      },
      {
        id: '3679',
        label: 'Bingol',
        country_id: '223',
      },
      {
        id: '3680',
        label: 'Bitlis',
        country_id: '223',
      },
      {
        id: '3681',
        label: 'Bolu',
        country_id: '223',
      },
      {
        id: '3682',
        label: 'Burdur',
        country_id: '223',
      },
      {
        id: '3683',
        label: 'Bursa',
        country_id: '223',
      },
      {
        id: '3684',
        label: 'Canakkale',
        country_id: '223',
      },
      {
        id: '3685',
        label: 'Cankiri',
        country_id: '223',
      },
      {
        id: '3686',
        label: 'Corum',
        country_id: '223',
      },
      {
        id: '3687',
        label: 'Denizli',
        country_id: '223',
      },
      {
        id: '3688',
        label: 'Diyarbakir',
        country_id: '223',
      },
      {
        id: '3689',
        label: 'Duzce',
        country_id: '223',
      },
      {
        id: '3690',
        label: 'Edirne',
        country_id: '223',
      },
      {
        id: '3691',
        label: 'Elazig',
        country_id: '223',
      },
      {
        id: '3692',
        label: 'Erzincan',
        country_id: '223',
      },
      {
        id: '3693',
        label: 'Erzurum',
        country_id: '223',
      },
      {
        id: '3694',
        label: 'Eskisehir',
        country_id: '223',
      },
      {
        id: '3695',
        label: 'Gaziantep',
        country_id: '223',
      },
      {
        id: '3696',
        label: 'Giresun',
        country_id: '223',
      },
      {
        id: '3697',
        label: 'Gumushane',
        country_id: '223',
      },
      {
        id: '3698',
        label: 'Hakkari',
        country_id: '223',
      },
      {
        id: '3699',
        label: 'Hatay',
        country_id: '223',
      },
      {
        id: '3700',
        label: 'Icel',
        country_id: '223',
      },
      {
        id: '3701',
        label: 'Igdir',
        country_id: '223',
      },
      {
        id: '3702',
        label: 'Isparta',
        country_id: '223',
      },
      {
        id: '3703',
        label: 'Istanbul',
        country_id: '223',
      },
      {
        id: '3704',
        label: 'Izmir',
        country_id: '223',
      },
      {
        id: '3705',
        label: 'Kahramanmaras',
        country_id: '223',
      },
      {
        id: '3706',
        label: 'Karabuk',
        country_id: '223',
      },
      {
        id: '3707',
        label: 'Karaman',
        country_id: '223',
      },
      {
        id: '3708',
        label: 'Kars',
        country_id: '223',
      },
      {
        id: '3709',
        label: 'Karsiyaka',
        country_id: '223',
      },
      {
        id: '3710',
        label: 'Kastamonu',
        country_id: '223',
      },
      {
        id: '3711',
        label: 'Kayseri',
        country_id: '223',
      },
      {
        id: '3712',
        label: 'Kilis',
        country_id: '223',
      },
      {
        id: '3713',
        label: 'Kirikkale',
        country_id: '223',
      },
      {
        id: '3714',
        label: 'Kirklareli',
        country_id: '223',
      },
      {
        id: '3715',
        label: 'Kirsehir',
        country_id: '223',
      },
      {
        id: '3716',
        label: 'Kocaeli',
        country_id: '223',
      },
      {
        id: '3717',
        label: 'Konya',
        country_id: '223',
      },
      {
        id: '3718',
        label: 'Kutahya',
        country_id: '223',
      },
      {
        id: '3719',
        label: 'Lefkosa',
        country_id: '223',
      },
      {
        id: '3720',
        label: 'Malatya',
        country_id: '223',
      },
      {
        id: '3721',
        label: 'Manisa',
        country_id: '223',
      },
      {
        id: '3722',
        label: 'Mardin',
        country_id: '223',
      },
      {
        id: '3723',
        label: 'Mugla',
        country_id: '223',
      },
      {
        id: '3724',
        label: 'Mus',
        country_id: '223',
      },
      {
        id: '3725',
        label: 'Nevsehir',
        country_id: '223',
      },
      {
        id: '3726',
        label: 'Nigde',
        country_id: '223',
      },
      {
        id: '3727',
        label: 'Ordu',
        country_id: '223',
      },
      {
        id: '3728',
        label: 'Osmaniye',
        country_id: '223',
      },
      {
        id: '3729',
        label: 'Rize',
        country_id: '223',
      },
      {
        id: '3730',
        label: 'Sakarya',
        country_id: '223',
      },
      {
        id: '3731',
        label: 'Samsun',
        country_id: '223',
      },
      {
        id: '3732',
        label: 'Sanliurfa',
        country_id: '223',
      },
      {
        id: '3733',
        label: 'Siirt',
        country_id: '223',
      },
      {
        id: '3734',
        label: 'Sinop',
        country_id: '223',
      },
      {
        id: '3735',
        label: 'Sirnak',
        country_id: '223',
      },
      {
        id: '3736',
        label: 'Sivas',
        country_id: '223',
      },
      {
        id: '3737',
        label: 'Tekirdag',
        country_id: '223',
      },
      {
        id: '3738',
        label: 'Tokat',
        country_id: '223',
      },
      {
        id: '3739',
        label: 'Trabzon',
        country_id: '223',
      },
      {
        id: '3740',
        label: 'Tunceli',
        country_id: '223',
      },
      {
        id: '3741',
        label: 'Usak',
        country_id: '223',
      },
      {
        id: '3742',
        label: 'Van',
        country_id: '223',
      },
      {
        id: '3743',
        label: 'Yalova',
        country_id: '223',
      },
      {
        id: '3744',
        label: 'Yozgat',
        country_id: '223',
      },
      {
        id: '3745',
        label: 'Zonguldak',
        country_id: '223',
      },
    ],
  },
  {
    id: '224',
    sortname: 'TM',
    label: 'Turkmenistan',
    states: [
      {
        id: '3746',
        label: 'Ahal',
        country_id: '224',
      },
      {
        id: '3747',
        label: 'Asgabat',
        country_id: '224',
      },
      {
        id: '3748',
        label: 'Balkan',
        country_id: '224',
      },
      {
        id: '3749',
        label: 'Dasoguz',
        country_id: '224',
      },
      {
        id: '3750',
        label: 'Lebap',
        country_id: '224',
      },
      {
        id: '3751',
        label: 'Mari',
        country_id: '224',
      },
    ],
  },
  {
    id: '225',
    sortname: 'TC',
    label: 'Turks And Caicos Islands',
    states: [
      {
        id: '3752',
        label: 'Grand Turk',
        country_id: '225',
      },
      {
        id: '3753',
        label: 'South Caicos and East Caicos',
        country_id: '225',
      },
    ],
  },
  {
    id: '226',
    sortname: 'TV',
    label: 'Tuvalu',
    states: [
      {
        id: '3754',
        label: 'Funafuti',
        country_id: '226',
      },
      {
        id: '3755',
        label: 'Nanumanga',
        country_id: '226',
      },
      {
        id: '3756',
        label: 'Nanumea',
        country_id: '226',
      },
      {
        id: '3757',
        label: 'Niutao',
        country_id: '226',
      },
      {
        id: '3758',
        label: 'Nui',
        country_id: '226',
      },
      {
        id: '3759',
        label: 'Nukufetau',
        country_id: '226',
      },
      {
        id: '3760',
        label: 'Nukulaelae',
        country_id: '226',
      },
      {
        id: '3761',
        label: 'Vaitupu',
        country_id: '226',
      },
    ],
  },
  {
    id: '227',
    sortname: 'UG',
    label: 'Uganda',
    states: [
      {
        id: '3762',
        label: 'Central',
        country_id: '227',
      },
      {
        id: '3763',
        label: 'Eastern',
        country_id: '227',
      },
      {
        id: '3764',
        label: 'Northern',
        country_id: '227',
      },
      {
        id: '3765',
        label: 'Western',
        country_id: '227',
      },
    ],
  },
  {
    id: '228',
    sortname: 'UA',
    label: 'Ukraine',
    states: [
      {
        id: '3766',
        label: "Cherkas'ka",
        country_id: '228',
      },
      {
        id: '3767',
        label: "Chernihivs'ka",
        country_id: '228',
      },
      {
        id: '3768',
        label: "Chernivets'ka",
        country_id: '228',
      },
      {
        id: '3769',
        label: 'Crimea',
        country_id: '228',
      },
      {
        id: '3770',
        label: 'Dnipropetrovska',
        country_id: '228',
      },
      {
        id: '3771',
        label: "Donets'ka",
        country_id: '228',
      },
      {
        id: '3772',
        label: "Ivano-Frankivs'ka",
        country_id: '228',
      },
      {
        id: '3773',
        label: 'Kharkiv',
        country_id: '228',
      },
      {
        id: '3774',
        label: 'Kharkov',
        country_id: '228',
      },
      {
        id: '3775',
        label: 'Khersonska',
        country_id: '228',
      },
      {
        id: '3776',
        label: "Khmel'nyts'ka",
        country_id: '228',
      },
      {
        id: '3777',
        label: 'Kirovohrad',
        country_id: '228',
      },
      {
        id: '3778',
        label: 'Krym',
        country_id: '228',
      },
      {
        id: '3779',
        label: 'Kyyiv',
        country_id: '228',
      },
      {
        id: '3780',
        label: "Kyyivs'ka",
        country_id: '228',
      },
      {
        id: '3781',
        label: "L'vivs'ka",
        country_id: '228',
      },
      {
        id: '3782',
        label: "Luhans'ka",
        country_id: '228',
      },
      {
        id: '3783',
        label: "Mykolayivs'ka",
        country_id: '228',
      },
      {
        id: '3784',
        label: "Odes'ka",
        country_id: '228',
      },
      {
        id: '3785',
        label: 'Odessa',
        country_id: '228',
      },
      {
        id: '3786',
        label: "Poltavs'ka",
        country_id: '228',
      },
      {
        id: '3787',
        label: "Rivnens'ka",
        country_id: '228',
      },
      {
        id: '3788',
        label: "Sevastopol'",
        country_id: '228',
      },
      {
        id: '3789',
        label: "Sums'ka",
        country_id: '228',
      },
      {
        id: '3790',
        label: "Ternopil's'ka",
        country_id: '228',
      },
      {
        id: '3791',
        label: "Volyns'ka",
        country_id: '228',
      },
      {
        id: '3792',
        label: "Vynnyts'ka",
        country_id: '228',
      },
      {
        id: '3793',
        label: "Zakarpats'ka",
        country_id: '228',
      },
      {
        id: '3794',
        label: 'Zaporizhia',
        country_id: '228',
      },
      {
        id: '3795',
        label: "Zhytomyrs'ka",
        country_id: '228',
      },
    ],
  },
  {
    id: '229',
    sortname: 'AE',
    label: 'United Arab Emirates',
    states: [
      {
        id: '3796',
        label: 'Abu Zabi',
        country_id: '229',
      },
      {
        id: '3797',
        label: 'Ajman',
        country_id: '229',
      },
      {
        id: '3798',
        label: 'Dubai',
        country_id: '229',
      },
      {
        id: '3799',
        label: 'Ras al-Khaymah',
        country_id: '229',
      },
      {
        id: '3800',
        label: 'Sharjah',
        country_id: '229',
      },
      {
        id: '3801',
        label: 'Sharjha',
        country_id: '229',
      },
      {
        id: '3802',
        label: 'Umm al Qaywayn',
        country_id: '229',
      },
      {
        id: '3803',
        label: 'al-Fujayrah',
        country_id: '229',
      },
      {
        id: '3804',
        label: 'ash-Shariqah',
        country_id: '229',
      },
    ],
  },
  {
    id: '230',
    sortname: 'GB',
    label: 'United Kingdom',
    states: [
      {
        id: '3805',
        label: 'Aberdeen',
        country_id: '230',
      },
      {
        id: '3806',
        label: 'Aberdeenshire',
        country_id: '230',
      },
      {
        id: '3807',
        label: 'Argyll',
        country_id: '230',
      },
      {
        id: '3808',
        label: 'Armagh',
        country_id: '230',
      },
      {
        id: '3809',
        label: 'Bedfordshire',
        country_id: '230',
      },
      {
        id: '3810',
        label: 'Belfast',
        country_id: '230',
      },
      {
        id: '3811',
        label: 'Berkshire',
        country_id: '230',
      },
      {
        id: '3812',
        label: 'Birmingham',
        country_id: '230',
      },
      {
        id: '3813',
        label: 'Brechin',
        country_id: '230',
      },
      {
        id: '3814',
        label: 'Bridgnorth',
        country_id: '230',
      },
      {
        id: '3815',
        label: 'Bristol',
        country_id: '230',
      },
      {
        id: '3816',
        label: 'Buckinghamshire',
        country_id: '230',
      },
      {
        id: '3817',
        label: 'Cambridge',
        country_id: '230',
      },
      {
        id: '3818',
        label: 'Cambridgeshire',
        country_id: '230',
      },
      {
        id: '3819',
        label: 'Channel Islands',
        country_id: '230',
      },
      {
        id: '3820',
        label: 'Cheshire',
        country_id: '230',
      },
      {
        id: '3821',
        label: 'Cleveland',
        country_id: '230',
      },
      {
        id: '3822',
        label: 'Co Fermanagh',
        country_id: '230',
      },
      {
        id: '3823',
        label: 'Conwy',
        country_id: '230',
      },
      {
        id: '3824',
        label: 'Cornwall',
        country_id: '230',
      },
      {
        id: '3825',
        label: 'Coventry',
        country_id: '230',
      },
      {
        id: '3826',
        label: 'Craven Arms',
        country_id: '230',
      },
      {
        id: '3827',
        label: 'Cumbria',
        country_id: '230',
      },
      {
        id: '3828',
        label: 'Denbighshire',
        country_id: '230',
      },
      {
        id: '3829',
        label: 'Derby',
        country_id: '230',
      },
      {
        id: '3830',
        label: 'Derbyshire',
        country_id: '230',
      },
      {
        id: '3831',
        label: 'Devon',
        country_id: '230',
      },
      {
        id: '3832',
        label: 'Dial Code Dungannon',
        country_id: '230',
      },
      {
        id: '3833',
        label: 'Didcot',
        country_id: '230',
      },
      {
        id: '3834',
        label: 'Dorset',
        country_id: '230',
      },
      {
        id: '3835',
        label: 'Dunbartonshire',
        country_id: '230',
      },
      {
        id: '3836',
        label: 'Durham',
        country_id: '230',
      },
      {
        id: '3837',
        label: 'East Dunbartonshire',
        country_id: '230',
      },
      {
        id: '3838',
        label: 'East Lothian',
        country_id: '230',
      },
      {
        id: '3839',
        label: 'East Midlands',
        country_id: '230',
      },
      {
        id: '3840',
        label: 'East Sussex',
        country_id: '230',
      },
      {
        id: '3841',
        label: 'East Yorkshire',
        country_id: '230',
      },
      {
        id: '3842',
        label: 'England',
        country_id: '230',
      },
      {
        id: '3843',
        label: 'Essex',
        country_id: '230',
      },
      {
        id: '3844',
        label: 'Fermanagh',
        country_id: '230',
      },
      {
        id: '3845',
        label: 'Fife',
        country_id: '230',
      },
      {
        id: '3846',
        label: 'Flintshire',
        country_id: '230',
      },
      {
        id: '3847',
        label: 'Fulham',
        country_id: '230',
      },
      {
        id: '3848',
        label: 'Gainsborough',
        country_id: '230',
      },
      {
        id: '3849',
        label: 'Glocestershire',
        country_id: '230',
      },
      {
        id: '3850',
        label: 'Gwent',
        country_id: '230',
      },
      {
        id: '3851',
        label: 'Hampshire',
        country_id: '230',
      },
      {
        id: '3852',
        label: 'Hants',
        country_id: '230',
      },
      {
        id: '3853',
        label: 'Herefordshire',
        country_id: '230',
      },
      {
        id: '3854',
        label: 'Hertfordshire',
        country_id: '230',
      },
      {
        id: '3855',
        label: 'Ireland',
        country_id: '230',
      },
      {
        id: '3856',
        label: 'Isle Of Man',
        country_id: '230',
      },
      {
        id: '3857',
        label: 'Isle of Wight',
        country_id: '230',
      },
      {
        id: '3858',
        label: 'Kenford',
        country_id: '230',
      },
      {
        id: '3859',
        label: 'Kent',
        country_id: '230',
      },
      {
        id: '3860',
        label: 'Kilmarnock',
        country_id: '230',
      },
      {
        id: '3861',
        label: 'Lanarkshire',
        country_id: '230',
      },
      {
        id: '3862',
        label: 'Lancashire',
        country_id: '230',
      },
      {
        id: '3863',
        label: 'Leicestershire',
        country_id: '230',
      },
      {
        id: '3864',
        label: 'Lincolnshire',
        country_id: '230',
      },
      {
        id: '3865',
        label: 'Llanymynech',
        country_id: '230',
      },
      {
        id: '3866',
        label: 'London',
        country_id: '230',
      },
      {
        id: '3867',
        label: 'Ludlow',
        country_id: '230',
      },
      {
        id: '3868',
        label: 'Manchester',
        country_id: '230',
      },
      {
        id: '3869',
        label: 'Mayfair',
        country_id: '230',
      },
      {
        id: '3870',
        label: 'Merseyside',
        country_id: '230',
      },
      {
        id: '3871',
        label: 'Mid Glamorgan',
        country_id: '230',
      },
      {
        id: '3872',
        label: 'Middlesex',
        country_id: '230',
      },
      {
        id: '3873',
        label: 'Mildenhall',
        country_id: '230',
      },
      {
        id: '3874',
        label: 'Monmouthshire',
        country_id: '230',
      },
      {
        id: '3875',
        label: 'Newton Stewart',
        country_id: '230',
      },
      {
        id: '3876',
        label: 'Norfolk',
        country_id: '230',
      },
      {
        id: '3877',
        label: 'North Humberside',
        country_id: '230',
      },
      {
        id: '3878',
        label: 'North Yorkshire',
        country_id: '230',
      },
      {
        id: '3879',
        label: 'Northamptonshire',
        country_id: '230',
      },
      {
        id: '3880',
        label: 'Northants',
        country_id: '230',
      },
      {
        id: '3881',
        label: 'Northern Ireland',
        country_id: '230',
      },
      {
        id: '3882',
        label: 'Northumberland',
        country_id: '230',
      },
      {
        id: '3883',
        label: 'Nottinghamshire',
        country_id: '230',
      },
      {
        id: '3884',
        label: 'Oxford',
        country_id: '230',
      },
      {
        id: '3885',
        label: 'Powys',
        country_id: '230',
      },
      {
        id: '3886',
        label: 'Roos-shire',
        country_id: '230',
      },
      {
        id: '3887',
        label: 'SUSSEX',
        country_id: '230',
      },
      {
        id: '3888',
        label: 'Sark',
        country_id: '230',
      },
      {
        id: '3889',
        label: 'Scotland',
        country_id: '230',
      },
      {
        id: '3890',
        label: 'Scottish Borders',
        country_id: '230',
      },
      {
        id: '3891',
        label: 'Shropshire',
        country_id: '230',
      },
      {
        id: '3892',
        label: 'Somerset',
        country_id: '230',
      },
      {
        id: '3893',
        label: 'South Glamorgan',
        country_id: '230',
      },
      {
        id: '3894',
        label: 'South Wales',
        country_id: '230',
      },
      {
        id: '3895',
        label: 'South Yorkshire',
        country_id: '230',
      },
      {
        id: '3896',
        label: 'Southwell',
        country_id: '230',
      },
      {
        id: '3897',
        label: 'Staffordshire',
        country_id: '230',
      },
      {
        id: '3898',
        label: 'Strabane',
        country_id: '230',
      },
      {
        id: '3899',
        label: 'Suffolk',
        country_id: '230',
      },
      {
        id: '3900',
        label: 'Surrey',
        country_id: '230',
      },
      {
        id: '3901',
        label: 'Sussex',
        country_id: '230',
      },
      {
        id: '3902',
        label: 'Twickenham',
        country_id: '230',
      },
      {
        id: '3903',
        label: 'Tyne and Wear',
        country_id: '230',
      },
      {
        id: '3904',
        label: 'Tyrone',
        country_id: '230',
      },
      {
        id: '3905',
        label: 'Utah',
        country_id: '230',
      },
      {
        id: '3906',
        label: 'Wales',
        country_id: '230',
      },
      {
        id: '3907',
        label: 'Warwickshire',
        country_id: '230',
      },
      {
        id: '3908',
        label: 'West Lothian',
        country_id: '230',
      },
      {
        id: '3909',
        label: 'West Midlands',
        country_id: '230',
      },
      {
        id: '3910',
        label: 'West Sussex',
        country_id: '230',
      },
      {
        id: '3911',
        label: 'West Yorkshire',
        country_id: '230',
      },
      {
        id: '3912',
        label: 'Whissendine',
        country_id: '230',
      },
      {
        id: '3913',
        label: 'Wiltshire',
        country_id: '230',
      },
      {
        id: '3914',
        label: 'Wokingham',
        country_id: '230',
      },
      {
        id: '3915',
        label: 'Worcestershire',
        country_id: '230',
      },
      {
        id: '3916',
        label: 'Wrexham',
        country_id: '230',
      },
      {
        id: '3917',
        label: 'Wurttemberg',
        country_id: '230',
      },
      {
        id: '3918',
        label: 'Yorkshire',
        country_id: '230',
      },
    ],
  },
  {
    id: '232',
    sortname: 'UM',
    label: 'United States Minor Outlying Islands',
    states: [
      {
        id: '3979',
        label: 'United States Minor Outlying I',
        country_id: '232',
      },
    ],
  },
  {
    id: '233',
    sortname: 'UY',
    label: 'Uruguay',
    states: [
      {
        id: '3980',
        label: 'Artigas',
        country_id: '233',
      },
      {
        id: '3981',
        label: 'Canelones',
        country_id: '233',
      },
      {
        id: '3982',
        label: 'Cerro Largo',
        country_id: '233',
      },
      {
        id: '3983',
        label: 'Colonia',
        country_id: '233',
      },
      {
        id: '3984',
        label: 'Durazno',
        country_id: '233',
      },
      {
        id: '3985',
        label: 'FLorida',
        country_id: '233',
      },
      {
        id: '3986',
        label: 'Flores',
        country_id: '233',
      },
      {
        id: '3987',
        label: 'Lavalleja',
        country_id: '233',
      },
      {
        id: '3988',
        label: 'Maldonado',
        country_id: '233',
      },
      {
        id: '3989',
        label: 'Montevideo',
        country_id: '233',
      },
      {
        id: '3990',
        label: 'Paysandu',
        country_id: '233',
      },
      {
        id: '3991',
        label: 'Rio Negro',
        country_id: '233',
      },
      {
        id: '3992',
        label: 'Rivera',
        country_id: '233',
      },
      {
        id: '3993',
        label: 'Rocha',
        country_id: '233',
      },
      {
        id: '3994',
        label: 'Salto',
        country_id: '233',
      },
      {
        id: '3995',
        label: 'San Jose',
        country_id: '233',
      },
      {
        id: '3996',
        label: 'Soriano',
        country_id: '233',
      },
      {
        id: '3997',
        label: 'Tacuarembo',
        country_id: '233',
      },
      {
        id: '3998',
        label: 'Treinta y Tres',
        country_id: '233',
      },
    ],
  },
  {
    id: '234',
    sortname: 'UZ',
    label: 'Uzbekistan',
    states: [
      {
        id: '3999',
        label: 'Andijon',
        country_id: '234',
      },
      {
        id: '4000',
        label: 'Buhoro',
        country_id: '234',
      },
      {
        id: '4001',
        label: 'Buxoro Viloyati',
        country_id: '234',
      },
      {
        id: '4002',
        label: 'Cizah',
        country_id: '234',
      },
      {
        id: '4003',
        label: 'Fargona',
        country_id: '234',
      },
      {
        id: '4004',
        label: 'Horazm',
        country_id: '234',
      },
      {
        id: '4005',
        label: 'Kaskadar',
        country_id: '234',
      },
      {
        id: '4006',
        label: 'Korakalpogiston',
        country_id: '234',
      },
      {
        id: '4007',
        label: 'Namangan',
        country_id: '234',
      },
      {
        id: '4008',
        label: 'Navoi',
        country_id: '234',
      },
      {
        id: '4009',
        label: 'Samarkand',
        country_id: '234',
      },
      {
        id: '4010',
        label: 'Sirdare',
        country_id: '234',
      },
      {
        id: '4011',
        label: 'Surhondar',
        country_id: '234',
      },
      {
        id: '4012',
        label: 'Toskent',
        country_id: '234',
      },
    ],
  },
  {
    id: '235',
    sortname: 'VU',
    label: 'Vanuatu',
    states: [
      {
        id: '4013',
        label: 'Malampa',
        country_id: '235',
      },
      {
        id: '4014',
        label: 'Penama',
        country_id: '235',
      },
      {
        id: '4015',
        label: 'Sanma',
        country_id: '235',
      },
      {
        id: '4016',
        label: 'Shefa',
        country_id: '235',
      },
      {
        id: '4017',
        label: 'Tafea',
        country_id: '235',
      },
      {
        id: '4018',
        label: 'Torba',
        country_id: '235',
      },
    ],
  },
  {
    id: '236',
    sortname: 'VA',
    label: 'Vatican City State (Holy See)',
    states: [
      {
        id: '4019',
        label: 'Vatican City State (Holy See)',
        country_id: '236',
      },
    ],
  },
  {
    id: '237',
    sortname: 'VE',
    label: 'Venezuela',
    states: [
      {
        id: '4020',
        label: 'Amazonas',
        country_id: '237',
      },
      {
        id: '4021',
        label: 'Anzoategui',
        country_id: '237',
      },
      {
        id: '4022',
        label: 'Apure',
        country_id: '237',
      },
      {
        id: '4023',
        label: 'Aragua',
        country_id: '237',
      },
      {
        id: '4024',
        label: 'Barinas',
        country_id: '237',
      },
      {
        id: '4025',
        label: 'Bolivar',
        country_id: '237',
      },
      {
        id: '4026',
        label: 'Carabobo',
        country_id: '237',
      },
      {
        id: '4027',
        label: 'Cojedes',
        country_id: '237',
      },
      {
        id: '4028',
        label: 'Delta Amacuro',
        country_id: '237',
      },
      {
        id: '4029',
        label: 'Distrito Federal',
        country_id: '237',
      },
      {
        id: '4030',
        label: 'Falcon',
        country_id: '237',
      },
      {
        id: '4031',
        label: 'Guarico',
        country_id: '237',
      },
      {
        id: '4032',
        label: 'Lara',
        country_id: '237',
      },
      {
        id: '4033',
        label: 'Merida',
        country_id: '237',
      },
      {
        id: '4034',
        label: 'Miranda',
        country_id: '237',
      },
      {
        id: '4035',
        label: 'Monagas',
        country_id: '237',
      },
      {
        id: '4036',
        label: 'Nueva Esparta',
        country_id: '237',
      },
      {
        id: '4037',
        label: 'Portuguesa',
        country_id: '237',
      },
      {
        id: '4038',
        label: 'Sucre',
        country_id: '237',
      },
      {
        id: '4039',
        label: 'Tachira',
        country_id: '237',
      },
      {
        id: '4040',
        label: 'Trujillo',
        country_id: '237',
      },
      {
        id: '4041',
        label: 'Vargas',
        country_id: '237',
      },
      {
        id: '4042',
        label: 'Yaracuy',
        country_id: '237',
      },
      {
        id: '4043',
        label: 'Zulia',
        country_id: '237',
      },
    ],
  },
  {
    id: '238',
    sortname: 'VN',
    label: 'Vietnam',
    states: [
      {
        id: '4044',
        label: 'Bac Giang',
        country_id: '238',
      },
      {
        id: '4045',
        label: 'Binh Dinh',
        country_id: '238',
      },
      {
        id: '4046',
        label: 'Binh Duong',
        country_id: '238',
      },
      {
        id: '4047',
        label: 'Da Nang',
        country_id: '238',
      },
      {
        id: '4048',
        label: 'Dong Bang Song Cuu Long',
        country_id: '238',
      },
      {
        id: '4049',
        label: 'Dong Bang Song Hong',
        country_id: '238',
      },
      {
        id: '4050',
        label: 'Dong Nai',
        country_id: '238',
      },
      {
        id: '4051',
        label: 'Dong Nam Bo',
        country_id: '238',
      },
      {
        id: '4052',
        label: 'Duyen Hai Mien Trung',
        country_id: '238',
      },
      {
        id: '4053',
        label: 'Hanoi',
        country_id: '238',
      },
      {
        id: '4054',
        label: 'Hung Yen',
        country_id: '238',
      },
      {
        id: '4055',
        label: 'Khu Bon Cu',
        country_id: '238',
      },
      {
        id: '4056',
        label: 'Long An',
        country_id: '238',
      },
      {
        id: '4057',
        label: 'Mien Nui Va Trung Du',
        country_id: '238',
      },
      {
        id: '4058',
        label: 'Thai Nguyen',
        country_id: '238',
      },
      {
        id: '4059',
        label: 'Thanh Pho Ho Chi Minh',
        country_id: '238',
      },
      {
        id: '4060',
        label: 'Thu Do Ha Noi',
        country_id: '238',
      },
      {
        id: '4061',
        label: 'Tinh Can Tho',
        country_id: '238',
      },
      {
        id: '4062',
        label: 'Tinh Da Nang',
        country_id: '238',
      },
      {
        id: '4063',
        label: 'Tinh Gia Lai',
        country_id: '238',
      },
    ],
  },
  {
    id: '239',
    sortname: 'VG',
    label: 'Virgin Islands (British)',
    states: [
      {
        id: '4064',
        label: 'Anegada',
        country_id: '239',
      },
      {
        id: '4065',
        label: 'Jost van Dyke',
        country_id: '239',
      },
      {
        id: '4066',
        label: 'Tortola',
        country_id: '239',
      },
    ],
  },
  {
    id: '240',
    sortname: 'VI',
    label: 'Virgin Islands (US)',
    states: [
      {
        id: '4067',
        label: 'Saint Croix',
        country_id: '240',
      },
      {
        id: '4068',
        label: 'Saint John',
        country_id: '240',
      },
      {
        id: '4069',
        label: 'Saint Thomas',
        country_id: '240',
      },
    ],
  },
  {
    id: '241',
    sortname: 'WF',
    label: 'Wallis And Futuna Islands',
    states: [
      {
        id: '4070',
        label: 'Alo',
        country_id: '241',
      },
      {
        id: '4071',
        label: 'Singave',
        country_id: '241',
      },
      {
        id: '4072',
        label: 'Wallis',
        country_id: '241',
      },
    ],
  },
  {
    id: '242',
    sortname: 'EH',
    label: 'Western Sahara',
    states: [
      {
        id: '4073',
        label: 'Bu Jaydur',
        country_id: '242',
      },
      {
        id: '4074',
        label: 'Wad-adh-Dhahab',
        country_id: '242',
      },
      {
        id: '4075',
        label: "al-'Ayun",
        country_id: '242',
      },
      {
        id: '4076',
        label: 'as-Samarah',
        country_id: '242',
      },
    ],
  },
  {
    id: '243',
    sortname: 'YE',
    label: 'Yemen',
    states: [
      {
        id: '4077',
        label: 'Adan',
        country_id: '243',
      },
      {
        id: '4078',
        label: 'Abyan',
        country_id: '243',
      },
      {
        id: '4079',
        label: 'Dhamar',
        country_id: '243',
      },
      {
        id: '4080',
        label: 'Hadramaut',
        country_id: '243',
      },
      {
        id: '4081',
        label: 'Hajjah',
        country_id: '243',
      },
      {
        id: '4082',
        label: 'Hudaydah',
        country_id: '243',
      },
      {
        id: '4083',
        label: 'Ibb',
        country_id: '243',
      },
      {
        id: '4084',
        label: 'Lahij',
        country_id: '243',
      },
      {
        id: '4085',
        label: "Ma'rib",
        country_id: '243',
      },
      {
        id: '4086',
        label: "Madinat San'a",
        country_id: '243',
      },
      {
        id: '4087',
        label: "Sa'dah",
        country_id: '243',
      },
      {
        id: '4088',
        label: 'Sana',
        country_id: '243',
      },
      {
        id: '4089',
        label: 'Shabwah',
        country_id: '243',
      },
      {
        id: '4090',
        label: "Ta'izz",
        country_id: '243',
      },
      {
        id: '4091',
        label: 'al-Bayda',
        country_id: '243',
      },
      {
        id: '4092',
        label: 'al-Hudaydah',
        country_id: '243',
      },
      {
        id: '4093',
        label: 'al-Jawf',
        country_id: '243',
      },
      {
        id: '4094',
        label: 'al-Mahrah',
        country_id: '243',
      },
      {
        id: '4095',
        label: 'al-Mahwit',
        country_id: '243',
      },
    ],
  },
  {
    id: '244',
    sortname: 'YU',
    label: 'Yugoslavia',
    states: [
      {
        id: '4096',
        label: 'Central Serbia',
        country_id: '244',
      },
      {
        id: '4097',
        label: 'Kosovo and Metohija',
        country_id: '244',
      },
      {
        id: '4098',
        label: 'Montenegro',
        country_id: '244',
      },
      {
        id: '4099',
        label: 'Republic of Serbia',
        country_id: '244',
      },
      {
        id: '4100',
        label: 'Serbia',
        country_id: '244',
      },
      {
        id: '4101',
        label: 'Vojvodina',
        country_id: '244',
      },
    ],
  },
  {
    id: '245',
    sortname: 'ZM',
    label: 'Zambia',
    states: [
      {
        id: '4102',
        label: 'Central',
        country_id: '245',
      },
      {
        id: '4103',
        label: 'Copperbelt',
        country_id: '245',
      },
      {
        id: '4104',
        label: 'Eastern',
        country_id: '245',
      },
      {
        id: '4105',
        label: 'Luapala',
        country_id: '245',
      },
      {
        id: '4106',
        label: 'Lusaka',
        country_id: '245',
      },
      {
        id: '4107',
        label: 'North-Western',
        country_id: '245',
      },
      {
        id: '4108',
        label: 'Northern',
        country_id: '245',
      },
      {
        id: '4109',
        label: 'Southern',
        country_id: '245',
      },
      {
        id: '4110',
        label: 'Western',
        country_id: '245',
      },
    ],
  },
  {
    id: '246',
    sortname: 'ZW',
    label: 'Zimbabwe',
    states: [
      {
        id: '4111',
        label: 'Bulawayo',
        country_id: '246',
      },
      {
        id: '4112',
        label: 'Harare',
        country_id: '246',
      },
      {
        id: '4113',
        label: 'Manicaland',
        country_id: '246',
      },
      {
        id: '4114',
        label: 'Mashonaland Central',
        country_id: '246',
      },
      {
        id: '4115',
        label: 'Mashonaland East',
        country_id: '246',
      },
      {
        id: '4116',
        label: 'Mashonaland West',
        country_id: '246',
      },
      {
        id: '4117',
        label: 'Masvingo',
        country_id: '246',
      },
      {
        id: '4118',
        label: 'Matabeleland North',
        country_id: '246',
      },
      {
        id: '4119',
        label: 'Matabeleland South',
        country_id: '246',
      },
      {
        id: '4120',
        label: 'Midlands',
        country_id: '246',
      },
    ],
  },
];

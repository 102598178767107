import styled from '@emotion/styled';
import {useEffect} from 'react';

// Components
import Form from '../../ComponentLibrary/forms/oc-form';
import Fieldset from '../../ComponentLibrary/input-elements/oc-fieldset';
import TextInput from '../../ComponentLibrary/input-elements/oc-text-input';
import RadioButton from '../../ComponentLibrary/input-elements/oc-radio-button';
import RadioGroup from '../../ComponentLibrary/input-elements/oc-radio-group';
import FormButton from '../../ComponentLibrary/input-elements/oc-form-button';

const InputContainer = styled.div`
	display: flex;
	margin: 0!important;

	span {
		margin: 20px 8px 0;
	}
`;

const ErrorContainer = styled.span`
	margin: 0!important;

	span {
 		margin: 4px 0 0!important;
	}
`;

const LaborCost = styled.div`
	width: 46%!important;
`;

const Reset = styled.span`
	align-self: center;

	a {
		&:hover {
			cursor: pointer;
		}
	}
`;

const CalculatorForm = ({formSubmit, inputChange, post, reset, showReset}) => {
	
	return (
		<>
			<p>Estimate how much rebar you need for your concrete slab on grade by entering the dimensions, desired rebar spacing and your labor costs below.</p>

			<Form onSubmit={formSubmit}>
			  <Fieldset  multipleFields={true} legend="Slab Dimensions (ft)" >
			  	<InputContainer>
			  		<ErrorContainer>
			    		<TextInput name="length" placeholder="Length" touched={true} error={post.lengthError} onChange={inputChange} value={post.length} />
			    	</ErrorContainer>
			    	<span>X</span>
			    	<ErrorContainer>
			    		<TextInput name="width" placeholder="Width" touched={true} error={post.widthError} onChange={inputChange} value={post.width} />
			    	</ErrorContainer>
			    </InputContainer>
			  </Fieldset>

			  <Fieldset horizontal={true} legend="Rebar Spacing (in)" onChange={inputChange}>
			    <RadioButton name="spacing" id="12" value="12" checked={post.spacing == '12'} >12"</RadioButton>
			    <RadioButton name="spacing" id="18" value="18" checked={post.spacing == '18'} >18"</RadioButton>
			  </Fieldset>

			  <Fieldset legend="Labor Rate (per hour)" helperTextBlock={true} helperText="The average hourly labor cost will be used if no input is entered.">
			  	<LaborCost>
			    	<TextInput name="laborCost" placeholder="Labor Cost" onChange={inputChange} value={post.laborCost} />
			    </LaborCost>
			  </Fieldset>

			  <Fieldset columns={true}>
			  	<FormButton buttonType="submit" data-track="form-submit" data-track-form-name="PinkBar vs Steel" data-track-form-type="Calculator" data-track-form-details="[laborCost]">
			  		Calculate
			  	</FormButton>

			  	{ showReset &&
				  	<Reset>
				  		<a onClick={reset} data-track="reset">Reset</a>
				  	</Reset>
				  }
			  </Fieldset>
			</Form>
		</>
	)
};

export default CalculatorForm;
import { jsx, css } from '@emotion/react';
import AlertMessage from '../../OC/oc-alert-message';

export default ({ moduleName, selectedSize, sizesHash }) => {
  let selection = selectedSize;
  let message;

  if (moduleName === 'CardSet' || moduleName === 'Category Features' || moduleName === 'Media Object Set') {
    if (!selectedSize) { return <></> }

    selection = selectedSize['selection'];

    if (moduleName && selection && sizesHash && sizesHash[selection]) {
      message = `Images for ${sizesHash && sizesHash[selection][0]} ${moduleName?.toLowerCase()} should be a <b>minimum width of ${sizesHash && sizesHash[selection][1]}</b>.`;
    } else { return <></> }
  } else if (moduleName === 'BasicContent') {
    message = 'Images for banner image should be a <b>minimum width of 2340px.</b>'
  } else if (moduleName === 'Hero') {
    message = 'Images for Hero should be a <b>minimum width of 2680px.</b>';
  } else if (moduleName === 'Small Hero') {
    message = 'Images for Small Image Hero should have <b>minimum dimensions of 500x370px.</b>';
  } else if (moduleName === 'Gallery') {
    message = 'Images for Gallery should be a <b>minimum width of 1800px.</b>';
  } else if (moduleName === 'Featured List') {
    message = 'Images should be a <b>minimum of 176x176px.</b>'
  } else if (moduleName === 'Card') {
    message = 'Images for cards should be a <b>minimum of 740x370px.</b>'
  } else if (moduleName === 'Full Bleed Content') {
    message = 'Images for Full Bleed Content should be a <b>minimum width of 2340px.</b>'
  } else if (moduleName === 'Block Link') {
    message = 'Images for Block Link should be a <b>minimum 1140x760px.</b>'
  } else {
    if (moduleName && selection && sizesHash && sizesHash[selection]) {
      message = `Images for ${sizesHash && sizesHash[selection][0]} ${moduleName?.toLowerCase()} should be a <b>minimum width of ${sizesHash && sizesHash[selection][1]}.</b>`;
    } else { return <></> }
  }

  return <div>
    <AlertMessage
      message={`<b>Note:</b> ${message}`}
      dismissable={true}
      type='info'
      alertIcon={true}
      textSize="13px"
      style={{ marginBottom: '12px' }}
    />
  </div>
};

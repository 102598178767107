import { ui, select, transform, when, s } from '@owenscorning/pcb.alpha';
import _ from 'lodash';
import FooterComponent from '../../../OC/footer';
import Content from './Content';


const iconFilter = {
  facebook: 'Facebook',
  instagram: 'Instagram',
  youtube: 'YouTube',
  linkedin: 'LinkedIn',
  twitter: 'Twitter'
}

const ColumnMeta = {
  details: {
    title: ui`Text`({ label: 'Column Header' }),
    always_expanded: ui`Switch`({ label: 'Always Expanded - Mobile', default: false }),
    [s._]: ui`Tip`.of('allows you to keep column uncollapse in mobile view. This is usually used on columns that only contains icons')
  },
};


const FooterItemMeta = {
  details: ui`Form`.of({
    is_icon: ui`Switch`({ label: 'Replace with icon(s)?', default: false }),
    title: ui`Text`({ label: 'Title', default: 'Item', visible: when`../is_icon`.is.equal.to(false) }),
    subheading: ui`Text`({ label: 'Subheading', visible: when`../is_icon`.is.equal.to(true) }),
    url: ui`Text`({ label: 'Text Link URL', default: '#',visible: when`../is_icon`.is.equal.to(false) }),
    iconList: ui`List`.of(
      ui`Form`.of({
        selected: ui`Choices`.of(iconFilter)({
          label: 'Social Media Icons',
          mode: ui`Choices/Mode/Dropdown`,
        }),
        url: ui`Text`({ label: 'Text Link URL',default: select`../selected`}),
      })({
        label: 'Filtering'
      })
    )({
      singular: 'Icon',
      title: 'title',
      label: 'Icon',
      visible: when`../is_icon`.is.equal.to(true)
    })
  })
}

const CopyrightMeta  = ui`Form`.of({
  url: ui`Text`({
    label: 'Copyright page url/link',
    default: '/en-us/copyright'
  }),
  [s._]: ui`Tip`.of('Leave "Copyright page url/link" blank to use the default "/copyright" path'),
  paragraph: ui`Text`({
    label: 'Copyright statement',
    default: 'The color PINK is a registered trademark of Owens Corning. THE PINK PANTHER™ & © 1964–2022 Metro-Goldwyn-Mayer Studios Inc. All Rights Reserved.'
  }),
})({
  label: 'Copyright Registration Section'
})

const quickLinksMeta = (side) => {
  const defaults = {
    right: [{ title: 'Unsubscribe', url: '/en-us/unsubscribe' }, { title: 'Terms of Use', url: '/en-us/terms-of-use' }, { title: 'Privacy Policy', url: '/en-us/privacy-policy' }],
    left: [{ title: 'Contact Us', url: 'https://www.owenscorning.com/en-us/contact-us' }, { title: 'Careers / Jobs', url: 'https://jobs.owenscorning.com/' }, { title: 'Investors', url: 'http://investor.owenscorning.com/investor-relations/default.aspx' }]
  }
  return (
    ui`List/Item`.of(
      ui`List`.of({
        title: ui`Text`({ label: 'Title' }),
        behavior: ui`Choices`.of({
          link: "Go to URL",
          function: "Trigger Function"
        })({
          label: "Link Behavior",
          default: "link"
        }),
        url: ui`Text`({
          label: 'URL',
          default: '#',
          visible: when`../behavior`.is.equal.to("link")
        }),
        functionName: ui`Choices`.of(
          Object.keys(PB_FUNCTIONS)
        )({
          label: "Function",
          default: Object.keys(PB_FUNCTIONS)[0],
          mode: "dropdown",
          visible: when`../behavior`.is.equal.to("function")
        })
      })({
        min: 1, max: 4,
        singular: 'Link',
        title: 'title',
        default: defaults[side]
      })
    )({
      title: `Bottom Quick Links (${side})`
    })
  )
}

const Footer = Content(
  'Footer',
  (UI) => ({
    version: '0.1',
    displayName: 'Footer',
    usage: 'type',
    pathTip: 'All pages at and below the selected path will use this footer.  If you do not see a path you want, please create a page first.',
    variables: {
      focus: { phase: 'list' }
    },
    sidebar: {
      builder: select`~focus/phase`.from({
        list: {
          header: ui`Form`.of({
            has_header:  ui`Switch`({ label: 'Add a Main Title to Footer?' }),
            [s._]: ui`Tip`.of(' Allows you to add a title to the Footer'),
            title: ui`Text`({
              label: 'Footer Main Title',
              default: 'Footer title',
              visible: when`../has_header`.is.equal.to(true)
             }),
          }),
          items: ui`List`.of({
            children: ui`List`({
              title: (item, index) => !item.details.is_icon? index : item.details.subheading || 'Icon(s)',
              singular: 'Item',
              minimal: true,
              onClick: Footer.Column,
              onAdd: (add) => add(Board.Evaluate(FooterItemMeta))
            })
          })({
            title: 'details/title',
            singular: 'Footer Column',
            min: 1, max: 6,
            onAdd: (add) => add(Board.Evaluate(ColumnMeta)),
            actions: [
              {
                label: 'Settings',
                icon: 'cogs',
                onClick: Footer.Item
              },
            ],
            startOpened: select`~lastOpenedSection`
          }),
          quickLinks: {
            left: quickLinksMeta('left'),
            right: quickLinksMeta('right')
          },
          copyright: CopyrightMeta
        },
        item: {
          heading: ui`Heading`({
            subheading: Footer.Item.Title,
            heading: 'Footer Item Settings',
            onBack: Footer.Back
          }),
          'items/~focus:item': ui`Form`.of(ColumnMeta)
        },
        column: {
          heading: ui`Heading`({
            subheading: Footer.Item.Title,
            heading: Footer.Column.Title,
            onBack: Footer.Back
          }),
          'items/~focus:item/children/~focus:column': ui`Form`.of(FooterItemMeta)
        }

      }),
    },
    view: Footer.Renderer(UI),
  })
);

Footer.Back = () => Board.select({ focus: { phase: 'list' }, lastOpenedSection: Board.focus.section });

Footer.Item = (item) => {
  Board.select({ focus: { phase: 'item', item } })};

Footer.Item.Title = when`../items/~focus:item/details/title`.is.present
  .then(select`../items/~focus:item/details/title`)
  .otherwise(transform`~focus:item`((index) => `Footer Item ${ parseInt(index) + 1 }`));

Footer.Column = (column) => {
 return Board.select({ focus: { phase: 'column', item: Board.Path.at(-2), column } })
};

Footer.Column.Title = when`../items/~focus:item/children/~focus:column/details/title`.is.present
  .then(select`../items/~focus:item/children/~focus:column/details/title`)
  .otherwise(transform`~focus:column`((index) => `Column ${ parseInt(index) + 1 }`));


Footer.Renderer = (UI, parameters={}) => ({
  contents: ({ value }) => (
    <Content.PreventClicks>
      <div style={ { height: '1000px' } }>
        <FooterComponent
        headerDetails={ value?.header}
        footerItems={ value?.items}
        quickLinks={value?.quickLinks}
        copyright={value?.copyright}
        />
      </div>
    </Content.PreventClicks>
  )
});

export default Footer;

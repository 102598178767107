import Loads from "./fields/floor_designer/Loads";
import LoadType from './fields/floor_designer/LoadType';
import Name from './fields/Name';
import UsAddressField from './fields/address/us_address';
import UsSmartyAddressField from './fields/address/us_smarty';
import AddressField from './fields/address';
import AddressStateSelect from "./fields/address/AddressStateSelect";

export default {
  "loads": Loads,
  "load_type": LoadType,
  "name": Name,
  "us_address": UsAddressField,
  "smarty_us_address": UsSmartyAddressField,
  "address": AddressField,
  "state_select": AddressStateSelect
}

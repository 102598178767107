import AlertMessage from '../oc-alert-message';

const CMSAlertMessage = ({ className, dismissable = false, message, title, type = "black" }) => {
  return (
    <AlertMessage
      alertIcon={true}
      className={`page-alert ${className}`.trim()}
      dismissable={dismissable}
      message={message}
      title={title}
      type={type}
    />
  )
};

export default CMSAlertMessage;

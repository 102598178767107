let OVERRIDDEN = false;
export default {
  scope: ({ type, mode }) => {
    // skipped for now, gonna handle in the same place so leaving here for reference

    // if (!OVERRIDDEN && type == 'Article' && mode == 'view') {
    //   const override = document.createElement("style");

    //   override.textContent = `body {
    //     font-size: 18px;
    //     line-height: 23px;

    //     @media (${ UI.Viewport.to.Tablet }) {
    //       font-size: 16px;
    //       line-height: 20px;
    //     }
    //   }`;

    //   console.log('OVERRIDING BODY COPY:', override.textContent);

    //   document.head.appendChild(override);
    //   OVERRIDDEN = true;

    //   return true;
    // }

    // return OVERRIDDEN;
  }
};

import {jsx, css} from '@emotion/react';
import _ from 'lodash';
import styled from "@emotion/styled";
import Modal from '../Modal';
import AlertBar from '../OC/oc-alert-bar';
import {useState, useEffect} from "react";
import Cta from '../OC/oc-cta';
import { store } from '../Store';
import {Provider} from "react-redux";
import {connectFeatureFlag} from "./FeatureFlagConnectors.jsx";

const ColoredLine = styled.hr`
  border-top: 0.1px solid black;
`
const MainHeading = styled.p`
   font-size: 24px;
   margin-top: 0px;
   font-weight: bold;
`;
const SubHeading = styled.p`
  font-size: 12px;
  margin-top: 0px;
  padding-top: 0px;
`;
const CtaContainer = styled.span`
  padding-right: 20px;
`
const Link = styled.a`
  cursor: pointer;
`
const FeatureName = styled.span`
  padding-right: 16px;
`

const FeatureFlagAlert = connectFeatureFlag(({previewFeatureFlags, disableAll,disableKey}) => {
  const [ModalOpen, ModalOpenChangeState] = useState(false);
  const [disable, setDisable] = useState(false);
  return(
    previewFeatureFlags.length > 0 && <div>
        <AlertBar themeColor="orange">
          Viewing Unpublished Content. For internal use only.&nbsp;
          <Link onClick={() => ModalOpenChangeState(true)}>Manage Content Options</Link>
        </AlertBar>

        <Modal open={ModalOpen} onClose={() => ModalOpenChangeState(false)}>
          <MainHeading>Manage Content Options</MainHeading>
          <SubHeading> To disable any active feature flag, click the corresponding disable link.</SubHeading>
          {previewFeatureFlags.map((n) => (
            <p>
              <FeatureName> {_.startCase(n)}</FeatureName>
              <Link onClick={() => {disableKey(n); setDisable(true);}}>Disable</Link>
            </p>
          ))}
          <ColoredLine/>
          <CtaContainer>
            <Cta color="grey" onClick={() => ModalOpenChangeState (false)}> Close </Cta>
          </CtaContainer>
          <Cta color="pink" disabled={disable} onClick={() => {disableAll(); setDisable(true);}}> Disable all </Cta>
        </Modal>
          </div>
  )
})

const ProvidedFeatureFlagAlert = (props) => (
  <Provider store={ store }>
    <FeatureFlagAlert {...props}/>
  </Provider>
);

export default ProvidedFeatureFlagAlert;

import { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import _ from "lodash";
import qs from 'qs';

// Assets
const ridgeVentImage = "https://imagecdn.owenscorning.com/image/upload/v1705516774/roofing/roofing-components/ridge-vent-no-words.jpg";
const roofVentImage = "https://imagecdn.owenscorning.com/image/upload/v1705516774/roofing/roofing-components/roof-vent-no-words.jpg";

import { connectLocation } from "../../location/LocationConnectors";
import Label from "../../ComponentLibrary/input-elements/oc-label";
import NumberInput from "../../ComponentLibrary/input-elements/oc-numeric-input";
import RadioGroup from "../../ComponentLibrary/input-elements/oc-radio-group";
import FormButton from "../../ComponentLibrary/input-elements/oc-form-button";
import Modal from "../../Modal";
import Product from "./Product";
import cms_api from '../../../cms_api';

// Styled Components
const InlineBlock = styled.div`
  display: inline-block;
  position: relative;
`;

const Container = styled(InlineBlock)`
  width: 100%;
`;

const Calculator = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
`;

const Form = styled(InlineBlock)`
  background-color: #E6E6E6;
  margin-bottom: 32px;
  padding: 24px 12px;
  width: 100%;

  @media screen and (min-width: 768px) {
    width: calc((100% / 3) - 15px);
  }

  @media screen and (min-width: 1200px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const FormSection = styled(InlineBlock)`
  margin-bottom: 32px;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }

  h4 {
    border-bottom: 1px solid #000000;
    font-size: 18px;
    margin-bottom: 8px;
    padding-bottom: 4px;

    @media screen and (min-width: 1200px) {
      font-size: 20px;
    }
  }

  p {
    font-size: 14px;
    margin-bottom: 8px;
    margin-top: 0;

    &:last-of-type {
      margin-bottom: 16px;
    }
  }
`;

const InputContainer = styled(InlineBlock)`
  margin-bottom: 16px;
  width: 100%;

  label {
    span {
      margin-right: 0;
    }
  }
`;

const RadioContainer = styled(RadioGroup)`
  margin-bottom: 8px;
`;

const Submit = styled(FormButton)`
  margin-top: 16px;
`;

const Divider = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 16px;
  position: relative;

  span {
    display: inline-block;
    position: relative;

    &.bar {
      background-color: #A8A8A8;
      flex-grow: 1;
      height: 1px;
    }

    &.badge {
      background-color: #585858;
      border-radius: 100%;
      color: #FFFFFF;
      margin-left: 10px;
      margin-right: 10px;
      padding: 6px;
    }
  }
`;

const Dimensions = styled.div`
  align-items: flex-end;
  display: flex;
  position: relative;

  strong {
    font-size: 18px;
    padding-bottom: 30px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  position: relative;
`;

const VentImage = styled.div`
  background-image: url('${props => props.url}');
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  position: relative;
  width: calc(50% - 4px);
  height: 22vw;
  display: flex;
  align-items: end;
  justify-content: end;
  text-align: right;
  padding-right: 4px;
  padding-bottom: 8px;
  color: white;
  font-size: 12px;
  line-height: 14px;
  text-shadow: 1px 1px 2px black;

  @media screen and (min-width: 768px) {
    height: 6vw;
    max-height: 54px;
    font-size: 9px;
    line-height: 12px;
  }

  @media screen and (min-width: 1200px) {
    height: 6vw;
    max-height: 88px;
    padding-right: 4px;
    padding-bottom: 8px;
    font-size: 12px;
    line-height: 14px;
  }
`;

const Result = styled(InlineBlock)`
  margin-bottom: 32px;
  width: 100%;

  @media screen and (min-width: 768px) {
    width: calc(((100% / 3) * 2) - 15px);
  }
`;

const ResultSection = styled(InlineBlock)`
  padding-bottom: 16px;
  width: 100%;

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &, &.border-bottom {
    border-bottom: 2px solid #000000;
    margin-bottom: 32px;
  }

  .pink {
    color: #D40F7D;
  }

  h4, p {
    margin-bottom: 8px;
  }

  h4 {
    font-size: 18px;

    @media screen and (min-width: 1200px) {
      font-size: 20px;
    }

    &.result-description {
      margin-bottom: 16px;
    }
  }

  p {
    margin-top: 0;
  }
`;

const MeasurementContainer = styled.div`
  display: flex;
  position: relative;
`;

const Measurement = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 16px;
  position: relative;
  width: 50%;

  h2 {
    align-items: flex-end;
    display: flex;
    margin-bottom: 12px;

    strong {
      font-family: "OCRoboto";
      font-size: 16px;
      line-height: 18px;
      margin-left: 4px;
      text-transform: lowercase;
    }
  }

  > strong {
    margin-bottom: 4px;
  }

  > span {
    font-size: 14px;
  }
`;

const ResultHeading = styled.h4`
  font-size: 22px;

  @media screen and (min-width: 1200px) {
    font-size: 24px;
  }
`;

const Underline = styled.span`
  text-decoration: underline;
`;

const ModalTrigger = styled.a`
  cursor: pointer;
  display: inline-block;
`;

const Li = styled.li`
  padding-bottom: 4px;
  padding-top: 4px;
`;

const VentilationCalculator = connectLocation(({ locale, t }) => {
  const [formData, setFormData] = useState({ sqft: "1100", length: "", width: "", sqin: "528", requirement: "150", ventType: "ridge" });
  const [exhaustProductData, setExhaustProductData] = useState({ ridge: [], roof: [] }); // Object divided by placement
  const [intakeProductData, setIntakeProductData] = useState([]); // Array
  const [modalOpen, setModalOpen] = useState(false);
  const resultRef = useRef();

  const ventRequirementOptions = [
    { text: t('roofing.ventilation_calculator.requirements.value_1'), value: "150" },
    { text: t('roofing.ventilation_calculator.requirements.value_2'), value: "300" }
  ];

  const exhaustSystemOptions = [
    { text: t('roofing.ventilation_calculator.exhaust.ridge_vents'), value: "ridge" },
    { text: t('roofing.ventilation_calculator.exhaust.roof_vents'), value: "roof" }
  ];

  const updateFormData = (event, key) => {
    let data = _.cloneDeep(formData);
    let value = event.target.value;
    data[key] = value;

    if (key == "sqft") {
      data["length"] = "";
      data["width"] = "";
    }

    if (["length", "width"].includes(key)) {
      let length = parseInt(data["length"]) || 0;
      let width = parseInt(data["width"]) || 0;
      let sqft = length * width;

      data["sqft"] = sqft ? sqft.toString() : "";
    }

    data["sqin"] = data["sqft"] ? parseInt(((data.sqft / data.requirement) * 144) / 2) : "---";
    setFormData(data);
  };

  const submit = (event) => {
    resultRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const cmsDataUrl = `${MDMS_URL}/api/v2/cms/sites/${PB_SITE}/published`;
    const query = {
      filter: {
        type: 'Cms::Content::Product::Roofing',
        language_iso_code: locale.code,
        'metadata.redirect': 'null',
        metadata: {
          settings: {
            attributes: {
              product_type: ['exhaust-ventilation', 'intake-ventilation'],
            },
            availability: {
              status: ['active', 'external']
            }
          }
        }
      },
      fields: {
        '*': 'contents,metadata,route'
      }
    };

    cms_api.get(query).then((responseJSON) => {
      let productData = responseJSON?.filter(product => product.metadata.settings.attributes.calculator.nfva.length);
      let [exhaustProducts, intakeProducts] = _.partition(
        productData, (product) => (product.metadata.settings.attributes.product_type == "exhaust-ventilation")
      );
      setExhaustProductData(_.groupBy(exhaustProducts, (product) => (product.metadata.settings.attributes.calculator.placement)));
      setIntakeProductData(intakeProducts);
    }).catch(error => {
      console.log('error', error);
    });
  }, []);

  return (
    <Container>
      <p>{t('roofing.ventilation_calculator.paragraph')}</p>

      <Calculator>
        <Form>
          <FormSection>
            <h4>{t('roofing.ventilation_calculator.square_footage.title')}</h4>
            <p>{t('roofing.ventilation_calculator.square_footage.description')}</p>
            <InputContainer>
              <NumberInput
                label={t('roofing.ventilation_calculator.square_footage.labels.square_footage')}
                onChange={(event) => updateFormData(event, "sqft")}
                placeholder="Ex: 1100"
                value={formData["sqft"]}
              />
            </InputContainer>

            <Divider>
              <span className="bar"></span>
              <span className="badge">{t('roofing.ventilation_calculator.or')}</span>
              <span className="bar"></span>
            </Divider>

            <Label text={t('roofing.ventilation_calculator.square_footage.labels.dimensions')} />
            <Dimensions>
              <InputContainer>
              <NumberInput
                label={t('roofing.ventilation_calculator.square_footage.labels.length')}
                onChange={(event) => updateFormData(event, "length")}
                value={formData["length"]}
              />
              </InputContainer>
              <strong>X</strong>
              <InputContainer>
                <NumberInput
                  label={t('roofing.ventilation_calculator.square_footage.labels.width')}
                  onChange={(event) => updateFormData(event, "width")}
                  value={formData["width"]}
                />
              </InputContainer>
            </Dimensions>
          </FormSection>

          <FormSection>
            <h4>{t('roofing.ventilation_calculator.requirements.title')}</h4>
            <p><span dangerouslySetInnerHTML={{ __html: t('roofing.ventilation_calculator.requirements.description', { guideline: t('roofing.ventilation_calculator.requirements.underline') }) }} />.  <ModalTrigger onClick={() => setModalOpen(true)}>{t('roofing.ventilation_calculator.requirements.learn_more')}</ModalTrigger></p>

            <RadioContainer
              defaultValue={formData["requirement"]}
              name="vent-calc-requirement"
              onChange={(event) => updateFormData(event, "requirement")}
              options={ventRequirementOptions}
              vertical
            />
          </FormSection>

          <FormSection>
            <h4>{t('roofing.ventilation_calculator.exhaust.title')}</h4>
            <p>{t('roofing.ventilation_calculator.exhaust.description')}</p>
            <ImageContainer>
              <VentImage
                aria-label="Arrow representing airflow extending from vent located at roof peak"
                role="img"
                url={ridgeVentImage}
              >
                {t('roofing.ventilation_calculator.exhaust.ridge_vents')}
              </VentImage>
              <VentImage
                aria-label="Arrow representing airflow extending from vent located mid-roof"
                role="img"
                url={roofVentImage}
              >
                {t('roofing.ventilation_calculator.exhaust.roof_vents')}
              </VentImage>
            </ImageContainer>
            <p>{t('roofing.ventilation_calculator.exhaust.select_label')}:</p>

            <RadioContainer
              defaultValue={formData["ventType"]}
              name="vent-calc-vent-type"
              onChange={(event) => updateFormData(event, "ventType")}
              options={exhaustSystemOptions}
              vertical
            />

            <Submit
              data-track="form-submit"
              data-track-form-name="Ventilation Calculator"
              data-track-form-details={`${formData.sqft}:${formData.requirement}:${formData.ventType}`}
              disabled={!formData["sqft"]?.length}
              onClick={(event) => submit(event)}
            >
              {t('roofing.ventilation_calculator.view_results')}
            </Submit>
          </FormSection>
        </Form>

        <Result ref={resultRef}>
          <ResultSection className="border-bottom">
            {!!parseInt(formData["sqin"]) &&
            <h4 className="result-description" dangerouslySetInnerHTML={{ __html: t('roofing.ventilation_calculator.results.based_on', { square_footage: formData["sqft"], guideline: formData["requirement"] }) }} />
            }
            <MeasurementContainer>
              <Measurement>
                <h2 className="pink">{formData["sqin"]} <strong>{t('roofing.ventilation_calculator.results.sq_in')}</strong></h2>
                <strong>{t('roofing.ventilation_calculator.results.exhaust')}</strong>
                <span>({t('roofing.ventilation_calculator.results.near_ridge')})</span>
              </Measurement>
              <Measurement>
                <h2 className="pink">{formData["sqin"]} <strong>{t('roofing.ventilation_calculator.results.sq_in')}</strong></h2>
                <strong>{t('roofing.ventilation_calculator.results.intake')}</strong>
                <span>({t('roofing.ventilation_calculator.results.on_soffits')})</span>
              </Measurement>
            </MeasurementContainer>
          </ResultSection>

          {!!parseInt(formData["sqin"]) &&
            <>
              <ResultSection>
                <ResultHeading>{t('roofing.ventilation_calculator.results.exhaust_title')}</ResultHeading>
                <p dangerouslySetInnerHTML={{ __html: `${t('roofing.ventilation_calculator.results.exhaust_subtitle', { exhaust: formData['sqin'] })}:` }} />
                {exhaustProductData[formData.ventType]?.map(product => (
                  <Product key={`product-${product.id}`} product={product} formResult={formData} />
                ))}
              </ResultSection>

              <ResultSection>
                <ResultHeading>{t('roofing.ventilation_calculator.results.intake_title')}</ResultHeading>
                <p dangerouslySetInnerHTML={{ __html: `${t('roofing.ventilation_calculator.results.intake_subtitle', { intake: formData['sqin'] })}:` }} />
                {intakeProductData?.map(product => (
                  <Product key={`product-${product.id}`} product={product} formResult={formData} />
                ))}
              </ResultSection>
            </>
          }
        </Result>
      </Calculator>

      <Modal
        data={{ "data-am-region": "Ventilation Calculator 1/150 Guideline Overlay" }}
        name="Ventilation Calculator Modal"
        onClose={() => setModalOpen(false)}
        open={modalOpen}
      >
        <h3>{t('roofing.ventilation_calculator.modal.title')}</h3>
        <p>{t('roofing.ventilation_calculator.modal.description')}:</p>
        <ul>
          <Li>{t('roofing.ventilation_calculator.modal.method_1')}.</Li>
          <Li>{t('roofing.ventilation_calculator.modal.method_2')}.</Li>
        </ul>
        <p>{t('roofing.ventilation_calculator.modal.footnote')}.</p>
      </Modal>
    </Container>
  );
});

export default VentilationCalculator;

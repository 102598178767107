import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import PropTypes from "prop-types";
import FilterList from './oc-filter-list'
import ResultList from './oc-result-list'
import FilterableListContainer from '../layout/FilterableList/FilterableListContainer';
import TabContentContainer from "../layout/FilterableList/TabContentContainer";
import { isOptionSelected } from '../../helpers/oc-filter-list-helper';
import {connectLocation} from "../../location/LocationConnectors";
import { connectURLSearchParams } from '../../../redux/connectors/URLSearchParamConnector';
import { TABS } from '../layout/FilterableList/FilterableListContainer';

const defaultPageSize = 20;

// TODO assumption is filter option has "value", maybe we allow a getter there too

const wrapValueInArray = (value) => (
  Array.isArray(value) ? value : [value]
);

const defaultGetter = (item, attribute) => _.get(item, attribute);

const getValueFromDataset = (dataset, propertyPath) => {
  if (typeof dataset !== 'object') {
    return null; // or some default value
  }

  if (!propertyPath) {
    // If propertyPath is not provided, return the entire dataset
    return dataset;
  }

  const pathArray = Array.isArray(propertyPath) ? propertyPath : [propertyPath];

  let currentValue = dataset;

  for (const propertyName of pathArray) {
    if (!currentValue || typeof currentValue !== 'object') {
      return dataset;
    }

    currentValue = currentValue[propertyName];

    if (currentValue === undefined) {
      return null;
    }
  }

  return currentValue;
}

const defaultMatchersByFilterThenValueTypes = {
  multiple: {
    multiple: (item, filterValue, attribute, itemValue) => (
      wrapValueInArray(filterValue).some(filter => wrapValueInArray(itemValue).includes(filter))
    ),
    singular: (item, filterValue, attribute, itemValue) => (
      wrapValueInArray(filterValue).includes(itemValue)
    ),
    none: () => true,
  },
  singular: {
    multiple: (item, filterValue, attribute, itemValue) => (itemValue || []).includes(filterValue),
    singular: (item, filterValue, attribute, itemValue) => itemValue === filterValue,
    none: () => true,
    search: (item, filterValue, attribute, itemValue, filter) => {
      let searchValue = filterValue?.trim()?.toLowerCase();
      return !searchValue
        ? true
        : filter.fields?.some(field => {
            let fieldValue = getValueFromDataset(item, field);
            if(!fieldValue) return false;
            return Array.isArray(fieldValue)
              ? fieldValue.some(n => n.toLowerCase().includes(searchValue))
              : fieldValue?.toLowerCase().includes(searchValue)
          })

    }

  },
}

const getGetter = (filter) => {
  return filter.getter || defaultGetter;
}

const getMatcher = (filter) => {
  const valueType = filter.valueType || 'none';
  const filterType = filter.component.filterValueType;
  const response = filter.matcher || defaultMatchersByFilterThenValueTypes[filterType]?.[valueType];
  if (!response)
    throw new Error(`Cannot identify filter function for ${filter.name}.  Add \`filterValueType\` with value "multiple" or "singular" to your component or supply a \`matcher\` function on the filter definition.`)
  return response;
};

const FilterListResults = connectURLSearchParams(connectLocation(({
  t,
  allowSearch,
  contentHeader,
  filters,
  lists,
  loading,
  noResultComponent,
  primaryActiveDefault = true,
  resultComponent,
  resultComponentStyle,
  resultGridSize,
  resultHeaderComponent,
  secondaryContent,
  pagination,
  countFromApi,
  appliedFilterLabel,
  tabs = null,
  filterChipsVisible = TABS.SECONDARY,
  sorts,
  updatesPathFromParams = true,
  serverSideCalculation = false,
  serverSideCalculationUpdate,
  userLocation = null,
  urlParams = {},
  setUrlParams,
  locale,
  excludeParams = [],
  showLogo=false,
}) => {

  appliedFilterLabel ||= `${t('filter_list.applied_filters')}:`;
  let primaryActive = urlParams[TABS.TAB_PARAM] ? (urlParams[TABS.TAB_PARAM] === TABS.PRIMARY) : primaryActiveDefault;
  let defaultActiveTab = urlParams[TABS.TAB_PARAM]; //required for tabs component

  const updatePathFromParams = (params) => {
    const newFilters = _.omitBy(params, v => typeof(v) === 'undefined')
    setUrlParams(newFilters);

    if (serverSideCalculation) {
      serverSideCalculationUpdate(newFilters);
    }
  }

  const updateParams = (attribute, option) => {
    let params = Object.assign({}, urlParams)
    if (filters[attribute] && (filters[attribute].component.filterValueType === 'multiple')) {
      params = updateArrayParams(params, attribute, option)
    } else {
      params = updateStringParams(params, attribute, option)
    }
    if (attribute !== 'page' && params.page && params.page !== '1') {
      params = updateStringParams(params, 'page', '1')
    }
    updatePathFromParams(params)
  }

  const resetAllFilters = () => {
    let params = _.pick(urlParams, excludeParams); // keep the excluded params
    updatePathFromParams(params);
  }

  const resetFilter = (attribute) => {
    let params = Object.assign({}, urlParams)
    delete params[attribute]
    updatePathFromParams(params)
  }

  const updateArrayParams = (params, attribute, option) => {
    const nextParams = _.cloneDeep(params);
    nextParams[attribute] = Array.wrap(nextParams[attribute])
    const hasOption = isOptionSelected(option, nextParams[attribute]);

    if (hasOption) {
      nextParams[attribute] = nextParams[attribute].filter(o => !option.includes(o));
      if (nextParams[attribute].length === 0) {
        delete nextParams[attribute];
      }
    } else {
      nextParams[attribute] ||= [];
      nextParams[attribute].push(option);
    }

    return nextParams;
  };

  const updateStringParams = (params, attribute, option) => {
    if (filters[attribute] && params[attribute] && params[attribute] === option) {
      delete params[attribute]
    } else {
      params[attribute] = option
      if (filters[attribute] && filters[attribute].onChange) { filters[attribute].onChange(option) }
    }
    return params
  }

  const matchByMatchType = (item, attribute, filterValue) => {
    const getter = getGetter(filters[attribute]);
    const matcher = getMatcher(filters[attribute]);

    return matcher(item, filterValue, attribute, getter(item, attribute), filters[attribute]);
  }

  const applyFilters = (list, filterValues) => {
    if (serverSideCalculation)
      return list || [];

    return (list || []).filter((item) => {
      return Object.keys(filterValues).every(attribute => matchByMatchType(item, attribute, filterValues[attribute]));
    })
  }



  const getFilteredList = () => {
    let listNumber = 1;

    return lists.map((list) => {
      let resultObject = { name: list.name, headerData: list.headerData };

      resultObject.data = applyFilters(list.data, appliedFilters);
      resultObject.data = sortList(resultObject.data);

      resultObject.data.map((item) => {
        item["list_number"] = listNumber++;
      });

      return resultObject;
    })
  }

  const formatMapData = (list) => {
    if (!list) return [];
    return list.flatMap((group) => group.data);
  }

  const getAppliedFilters = () => {
    const validFilters = {};
    Object.keys(urlParams).forEach(k => {
      if (!filters || filters[k] === undefined) return;
      validFilters[k] = urlParams[k];
    });
    return validFilters;
  }

  const getResultsCount = () => {
    let reducer = (count, resultGroup) => count + resultGroup.data.length
    return filteredList.reduce(reducer, 0)
  }

  const getAppliedSort = () => {
    //Provision for default sort attribute. Add 'default: true' to sort field
    let defaultSort = (sorts && sorts.fields) ? Object.keys(sorts.fields).filter(field => sorts.fields[field].default)?.[0] : null;
    return urlParams.sort || defaultSort || '';
  }

  const getSearchValue = () => {
    return (urlParams.q || '').trim()
  }

  const sortList = (listToSort) => {
    let appliedSort = getAppliedSort();
    if (!appliedSort || appliedSort.length === 0) return listToSort;
    const sortProperties = sorts.fields[appliedSort];
    if (!sortProperties) return listToSort;
    const sortAttribute = sortProperties.attribute;
    if (!sortAttribute) return listToSort; // Reset sort by passing null value against attribute
    if (sortProperties.direction === 'desc') {
      return listToSort.sort((firstEl, secondEl) => firstEl[sortAttribute] < secondEl[sortAttribute] ? 1 : -1 )
    } else {
      return listToSort.sort((firstEl, secondEl) => firstEl[sortAttribute] > secondEl[sortAttribute] ? 1 : -1)
    }
  }

  const appliedFilters = getAppliedFilters();
  const filteredList = getFilteredList();
  const totalCount = countFromApi || getResultsCount();

  if (pagination) {
    if (typeof(pagination) === 'boolean') {
      pagination = {}
    }
    pagination = {
      page: urlParams["page"] || 1,
      perPage: defaultPageSize,
      ...pagination,
    }
    const totalPages = Math.ceil((1.0*totalCount) / pagination.perPage);
    pagination = {
      updateParams,
      ...pagination,
      totalPages,
    }
  }

  const getCount = (list, attribute, filterValue) => {
    const otherFilters = _.omit(appliedFilters, attribute)
    otherFilters[attribute] = filterValue;
    const result = applyFilters(list, otherFilters).length
    return result;
  }

  const getCounts = (attribute, target, options) => {
    options.forEach((option) => {
      target[option.value] =
        lists.reduce((prev, curr) => prev + getCount(curr.data, attribute, option.value), 0);
      if (option.children) {
        getCounts(attribute, target, option.children);
      }
    })
  }

  const filterCounts = useMemo(() => {
    const counts = {};

    Object.keys(filters || {}).forEach((attribute) => {
      if (!filters[attribute].suppressCount) {
        if (serverSideCalculation) {
          counts[attribute] = filters[attribute].filterCounts
        } else {
          counts[attribute] = {};
          getCounts(attribute, counts[attribute], filters[attribute].options)
        }
      }
    })

    return counts;
  }, [lists, filters, appliedFilters])

  const mapData = formatMapData(filteredList);
  const contentHeaderProps = Object.assign(contentHeader?.props || {}, {
    count: totalCount,
    updateParams,
    urlParams,
    searchValue: (contentHeader?.props?.searchValue || getSearchValue())
  });
  const sortProps = sorts?.component ?
    {
      ...sorts.props,
      options: Object.keys(sorts.fields).map(value => ({
        value,
        displayName: sorts.fields[value].displayName,
      })),
      updateParams,
      selected: getAppliedSort(),
    } : {};

  const ContainerComponent = tabs ? TabContentContainer : FilterableListContainer
  return(
    <>
      <ContainerComponent
        allowSearch={allowSearch}
        appliedFilters={appliedFilters}
        appliedFilterLabel={appliedFilterLabel}
        filterContent={
          filters ?
            <FilterList
              filters={filters}
              appliedFilters={appliedFilters}
              filterCounts={filterCounts}
              updateParams={updateParams}
              resetFilter={resetFilter}
              loading={loading}
            /> : undefined
        }
        filterHeading={t('filter_list.filters')}
        filterReset={resetAllFilters}
        filters={filters}
        headerContent={{ component: contentHeader?.component, props: contentHeaderProps }}
        loading={loading}
        noResultContent={noResultComponent}
        primaryActiveDefault={primaryActive}
        primaryContent={
          <ResultList
            pagination={pagination}
            resultHeaderComponent={resultHeaderComponent}
            results={filteredList}
            resultComponent={resultComponent}
            resultComponentStyle={resultComponentStyle}
            resultGridSize={resultGridSize}
            appliedFilters={appliedFilters}
            locale={locale}
            showLogo={showLogo}
          />
        }
        resultCount={contentHeaderProps.count}
        secondaryContent={secondaryContent ? secondaryContent({ locations: mapData, userLocation }) : null}
        resultHeaderComponent={resultHeaderComponent}
        results={filteredList}
        tabs={tabs}
        defaultActiveTab={defaultActiveTab}
        filterChipsVisible={filterChipsVisible}
        sortContent={sorts?.component ? { component: sorts.component, props: sortProps, fields: sorts.fields } : undefined}
      />
    </>
  )
}))

FilterListResults.propTypes = {
  filters: PropTypes.object,
  lists: PropTypes.array,
  resultComponent: PropTypes.elementType,
  resultHeaderComponent: PropTypes.elementType,
  sorts: PropTypes.object,
  secondaryContent: PropTypes.elementType,
  contentHeader: PropTypes.object,
  countFromApi: PropTypes.number
};

export default (props) => (<FilterListResults {...props}/>)

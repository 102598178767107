import React, { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { css } from "@emotion/css";
import styled from '@emotion/styled';
import anime from 'animejs';

// Components
import { H3 } from './oc-h';
import RichText from './oc-rich-text';
import Text from './oc-text';
import AnimatedAttentionBarWrapper from "./shared/oc-animate-wrapper";
import AnimateTextContainer from "./shared/oc-animated-text-container";

const AttentionBarTextWrapper = styled.div`
  background: #000000;
  color: #FFFFFF;
  margin-bottom: 35px;
  padding: 20px;

  @media (min-width: 768px) {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: ${ props => props.text ? 'space-between' : 'start' };
    margin: 0 auto 30px;
    padding: 15px;
    width: 100%;
  }

  @media (min-width: 1200px) {
    margin-bottom: 70px;
    padding: 35px;
    width: 100%;
  }

  h3 {
    margin-bottom: 0;
    text-align: left;
  }

  .bar-text-container {
    width: 100%;
    padding-top: 5px;

    @media(min-width: 768px) {
      width: ${ props => props.text ? '60%' : 'auto' };
    }

    @media(min-width: 1200px) {
      width: ${ props => props.text ? '65%' : 'auto' };
    }

    p {
      color: white;
      font-size: 14px;
      margin: 10px 0 15px;

      @media(min-width: 1200px) {
        font-size: 16px;
        margin: 10px 0 0;
      }
    }

    a {
      color: white;

      &:hover {
        color: #D30E7D;
      }
    }
  }

  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6,
  .prehead {
    color: white!important;
  }

  h2, h3 {
    flex: 1 100%;

    @media (min-width: 1200px) {
      flex: none;
      margin: 0 35px 0 0;
      text-align: left;
      width: auto;
    }

    .prehead {
      color: white;
      margin-bottom: 0;
    }
  }

  .cta-container {
    display: block;
    margin: 0 auto;
    width: auto;

    @media (min-width: 768px) {
      display: flex;
    }

    @media (min-width: 1200px) {
      margin: 0;
    }
  }

  .ab-cta {
    margin: 0 auto 0;
    text-align: center;

    & + .ab-cta {
      margin: 20px auto 0;

      @media screen and (min-width: 768px) {
        margin: 0 15px;
      }
    }

    @media (min-width: 768px) {
      flex: none;
      margin: 0 15px;
      width: auto;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    .oc-cta-container {
      @media (min-width: 768px) {
        padding-top: 0;
      }
    }

    .oc-cta-button {
      font-size: 15px;
    }
  }

  ${(props) => (props.noMargin && css`
    margin-bottom: 0px !important;
  `)}
`;

const CustomRichText = styled(RichText)`
  margin-bottom: 0 !important;
`

const AttentionBarText = ({ prehead, heading, text, children, noMargin = false, className, animationProps, ...props }) => {
  const {animate, flashAnimRef, slideAnimRef } = animationProps || {animate: false, flashAnimRef: useRef(null), slideAnimRef: useRef(null)};
  const [inViewRef, inView] = useInView({
    threshold: 1,
    triggerOnce: true, // set this flag to false if you want to animate the text on every scroll into view
  });
  const animationTimeline = anime.timeline({
    autoplay: false,
  });

  useEffect(() => {
    if (animate) {
      AnimateTextContainer(inView, slideAnimRef.current, animationTimeline);
    }
  }, [inView, animate]);

  return (
    <div id="pinkbar-vs-steel-savings-estimator">
      <AnimatedAttentionBarWrapper
        wrapperComponent={AttentionBarTextWrapper}
        wrapperProps={{
          noMargin: noMargin,
          className: `flash-bar-text ${className}`,
          text: text,
        }}
        target={flashAnimRef}
        animate={animate}
        reference={inViewRef}
        inView={inView}
        animationTimeline={animationTimeline}
      >
        <div ref={slideAnimRef}>
          <H3 prehead={prehead}>
            <Text content={heading} />
          </H3>
          {text && <p><CustomRichText content={text} inline={true}/></p>}
        </div>
        <div className="cta-container">
          {children}
        </div>
      </AnimatedAttentionBarWrapper>
    </div>
  );
};

export default AttentionBarText;

import styled from '@emotion/styled';

// kind of strange to give this its own file, but it avoids circular dependencies
export const InputWrapper = styled.div`
  margin-bottom: 30px;
  label,
  input {
    ${({ error }) => (error ? 'color: #B32524; border-color: #B32524;' : '')}
  }
`;

import IconBase from './icon-base';

const IconZipFile = ({ className, fill="#000000", height="24px", hoverColor="#000000", width="28px" }) => (
  <IconBase className={className} fill={fill} height={height} hoverColor={hoverColor} viewBox="0 0 28 24" width={width}>
    <path fill="currentColor" d="M14.341 3.579c-.347-.473-.831-1.027-1.362-1.558S11.894 1.006 11.421.659C10.615.068 10.224 0 10 0H2.25C1.561 0 1 .561 1 1.25v13.5c0 .689.561 1.25 1.25 1.25h11.5c.689 0 1.25-.561 1.25-1.25V5c0-.224-.068-.615-.659-1.421m-2.07-.85c.48.48.856.912 1.134 1.271h-2.406V1.595c.359.278.792.654 1.271 1.134zM14 14.75c0 .136-.114.25-.25.25H2.25a.253.253 0 0 1-.25-.25V1.25c0-.135.115-.25.25-.25H10v3.5a.5.5 0 0 0 .5.5H14z"/>
    <path fill="currentColor" d="M4 1h2v1H4zm2 1h2v1H6zM4 3h2v1H4zm2 1h2v1H6zM4 5h2v1H4zm2 1h2v1H6zM4 7h2v1H4zm2 1h2v1H6zm-2 5.25c0 .412.338.75.75.75h2.5c.412 0 .75-.338.75-.75v-2.5a.753.753 0 0 0-.75-.75H6V9H4zM7 12v1H5v-1z"/>
  </IconBase>
);

export default IconZipFile;

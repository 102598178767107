import { ui, define } from '@owenscorning/pcb.alpha';

import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';

const Chevron = styled.span`
  display: inline-flex;
  align-self: center;
  margin-left: 10px;
  transition: margin .35s;

  .link-parent:hover &, a:hover > & {
    margin-left: 25px;
    color: ${ () => UI.Theme.colors.brand };
  }

  &:after {
    font-family: "icomoon";
    content: "\\e608";
    position: relative;
  }
`;

const Link = ({ url, ...props }) => <UI.Button
  css={
    css`
      .link-parent:hover &, &:hover { color: ${ UI.Theme.colors.brand }; }
      text-decoration: underline;
    `
  }
  action={ url }
  { ...props }
/>;

Link.Chevron = ({ text, children, ...props }) => <Link
  css={ css`
    display: inline-flex;
    text-decoration: none;
  ` }
  { ...props }
>
  { text }{ children } <Chevron />
</Link>;

Link.Parent = (props) => <Link
  className={ parent && 'link-parent' }
  css={
    css`
      color: inherit !important;
      text-decoration: inherit;
    `
  }
  { ...props }
/>

export default define`Link`('0.0.1')({
  view: Link,
  edit: ui`Form`.of({
    url: ui`Text`({label: 'Url'}),
    text: ui`Text`({label: 'Text to display'})
  })
});

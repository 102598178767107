
import { useEffect } from 'react';
import {jsx} from '@emotion/react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import {connectLocation} from '../location/LocationConnectors';
import {StepSetup, StepConfiguration} from './steps_order.jsx';

import _ from 'lodash';

import css from './styles_utilities';
import CTAButton from "../CTAButton";
import { VariableCTAButton } from './BYRConnectors';


export const mapBuilderData = (p) => {
  const segment = _.last(p.route.split('/'));
  return (
    {
      uid: segment.replaceAll(/-/g,'_'),
      name: _.get(p, 'metadata.settings.general.proper_name', '') || p.name,
      full_proper_name: _.get(p, 'metadata.settings.general.proper_name', '') || p.name,
      description: _.get(p, 'metadata.settings.general.short_description', ''),
      image: _.get(p, ['metadata', 'settings', 'images', 'images', _.get(p, 'metadata.settings.images.list_item_image'), 'file'], ''),
      slug: segment,
    }
  );
}

export const Section = ({children}) => (
  <section css={css.contentSection}>{children}</section>
)

export const StepColumns = ({children}) => (
  <div css={css.stepColumns}>{children}</div>
)

export const MoveNext = connectLocation(({t, next, styles}) => (
  <div css={styles}>
    <div><Link to={next} className="oc-cta-button" data-track="tool-advance">{t('build_your_roof.next_step')}</Link></div>
  </div>
))

export const PrevNext = connectLocation(({t, prev}) => (
  <div>
    <div><Link to={prev} className="oc-cta-button transparent" data-track="tool-advance">{t('build_your_roof.prev_step')}</Link></div>
  </div>
))

export const HeaderIcon = connectLocation(({t, what, stepHeading, cssOverride}) => {
  let text = t(`build_your_roof.header_${what}`)

  let usedCss = stepHeading ? [css.iconHeading, css.stepHeading] : css.iconHeading;
  if(cssOverride) usedCss = cssOverride;

  return (
    <h5 css={usedCss}>
      <img src={`https://imagecdn.owenscorning.com/ocimages/image/upload/q_auto,w_30/roofing/build-a-roof/icon-${what}.svg`} />
      {text}
    </h5>
  )
})

export const DataOrEmpty = ({object, path, empty="--"}) => (
  _.get(object, path, empty)
)

export const PriceWidget = ({price, header}) => {
  if(!price)
    return <span>--</span>;
  const active = (i) => <span key={i} className="active">$</span>;
  const inactive = (i) => <span key={i}>$</span>;

  return <span css={header ? css.headerPriceBadge : css.priceBadge}>{_.range(0,5).map(i => i < price ? active(i) : inactive(i) )}</span>

}

const NextButtonWithoutRouter = connectLocation(({t, history, location, overrides}) => {


  const next = () => {
    const steps = StepSetup();
    let idx = _.indexOf(steps, location.pathname.replace('/', ''));
    let nextStep = '/' + steps[idx+1];

    history.push(nextStep);
  }

  const [penultimatePath, lastPath] = StepConfiguration().map(s => s.path).slice(-2);

  if(location.pathname === lastPath) {
    return <VariableCTAButton overrides={overrides} href="#contractor" data-track="find-a-professional" data-track-professional="contractor">{t('build_your_roof.find_a_contractor')}</VariableCTAButton>
  }

  if(location.pathname == penultimatePath) {
    return <button onClick={next} className="oc-cta-button" data-track="tool-advance">{t('build_your_roof.summary')}</button>
  }



  return (
   <span><CTAButton onClick={next} data-track="tool-advance">{t('build_your_roof.next')}</CTAButton></span>
  )
})

export const NextButton = withRouter(NextButtonWithoutRouter);

const PreviousButtonWithoutRouter = connectLocation(({t, history, location}) => {

  const prev = () => {

    const steps = StepSetup();
    const idx = _.indexOf(steps, location.pathname.replace('/', ''));
    let prevStep = '/' + steps[idx-1];

    history.push(prevStep);

  }

  if(location.pathname == '/shingles') {
    return null;
  }

  return <button css={[css.mobilePrev, css.mobilePrevNext]} onClick={prev}><i className="icon-chevron-left" /> {t('build_your_roof.previous')}</button>
})

export const MobilePrevButton = withRouter(PreviousButtonWithoutRouter);

const MobileNextButtonWithoutRouter = connectLocation(({t, history, location}) => {

  const next = () => {

    const steps = StepSetup();
    console.log(steps);
    let idx = _.indexOf(steps, location.pathname.replace('/', ''));
    let nextStep = '/' + steps[idx+1];

    history.push(nextStep);
  }

  if(location.pathname == '/summary') {
    return null
  }

  return (
   <span><button css={[css.mobileNext, css.mobilePrevNext]} onClick={next} data-track="tool-advance"><i className="icon-chevron-right" /> {t('build_your_roof.next')}</button></span>
  )
})

export const MobileNextButton = withRouter(MobileNextButtonWithoutRouter);

const StepWithoutRouter = ({t, path, location, title, history, step}) => {
  if(location.pathname === path) {

    return (
      <div>
        <span css={[css.stepTab, css.currentStep]} tabIndex="0">
          <span className="stepNumber">{step}</span>
          <span>{title}</span>
          <span className="mobileProgress">{location.pathname == '/summary' ? '' : `${step} of 6`}</span>
        </span>
      </div>
    );
  }

  const steps = StepSetup();
  let currentStepIdx = _.indexOf(steps, location.pathname.replace('/', ''));
  let myIdx = _.indexOf(steps, path.replace('/', ''));

  if(myIdx < currentStepIdx) {
    const next = () => {
      history.push(path);
    }

    return (
      <span onClick={next} css={[css.stepTab, css.completedStep]} tabIndex="0" data-track="tab-click" data-track-tab-name={title}>
        <span className="completed fa fa-check"></span>
        <span>{title}</span>
      </span>
    );
  }

  return (
    <span css={css.stepTab} tabIndex="0">
      <span className="stepNumber">{step}</span>
      <span>{title}</span>
    </span>
  )
}

export const Step = withRouter(StepWithoutRouter);

export const If = ({condition, children}) => {
  if(condition)
    return children
  return null;
}
If.displayName = 'If';

export const IfElse = ({condition, children}) => {
  if(condition)
    return children[0];
  return children[1];
}
IfElse.displayName = 'IfElse';

import styled from '@emotion/styled';

import Theme from '../../../themes';
import Icon from '../icons/oc-icon';
import { connectLocation } from "../../location/LocationConnectors";

// STYLES
const ChipList = styled.div`
  display: none;
  align-items: center;

  @media(min-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const Chip = styled.span`
  align-items: center;
  background-color: #D1D1D1;
  display: inline-flex;
  font-size: 15px;
  justify-content: space-between;
  line-height: 16px;
  margin: 0 8px 8px 0;
  padding: 6px 8px;

  &:last-of-type {
    margin-right: 0;
  }
`;

const AppliedFilterLabel = styled.span`
  font-weight: 700;
  margin: 0 8px 8px 0;
`;

const Close = styled.span`
  color: #333;
  font-size: 22px;
  font-weight: 700;
  margin-left: 16px;

  &:hover{
    color: ${Theme.colors.brand};
    cursor: pointer;
  }
`;

const Reset = styled.span`
  &:hover {
    color: ${Theme.colors.brand};
    cursor: pointer;
  }
`;

// CHIP
const FilterChip = ({label, updateParams, appliedFilter}) => {
  let toggleFilter = () => {
    updateParams(Object.keys(appliedFilter.filterData)[0], Object.values(appliedFilter.filterData)[0])
  }
  return (
    <Chip>{label} <Close onClick={() => toggleFilter()}>&times;</Close></Chip>
  )
};

// CHIP LIST
const FilterChips = connectLocation(({t, filterReset, filters, appliedFilters, updateParams, appliedFilterLabel}) => {
  let appliedFiltersArray = [];
  Object.keys(appliedFilters).forEach((filterType) => {
    let filter = filters[filterType];
    if (filter.component.getChipInfo) {
      const chipInfos = filter.component.getChipInfo(filter, appliedFilters[filterType]) || [];
      appliedFiltersArray = appliedFiltersArray.concat(chipInfos.map(info => (
        {
          ...info,
          filterData: { [filterType]: info.value }
        }
      )))
    }
  })
  return (
    <ChipList>
      {(appliedFilterLabel && appliedFiltersArray.length > 0) && <AppliedFilterLabel>{appliedFilterLabel}</AppliedFilterLabel>}
      {
        appliedFiltersArray.map((filter, index) => {
          let filterLabel = filter.displayName || filter.name
          return (
            <FilterChip key={index} label={filterLabel} updateParams={updateParams} appliedFilter={filter}/>
          )
        })
      }

      { appliedFiltersArray.length > 0 && <Reset onClick={filterReset}><Icon type="repeat" /> {t("filter_list.reset")}</Reset> }
    </ChipList>
  )
});

export default FilterChips;

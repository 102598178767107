import { define } from '@owenscorning/pcb.alpha';

import React from 'react';
import { jsx, css } from '@emotion/react';

import _ from 'lodash';

const Columns = {
  Desktop: 12,
  Mobile: 4
};

const Aliases = { colors: 'back' };

const Commands = {
  center: (def, axis='x') => css`
    & > div {
      ${ axis.includes('x') && css` justify-content: center; ` }
      ${ axis.includes('y') && css` align-items: center; ` }
    }
  `,
  columns: (def, amount) => {
    const columns = def.size == 'mobile' ? Columns.Mobile : Columns.Desktop;
    if (amount == 'fit') return css` flex-grow: 1; `;
    amount = { full: columns, half: columns / 2, third: columns / 3, quarter: columns / 4, sixth: columns / 6, column: 1 }[ amount ] || amount;
    let gap = def.parent?.queries?.[def.size]?.gap;
    if (!gap) gap = def.parent?.queries?.all?.gap;
    if (!gap) gap = def.parent?.gap;
    return css`
      flex-shrink: 0;
      flex-basis: calc( ${ ( ( amount / columns ) * 100 ) + '%' } - ${ gap ? UI.Theme.Pixels(gap) : 0 }px );
    `;
  },
  column: (def) => Commands.columns(def, 'column'),
  half: (def) => Commands.columns(def, 'half'),
  third: (def) => Commands.columns(def, 'third'),
  quarter: (def) => Commands.columns(def, 'quarter'),
  sixth: (def) => Commands.columns(def, 'sixth'),
  full: (def) => Commands.columns(def, 'full'),
  fit: (def) => Commands.columns(def, 'fit'),
  fits: (def, amount) => {
    const columns = def.size == 'mobile' ? Columns.Mobile : Columns.Desktop;
    return css`
      & > div > * {
        ${
          amount > 1
            ? Commands.columns({ size: def.size, parent: def }, columns / amount)
            : Commands.full({ size: def.size, parent: def })
        }
      }
    `;
  },
  gap: (def, amount) => css`
    & > div {
      margin: -${ UI.Theme.Pixels(amount) }px -${ UI.Theme.Pixels(amount) }px 0 0;
      width: calc( 100% + ${ UI.Theme.Pixels(amount) }px );
    }
    & > div > * {
      margin: ${ UI.Theme.Pixels(amount) }px ${ UI.Theme.Pixels(amount) }px 0 0;
    }
  `
};

export default define`Box`('0.0.1')({
  batch: () => ({
    Row: (props) => <UI.Box row { ...props } />,
    Rows: (props) => <UI.Box rows { ...props } />,
    Box: UI.Theme.Taggable(
      ({ children, contents, row, rows, ...props }) => {
        const layout = React.Children.count(children) > 1;
        const types = [ (<UI.Box />).type, (<UI.Row />).type, (<UI.Rows />).type ];
        return <div
          css={ css`
            ${
              layout && css`
                &:before, &:after {
                  content: ' ';
                  display: table;
                }
              `
            }
            ${
              row && css`
                & > div {
                  display: flex;
                  flex-wrap: wrap;
                  align-items: flex-start;
                }
              `
            }
            ${
              rows && css`
                & > div {
                  display: flex;
                  flex-wrap: wrap;
                  flex-direction: column;
                }
              `
            }
          ` }
          { ...props }
        >
          {
            (row || rows) && _.isArray(children) ? <div>{
              React.Children.map(
                children,
                (child) => layout && child && !types.includes(child?.type)
                  ? ( rows ? <UI.Row>{ child }</UI.Row> : <UI.Box>{ child }</UI.Box> )
                  : child
              )
            }</div> : children
          }
        </div>;
      },
      {
        commands: Commands,
        aliases: Aliases
      }
    )
  })
});

import { Children, isValidElement } from 'react';
import styled from '@emotion/styled';

// Components
import Image from './oc-image';
import Picture from "./oc-picture";
import Text from './oc-text';

// Utilities
import placeholder_url from '../../placeholder';
import { convertToISODate } from "./PageBuilder/utilities/shared";
import { connectLocation } from "../location/LocationConnectors";

// Styled Components
const HeroContainer = styled.div`
  position: relative;
`;

const ImageContainer = styled.div`
  &, & picture, img {
    display: inline-block;
    position: relative;
    width: 100%;
  }

  img {
    object-fit: cover;
    object-position: center top;

    @media(min-width: 768px) {
      height: 300px;
    }

    @media(min-width: 1200px) {
      height: 380px;
    }
  }
`;

const HeroPageHeading = styled.div`
  padding: 16px;
  width: 100%;
  z-index: 10;

  @media(min-width: 768px) {
    background: #FFFFFF;
    display: block;
    margin: -50px auto 0;
    max-height: 178px;
    padding: 24px 40px;
    position: relative;
    width: 688px;
  }

  @media screen and (min-width: 1200px) {
    margin: -78px auto 0;
    max-height: 212px;
    padding: 32px 32px 0px 100px;
    width: 970px;
  }
`;

const Tags = styled.ul`
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 8px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  li {
    margin: 0 26px 0 0;

    &:first-of-type {
      list-style: none;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const PublishInfo = styled.div`
  margin-top: 8px;
  font-size: 14px;

  @media(min-width:  1200px) {
    font-size: 16px;
  }

  .middle-dot {
    @media(max-width: 767px) {
      display: none;
    }

    font-size: 18px;
    line-height: 23px;
    display: inline-block;
    margin: 0 8px;
  }
`;

const Author = styled.span`
  @media(max-width: 767px) {
    margin-bottom: 8px;
    display: inline-block;
  }
`;

const Published = styled.span`
  @media(max-width: 767px) {
    display: block;
  }
`;

const defaults = {
  heroDesktop: placeholder_url(2680,800)
}

const HeroBlog = connectLocation(({ t, imgDesktop, imgTablet, imgMobile, mobileAspectRatio, altText, prehead, h1, tags, author, publishDate }) => {
  let processedImgDesktop = Picture.DefaultOrCrop(imgDesktop?.file, imgDesktop?.crop, defaults.heroDesktop);
  let processedImgTablet = Picture.DefaultOrCrop(imgTablet?.file, imgTablet?.crop, imgDesktop?.file || defaults.heroDesktop);
  let processedImgMobile = Picture.DefaultOrCrop(imgMobile?.file, imgMobile?.crop, imgDesktop?.file || defaults.heroDesktop);

  // TODO: get rid of this. We should not be UI.Date at the higher level, instead in here publishDate should be the data
  // and we can do a standard null/empty check and UI.Date wrap here
  const showPublishDate = isValidElement(publishDate) && publishDate?.props?.children && Children.count(publishDate?.props?.children) > 0;

  // PB-868: Convert bg images on desktop/tablet to picture variants to fix alt text
  const imageVariants = [
    { media: "(min-width: 1200px)", source: processedImgDesktop },
    { media: "(min-width: 768px)", source: processedImgTablet },
  ];

  return (
    <HeroContainer>
      <ImageContainer>
        <Picture source={processedImgMobile} aspectRatio={mobileAspectRatio} alt={altText} variants={imageVariants} />
      </ImageContainer>
      <HeroPageHeading>
        { tags &&
          <Tags>
            {tags.map((tag, index) => (
              <li key={ index } >{ tag }</li>
            ))}
          </Tags>
        }

        {prehead && <span className="prehead"><Text content={ prehead } /></span>}
        <h1 className="tier3">{h1}</h1>

        { (author || publishDate) &&
          <PublishInfo>
            { author && <Author><strong>{ t('components.oc-hero.blog.writtenBy') }</strong> {author}</Author> }
            { showPublishDate && <Published>
              { author && <span className="middle-dot">•</span> }<strong>{ t('components.oc-hero.blog.datePublished') }</strong> { convertToISODate(publishDate) }
            </Published> }
          </PublishInfo>
        }

      </HeroPageHeading>
    </HeroContainer>
  );
});

export default HeroBlog;

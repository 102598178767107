import { ui } from '@owenscorning/pcb.alpha';
import Product from '../Product/Product';

// This kind of PDP is expected to be articles of solutions, but
// we need it to behave like a Product kind of PDP for the structure
// we want it to have
const Applications = Product(
  'Article::Applications',
  ui`AttributeSet`({
    query: {
      name: 'Applications Attributes',
      path: '/insulation'
    }
  }),
  {},
  {},
  {
    version: '0.1',
    displayName: 'Applications Solutions',
    paths: ['insulation/applications'],
  },
  [
    {
      title: 'Intro',
      unmovables: [true, true],
      modules: ['BasicContent', 'ContentAndImage', 'Reusable'],
      initial: [
        'ContentAndImage',
        {
          type: 'ContentAndImage',
          data: {
            arrangement: {
              contentOrder: 'last'
            }
          }
        }
      ]
    },
    {
      title: 'System Benefits',
      unmovables: [true],
      modules: ['BasicContent', 'MediaObjectSet', 'Reusable'],
      initial: ['MediaObjectSet']
    },
    {
      title: 'Documents',
      unmovables: [true],
      modules: ['BasicContent', 'Reusable'],
      initial: ['MediaObjectSet']
    },
    {
      title: 'Testimonials',
      unmovables: [true],
      modules: ['BasicContent', 'Reusable'],
      initial: ['ContentAndImage']
    },
    {
      title: 'Case Studies',
      unmovables: [true],
      modules: ['BasicContent', 'CardSet', 'Reusable'],
      initial: ['CardSet']
    },
    {
      title: 'Explore More',
      unmovables: [true],
      modules: ['BasicContent', 'CardSet', 'Reusable'],
      initial: ['CardSet']
    }
  ]
)

export default Applications;

import { s, define, ui, when, select, request } from '@owenscorning/pcb.alpha';
import _ from 'lodash';
import React from 'react';

import { VIEWS } from '../../../../../OC/PageBuilder/Locator/Tabs.js';
import Renderer from '../../../../../OC/PageBuilder/Locator/index.jsx';
import { locatorOptions } from '../../../../../OC/PageBuilder/Locator/locators.js';

export default define`Locator`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/Locator`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value }) => <Renderer {...value} />,
  edit: {
    locatorUid: ui`Choices`.of(locatorOptions)({
      label: 'Select Locator',
      default: Object.keys(locatorOptions)[0],
    }),
    defaultView: ui`Choices`.of({
      [VIEWS.MAP]: 'Map',
      [VIEWS.LIST]: 'List',
    })({
      label: 'Default View',
      default: VIEWS.MAP,
    }),
    resultsHeading: ui`Form`.of({
      none: ui`Text`({
        label: 'None',
        default: 'There are no locations near {city}, {region}',
      }),
      singular: ui`Text`({
        label: 'Singular',
        default: '{count} Location Near {city}, {region}',
      }),
      plural: ui`Text`({
        label: 'Plural',
        default: '{count} Locations Near {city}, {region}',
      }),
    })({
      label: 'Results Heading',
      sublabel:
        'Interpolation is enabled within these text elements. Try or "{count}" "{city}" "{region}"',
    }),
    filterInformation: ui`Form`.of({
      distanceBreakpoints: ui`List`.of({
        distance: ui`Number`({
          min: 1,
          max: 1000,
          step: 1,
          label: 'Breakpoint',
          default: 20,
        }),
      })({
        label: 'Distance Filter Breakpoints',
        min: 1,
        max: 6,
        // TODO having issues with title here. it doesn't seem to like numbers
        default: [20, 50, 100].map(distance => ({ distance })),
      }),
      filters: ui`Modules/Page/Locator/FiltersWrapper`({
        locatorUid: select`../../locatorUid`,
        label: 'Other Filters',
      }),
    })({
      label: 'Filter Information',
    }),
    underFilterContent: ui`List`.of({
      type: ui`Choices`.of({
        basicContent: 'Basic Content',
        legend: 'Legend',
        reusable: 'Reusable Content',
      })({ label: 'Content Type' }),
      basicContent: ui`Content/Basic`({
        label: 'Basic Content',
        visible: when`../type`.is.equal
          .to('basicContent')
          .then(true)
          .otherwise(false),
      }),
      reusable: ui`Modules/Page/Reusable`({
        label: 'Reusable Content',
        visible: when`../type`.is.equal
          .to('reusable')
          .then(true)
          .otherwise(false),
      }),
      legend: ui`Legend`({
        label: 'Legend',
        visible: when`../type`.is.equal
          .to('legend')
          .then(true)
          .otherwise(false),
      }),
    })({
      label: 'Under Filter Content',
      sublabel: 'Content that appears under the filter section',
      min: 0,
      max: 6,
      default: [],
    }),
    groups: ui`Modules/Page/Locator/GroupsWrapper`({
      locatorUid: select`../locatorUid`,
      label: 'Group Headers',
    }),
    features: ui`Modules/Page/Locator/Features`({
      filterInfo: select`../filterInformation`,
    }),
    noResultsContent: ui`Form`.of({
      basicContent: ui`Content/Basic`,
      includeFindNearest: ui`Switch`({
        label: 'Show "Find Nearest" CTA',
        default: true,
      }),
      findNearstCtaText: ui`Text`({
        label: '"Find Nearest" CTA Text',
        visible: when`../includeFindNearest`.is.equal
          .to(true)
          .then(true)
          .otherwise(false),
        default: 'See Closest Dealer to me',
      }),
    })({
      label: '"No Results" content block',
      sublabel:
        'Interpolation is enabled within the text. Try or "{zip}" "{distance}" within a text element.',
    }),
    showLogo: ui`Switch`({ label: 'Show Logo?', default: false }),
  },
});

import { jsx, css, keyframes } from '@emotion/react';

const Animations = {
  spin: keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  `
};

export default Animations;

import styled from '@emotion/styled';
import { jsx, css } from '@emotion/react';

const TableResponsive = styled.div`
	min-height: .01%;
  overflow-x: auto;
  margin: ${props => props.margin ? props.margin : '0'};

	@media(max-width: 767px) {
		width: 100%;
    overflow-y: hidden;
	}
`;

const TableStyles = styled.table`
	font-size: 14px;
	color: #333;
	width: 100%;
  max-width: 100%;
  border-spacing: 0;
  ${props => props.fixedTableLayout && css`
    table-layout: fixed;
  `};
  ${props => props.bordered && css`
    border-left: 1px solid #BCBCBC;
    border-right: 1px solid #BCBCBC;
  `};

  th {
    background: white;
    color: black;
    text-transform: uppercase;
    padding: 20px 15px;
    border-top: 2px solid #BCBCBC;
    border-bottom: 2px solid #BCBCBC;
    border-right: 1px solid #BCBCBC;
    text-align: center;
    font-size: 16px;

    &:last-of-type {
      border-right: none;
    }

    &.left-align {
      text-align: left;
    }

    &.pink-text {
      color: #d40f7d;
      font-weight: 700;
    }

    &.condensed {
      padding: 5px 15px;
      font-size: 14px;
    }
  }

  ${props => props.striped && css`
  	tr:nth-of-type(even) {
			background: #F8F8F8;
		}
  `};

  td {
    padding: 10px 15px;
    border-bottom: 1px solid #BCBCBC;
    border-right: 1px solid #BCBCBC;
    text-align: center;
    position: relative;

    ${props => props.firstLeft && css`
      &:first-of-type {
        text-align: left;
      }
    `};

    &:last-of-type {
      border-right: none;
    }

    &.row-heading {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 16px;
    }
  }
`;

const Table = ({children, striped=false, margin, firstLeft=true, bordered=false, fixedTableLayout=false}) => (
	<TableResponsive margin={margin}>
		<TableStyles striped={striped} firstLeft={firstLeft} bordered={bordered} fixedTableLayout={fixedTableLayout} >
			{children}
		</TableStyles>
	</TableResponsive>
);

export default Table;

import { ui, select, transform, when, s } from '@owenscorning/pcb.alpha';
import _ from 'lodash';
import Content from './Content';
import Renderer from '../../../OC/PageBuilder/Form.jsx';
import Modular from './Modular';
import Examples from './Form.examples.json';
import Tab from '../../../ComponentLibrary/tabs/Tab';
import TabGroup from '../../../ComponentLibrary/tabs/TabGroup';
import { useState, Fragment } from 'react';
import styled from '@emotion/styled';

const defaults = Examples.subscribe


const openExample = (key, onChange) => {
  Board.Change({ form: Examples[key].schema, ui: Examples[key].uiSchema }, 'columns.sidebar.builder.schema')
  // this is awkward, this will get raised all the way up to board definition and cause a push to preview frame
  onChange()
}

const ExampleList = ({ onChange }) => (
  <ul>
    {
      _.map(
        Examples,
        (v,k) =>
          <li key={ k }>
            <a onClick={ () => openExample(k, onChange) }>{ v.name }</a>
          </li>
      )
    }
  </ul>
)

const recurseWebhookSchemaNode = (node) => {
  if (node?.type === 'object') {
    const response = _.pick(node, ['properties', 'type']);
    response.properties = _.mapValues(response.properties, recurseWebhookSchemaNode)
    return response
  }
  if (Array.isArray(node)) {
    return _.map(node, recurseWebhookSchemaNode)
  } else if (typeof(node) === 'object') {
    return _.mapValues(_.pick(node,['type']), recurseWebhookSchemaNode)
  } else {
    return node;
  }
}

const generateWebhookSchema = (schema) => {
  return {
    type: 'object',
    properties: {
      uid: {
        type: 'string'
      },
      host: {
        type: 'string'
      },
      created_at: {
        type: 'string'
      },
      test: {
        type: 'boolean'
      },
      data: recurseWebhookSchemaNode(schema)
    }
  }
}

const Form = Content(
  'Form',
  (UI) => ({
    version: '0.1',
    displayName: 'Form',
    // need to read/write as strings since the data is jsonb and loses ordering
    read: ({ contents = {}, metadata }) => {
      const { success_content, schema = {}, ...rest } = contents
      return {
        contents: {
          success_content: success_content || {
            type: 'content',
            content: [
              {
                type: 'BasicContent',
                data: {
                  content: {
                    prehead: ' ',
                    heading: 'Thank you!',
                    bodyCopy: 'Your form was submitted.'
                  }
                }
              }
            ]
          },
          schema: {
            form: typeof(schema.form) !== 'undefined' ? JSON.parse(schema.form) : defaults.schema,
            ui: typeof(schema.ui) !== 'undefined' ? JSON.parse(schema.ui) : defaults.uiSchema
          },
          ...rest
        },
        metadata
      }
    },
    write: ({ contents, metadata }) => {
      return {
        contents: {
          ...contents,
          schema: {
            form: JSON.stringify(contents.schema.form),
            ui: JSON.stringify(contents.schema.ui),
          }
        },
        metadata
      }
    },
    variables: {
      build: { moduleType: 'Page' },
      focus: { phase: 'raw' },
      names: {
        modules: _.mapValues(UI.Modules['Page'], (module) => module.displayName),
      },
    },
    sidebar: {
      builder: select`~focus/phase`.from({
        raw: {
          tip: ui`Tip`.of('<p>This content is the input to the Owens Corning Form component, which is a wrapper around <a target="_blank" href="https://github.com/rjsf-team/react-jsonschema-form">react-jsonschema-form</a> schema and uiSchema properties.  While the entire react-jsonschema-form functionality is available, these values are preprocessed before handing to the library to enforce consistency or provide additional features, as noted <a target="_blank" href="https://owenscorning.atlassian.net/wiki/spaces/DevT/pages/1676738561/Form+Service#JSON-Schema-based-Forms-(v1%2C-v2%2C-v2%2B-serverside-only)">here</a>.  There are plenty of examples at the <a target="_blank" href="https://rjsf-team.github.io/react-jsonschema-form/">react-jsonshema-form playground</a>, and info in the <a target="_blank" href="https://rjsf-team.github.io/react-jsonschema-form/docs/">documentation</a>.</p><p>Expand the following to see some examples to get you started showcasing some common scenarios and our extensions (note, clicking these links will replace the current form contents entirely):</p>'),
          example: ({ onChange }) => (
            <UI.List.Item standalone title="Examples">
              <ExampleList onChange={ onChange } />
            </UI.List.Item>
          ),
          schema: ui`List/Item`.of(
            ui`Form`.of({
              form: ui`Json`({
                label: 'Field',
              }),
              ui: ui`Json`({
                label: 'UI',
              }),
            })
          )({
            title: 'Form Definition',
            unwrapped: true,
            standalone: true,
          }),
          success_content: ui`List/Item`.of(
            ui`Form`.of({
              type: ui`Choices`.of({
                content: 'Content',
                url: 'URL'
              })({
                label: 'Type',
                default: 'content'
              }),
              modal: ui`Switch`({
                label: 'Content in modal?'
              }),
              content: ({path})=> <Subschema>{
                Modular.Module.List(UI, null, null, (index) => Form.SuccessContent([...(path.slice(3)), index]),  when`../type`.is.equal.to('content'))
              }</Subschema>,
              url: ui`Url`({
                label: 'URL',
                visible: when`../type`.is.equal.to('url')
              })
            })
          )({
            title: 'Success Content',
            unwrapped: true,
            standalone: true,
          })
        },
        successContent: {
          heading: ui`Heading`({
            subheading: 'Success Content',
            heading: select`../success_content/content/~focus:successContent/type`.from`~names/modules`,
            onBack: () => Board.select({ focus: {...Board.focus.last} })
          }),
          'success_content/content/~focus:successContent/data': ui`Form`.of(ui`Modules/~build:moduleType/@..:type`)({ title: true })
        }
      }),
      settings: {
        action: ui`Choices`.of({
          'webhook': 'Webhook (e.g. Power Automate Flow)',
          'email': 'Email(s)',
          'custom': 'Custom Development'
        })({
          label: 'Form Action',
          multiple: true
        }),
        webhook: ui`List/Item`.of({
          tip: ui`Tip`({
            contents: transform`~build`(
              build => `Form will be POST to the following enabled URLs as JSON in following format<br><pre>{\n  "uid": "${build.site}/${build.language.toLowerCase()}/${build.path}",\n  "host": "${window.location.host || build.site}"\n  "test": false\n  "created_at": "${new Date().toISOString()}"\n  "data": {\n    ...\n  }\n}</pre><br>Note that "test" will be <pre>true</pre> if submitted from the designer preview.`
            )
          }),
          download: ui`Button|view`({
            text: 'Download JSON Schema',
            action: () => {
              Board.modal.open('schema', null, { form: generateWebhookSchema(Board.Value.columns.sidebar.builder.schema.form) })
            }
          }),
          urls: ui`List`.of({
            enabled: ui`Switch`({ label: 'Enabled' }),
            label: ui`Text`({ label: 'Label' }),
            url: ui`Text`({ label: 'URL' }),
          })({
            singular: 'URL',
            title: 'label',
            hideable: false
          })
        })({
          title: 'Webhook URLs',
          visible: when`../action`.is.containing('webhook'),
          standalone: true
        }),
        email: ui`List/Item`.of(
          ui`Form`.of({
            to: ui`List`.of(ui`Text`)({ label: 'To', singular: 'Address', title: i => i, hideable: false }),
            cc: ui`List`.of(ui`Text`)({ label: 'CC', singular: 'Address', title: i => i, hideable: false }),
            bcc: ui`List`.of(ui`Text`)({ label: 'BCC', singular: 'Address', title: i => i, hideable: false }),
            subject: ui`Text`({label: 'Subject'}),
          })
        )({
          title: 'Email Info',
          visible: when`../action`.is.containing('email'),
          standalone: true
        }),
        custom: ui`List/Item`.of(
          ui`Form`.of({
            model: ui`Text`({label: 'Handler', sublabel: 'Please coordinate with Digital Team to build and deploy this handler'}),
          })
        )({
          title: 'Custom Development',
          visible: when`../action`.is.containing('custom'),
          standalone: true
        })
      },
    },
    view: Form.Renderer(UI),
    modals: {
      schema: {
        title: 'Schema',
        body: ui`Form`.of({
          form: ui`Json`({ readOnly: true })
        })
      }
    }
  })
);

Form.SuccessContent = (path) => {
  return Board.select({
    focus: {
      phase: 'successContent',
      successContent: path[2],
      last: _.cloneDeep(Board.focus)
    }
  });
}

const HalfWidthTab = styled(Tab)`
  width: 50%
`

const FormSubmissionWarning = styled.div`
  padding: 12px 16px;
  z-index: 2000;
  width: 100%;
  background-color: #FFF1E5;
  color: #7E3900;
  font-family: OCRoboto;
  font-style: normal;
  font-size: 15px;
  line-height: 16px;
  margin-bottom: 20px;

  i {
    margin-right: 10px;
  }
`

const FormSubmissionWarningSpacer = styled.div`
  position: static;
  top: 0;
  height: 20px;
`

const formatActions = ({ settings = {} } = {}) => {
  const { action = [], email, webhook, custom } = settings;
  const actions = [];
  if (action.includes('email') && email) {
    const { to, cc, bcc, subject } = email;
    actions.push(
      <li key="email">
        Email
        <dl>
          <dt><b>Subject</b></dt>
          <dd>{ `TEST - ${ subject }` }</dd>
          <dt><b>To</b></dt>
          <dd>{ to.join(', ') }</dd>
          {
            cc.length > 0 &&
            <>
              <dt><b>CC</b></dt>
              <dd>{ cc.join(', ') }</dd>
            </>
          }
          {
            bcc.length > 0 &&
            <>
              <dt><b>BCC</b></dt>
              <dd>{ bcc.join(', ') }</dd>
            </>
          }
        </dl>
      </li>
    )
  }
  if (action.includes('webhook') && webhook) {
    const urls = webhook.urls.filter((url) => url.enabled)
    if (urls.length > 0) {
      actions.push(
        <li key="webhook">
          Webhook
          {
            urls.map(url =>
              <Fragment key={ url.url }>
                <dt><b>URL</b></dt>
                <dd>{ url.url }</dd>
              </Fragment>
            )
          }
        </li>
      )
    }
  }
  if (action.includes('custom') && custom?.model) {
    actions.push(
      <li key="custom">
        Custom
        <dl>
          <dt><b>Model</b></dt>
          <dd>{ custom.model }</dd>
        </dl>
      </li>
    )
  }
  if (actions.length === 0) {
    actions.push(<b key="none">None</b>)
  }
  return actions
}

Form.Renderer = (UI, parameters={}) => ({
  contents: ({ value, ...props }) => {
    const [showSuccess, setShowSuccess] = useState(false)
    const editable = typeof(Board) === 'undefined' ? false : Board?.editable;
    const Wrapper = editable ?
      // TODO: get Tabs component working, the current UI.Tabs only seems to work with a schematic, not components
      ({ children }) => (
        <div>
          <p>
            This is the form designer view, you can "submit" the form here, but it is going to a sandbox (nothing on
            Settings tab will execute).  It is useful to test browser validation or the success view, which can also be
            toggled via the tabs below.
          </p>
          <div>
            <TabGroup tabsAlign="center" tabSize="large" tabCount={ 2 } >
              <HalfWidthTab
                tabSize="large"
                active={ !showSuccess }
                onClick={ () => setShowSuccess(false) }
                label="Form"
              />
              <HalfWidthTab
                tabSize="large"
                active={ showSuccess }
                onClick={ () => setShowSuccess(true) }
                label="Success"
              />
            </TabGroup>
          </div>
          { children }
        </div>
      ) :
      ({ children }) => (
        <div>
          <FormSubmissionWarningSpacer />
          <FormSubmissionWarning>
            <i className="fa fa-exclamation-triangle" aria-hidden="true"></i><b>Warning!</b>
            <p>
              This is the form "preview" view.  Form submission <b>will be processed</b> according to the Settings tab.
              It is useful to test the form workflow.
            </p>
            <p>
              The form will be processed in "test" mode, which is passed on to Webhooks, and Email subject/body.  For
              Custom Development, your developer should be able to describe what will happen for "test" mode forms.  The
              responsibility to prevent unintended side effects from "test" forms is the developer's.
            </p>
            <p>
              For reference, the following actions will be taken:
              <ul>
                { formatActions(Board.Value.metadata) }
              </ul>
            </p>
          </FormSubmissionWarning>
          { children }
        </div>
      )
    return (
      <div>
        <Wrapper>
          <Renderer content={ value } sandbox={ editable } test={ !editable } success={ showSuccess } afterSave={ () => setShowSuccess(true) } />
        </Wrapper>
      </div>
    )
  }
});

export default Form;

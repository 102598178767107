import useMdmsApi from './use_mdms_api';

const INITIAL_LOCATION_INFO = {
  zip_code: '',
  valid: true,
  city: '',
  region: '',
  latitude: '',
  longitude: '',
  zone: '',
};

const useValidZip = (zip, c_code) => {
  const { status, data } = useMdmsApi(
    `/api/v1/validzip?zip=${zip}&c_code=${c_code}`,
    zip && c_code
  );
  return [status, data || INITIAL_LOCATION_INFO];
};

export default useValidZip;

import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { define, ui, s, select, when } from '@owenscorning/pcb.alpha';
import BasicContent from './../../../../OC/PageBuilder/BasicContent.jsx';
import Accordion from './../../../../OC/PageBuilder/Accordion.jsx';
import Data from '../../../Data';
import MediaObject from '../../../../OC/PageBuilder/MediaObject';
import { isDeltaEmpty } from '../../Text/Rich';
import { connectLocation } from '../../../../location/LocationConnectors';
import cms_api from '../../../../../cms_api';
import { highlightSettingsSection } from "../../../../OC/PageBuilder/utilities/shared";

const FeaturedDocuments = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
`;

const defaultCopy = {
  prehead: '',
  heading: '',
  bodyCopy: null
}

const SAFETY_DATA_SHEET_CATEGORY = "safety-data-sheets";

const useAttribute = (query) => {
  const [lookup, setLookup] = useState();
  useEffect(() => {
    cms_api.get_single_contents({
      filter: {
        type: 'Cms::Content::Attribute',
        language_iso_code: Board.build.language,
        route: query.path,
        name: query.name,
      },
      fields: {
        '*': 'contents',
      },
    }).then(response => setLookup(_.keyBy(response?.items, r => r.value)))
  }, []);

  return lookup;
}

const mergeDocuments = (oldDocuments, newDocuments) => {
  newDocuments.forEach(newDocument => {
    // A document will be considered a duplicate if both the newDocument and the oldDocument have their corresponding
    // document element, and their __ref is the same. We are also going to consider it a duplicate if the old document
    // is not being hidden. Since the way this method work is that it prints first as oldDocuments the ones that come
    // from related products, and then it merges to it the newDocuments the one manually added to the enclosure product,
    // if we decide to hide the related product document, then we should push the direct product document to the list to
    // be rendered "as well".
    const documentIsDuplicated = oldDocuments.some((oldDocument) => {
      return newDocument?.document && oldDocument?.document && newDocument.document.__ref === oldDocument.document.__ref && !oldDocument.__hidden
    })
    if (documentIsDuplicated)
      return;

    oldDocuments.push(newDocument);
  });
};

// Function expecting a list of documents with the usual document structure, and a similar list containing the visibility
// for each of these documents. These list do not necessarily need to match, since documents may be a list coming from associated
// product, and their corresponding visibility has not been set yet. We default to document being visible if it is not found within
// the documentsWithVisibility array.
const applyCustomDocumentVisibility = (documents, documentsWithVisibility) => {
  if (documentsWithVisibility === undefined || documentsWithVisibility?.length <= 0) {
    return documents;
  }

  documents.forEach((doc, index) => {
    let documentWithVisibility = null;
    if (doc.document) { // Filtering a document type
      documentWithVisibility = documentsWithVisibility.find((visibilityDoc) => {
        return visibilityDoc.document && visibilityDoc.document.__ref === doc.document.__ref
      })
    } else { // Filtering a link
      documentWithVisibility = documentsWithVisibility.find((visibilityDoc) => {
        return !visibilityDoc.document && visibilityDoc.link === doc.link
      })
    }

    if (documentWithVisibility) {
      // Document structure is
      // {
      //   document: { __ref: '', __data: ... },
      //   type: '',
      //   title: '',
      //   description: '',
      //   link: '',
      //   __hidden,
      //   __visibility
      // }
      // we want to grab only the hidden and visibility attributes if they are present.
      let { document, title, type, description, link, ...rest } = documentWithVisibility
      documents[index] = _.merge(doc, rest)
    }
  })
  return documents;
};

const Renderer = connectLocation(({
  copy = defaultCopy,
  t,
  locale: { code }
}) => {
  const settings = Board.settings || {};
  const categories = settings.documents?.categories || [];
  let featured_documents = settings.documents?.featured_documents || {};
  const products = settings.associatedProducts || [];
  const attribute = useAttribute({
    path: '/',
    name: 'PDP Document Categories'
  });

  const getTitle = (category, title) => {
    return attribute?.[category]?.label || title
  };

  const isDocPresent = ({ type, document, link, __hidden }) => {
    if (__hidden) return false;

    let docType = type || "document";
    if (docType === "link") return (!!link);
    if (docType === "document") return (document && (document.__data || document.__ref));
  };

  const [resolvedCategories, setResolvedCategories] = useState(categories);
  const dataSetKey = PB_SITE === 'www.owenscorning.com' ? 'documents' : 'document_builder'
  useDeepCompareEffectNoCheck(() => {
    if (Board.build.type === 'Product::Enclosure') {
      const adapter = Data.for('ProductsInSolution')['documents'];
      adapter.fetch_data({ ...products }).then(results => {
        const parsedCategories = [];
        (results || []).forEach((result) => {
          if (!result) {
            return;
          }

          const { productCategories, documentsVisibility } = result;
          (productCategories || []).flat().filter(Boolean).forEach((category) => {
            const categoryIndex = parsedCategories.findIndex(c => c.category === category.category)
            // Clone the category to apply visibility mutation and not mess with original data
            const clonedCategory = structuredClone(category)
            clonedCategory.documents = applyCustomDocumentVisibility(clonedCategory.documents, documentsVisibility || []);
            if (categoryIndex >= 0) {
              mergeDocuments(parsedCategories[categoryIndex].documents, clonedCategory.documents);
            } else {
              parsedCategories.push(clonedCategory);
            }
          });
        });

        const addedCategories = categories.map(category => {
          return category.documents?.length && {...category, category: category.category}
        } ).filter(Boolean);
        const resultantCategories = [...parsedCategories, ...addedCategories].reduce((acc, currentObj) => {
          const existingCategoryIndex = acc.findIndex((item) => item.category === currentObj.category);

          if (existingCategoryIndex !== -1) {
            // Merge documents if category already exists
            mergeDocuments(acc[existingCategoryIndex].documents, currentObj.documents);
          } else {
            // Add the new category
            acc.push({ ...currentObj });
          }

          return acc;
        }, []);
        Data.for('Accordion')['documents'].old_view(
          { documents: { categories: resultantCategories } }
        ).then(r => {
          setResolvedCategories(r.filter(cat => !cat.__hidden));
        });
      })
    } else {
      const adapter = Data.for('Accordion')[dataSetKey];
      if (adapter) {
        adapter.old_view({ [dataSetKey]: { categories } }).then(result => {
          setResolvedCategories(result.filter(cat => !cat.__hidden) )
        });
      }
    }
  }, [categories, products]);

  featured_documents = resolvedCategories.flatMap(({ category, title, documents = [] }) => documents.filter(isDocPresent).filter(({ featured }) => featured).map(document => ({ prehead: getTitle(category, title), ...document })));
  const [featuredDocuments, setFeaturedDocuments] = useState(featured_documents);
  useDeepCompareEffectNoCheck(() => {
    const adapter = Data.for('MediaObjectSet')[dataSetKey];
    if (adapter) {
      adapter.old_view({ [dataSetKey]: { items: featured_documents } }).then(result => setFeaturedDocuments(result));
    }
  }, [featured_documents]);

  const blotType = PB_SITE === 'www.owenscorning.com' ? 'document' : 'document_builder'

  const generateDocOp = (obj) => {
    if (obj.type === "document" || obj.format === "document") {
      return [{ insert: { [blotType]: obj }}];
    } else {
      return [
        {
          attributes: {
            link: obj.link
          },
          insert: obj.title
        },
        {
          insert: {
            breaker: true
          }
        },
        {
          insert: "\n"
        }
      ]
    }
  };

  const accordions = resolvedCategories.map(({ category, title, content, documents = [], sds_search }) => {
    const foundDocuments = documents.filter(isDocPresent);
    const isSdsCategory = (category && category === SAFETY_DATA_SHEET_CATEGORY)
    if (!isSdsCategory && foundDocuments.length === 0 && isDeltaEmpty(content)) {
      return null;
    }

    return {
      title: `${ getTitle(category, title) }${ foundDocuments.length > 0 ? ` (${ foundDocuments.length })` : ''}`,
      content: {
        ops: (content?.ops || []).concat(foundDocuments.flatMap(generateDocOp)).
        concat(isSdsCategory ? [
          {
            attributes: {
              link: `/${ code.toLowerCase() }/sds${ sds_search?.term ? `/results?q=${ encodeURIComponent(sds_search.term) }` : ''}`
            },
            insert: t('product.sds_search.title')
          },
          {
            insert: {
              breaker: true
            }
          },
          {
            insert: "\n"
          }
        ] : [])
      }
    }
  }).filter(x => x);

  return (
    <div>
      <BasicContent content={ copy } />
      {featuredDocuments.length > 0 &&
        <FeaturedDocuments>
          { /* TODO: could not use MediaObjectSet renderer because we want fits tablet 1 */ }
          <UI.Row gap-3 fits={ settings?.itemsPerRow || 3 } tablet-fits-1 mobile-fits-1>
            {featuredDocuments.map((mo, index) => {
              let data = mo?.document?.__data;
              return (
                <Fragment key={ index }>
                  {(settings?.itemsPerRow === '1' && settings?.rowBorder && index > 0) &&
                    <div className="border-b" style={{ 'margin-bottom': '24px' }} />
                  }

                  <MediaObject
                    aspectRatio={ settings?.aspectRatio }
                    imgSize="document"
                    url={data?.url}
                    heading={data?.title}
                    {...mo}
                  />
                </Fragment>
              );
            })}
          </UI.Row>
        </FeaturedDocuments>
      }

      <Accordion accordions={ accordions } />
    </div>
  );
})

const SpecificationsAndLiterature = define`Specifications & Literature`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/Placeholder`,
  view: ({value}) => {
    return <Renderer {...value} reRender={ JSON.stringify(Board.settings?.documents) }/>
  },
  edit: {
    [s._]: ui`Tip`.of('Please configure documents within the Documents section in Settings')({ noTopMargin: true }),
    [s._]: ui`RawLink`.of('Go to settings')({
      onClick: () => {
        highlightSettingsSection('sidebar-pane', 'productDocuments');
        Board?.setDocumentsOpener(true);
      },
      noTopMargin: true,
      icon: 'external-link',
    }),
  }
})

export default SpecificationsAndLiterature;

import React, { useRef, useEffect, useState } from 'react';

import Library from './';
import { board, ui, is, isnt, when, always, select, request, transform, s } from '@owenscorning/pcb.alpha';

import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";

import Beautify from 'ace-builds/src-noconflict/ext-beautify';

import { transform as parse } from '@babel/standalone';

const Cookie = 'PCB_REPL_CONTENT';
let Loaded = false;

const Code = styled.pre`
  white-space: normal;
`;

export default ({ saves=true, path, onChange, value }) => {
  const ref = useRef();
  useEffect(() => Beautify.beautify(ref.current.editor.session), []);

  const [ state, setState ] = useState({ value: {} });

  if (saves) {
    if (Loaded) {
      UI.Cookies.Set(Cookie, value);
    } else {
      value = UI.Cookies.Get(Cookie);
      Loaded = true;
    }
  }

  let parsed = '';
  let schematic = {};
  let failed = false;

  try {

    parsed = parse(
      `(
        _, React,
        Library,
        board, ui,
        is, isnt,
        when, always,
        select, request,
        transform, s
      ) => {
        ${ value.includes('return') ? value : `return ${ value }` }
      }`,
      { presets: [ 'es2015', 'react' ] }
    );

    schematic = eval(parsed.code)(
      _, React,
      Library,
      board, ui,
      is, isnt,
      when, always,
      select, request,
      transform, s
    );

  } catch(error) {
    failed = error.message;
  }

  const Board = failed
    ? () => <UI.Paragraph red >
      Failed to parse!<br/>
      <pre>Error: { failed }</pre>
      <pre>{ JSON.stringify(parsed) }</pre>
    </UI.Paragraph>
    : board`Repl::Board`('0.0.1')(
      Library,
      { schematic: ui`Form`.of(schematic)({ title: true }) }
    );

  return <div css={ css`
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    gap: 16px;
    & > div {
      width: 50%;
      overflow-y: auto;
    }
  ` } >
    <div>
      <AceEditor
        ref={ ref }
        mode="javascript"
        theme="monokai"
        defaultValue={ value }
        onChange={ onChange }
        name={ `repl${ path }` }
        tabSize={ 2 }
        fontSize={ 16 }
        width="100%"
        height="100%"
        wrapEnabled={ true }
        showPrintMargin={ false }
        commands={ Beautify.commands }
        editorProps={{ $blockScrolling: true }}
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false
        }}
      />
    </div>
    <div>
      <UI.Error.Boundary key={ value } >
        <Board />
      </UI.Error.Boundary>
    </div>
  </div>;
}

import { define, ui, when, select, always } from '@owenscorning/pcb.alpha';
import _ from 'lodash';

// Components
import AspectRatio from '../../../AspectRatio.jsx';
import CMSHero from './../../../../../OC/PageBuilder/Hero.jsx';

// Utilities
import placeholder_url from '../../../../../../placeholder';

const articles = [
  'Article::Roofing', 'Article::InsulationResidential', 'Article::InsulationIndustrial',
  'Article::Lumber', 'Article::Applications', 'Article::Paroc'
];

export default define`Hero`('0.0.1')({
  gapless: true,
  gutterless: true,
  view: ({ value }) => {
    let props = _.cloneDeep(value);

    if (Board.build.type === 'Article::InsulationSolutionsSuite') {
      props = {
        ...( props || {} ),
        copy: { ...( props?.copy || {} ), prehead: '', mainHeading: Board.settings.details?.information?.title },
        tags: [
          Board.settings.details?.taxonomies?.primary_solution_center || _.first(Board.settings.details?.taxonomies?.solution_centers),
          Board.settings.details?.taxonomies?.primary_industry || _.first(Board.settings.details?.taxonomies?.industries),
          Board.settings.details?.taxonomies?.category
        ].filter(x=>x),
        author: Board.settings.details?.information?.writer,
        // TODO: get rid of this.  We should not be UI.Date at the highest level, instead in here just pass published
        // we would want to UI.Date wrap at lowest level
        published: <UI.Date>{ Board.settings.details?.information?.published }</UI.Date>,
        images: { bgDesktop: Board.settings.details?.image },
        preset: 'custom',
        type: 'tier_4'
      }
    } else if (articles.includes(Board.build.type)) {
      props = {
        ...( props || {} ),
        copy: { ...( props?.copy || {} ), prehead: '', mainHeading: Board.settings.details?.information?.title },
        tags: [
          Board.settings.details?.taxonomies?.primary_category || _.first(Board.settings.details?.taxonomies?.categories)
        ].filter(x=>x),
        author: Board.settings.details?.information?.writer,
        // TODO: get rid of this.  We should not be UI.Date at the highest level, instead in here just pass published
        // we would want to UI.Date wrap at lowest level
        published: <UI.Date>{ Board.settings.details?.information?.published }</UI.Date>,
        images: { bgDesktop: Board.settings.details?.image },
        preset: 'custom',
        type: 'tier_4'
      };
    }

    return <CMSHero { ...props } />
  },
  edit: {
    preset: ui`Choices`.of({
      custom: 'Custom',
      home_t1: 'Home | Tier 1',
      roofing_t1: 'Roofing | Tier 1',
      insulation_t1: 'Insulation | Tier 1',
      composites_t1: 'Composites | Tier 1',
      com_insulation_hero: 'Commercial Insulation',
      res_insulation_hero: 'Residential Insulation',
      roofing_components_hero: 'Roofing Components',
    })({
      label: 'Hero Preset',
      default: 'custom'
    }),
    type: ui`Choices`.of({
      tier_2: 'Tier 2',
      tier_3: 'Tier 3',
      tier_4: 'Tier 4'
    })({
      label: 'Hero Type',
      default: 'tier_3',
      visible: when`../preset`.is.equal.to('custom').then(true).otherwise(false)
    }),
    imageSizeInfo: ui`ImageSizeInfo`({
      moduleName: 'Hero',
      visible: when`../preset`.is.equal.to('custom').and.when`../type`.is.equal.to('tier_3').and.when`../showHeroImage`.is.equal.to('small').then(false).otherwise(true)
    }),
    background: ui`Form`.of({
      mediaType: ui`Choices`.of({
        image: 'Image',
        video: 'Video'
      })({
        label: 'Media Type',
        default: 'image'
      }),
      desktop: ui`Image`({
        crop: true,
        label: 'Desktop',
        visible: when`../mediaType`.is.equal.to('image')
      }),
      tablet: ui`Image`({
        crop: true,
        label: 'Tablet',
        visible: when`../mediaType`.is.equal.to('image')
      }),
      mp4: ui`Text`({
        label: 'MP4',
        visible: when`../mediaType`.is.equal.to('video')
      }),
      webm: ui`Text`({
        label: 'WEBM',
        visible: when`../mediaType`.is.equal.to('video')
      })
    })({
      label: 'Hero Background',
      visible: when`../preset`.isnt.equal.to('custom')
    }),
    showHeroImage: ui`Choices`.of({
      standard: "Standard Image",
      small: "Small Image",
      none: "No Image"
    })({
      label: "Hero Image Size",
      default: "standard",
      visible: when`../preset`.is.equal.to('custom').and.when`../type`.is.equal.to('tier_3')
    }),
    smallImageSizeInfo: ui`ImageSizeInfo`({
      moduleName: 'Small Hero',
      visible: when`../preset`.is.equal.to('custom').and.when`../type`.is.equal.to('tier_3').and.when`../showHeroImage`.is.equal.to('small').then(true).otherwise(false)
    }),
    // PB-727: 'contentOrder' data for the ContentAndImage component is in reference to the CONTENT
    // but the labels in the hero UI here are in the context of the IMAGE, so they're switched
    contentOrder: ui`Form`.of({
      desktop: ui`Choices`.of({
        'first': 'Right', // content first: image right
        'last': 'Left'    // content last: image left
      })({
        label: 'Desktop',
        default: 'first'
      }),
      mobile: ui`Choices`.of({
        'last': 'Before Content', // content last: image first
        'first': 'After Content'  // content first: image last
      })({
        label: 'Mobile',
        default: 'last'
      })
    })({
      label: "Hero Image Placement",
      visible: when`../preset`.is.equal.to('custom').and.when`../type`.is.equal.to('tier_3').and.when`../showHeroImage`.is.equal.to('small').then(true).otherwise(false)
    }),
    mobileAspectRatio: AspectRatio({
      label: "T4 Hero Mobile Image Ratio",
      visible: when`../preset`.is.equal.to('custom').and.when`../type`.is.equal.to('tier_4')
    }),
    images: [{
      bgDesktop: ui`Image`({
        crop: true,
        askAlt: false,
        label: 'Desktop Image',
        defaultImage: placeholder_url(1340,600)
      }),
      bgTablet: ui`Image`({
        crop: true,
        askAlt: false,
        label: 'Tablet Image',
        default: placeholder_url(1340,600),
        visible: when`../../preset`.is.equal.to('custom').and.when`../../type`.is.equal.to('tier_3').and.when`../../showHeroImage`.is.equal.to('small').then(false).otherwise(true)
      }),
      bgMobile: ui`Image`({
        crop: true,
        askAlt: false,
        label: 'Mobile Image',
        defaultImage: placeholder_url(1340,600),
        visible: when`../../preset`.is.equal.to('custom').and.when`../../type`.is.equal.to('tier_4')
      })
    }, {
      label: 'Images',
      visible: when`../preset`.is.equal.to('custom')
    }],
    imgAlt: ui`Text`({
      label: "Hero Alt Text"
    }),
    imageUrl: ui`Text`({
      label: 'Image Url (Optional)',
      visible: when`../preset`.is.equal.to('custom').and.when`../type`.is.equal.to('tier_3').and.when`../showHeroImage`.is.equal.to('small').then(true).otherwise(false)
    }),
    panther: ui`Form`.of({
      image: ui`Image`
    })({
      label: 'Panther',
      visible: when`../preset`.isnt.equal.to('custom')
    }),
    copy: ui`Form`.of({
      prehead: ui`Text`({ label: 'Prehead', default: 'Prehead' }),
      mainHeading: ui`Text/Rich/Header`({ label: 'Main Heading', default: 'Heading' }),
      posthead: ui`Text`({ label: 'Posthead', default: 'Posthead', visible: when`../../type`.is.equal.to('tier_3') }),
      body: ui`Text/Rich`({ label: 'Body Copy', default: 'Lorum ipsum' })
    })({
      label: 'Text Content'
    }),
    alertMessage: ui`Form`.of({
      display: ui`Switch`({
        default: false
      }),
      type: ui`Choices`.of({
        black: "Black",
        error: "Error",
        info: "Info",
        other: "Other",
        success: "Success",
        warning: "Warning",
      })({
        label: "Alert Type",
        default: "black",
        visible: when`../display`.is.equal.to(true)
      }),
      title: ui`Text`({
        label: "Title",
        visible: when`../display`.is.equal.to(true)
      }),
      message: ui`Text/Rich`({
        label: "Message Content",
        default: "This is an alert message.",
        visible: when`../display`.is.equal.to(true)
      }),
      dismissable: ui`Switch`({
        label: "Dismissable",
        default: false,
        visible: when`../display`.is.equal.to(true)
      })
    })({
      label: 'Alert Message'
    }),
    logo: ui`Form`.of({
      logoSize: ui`Choices`.of({
        xl: 'ExtraLarge',
        lg: 'Large',
        sm: 'Small'
      })({
        label: 'Logo Size',
        default: 'lg'
      }),
      logo: ui`Upload/Image`({ label: 'Logo' }),
      imgAlt: ui`Text`({ label: 'Alt Text' }),
    })({
      label: 'Logo',
      visible: when`../preset`.is.equal.to('custom')
    }),
    cutawayImage: ui`Form`.of({
      size: ui`Choices`.of({
        xl: 'ExtraLarge',
        lg: 'Large',
        sm: 'Small'
      })({
        label: 'Cut-away Image Size',
        default: 'lg'
      }),
      image: ui`Upload/Image`({ label: 'Cut-away Image' }),
      imgAlt: ui`Text`({ label: 'Alt Text' }),
    })({
      label: 'Cut-away Image',
      visible: when`../preset`.is.equal.to('custom').and.when`../type`.is.equal.to('tier_2')
    }),
    ctas: ui`CTA/List`({
      max: when`../preset`.is.equal.to('custom').and.when`../type`.is.equal.to('tier_3').and.when`../showHeroImage`.is.equal.to('small').then(2).otherwise(4)
    })
  }
});

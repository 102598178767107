
import {jsx} from '@emotion/react';
import {Step, NextButton} from './utilities';
import { connectLocation } from '../location/LocationConnectors';
import { connectOrder } from './BYRConnectors.jsx';
import {Redirect } from 'react-router-dom';
import {store} from '../Store';
import cx from 'classnames';
import {get, isEmpty, } from 'lodash';
// This file is responsible for all step-ordering logic.
// If this will ever be extended make sure to make adjustments here and nowhere else.

const CONFIGURATIONS = {
  standard: [
    {name: 'shingles', path: '/shingles', title: 'build_your_roof.shingles', customizeName: 'shingles'},
    {name: 'ice-water', path: '/ice-water', title: 'build_your_roof.ice_water_menu', customizeName: 'barriers'},
    {name: 'underlayment', path: '/underlayment', title: 'build_your_roof.underlayment_menu', customizeName: 'underlayments'},
    {name: 'starter-shingles', path: '/starter-shingles', title: 'build_your_roof.starter_shingles', customizeName: 'starterShingles'},
    {name: 'hip-ridge', path: '/hip-ridge', title: 'build_your_roof.hip_ridge_menu', customizeName: 'ridges'},
    {name: 'ventilation', path: '/ventilation', title: 'build_your_roof.ventilation', customizeName: 'exhaustVentilations'},
    {name: 'summary', path: '/summary', title: 'build_your_roof.summary', customizeName: null},
  ],
  ice_water_first: [
    {name: 'ice-water', path: '/ice-water', title: 'build_your_roof.ice_water_menu', customizeName: 'barriers'},
    {name: 'underlayment', path: '/underlayment', title: 'build_your_roof.underlayment_menu', customizeName: 'underlayments'},
    {name: 'starter-shingles', path: '/starter-shingles', title: 'build_your_roof.starter_shingles', customizeName: 'starterShingles'},
    {name: 'shingles', path: '/shingles', title: 'build_your_roof.shingles', customizeName: 'shingles'},
    {name: 'ventilation', path: '/ventilation', title: 'build_your_roof.ventilation', customizeName: 'exhaustVentilations'},
    {name: 'hip-ridge', path: '/hip-ridge', title: 'build_your_roof.hip_ridge_menu', customizeName: 'ridges'},
    {name: 'summary', path: '/summary', title: 'build_your_roof.summary', customizeName: null},
  ]
}

const loadVariantFromStore = () => {
  // Load byrConfig.stepOrder

  // We're loading either from customizer state or byr state, depending on where we are
  let order;

  order = get(store.getState(), 'byrEditor.configuration.configuration.stepOrder');
  if(isEmpty(order))  {
    order = get(store.getState(), 'byrConfig.stepOrder');
  }

  if(isEmpty(order)) order = 'standard';

  return order;
}
//const DEFAULT_VARIANT = 'ice_water_first';
export const StepSetup = ({variant = loadVariantFromStore()} = {}) =>  {

  return CONFIGURATIONS[variant].map(s => s.name);
}

export const CustomizeStepSetup = ({variant = loadVariantFromStore()} = {}) => {
  return CONFIGURATIONS[variant]
    .filter(s => s.customizeName)
    .map(s => s.customizeName);
}

export const StepConfiguration = ({variant} = {variant: loadVariantFromStore()}) => {
  return CONFIGURATIONS[variant];
}

export const XInitialRedirector = ({stepOrder}) => {
  // QueryParams

  if(!stepOrder) return null;
  // window.location.search is set to preserve queryparams
  return <Redirect to={CONFIGURATIONS[stepOrder][0].path + window.location.search}/>
}

export const getNextStep = (current, variant = loadVariantFromStore()) => {
  const steps = StepSetup({variant});
  let nextIndex = steps.indexOf(current) + 1;

  console.log(nextIndex, steps[nextIndex]);
  return steps[nextIndex];
}

export const getPrevStep = (current, variant = loadVariantFromStore()) => {
  const steps = StepSetup({variant});
  let prevIndex = steps.indexOf(current) - 1;

  return steps[prevIndex];
}
export const InitialRedirector = connectOrder(XInitialRedirector);

export const XOrderedMenuItems = ({t, stylesheets, variant = loadVariantFromStore()}) => {
  return (
    <ul css={stylesheets.menu_navigation}>
      {CONFIGURATIONS[variant].map((step, idx) => (
        <li css={idx === CONFIGURATIONS[variant].length - 1 && stylesheets.mobileOnly}>
          <Step path={step.path} step={idx + 1} title={t(step.title)}  />
        </li>
      ))}
    </ul>
  )
}



export const OrderedMenuItems = connectLocation(XOrderedMenuItems);

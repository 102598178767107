import { PureComponent, memo } from 'react';

import { joinPath } from '../../../PageBuilder/helpers/path';
import { apiDelete } from '../../../PageBuilder/helpers/api';

import Variants from './Variants';
import Flags from './Flags';

import _ from 'lodash';
import { localeSort } from '../../../location/locales';

const deleteConfirmationMessage = "Are you sure you want to delete?\n\nYou will not be able to undo this action.";

class InternalBuild extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.getSummaryState(props.language_summary);
  }

  getSummaryState = (summary) => {
    const itemPath = joinPath(this.props.path, this.props.slug);
    const contentPathLookupResult = this.props.existingContentPathLanguageLookup[itemPath];
    const count = UI.Build.Status.Count(summary);
    const warnings = []
    if (!contentPathLookupResult) {
      count.Warning = 1;
      warnings.push('Missing route to item');
    }
    const redirects = summary ? _.uniq(Object.values(summary).map(l => l.published_redirect)) : [];
    const redirect = _.first(redirects);
    const itemRedirected = redirect && redirects.length === 1;
    const usedLocales = Object.keys(summary).filter(key => summary[key].edited_by);
    const actions = [
      this.props.allowRedirect && {
        label: itemRedirected ? 'Manage Redirect...' : 'Redirect...',
        icon: 'share',
        onClick: () => Board.modal.open('redirect_all', null, redirect, {
          item: this.props.id,
          path: this.props.path,
          slug: this.props.slug,
          exists: redirect ? 'true' : 'false'
        })
      },
      {
        label: 'Duplicate',
        icon: 'files-o',
        onClick: () => Board.modal.open('duplicate', null, null, {
          item: this.props.id,
          paths: this.props.paths
        })
      },
      !count.Published && !count.Redirected && {
        label: 'Delete',
        icon: 'trash',
        onClick: () => {
          if (confirm(deleteConfirmationMessage)) {
            apiDelete(`/api/v1/cms/sites/${PB_SITE}/contents/${ this.props.id }`)
              .then(response => this.props.onDelete(this.props.id));
          }
        }
      }
    ].filter(Boolean)
    const pathAvailableLocales = contentPathLookupResult ? Object.keys(contentPathLookupResult) : [];
    const addableLocales = pathAvailableLocales.filter(l => usedLocales.indexOf(l) === -1)
    const languageOptions = new Set(pathAvailableLocales.map(language => ({
      value: language,
      label: this.props?.languages?.['en-US']?.[language]?.locale_name,
      disabled: usedLocales.indexOf(language) !== -1,
      title: usedLocales.indexOf(language) !== -1 ? 'This language already exists' : null
    })))

    return { summary, count, usedLocales, actions, addableLocales, languageOptions, warnings };
  }

  setSummaryState = (summary) => {
    this.setState(this.getSummaryState(summary))
  }

  get definition() { return this.props.type }

  render() {
    const { id, name, path, slug, allowRedirect } = this.props;

    const languages = this.props.languages;
    const { summary, count, usedLocales, actions, addableLocales, languageOptions, warnings } = this.state;
    const itemPath = Object.values(summary)[0]?.edited_route

    const addable = addableLocales.length > 0;
    const redirects = summary ? _.uniq(Object.values(summary).map(l => l.published_redirect)) : [];
    const redirect = _.first(redirects);
    const itemRedirected = redirect && redirects.length === 1;
    const childrenAvailable = !itemRedirected

    return <>
      <UI.List.Item
        fullWidth
        standalone
        showChildren={ childrenAvailable }
        key={ usedLocales.join('/') }
        title={ itemRedirected ? `<em>${ name }</em>` : name }
        subtitle={ itemRedirected ? <em><a target="_blank" href={ itemPath }>{ itemPath }</a> => <a target="_blank" href={ redirect.target }>{ redirect.target }</a></em> : <a target="_blank" href={ itemPath }>{ itemPath }</a> }
        tools={
          <>
            <Flags list={
              [
                itemRedirected && [ Flags.Status.Redirected, usedLocales.length ],
                !itemRedirected && count.Redirected && [ Flags.Status.Redirected, count.Redirected ],
                !itemRedirected && count.Published && [ Flags.Status.Published, count.Published ],
                !itemRedirected && count.Draft && [ Flags.Status.Draft, count.Draft ],
                !itemRedirected && count.Warning && [ Flags.Status.Warning, count.Warning, warnings.join('\n') ]
              ].filter(Boolean)
            } />
            <UI.Dots actions={ actions } />
          </>
        }
      >
        <Variants
          definition={ this.definition }
          list={
            usedLocales.sort((a, b) => localeSort({ code: a }, { code: b })).map((locale) => {
              const language = summary[locale];
              const editor = this.props.users?.[language.edited_by]?.name;
              const publisher = this.props.users?.[language.published_by]?.name;
              return {
                id: id,
                locale: locale,
                locale_name: languages['en-US'][locale].locale_name,
                published_route: language.published_route,
                name: language.edited_breadcrumb || language.published_breadcrumb,
                edited: { by: editor, at: UI.Build.Status.Stamp.Edit(language) },
                published: language.published_at ? { by: publisher, at: UI.Build.Status.Stamp.Publish(language) } : null,
                redirect: language.published_redirect,
                status: UI.Build.Status.Flags(language)
              }
            })
          }
          onRedirect={ allowRedirect ? variant => Board.modal.open('redirect_one', null, variant.redirect, {
            item: id,
            path: path,
            slug: slug,
            locale: variant.locale,
            exists: variant.redirect ? 'true' : 'false'
          }) : undefined }
          onDuplicate={ addable && ((variant) => Board.modal.open('languageDuplicate', null, null, {
            item: id,
            locale: variant.locale,
            languages: languageOptions
          })) }
          onDelete={ (variant) => {
            if (confirm(deleteConfirmationMessage)) {
              apiDelete(`/api/v1/cms/sites/${PB_SITE}/contents/${ id }/languages/${ variant.locale.toLowerCase() }`)
                .then(response => this.setSummaryState(_.omit(this.state.summary, variant.locale)));
            }
          } }
        />
        <UI.List.Adder
          singular="New Language / Location"
          filled="All Languages / Locations Accounted For"
          full={ !addable }
          onClick={ addable && (() => Board.modal.open('languageCreate', null, null, {
            languages: languageOptions,
            paths: this.props.paths,
            item: id
          })) }
        />
      </UI.List.Item>
    </>
  }
}

const Build = memo(InternalBuild);

export default Build;

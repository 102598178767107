import React, { useState } from 'react';

import CategoryList from './CategoryList';
import SheetActions from './SheetActions';
import SheetOptions from './SheetOptions';
import SheetOverview from './SheetOverview';
import sheetData from '../../../../../submittal_sheets/L77-Loosefill.json';

const SHEET_NAME = 'L77-Loosefill';

function L77Loosefill() {
  const [contractor, setContractor] = useState('');
  const [project, setProject] = useState('');
  const [selectedIds, setSelectedIds] = useState([]);
  const [specifications, setSpecifications] = useState([]);

  const { meta } = sheetData;
  const { tabularOptions, allowSpecifications, optionIdentifier } = meta;
  const { overview } = sheetData;
  const { categories } = sheetData;

  const selectId = id => {
    const currentIds = selectedIds.slice(0);
    currentIds.push(id);
    setSelectedIds(currentIds);
  };

  const removeId = id => {
    const filteredIds = selectedIds.filter(selectedId => selectedId !== id);
    setSelectedIds(filteredIds);
  };

  const isSelected = id => selectedIds.includes(id);

  const updateSpecifications = (categoryId, value) => {
    const currentSpecifications = specifications.slice(0);
    currentSpecifications[categoryId] = value || null;

    setSpecifications(currentSpecifications);
  };

  return (
    <div>
      <SheetOptions
        contractor={contractor}
        project={project}
        setContractor={setContractor}
        setProject={setProject}
      />
      <SheetOverview
        description={overview.description}
        compliance={overview.compliance}
      />
      <CategoryList
        categories={categories}
        tabularOptions={tabularOptions}
        selectId={selectId}
        removeId={removeId}
        isSelected={isSelected}
        updateSpecifications={updateSpecifications}
        specifications={specifications}
        allowSpecifications={allowSpecifications}
      />
      <SheetActions
        sheetName={SHEET_NAME}
        contractor={contractor}
        project={project}
        categories={categories}
        selectedIds={selectedIds}
        isSelected={isSelected}
        optionIdentifier={optionIdentifier}
        specifications={specifications}
      />
    </div>
  );
}

export default L77Loosefill;

import { useRef, useEffect } from 'react';

// Supports multiple refs to the same element
// https://medium.com/trabe/keep-two-refs-in-sync-using-a-custom-react-hook-c47c61e43e8f
const useSyncedRef = outerRef => {
  const innerRef = useRef();

  useEffect(() => {
    if (!outerRef) return;

    // handle callback refs
    if (typeof outerRef === "function") {
      outerRef(innerRef.current);
    }

    // handle object refs
    else {
      outerRef.current = innerRef.current;
    }
  });

  return innerRef;
};

export default useSyncedRef

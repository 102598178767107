import _ from 'lodash';
import {
  SET_URL_PARAM,
  SET_ALL_URL_PARAMS,
} from '../actions/URLSearchParamActions';
import { getParams } from '../../components/helpers/params';

const URLSearchParamReducer = (state = getParams(), action) => {
  switch (action.type) {
    case SET_URL_PARAM:
      const newState = _.cloneDeep(state);
      const { key, value } = action;
      if (_.isNil(value) || value === '') {
        delete newState[key];
      } else {
        newState[key] = value;
      }
      return _.assign({}, newState);
    case SET_ALL_URL_PARAMS:
      const { params } = action;
      return _.cloneDeep(params);
    default:
      return state;
  }
};

export default URLSearchParamReducer;

import { define } from '@owenscorning/pcb.alpha';

import AceEditor from "react-ace";
import { css } from '@emotion/react';

import "ace-builds/webpack-resolver"
import Beautify from 'ace-builds/src-noconflict/ext-beautify';
import { useCallback, useEffect, useRef, useState } from 'react';

const JsonEditor = ({ path, value, onChange, readOnly }) => {
  const ref = useRef();
  useEffect(() => ref.current ? Beautify.beautify(ref.current.editor.session) : null, []);

  const [error, setError] = useState();
  const localOnChange = useCallback((v) => {
    try {
      onChange ? onChange(JSON.parse(v)) : null
      setError(null)
    } catch (e) {
      setError(e.message)
    }
  }, [onChange])

  return (
    <>
      <div css={ css`
        width: 100%;
        height: 100%;
        min-height: 400px;
        display: flex;
        gap: 16px;
        & > div {
          width: 100%;
          overflow-y: auto;
        }
      ` } >
        <div>
          <AceEditor
            ref={ ref }
            mode="json"
            theme="monokai"
            readOnly={ readOnly }
            defaultValue={ value ? JSON.stringify(value, null, 2) : value }
            onChange={ localOnChange }
            name={ `repl${ path }` }
            tabSize={ 2 }
            fontSize={ 16 }
            width="100%"
            height="100%"
            wrapEnabled={ true }
            showPrintMargin={ false }
            commands={ Beautify.commands }
            editorProps={{ $blockScrolling: true }}
            setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false
            }}
          />
        </div>
      </div>
      { error &&
        <UI.Paragraph red >
          Failed to parse!<br/>
          <pre>Error: { error }</pre>
        </UI.Paragraph>
      }
    </>
  );
}

export default define`Json`('0.0.1')({
  edit: [
    ({ path, value, onChange, readOnly }) => {
      return (
        <>
          <JsonEditor path={ path }
                      value={ value }
                      onChange={ onChange }
                      readOnly={ readOnly }/>
        </>
      )
    }
  ],
});

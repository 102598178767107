import { jsx } from '@emotion/react';
import { connectLocation } from "../../../location/LocationConnectors";
import styled from "@emotion/styled";

const ContainerElement = styled.div`
  display: none;
  margin-bottom: 0.5em;
  position: relative;
  width: 100%;

  @media screen and (max-width: 1199px) {
    display: none;
  }

  @media screen and (min-width: 1200px) {
    margin-left: 1em;
    max-width: calc(50% - 1em);
  }

  &.active {
    display: inline-block;

    @media screen and (max-width: 1199px) {
      margin-left: 0;
    }
  }
`;

const SecondaryContentContainer = connectLocation(({ t, secondaryActive, children }) => {
  let classes = secondaryActive ? "active" : null;

  return (
    <ContainerElement id="secondary-tab" className={classes}>
      {children}
    </ContainerElement>
  )
});

// MapContainer.WrappedComponent.displayName = 'MapContainer';

export default SecondaryContentContainer;

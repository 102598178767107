import { connectLocation } from '../../location/LocationConnectors';
import { useState } from 'react';
import styled from '@emotion/styled';
import wrappedInReduxProvider from '../../wrappedInReduxProvider';
import Linkedin from '../../Builder/Library/Images/Social/Linkedin'
import Facebook from '../../Builder/Library/Images/Social/Facebook'
import Twitter from '../../Builder/Library/Images/Social/TwitterSquare'
import Youtube from '../../Builder/Library/Images/Social/Youtube'
import Instagram from '../../Builder/Library/Images/Social/Instagram'
import Text from '../../OC/oc-text'
import { isItemVisible } from '../../PageBuilder/helpers/content.js';

const restructureHelpers = {
    setImageIconToList: (arr = [], { aria_label, link }) => {
        if (arr?.length === 0 || arr[arr?.length - 1]?.['details']?.['iconList']?.length === 5) {
            const listCopy = [...arr];
            listCopy.push({
                details: {
                    is_icon: true,
                    iconList: [{
                        selected: aria_label?.toLowerCase(),
                        url: link
                    }],
                },
            });
            return listCopy;
        }
        if (arr?.length > 0 && !arr[arr?.length - 1]['details']?.['iconList']) {
            const listCopy = [...arr];
            listCopy[listCopy?.length - 1]['details'] = {
                ...listCopy[listCopy?.length - 1]?.['details'],
                is_icon: true,
                iconList: [{
                    selected: aria_label?.toLowerCase(),
                    url: link
                }],
            };
            return listCopy;
        }
        if (arr?.length > 0 && arr[arr?.length - 1]['details']['iconList']) {
            const listCopy = [...arr];
            listCopy[listCopy.length - 1]['details']['iconList'].push({
                selected: aria_label?.toLowerCase(),
                url: link
            });
            return listCopy;
        }
    },
    setTitleToList: (arr = [], { title }) => {
        const listCopy = [...arr];
        listCopy.push({
            details: {
                is_icon: true,
                subheading: title
            },
        })
        return listCopy
    },
    splitBottomLinks: (bottomLinks = []) => {
        const half = Math.ceil(bottomLinks.length / 2)

        return {
            left: bottomLinks?.slice(0, half),
            right: bottomLinks?.slice(half)
        }

    }
}

const restructureLegacyData = {
    headerDetailsFn: (heading) => ({
        has_header: heading ? true : false,
        title: heading
    }),
    footerItemsFn: (cleanGroups = []) => (
        cleanGroups.map(({ title, links, html_class = null, always_expanded }) => {
            return {
                details: {
                    title,
                    always_expanded
                },
                children: html_class != 'social-media-links' ?
                    links.map(({ title, link }) => (
                        {
                            details: {
                                title,
                                url: link
                            }
                        }
                    ))
                    :
                    links.reduce((prev, current) => {
                        if (current.image) {
                            return restructureHelpers.setImageIconToList(prev, current);
                        }

                        if (current.title) {
                            return restructureHelpers.setTitleToList(prev, current)
                        }
                        return prev;
                    }, [])
            }
        })
    ),
    copyrightFn: (registeredStatement) => ({
        paragraph: registeredStatement
    }),
    quickLinksFn: (bottomLeftLinks, bottomRightLinks, bottomLinks) => {
        console.log("split", restructureHelpers.splitBottomLinks(bottomLinks).left, restructureHelpers.splitBottomLinks(bottomLinks).right)
        bottomLeftLinks = bottomLinks ? restructureHelpers.splitBottomLinks(bottomLinks).left : bottomLeftLinks
        bottomRightLinks = bottomLinks ? restructureHelpers.splitBottomLinks(bottomLinks).right : bottomRightLinks
        return {
            left: bottomLeftLinks?.map(({ text, path, title, link }) => (
                {
                    title: text || title,
                    url: path || link
                }
            )),
            right: bottomRightLinks?.map(({ text, path, title, link }) => (
                {
                    title: text || title,
                    url: path || link
                }
            ))
        }
    },
    removeEmptyfooterItems: (groups = []) => (
        groups.map((x) => {
            if (x?.html_class != 'social-media-links') {
                return {
                    ...x,
                    links: x?.links?.filter(({ title, link }) => title.trim() && link.trim() ? true : false)
                }
            }
            return x
        })
    )
}

const FooterContainer = styled.footer`
  background-color: #E6E6E6;
  margin: auto;
  max-width: max-content;
  position: relative;

  @media screen and (min-width: 1200px) {
    max-width: 1440px;
  }

  &.tenant-paroc {
    padding-top: 35px;

    &:before {
      background-image: repeating-linear-gradient(307.5deg, #CE1126 -4.7%, #CE1126 6.5%, #FFFFFF 0, #FFFFFF 19%);
      content: "";
      display: inline-block;
      height: 16px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      @media screen and (min-width: 768px) {
        background-image: repeating-linear-gradient(307.5deg, #CE1126 -2.8%, #CE1126 4.4%, #FFFFFF 0, #FFFFFF 11.2%);
      }

      @media screen and (min-width: 1200px) {
        background-image: repeating-linear-gradient(307.5deg, #CE1126 0, #CE1126 3.8%, #FFFFFF 0, #FFFFFF 7.4%);
      }
    }
  }
`;

const FooterElements = styled.div`
    margin: auto;
    ul{
        list-style: none;
        padding-left: 0;
    }
    a{
        text-decoration: none;
    }

    padding: 20px 15px;

    .links{
        &:hover {
            text-decoration: underline;
        }
        &:focus {
            text-decoration: underline;
        }
        @media screen and (min-width: 768px) {
            margin-left: 5px
        }
    }
    @media screen and (min-width: 768px){
        max-width: 800px
    }
    @media screen and (min-width: 1200px){
        max-width: 1185px
    }
`

const ContentsContainer = styled.div`
    display: grid;
    width: 100%;
    margin-bottom: 15px;

    @media screen and (min-width: 768px){
        grid-template-columns: repeat(2, 1fr);
        row-gap: 10px;
    }

    @media screen and (min-width: 1200px){
        display: flex;
        flex-wrap: nowrap;
        gap: 10px
    }
`

const Column = styled.ul`
    margin: 0;
    .column--list{
        padding: 0;
        margin: 0
    }
    border-bottom: 1px solid white;

    .collapsible__content{
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition: all 0.2s;
    }

    .collapsible--expanded{
        .icon--chevron{
            transform: rotate(90deg);
        }
    }

    .collapsible--expanded + .collapsible__content {
        max-height: 100%;
        opacity: 1;
    }
    @media screen and (min-width: 768px) {
        border-bottom: 0;
        .collapsible__content{
            max-height: 100%;
            opacity: 1;
        }
    }
    @media screen and (min-width: 1200px){
        flex: 1;
    }
    .collapsible__content{
        &.always_expanded{
            max-height: 100%;
            opacity: 1;
            .icon--chevron{
                visibility: hidden;
            }
        }
    }
    .column--list{
        .icon--chevron{
            &.hide_icon{
                visibility: hidden;
            }
        }
    }
`

const ColumnTitle = styled.h3`
    font-family: "OCRoboto", sans-serif;
    text-transform: none;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin: 0;
    margin-bottom: 5px;
    padding: 20px 0;

    @media screen and (min-width: 768px) {
        padding: 0px;
        margin-bottom: 10px;
        margin-left: 5px
    }
`

const LinkList = styled.li`
    &.column--list_item{
        margin: 5px;
        margin-bottom: 9px;
        margin-left: 0px;
    }

    a{
        font-family: "OCRoboto", sans-serif;
        font-size: 18px;
        display: block;
        color: black;
        text-decoration: none;
        padding: 10px 0;
        cursor: pointer;
    }

    @media screen and (min-width: 768px) {
        a{
            padding: 0px;
            font-size: 16px;
        }
    }

    &.icon__container{
        .icon--group {
            display: flex;
            align-items: center;
            padding-left: 5px;
            margin-bottom: 8px;
            margin-top: 3px;
        }

        .icon--group{
            .icon--wrapper {
                cursor: pointer;
                position: relative;
                padding: 10px;
                background: linear-gradient(black, black);
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: 0% 0%;
                transition: background-size .1s;
                &:hover{
                    background-size: 100% 100%;
                    svg{
                        fill: #E6E6E6;
                    }
                }
            }
        }
    }
    b{
        font-family: "OCRoboto", sans-serif;
        text-transform: none;
        font-size: 18px;
    }
    @media screen and (min-width: 768px){
        b{
            font-size: 16px;
        }
    }
`

const TitleContainer = styled.span`
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    &:focus {
        outline: 2px solid #d40f7d;
        outline-offset: 2px;
      }

    i {
        margin-top: auto;
        margin-bottom: auto;
        transform: rotate(-90deg);
        font-size: 20px;
        margin-right: 10px;
        font-family: icomoon;
        font-weight: 700;
        @media screen and (min-width: 768px) {
            display: none;
        }
    }

    @media screen and (min-width: 768px){
        cursor: default;
        &:focus {
            outline: none;
          }
    }
`

const BottomLinksContainer = styled.div``

const BottomLinksContents = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    a{
        font-family: "OCRoboto", sans-serif;
        font-size: 14px;
        display: block;
        color: black;
        text-decoration: none;
        padding: 10px 0;
        cursor: pointer;
    }

    ul{
        margin-top: 10px;
        margin-bottom: 8px;
        li{
            margin: 0px;
        }
    }

    @media screen and (min-width: 768px) {
        border-top: 2px solid black;
        display: flex;
        justify-content: space-between;

        ul {
            display: flex;
            align-items: space-betwen;
            column-gap: 25px;
        }
    }

    @media screen and (min-width: 1200px){
       a{
            font-size: 16px;
       }
    }
`

const CopyrightBody = styled.p`
    font-size: 12px;
    margin: 10px 0 5px;

    a {
        color: black;
        display: block;
        padding: 10px 0;
        cursor: pointer;
    }

    @media screen and (min-width: 768px) {
        a{
            display: inline;
            padding: 0px;
        }
    }
`

const FooterHeader = styled.h2`
  margin: 18px 0px 31px 0px;
`;

const svgIcon = {
  linkedin: Linkedin,
  instagram: Instagram,
  youtube: Youtube,
  twitter: Twitter,
  instagram: Instagram,
  facebook: Facebook
};

const FooterColumn = ({ details, children, index }) => {
    const [selectedExpandables, setSelectedExpandables] = useState({})
    const handleToggle = (value) => () => {
        const copy = { ...selectedExpandables }

        if (copy[value]) {
            delete copy[value]
            return setSelectedExpandables(copy)
        } else {
            copy[value] = true
            return setSelectedExpandables(copy)
        }
    }

    return <Column key={index} aria-hidden="true">
        <li className='column--list'>
            <TitleContainer
                tabIndex="0"
                className={selectedExpandables[index] ? 'collapsible--expanded' : ''}
                onClick={handleToggle(index)}
            >
                <ColumnTitle>
                    {details?.title}
                </ColumnTitle>
                <i
                    className={ `icon-chevron-left icon--chevron ${details?.always_expanded ? 'hide_icon' : ''}` }
                />
            </TitleContainer>
            <ul
                aria-hidden="true"
                className={ `collapsible__content ${details?.always_expanded ? 'always_expanded' : ''}`}
            >
                {children?.filter((item) => isItemVisible(item))?.map(({ details }, index) => (
                    <LinkList className={!details?.is_icon ? 'column--list_item' : 'icon__container'}
                        key={index}
                    >
                        {!details?.is_icon && <a
                            data-track="nav-click"
                            data-track-nav-loc="footer"
                            data-track-top-level={details?.title}
                            data-track-sub-level={details?.title}
                            aria-label={details?.title}
                            href={details?.url}
                            className='child--item_link links'
                        >
                            { details?.title }
                        </a>}
                        {details?.is_icon &&
                            <>
                                {details?.subheading && <b>{details?.subheading}</b>}
                                <span className='icon--group'>
                                    {details?.iconList?.map(({ selected, url }, i) => (
                                        selected && <a
                                            className='icon--wrapper'
                                            data-track="nav-click"
                                            data-track-nav-loc="footer"
                                            data-track-top-level="Social Media"
                                            data-track-sub-level={
                                                `${selected} ${details?.subheading ? `-${details?.subheading}` : ``}`
                                            }
                                            aria-label={selected}
                                            target="_blank"
                                            rel=""
                                            href={url}
                                        >
                                            {selected && svgIcon?.[selected] && svgIcon?.[selected]()}
                                        </a>
                                    ))}
                                </span>
                            </>
                        }
                    </LinkList>
                ))}
            </ul>
        </li>
    </Column>
}

const QuickLinks = ({ leftAndRight = [] }) => (
  <BottomLinksContents>
    {leftAndRight?.map((leftOrRight, currentIndex) => (
      currentIndex > 1 ? null :
        <ul>{leftOrRight?.map(({ title, behavior, url, functionName }, index) => {
          var clickFunction = (behavior === "function") ? PB_FUNCTIONS[functionName] : null;

          return (
            <li>
              <a
                aria-label={title}
                className='links'
                data-track-nav-loc="footer"
                data-track-sub-level={title}
                data-track-top-level={title}
                data-track="nav-click"
                href={url}
                onClick={clickFunction ? (event) => clickFunction(event) : null}
              >
                {title}
              </a>
            </li>
          );
        })}</ul>
    ))}
  </BottomLinksContents>
);

const CopyrightContent = ({ url, paragraph }) => (
  <CopyrightBody>
      <a
        href={ url || '/copyright' }
        className='links'
      >
        © 1996–{ `${new Date().getFullYear()}` } Owens Corning.
      </a>&nbsp;
      <Text content={ paragraph } />
  </CopyrightBody>
);

const Footer = connectLocation(({ t, locale: { code }, headerDetails, footerItems, quickLinks, copyright, ...legacyFooterData }) => {
  let footerClasses = ["oc-footer"];
  if (PB_SITE == "www.paroc.com") footerClasses.push("tenant-paroc");

  if (legacyFooterData?.groups && !headerDetails, !footerItems, !quickLinks, !copyright) {
    const {
      heading,
      groups,
      registered_statement : registeredStatement,
      bottom_left_links : bottomLeftLinks,
      bottom_right_links : bottomRightLinks,
      bottom_links : bottomLinks
    } = legacyFooterData

    const {
      headerDetailsFn,
      footerItemsFn,
      copyrightFn,
      quickLinksFn,
      removeEmptyfooterItems
    } = restructureLegacyData;

    headerDetails = headerDetailsFn(heading);
    footerItems = footerItemsFn(removeEmptyfooterItems(groups));
    quickLinks = quickLinksFn(bottomLeftLinks, bottomRightLinks, bottomLinks);
    copyright = copyrightFn(registeredStatement)
  }

  return (
    <FooterContainer className={footerClasses.join(" ")}>
      <FooterElements>
        {headerDetails?.has_header && <FooterHeader>{headerDetails?.title}</FooterHeader>}
        <ContentsContainer>
          {footerItems?.filter((item) => isItemVisible(item))?.map(({ details, children }, index) => (
            <FooterColumn details={details} children={children} index={index} />
          ))}
        </ContentsContainer>
        <BottomLinksContainer>
          <QuickLinks leftAndRight={[quickLinks?.left, quickLinks?.right]} />
        </BottomLinksContainer>
        <CopyrightContent
          url={copyright?.url}
          paragraph={copyright?.paragraph}
        />
      </FooterElements>
    </FooterContainer>
  );
})

export default wrappedInReduxProvider(connectLocation(Footer));

import { define, ui } from '@owenscorning/pcb.alpha';
import AlertMessage from '../../Page/AlertMessage';
import { stringsFor } from '../../../../../location/locales';
import _ from 'lodash';

export default define`Discontinued Banner`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/AlertMessage`,
  view: ({ value }) => {
    const locale = Board.build.language.toLocaleLowerCase();
    const productType = Board.build.type;
    const productUrls = {
      "Product::Roofing": `${location.origin}/${locale}/roofing/components/products`,
      "Product::Insulation": `${location.origin}/${locale}/insulation/products`
    }

    const props = _.merge({}, value, {
      dismissable: false,
      message: {
        ops: [
          {
            insert: `${_.get(stringsFor(Board.build.language), 'pdp.discontinued')} `
          },
          {
            attributes: { bold: true, link: productUrls[productType] },
            insert: _.get(stringsFor(Board.build.language), 'pdp.browse')
          }
        ]
      },
      type: "black"
    });
    return <AlertMessage.modes.view value={props} />;
  },
});

const handleExternalUrl = (url) => {
  if (!url) return null;

  if (
    url.startsWith('mailto:') ||
    url.startsWith('tel:') ||
    url.startsWith('#')
  ) {
    return url;
  }

  if (url.startsWith('//')) {
    return url;
  }

  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`;
  }

  return url;
};

export default handleExternalUrl;

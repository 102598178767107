import { Desktop, Tablet, Mobile } from "./Devices";

const Gutters = {
  base: {
    [Desktop]: 85,
    [Tablet]: 15,
    [Mobile]: 15
  },
  Article: {
    [Desktop]: 285,
    [Tablet]: 95,
    [Mobile]: 15
  }
};

export default (type) => Gutters[type] || Gutters.base;

import { jsx } from '@emotion/react';
import { connectLocation } from "../../../location/LocationConnectors";
import styled from "@emotion/styled";

const ContainerElement = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;

  @media screen and (max-width: 1199px) {
    display: none;
  }

  @media screen and (min-width: 1200px) {
    margin-right: 1em;
    max-width: calc(100% - (270px + 2em));
  }

  &.active {
    display: inline-block;
    margin-right: 0;
  }
`;

const PrimaryContentContainer = connectLocation(({ t, primaryActive, children, filterContent }) => {
  let classes = primaryActive ? "active" : null;

  const NoMaxWidthStyle = {
    maxWidth: 'none'
  }

  return (
    <ContainerElement className={classes} data-am-region="Filter Results" id="primary-tab" style={filterContent === undefined ? NoMaxWidthStyle : null}>
      {children}
    </ContainerElement>
  )
});

// ListContainer.WrappedComponent.displayName = 'ListContainer';

export default PrimaryContentContainer;

export default {
  displayName: 'Attention Bar with Image',
  allowableParents: [],
  migrator: (data) => {
    const { image } = data;
    if (image && image.img) {
      data = { ...data, image: { file: image.img, alt: image.alt } }
    }
    const { layout, content, ...rest } = data;
    data = {
      ...rest, component: 'AttentionBarPicker', settings: { attentionBarType: 'withImage', closeButton: false },
      layoutWithImage: layout,
      contentWithImage: content,
      imageWithImage: data.image
    }
    return data;
  },
};

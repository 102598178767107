import { mediaToMediaNew } from '../../../migrations';

export default {
  displayName: "Gallery",
  allowableParents: ["Section"],
  migrator: (data) => {
    const { items, ...rest } = data;
    const migrateItem = (item) => {
      const { uploader = {}, ...rest } = item;
      return { ...rest, uploader: { ...uploader, media: mediaToMediaNew(uploader.media) } }
    }
    return { ...rest, items: items?.map(migrateItem) || [] };
  }
};

import { define } from '@owenscorning/pcb.alpha';

export default define`Headings`('0.0.1')({
  batch: () => UI.Theme.Component.Group(
    'headings',
    ({ children, contents, __styles, __name, ...props }) => {
      const Tag = __name[0] == 'H' ? `h${ Number(__name[1]) }` : {
        Posthead: 'div',
        Prehead: 'div'
      }[ __name ] || 'div';
      return <Tag { ...props } ><UI.Text>{ children }</UI.Text></Tag>;
    }
  )
});

import anime from "animejs";
import moment from "moment";

const applyBlinkingAnimation = (target) => {
  anime({
    targets: `#${target}`,
    borderColor: ['#D40F7D', '#959595'],
    borderWidth: ['3px', '1px'],
    easing: 'easeInOutQuad',
    loop: 2,
    duration: 2000,
  })
};


const scrollTarget = (parentDiv, target) => {
  const sidebarContainer = document.getElementById(parentDiv);
  const targetElement = document.getElementById(target);
  if (sidebarContainer && targetElement) {
    const scrollDistance = targetElement.getBoundingClientRect().top - 250;

    sidebarContainer.scrollTo({
      top: scrollDistance,
      behavior: 'smooth'
    });
  }
}

export const highlightSettingsSection = async (parentDiv, target) => {
  applyBlinkingAnimation(target); //Apply border blinking effect
  const switchTab = () => {
    return new Promise((resolve) => {
      Board.Set({ sidebar: 'settings' }, () => {
        resolve();
      });
    });
  };

  // Function to scroll after a delay
  const scrollAfterDelay = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        scrollTarget(parentDiv, target);
      }, 300); // Adjust the delay as needed
    });
  };

  // Switch the tab and wait for it to complete
  await switchTab();

  // Scroll after the tab has been switched
  await scrollAfterDelay();
};

export const convertToISODate = (date) => {
  if (typeof(date) === 'object'){
    date = date.props.children
  }
  let dateObj = moment(date);
  if (dateObj.isValid()) {
    return dateObj.format('YYYY-MM-DD');
  } else {
    return 'Invalid Date';
  }
}

import styled from '@emotion/styled';
import Theme from '../../../themes';

const FilterPaneButtonsContainer = styled.div`
  background: #FFFFFF;
  border-top: 1px solid #D1D1D1;
  bottom: 0;
  display: ${props => props.visible ? 'flex' : 'none'};
  left: 0;
  position: absolute;
  width: 100%;

  @media(min-width: 1200px) {
    display: none;
  }
`;

const Button = styled.button`
  background-color: #FFFFFF;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  padding: 16px 8px;
  text-align: center;
  width: 50%;
`;

const ResetButton = styled(Button)`
  background-color: #FFFFFF;
  color: #000000;

  &:hover {
    background-color: #000000;
    color: #FFFFFF;
  }
`;

const ApplyButton = styled(Button)`
  background-color: ${Theme.colors.brand};
  color: #FFFFFF;

  &:hover {
    background-color: #FFFFFF;
    color: ${Theme.colors.brand};
  }
`;

const FilterPaneButtons = ({filterReset, apply, visible, count}) => {
  return(
    <FilterPaneButtonsContainer visible={visible}>
      <ResetButton data-track="reset" onClick={filterReset}>Reset</ResetButton>
      <ApplyButton
        aria-label="Close Modal"
        data-track="close-modal"
        data-track-modal-name="Filter Overlay"
        onClick={apply}
      >
        Apply ({count})
      </ApplyButton>
    </FilterPaneButtonsContainer>
  );
};

export default FilterPaneButtons;
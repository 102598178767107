import { jsx, css } from '@emotion/react';

export default ({ columns=4, gap=24 }) => (
  <div css={ css`
    display: grid;
    gap: ${ gap }px;
    grid-template-columns: repeat(${ columns }, 1fr);
  ` } >
    <Contents />
  </div>
);

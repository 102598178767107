import SprayFoamVentilationCalculator from '../../composites/SprayFoamVentilation/SprayFoamVentilationCalculator';
import RichText from './RichText';
import { BasicContent } from './BasicContent';


const CMSSprayFoamVentilationCalculator = ({content, calculator}) => {
  return (
    <>
      <BasicContent { ...content.copy } />
      <SprayFoamVentilationCalculator laborRate={calculator.laborRate}/>
      <RichText content={content.footnotes} />
    </>
  )
};

export default CMSSprayFoamVentilationCalculator;

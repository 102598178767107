import { define, ui } from '@owenscorning/pcb.alpha';
import { useState, useEffect, useMemo } from 'react';
import SealDefendBreathe from '../../../../ComponentLibrary/icons/roofing/SealDefendBreathe';
import qs from 'qs';
import MediaObject from '../../../../OC/oc-media-object';
import cms_api from '../../../../../cms_api';
import _ from 'lodash';


const insulationPdpData = _.memoize(
    async () => {
        return await cms_api.get_single({
          filter: {
            type: 'Cms::Content::Attribute',
            language_iso_code: Board.build.language,
            route: '/insulation',
            name: 'Insulation Certifications'
          },
          fields: {
            '*': 'contents'
          }
        })
    }
);


const getImages = (list = [], selected = {}) => {

    return list.filter((x) => selected[x?.value] && x?.image?.file).map((x) => (
        {
            image: x?.image,
            alt: x?.image?.alt,
            heading: x?.label
        }
    ))
}

export default define`Card Set`('0.0.1')({
    thumbnail: ui`Modules/Page/Thumbnails/MediaObject`,
    category: ui`Modules/Categories/Page/Customizable`,
    view: ({ value }) => {
        const [lookup, setLookup] = useState();
        const certifications = Board.settings.attributes?.certification;

        const selectedCertifications = certifications?.reduce((x, y) => ({ ...x, [y]: true }), {})
        useEffect(() => {
            insulationPdpData().then(lookup => setLookup(lookup))
        }, [])

        const getListImageCert = useMemo(() => {
            return getImages(lookup?.contents?.items, selectedCertifications)
        }, [lookup, selectedCertifications])

        if (_.isEmpty(certifications)) {
            return null;
        }

        if (!lookup) {
            return null;
        }

        return <UI.Modules.Page.CardSet
            value={
                {
                    cards: getListImageCert,
                    settings: {
                        cardType: "standard",
                        alignment: "Left",
                        itemsPerRow: 6,
                        containerSize: {
                            selection: "xl"
                        },
                        aspectRatio: {
                            custom: "0.01",
                            selection: "none"
                        },
                        itemsPerRowMobile: 1,
                        itemsPerRowTablet: 4
                    }
                }
            }
        />
    },
});



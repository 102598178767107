import { useRef, useCallback, useState } from "react";
import { throttle } from "lodash";
import { useAccessibleInteractivity } from "../../../hooks";
import Theme from '../../../themes/index';
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/react"
import MaskedInput from "react-text-mask";

// Components
import Label from "./oc-label";
import InputError from "./oc-input-error";

// Styled Components
const inputStyle = (props) => css`
  appearance: none; // Reset for mobile user agent styles
  border-color: ${props.valid ? '#585858' : '#940420' };
  border-radius: 0; // Reset for mobile user agent styles
  border-style: solid;
  border-width: 1px;
  color: ${props.valid ? '#585858' : '#940420' };
  font-family: OCRoboto;
  font-size: 16px;
  margin: 0; // Reset for mobile user agent styles
  padding: 14px 8px;
  width: 100%;

  ${props.type === "textarea" && `
    resize: none;
  `}

  &:focus, &:focus-visible {
    border-color: ${props.themeColor};
    outline: none;
  }

  &.disabled {
    border-color: #A8A8A8;
  }
`;

const Textarea = styled.textarea`
  ${inputStyle};
`;

const Input = styled.input`
  ${inputStyle};
`;

const Mask = Input.withComponent(MaskedInput);

const BaseInput = ({
  className = "",
  defaultValue,
  disabled = false,
  error,
  errorIcon = null,
  helperText,
  helperTextColor = null,
  inputType = "text",
  infoFunction,
  label,
  mask,
  name,
  themeColor = Theme.colors.brand,
  onBlur,
  onChange,
  pattern,
  placeholder,
  required = false,
  rows = 3,
  touched = false,
  valid = true,
  value,
  ...props
}) => {
  const input = useRef();
  const onChangeCallback = useCallback(throttle(e => onChange(input.current.value), 100, { leading: false }));
  const Component = mask ? Mask : ((inputType == "textarea") ? Textarea : Input);
  const defaultHelperText = helperText || (required ? "Required" : null)
  const textareaRows = (inputType == "textarea") ? rows : null;
  const validity = touched ? valid : true;
  const classList = className.split(" ");
  const inputTypes = ['text', 'textarea'];
  const [focused, setFocused] = useState(false);

  const handleClick = (event) => {
    const element = input.current;
    if (!element) return;
    if (!inputTypes.includes(inputType)) return;

    const style = window.getComputedStyle(element, null);
    if (!style) return;
    const pTop = parseInt(style.getPropertyValue('padding-top'), 10);
    const pRight = parseFloat(style.getPropertyValue('padding-right'));
    const pLeft = parseFloat(style.getPropertyValue('padding-left'));
    const pBottom = parseFloat(style.getPropertyValue('padding-bottom'));
    const width = element.offsetWidth;
    const height = element.offsetHeight;

    const rect = element.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const isPaddingClick = !((x > pLeft && x < width - pRight) &&
                              (y > pTop && y < height - pBottom));

    if (isPaddingClick && !focused) {
      const length = element.value.length;
      element.setSelectionRange(length, length);
      setFocused(true);
    }
  };

  if (disabled) classList.push("disabled");

  return (
    <>
      {(label || helperText) && <Label text={label} helperText={defaultHelperText} helperTextColor={helperTextColor} disabled={disabled} valid={validity} infoFunction={infoFunction} required={required} />}
        <Component
          className={classList.join(" ")}
          defaultValue={defaultValue}
          disabled={disabled}
          id={name}
          mask={mask}
          name={name}
          themeColor={themeColor}
          onBlur={(event) => {
            setFocused(false)
            if (onBlur) onBlur(event)
          }}
          onClick={handleClick}
          onChange={onChange || onChangeCallback}
          pattern={pattern}
          placeholder={placeholder}
          ref={input}
          required={required}
          rows={textareaRows}
          type={inputType}
          valid={validity}
          value={value}
          {...props}
        />
      {(error && touched) && <InputError message={error} icon={errorIcon} />}
    </>
  );
}

export default BaseInput;

BaseInput.propTypes = {
  /* HTML: Sets the disabled attribute on the input element */
  disabled: PropTypes.bool,
  /* HTML: Error message that is displayed under the input element */
  error: PropTypes.string,
  /* HTML: Label helper text, ex: 'Required' */
  helperText: PropTypes.string,
  /* HTML: The label text above the input element */
  label: PropTypes.string,
  /* HTML: Sets the name attribute on the input element */
  name: PropTypes.string,
  /* CSS: Hex value for color theme */
  themeColor: PropTypes.string,
  /* HTML: Sets the placeholder text on the input element */
  placeholder: PropTypes.string,
  /* HTML: Determines whether or not the input is required */
  required: PropTypes.bool,
  /* JSX: Controls the validated state of the label, input, and error message, etc */
  valid: PropTypes.bool,
  /* HTML: Sets the value of the number field */
  value: PropTypes.string
}

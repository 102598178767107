import { Helmet } from "react-helmet";
import Cta from './OC/oc-cta';
import { useEffect } from 'react';
import Theme from '../themes';

export default ({ pricespider_upc = '' }) => {
  useEffect(() => {
      if (typeof(PriceSpider) !== 'undefined') {
        PriceSpider.rebind();
      }
    },
    [pricespider_upc]
  );

  return pricespider_upc && (
    <>
      <Helmet>
        <meta name="ps-key" content="4018-5f11f0e7849974005070ca68" />
        <meta name="ps-country" content="US" />
        <meta name="ps-language" content="en" />
        <meta name="ps-track" content="false" />
        <script src="//cdn.pricespider.com/1/lib/ps-widget.js" async="async" />
        <style>{`
        a.ps-widget.ps-widget-new-brand[ps-sku],
        a.ps-widget.ps-widget-new-brand.ps-disabled[ps-sku] {
          background: ${ Theme.colors.brand };
          color: white;
          padding: 0 1rem;
          min-width: 170px;
          min-height: 35px;
          cursor: pointer;
          font-size: 18px;
          border: 1px solid ${ Theme.colors.brand };
          overflow: hidden;
          z-index: 1;
          -webkit-transition: 0.25s ease-in-out;
          transition: 0.25s ease-in-out;
          min-width: 220px !important;
        }

        a.ps-widget.ps-widget-new-brand[ps-sku]:hover .ps-button-label,
        a.ps-widget.ps-widget-new-brand.ps-disabled[ps-sku]:hover small,
        a.ps-widget.ps-widget-new-brand[ps-sku]:focus .ps-button-label,
        a.ps-widget.ps-widget-new-brand.ps-disabled[ps-sku]:focus small {
          text-decoration: none;
          color: ${ Theme.colors.brand };
          outline: 0;
        }
      `}</style>
      </Helmet>
      <Cta className="ps-widget ps-widget-new-brand"
           ps-sku={ pricespider_upc }
           data-track="open-modal"
           data-track-modal-name="Price Spider"
      />
    </>
  )
}

import styled from "@emotion/styled";
import CTAButton from "../OC/oc-cta";

const Wrapper = styled.div`
  margin-top: 25px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 3px;
`;

const ButtonWrapper = styled.div`
  flex: 1;
  text-align: center;
  margin-top: 3px;
`;

const InputWrapper = styled.div`
  flex: 1.5;
  text-align: center;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
`;

const Pager = styled.span`
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
`;

const PageJump = styled.div`
  display: inline-block;
  input {
    border-color: #2d2d2d;
    border-radius: 0;
    width: 70px;
    text-align: center;
    background: #fff;
    padding: 5px 7px;
    font-size: inherit;
    font-weight: 400;
    outline: none;
  }
`;

const TotalPages = styled.span`
  white-space: nowrap;
`;

const Pagination = ({value = 1, onChange, totalPages, showPageSize}) => (
  <div className="pagination-bottom">
    <Wrapper>
      <ButtonWrapper>
        <CTAButton color="white" disabled={ value <= 1 } onClick={() => onChange(+value - 1)}>
          Previous
        </CTAButton>
      </ButtonWrapper>
      <InputWrapper>
        <Pager>
          Page{' '}
          <PageJump><input type="number" value={ value } min="1" max={ totalPages } step="1" onChange={ e => onChange(e.target.value) } /></PageJump>
          {' '}of{' '}
          <TotalPages>{ totalPages }</TotalPages>
        </Pager>
      </InputWrapper>
      <ButtonWrapper>
        <CTAButton color="white" disabled={ value >= totalPages } onClick={() => onChange(+value + 1)}>
          Next
        </CTAButton>
      </ButtonWrapper>
    </Wrapper>
  </div>
);

export default Pagination;

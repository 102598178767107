import { Component, useState } from "react";
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import Cta from "../oc-cta";
import Image from "../oc-image";


const HeaderRow = styled.tr`
    text-align: left;
    width: 20%;
    min-width: 115px;
    font-weight: 700;
    border-bottom: 2px solid #BCBCBC;
    border-top: 2px solid #BCBCBC;
    font-size: 20px;
    text-transform: uppercase;
`;

const Offset = styled.tr`
    background-color: #f8f8f8;
    border-top: 1px solid #bcbcbc;
    border-bottom: 1px solid #bcbcbc;
    padding: 12px 8px 7px 8px;
    text-align: center;
    width: 20%;
    min-width: 115px;
`;

const DisplayTable = styled.table`
  border-collapse: collapse;
  font-size: 14px;
  overflow-x: auto;
  display: block;

  thead th {
    padding: 20px 15px;
    font-size: 30px
  }

  tbody td[scope="colspan"] {
    background-color: #e6e6e6;
    text-align: left;
    font-weight: 700;
  }

  td, th {
    padding: 12px 8px 7px 8px;
    text-align: center;
    width: 20%;
    min-width: 115px;
  }

   thead th:first-of-type {
    text-align: left;
  }

  tr > * {
    border-left: 1px solid #bcbcbc;
  }

  tr > :first-of-type {
    border-left: 0;
    text-align:left;
    width: 35%;
    min-width: 115px;
  }

`;

class ContractorNetworkTable extends Component {
  constructor(props) {
    super(props);
    this.modals = {
        preferred: {
          buttons: [],
          title: "UPGRADING YOUR PROGRAM MEMBERSHIP",
          body: () => (
            <>
              <p>Enrollment in the Owens Corning Roofing Preferred Contractor Network is by invitation only. Once enrolled as a Contractor Rewards member, contractors who meet the following eligibility requirements may be invited by their local Owens Corning Roofing Area Sales Manager to become a Preferred Contractor:</p>
              <ul>
                <li>Carry at least $1,000,000 in general liability insurance</li>
                <li>Have been screened for financial stability and customer service</li>
              </ul>
            </>
          )
        },
        platinum: {
          buttons: [],
          title: "UPGRADING YOUR PROGRAM MEMBERSHIP",
          body: () => (
            <>
              <p>Enrollment in the Owens Corning Roofing Platinum Contractor Network is by invitation only. As a
                Platinum Contractor, contractors who meet the following eligibility requirements may be invited by their
                local Owens Corning Roofing Area Sales Manager to become a Platinum Contractor:</p>
              <ul>
                <li>Passed an installation workmanship inspection</li>
                <li>Carry at least $1,000,000 in general liability insurance</li>
                <li>Have been screened for financial stability and customer service</li>
              </ul>
            </>
          )
        }
    }
  }



  render() {

    return (

       <DisplayTable>

         <thead>
         <tr>
           <th>Levels</th>
           <th>
             <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/v1626092989/roofing/contractor-network/contractor-rewards.png" alt="Owens Corning Contractor Rewards" style={{width: 121, height: 39, margin: 10}}/>
             <Cta href = "https://www.owenscorning.com/en-us/roofing/rewards-program/enroll" analytics = {{track: "learn-more", destination: "Rewards Program Enroll"}} >Join Today</Cta>
           </th>
           <th>
             <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/v1626093055/roofing/contractor-network/preffered-contractor.png" alt="Owens Corning Preferred Contractor" style={{width: 130, height: 39, margin: 10}}/>
             <Cta  onKeyPress={() => Board.modal.open(this.modals.preferred)} tabindex = "0" color = "white" onClick = {() => Board.modal.open(this.modals.preferred)} analytics = {{track: "learn-more", destination: "Preferred Pop UP"}} >Ask Today</Cta>
           </th>
           <th>
             <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/v1626093138/roofing/contractor-network/platinum-contractor.png" alt="Owens Corning Platinum Contractor" style={{width: 195, height: 39, margin: 10}}/>
             <Cta onKeyPress = {() => Board.modal.open(this.modals.platinum)} tabindex = "0" color = "white" onClick = {() => Board.modal.open(this.modals.platinum)} analytics = {{track: "learn-more", destination: "Preferred Pop UP"}} >Ask Today</Cta>
           </th>
         </tr>
         </thead>

         <tbody>
         <HeaderRow>
           <td><h4>Rewards</h4></td>
           <td></td>
           <td></td>
           <td></td>
         </HeaderRow>
          <tr>
            <td>Earn rewards on Owens Corning Purchases</td>
            <td><i className="fa fa-check" aria-hidden="true"></i></td>
            <td><i className="fa fa-check" aria-hidden="true"></i></td>
            <td><i className="fa fa-check" aria-hidden="true"></i></td>
          </tr>
         <Offset>
           <td>Access to seasonal product rebates</td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
         <HeaderRow>
           <td>Marketing</td>
           <td></td>
           <td></td>
           <td></td>
         </HeaderRow>
         <tr>
           <td>Online ordering for product literature and samples</td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <Offset>
           <td>Access to Owens Corning branded promotional merchandise</td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
         <tr>
           <td>Text messaging news updates</td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <Offset>
           <td>Monthly Owens Corning Roofing High Points eNewsletters with the latest news updates</td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
         <tr>
           <td>Access to full-service marketing support leveraging the power of the Owens Corning brand and Pink Panther™</td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <Offset>
           <td>Contractor listing on owenscorning.com</td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
          <HeaderRow>
          <td>Education</td>
            <td></td>
            <td></td>
            <td></td>
          </HeaderRow>
         <tr>
           <td>Access to Owens Corning University</td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <Offset>
           <td>In person training with sales expert Rodney Webb and other notable presenters</td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
         <tr>
           <td>Access to contractor education through the OCConnect™ Resource Center with NRCA</td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <HeaderRow>
          <td>Exclusive Offers</td>
           <td></td>
           <td></td>
           <td></td>
         </HeaderRow>
         <tr>
           <td>Ability to offer consumer financing at negotiated rates through Service Finance Company</td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <Offset>
           <td>Lead generation opportunities with key business service offers</td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
         <tr>
          <td>Ability to offer System Protection Limited Warranty</td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <Offset>
           <td>Lead generation opportunities with the Owens Corning Roofing Lead Management System</td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
         <tr>
           <td>Ability to offer the Preferred Protection System Roofing Limited Warranty</td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <Offset>
           <td>Business building resources to help grow your business</td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
         <tr>
           <td>Exclusive ability to offer the Platinum Protection System Roofing Limited Warranty</td>
           <td></td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <Offset>
           <td>Priority listing on owenscorning.com</td>
           <td></td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
         <tr>
           <td>Opportunity to attend Platinum Conference</td>
           <td></td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <Offset>
           <td>Eligible for Solar PROtect™ Certification</td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
         <HeaderRow>
           <td>Minimum Member Requirements</td>
           <td></td>
           <td></td>
           <td></td>
         </HeaderRow>
         <tr>
           <td>Sell Owens Corning Roofing and Roofing Accessories</td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <Offset>
           <td>Be a member of the Owens Corning Contractor Network</td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
         <tr>
           <td>Carry at least $1,000,000 in general liability insurance	</td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <Offset>
           <td>Hold licenses and other insurances as required by local, state or federal law</td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
         <tr>
           <td>A business credit rating that is satisfactory to Owens Corning and/or its affiliated entities</td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </tr>
         <Offset>
           <td>Have passed an installation workmanship inspection</td>
           <td></td>
           <td></td>
           <td><i className="fa fa-check" aria-hidden="true"></i></td>
         </Offset>
         <tr>
           <td></td>
           <td>
             <Cta href = "https://www.owenscorning.com/en-us/roofing/rewards-program/enroll" analytics = {{track: "learn-more", destination: "Rewards Program Enroll"}} >Join Today</Cta>
           </td>
           <td>
             <Cta onKeyPress = {() => Board.modal.open(this.modals.preferred)} tabindex = "0" color= "white" onClick = {() => Board.modal.open(this.modals.preferred)} analytics = {{track: "learn-more", destination: "Preferred Pop UP"}} >Ask Today</Cta>
           </td>
           <td>
             <Cta onKeyPress = {() => Board.modal.open(this.modals.platinum)} tabindex = "0" color = "white" onClick = {() => Board.modal.open(this.modals.platinum)} analytics = {{track: "learn-more", destination: "Preferred Pop UP"}} >Ask Today</Cta>
           </td>
         </tr>
         </tbody>

       </DisplayTable>
    );
  };
};

export default ContractorNetworkTable;

export default function getFormatFromMime(mime) {
  if (typeof mime != "string") {
    return null
  }
  else if (mime.includes("wordprocessingml")) {
    return 'docx';
  }
  else if (mime.includes("msword")) {
    return 'doc'
  }
  else if (mime.includes("pdf")) {
    return 'pdf';
  }
  else if (mime.includes("ms-excel") || mime.includes("spreadsheetml")) {
    return 'xls';
  }
  return null;
}

export default {
  displayName: 'Block Link Set',
  allowableParents: ['Section'],
  migrator: (data) => {
    if (data?.blocks?.length > 0) {
      if (data?.blocks.filter(list => Array.isArray(list)).length > 0) {
        // old array of array format
        const { blocks, ...rest } = data;
        data = { ...rest, blocks: blocks.map(list => ({row: list})) };
      }
    }
    return data;
  },
};

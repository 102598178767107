import {connect} from 'react-redux';
import _ from "lodash";
import {
  SHINGLES_FAVORITE_ADD,
  SHINGLES_FAVORITE_REMOVE,
  SHINGLES_FAVORITE_RESYNC
} from "../../../Actions";
import getReduxInitial from "../../../helpers/redux_initial";

// PYS Favorites
const favoritesDefaults = { items: [], message: null };
const favoritesInitial = _.assign({}, favoritesDefaults, getReduxInitial('shinglesFavorites', {}));
export const shinglesFavoritesReducer = (state = favoritesInitial, action) => {
  const newState = _.cloneDeep(state);
  switch(action.type) {
    case SHINGLES_FAVORITE_ADD:
      newState.items = [...newState.items, action.color];
      newState.message = 'shingles.added_to_favorites';
      return newState;
    case SHINGLES_FAVORITE_REMOVE:
      newState.items = newState.items.filter(fav => fav !== action.color);
      newState.message = 'shingles.removed_from_favorites';
      return newState
    case SHINGLES_FAVORITE_RESYNC:
      newState.items = [...action.colors];
      newState.message = 'shingles.favorites_resync';
      return newState;
    default:
      return state;
  }
};

export const addToFavorites = (color) => ({type: SHINGLES_FAVORITE_ADD, color});
export const removeFromFavorites = (color) => ({type: SHINGLES_FAVORITE_REMOVE, color});
export const resyncFavorites = (colors) => ({ type: SHINGLES_FAVORITE_RESYNC, colors });

export const connectFavorites = connect(
  (state) => ({favorites: state.shinglesFavorites.items, favoritesStatus: state.shinglesFavorites.message}),
  { addToFavorites, removeFromFavorites, resyncFavorites }
);

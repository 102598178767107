import styled from '@emotion/styled';
import ContentAndImage from '../oc-content-and-image';
import { BasicContent } from './BasicContent';
import FeatureList from '../../ComponentLibrary/oc-feature-list';

const ContentAndImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${ (props) => props.alignment === 'center' ? 'center' : 'flex-start' };
`;

const videoUrl = (media) => {
  if (typeof(media?.video) === 'string') {
    return media.video;
  }
  switch (media?.type) {
    case 'video':
      return media?.resources?.video?.url;
    case 'youtube':
      return media?.resources?.youtube?.url;
  }
  return null;
}

const imgUrl = (media) => {
  if (typeof(media?.img) === 'string') {
    return media.img;
  }

  switch (media?.type) {
    case 'image':
      let image = media?.resources?.image?.file;
      return image;
  }
  return null;
}

const imgAlt = (media) => {
  if (typeof(media?.alt) === 'string') {
    return media.alt;
  }
  switch (media?.type) {
    case 'image':
      return media?.resources?.image?.alt;
  }
  return null;
}

const CMSContentAndImage = ({
  arrangement,
  media,
  content,
  imageUrl,
  ...props
}) => {
  const bodyAttributes = content?.bodyAttributes;
  const footnotes = content?.footnotes;

  let transform = media?.resources?.image?.crop ? { crop: 'crop', ...media.resources.image.crop } : null;

  let caption, captionAlignment;
  if (media?.type === 'image') {
    caption = media?.resources?.image?.caption;
    captionAlignment = media?.resources?.image?.captionAlignment;
  } else {
    caption = media?.resources?.caption;
    captionAlignment = media?.resources?.captionAlignment;
  }

  return(
    <ContentAndImageWrapper alignment={ arrangement?.alignment }>
      <ContentAndImage
        alt={ imgAlt(media) }
        caption={ caption }
        captionAlignment={ captionAlignment }
        containerSize={ arrangement?.containerSize }
        contentOrder={arrangement?.contentOrder}
        contentOrderMobile={arrangement?.contentOrderMobile}
        img={ imgUrl(media) }
        imageUrl={ imageUrl }
        noMargin={true}
        ratio={arrangement?.layout}
        transform={transform}
        video={ videoUrl(media) }
        {...props}
      >
        <BasicContent { ...content }>
          {bodyAttributes &&
            <FeatureList features={bodyAttributes} fullWidth />
          }
        </BasicContent>
      </ContentAndImage>

      {footnotes &&
        <BasicContent { ...footnotes } />
      }
    </ContentAndImageWrapper>
  );
};

export default CMSContentAndImage;

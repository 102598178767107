import { define, ui, when } from '@owenscorning/pcb.alpha';
import IconPimLink from '../../../ComponentLibrary/icons/icon-pim-link';

export default define`ProductMeta`('0.0.1')({
  view: (props) => (
    <></>
  ),
  edit: ui`Form`.of({
    title: ui`Text`({
      label: 'Page Title',
      disabled: when`~linked_object`.is.present.and.when`~linked_object/source`.is.equal.to("pim_paroc"),
      tip: when`~linked_object`.is.present.and.when`~linked_object/source`.is.equal.to("pim_paroc").then("Field connected to PIM product."),
      tipIcon: IconPimLink
    }),
    keywords: ui`Text`({ label: 'Meta Keywords' }),
    description: ui`Text`({
      label: 'Meta Description',
      maxlength: 155,
      disabled: when`~linked_object`.is.present.and.when`~linked_object/source`.is.equal.to("pim_paroc"),
      tip: when`~linked_object`.is.present.and.when`~linked_object/source`.is.equal.to("pim_paroc").then("Field connected to PIM product."),
      tipIcon: IconPimLink,
    }),
    noIndex: ui`Switch`({ label: 'No Index Tag' }),
    noFollow: ui`Switch`({ label: 'No Follow Tag' })
  })
});

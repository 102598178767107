import styled from "@emotion/styled";

// Components
import Map from "../../../ComponentLibrary/oc-map";

// Styled Components
const StickyMap = styled(Map)`
  position: sticky;
  top: 0;
`;

const DistributorMap = ({ locations, userLocation }) => {
  const extraBounds = userLocation ? [userLocation] : []
  return (
    <StickyMap locations={locations} extraBounds={extraBounds} />
  )
}

export default DistributorMap;

import { useState } from 'react';
import { define, ui, s, select, when } from '@owenscorning/pcb.alpha';
import queryString from 'qs';
import styled from '@emotion/styled';
import Data from '../../../Data';
import { Mobile, Tablet, Desktop } from '../../../../OC/PageBuilder/internals/Devices';
import { Screens } from '../../../../PageBuilder/editing/Viewport';
import { BasicContent } from '../../../../OC/PageBuilder/BasicContent';

import FilterListResults from '../../../../ComponentLibrary/filter-list/oc-filter-list-results';
import useReference from '../../../../../hooks/use_reference';
import { unwrapRef, wrapRef } from '../../../../../data';
import TextSearchFilter from "../../../../ComponentLibrary/filter-list/oc-text-search-filter";
import { makeT } from "../../../../location/locales";

const dataType = 'ItemList';

const Sizes = {
  'xl': { [Tablet]: 738, [Desktop]: 1170 },
  'l': { [Tablet]: 738, [Desktop]: 970 },
  'm': { [Tablet]: 482, [Desktop]: 770 },
};

const ItemListWrapper = styled.div`
  display: flex;
`;

const ItemListSet = styled.div`
  margin: 0;
  width: ${({ width }) => `${width.Mobile}px`};
  @media (min-width: ${Screens.Tablet}px) { width: ${({ width }) => `${width.Tablet}px`}; }
  @media (min-width: ${Screens.Desktop}px) { width: ${({ width }) => `${width.Desktop}px`}; }
`;

const NoResult = () => <div />

const Renderer = ({ copy, type: { source = 'data' } = { source: 'data' }, data, settings, socialShare, searchFilter }) => {
  const size = settings?.containerSize?.selection || 'xl';
  const alignment = settings?.alignment || 'Left';
  const Width = Sizes[size];
  const adapter = Data.for(dataType)[data?.dataset];
  const centerStyle = {
    justifyContent: 'center'
  }
  const [initialLoad, setInitialLoad] = useState(true);
  const urlParams = queryString.parse(new URL(document.location).search, {ignoreQueryPrefix: true})
  const [appliedFilters, setAppliedFilters] = useState(urlParams || {});

  let reference = data?.reference;
  const language = Board.build.language
  const t = makeT(language);
  if (adapter?.settings?.serverSideCalculation && (Object.keys(appliedFilters)?.length > 0 || !initialLoad)) {
    reference = wrapRef(
      'Cms::DataProvider',
      {
        type: dataType,
        dataset: data.dataset,
        parameters: { ...data.parameters[data.dataset], filters: appliedFilters },
        language: language
      }
    )
  }
  // TODO: remove me after a bit, data.reference being nil is an old bit of data pre PB-295
  else if (typeof(reference) === 'undefined' && data?.dataset && data?.parameters) {
    reference = wrapRef('Cms::DataProvider', { type: dataType, dataset: data.dataset, parameters: data.parameters[data.dataset], language: Board.build.language })
  }

  const updateFilters = (filters) => {
    setAppliedFilters(filters);
    setInitialLoad(false);
  }

  // TODO: it also seems we could remove this bit, if we can remove the top-level "dataset" and "parameters" and make part
  // of Data/Parameters
  const [type, value] = reference ? unwrapRef(reference) : []
  if (value?.dataset !== data?.dataset) {
    reference = null;
  }

  const { results, error, loading } = useReference(reference);
  const view = adapter?.view(results);
  const { Component, filters } = (view || {});
  const enableSearch = (view && view.enableSearch === undefined && view.searchFields) ? true : view?.enableSearch; //set default to true if not defined
  const modifiedFilters = (searchFilter && enableSearch)
    ? {
      q: {
        component: TextSearchFilter,
        valueType: 'search',
        displayName: t('filter_list.search'),
        fields: view.searchFields,
        options: [],
        serverSide: false,
      },
      ...filters,
    }
    : filters;

  return <ItemListWrapper style={alignment !== 'Left' ? centerStyle : null}>
    <ItemListSet width={Width} size={size}>
      <BasicContent {...copy} />
      <UI.Row>
        {view && Component &&
          <FilterListResults
            resultComponent={(props) => <Component aspectRatio={settings?.aspectRatio} socialShare={socialShare?.image} {...props} />}
            noResultComponent={NoResult}
            {...{ ...view, filters: modifiedFilters }}
            loading={loading}
            {...adapter?.settings?.resultComponent}
            serverSideCalculation={adapter?.settings?.serverSideCalculation}
            serverSideCalculationUpdate={updateFilters}
            search={{enable: searchFilter, position: 'left'}}
            filterChipsVisible={true}
          />
        }
      </UI.Row>
    </ItemListSet>
  </ItemListWrapper>;
};

export default define`Item List`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/ItemList`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value }) => <Renderer key={ value?.data?.dataset } { ...value } />,
  edit: {
    copy: ui`Content/Basic`,
    type: ui`Form`.of({
      source: ui`Choices`.of({
        'data': 'Data Driven'
      })({
        label: 'Select Type',
        default: 'data',
        mode: ui`Choices/Mode/Dropdown`
      }),
      [s._]: ui`Tip`.of('Select the type of items you would like to create or pull data from.'),
    })({
      label: 'Select Type',
    }),
    data: ui`Form`.of({
      dataset: ui`Data/Choices`.of(dataType),
      parameters: ui`Data/Parameters`.of(dataType)({ dataset: select`../dataset` }),
      reference: ui`Data/Reference`({
        type: dataType,
        dataset: select`../dataset`,
        parameters: select`../parameters`,
      })
    })({
      label: 'Select Details',
      visible: when `../type/source`.is.equal.to('data').then(true).otherwise(false)
    }),
    socialShare: ui`Form`.of({
      image: ui`Switch`({
        default: false,
      }),
      [s._]: ui`Tip`.of("By enabling image social share we will be showing share options on this module's modal images.")
    })({
      label: 'Image Social Sharing',
      visible: when `../data/dataset`.is.equal.to('shingles').then(true).otherwise(false),
    }),
    searchFilter: ui`Switch`({
      label: 'Search Filter',
      default: false,
      visible: when `../data/dataset`.is.equal.to('bim').then(false).otherwise(true),
    }),
    settings: ui`Form`.of({
      aspectRatio: ui`AspectRatio`({ default: { selection: '1' } }),
      alignment: ui`Choices`.of(['Left', 'Center'])({
        label: 'Alignment',
        default: 'Left'
      }),
      containerSize: ui`Form`.of({
        selection: ui`Choices`.of({
          'xl': 'XL',
          'l': 'Large',
          'm': 'Medium',
        })({
          label: 'Container Size',
          default: 'xl',
          mode: ui`Choices/Mode/Dropdown`
        })
      }),
    })({
      label: 'Layout'
    }),
  },
});

import PropTypes from 'prop-types';

import IconMapMarker from '../../icons/icon-map-marker';
import { ActionLink } from '../result-block-styled-components';

const Directions = ({ label, address, buyType, name }) => (
  <ActionLink
    href={`http://maps.google.com/?q=${address}`}
    tabIndex={0}
    data-track="view-directions"
    data-track-buy-type={buyType}
    data-track-supplier-name={name}
  >
    <IconMapMarker width="13" fill="currentColor" className="icon" />
    {label}
  </ActionLink>
);

Directions.propTypes = {
  label: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  buyType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Directions;

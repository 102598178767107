import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import NumberFormat from "react-number-format";
import _ from "lodash";

// Imported Components
import { H2, H5, H6 } from '../../ComponentLibrary/text-elements/oc-h';

// Styled Components
const Container = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 1170px;
  position: relative;
  width: 100%;
`;

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  color: #333;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 16px;
  min-height: 0.01%;
  overflow-x: auto;
  width: 100%;

  &.four-up {
    th, td {
      width: 25%;
    }
  }

  &.five-up {
    th, td {
      width: 20%;
    }
  }

  tbody tr:nth-of-type(even) {
    background: #F8F8F8;
  }

  .pink {
    color: #D40F7D;
  }

  .strong {
    font-weight: bold;
  }
`;

const TableHeader = styled.th`
  border-bottom: 2px solid #bcbcbc;
  border-left: 1px solid #bcbcbc;
  border-top: 2px solid #bcbcbc;
  font-size: 14px;
  padding: 8px 12px;
  text-transform: none;

  &:first-of-type {
    border-left: 0;
    text-align: left;
  }
`;

const TableRow = styled.tr`
  &.divider {
    border-top: 8px solid #BCBCBC;
  }
`;

const TableData = styled.td`
  border-bottom: 1px solid #BCBCBC;
  border-left: 1px solid #BCBCBC;
  min-width: 184px;
  padding: 8px 12px;
  text-align: center;

  &:first-of-type {
    border-left: 0;
  }

  &.left {
    text-align: left;
  }
`;

const ProductName = styled.strong`
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  width: 100%;
`;

const Input = styled(NumberFormat, { displayType: "input" })`
  appearance: none;
  border-radius: 0;
  border: 1px solid #585858;
  display: inline-block;
  font-size: 16px;
  padding: 14px 8px;
  position: relative;
  width: 100%;
`;

const ResultContainer = styled.div`
  display: inline-block;
  margin-right: 4%;
  min-width: 242px;
  position: relative;

  &:last-of-type {
    margin-right: 0;
  }

  h2 {
    color: #D40F7D;
  }
`;

const MINIMUM_TOTAL_SPENDING = 10_000
const MINIMUM_FIBERGLAS_SPENDING = 5_000

const PadpPromoCalc = ({}) => {
  const [fiberglasPurchases, setFiberglasPurchases] = useState(55000);
  const [loosefillPurchases, setLoosefillPurchases] = useState(12000);
  const [foamularPurchases, setFoamularPurchases] = useState(10000);
  const [thermafiberPurchases, setThermafiberPurchases] = useState(3500);
  const [roofingPurchases, setRoofingPurchases] = useState(30000);
  const [tierLevel, setTierLevel] = useState("diamond");
  const sumPurchases = fiberglasPurchases + loosefillPurchases + foamularPurchases + thermafiberPurchases + roofingPurchases
  const meetsSpendingRequirements = sumPurchases >= MINIMUM_TOTAL_SPENDING && (fiberglasPurchases + loosefillPurchases) >= MINIMUM_FIBERGLAS_SPENDING

  const underGold = _.includes(["bronze", "silver"], tierLevel);
  const tableClass = underGold ? "five-up" : "four-up";

  const insulationFundingRate = { "not-available": 0, "bronze": 0.01, "silver": 0.02, "gold": 0.03, "diamond": 0.03 };
  const roofingFundingRate = { "not-available": 0, "bronze": 0.01, "silver": 0.01, "gold": 0.01, "diamond": 0.01 };

  const fiberglasFieldName = "Pink Next Gen™ Fiberglas™";
  const loosefillFieldName = "ProCat® Loosefill";
  const foamularFieldName = "Foamular®";
  const thermafiberFieldName = "Thermafiber®";
  const roofingFieldName = "Roofing Shingles and Components";

  const fundingRatePercentage = (rate) => {
    return `${rate * 100}%`;
  };

  const caclulatePromoFunds = (value, rate = insulationFundingRate[tierLevel]) => {
    return value * rate;
  };

  const caclulateAdditionalFunds = (value) => {
    let diamondFunds = caclulatePromoFunds(value, insulationFundingRate["diamond"]);
    let currentFunds = caclulatePromoFunds(value);

    return diamondFunds - currentFunds;
  };

  const calculateTotalFunding = () => {
    let fiberglasFunds = caclulatePromoFunds(fiberglasPurchases);
    let loosefillFunds = caclulatePromoFunds(loosefillPurchases);
    let foamularFunds = caclulatePromoFunds(foamularPurchases);
    let thermafiberFunds = caclulatePromoFunds(thermafiberPurchases);
    let roofingFunds = caclulatePromoFunds(roofingPurchases, roofingFundingRate[tierLevel]);

    return fiberglasFunds + loosefillFunds + foamularFunds + thermafiberFunds + roofingFunds;
  };

  const updateFunds = (event, categoryCallback) => {
    let value = (event.floatValue) ? event.floatValue : 0;
    categoryCallback(value);
  };

  const updateFiberglas = (event) => {
    updateFunds(event, setFiberglasPurchases);
  };

  const updateLoosefill = (event) => {
    updateFunds(event, setLoosefillPurchases);
  };

  const updateFoamular = (event) => {
    updateFunds(event, setFoamularPurchases);
  };

  const updateThermafiber = (event) => {
    updateFunds(event, setThermafiberPurchases);
  };

  const updateRoofing = (event) => {
    updateFunds(event, setRoofingPurchases);
  };

  const trackUpdate = (fieldName) => {
    if (typeof(_satellite) !== "undefined") {
      _satellite.track("calc-engagement", {
        calcName: "PADP Promo Funds Calculator",
        fieldUpdated: fieldName
      });
    }
  };

  useEffect(() => {
    if (!meetsSpendingRequirements) {
      setTierLevel("not-available");
      return;
    }

    let spendingCategories = 0;
    [fiberglasPurchases, loosefillPurchases, foamularPurchases, thermafiberPurchases, roofingPurchases].map((purchases) => {
      if (purchases > 0) spendingCategories++;
    });

    switch (spendingCategories) {
      case 5:
        setTierLevel("diamond");
        break;
      case 4:
        setTierLevel("diamond");
        break;
      case 3:
        setTierLevel("gold");
        break;
      case 2:
        setTierLevel("silver");
        break;
      case 1:
        setTierLevel("bronze");
        break;
      default:
        break;
    }
  }, [fiberglasPurchases, loosefillPurchases, foamularPurchases, thermafiberPurchases, roofingPurchases]);

  let fiberglasAdditionalFunds = caclulateAdditionalFunds(fiberglasPurchases);
  let loosefillAdditionalFunds = caclulateAdditionalFunds(loosefillPurchases);
  let foamularAdditionalFunds = caclulateAdditionalFunds(foamularPurchases);
  let thermafiberAdditionalFunds = caclulateAdditionalFunds(thermafiberPurchases);

  return (
    <Container>
      <Table className={tableClass}>
        <thead>
          <TableRow>
            <TableHeader>Category</TableHeader>
            <TableHeader>Purchases</TableHeader>
            <TableHeader>Funding Rate</TableHeader>
            <TableHeader className="pink">Promo Funds</TableHeader>
            {underGold &&
              <TableHeader className="pink">Additional $ at Diamond</TableHeader>
            }
          </TableRow>
        </thead>
        <tbody>
          <TableRow>
            <TableData className="left">
              Owens Corning®
              <ProductName>{fiberglasFieldName}</ProductName>
            </TableData>
            <TableData>
              <Input
                allowEmptyFormatting={true}
                allowNegative={false}
                decimalScale={0}
                onBlur={(event) => trackUpdate(fiberglasFieldName)}
                onValueChange={(event) => updateFiberglas(event)}
                prefix="$"
                thousandSeparator={true}
                value={fiberglasPurchases}
              />
            </TableData>
            <TableData>
              {fundingRatePercentage(insulationFundingRate[tierLevel])}
            </TableData>
            <TableData>
              {<NumberFormat
                className="strong"
                decimalScale={2}
                displayType="text"
                fixedDecimalScale={true}
                prefix="$"
                thousandSeparator={true}
                value={caclulatePromoFunds(fiberglasPurchases)}
              />}
            </TableData>
            {underGold &&
              <TableData>
                {<NumberFormat
                  className={`strong ${fiberglasAdditionalFunds > 0 && "pink"}`}
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale={true}
                  prefix="$"
                  thousandSeparator={true}
                  value={fiberglasAdditionalFunds}
                />}
              </TableData>
            }
          </TableRow>
          <TableRow>
            <TableData className="left">
              Owens Corning®
              <ProductName>{loosefillFieldName}</ProductName>
            </TableData>
            <TableData>
              <Input
                allowEmptyFormatting={true}
                allowNegative={false}
                decimalScale={0}
                onBlur={(event) => trackUpdate(loosefillFieldName)}
                onValueChange={(event) => updateLoosefill(event)}
                prefix="$"
                thousandSeparator={true}
                value={loosefillPurchases}
              />
            </TableData>
            <TableData>
              {fundingRatePercentage(insulationFundingRate[tierLevel])}
            </TableData>
            <TableData>
              {<NumberFormat
                className="strong"
                decimalScale={2}
                displayType="text"
                fixedDecimalScale={true}
                prefix="$"
                thousandSeparator={true}
                value={caclulatePromoFunds(loosefillPurchases)}
              />}
            </TableData>
            {underGold &&
              <TableData>
                {<NumberFormat
                  className={`strong ${loosefillAdditionalFunds > 0 && "pink"}`}
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale={true}
                  prefix="$"
                  thousandSeparator={true}
                  value={loosefillAdditionalFunds}
                />}
              </TableData>
            }
          </TableRow>
          <TableRow>
            <TableData className="left">
              Owens Corning®
              <ProductName>{foamularFieldName}</ProductName>
            </TableData>
            <TableData>
              <Input
                allowEmptyFormatting={true}
                allowNegative={false}
                decimalScale={0}
                onBlur={(event) => trackUpdate(foamularFieldName)}
                onValueChange={(event) => updateFoamular(event)}
                prefix="$"
                thousandSeparator={true}
                value={foamularPurchases}
              />
            </TableData>
            <TableData>
              {fundingRatePercentage(insulationFundingRate[tierLevel])}
            </TableData>
            <TableData>
              {<NumberFormat
                className="strong"
                decimalScale={2}
                displayType="text"
                fixedDecimalScale={true}
                prefix="$"
                thousandSeparator={true}
                value={caclulatePromoFunds(foamularPurchases)}
              />}
            </TableData>
            {underGold &&
              <TableData>
                {<NumberFormat
                  className={`strong ${foamularAdditionalFunds > 0 && "pink"}`}
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale={true}
                  prefix="$"
                  thousandSeparator={true}
                  value={foamularAdditionalFunds}
                />}
              </TableData>
            }
          </TableRow>
          <TableRow>
            <TableData className="left">
              Owens Corning®
              <ProductName>{thermafiberFieldName}</ProductName>
            </TableData>
            <TableData>
              <Input
                allowEmptyFormatting={true}
                allowNegative={false}
                decimalScale={0}
                onBlur={(event) => trackUpdate(thermafiberFieldName)}
                onValueChange={(event) => updateThermafiber(event)}
                prefix="$"
                thousandSeparator={true}
                value={thermafiberPurchases}
              />
            </TableData>
            <TableData>
              {fundingRatePercentage(insulationFundingRate[tierLevel])}
            </TableData>
            <TableData>
              {<NumberFormat
                className="strong"
                decimalScale={2}
                displayType="text"
                fixedDecimalScale={true}
                prefix="$"
                thousandSeparator={true}
                value={caclulatePromoFunds(thermafiberPurchases)}
              />}
            </TableData>
            {underGold &&
              <TableData>
                {<NumberFormat
                  className={`strong ${thermafiberAdditionalFunds > 0 && "pink"}`}
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale={true}
                  prefix="$"
                  thousandSeparator={true}
                  value={thermafiberAdditionalFunds}
                />}
              </TableData>
            }
          </TableRow>
          <TableRow className="divider">
            <TableData className="left">
              Owens Corning®
              <ProductName>{roofingFieldName}</ProductName>
            </TableData>
            <TableData>
              <Input
                allowEmptyFormatting={true}
                allowNegative={false}
                decimalScale={0}
                onBlur={(event) => trackUpdate(roofingFieldName)}
                onValueChange={(event) => updateRoofing(event)}
                prefix="$"
                thousandSeparator={true}
                value={roofingPurchases}
              />
            </TableData>
            <TableData>
              {fundingRatePercentage(roofingFundingRate[tierLevel])}
            </TableData>
            <TableData>
              {<NumberFormat
                className="strong"
                decimalScale={2}
                displayType="text"
                fixedDecimalScale={true}
                prefix="$"
                thousandSeparator={true}
                value={caclulatePromoFunds(roofingPurchases, roofingFundingRate[tierLevel])}
              />}
            </TableData>
            {underGold &&
              <TableData>
                {<NumberFormat
                  className="strong"
                  displayType="text"
                  value="-"
                />}
              </TableData>
            }
          </TableRow>
        </tbody>
      </Table>

      <ResultContainer>
        <H6>Tier Level</H6>
        <H2>{_.startCase(tierLevel)}</H2>
      </ResultContainer>
      <ResultContainer>
        <H6>Total Funding</H6>
        <H2>
          {<NumberFormat
            decimalScale={2}
            displayType="text"
            fixedDecimalScale={true}
            prefix="$"
            thousandSeparator={true}
            value={calculateTotalFunding()}
          />}
        </H2>
      </ResultContainer>
    </Container>
  );
};

export default PadpPromoCalc;

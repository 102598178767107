import { ui, transform, select } from '@owenscorning/pcb.alpha';
import Content from './Content';
import _ from 'lodash';
import placeholder_url from "../../../../placeholder";
import { BasicContent } from "../../../OC/PageBuilder/BasicContent";
import Card from "../../../OC/PageBuilder/Card";
import { filterEntity, isItemVisible } from "../../../PageBuilder/helpers/content";

const BusinessService = Content(
  'BusinessService', (UI) => ({
    displayName: 'BusinessService',
    sidebar: {
      builder: ui`Form`.of({
        copy: ui`Content/OCBasic`,
        cards: ui`List`.of({
          image: ui`Upload/Image`({ label: 'Image Path', default: placeholder_url(340,190) }),
          alt: ui`Text`({ label: 'Alt Text' }),
          prehead: ui`Text`({ label: 'Prehead', default: 'Business Tools' }),
          heading: ui`Text`({ label: 'Heading' }),
          text: ui`Text/Rich`({ label: 'Description' }),
          linkText: ui`Text`({ label: 'Link Text', default: 'Learn More' }),
          url: ui`Text`({ label: 'Link URL' }),

          analytics: ui`Form`.of({
            dataAmRegion: ui`Text`({ label: 'data-am-region' }),
            dataTrackFileName: ui`Text`({ label: 'data-track-file-name' })
          })({
            label: 'Analytics'
          })
        })({
          // max: 24,
          singular: 'Card',
          title: 'heading',
          label: 'Service Cards',
        })
      })
    },
    view: BusinessService.Renderer(UI),
    read: ({ contents, metadata }) => {
      if (!_.isEmpty(contents) && !Array.isArray(contents)) {
        return { contents, metadata };
      }
      return {
        contents: _.omit(contents[0].children[0], ['__uuid', 'component']),
        metadata
      };
    },
    write: ({ contents, metadata }) => {
      return {
        contents: [{ children: [contents] }],
        metadata
      };
    }
  })
);

BusinessService.Renderer = (UI, parameters={}) => ({
  contents: ({ value = {} }) => {
    const { copy, cards } = filterEntity(value, isItemVisible);
    const { Row } = UI.Box.batch();
    return <>
      <BasicContent { ...copy } />
      <Row
        gap-3 fits={ 3 }
        tablet-fits={ 2 }
        mobile-fits={ 1 }
      >
        {
          cards?.map((card, idx) => <Card
            key={idx}
            img={ card.image }
            aspectRatio={{
              "selection": "2",
              "custom": 1
            }}
            alt={ card.alt }
            prehead={ card.prehead }
            heading={ card.heading }
            posthead={ card.posthead }
            text={ card.text }
            linkText={ card.linkText }
            linkUrl={ card.url }
            url={ card.url }
            icon={ card.icon }
            dataAmRegion={ card.analytics.dataAmRegion }
            dataTrackFileName={ card.analytics.dataTrackFileName }
          />)
        }
      </Row>
    </>;
  }
});

export default BusinessService;

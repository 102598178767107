import ProCatCalculator from '../../Insulation/ProCatCalculator/ProCatCalculator';
import { BasicContent } from './BasicContent';
import RichText from './RichText';


const CMSProCatCalculator = ({content, calculator}) => {
  return (
    <>
      <BasicContent { ...content.copy } />
      <ProCatCalculator 
        costPerBag={calculator.costPerBag} 
        installers={calculator.installers}
        laborRate={calculator.laborRate}
        markup={calculator.markup}
        footnotes={<RichText content={calculator.footnotes} />}
      />
    </>
  )
};

export default CMSProCatCalculator;
import styled from "@emotion/styled"
import withDefaultPrevented from "../../withDefaultPrevented"
import wrappedInReduxProvider from "../wrappedInReduxProvider"
import { connectLocation } from "../location/LocationConnectors"

const ChangeLocationButton = styled.a`
  -webkit-appearance: none;
  align-items: flex-end;
  border: 0;
  text-decoration: underline;
  cursor: pointer;
  display: inline-flex;

  svg {
    margin-right: 5px;
  }
`

// openLocationModal provided by the connectLocation HOC
const LocationModalOpener = ({
  t,
  ariaLabel = "",
  className = "oc-icon",
  dataTM = "",
  dataTrack = "",
  dataTrackModalName = "",
  openLocationModal,
  showIcon = true,
  text = t('generic_locator.change')
}) => (
  <ChangeLocationButton
    aria-label={ariaLabel}
    className={className}
    data-tm={dataTM}
    data-track-modal-name={dataTrackModalName}
    data-track={dataTrack}
    onClick={withDefaultPrevented(openLocationModal)}
    tabIndex={0}
  >
    {showIcon && <i className="icon location" aria-hidden="true"></i>}
    {text}
  </ChangeLocationButton>
)

// We wrap LocationModalOpener in the connectLocation HOC
// to get access to the openLocationModal function.
// For that HOC to function, it needs access to a redux provider,
// so we wrap it in the wrappedInReduxProvider HOC.
export default wrappedInReduxProvider(connectLocation(LocationModalOpener))

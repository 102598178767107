import PropTypes from 'prop-types';
import React from 'react';

import Button from '../../../ComponentLibrary/oc-button';

export default function PDFRequester({
  selectedIds,
  specifications,
  sheetName,
  contractor,
  project,
}) {
  const isActive = selectedIds.length > 0;

  const specificationsQueryStringSegment = () =>
    specifications.reduce(
      (queryString, specification, index) =>
        specification
          ? `${queryString}&specifications[${index}]=${specification}`
          : queryString,
      ''
    );

  const pdfUrl = !isActive
    ? null
    : `/insulation/submittal-sheets/${sheetName}/writeup.pdf?selected=${selectedIds}&contractor=${
        contractor || ''
      }&project=${project || ''}${specificationsQueryStringSegment()}`;

  return (
    <Button
      target="_blank"
      href={pdfUrl}
      color={isActive ? 'red' : 'gray'}
      disabled={!isActive}
      track={{
        track: 'file-download',
        'element-location': 'CEE Submittal App',
        'file-name': 'submittal-sheet.pdf',
        'file-type': 'PDF',
        'doc-type': 'Submittal Sheet',
      }}
    >
      DOWNLOAD PDF
    </Button>
  );
}

PDFRequester.propTypes = {
  sheetName: PropTypes.string.isRequired,
  contractor: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  selectedIds: PropTypes.array.isRequired,
  specifications: PropTypes.array.isRequired,
};

import PadpPromoCalc from '../../ComponentLibrary/calculators/PadpPromoCalc';
import { BasicContent } from './BasicContent';

const CMSPadpPromoCalc = ({ content }) => {
  return (
    <>
      <BasicContent { ...content.copy } />
      <PadpPromoCalc />
    </>
  )
};

export default CMSPadpPromoCalc;

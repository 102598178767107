import { define, ui } from '@owenscorning/pcb.alpha';
import Renderer from '../../../../OC/PageBuilder/AlertMessage';

export default define`Alert Message`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/AlertMessage`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value }) => <Renderer { ...value } />,
  edit: {
    type: ui`Choices`.of({
      black: "Black",
      error: "Error",
      info: "Info",
      other: "Other",
      success: "Success",
      warning: "Warning",
    })({
      label: "Alert Type",
      default: "black"
    }),
    title: ui`Text`({
      label: "Title"
    }),
    message: ui`Text/Rich`({
      label: "Message Content",
      default: "This product has been discontinued.",
    }),
    dismissable: ui`Switch`({
      label: "Dismissable",
      default: false
    })
  },
});

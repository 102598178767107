import styled from '@emotion/styled';
import { useContext } from 'react';

import LocatorContext from './context';
import UI from '../../../Builder/Library';
// eslint-disable-next-line import/no-cycle
import CMSBasicContent from '../BasicContent';

const UnderFilterWrapper = styled.div`
  margin-top: 10px;

  .under-filter-basic-content {
    img {
      width: 50%;
    }
  }
`;

const UnderFilterContent = () => {
  const { underFilterContent = [] } = useContext(LocatorContext) || {};

  if (!underFilterContent.length) {
    return null;
  }

  return (
    <UnderFilterWrapper>
      {underFilterContent.map((content, index) => {
        const { basicContent, legend, reusable, type } = content;

        /* eslint-disable react/no-array-index-key */
        switch (type) {
          case 'basicContent':
            return (
              <CMSBasicContent
                key={index}
                content={{
                  ...basicContent,
                  className: 'under-filter-basic-content',
                }}
              />
            );
          case 'legend':
            return <UI.Legend key={index} value={legend} />;
          case 'reusable':
            return <UI.Modules.Page.Reusable key={index} value={reusable} />;
          default:
            return null;
        }
        /* eslint-enable react/no-array-index-key */
      })}
    </UnderFilterWrapper>
  );
};

export default UnderFilterContent;

import { define, ui } from '@owenscorning/pcb.alpha';
import _ from 'lodash';
import React from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import useMdmsApi, { SUCCESS } from '../../../../../../hooks/use_mdms_api';

export default define`GroupsWrapper`('0.0.1')({
  view: () => <div> </div>,
  edit: ({ Board, locatorUid, value }) => {
    const url = `/api/v3/locations/${locatorUid}/metadata`;
    const { status, data } = useMdmsApi(url, locatorUid);
    const groups = data?.groups || [];

    useDeepCompareEffect(() => {
      // update the board only when groups change and status is success
      if (status === SUCCESS) {
        // remove groups which are no longer included
        const newGroups = _.clone(value || []).filter(
          group => groups.includes(group.groupId) || group.groupId === 'all'
        );
        // initialize new groups
        groups.forEach(group => {
          if (!newGroups.find(g => g.groupId === group)) {
            newGroups.push({
              groupId: group,
              title: _.startCase(group),
              templated: true,
            });
          }
        });
        if (!newGroups.length) {
          newGroups.push({
            groupId: 'all',
            title: 'all',
            templated: true,
          });
        }
        Board.Change(newGroups);
      }
    }, [groups, status]);

    return (
      <Subschema>
        {[ui`Modules/Page/Locator/Groups`({ status, groups })]}
      </Subschema>
    );
  },
});

import { define, ui } from "@owenscorning/pcb.alpha";
import styled from "@emotion/styled";

// Content Components
import { BasicContent } from '../../../../OC/PageBuilder/BasicContent.jsx';
import Modular from '../../Types/Modular.js';

// Utility Components
import { Screens } from '../../../../PageBuilder/editing/Viewport';

// Styled Components
const Container = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

const Header = styled(Container)`
  margin-bottom: 16px;
`;

const ModuleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  row-gap: 16px;

  @media screen and (min-width: ${Screens.Tablet}px) {
    column-gap: 32px;
  }
`;

const Module = styled(Container)`
  @media screen and (min-width: ${Screens.Tablet}px) {
    width: calc(50% - 16px);
  }
`;

const ModuleRenderer = ({ value }) => {
  const definition = UI.Modules?.Page?.[value.type];
  const Component = definition?.modes?.view || (({ value }) => <div></div>);
  value.data.parent = "ModuleSet";
  return <Component value={value?.data} />;
};

const Renderer = ({ copy, moduleList }) => {
  return (
    <Container>
      <Header>
        <BasicContent {...copy} />
      </Header>

      <ModuleContainer>
        {moduleList && moduleList.map((mod, index) => (
          <Module key={index}>
            <ModuleRenderer value={mod} />
          </Module>
        ))}
      </ModuleContainer>
    </Container>
  );
};

export default define`Module Set`("0.0.1")({
  thumbnail: ui`Modules/Page/Thumbnails/CardSet`,
  category: ui`Modules/Categories/Page/Customizable`,
  prepare: () => ({
    view: ({ value }) => <Renderer { ...value } />,
    edit: {
      copy: ui`Content/Basic`,
      moduleList: ui`List/Item`.of(({ path }) => {
        const restrictedModules = ['AttentionBarPicker', 'FeaturedList', 'FullBleedContent', 'Gallery', 'ModuleSet'];
        const allowedModules = Object.keys(UI.Modules.Page).filter(module => !restrictedModules.includes(module));

        return (
          <Subschema>{
            Modular.Module.List(UI, null, allowedModules, (index) => Modular.ModuleList([...(path.slice(3)), index]))
          }</Subschema>
        );
      })({
        label: "Modules",
        dropdown: false
      }),
    }
  }),
});

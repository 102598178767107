import { define } from '@owenscorning/pcb.alpha';

import React from 'react';
import { jsx, css } from '@emotion/react';
import { injectGlobal } from '@emotion/css';

import _ from 'lodash';

const Batches = [ 'intent' ];
const Aliases = { colors: 'color', fonts: 'font', copy: 'copy' };
const Commands = {
  font: (def, name) => css` font-family: ${ UI.Theme.fonts[name] }; `,
  size: (def, amount) => css` font-size: ${ amount }px; `,
  weight: (def, amount) => css` font-weight: ${ amount }; `,
  line: (def, amount) => css` line-height: ${ amount }px; `,
  copy: (def, name) => css` ${ def.Styles(UI.Theme.copy[name]) } `,
  back: (def, name) => css` background-color: ${ UI.Theme.colors[name] }; `,
  color: (def, name) => css` color: ${ UI.Theme.colors[name] }; `,
  width: (def, amount) => css` width: ${ UI.Theme.Pixels(amount) }px; `,
  height: (def, amount) => css` height: ${ UI.Theme.Pixels(amount) }px; `,
  padding: (def, ...amounts) => css` padding: ${ amounts.map((amount) => `${ UI.Theme.Pixels(amount) }px`).join(' ') }; `,
  paddingTop: (def, amount) => css` padding-top: ${ UI.Theme.Pixels(amount) }px; `,
  paddingRight: (def, amount) => css` padding-right: ${ UI.Theme.Pixels(amount) }px; `,
  paddingBottom: (def, amount) => css` padding-bottom: ${ UI.Theme.Pixels(amount) }px; `,
  paddingLeft: (def, amount) => css` padding-left: ${ UI.Theme.Pixels(amount) }px; `,
  margin: (def, ...amounts) => css` margin: ${ amounts.map((amount) => `${ UI.Theme.Pixels(amount) }px`).join(' ') }; `,
  marginTop: (def, amount) => css` margin-top: ${ UI.Theme.Pixels(amount) }px; `,
  marginRight: (def, amount) => css` margin-right: ${ UI.Theme.Pixels(amount) }px; `,
  marginBottom: (def, amount) => css` margin-bottom: ${ UI.Theme.Pixels(amount) }px; `,
  marginLeft: (def, amount) => css` margin-left: ${ UI.Theme.Pixels(amount) }px; `,
  case: (def, type) => css` text-transform: ${ { upper: 'uppercase', lower: 'lowercase', caps: 'capitalize' }[type] }; `,
  uppercase: (def) => Taggable.Commands.case(def, 'upper'),
  lowercase: (def) => Taggable.Commands.case(def, 'lower'),
  capitalize: (def) => Taggable.Commands.case(def, 'caps'),
  shadow: (def, type) => ({
    modal: css` box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.4); `,
    flyout: css` box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.25); `,
    blockLink: css` filter: drop-shadow(0px 12px 15px rgba(0, 0, 0, 0.2)); `
  }[type]),
  unclickable: (def) => css` pointer-events: none; `,
  unselectable: (def) => css` user-select: none; `,
  cursor: (def, type) => css` cursor: ${ type }; `
};

const Taggable = (Body, { commands={}, aliases={}, batches=[], base={} }={}) => ({ children, __parent={}, ...props }) => {
  const definition = Taggable.Compile(
    {
      ...Taggable.Parse(_.isFunction(base) ? base(props) : base),
      __customise: true, ...props
    },
    commands,
    aliases,
    batches
  );

  if (!Body.__tags) {
    Body.__tags = _.keys({ ...Commands, ...commands }).concat(_.reduce(
      { ...Aliases, ...aliases },
      (list, prop, theme) => list.concat(_.keys(_.get(UI.Theme, theme))), []
    )).concat(_.reduce(
      { ...Batches, ...batches },
      (list, batch) => list.concat(_.keys(_.get(UI.Theme, batch))), []
    ));
  }

  const tags = Body.__tags;
  const passed = _.reduce(props, (result, value, key) => {
    if (tags.includes(key)) return result;
    for (let i = 0; i < tags.length - 1; i++) if (key.startsWith(`${ tags[i] }-`)) return result;
    result[key] = value;
    return result;
  }, {});

  return <Body
    { ...passed }
    css={ Taggable.Styles(definition, commands, aliases, { children: _.isArray(children) ? React.Children.count(children) : 0, parent: __parent }) }
  >
    {
      _.isArray(children) ? React.Children.map(
        children,
        (child) => {
          if (React.isValidElement(child) && !_.isString(child.type)) return React.cloneElement(child, { '__parent': { children: React.Children.count(children), props, ...definition } });
          return child;
        }
      ) : children
    }
  </Body>
};

const Pixels = (amount=1) => (_.isArray(amount) ? amount[0] || 1 : amount) * UI.Theme.multiplier;

Taggable.Queries = {
  tablet: () => UI.Viewport.to.Desktop,
  mobile: () => UI.Viewport.to.Tablet
};

Taggable.Parse = (definition) => _.isString(definition)
  ? _.mapValues(_.mapKeys(definition.replace(/[\r\n]+/g,' ').replace(/\s{2,}/g,' ').trim().split(' ')), () => true)
  : definition;

Taggable.Compile = (descriptor, commands={}, aliases={}, batches=[]) => {
  descriptor = Taggable.Parse(descriptor);

  commands = { ...Taggable.Commands, ...commands };
  aliases = { ...Taggable.Aliases, ...aliases };
  batches = _.uniq([ ...Taggable.Batches, ...batches ]);

  _.each(aliases, (prop, theme) => {
    if (!descriptor[prop]) {
      _.each(_.get(UI.Theme, theme), (value, name) => {
        if (descriptor[name]) descriptor[`${ prop }-${ name }`] = true;
      });
    }
  });

  let group = 'all';
  let queries = {};

  _.each(batches, (batch) => {
    _.each(_.get(UI.Theme, batch), (tags, name) => {
      if (descriptor[name] || descriptor[`${ batch }-${ name }`]) {
        const compiled = Taggable.Compile(tags, commands, aliases, batches);
        queries = _.merge({}, queries, compiled.__queries);
        descriptor = {
          ..._.omit(compiled, [ '__queries' ]),
          ...descriptor
        };
      }
    });
  });

  const definition = _.reduce(descriptor, (result, value, name) => {
    let parts = name.split('-');
    let style = parts[0];
    let size = group;

    if (style == 'all' || style == '__customise' || Taggable.Queries[style]) {
      if (parts.length == 1) {
        group = style == '__customise' ? 'all' : style;
        return result;
      } else {
        size = style;
        parts = _.tail(parts);
        style = parts[0];
      }
    }

    if (commands[style]) _.set(
      size == 'all' ? result : queries,
      size == 'all' ? style : [ size, style ],
      [ ..._.tail(parts), ...( value !== true ? value : [] ) ]
    );

    return result;
  }, { __definition: true });

  if (definition.back && !definition.color) definition.color = UI.Theme.foreground[definition.back] || UI.Theme.foreground.default;
  return { ...definition, __queries: queries };
};

Taggable.Styles = (definition, commands={}, aliases={}, additional={}) => {
  if (_.isEmpty(definition)) return [];
  if (!definition.__definition) definition = Taggable.Compile(definition, commands, aliases);

  commands = { ...Taggable.Commands, ...commands };
  aliases = { ...Taggable.Aliases, ...aliases };

  const all = definition;
  const compile = (definition, queries={}, size='all') => _.reduce(
    _.omit(definition, [ '__definition', '__queries' ]),
    (result, args, name) => result.concat(
      commands[name](
        {
          size, queries: { ...queries, all }, ...additional, ...definition,
          Styles: (definition) => Taggable.Styles(definition, commands, aliases, additional)
        },
        ..._.castArray(args)
      )
    ),
    []
  );

  return compile(definition, definition.__queries).concat(
    _.values(
      _.mapValues(
        definition.__queries,
        (styles, size) => css` @media (${ Taggable.Queries[size]() }) { ${ compile(styles, definition.__queries, size) } } `
      )
    )
  );
};

Taggable.Batches = Batches;
Taggable.Aliases = Aliases;
Taggable.Commands = Commands;

const Path = (descriptor) => descriptor.split('.').reduce((result, value) => result.concat(value.split('/')), []);

const Wrapped = (Body) => _.isString(Body) ? ({ contents, __name, __styles, ...props }) => <Body { ...props } /> : Body;
const Component = (descriptor, Body) => UI.Theme.Taggable(Wrapped(Body), { base: _.get(UI.Theme, Path(descriptor)) });
Component.Group = (descriptor, Body) => {
  Body = Wrapped(Body);

  if (_.isString(descriptor)) descriptor = Path(descriptor);
  const styles = _.isPlainObject(descriptor) ? descriptor : _.get(UI.Theme, descriptor);

  const Contextual = (definition, props) => {
    if (_.isString(definition)) return definition;
    return definition[
      _.reduce(
        definition, (result, style, tag) => props[tag] ? tag : result,
        _.first(_.keys(definition))
      )
    ];
  };

  return _.isString(styles)
    ? { [_.capitalize(_.last(descriptor))]: UI.Theme.Taggable(Body, { base: styles }) }
    : _.mapValues(
      _.mapKeys(styles, (value, key) => _.capitalize(key)),
      (definition, name) => UI.Theme.Taggable(
        (props) => {
          return <Body
            { ...props }
            __styles={ Contextual(definition, props) }
            __name={ _.isString(Body) ? null : name }
          />
        },
        {
          base: (props) => {
            const result = Contextual(definition, props);
            return ( _.isPlainObject(result) && result.base )
              ? result.base
              : result;
          }
        }
      )
    );
};

let THEME = null;
export default define`Theme`({
  get: (origin) => {
    if (!THEME) {
      THEME = _.merge(
        {}, { ...origin.Definitions.base, Pixels, Taggable, Component },
        _.reduce(
          _.omit(origin.Definitions, 'base'),
          (result, theme, name) => theme.scope?.({
            site: typeof PB_SITE === 'undefined' ? '' : PB_SITE,
            path: typeof PB_PAGE === 'undefined' ? '' : PB_PAGE,
            type: typeof PB_TYPE === 'undefined' ? 'Page' : PB_TYPE,
            mode: typeof PB_MODE === 'undefined' ? 'view' : PB_MODE
          }) ? theme : result,
          origin.Definitions.base
        )
      );

      injectGlobal` body { ${ Taggable.Styles(THEME.global) } } `
    }

    return THEME;
  }
});

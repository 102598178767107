import _ from 'lodash';
import { limitSplit } from '../data'

const CLOUDINARY_DOMAIN = 'res.cloudinary.com'
export const CLOUDFRONT_IMAGE_CDN_DOMAIN = 'imagecdn.owenscorning.com'

export const CloudinaryDefaultConfig = {
  cloud_name: 'ocimages',
  secure: true,
  secure_distribution: CLOUDFRONT_IMAGE_CDN_DOMAIN
}

/**
 * This is for wrapping a function that returns a potentially cloudinary URL
 * the result will be passed to `cloudinaryUrlToCloudfront`
 *
 * It's probably better to just let the sdk convert them by using CloudinaryDefaultConfig for the config
 * where `secure_distribution: CLOUDFRONT_IMAGE_CDN_DOMAIN` will make the swap
 * but this will work in cases where we're not calling the SDK
 */
export const swapResultToCloudfront = (thisObj, func) => (...args) => {
  let url = Reflect.apply(func, thisObj, args)
  return cloudinaryUrlToCloudfront(url)
}

// we sometimes check if a url is cloudinary, we should want to treat cloudfront domain'd urls with the same logic
export const isUrlCloudinary = (url) => (typeof (url) == 'string' && (url.includes(CLOUDFRONT_IMAGE_CDN_DOMAIN) || url.includes(CLOUDINARY_DOMAIN)))

export const cloudinaryUrlToCloudfront = (url) => {
  if (typeof (url) == 'string') {
    return url.replace(CLOUDINARY_DOMAIN, CLOUDFRONT_IMAGE_CDN_DOMAIN)
  } else {
    return url
  }
}

//https://res.cloudinary.com/<cloud_name>/<asset_type>/<delivery_type>/<transformations>/<version>/<public_id_full_path>.<extension>
// we only care about "image" asset_type, "upload" delivery_type

// just grabbed from documentation, not a good way to grab from existing cloudinary libs
const CLOUDINARY_URL_TRANSFORM_KEYS =
  ['a','ac','af','ar','b','bo','br','c','co','cs','d','dl','dn',
  'dpr','du','e','eo','f','fl','fn','fps','g','h','if','ki','l','o',
  'p','pg','q','r','so','sp','t','u','vc','vs','w','x','y','z','$']

const CLOUDINARY_URL_TRANSFORM_REGEX = new RegExp(`^(${CLOUDINARY_URL_TRANSFORM_KEYS.join('|')})_[\\w\\d.:$!\\s()]+$`)

const isCloudinaryTransform = (str) => {
  return !!str.match(CLOUDINARY_URL_TRANSFORM_REGEX)
}

export const getComponentsFromCloudinaryUrl = (urlOrPublicId) => {
  if (!isUrlCloudinary(urlOrPublicId)) {
    // assume it's a public id if no cloudinary host
    // we can't (well, shouldn't) call this on a non-cloudinary url
    return {
      base_url: `https://${CloudinaryDefaultConfig.secure_distribution}/${CloudinaryDefaultConfig.cloud_name}/image/upload`,
      type: 'upload',
      transforms: '',
      public_id: urlOrPublicId
    }
  }

  const [split, type] = urlOrPublicId.match(/\/image\/(upload|youtube)\//)
  const base_url = `https://${CloudinaryDefaultConfig.secure_distribution}/${CloudinaryDefaultConfig.cloud_name}/image/${type}`;
  const [ignore, rest] = limitSplit(urlOrPublicId, split, 2);

  const segments = rest.split('/')
  const public_id = [];
  const transforms = [];
  let gettingTransforms = true;
  for (let segment of segments) {
    if (gettingTransforms && segment.split(',').every(isCloudinaryTransform)) {
      transforms.push(segment)
    } else {
      gettingTransforms = false;
      public_id.push(segment)
    }
  }

  return {
    base_url,
    type,
    transforms: transforms.join('/'),
    public_id: public_id.join('/')
  };
}

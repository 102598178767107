import { define, ui } from '@owenscorning/pcb.alpha';
import { Edit } from '../Page/AttentionBarPicker';

export default define`Attention Bar`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/AttentionBarWithImage`,
  view: ({ value }) => {
    return <UI.Modules.Page.AttentionBarPicker value={ value } />
  },
  edit: Edit({ size: { default: "m" } })
});

import { useCallback, useState, isValidElement, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { define } from '@owenscorning/pcb.alpha';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import Data from '../Data';

// TODO replace with UI.Text?
import OcTextInput from '../../OC/forms/oc-text-input';
import TabGroup from '../../ComponentLibrary/tabs/TabGroup';
import Tab from '../../ComponentLibrary/tabs/Tab';

import Theme from '../../../themes';

const AjaxOverlayWrapper = styled.div`
  position: relative;
  height: 100%;
  min-height: 50px;
`;

const LoaderProgress = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: white;
  opacity: .8;
  span {
    position: absolute;
    left: 50%;
    width: 18px;
  }
`;

const Spinner = styled.div`
  padding: 10px;
  height: 28px;
`;

const AjaxOverlay = ({show, fetching, children, message}) => (
  <AjaxOverlayWrapper>
    {children}
    {
      (show||fetching) &&
      <LoaderProgress>
        {fetching && <Spinner><UI.Icon type="spinner" spin large /></Spinner>}
        {message &&
          <div>
            {message}
          </div>
        }
      </LoaderProgress>
    }
  </AjaxOverlayWrapper>
);


const HtmlTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
`

const Header = styled.thead`
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
`

const Divider = styled(({ className }) => (
  <tr className={ className }><td colSpan="100%" /></tr>
))`
  td {
    border-bottom: solid 1px #959595;
  }
`

const Label = styled.span`
  font-family: OCRoboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  display: block;
  padding-top: 16px;
  padding-bottom: 8px;
`;

const Info = styled.td`
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 8px;
`

const Row = styled.tr`
  cursor: pointer;
  border: solid 2px white;
  &:hover {
    border: solid 2px ${Theme.colors.brand};
  }
`

// only show for capitalized key name like "ID" or "First Name", others like "id" or "firstName" will be skipped
const keysForDisplay = (object) => (
  object ? Object.keys(object).filter(key => /^[A-Z]/.test(key)) : []
)

const FullWidthTab = styled(Tab)`
  width: 100%
`

const Table = ({ source, data, onClick }) => {
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  useDeepCompareEffectNoCheck(() => {
    if (source === 'data') {
      const adapter = Data.for('Search')[data?.dataset];
      if (adapter) {
        setLoading(true);
        if (adapter.tabs) {
          setTabs(adapter.tabs);
          adapter.tabs[selectedTab].search(data?.parameters?.[data?.dataset]).then(result => {
            setResults(result);
            setLoading(false);
          });
        } else {
          adapter.search(data?.parameters).then(result => {
            setResults(result);
            setLoading(false);
          });
        }
      }
    }
  }, [source, data, selectedTab]);

  const header = results === null ? <div /> : (results.length > 0 ? <Header>{ keysForDisplay(results[0]).map(k => <th>{ k }</th>) }</Header> : <div><Header><th>No results found</th></Header></div>)
  const body = results ? results.map((r,i) => (
    <>
      {
        i > 0 && <Divider key={`divider-${i}`} />
      }
      <Row onClick={ () => onClick(r) } key={ i }>
        { keysForDisplay(r).map(key => (
            <Info>{ r[key] }</Info>
          ))
        }
      </Row>
    </>
  )) : null;

  return (
    <>
      {
        tabs.length > 0 && results !== null && <TabGroup tabsAlign="center" tabSize="large" tabCount={ tabs.length } >
          {
            tabs.map((tab, i) => (
              <FullWidthTab
                key={ i }
                tabSize="large"
                active={ selectedTab === i }
                onClick={ () => setSelectedTab(i) }
                label={ tab.label }
              />
            ))
          }
        </TabGroup>
      }
      <AjaxOverlay fetching={ loading } message="Loading">
        <HtmlTable>
          { header }
          <tbody>
          { body }
          </tbody>
        </HtmlTable>
      </AjaxOverlay>
    </>
  );
}

// TODO - why is onChange intercepted by modal pcb scan?  it's replaced, not chained.  hence the name onSelect
const SearchableTable = ({ dataset, onSelect }) => {
  const [filter, setFilter] = useState('');

  return (
    <div>
      {/* TODO get UI.Text working, keeps returning `undefined' */}
      {/*<label>Search</label>*/}
      {/*<UI.Text*/}
      {/*  MODE="edit"*/}
      {/*  value={ filter }*/}
      {/*  onChange={ setFilter }*/}
      {/*  controlled*/}
      {/*/>*/}
      <OcTextInput focus label={ <Label>Search</Label> } value={ filter } onChange={ setFilter } debounced={ 500 } />
      <Table
        source="data"
        data={ { dataset, parameters: { [dataset]: { filter } } } }
        onClick={(r) => {
          onSelect(r)
          Board.modal.close();
        }} />
    </div>
  );
}

export default define`Search`('0.0.1')({
  view: ({ value }) => <div />,
  edit: ({UI, Board, contents, path, title, value, onChange, label, dataset, get, set, startOpen, editable = true, ...props}) => {
    const onSelect = useCallback(val => {
      onChange(set(val, path))
    });
    const onClick = useCallback(() => {
      const modalDefinition = {
        title: `Add ${label}`,
        body: <SearchableTable dataset={ dataset } onSelect={ onSelect } />
      }
      Board.modal.open(modalDefinition)
    });
    useEffect(() => {
      if (startOpen) {
        onClick();
      }
    }, [])

    const selection = get(value);

    if (isValidElement(selection)) {
      return selection;
    }

    const adapter = Data.for('Search')[dataset];
    const view = adapter && selection && adapter.preview && adapter.preview(selection)

    return (
      <div>
        {
          !selection && editable && <UI.Button action={ onClick }>
            Select { label }
          </UI.Button>
        }
        {
          selection &&
          <>
            { view ? view : <span>{ selection.toString() }</span> }

            { editable &&
              <div style={{ textAlign: 'center' }}>
                <UI.Button action={ onClick }>
                  Change { label }
                </UI.Button>
              </div>
            }
          </>
        }
      </div>
    );
  }
});


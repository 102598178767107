import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Svg = styled.svg`
  ${props => (props.hoverColor && css`
    &:hover {
      fill: ${props.hoverColor};
    }
  `)}
`;

const IconBase = ({fill='currentColor', width, height, hoverColor, className, children, viewBox}) => (
  <Svg
    aria-hidden="true"
    className={className}
    fill={fill}
    height={height}
    hoverColor={hoverColor}
    role="img"
    viewBox={viewBox}
    width={width}
  >
    {children}
  </Svg>
);

export default IconBase;
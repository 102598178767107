export const co2Calculator = (displayedProduct) => {

  let result = {}

  if(displayedProduct == {}) {
    return result;
  }

  let base_val_1 = displayedProduct["base_value_1"];
  let base_val_2 = displayedProduct["base_value_2"];
  let scaling_factor = displayedProduct["scaling_factor"];
  let lambda = displayedProduct["lambda_value"];
  let density = displayedProduct["density"];
  let thickness = displayedProduct["thickness"];

  result = {
    "a1A3GWPM2R1": base_val_1 * scaling_factor,
    "a1A3GWPM2": thickness * base_val_1 * scaling_factor / lambda / 1000,
    "a1A3GWPKg": (base_val_1 * scaling_factor)/(lambda * density),
    "a1A3GWPM3": base_val_1 * scaling_factor / lambda,
    "a1C4GWPM2R1": base_val_2 * scaling_factor,
    "a1C4GWPM2": thickness * base_val_2 * scaling_factor / lambda / 1000,
    "a1C4GWPKg": (base_val_2 * scaling_factor)/(lambda * density),
    "a1C4GWPM3": base_val_2 * scaling_factor / lambda,
    "a1C4GWPAvgDensity": density,
    "a1C4GWPThermalResistance": thickness / lambda / 1000
  }

  return result;
};

import { ui, request } from '@owenscorning/pcb.alpha';
import { useRef, useCallback, useMemo } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';

import Select, { components } from 'react-select';
import {
  getReactSelectOptions,
  getOptions,
  getParentReactSelectOption,
} from '../../../helpers/react-select';

import _ from 'lodash';

const Wrapper = styled.div`
  display: flex;

`;

const Group = styled.ul`
  list-style: none;
  padding-left: 0px;
  margin: 0;
  margin-top: 0px;
  cursor: default;
  label {
    display: grid;
    position: relative;
  }

  li:last-of-type {
    margin-bottom: 0;
  }
`;

const ListContainer = styled.ul`
  list-style: none;
  padding-left: 0px;
  margin: 0;
  margin-top: 0px;
  cursor: default;
  li:last-of-type {
    margin-bottom: 0;
  }
  flex: 1;
`;

const Drag = {
  Container: styled.div`
    transition: opacity 0.4s, width 0.3s;
    opacity: ${({ enabled }) => (enabled ? 1.0 : 0.0)};
    width: ${({ enabled }) => (enabled ? 20 : 0)}px;
    overflow: hidden;
    flex-shrink: 0;
    ${({ minimal, enabled }) =>
      minimal &&
      css`
        width: ${enabled ? 27 : 0}px;
      `}
  `,
  Button: styled.div`
    width: 11px;
    height: 46px;
    margin-right: 10px;
    background-image: url(${() => UI.Images.Drag});
    background-position: center;
    background-size: auto 17px;
    background-repeat: no-repeat;
    cursor: grab;
    pointer-events: ${({ enabled }) => (enabled ? 'auto' : 'none')};
    user-select: none;
    }
  `,
};

const DraggableLi = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  span {
    flex: 1;
    background-color: #fff;
    border: 1px solid ${() => UI.Theme.colors.greyShade3};
    border-radius: 8px;
    padding: 16px;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    display: flex;
    justify-content: space-between;
    opacity: 0.5;
    &.selected {
      border: 1px solid ${() => UI.Theme.colors.black};
      opacity: revert;
    }
  }
  label {
    // top: 1px;
    user-select: none;
    color: ${() => UI.Theme.colors.greyShade3};
    &.selected {
      color: ${() => UI.Theme.colors.black};
    }
  }
`;
const objectToMap = obj => new Map(Object.entries(obj));
const mapToObject = map => Object.fromEntries(map.entries());

const orderChildren = (children = [], sorted = []) => {
  return [...children].sort(
    (a, b) => sorted.indexOf(a.value) - sorted.indexOf(b.value)
  );
};

const objToOrderedList = (obj)=> Object.keys(obj).reduce((acc, key) => {
  return [...acc, {[key]: obj[key]}]
}, [])

const orderedListToObj = (list) => list.reduce((acc, obj) => {
  return {
    ...acc,
    ...obj
  };
}, {});

const ListItem = ({option, name, isDisabled, i, value, onChange}) => {
  let { label, value: key, disabled } = option;

  const field = `${ name }[${ key }]`;
  const contains = option.children;
  const checkboxRef = useRef();
  if (isDisabled) disabled = true;
  return (
    <Draggable key={ field } draggableId={ field } index={ i }>
      { (provided, snapshot) => {
        if (snapshot.isDragging) {
          const offset = { x: 50, y: 165 }; // fixing offset
          const x = provided.draggableProps.style.left - offset.x;
          const y = provided.draggableProps.style.top - offset.y;
          provided.draggableProps.style.left = x;
          provided.draggableProps.style.top = y;
        }

        return (
          <DraggableLi
            ref={ provided.innerRef }
            { ...provided.draggableProps }
            { ...provided.dragHandleProps }
          >
            <Drag.Button enabled={ true }/>
            <span
              className={
                _.get(value, key)
                  ? 'selected'
                  : ''
              }
            >
                          <label
                            className={
                              _.get(value, key)
                                ? 'selected'
                                : ''
                            }
                          >
                            { label }
                          </label>
                          <input
                            style={ { display: 'none' } }
                            type="checkbox"
                            ref={ checkboxRef }
                            key={ field }
                            name={ field }
                            id={ field }
                            checked={ _.get(value, key) }
                            value={ _.get(value, key) }
                            onChange={ e =>
                              onChange(_.set(value, key, e?.target?.checked))
                            }
                          />
                          <i
                            class={ `fa fa-eye${
                              _.get(value, key)
                                ? ``
                                : `-slash`
                            }` }
                            aria-hidden="true"
                            onClick={ () => checkboxRef?.current?.click() }
                            style={ { cursor: 'pointer' } }
                          ></i>
                        </span>
          </DraggableLi>
        );
      } }
    </Draggable>
  );
}

const DragDropList = ({
  name,
  value = {},
  onChange,
  children,
  isDisabled,
  onReorder,
}) => {
  const onDragEnd = useCallback(
    result => {
      const newItems = [...Object.keys(value)];
      const [removed] = newItems.splice(result.source.index, 1);
      newItems.splice(result.destination.index, 0, removed);
      const obj = newItems.reduce((accumulator, key) => {
        return {
          ...accumulator,
          [key]: value[key],
        };
      }, {});
      onReorder(obj);
    },
    [value]
  );
  const childrenList = useMemo(
    () => orderChildren(children, Object.keys(value)),
    [children, value]
  );
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-1">
        {(provided, snapshot) => (
          <ListContainer {...provided.droppableProps} ref={provided.innerRef}>
            {
              _.map(childrenList, (option, i) => <ListItem key={ `${ name }[${ option.value }]` } option={ option } name={ name } isDisabled={ isDisabled } i={ i } value={ value } onChange={ onChange } />)
            }
            {provided.placeholder}
          </ListContainer>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const formatValues = (value, content) => (
    Object.fromEntries(
      content.map(c =>
        [
          c.value,
          c.children ? formatValues(value, c.children) : value.indexOf(c.value) >= 0
        ])
    )
  )

const ChoicesDraggable = props => {
  const {
    name,
    onChange,
    value: savedData = {},
    disabled = false,
    subchecks = false,
  } = props;

  const {orderedList} = savedData

  const value = orderedList && orderedListToObj(orderedList) || _.isArray(savedData) && savedData || []

  let { contents, mode = undefined, format = undefined } = props;
  if (_.isFunction(contents)) {
    contents = contents();
  }

  if (_.isEmpty(contents)) contents = {};

  if (_.isString(contents))
    return (
      <Subschema>
        {[
          ui`Choices`(
            _.omit(props, ['Board', 'Contents', 'Subschema', 'UI', 'contents'])
          ),
          request(contents)
            .during({ disabled: true, placeholder: 'Loading options...' })
            .failure(error => ({
              disabled: true,
              placeholder: 'Error loading! Please try again',
            }))
            .success(({ data }) => ({ contents: data })),
        ]}
      </Subschema>
    );


  contents = getOptions(contents)

  const formattedOnChange = (data) => {
    onChange({
        enabledFilters: Object.keys(data).filter(k => data[k]),
        orderedList: objToOrderedList(data), // required for drag and drop feature to keep order of filters
    })
}
  const resetSavedValueType = value =>{
    //checking if value is an array for backward compatibility, previous saved data may have been an array if ui Choices was saved
    return _.isArray(value) ? formatValues(value, contents) : value;
  }

  return (
    <Wrapper mode={mode} key={name}>
      {
        <DragDropList
          name={name}
          value={resetSavedValueType(value)}
          onChange={formattedOnChange}
          onReorder = {formattedOnChange}
          isDisabled={disabled}
          subchecks={subchecks}
        >
          {contents}
        </DragDropList>
      }
    </Wrapper>
  );
};


export default ChoicesDraggable;

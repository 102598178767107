export default {
  displayName: 'Hero',
  allowableParents: [],
  migrator: (data) => {
    data.preset = data.preset || 'custom';

    // PB-585: Need to convert old boolean values to match new options
    if (data.preset === 'custom') {
      const showHeroImage = data.showHeroImage;
      const showHeroImageMap = { true: 'standard', false: 'none' };
      data.showHeroImage = (typeof showHeroImage === 'boolean') ? showHeroImageMap[showHeroImage] : showHeroImage;
    }

    // PB-727: Need to convert imagePosition values to new contentOrder options
    // Desktop value remains the same, but mobile value is reversed
    if (data.imagePosition) {
      const imagePosition = data.imagePosition;
      const contentOrderMobile = (imagePosition.mobile == "last") ? "first" : "last";
      data.contentOrder = { desktop: imagePosition.desktop, mobile: contentOrderMobile };
      delete data.imagePosition;
    }

    // PB-444: Hero images have been transformed to use UI`Image` instead of UI`Upload/Image`.
    //         As a consequence, we need to migrate the already set images to the new structure,
    //         so that we don't lose the existing Heroes and allow cropping the previously
    //         existing images. As a precaution, setting a default of 3k x 3k on the crop here
    //         since we have Hero backgrounds that have a very long horizontal image.
    if (typeof data?.images?.bgDesktop === 'string') {
      data.images = {
        bgDesktop: {
          file: data?.images?.bgDesktop,
          crop: undefined,
        },
        bgTablet: {
          file: data?.images?.bgTablet,
          crop: undefined,
        },
        bgMobile: {
          file: data?.images?.bgMobile,
          crop: undefined,
        }
      }
    }

    if (data[data.preset]) {
      const { type, images, logo, copy, ctas, background, panther } = data[data.preset];
      data = {
        type, images, logo, copy, ctas, background, panther,
        ...data
      };
    }
    return data;
  },
};

import { define, ui } from '@owenscorning/pcb.alpha';

import LegendView from './LegendView';

export default define`Legend`('0.0.1')({
  view: LegendView,
  edit: {
    attribute: ui`Attribute`({}),
  },
});

import styled from '@emotion/styled';
import { jsx, css } from '@emotion/react';
import IconBase from './icon-base';

const IconPlus = ({fill, width, height, hoverColor, className}) => (
	<IconBase className={className} viewBox="0 0 448 512" width={width} height={height} fill={fill} hoverColor={hoverColor} >
		<path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
	</IconBase>
);

export default IconPlus;
import { useRef, useState } from 'react';
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import { useAccessibleInteractivity } from "../../../hooks";
import Theme from '../../../themes/index';
import PropTypes from 'prop-types';

const RadioButtonLabel = styled.label`
  color: ${props => props.disabled ? '#666' : 'black' };
  display: flex;
  flex-direction: ${props => props.labelLeft ? 'row-reverse' : 'row'};
  justify-content: ${props => props.labelLeft ? 'space-between' : 'flex-start'};
  opacity: ${props => props.disabled ? '.5' : '1' };
  position: relative;

  &:hover {
    cursor: ${props => props.disabled ? 'default' : 'pointer' };
  }

  &:focus {
    outline-offset: ${props => props.disabled ? '0' : '2px' };
    outline: ${props => props.disabled ? 'none' : `${props.themeColor} solid 2px` };
  }

  &:focus:not(:focus-visible) {
    outline: none !important;
  }

  &:focus-visible {
    outline-offset: ${props => props.disabled ? '0' : '2px' };
    outline: ${props => props.disabled ? 'none' : `${props.themeColor} solid 2px` };
  }

  input[type="radio"]:checked {
    background-color: white;
    border-color: ${props => props.disabled ? '#666666' : props.themeColor };
  }

  input[type="radio"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #D1D1D1;
    cursor: ${props => props.disabled ? 'default' : 'pointer' };
    flex-shrink: 0;
    height: 16px;
    margin: 2px 8px 0 0;
    width: 16px;
  }

  input[type="radio"] ~ .radio {
    background: ${props => props.themeColor};
    border-radius: 6px;
    display: none;
    height: 8px;
    left: ${props => props.labelLeft ? 'auto' : '4px'};
    position: absolute;
    top: 6px;
    width: 8px;

    ${props => props.labelLeft === true && `
      right: 12px;
    `}
  }

  input[type="radio"]:checked ~ .radio {
    display: inline-block;
  }
`;

const RadioButton = ({
  children,
  defaultChecked=false,
  disabled=false,
  labelLeft=false,
  name,
  themeColor = Theme.colors.brand,
  onChange,
  value,
  ...props
}) => {
  const [checked, setChecked] = useState(defaultChecked);
  const radioButton = useRef(null);

  const updatedChecked = (event) => {
    setChecked(!checked);
    {onChange && onChange(event)}
  }

  return (
    <RadioButtonLabel
      disabled={disabled}
      labelLeft={labelLeft}
      themeColor={themeColor}
      role="button"
      tabIndex="-1"
    >
      <input
        defaultChecked={checked}
        disabled={disabled}
        name={name}
        onChange={updatedChecked}
        readOnly={disabled ? "readonly" : null}
        ref={radioButton}
        tabIndex={disabled ? "-1" : "0"}
        type="radio"
        value={value}
        { ...props }
      />
      { children }
      <span
        aria-checked={checked}
        aria-disabled={disabled}
        className="radio"
        role="checkbox"
      />
    </RadioButtonLabel>
  );
};

export default RadioButton;

RadioButton.propTypes = {
  /** This is where you put the label of the checkbox */
  children: PropTypes.string,
  /** Set this to true, if you want the checkbox disabled */
  disabled: PropTypes.bool,
  /** Set a name for the radio group */
  name: PropTypes.string,
  /** Set a value for the radio */
  value: PropTypes.string,
  /** Call the function you want to fire when changed */
  onChange: PropTypes.func,
  /** If you want the checkbox checked on load */
  defaultChecked: PropTypes.bool,
  /** If you want the label to be left and the check to the right */
  labelLeft: PropTypes.bool,
  /** Hex value for color theme */
  themeColor: PropTypes.string
}

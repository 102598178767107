const BuildInfo = ({ users, language_summary: { edited_by, edited_by_name, published_by, published_by_name, ...summary } }) => {
  const editor = edited_by_name || users?.[edited_by]?.name;
  const publisher = published_by_name || users?.[published_by]?.name;

  return (
    <UI.Build.Variants
      compact
      showDots={ false }
      showName={ false }
      showLocale={ false }
      list={[{
        edited: { by: editor, at: UI.Build.Status.Stamp.Edit(summary) },
        published: publisher ? { by: publisher, at: UI.Build.Status.Stamp.Publish(summary) } : null,
        status: UI.Build.Status.Flags(summary)
      }]}
    />
  )
}

BuildInfo.displayName = 'Build.Info'

export default BuildInfo;

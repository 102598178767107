import PropTypes from 'prop-types';

import Text from '../../../../OC/PageBuilder/utilities/Text';
import FigureOverlay from '../../../../OC/oc-figure-overlay';
import { imageOverlayConfig } from '../../utils/imageOverlayConfig';

const RoofingPreview = ({
  selectedShingle,
  heroImage,
  subtitle,
  colorName,
}) => {
  const { results = {} } = selectedShingle;
  const { data = {} } = results;
  const { shingle_colors: shingleColors = [] } = data;

  if (!shingleColors.length) return null;

  const shingleColor = shingleColors[0];
  const { baseLayer, overlays } = imageOverlayConfig({
    shingleColor,
    heroImage,
  });

  return (
    <div>
      <span className="posthead">
        <Text content={subtitle} />
      </span>

      <h1 className="tier3 title">
        <Text content={`${colorName} & ${shingleColor.name}`} />
      </h1>

      <div>
        <FigureOverlay
          className="mobile-only"
          size="xxl"
          baseLayer={baseLayer.mobile}
          overlays={overlays.mobile}
        />
        <FigureOverlay
          className="tablet-only"
          size="xxl"
          baseLayer={baseLayer.tablet}
          overlays={overlays.tablet}
        />
        <FigureOverlay
          className="desktop-only"
          size="full-bleed"
          baseLayer={baseLayer.desktop}
          overlays={overlays.desktop}
        />
      </div>
    </div>
  );
};

RoofingPreview.propTypes = {
  selectedShingle: PropTypes.shape({
    results: {
      data: {
        shingle_colors: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
          })
        ),
      },
    },
    cropSettings: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    positioning: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
  }),
  heroImage: PropTypes.shape({
    mobile: PropTypes.shape({
      file: PropTypes.string,
      alt: PropTypes.string,
    }),
    tablet: PropTypes.shape({
      file: PropTypes.string,
      alt: PropTypes.string,
    }),
    desktop: PropTypes.shape({
      file: PropTypes.string,
      alt: PropTypes.string,
    }),
  }).isRequired,
  colorName: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

RoofingPreview.defaultProps = {
  selectedShingle: {
    results: {
      data: {
        shingle_colors: [],
      },
    },
    cropSettings: {},
    positioning: {},
  },
  subtitle: 'SHINGLE INSPIRATION',
};

export { RoofingPreview };

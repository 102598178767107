import _ from 'lodash';

import { connect } from 'react-redux';
import { compose, combineReducers } from 'redux';

import onlineStatusReducer, { onlineStatusConnector, onlineStatusMiddleware } from "./onlineStatus";
import usersReducer, { usersConnector, usersMiddleware } from "./users";
import contentReducer, { contentConnector } from "./content";
import saveStatusReducer, { saveStatusConnector, saveStatusMiddleware } from "./saveStatus";
import editorReducer, { editorConnector } from "./editor";
import { renderConnector } from "./render";

// TODO remove this, just wanted to get a sample for styling
import { toast } from "../helpers/toast";

if (typeof(window) !== 'undefined') {
  window.toastWarn = () => (toast.warn('sample warning toast'));
  window.toastError = () => (toast.error('sample error toast'));
  window.toastPersistent = () => (toast.warn('sample persistent toast', {autoClose: false}));

  const permanentToastId = '1234567890';
  window.toastPermanent = () => (toast.warn('permanent toast', {
    toastId: permanentToastId,
    autoClose: false,
    closeButton: false
  }))
  window.closePermanentToast = () => (toast.dismiss(permanentToastId))
}
// TODO end remove this


// shape of redux cms state:
/*

{
  outerFrame: true,
  content_id: 1,
  user_id: 1,  // current user id
  selectedItem: {…}
}

 */

// some elements we inject to state from page render, but we don't actually mutate them so there are no actions to take
const noOpReducer = (state = null) => state;

export const cmsReducer = combineReducers({
  outerFrame: noOpReducer,
  user_id: noOpReducer,
  content_id: noOpReducer,
  content_info: noOpReducer,
  language: noOpReducer,
  previousVersions: noOpReducer,
  zipFile: noOpReducer,
  ...onlineStatusReducer,
  ...usersReducer,
  ...contentReducer,
  ...saveStatusReducer,
  ...editorReducer
});


export const cmsMiddleware = store => next => action => {
  const { cms: { outerFrame } } = store.getState();
  const middlewares = [
    usersMiddleware,
    saveStatusMiddleware,
    onlineStatusMiddleware
  ]
  return compose(
    ...[...middlewares.map(middleware => middleware(store))]
  )(next)(action);
};

// this is too big of a connector, you should rather use one more focused to the type of data you need
export const cmsConnector = compose(
  onlineStatusConnector,
  contentConnector,
  usersConnector,
  saveStatusConnector,
  renderConnector,
  //editorConnector    <-- because trying to eliminate "superstate" connector don't bind these here until we need it (and even then avoid it)
  connect(
    (state) => ({
      outerFrame: state.cms.outerFrame,
      user_id: state.cms.user_id,
      content_id: state.cms.content_id,
      language: state.cms.language
    })
  )
);

export {
  onlineStatusConnector as cmsOnlineStatusConnector,
  contentConnector as cmsContentConnector,
  usersConnector as cmsUsersConnector,
  saveStatusConnector as cmsSaveStatusConnector,
  editorConnector as cmsEditorConnector,
  renderConnector as cmsRenderConnector
};

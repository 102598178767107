import styled from '@emotion/styled';
import _ from 'lodash';

import { InputWrapper } from './InputWrapper';
import { Radios } from '../../Builder/Library/Choices';
import Label from '../../ComponentLibrary/input-elements/oc-label';

// need to override some old global styles
const RadioWrapper = styled.div`
  input[type='radio'] {
    opacity: 1;
    height: 20px;
    width: 20px;
    margin-right: 10px;
    margin-left: 0px;
    display: inline-block;
  }
`;

export function makeLanguageOption({ code, countryName, languageName }) {
  return {
    value: code.split('-')[0],
    label: `${_.capitalize(languageName)} (${countryName})`,
  };
}

export const LanguagePicker = ({ setLanguage, options, value, label }) => (
  <InputWrapper>
    <RadioWrapper>
      <Label text={label} />
      <Radios value={value} onChange={setLanguage} mode="vertical">
        {options}
      </Radios>
    </RadioWrapper>
  </InputWrapper>
);

import _ from 'lodash';
import Twitter from "./Images/Social/TwitterSquare";

const iconDimension = "32px";

const Platforms = {
  facebook: {
    icon: 'facebook-square fa-brands',
    share: (url, message) => `https://www.facebook.com/sharer/sharer.php?u=${ url }&quote=${ message }`
  },
  twitter: {
    icon: Twitter({ height: iconDimension, width: iconDimension }),
    share: (url, message) => `https://twitter.com/intent/tweet?url=${ url }&text=${ message }`
  },
  linkedin: {
    icon: 'linkedin fa-brands',
    share: (url) => `https://www.linkedin.com/sharing/share-offsite/?url=${ url }`
  },
  pinterest: {
    icon: 'pinterest fa-brands',
    share: (url) => `http://pinterest.com/pin/create/button/?url=${ url }`
  }
};

const Share = ({ platform, url=window.location.href, message, children, ...props }) => {
  const iconkey = (_.isString(Platforms[platform].icon)) ? "type" : "image";
  const iconProps = { [iconkey]: Platforms[platform].icon };

  return (
    <UI.Button action={Platforms[platform].share(url, message)} popup>
      {children ? children : <UI.Icon {...iconProps} x2 />}
    </UI.Button>
  )
};

_.each(Platforms, (platform, name) => Share[_.capitalize(name)] = (props) => <Share { ...props } platform={ name } /> )
export default Share;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Theme from '../../../themes';

const Selection = styled.div`
  position: relative;

  &:after {
    content: '';
    pointer-events: none;
    position: absolute;
    z-index: 9999;

    height: 100%;
    left: 0px;
    margin: 0 16px;
    right: 0px;
    top: 0px;

    outline: 3px solid rgba(212,15,125,0);
    outline-offset: 8px;
    transition: all 0.2s;

    ${ ({ selector }) => Board.selection == selector && css`
      outline-color: ${Theme.colors.brand};
      outline-offset: 14px;

      @media (${ UI.Viewport.to.Desktop }) {
        margin: 0 5px;
        outline-offset: 11px;
      }
    ` }
  }
`;

export default ({ selectable=true, unwrapped, children, onClick, selector, ...props }) => (
  Board.editable && selectable
    ? <Selection
      id={ selector }
      onClick={ Board.editable && onClick }
      selector={ Board.editable && selector }
      { ...props }
    >
      { children }
    </Selection>
    : (
      unwrapped
        ? children
        : <div id={ selector } >{ children }</div>
    )
);

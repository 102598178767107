import { jsx, css } from '@emotion/react';
import { define, ui } from '@owenscorning/pcb.alpha';

import Renderer from './../../../../OC/PageBuilder/ProCatCalculator'


export default define`ProCat Calculator`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/NGXCalculator`,
  category: ui`Modules/Categories/Page/Prebuilt`,
  view: ({ value }) => <Renderer { ...value } />,
  edit: {
    content: ui`Form`.of({
      copy: ui`Content/Basic`,
    })({ label: 'Content' }),
    calculator: ui`Form`.of({
      costPerBag: ui`Text`({ label: 'Cost Per Bag', default: 45.00}),
      installers: ui`Text`({ label: 'Number of Installers', default: 2 }),
      laborRate: ui`Text`({ label: 'Default Labor Rate', default: 25.00 }),
      markup: ui`Text`({label: 'Desired Markup', default: 100}),
      footnotes: ui`Text/Rich`({ label: 'Footnotes' }),
    })({ label: 'Calculator Defaults' })
  },
});

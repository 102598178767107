import { appendAspectRatio, migrateArticleDataSetParameters, migrateDataSetParameters } from '../../../migrations';

export default {
  displayName: 'Card Set',
  allowableParents: ['Section'],
  migrator: (data) => {
    if (data === undefined || data === null) {
      return {}
    }
    data = appendAspectRatio(data);
    data.data = migrateDataSetParameters(data.data, data.data?.dataset)
    if (data.data?.dataset === 'iss' || data.data?.dataset === 'roofing_blog') {
      data.data = migrateArticleDataSetParameters(data.data, data.data.dataset)
    }

    // PB-444: Card images were using the UI`Upload/Image`, but now we are using
    //         UI`Image` component. This means that the structure is different and
    //         we need to alter it here in order to not break previously existinh
    //         cards.
    const transformedCards = data.cards?.map((card) => {
      if (typeof card.image === 'string') {
        return {
          ...card,
          image: {
            file: card.image,
            crop: undefined,
          }
        };
      } else {
        return card;
      }
    });
    data.cards = transformedCards;

    return data;
  },
};

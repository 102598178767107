/* eslint-disable no-restricted-syntax, no-param-reassign */
// recursively flatten keys, joining path
// flattenKeys({a: {b: {c: 1}}, d: 2}) => { 'a.b.c': 1, d: 2 }
export default function flattenKeys(obj, currentPath = '', result = {}) {
  for (const key of Object.keys(obj)) {
    const newPath = currentPath ? `${currentPath}.${key}` : key;

    if (
      typeof obj[key] === 'object' &&
      obj[key] !== null &&
      !Array.isArray(obj[key])
    ) {
      flattenKeys(obj[key], newPath, result);
    } else {
      result[newPath] = obj[key];
    }
  }

  return result;
}

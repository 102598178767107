import { jsx, css } from '@emotion/react';
import { define, ui, s, select, when } from '@owenscorning/pcb.alpha';

import Renderer from './../../../../OC/PageBuilder/FullBleedContent.jsx';
import placeholder_url from '../../../../../placeholder';

export default define`Full Bleed Content`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/FullBleedContent`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value }) => <Renderer { ...value } />,
  gutterlessForReusable: true,
  gutterless: true,
  edit: {
    content: ui`Content/Basic`,
    img: ui`Image`({
      askAlt: false,
      label: 'Background',
      crop:true,
      defaultImage: placeholder_url(1340,600)
    }),
    imageSizeInfo: ui`ImageSizeInfo`(
      {
        moduleName: 'Full Bleed Content'
      },
    ),
    alt: ui`Text`({
      label: 'Alt Text',
      visible: when`../img`.is.present
    }),
    layout: [ {
      aspectRatio: ui`AspectRatio`,
      [s._]: ui`Tip`.of('Since Desktop uses a background image, aspect ratio will only affect tablet and mobile.'),
      contentSide: ui`Choices`.of({
        left: 'Left',
        right: 'Right'
      })({
        label: 'Content Side (Desktop)',
        default: 'right'
      })
    }, {
      label: 'Layout'
    } ],
    hotSpots: ui`List`.of({
      primaryText: ui`Text`({ label: 'Black Primary Text' }),
      pinkText: ui`Text`({ label: 'Brand Primary Text' }),
      disclaimerText: ui`Text`({ label: 'Disclaimer Text' }),
      row: ui`Choices`.of({
        '1': '1',
        '2': '2',
        '3': '3'
      })({
        label: 'Row',
        default: '1'
      })
    })({
      min: 0,
      max: 3,
      singular: 'Callout',
      title: 'Callout',
      label: 'Callouts'
    })
  },
});

import { define, ui } from '@owenscorning/pcb.alpha';
import { jsx, css } from '@emotion/react';

export default define`Object`('0.0.1')({
  view: ({ prehead, heading, body, link }) => <div>
    { prehead && <UI.Prehead T4 >{ prehead }</UI.Prehead> }
    { heading && <UI.H2 T2 >{ heading }</UI.H2> }
    <UI.Paragraph>{ body }</UI.Paragraph>
    <UI.Link.Chevron { ...link } />
  </div>,
  edit: {
    prehead: ui`Text`,
    heading: ui`Text`,
    body: ui`Text/Rich`,
    link: ui`Link`
  }
})

import PropTypes from 'prop-types';
import React from 'react';

import {
  ResultWrapper,
  Media,
  ResultInfo,
} from './result-block-styled-components';
import Image from '../oc-image';

const ResultBlock = ({ children, media, alt, style }) => (
  <ResultWrapper {...(style && { style })}>
    {media && (
      <Media>
        <Image src={media} alt={alt} />
      </Media>
    )}

    <ResultInfo>{children}</ResultInfo>
  </ResultWrapper>
);

export default ResultBlock;

ResultBlock.propTypes = {
  /** Where the specific results block goes */
  children: PropTypes.node,
  /** The image path to the image or logo */
  media: PropTypes.string,
  /** The alt text for the image or logo */
  alt: PropTypes.string,
};

import styled from '@emotion/styled';
import React, { useContext } from 'react';

// Imported Components
import {
  CallNowCTA,
  CustomizedComponent,
  Email as EmailBlockPart,
} from './filter-result-blocks/block-parts';
import { formatAddress } from './filter-result-blocks/format-address';
import { formatPhone, phoneHref } from './filter-result-blocks/format-phone';
import IconChevron from './icons/icon-chevron';
import Button from './oc-button';
import LocatorContext from '../OC/PageBuilder/Locator/context';
import { H4 } from '../OC/oc-h';
import { connectLocation } from '../location/LocationConnectors';

// Styled Components
const PopupContainer = styled.div`
  @media (max-width: 400px) {
    max-width: 310px;
  }

  background-color: #ffffff;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.25);
  display: inline-block;
  max-width: 347px;
  position: relative;
  width: 100%;

  &:before,
  &:after {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    content: '';
    display: inline-block;
    height: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
  }

  &:before {
    border-top: 10px solid rgb(0 0 0 / 25%);
    bottom: -11px;
    filter: blur(4px);
    z-index: -1;
  }

  &:after {
    border-top: 10px solid white;
    bottom: -9px;
  }
`;

const PopupSection = styled.div`
  background-color: #ffffff;
  display: inline-block;
  padding: 8px;
  position: relative;
  width: 100%;
`;

const PopupHeader = styled(PopupSection)`
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
`;

const Name = styled(H4)`
  margin: 0 !important;
`;

const LocationImage = styled.div`
  background-color: #e6e6e6;
  background-image: url(${props => props.imgUrl});
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  position: relative;
  width: 32px;
`;

const PopupBody = styled(PopupSection)`
  font-size: 14px;
  padding-bottom: 0;
`;

const PopupText = styled.span`
  display: inline-block;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
`;

const PopupLink = styled.a`
  border-right: 2px solid;
  color: #000000;
  display: inline-block;
  margin-right: 16px;
  padding-right: 16px;
  position: relative;
  text-decoration: none;

  &:last-of-type {
    border: none;
    margin-right: 0;
  }
`;

const PopupFooter = styled(PopupSection)`
  @media (max-width: 400px) {
    a {
      font-size: 15px;
    }
  }

  display: inline-flex;
  justify-content: space-between;
  padding-top: 0;

  html[lang='sv-SE'] & {
    a {
      font-size: 13px;
    }
  }

  div {
    padding-top: 0;
  }
`;

const CtaChevron = styled.span`
  display: inline-block;
  margin-left: 6px;
  position: relative;

  svg {
    height: 12px;
    transform: rotate(-90deg);
    width: 14px;
  }
`;

const MapPopup = connectLocation(
  ({ t, className, location, locale: { code } }) => {
    const fullAddress = formatAddress(location, code);
    const { logo, phone } = location;
    const phoneLink = phoneHref(phone);
    const phoneLabel = formatPhone(phone, code);

    const { resultComponentOptions = {} } = useContext(LocatorContext) || {};
    const { components } = resultComponentOptions;
    const Email = () => {
      if (components && components.Email) {
        return (
          <CustomizedComponent
            showComponent
            getProps={components.Email.getProps}
            Component={EmailBlockPart}
            resource={location}
            t={t}
          >
            Email
          </CustomizedComponent>
        );
      }
      if (location.email) {
        return (
          <PopupLink
            data-track="email"
            data-track-buy-type={location.typeDisplayName}
            data-track-supplier-name={location.name}
            href={`mailto:${location.email}`}
            rel="noopener"
            target="_blank"
          >
            Email
          </PopupLink>
        );
      }

      return null;
    };

    return (
      <PopupContainer
        className={className}
        data-am-region="Map View - Location Details"
        location={location}
      >
        <PopupHeader>
          {logo && <LocationImage imgUrl={logo} />}
          <Name>{location.name}</Name>
        </PopupHeader>
        <PopupBody>
          <PopupText>{fullAddress}</PopupText>
          <PopupText>
            {location.phone && (
              <PopupLink
                data-track="phone"
                data-track-buy-type={location.typeDisplayName}
                data-track-supplier-name={location.name}
                href={phoneLink}
              >
                {phoneLabel}
              </PopupLink>
            )}
            <Email />
            {location.website_address && (
              <PopupLink
                data-track="website"
                data-track-buy-type={location.typeDisplayName}
                data-track-supplier-name={location.name}
                href={location.website_address}
                rel="noopener"
                target="_blank"
              >
                {t('generic_locator.website')}
              </PopupLink>
            )}
          </PopupText>

          {location.product_stock_display_text && (
            <PopupText
              dangerouslySetInnerHTML={{
                __html: location.product_stock_display_text,
              }}
            />
          )}
        </PopupBody>
        <PopupFooter>
          <Button
            color="white"
            href={`http://maps.google.com/?q=${fullAddress}`}
            data-track="view-directions"
            data-track-buy-type={location.typeDisplayName}
            data-track-supplier-name={location.name}
            target="_blank"
          >
            {t('generic_locator.get_directions')}{' '}
            <CtaChevron>
              <IconChevron />
            </CtaChevron>
          </Button>

          {components && components.CTA && (
            <CustomizedComponent
              showComponent
              getProps={components.CTA.getProps}
              Component={CallNowCTA}
              resource={location}
              t={t}
            />
          )}
          {!components && phone && (
            <Button
              href={phoneLink}
              data-track="phone"
              data-track-buy-type={location.typeDisplayName}
              data-track-supplier-name={location.name}
            >
              {t('generic_locator.call_now')}
            </Button>
          )}
        </PopupFooter>
      </PopupContainer>
    );
  }
);

export default MapPopup;

import styled from '@emotion/styled';

// Components
import Form from '../../ComponentLibrary/forms/oc-form';
import Fieldset from '../../ComponentLibrary/input-elements/oc-fieldset';
import TextInput from '../../ComponentLibrary/input-elements/oc-text-input';
import RadioButton from '../../ComponentLibrary/input-elements/oc-radio-button';
import FormButton from '../../ComponentLibrary/input-elements/oc-form-button';
import IconInfo from '../../ComponentLibrary/icons/icon-info';

const InputContainer = styled.div`
	display: flex;
	margin: 0!important;

	span {
		margin: 40px 8px 0;
	}
`;

const Legend = styled.legend`
  color: black;
  font-weight: 700;
  margin-bottom: 8px;
  width: 100%;
`;

const ErrorContainer = styled.span`
	margin: 0!important;

	span {
 		margin: 4px 0 0!important;
	}
`;

const ColoredLine = styled.hr`
  border-top: solid black;
  margin-bottom: 20px;
`

const Icon = styled(IconInfo)`
  margin-right: 5px;
`;

const CalculatorForm = ({formSubmit, inputChange, post}) => {
	
	return (
		<>
			<h6>Dimensions</h6>
      <ColoredLine/>

      <Form onSubmit={formSubmit}>
        <Fieldset legend="Room Shape" onChange={inputChange}>
			    <RadioButton name="shape" id="triangle" value="triangle" checked={post.shape == 'triangle'} >Triangle (attic)</RadioButton>
			    <RadioButton name="shape" id="rectangle" value="rectangle" checked={post.shape == 'rectangle'} >Square/rectangle (room)</RadioButton>
			  </Fieldset>

        <Fieldset multipleFields={true} legend="Enter the dimensions of your attic:" >
          <InputContainer>
            <div>
              <Legend>Length (ft)</Legend>
              <ErrorContainer>
                <TextInput name="length" placeholder="Length" touched={true} error={post.lengthError} onChange={inputChange} value={post.length} />
              </ErrorContainer>
            </div>
            <span>X</span>
            <div>
              <Legend>Width (ft)</Legend>
              <ErrorContainer>
                <TextInput name="width" placeholder="Width" touched={true} error={post.widthError} onChange={inputChange} value={post.width} />
              </ErrorContainer>
            </div>
          </InputContainer>
        </Fieldset>

        <ErrorContainer>
          <Fieldset legend="Height (ft)">
            <TextInput name="height" touched={true} error={post.heightError} onChange={inputChange} value={post.height} />
          </Fieldset>
        </ErrorContainer>

        <Fieldset legend="Air Changes per Hour (ACH)">
			    <TextInput name="airChangesPerHour" touched={true} error={post.ACHError} onChange={inputChange} value={post.airChangesPerHour} />
          <p>
            <Icon/>
            Minimum of 10 ACH required; more is better
          </p>
			  </Fieldset>

        <Fieldset columns={true}>
			  	<FormButton buttonType="submit" data-track="form-submit" data-track-form-name="Spray Foam Ventilation" data-track-form-type="Calculator" data-track-form-details="[airChangesPerHour]">
            View Results
			  	</FormButton>
			  </Fieldset>
      </Form>
		</>
	)
};

export default CalculatorForm;

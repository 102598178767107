import { jsx, css } from '@emotion/react';
import { define, ui, when } from '@owenscorning/pcb.alpha';

import Renderer from './../../../../OC/PageBuilder/BlockLinkSet.jsx';

export default define`Block Link Set`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/BlockLinkSet`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value }) => <Renderer { ...value } />,
  edit: {
    copy: ui`Content/Basic`,
    containerSize: ui`Form`.of({
      selection: ui`Choices`.of({
        'xl': 'XL',
        'l': 'Large',
        'm': 'Medium',
      })({
        label: 'Container Size',
        default: 'xl',
        mode: ui`Choices/Mode/Dropdown`
      })
    }),
    alignment: ui`Choices`.of(['Left', 'Center'])({
      label: 'Alignment',
      default: 'Left'
    }),
    blocks: ui`List`.of({
      aspectRatio: ui`AspectRatio`({ default: { selection: '1' } }),
      row: ui`List`.of({
        heading: ui`Text`({ label: 'Heading' }),
        url: ui`Text`({ label: 'URL' }),
        image: ui`Image`({ crop: true }),
        imageSizeInfo: ui`ImageSizeInfo`(
          {
            moduleName: 'Block Link'
          },
        ),
      })({
        min: 2, max: 4,
        singular: 'Block Link',
        title: 'heading'
      })
    })({
      min: 1, max: 2,
      hideable: false,
      singular: 'Row',
      label: 'Block Links'
    }),
    showCta: ui`Switch`({ label: 'Use call-to-action', default: true }),
    cta: ui`CTA`({ visible: when`../showCta`.is.equal.to(true) }),
  },
});

// ported from mdms Form#transform_schema_for_view
// expectation is that we probably won't need both, at some point
// main thing to worry about keeping in sync is injected fields (recaptcha/preserve_responses) and the lookupOptions
// the rest is all recursively matching the pattern and calling/deduping api calls

import _ from 'lodash';
import qs from 'qs';
import api from '../../../../mdms_api';
// TODO: SSR support
import Cookies from 'js-cookie';
import { limitSplit } from '../../../../data'

const SCHEMA_TRANSFORM_REGEX = /{{\s*(?<key>\w+):?\s*(?<lookup>[\w\-\.]+)?\s*}}/g

const apiGetPromiseCache = {}
const apiGetDeduper = async (url, query) => {
  const finalUrl = `${url}?${qs.stringify(query)}`
  if (apiGetPromiseCache[finalUrl]) {
    return apiGetPromiseCache[finalUrl];
  }
  return apiGetPromiseCache[finalUrl] = api.get(finalUrl).then(response => response.json())
}

const lookupOptions = {
  ip2location: async (lookup) => _.get(await apiGetDeduper('/api/v1/ip2location', { useip: true }), lookup),
  param: (lookup) => _.get(qs.parse(window.search), lookup),
  cookie: (lookup) => _.get(Cookies.get(), lookup),
  now: (lookup) => new Date(),
  lang: (lookup) => {
    // TODO: let's skip for now, this is used to have single form definition on mdms and output localized fields
    // because PB i18n works by having multiple copies of the content, we won't need this
  },
  contractor: (lookup) => {
    // TODO: let's skip for now, this is only used on FAC email me
    // we would need a public appropriate API endpoint to retrieve some contractor fields by membership_number
    // the only thing we use is to lookup the store_name for a data-track attributes
  }
}

const lookupResponses = {}
const lookupCache = async ({ key, lookup }) => {
  lookupResponses[key] = lookupResponses[key] || {}
  if (lookupResponses[key][lookup]) {
    return lookupResponses[key][lookup];
  }
  return lookupResponses[key][lookup] = await lookupOptions[key]?.(lookup)
}

const mapValuesAsync = async (object, asyncFn) => {
  return Object.fromEntries(
    await Promise.all(
      Object.entries(object).map(async ([key, value]) => [
        key,
        await asyncFn(value, key, object)
      ])
    )
  );
}

async function mapArrayAsync(array, asyncFn) {
  return await Promise.all(
    array.map(async (value) => await asyncFn(value))
  );
}

const replaceAllAsync = async (string, search, replacement) => {
  const matches = [...(string.matchAll(search))].filter(m => m[0]).map(m => ({ value: m[0], response: replacement(m.groups) }))

  const responses = await Promise.all(_.map(matches, 'response'))
  return string.replaceAll(search, (match) => {
    return responses[matches.findIndex(v => v.value === match)]
  })
}

const recursiveMapper = async o => {
  if (typeof(o) === 'string') {
    return await replaceAllAsync(o, SCHEMA_TRANSFORM_REGEX, lookupCache)
  } else if (Array.isArray(o)) {
    return await mapArrayAsync(o, recursiveMapper)
  } else if (typeof(o) === 'object') {
    return await mapValuesAsync(o, recursiveMapper)
  } else {
    return o
  }
}

const repeatMapValuesAsyncUntilStable = async (value, mapper) => {
  let transformedSchema = value
  let oldTransformedSchema;
  do {
    oldTransformedSchema = transformedSchema
    transformedSchema = await mapValuesAsync(oldTransformedSchema, mapper)
  } while (JSON.stringify(transformedSchema) !== JSON.stringify(oldTransformedSchema))
  return transformedSchema;
}

const schemaFieldDefinition = (startingSchemaNode, fieldPath) => {
  const [segment, rest] = limitSplit(fieldPath, '.', 2)
  const schemaNode = startingSchemaNode['properties'][segment]
  if (!rest) {
    return schemaNode;
  }
  return schemaFieldDefinition(schemaNode, rest)
}

const COOKIE_NAME = 'preserved_form_responses'
const preservedFormResponses = () => {
  const value = Cookies.get(COOKIE_NAME)
  if (value) {
    try {
      return JSON.parse(value)
    } catch {
      return {}
    }
  } else {
    return {}
  }
}

export default class SchemaTransformer {
  constructor(t, schema, uiSchema) {
    this.t = t;
    this.schema = schema || {}
    this.uiSchema = uiSchema || {}

    this.transformedSchema = null
    this.transformedUiSchema = null
  }

  transformed = async () => {
    if (this.transformedSchema && this.transformedUiSchema) {
      return { schema: this.transformedSchema, uiSchema: this.transformedUiSchema }
    }

    let schema = this.schema
    let uiSchema = this.uiSchema

    if (schema.properties) {
      let prepopulate_default = schema.meta?.prepopulate_default
      if (Array.isArray(schema.meta?.prepopulate) && schema.meta?.prepopulate.length > 0) {
        schema.properties.preserve_responses = {
          type: 'boolean',
          default: prepopulate_default !== undefined ? prepopulate_default: true,
          label: this.t('owenscorning.components.form.preserve_responses')
        }
        uiSchema['preserve_responses'] = {
          'ui:widget': 'PreserveResponsesCheckbox',
          "ui:options": {
            "hideLabel": true
          }
        }

        // set field defaults for the whitelisted prepopulation params only
        schema.meta?.prepopulate.forEach(key => {
          const preserved = preservedFormResponses()
          const schemaNode = schemaFieldDefinition(schema, key)
          if (schemaNode) {
            schemaNode['default'] = _.get(preserved, key)
          }
        })
      }

      if (schema.meta?.includeRecaptcha !== false) {
        // we inject a "recaptcha" as string param at the end
        schema.properties.recaptcha = {
          type: 'string',
          title: ''
        }

        schema['required'] = schema['required'] || []
        if (schema['required'].indexOf('recaptcha') < 0) {
          schema['required'].push('recaptcha')
        }
        uiSchema['recaptcha'] = {
          'ui:title': ' ',
          'ui:widget': 'Recaptcha',
          'ui:options': {
            recaptchaSiteKey: FORM_RECAPTCHA_KEY
          }
        }
        if (uiSchema['ui:order'] && uiSchema['ui:order'].indexOf('recaptcha') < 0) {
          uiSchema['ui:order'].push('recaptcha')
        }
      }
    }

    this.transformedSchema = schema = await repeatMapValuesAsyncUntilStable(schema, recursiveMapper)
    this.transformedUiSchema = uiSchema = await repeatMapValuesAsyncUntilStable(uiSchema, recursiveMapper)

    return { schema, uiSchema }
  }
}

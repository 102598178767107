import { define } from '@owenscorning/pcb.alpha';
import styled from '@emotion/styled';
import _ from 'lodash';
import Data from '../../Data';

const Label = styled.label`
  color: #000;
  display: block;
  padding-bottom: ${ ({ sublabel }) => sublabel ? 4 : 8 }px;
  ${ ({ title }) => title && css` font-size: 24px; padding: 28px 0 16px; ` }
  font-weight: bold;
`;

export default define`Data Choices`('0.0.1')({
  view: <div></div>,
  edit: ({ contents, ...props }) => {
    return <>
      <Label>Select Data Set</Label>
      <UI.Choices { ...props } mode="dropdown" contents={ _.mapValues(Data.for(contents), 'name') } />
      <div style={{ height: '32px' }} />
      <UI.Tip>Select the Data Set of Items you would like to pull from.</UI.Tip>
    </>
  }
});

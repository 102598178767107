import { jsx } from '@emotion/react';
import { Component } from 'react';
import { Provider } from 'react-redux';

import { store } from "../../Store";
import { connectLocation } from "../../location/LocationConnectors";
import { gwpCalculatorData } from './GwpCalculatorData';
import { GwpCalculator } from './GwpCalculator';
import { SummaryBox } from './SummaryBox';

const projectTypes = [
  'hospital',
  'small_hotel',
  'large_hotel',
  'apartment_building',
  'small_office',
  'medium_office',
  'large_office',
  'elementary_school',
  'high_school',
  'ten_boards',
]

class GwpCalculatorHub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectType: projectTypes[0],
    }
    this.setProjectType = this.setProjectType.bind(this);
    this.boardFeetUsedInProject = this.boardFeetUsedInProject.bind(this);
    this.foamularNgxData = this.foamularNgxData.bind(this);
    this.foamularData = this.foamularData.bind(this);
    this.duPontStyrofoamData = this.duPontStyrofoamData.bind(this);

    this.gwpCalculatorData = gwpCalculatorData[props?.locale?.code || 'en-US'];
  }

  boardFeetUsedInProject(projectType) {
    return this.gwpCalculatorData[projectType].boardFeetUsedInProject;
  }

  foamularNgxData(projectType) {
    return {
      gwpImpactOverBoardFeetProduct: this.gwpCalculatorData.gwpImpactOverBoardFeetProduct.foamularNgx,
      totalEmissions:
        this.gwpCalculatorData[projectType].boardFeetUsedInProject * this.gwpCalculatorData.gwpImpactOverBoardFeetProduct.foamularNgx,
    }
  }

  foamularData(projectType) {
    return {
      gwpImpactOverBoardFeetProduct: this.gwpCalculatorData.gwpImpactOverBoardFeetProduct.foamular,
      totalEmissions:
        this.gwpCalculatorData[projectType].boardFeetUsedInProject * this.gwpCalculatorData.gwpImpactOverBoardFeetProduct.foamular,
      additionalEmissions: this.gwpCalculatorData[projectType].boardFeetUsedInProject *
        (this.gwpCalculatorData.gwpImpactOverBoardFeetProduct.foamular - this.gwpCalculatorData.gwpImpactOverBoardFeetProduct.foamularNgx),
    }
  }

  duPontStyrofoamData(projectType) {
    return {
      gwpImpactOverBoardFeetProduct: this.gwpCalculatorData.gwpImpactOverBoardFeetProduct.duPontStyrofoam,
      totalEmissions:
        this.gwpCalculatorData[projectType].boardFeetUsedInProject * this.gwpCalculatorData.gwpImpactOverBoardFeetProduct.duPontStyrofoam,
      additionalEmissions: this.gwpCalculatorData[projectType].boardFeetUsedInProject *
        (this.gwpCalculatorData.gwpImpactOverBoardFeetProduct.duPontStyrofoam - this.gwpCalculatorData.gwpImpactOverBoardFeetProduct.foamularNgx),
    }
  }

  summaryData(projectType) {
    return [
      {
        value: this.gwpCalculatorData[projectType].reductionTypeOne,
        translation: 'gwp_reduction_type_one',
      },
      {
        value: this.gwpCalculatorData[projectType].reductionTypeTwo,
        translation: 'gwp_reduction_type_two',
      },
      {
        value: this.gwpCalculatorData[projectType].reductionTypeThree,
        translation: 'gwp_reduction_type_three',
      },
      {
        value: this.gwpCalculatorData[projectType].reductionTypeFour,
        translation: 'gwp_reduction_type_four',
      },
    ]
  }

  setProjectType(newProjectType) {
    this.setState({ projectType: newProjectType })
  }

  render() {
    const { projectType } = this.state;

    return (
      <>
        <GwpCalculator
          projectTypes={projectTypes}
          boardFeetUsedInProject={this.boardFeetUsedInProject(projectType)}
          foamularNgxData={this.foamularNgxData(projectType)}
          foamularData={this.foamularData(projectType)}
          duPontStyrofoamData={this.duPontStyrofoamData(projectType)}
          projectType={projectType}
          setProjectType={this.setProjectType}
          {...this.props}
        />
        <SummaryBox
          projectData={this.summaryData(projectType)}
          projectEmissionSaving={this.duPontStyrofoamData(projectType).additionalEmissions}
          projectType={projectType}
          {...this.props}
        />
      </>
    );
  }
}

const LocalizedGwpCalculatorHub = connectLocation(GwpCalculatorHub);

const GwpCalculatorContainer = (props) => (
  <Provider store={store}>
    <LocalizedGwpCalculatorHub {...props} />
  </Provider>
)

export default GwpCalculatorContainer

import ParocCo2Calculator from '../../composites/Paroc/ParocCo2Calculator';
import RichText from './RichText';
import { BasicContent } from './BasicContent';


const CMSParocCo2Calculator = ({...content}) => {
  return (
    <>
      <BasicContent { ...content.copy } />
      <ParocCo2Calculator />
      <RichText content={content.footnotes} />
    </>
  )
};

export default CMSParocCo2Calculator;

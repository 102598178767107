// started from https://github.com/rjsf-team/react-jsonschema-form/blob/ef8b7fce19c5271f75a849fa951ad62595314b00/packages/core/src/components/widgets/SelectWidget.js
import PropTypes from 'prop-types';
import SelectDropdown from '../ComponentLibrary/input-elements/oc-select-dropdown';

import { asNumber, guessType } from '@rjsf/utils'

const nums = new Set(["number", "integer"]);

/**
 * This is a silly limitation in the DOM where option change event values are
 * always retrieved as strings.
 */
function processValue(schema, value) {
  // "enum" is a reserved word, so only "type" and "items" can be destructured
  const { type, items } = schema;
  if (value === "") {
    return undefined;
  } else if (type === "array" && items && nums.has(items.type)) {
    return value.map(asNumber);
  } else if (type === "boolean") {
    return value === "true";
  } else if (type === "number") {
    return asNumber(value);
  }

  // If type is undefined, but an enum is present, try and infer the type from
  // the enum values
  if (schema.enum) {
    if (schema.enum.every(x => guessType(x) === "number")) {
      return asNumber(value);
    } else if (schema.enum.every(x => guessType(x) === "boolean")) {
      return value === "true";
    }
  }

  return value;
}

function getValue(event, multiple) {
  if (multiple) {
    return [].slice
      .call(event.target.options)
      .filter(o => o.selected)
      .map(o => o.value);
  } else {
    return event.target.value;
  }
}

function SelectWidget(props) {
  const {
    schema,
    id,
    options,
    value,
    required,
    disabled,
    readonly,
    multiple,
    autofocus,
    onChange,
    onBlur,
    onFocus,
    placeholder,
  } = props;
  const { enumOptions, enumDisabled } = options;
  const emptyValue = multiple ? [] : "";
  const mode = schema?.mode || null;

  // Complaints logged by QA (Karishma) about the default dropdown Component using React Select
  // at first it seemed like the arrows don’t do anything, but actually its because there’s nothing to indicate that the menu is scrolling or that any value is selected.
  // Additionally, not all of the values were selectable. Some of them cannot be selected using the keyboard at all.

  // I introduced a new mode (add "mode": "select", to the field) to fall back to the default dropdown select for now, later we can find a better solution

  return mode === null ?
      (
        <SelectDropdown
          name={id}
          multiple={multiple}
          value={ typeof value === "undefined" ? emptyValue : value }
          onChange={event => {
            const newValue = getValue(event, multiple);
            onChange(processValue(schema, newValue));
          }}
          options={
            [
              ...(!multiple && schema.default === undefined ? [{
                text: placeholder,
                value: emptyValue
              }] : []),
              ...enumOptions.map(({ value, label }, i) => {
                const disabled = enumDisabled && enumDisabled.indexOf(value) != -1;
                return ({
                  text: label,
                  disabled,
                  value
                });
              })
            ]
          }
        />
      ) :
      (
        <select
          id={id}
          multiple={multiple}
          className="form-control"
          value={typeof value === "undefined" ? emptyValue : value}
          required={required}
          disabled={disabled || readonly}
          autoFocus={autofocus}
          onBlur={
            onBlur &&
            (event => {
              const newValue = getValue(event, multiple);
              onBlur(id, processValue(schema, newValue));
            })
          }
          onFocus={
            onFocus &&
            (event => {
              const newValue = getValue(event, multiple);
              onFocus(id, processValue(schema, newValue));
            })
          }
          onChange={event => {
            const newValue = getValue(event, multiple);
            onChange(processValue(schema, newValue));
          }}>
          {!multiple && schema.default === undefined && (
            <option value="">{placeholder}</option>
          )}
          {enumOptions.map(({ value, label }, i) => {
            const disabled = enumDisabled && enumDisabled.indexOf(value) != -1;
            return (
              <option key={i} value={value} disabled={disabled}>
                {label}
              </option>
            );
          })}
        </select>
      )
}

SelectWidget.defaultProps = {
  autofocus: false,
};

if (process.env.NODE_ENV !== "production") {
  SelectWidget.propTypes = {
    schema: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    options: PropTypes.shape({
      enumOptions: PropTypes.array,
    }).isRequired,
    value: PropTypes.any,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    multiple: PropTypes.bool,
    autofocus: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  };
}

export default SelectWidget;

import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';

import TextInput from '../../../ComponentLibrary/input-elements/oc-text-input';

const SpecificationWrapper = styled.div`
  width: 100%;
`;

function SpecificationInput({
  categoryId,
  updateSpecifications,
  specification,
}) {
  const handleInput = event => {
    const { value } = event.target;
    updateSpecifications(categoryId, value);
  };

  const name = `${categoryId}_specification`;

  return (
    <SpecificationWrapper>
      <TextInput
        name={name}
        label="Specification:"
        onChange={handleInput}
        value={specification}
      />
    </SpecificationWrapper>
  );
}

SpecificationInput.propTypes = {
  categoryId: PropTypes.string.isRequired,
  updateSpecifications: PropTypes.func.isRequired,
  specification: PropTypes.string,
};

SpecificationInput.defaultProps = {
  specification: '',
};

export default SpecificationInput;

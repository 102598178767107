import styled from '@emotion/styled';
import { jsx, css } from '@emotion/react';
import IconBase from './icon-base';

const IconMap = ({fill, width, height, hoverColor, className}) => (
	<IconBase className={className} viewBox="0 0 10.8 9.62" width={width} height={height} fill={fill} hoverColor={hoverColor} >
		<path d="M5.7.2A2.37,2.37,0,0,0,3.34,2.57c0,1.06,1.54,3,2.13,3.68a.3.3,0,0,0,.46,0c.59-.7,2.13-2.62,2.13-3.68A2.37,2.37,0,0,0,5.7.2Zm0,3.16a.79.79,0,0,1,0-1.58.79.79,0,1,1,0,1.58Zm-5,.9a.6.6,0,0,0-.28.22.67.67,0,0,0-.1.34v4.7a.3.3,0,0,0,.41.28L3.3,8.62V4.24a5.54,5.54,0,0,1-.4-.87ZM5.7,7A.9.9,0,0,1,5,6.64C4.65,6.2,4.25,5.71,3.9,5.2V8.62l3.6,1.2V5.2c-.35.51-.75,1-1.12,1.44A.9.9,0,0,1,5.7,7Zm5-3.73L8.1,4.41V9.82l2.62-1.05A.6.6,0,0,0,11,8.55a.67.67,0,0,0,.1-.34V3.51A.3.3,0,0,0,10.69,3.23Z" transform="translate(-0.3 -0.2)" />
	</IconBase>
);

export default IconMap;
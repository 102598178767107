import PropTypes from 'prop-types';

import { ActionLink } from '../result-block-styled-components';

const Phone = ({ label, href, buyType, name }) => (
  <ActionLink
    href={href}
    tabIndex={0}
    data-track="phone"
    data-track-buy-type={buyType}
    data-track-supplier-name={name}
  >
    {label}
  </ActionLink>
);

Phone.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  buyType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Phone;

import RichText from './RichText';
import Text from './utilities/Text';
import News from '../oc-news';

const CMSNews = ({ news, newsHeading, customCta }) => {
  news = news?.map((item, index) => {
    if (item) item.text = <RichText content={item.text} />;
    return item;
  });

  return (
    <News
      news={news}
      newsHeading={newsHeading}
      cta={customCta?.cta}
      ctaUrl={customCta?.ctaUrl || 'https://newsroom.owenscorning.com'}
      ctaText={customCta?.ctaText || 'More News'}
    />
  );
};

export default Text.Parse(CMSNews, 'news.prehead', 'news.heading');

import { List, Map } from 'immutable';

import {
  EditorState,
  ContentBlock,
  BlockMapBuilder,
  Modifier,
  RichUtils,
  Entity,
  genKey
} from 'draft-js';

const Styles = {
  active: (draft, style) => ( draft.getCurrentInlineStyle().has(style) ),
  toggle: (draft, style) => ( RichUtils.toggleInlineStyle(draft, style) )
};

const Blocks = {
  active: (draft, block) => ( RichUtils.getCurrentBlockType(draft) === block ),
  toggle: (draft, block) => ( RichUtils.toggleBlockType(draft, block) ),
  insert: (draft, data) => {
    const content = draft.getCurrentContent();
    const selection = draft.getSelection();

    const removalState = Modifier.removeRange(content, selection, 'backward');
    const splitState = Modifier.splitBlock(removalState, removalState.getSelectionAfter());
    const target = splitState.getSelectionAfter();

    const block = Modifier.replaceWithFragment(
      Modifier.setBlockType(splitState, target, 'atomic'),
      target, BlockMapBuilder.createFromArray([
        new ContentBlock({ key: genKey(), type: 'atomic', text: '', characterList: List(), data: Map(data) }),
        new ContentBlock({ key: genKey(), type: 'unstyled', text: '', characterList: List() })
      ])
    );

    return EditorState.push(
      draft,
      block.merge({
        selectionBefore: selection,
        selectionAfter: block.getSelectionAfter().set('hasFocus', true)
      }),
      'insert-fragment'
    );
  }
};

const Entities = {
  all: (block, callback, state, type) => {
    block.findEntityRanges(
      (character) => {
        const entity = character.getEntity();
        return entity !== null && state.getEntity(entity).getType() === type;
      },
      callback
    );
  },
  selected: (draft, type=false) => {
    let content = draft.getCurrentContent();

    let selection = draft.getSelection();
    let selectedBlockKey = selection.getAnchorKey();

    let selectedEntity = null;
    let entities = [];

    content.getBlockForKey(selectedBlockKey).findEntityRanges(
      (character) => {
        let entityKey = character.getEntity();

        if (entityKey !== null) {
          let entity = content.getEntity(entityKey);

          if (!type || entity.getType() === type) {
            selectedEntity = { entityKey: entityKey, blockKey: selectedBlockKey, entity: entity };
            return true;
          }

          return false;
        }
      },
      (start, end) => {
        if ((selection.getStartOffset() <= end && selection.getEndOffset() >= start) || (selection.getEndOffset() >= start && selection.getStartOffset() <= end)) {
          entities.push({ ...selectedEntity, start: start, end: end });
        }
      }
    );

    return entities;
  },
  active: (draft, type=false) => ( Entities.selected(draft, type).length > 0 ),
  toggle: (draft, type=null, mutability=null, data=null) => {
    if (Entities.active(draft, type)) {
      // need to make this smarter, currently just nullifies all entities in selection range
      return RichUtils.toggleLink(draft, draft.getSelection(), null);
    } else {
      return RichUtils.toggleLink(draft, draft.getSelection(), Entity.create(type, mutability, data));
    }
  }
};

const DraftUtils = {
  Styles: Styles,
  Blocks: Blocks,
  Entities: Entities
};

export { DraftUtils };

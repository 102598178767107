import styled from '@emotion/styled';

import Devices from './PageBuilder/internals/Devices';
import Gutter from './PageBuilder/internals/Gutter';
import { PictureOverlay } from './oc-picture-overlay';

// 'xxl' is just a quick workaround to maintain a full-bleed size but use margin: 0 auto
const sizes = {
  'full-bleed': [405, 798, 1340],
  xxl: [405, 798, 1340],
  xl: [345, 738, 1170],
  l: [345, 738, 970],
  m: [225, 482, 770],
  s: [165, 354, 370],
  thm: [105, 226, 170],
};

const MyFigure = styled.figure`
  margin: 0 auto;
  max-width: 100%;
  width: ${props => sizes[props.size][0] + 'px'};

  @media (max-width: 767px) {
    margin: ${props =>
      props.size === 'full-bleed'
        ? `0 -${props.gutter[Devices.Mobile]}px`
        : '0 auto'};
    width: ${props => props.size === 'full-bleed' && 'calc(100% + 30px)'};
  }

  @media (min-width: 768px) {
    margin: ${props =>
      props.size === 'full-bleed'
        ? `0 -${props.gutter[Devices.Tablet]}px`
        : '0 auto'};
    width: ${props => sizes[props.size][1] + 'px'};
  }

  @media (min-width: 1200px) {
    margin: ${props =>
      props.size === 'full-bleed'
        ? `0 -${props.gutter[Devices.Desktop]}px`
        : '0 auto'};
    margin: ${props =>
      ['xl', 'l'].includes(props.size) &&
      sizes[props.size][2] > props.gutter[Devices.Desktop]
        ? `0 -${
            (sizes[props.size][2] / 2 - props.gutter[Devices.Desktop]) / 2
          }px`
        : '0 auto'};
    width: ${props => sizes[props.size][2] + 'px'};
  }

  figcaption {
    @media (max-width: 767px) {
      margin: ${props =>
        props.size === 'full-bleed' &&
        `8px ${props.gutter[Devices.Mobile]}px 0`};
      margin: ${props => props.size === 's' && '8px -30px 0'};
      margin: ${props => props.size === 'thm' && '8px -60px 0'};
    }

    @media (min-width: 768px) {
      margin: ${props =>
        props.size === 'full-bleed' &&
        `8px ${props.gutter[Devices.Tablet]}px 0`};
      margin: ${props => props.size === 'thm' && '8px -64px 0'};
    }

    @media (min-width: 1200px) {
      margin: ${props =>
        props.size === 'full-bleed' &&
        `8px ${props.gutter[Devices.Desktop]}px 0`};
      margin: ${props => props.size === 'thm' && '8px -100px 0'};
    }
  }

  img {
    max-width: 100%;
  }

  figcaption {
    font-size: 16px;
  }
`;

const FigurePOC = ({
  size,
  baseLayer,
  overlays,
  alt,
  caption,
  className,
  contentType,
}) => {
  return (
    <MyFigure size={size} className={className} gutter={Gutter(contentType)}>
      <PictureOverlay baseLayer={baseLayer} overlays={overlays} alt={alt} />
      <figcaption>{caption}</figcaption>
    </MyFigure>
  );
};

export default FigurePOC;
//h_250,w_300/q_50/fl_layer_apply,g_center,x_-15,y_-25/l


import styled from "@emotion/styled";

// Icons
import IconCompressiveStrength from "./icons/icon-compressive-strength";
import IconCoverageArea from "./icons/icon-coverage-area";
import IconDepth from "./icons/icon-depth";
import IconThermalResistance from "./icons/icon-thermal-resistance";
import IconEdgeTypes from "./icons/icon-edge-types";

// Styled Components
const FeatureContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;
  margin-bottom: 24px;
  position: relative;
`;

const Feature = styled.div`
  align-items: center;
  display: inline-flex;
  justify-content: flex-start;
  position: relative;
  width: 100%;

  ${props => !props.fullWidth && `
    @media screen and (min-width: 768px) {
      width: calc(50% - 8px);
    }
  `}

  strong {
    display: inline-block;
    margin-right: 8px;
    position: relative;
  }
`;

const IconContainer = styled.div`
  display: inline-block;
  height: 24px;
  margin-right: 8px;
  position: relative;
  width: 24px;

  svg {
    height: 100%;
    width: 100%;
  }
`;

const FeatureList = ({ features, fullWidth = false }) => {
  const featureMapping = {
    compressiveStrength: { icon: IconCompressiveStrength, label: "Compressive Strength", sortOrder: 4, type: "range", unit: "lb/in²" },
    coverageArea: { icon: IconCoverageArea, label: "Coverage Area", sortOrder: 3, type: "range", unit: "ft²" },
    depth: { icon: IconDepth, label: "Depth / Thickness", sortOrder: 1, type: "range", unit: "in" },
    edgeTypes: { icon: IconEdgeTypes, label: "Edge Types Available", sortOrder: 5, type: "array", unit: "" },
    thermalResistance: { icon: IconThermalResistance, label: "Thermal Resistance", sortOrder: 2, type: "range", unit: "" }
  };

  const sortFeatures = (features) => {
    let entries = Object.entries(features);
    return entries.sort(([keyA], [keyB]) => (featureMapping[keyA]?.sortOrder - featureMapping[keyB]?.sortOrder));
  };

  const roundNumber = (number, precision = 2) => {
    var power = Math.pow(10, precision);
    var epsilon = (number * power) * (1 + Number.EPSILON);
    return Math.round(epsilon) / power;
  };

  const displayRange = (propertyStats) => {
    let min = roundNumber(propertyStats.min);
    let max = roundNumber(propertyStats.max);

    if (min === max) return max;
    return `${min} - ${max}`;
  };

  const displayArray = (array) => {
    return array.join(", ");
  };

  const displayValue = (value) => {
    return value;
  };

  const valueFnMapping = {
    array: displayArray,
    range: displayRange,
    value: displayValue
  };

  return (
    <FeatureContainer>
      {sortFeatures(features).map(([key, value]) => {
        const feature = featureMapping[key];
        if (!feature) return false;

        const Icon = feature.icon;
        const type = feature.type || "range";
        const valueFn = valueFnMapping[type];
        return (
          <Feature fullWidth={fullWidth}>
            <IconContainer><Icon /></IconContainer>
            <strong>{feature.label}:</strong>
            <span>{`${valueFn(value)} ${feature.unit}`.trim()}</span>
          </Feature>
        )
      })}
    </FeatureContainer>
  );
};

export default FeatureList;

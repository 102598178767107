import { Component, useState, createRef } from 'react';
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';

import mime from 'mime-types';
import MD5 from 'spark-md5';

import _ from 'lodash';

import getFileExtension from '../../helpers/get_file_extension';
import getFileBasename from '../../helpers/get_file_basename';
import getFileSize from '../../helpers/get_file_size';

import { apiGet, apiUpload } from '../../PageBuilder/helpers/api';
import { toast } from '../../PageBuilder/helpers/toast';

import Theme from '../../../themes';

// used to use default export from npm package https://github.com/spatie/font-awesome-filetypes/tree/master
// but we want to use mime type instead of extension and that updated to FA 5 before they added getClassNameForMimeType
const getClassNameForMimeType = (mimeType) => {
  const icons = {
    image: 'fa-file-image-o',
    pdf: 'fa-file-pdf-o',
    word: 'fa-file-word-o',
    powerpoint: 'fa-file-powerpoint-o',
    excel: 'fa-file-excel-o',
    audio: 'fa-file-audio-o',
    video: 'fa-file-video-o',
    zip: 'fa-file-zip-o',
    code: 'fa-file-code-o',
    text: 'fa-file-text-o',
    file: 'fa-file-o'
  }
  const mimeTypes = {
    'image/gif': icons.image,
    'image/jpeg': icons.image,
    'image/png': icons.image,

    'application/pdf': icons.pdf,

    'application/msword': icons.word,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': icons.word,

    'application/mspowerpoint': icons.powerpoint,
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': icons.powerpoint,

    'application/msexcel': icons.excel,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': icons.excel,

    'text/csv': icons.csv,

    'audio/aac': icons.audio,
    'audio/wav': icons.audio,
    'audio/mpeg': icons.audio,
    'audio/mp4': icons.audio,
    'audio/ogg': icons.audio,

    'video/x-msvideo': icons.video,
    'video/mpeg': icons.video,
    'video/mp4': icons.video,
    'video/ogg': icons.video,
    'video/quicktime': icons.video,
    'video/webm': icons.video,

    'application/gzip': icons.archive,
    'application/zip': icons.archive,

    'text/css': icons.code,
    'text/html': icons.code,
    'text/javascript': icons.code,
    'application/javascript': icons.code,

    'text/plain': icons.text,
    'text/richtext': icons.text,
    'text/rtf': icons.text
  }
  return mimeTypes[mimeType.toLowerCase()] || icons.file
}

// the 180/100 padding-bottom should be redone, something wrong with absolute positioning within
const Container = styled.div`
  position: relative;
  width: 100%;
  height: 138px;
  padding-bottom: 180px;
  ${ (props) => props.short && css` padding-bottom: 100px; ` }
  ${ (props) => props.short && css` height: 70px; ` }
  transition: height 0.5s;
`;

const Drop = {
  Container: styled.div`
    perspective: 35px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 138px;
  `,
  Area: styled.div`
    text-align: center;
    border: 1px dashed #585858;
    border-radius: 5px;
    padding: 16px;
    transition: opacity 0.4s, transform 0.4s;
    ${ (props) => props.visible && css` transition-delay: 0.2s, 0s; ` }
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;

    i {
      color: #585858;
      font-size: 37.4px;
    }

    ${ (props) => props.dragging && css`
      transform: scale(0.98);
    ` }

    ${ (props) => !props.visible && css`
      pointer-events: none;
      transform: scale(0.75);
      opacity: 0.0;
    ` }
  `,
  Usage: styled.div`
    padding-top: 5px;
    padding-bottom: 8px;

    span {
      user-select: none;
      text-decoration: underline;
      cursor: alias;

      &:hover {
        color: ${Theme.colors.brand};
      }
    }

    input {
      position: absolute;
      top: -10000px;
      left: -10000px;
    }
  `,
  Rules: styled.div`
    font-size: 12px;
    line-height: 17px;
    color: #000;
  `
};

const Document = {
  Container: styled.div`
    pointer-events: none;
    transition: all 0.5s;
    opacity: 0.0;
    transform: translateY(10px);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    display: flex;

    ${ (props) => props.visible && css`
      pointer-events: auto;
      transition-delay: 0.2s;
      transform: translateY(0px);
      opacity: 1.0;
    ` }
  `,
  Preview: styled.div`
    position: relative;
    width: 100px;
    height: 70px;
    margin-right: 16px;
    background-color: #EFEFEF;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #666;

    i {
      font-size: 21px;
      position: relative;
      z-index: 1;
    }
  `,
  Viewer: styled.canvas`
    transition: all 0.3s;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    ${ (props) => props.transparent && css`
      opacity: 0.2;
    ` }
  `,
  Details: styled.div`
    flex-grow: 1;
    min-width: 0;
  `,
  Summary: styled.div`
    padding-right: 24px;
  `,
  Name: styled.div`
    font-size: 14px;
    padding-bottom: 4px;
    direction: rtl;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
  `,
  Size: styled.div`
    font-size: 12px;
    color: #585858;
    padding-bottom: 8px;
  `,
  Remove: styled.span`
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  `,
  Upload: {
    Progress: styled.div`
      width: 100%;
      height: 4px;
      background-color: #EFEFEF;
      position: relative;

      &:after {
        content: '';
        height: 100%;
        background-color: #085D0F;
        transition: width 0.2s;
        position: absolute;
        left: 0px;
        top: 0px;

        ${ (props) => css` width: ${ `${ props.completion }%` }; ` }
        ${ (props) => props.failed && css` background-color: #940420; ` }
      }
    `,
    Cancel: styled.div`
      color: #585858;
      position: absolute;
      top: 4px;
      right: 0px;
      font-size: 22px;
      cursor: pointer;
    `,
  }
};

class Phase {
  constructor(length, method) {
    this.length = length;
    this.method = method;
    this.progress = 0;
    this.complete = false;
  }

  launch(handoff, callback) {
    this.cancellation = this.method((amount, complete) => {
      this.progress = ( amount / 100 ) * this.length;
      if (complete !== undefined) this.complete = true;
      callback(this.progress, complete);
    }, handoff);
  }

  cancel() {
    this.cancellation?.();
    this.reset();
  }

  reset() {
    this.progress = 0;
    this.complete = false;
  }
};

class Upload extends Component {
  static Status = {
    Idle: 'Idle',
    Uploading: 'Uploading'
  };

  static Phase = { 'Start': -1 };

  static Resource = 'file';
  static Formats = null;
  static Aliases = { JPG: 'JPEG' };
  static Maximum = null;
  static Previewable = 5000000;
  static Library = { title: 'Files' };

  // The upload procedure is the meat of the upload process.
  // This is just a convenient way to organise the process
  // around animations and a single progress bar for all
  // the asynchronous moving parts involved.

  // The first argument of the Phase class' constructor is
  // the amount of percentage the relative progress of the
  // phase applies to in the total progress for the bar.

  // The second argument is the phase method, this is where
  // the actual async work gets handled.

  // The phase method's first argument "callback" is a
  // function to respond to the main phase controller during
  // async processes that progress has happened or the phase
  // has completed.

  // It takes 2 arguments:
  // 1. The amount of progress relative to the phase you're
  //    in (from 0-100, like a percentage).
  // 2. The completion flag. This also doubles as the source
  //    of the incoming handoff variable. This argument is
  //    optional up until you need to mark the phase as
  //    finished in the controller, at which point you just
  //    need to provide a value (truthy at least for the sake
  //    of readability).

  // The phase method's second argument "handoff" is the
  // value of the last phase's completion flag. Here we use
  // it to pass the hash of the file we're uploading to the
  // ajax call that checks if it already exists on MDMS.

  static Procedure = {};

  get Resource() { return this.constructor.Resource; }
  get Formats() { return this.constructor.Formats; }
  get Aliases() { return this.constructor.Aliases; }
  get Maximum() { return this.props.maximum || this.constructor.Maximum; }
  get Previewable() { return this.constructor.Previewable; }
  get Library() { return this.constructor.Library; }
  get Procedure() { return this.constructor.Procedure; }

  get file() { return this.state?.file; }
  get status() { return this.state.status; }
  get empty() { return !this.props?.value; }
  get full() { return !this.empty; }
  get detailed() { return this.props?.detailed }

  constructor(props) {
    super(props);

    this.state = {
      file: this.parse(props?.value),
      status: Upload.Status.Idle,
      progress: 0,
      error: null,
      dragging: false
    };

    this.drop = {
      zone: createRef(),
      animation: createRef(),
      throttle: null,
      events: {
        listen: () => this.drop.events.switch(true),
        unlisten: () => this.drop.events.switch(false),
        switch: (on) => {
          let method = on ? 'addEventListener' : 'removeEventListener';
          this.drop.zone.current[method]('dragover', this.drop.reactions('hover'));
          this.drop.zone.current[method]('dragleave', this.drop.reactions('leave'));
          this.drop.zone.current[method]('drop', this.drop.reactions('drop'));
        }
      },
      reactions: (reaction) => (event) => {
        if (this.filled) return;
        event.preventDefault();
        event.stopPropagation();
        ({
          hover: (event) => {
            this.drop.animate(event.layerX, event.layerY);
            if (!this.state.dragging) this.setState({ dragging: true });
          },
          leave: (event) => {
            this.drop.rest();
            if (this.state.dragging) this.setState({ dragging: false });
          },
          drop: (event) => {
            this.drop.rest();
            this.select(event.dataTransfer.files[0]);
          }
        })
        [reaction]
        (event);
      },
      rest: () => this.drop.animation.current.style.transform = null,
      animate: (x, y) => {
        let now = new Date().getTime();
        if (now >= this.drop.throttle + 20) {
          this.drop.throttle = now;
          this.drop.animation.current.style.transition = 'all 0.1s';
          this.drop.animation.current.style.transform = `rotateX(${
            (( x / this.drop.zone.current.offsetWidth ) - 0.5).toFixed(2) * -1.0
          }deg) rotateY(${
            (( y / this.drop.zone.current.offsetHeight ) - 0.5).toFixed(2)
          }deg)`;
        }
      }
    };

    this.browser = createRef();
    this.viewer = createRef();

    this.browse = this.browse.bind(this);
    this.parse = this.parse.bind(this);
    this.validate = this.validate.bind(this);
    this.prepare = this.prepare.bind(this);
    this.upload = this.upload.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidMount() { this.drop.events.listen(); this.prepare(); }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value) {
      this.setState(
        {
          file: this.parse(this.props.value),
        },
        () => this.prepare()
      );
    }
  }
  componentWillUnmount() { this.drop.events.unlisten(); }

  browse() { this.browser.current?.click(); }

  select(file) {
    file = this.parse(file);
    if (this.validate(file)) {
      this.setState(
        {
          file: file,
          status: Upload.Status.Uploading,
          progress: 0,
          dragging: false
        },
        () => this.prepare(true)
      );
    }
  }

  parse(file) {
    if (!file) return null;
    if (file instanceof File) {
      // do nothing
    } else if (typeof file === 'object') {
      file = { name: file.url, type: file.mime_type, size: file.size_bytes, width: file.width, height: file.height };
    } else if (typeof file === 'string') {
      file = { name: file, type: mime.lookup(file) || null };
    }
    return {
      source: file,
      url: file.name,
      name: getFileBasename(file.name),
      size: file?.size,
      extension: (getFileExtension(file.name)||'').toUpperCase(),
      type: file?.type,
      ...file
    };
  }

  validate(file) {
    if (file.size > this.Maximum * 1000000) return false;
    if (!_.includes(this.Formats, file.extension in this.Aliases ? this.Aliases[file.extension] : file.extension)) return false;
    return true;
  }

  prepare(upload=false) {
    if (!this.file) return;

    const preview = (callback) => {
      let canvas = this.viewer.current;

      // TODO - figure out how to size this as rerendered, because if this is
      // collapsed in a list the offset size is 0,0 and drawing image does nothing
      canvas.width = canvas.offsetWidth * 2.0;
      canvas.height = canvas.offsetHeight * 2.0;
      canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);

      const load = (url) => {
        const image = new Image();
        image.src = url;
        image.onload = () => {
          let ratio = Math.min( canvas.width / image.width, canvas.height / image.height );
          canvas.getContext('2d').drawImage(
            image,
            0, 0, image.width, image.height,
            ( canvas.width - image.width * ratio ) / 2,
            ( canvas.height - image.height * ratio ) / 2,
            image.width * ratio, image.height * ratio
          );
        };
      };

      if (this.file.type && this.file.type.startsWith('image')) {
        if (this.file?.source instanceof File && this.file.size <= this.Previewable) {
          const reader = new FileReader();
          reader.readAsDataURL(this.file.source);
          reader.onload = () => load(reader.result);
        } else {
          load(this.file.url);
        }
      }
    };

    const ready = () => {
      preview();
      if (upload) this.upload();
    };
    if (!(this.file.source instanceof File) && (!this.file?.size || !this.file?.type)) {
      const measuring = new XMLHttpRequest();

      measuring.open('HEAD', this.file.url, true);
      measuring.onreadystatechange = () => {
        if (measuring.readyState == measuring.DONE && measuring.status === 200) {
          this.setState({
            file: {
              ...this.file,
              size: parseInt(measuring.getResponseHeader('content-length')),
              type: measuring.getResponseHeader('content-type')
            }
          }, ready);
        }
      };

      measuring.send();
    } else {
      ready();
    }
  }

  upload() {
    let current = Upload.Phase.Start;

    const conclusion = (handoff) => {
      // onchange here with built result value from upload phases (handoff arg)
      // detailed response gets a few fields included
      if (this.detailed) {
        handoff = _.pick(handoff, ['url', 'mime_type', 'size_bytes', 'width', 'height'])
      } else {
        handoff = handoff.url
      }
      this.props?.onChange(handoff);
      this.setState({ progress: 100 }, () => setTimeout(() => this.setState({
        status: Upload.Status.Idle,
        progress: 0
      }), 520));
    };

    const step = (handoff) => {
      if (this.status != Upload.Status.Uploading) return;
      current++; if (current >= Object.keys(this.Procedure).length) return conclusion(handoff);

      this.Procedure[current].launch(handoff, (progress, complete) => {
        if (this.status != Upload.Status.Uploading) return;
        let total = progress;
        for (let i = 0; i < current; i++) total += this.Procedure[i].length;
        this.setState({ progress: total }, (complete !== undefined) ? (() => step(complete)) : null);
      });
    };

    step();
  }

  reset() {
    if (this.full) this.props?.onChange(this.detailed ? null : '');
    _.each(this.Procedure, (phase) => phase.cancel());
    this.setState({
      file: null,
      status: Upload.Status.Idle,
      progress: 0,
      dragging: false
    }, () => this.browser && (this.browser.current.value = null));
  }

  render() {
    return (
      <Container short={ this.full || this.status == Upload.Status.Uploading } >
        <Drop.Container ref={ this.drop.zone } dragging={ this.state.dragging } >
          <div ref={ this.drop.animation } >
            <Drop.Area visible={ this.empty && this.status == Upload.Status.Idle } dragging={ this.state.dragging } >
              <UI.Icon type="cloud-upload" />
              <Drop.Usage>
                Drag and drop your { this.Resource } here or <span onClick={ this.browse } >Browse</span>.
                <input
                  ref={ this.browser }
                  onChange={ (event) => this.select(event.target.files[0]) }
                  type="file"
                  accept={
                    this.Formats.length ? '.' + this.Formats.concat(
                      Object.keys( _.pickBy(this.Aliases, (format, alias) => _.includes(this.Formats, format)) )
                    ).join(',.').toLowerCase() : null
                  }
                />
              </Drop.Usage>
              <Drop.Rules>
                {
                  this.Formats && <>{ `Accepted ${ this.Resource } ${
                    this.Formats.length > 1
                      ? `formats include ${ _.initial(this.Formats).join(', ') } and ${ _.last(this.Formats) }`
                      : `format: ${ this.Formats[0] }`
                  }.` }<br/></>
                }
                { this.Maximum && `Files must be ${ this.Maximum } MB or less.` }
              </Drop.Rules>
            </Drop.Area>
          </div>
        </Drop.Container>
        <Document.Container visible={ this.full || this.status == Upload.Status.Uploading } >
          { this.state.file && (
            <>
              <Document.Preview>
                <Document.Viewer ref={ this.viewer } transparent={ this.empty || this.status == Upload.Status.Uploading } />
                { !this.state.error && {
                  [Upload.Status.Uploading]: <UI.Icon type="cog fa-spin fa-fw" />,
                  [Upload.Status.Idle]: this.full && !(this.state.file.type||'').startsWith('image') && <UI.Icon type={ getClassNameForMimeType(this.state.file.type||'').replace('fa-', '') } />
                }?.[this.status] }
                { this.state.error && <UI.Icon type="fa-exclamation-triangle" /> }
              </Document.Preview>
              <Document.Details>
                <Document.Summary>
                  <Document.Name>{ this.state.file.name }</Document.Name>
                  { this.state.file?.size && <Document.Size>{ getFileSize(this.state.file.size) }</Document.Size> }
                </Document.Summary>
                { this.status == Upload.Status.Idle && this.full && <Document.Remove onClick={ () => this.reset() } >Remove { this.Resource }</Document.Remove> }
                { this.status == Upload.Status.Uploading && <Document.Upload.Progress completion={ this.state.progress } failed={ this.state.error } /> }
                { this.status == Upload.Status.Uploading && <Document.Upload.Cancel onClick={ () => this.reset() } ><UI.Icon type="times" /></Document.Upload.Cancel> }
              </Document.Details>
            </>
          ) }
        </Document.Container>
      </Container>
    );
  }
};

class MdmsUpload extends Upload {
  static Resource = 'image';
  static Formats = [ 'JPEG', 'PNG', 'SVG', 'EPS' ];

  static Maximum = 20;
  static Library = { title: 'Images' };

  static Phase = {
    ...Upload.Phase,
    'Hash': 0,
    'Check': 1,
    'Push': 2
  };

  get Procedure() {
    return {
      [MdmsUpload.Phase.Hash]: new Phase(20, (callback) => {
        const file = this.file.source;

        const reader = new FileReader();
        const buffer = new MD5.ArrayBuffer();

        const step = 2097152;
        const chunks = Math.ceil(file.size / step);
        let chunk = 0;

        reader.onerror = (event) => console.error('error reading file chunk during hash:', event);
        reader.onload = (event) => {
          buffer.append(event.target.result);
          chunk++;
          feed();
        };

        const feed = () => {
          if (chunk >= chunks) return callback(100, buffer.end());

          const from = chunk * step;
          const to = Math.min(from + step, file.size);

          reader.readAsArrayBuffer(file.slice(from, to));
          callback((chunk / chunks) * 100);
        };

        feed();
      }),
      [MdmsUpload.Phase.Check]: new Phase(10, (callback, handoff) => {
        apiGet(`/api/v1/cms/sites/${PB_SITE}/assets/${ this.Resource }/md5/${ handoff }`, { suppressToast: true }).then(response => {
          callback(100, {upload: false, response});
        }).catch(err => {
          if (err.status === 404) {
            callback(100, { upload: true });
          } else {
            toast.error(`Failed. The message returned from the server was: ${err}`)
            // so we can ask someone to check console if they missed toast
            console.error('Error during file hash check:', err);
          }
        })
      }),
      [MdmsUpload.Phase.Push]: new Phase(70, (callback, handoff) => {
        if (handoff.upload) {
          const onProgress = (ev) => {
            if (ev.lengthComputable) {
              const progress = Math.floor(100 * ev.loaded / ev.total);
              callback(progress);
            }
          };
          apiUpload(`/api/v1/cms/sites/${PB_SITE}/assets/${ this.Resource }`, { file: this.file.source, data: { } }, onProgress)
            .then(response => {
              callback(100, response);
            }).catch(err => {
            // in this case the apiUpload already toasted the message, below
            // only so we can ask someone to check console if they missed toast
            console.error('Error during file upload:', err);
          })
        } else {
          callback(100, handoff.response);
        }
      })
    };
  }
}

const Types = {
  Base: Upload,
  Image: class Image extends MdmsUpload {
    static Resource = 'image';
    static Formats = [ 'JPEG', 'PNG', 'SVG', 'EPS' ];

    static Maximum = 20;
    static Library = { title: 'Images' };
  },
  Document: class Document extends MdmsUpload {
    static Resource = 'document';
    static Formats = [ 'DOCX', 'DOC', 'XLS', 'XLSX', 'PDF', 'DWG', 'RVT' ];

    static Maximum = 20;
    static Library = { title: 'Documents' };
  }
};

export default Types;

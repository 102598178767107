import { useEffect, useRef, useState } from 'react';

import styled from '@emotion/styled';
import CTAButton from '../../../CTAButton';
import { v4 as uuid } from 'uuid';

const Container = styled.div`
  margin-top: 16px;

  label, span {
    margin-left: 16px; margin-right: 16px;

    input {
      margin-left: 8px;
    }
  }
`;

const ButtonContainer = styled.div`
  margin-top: 12px;
  margin-left: 24px;
  margin-bottom: 24px;

  button {
    min-width: 100px;
  }
`;

const Load = ({index, formData, onChange}) => {
  const isFirst = index === 0;
  const [di, setDi] = useState(formData.di);
  const [fui, setFui] = useState(formData.fui);

  const inpRef = useRef();
  useEffect(() => {
    if(formData.autofocus) {
      inpRef.current.focus();
      formData.autofocus = false;
    }
  });

  const setField = (typ, val) => {
    const parsed = val && parseInt(val);
    if(typ === 'di') {
      setDi(parsed);
      onChange({index: index, data: {di: parsed, fui: fui}});
    } else {
      setFui(parsed);
      onChange({index: index, data: {di: di, fui: parsed}});
    }
  }

  return (
    <Container>
      <label>Load {index+1}</label>
      <span>
        d,i (mm):
        <input type="number"
          ref={inpRef}
          readOnly={isFirst}
          value={di?.toString() || ''}
          required
          onChange={e => setField('di', e.target.value)}/>
      </span>
      <span>
        Fu\i (kN):
          <input type="number"
            value={fui?.toString() || ''}
            required
            onChange={e => setField('fui', e.target.value)}/>
      </span>
    </Container>
  );
};

const Loads = ({onChange, formData}) => {
  const [loads, setLoads] = useState(formData || []);

  formData?.forEach(fd => {
    if(!fd.uuid) fd.uuid = uuid();
  });

  const updateLoads = (start, remove, insert) => {
    const l = loads.slice();
    if(insert) {
      l.splice(start,remove,insert);
    } else {
      l.splice(start,remove);
    }
    setLoads(l);
    onChange(l);
  }

  const updateField = (obj) => {
    updateLoads(obj.index, 1, Object.assign({uuid: loads[obj.index].uuid}, obj.data));
  }

  const addLoad = (ind) => {
    updateLoads(ind+1, 0, {autofocus: true, di: 0, fui: 0, uuid: uuid()});
  };

  const removeLoad = (ind) => {
    updateLoads(ind, 1);
  };
  return (
    <>
    <ButtonContainer>
      <CTAButton
        onKeyPress={(e) => {e.preventDefault(); addLoad(-1)}}
        element="button"
        text="Add"
        onClick={(e) => {e.preventDefault(); addLoad(-1)}}/>
    </ButtonContainer>
    {
      loads.map((l, i) => {
        return (
          <div key={l.uuid}>
            <Load index={i} onChange={updateField} formData={l}/>
            <ButtonContainer>
              {i < 50 &&
              <CTAButton
                onKeyPress={(e) => {e.preventDefault(); addLoad(i)}}
                element="button"
                text="Add"
                onClick={(e) => {e.preventDefault(); addLoad(i)}}/>}
              {!(i==0 && loads.length > 1) && <CTAButton
                onKeyPress={(e) => {e.preventDefault(); removeLoad(i)}}
                element="button"
                text="Remove"
                onClick={(e) => {e.preventDefault();removeLoad(i)}}/>}
            </ButtonContainer>
          </div>
        )
      })
    }
    </>
  );
};

export default Loads;

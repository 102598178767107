import { useState } from 'react';
import ReactDOM from 'react-dom';

import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Path, define } from '@owenscorning/pcb.alpha';
import _ from 'lodash';
import Visibility from './Visibility';

const Drag = {
  Container: styled.div`
    transition: opacity 0.4s, width 0.3s;
    opacity: ${ ({ enabled }) => enabled ? 1.0 : 0.0 };
    width: ${ ({ enabled }) => enabled ? 20 : 0 }px;
    overflow: hidden;
    flex-shrink: 0;
    ${ ({ minimal, enabled }) => minimal && css` width: ${ enabled ? 27 : 0 }px; ` }
  `,
  Button: styled.div`
    width: 11px;
    height: 17px;
    margin-top: 24px;
    background-image: url(${ () => UI.Images.Drag });
    background-position: center;
    background-size: auto 17px;
    background-repeat: no-repeat;
    cursor: grab;
    pointer-events: ${ ({ enabled }) => enabled ? 'auto' : 'none' };
    user-select: none;
    ${ ({ minimal }) => minimal && css` margin-top: 10px; ` }
  `
};

export default define`List`('0.0.1')({
  view: ({ value, contents, name }) => value?.map?.((item, index) => <Subschema field={ index } >{ contents }</Subschema>) || null,
  edit: ({ UI, contents, path=[], title='', singular='', min=0, max=-1, immutable=false, hideable=true, dropdown=true, minimal, actions, value, onChange, onClick, onAdd, startOpened, opts }) => {
    if (!value) onChange(value = []);
    const defaultOpened = _.isArray(startOpened) ? startOpened : (new Array(value?.length || 0)).fill(false);

    if (!_.isUndefined(startOpened) && !_.isArray(startOpened)) defaultOpened[startOpened] = true;

    const [ opened, setOpened ] = useState(defaultOpened);

    const full = max >= 0 && value.length >= max;
    const empty = min > value.length;

    const move = (list, from, to) => {
      if (from == to) return list;
      const array = _.cloneDeep(list);
      array.splice(to, 0, array.splice(from, 1)[0]);
      return array;
    };

    const items = {
      change: (index, item) => onChange( _.set(value, index, item) ),
      add: (contents={}) => onChange( value.concat(contents) ),
      duplicate: (index) => onChange( value.slice(0, index).concat(_.cloneDeep(value[index]), value.slice(index)) ),
      delete: (index) => onChange( _.values(_.omit(value, index)) ),
      drop: (drop) => {
        if (!drop.destination) return;
        const [ from, to ] = [ drop.source.index, drop.destination.index ]; if (from == to) return;
        onChange(move(value, from, to));
        setOpened(move(opened, from, to));
      }
    };
    return <div>
      <DragDropContext onDragEnd={ items.drop } >
        <Droppable isDropDisabled={ immutable } droppableId={`${ path.join('-') }-drop`} >{
          (provided, snapshot) => (
            <div
              { ...provided.droppableProps }
              ref={ provided.innerRef }
              css={[ css` ${ !minimal && css `margin-bottom: -24px; ` } `, snapshot.isDraggingOver ]}
            >
              {
                value.map?.((item, index) => {
                  const draggable = value.length > 1 && !opened[index];
                  const itemActions = _.isFunction(actions) ? actions(item, index) : actions;
                  return <Draggable key={ index } index={ index } draggableId={ `${ path.join('-') }-drag-${ index }` } isDragDisabled={ immutable } >
                    {
                      (provided, snapshot) => ((body) => snapshot.isDragging ? ReactDOM.createPortal(body, document.body) : body)(
                        <div
                          ref={ provided.innerRef }
                          { ...provided.draggableProps }
                          css={[
                            css` display: flex; ${ !minimal && css `margin-bottom: 24px; ` } `,
                            provided?.draggableProps?.style
                          ]}
                        >
                          <Drag.Container minimal={ minimal } enabled={ draggable } >
                            <Drag.Button minimal={ minimal } enabled={ draggable } { ...provided.dragHandleProps } />
                          </Drag.Container>
                          <UI.List.Item
                            unwrapped
                            key={ index }
                            tools={
                              ( (!immutable && !item.templated) || !_.isEmpty(itemActions) ) && <UI.Dots actions={ itemActions } reference={ index } >
                                { !immutable && !full && !item.templated && <UI.Belt.Action onClick={ () => items.duplicate(index) } ><UI.Icon type="files-o" /> Duplicate</UI.Belt.Action> }
                                { !immutable && !empty && !item.templated && <UI.Belt.Action onClick={ () => items.delete(index) } ><UI.Icon type="trash" /> Delete</UI.Belt.Action> }
                              </UI.Dots>
                            }
                            title={ title && ( _.isFunction(title) ? title(item) : _.get(item, Path.Parse(title)) ) }
                            subtitle={ `${ singular ? `${ singular } ` : '' }${ index + 1 }` }
                            minimal={ minimal }
                            dropdown={ dropdown }
                            value={ item }
                            onChange={ (value) => items.change(index, value) }
                            onClick={ onClick && ( () => onClick(index, {...item, opts}) ) }
                            onOpen={ () => setOpened(_.set([...opened], index, true)) }
                            onClose={ () => setOpened(_.set([...opened], index, false)) }
                            opened={ opened[index] }
                            hideable={ hideable }
                          >
                            <UI.Form field={ index } >{ contents }</UI.Form>
                          </UI.List.Item>
                        </div>
                      )
                    }
                  </Draggable>
                })
              }
              { provided.placeholder }
            </div>
          )
        }</Droppable>
      </DragDropContext>
      { !immutable && <UI.List.Adder
        full={ full }
        empty={ empty }
        max={ max }
        singular={ singular }
        onClick={ !full && ( onAdd ? () => onAdd(items.add) : items.add ) }
        minimal={ minimal }
      /> }
    </div>;
  }
});

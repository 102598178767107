import { ui, define } from '@owenscorning/pcb.alpha';
import _ from 'lodash';

export default define`OCBasic`('0.0.1')({
  view: ({ prehead, heading, bodyCopy, banner={}, ctas=[], bodyFont, theme, children, ...props }) => <>
    <UI.Box margin={ _.isEmpty(ctas) ? ['4', '0'] : ['2', '0'] } >
      <UI.Prehead>{ prehead }</UI.Prehead>
      <UI.H2>{ heading }</UI.H2>
      <UI.Text>{ bodyCopy }</UI.Text>
    </UI.Box>
  </>,
  edit: ui`List/Item`.of(
    ui`Form`.of({
      prehead: ui`Text`({ label: 'Prehead', default: 'Marketing' }),
      heading: ui`Text/Rich/Header`({ label: 'Main Heading', default: 'Business Services' }),
      bodyCopy: ui`Text/Rich`({ label: 'Body Copy', default: 'ID 6832951 / Company Overview' }),
    })({
      label: 'Content',
    })
  )({
    title: 'Header Content',
    hideable: true
  })
})

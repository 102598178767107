import { useState } from 'react';
import { jsx, css } from '@emotion/react';

const Heading = ({ open, onToggle, children }) => (
  <div
    onClick={ onToggle }
    css={ css`
      display: flex;
      align-items: center;
    ` }
  >
    <div css={ css` flex-grow: 1; ` } >{ children }</div>
    <UI.Icon image={ open ? UI.Images.ChevronUp : UI.Images.ChevronDown } />
  </div>
);

Heading.Small = ({ open, onToggle, children }) => <div css={ css`
  display: flex;
  align-items: center;
` } >
  <UI.Icon
    css={ css`
      transition: transform 0.1s;
      transform: rotate(${ open ? 0 : -90 }deg);
      margin-right: 8px;
    ` }
    type="chevron-down"
    onClick={ onToggle }
    interactive
  />
  <div>{ children }</div>
</div>;

Heading.Mobile = ({ open, onToggle, children }) => <div css={ css`
  display: flex;
  align-items: center;
  background-color: ${ open ? '#585858' : '#E6E6E6' };
  color: ${ open ? 'white' : 'black' };
  padding: 16px;
  justify-content: space-between;
  cursor: pointer;
  ` }
  onClick={onToggle}
  >
  <div>{ children }</div>
  <UI.Icon
    css={ css`
      transition: transform 0.1s;
      transform: rotate(${ open ? 0 : -90 }deg);
      margin-right: 8px;
    ` }
    type="chevron-down"
    interactive
  />
</div>;

const Dropdown = (props) => {
  const { heading, title, children, startsOpen, style } = props;

  const Top = heading ? heading : Heading;

  const [ open, setOpen ] = useState(startsOpen || false);
  const onToggle = () => setOpen(!open);

  return <div>
    <Top { ...props } open={ open } onToggle={ onToggle } >{ title }</Top>
    <div css={[css` padding-top: 16px; `, !open && css` display: none; `]}
      {...(style && { style })}
    >
      {children}
    </div>
  </div>;
};

Dropdown.Small = ({ ...props }) => <UI.Dropdown
  { ...props }
  heading={ Heading.Small }
/>;

Dropdown.Mobile = ({ ...props }) => <UI.Dropdown
  { ...props }
  heading={ Heading.Mobile }
  style={{
    borderBottom: '1px solid #D5D5D5',
    paddingBottom: '10px'
  }}
/>;

export default Dropdown;

import { useState } from 'react';
import styled from '@emotion/styled';

const FactorLabel = styled.label`
  margin-top:18px;
`;

const LoadType = ({onChange, formData}) => {
  const [loadType, setLoadType] = useState(formData.load_type || '');
  const [dynamicFactor, setDynamicFactor] = useState(formData.dynamic_factor || 1);

  if(!formData.dynamic_factor) {
    onChange({load_type: 'Multiple static load', dynamic_factor: 1});
  }

  const dropdownChanged = (e) => {
    if (e.target.value === 'Multiple static load') {
      setDynamicFactor(1);
    }
    setLoadType(e.target.value);
    onChange({load_type: e.target.value, dynamic_factor: dynamicFactor});
  };

  const factorChanged = (e) => {
    setDynamicFactor(e.target.value)
    if (e.target.value) {
      onChange({load_type: loadType, dynamic_factor: parseFloat(e.target.value)})
    }
  };
  return (
    <>
      <select onChange={dropdownChanged} required value={loadType}>
        <option value="Multiple static load">Multiple static load</option>
        <option value="Multiple dynamic load">Multiple dynamic load</option>
      </select>
      {loadType === 'Multiple dynamic load' && (
        <>
          <FactorLabel>
            Dynamic Factor*
          </FactorLabel>
          <input required onChange={factorChanged} value={dynamicFactor} type="number" step="any"></input>
        </>
      )}
    </>
  );
};

export default LoadType;

import { ui } from '@owenscorning/pcb.alpha';
import Article from './Article';

const InsulationSolutionsSuite = Article(
  'Article::InsulationSolutionsSuite',
  'iss',
  [
    'SocialMediaShareBanner',
    'RelatedArticles',
    'JumpToSolutionCenter'
  ],
  ui`AttributeSet`({
    query: {
      name: 'ISS Article Attributes',
      path: '/insulation/insulation-solutions-suite/article'
    }
  }),
  {
    version: '0.1',
    displayName: 'ISS Article',
    paths: ['insulation/insulation-solutions-suite/article'],
  }
)

export default InsulationSolutionsSuite;

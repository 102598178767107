import React, { useState } from 'react';
import Theme from '../../../themes/index';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

// Components
import Label from './oc-label';
import RadioButton from './oc-radio-button';
import ImageRadioButton from './oc-image-radio-button';
import Checkbox from './oc-checkbox';
import InputError from './oc-input-error';

const Container = styled.div`
  display: inline-block;
  position: relative;
  margin-bottom: ${props => props.margin};
  width: 100%;
`;

const Group = styled.div`
  display: flex;
  flex-direction: ${props => props.vertical ? 'column' : 'row' };
  flex-wrap: wrap;
  justify-content: flex-start;

  ${props => !props.imageRadio && `
    @media screen and (max-width: 767px) {
      background-color: #FFFFFF;
      border-left: 1px solid #000000;
      border-right: 1px solid #000000;
      border-top: 1px solid #000000;
      flex-direction: column;
    }
  `}

  @media screen and (min-width: 768px) {
    flex-direction: ${props => props.vertical ? 'column' : 'row' };
  }

  label {
    margin-bottom: ${props => props.additionalCheckbox ? "1em" : (props.extendGap ? "1em" : "0.5em") };

    ${props => !props.vertical && `
      margin-right: 4em;
    `}

    ${props => props.label && `
      &:first-of-type {
        margin-bottom: 0.5em;
      }
    `}

    &:last-of-type {
      margin-right: 0;
      margin-bottom: 0;
    }

    ${props => !props.imageRadio && `
      @media screen and (max-width: 767px) {
        border-bottom: 1px solid #000000;
        margin: 0;
        padding: 16px 8px;

        input[type="radio"] ~ .radio {
          left: 12px;
          top: 22px;
        }
      }
    `}
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  width: 100%;
`;

const Error = styled(InputError)`
  display: inline-block;
  width: 100%;
`;

const RadioGroup = ({
  additionalChange,
  additionalCheckbox,
  className,
  defaultValue,
  error,
  helperText,
  helperTextColor,
  extendGap = false,
  imageRadio = false,
  infoFunction,
  label,
  margin = "40px",
  name,
  themeColor = Theme.colors.brand,
  onBlur,
  onChange,
  options,
  required = false,
  valid = true,
  vertical = false,
  ...props
}) => {
  const Component = imageRadio ? ImageRadioButton : RadioButton;
  const [value, setValue] = useState();
  const [additionalChecked, setAdditionalChecked] = useState(false);

  const handleAdditionalChange = (event) => {
    setAdditionalChecked(!additionalChecked);
    additionalChange && additionalChange(event);
  }

  return (
    <Container className={className} label={label} margin={margin}>
      {label && <Label text={label} helperText={helperText} helperTextColor={helperTextColor} valid={valid} infoFunction={infoFunction} required={required} />}
      <Group
        additionalCheckbox={additionalCheckbox}
        imageRadio={imageRadio}
        onBlur={onBlur}
        onChange={onChange}
        vertical={vertical}
        extendGap={extendGap}
      >
        {options.map((option, optionIndex) =>
          <Component
            defaultChecked={option.value == defaultValue}
            key={optionIndex}
            name={name}
            themeColor={themeColor}
            value={option.value}
            {...option}
          >
            {option.text}
          </Component>
        )}
      </Group>
      {additionalCheckbox &&
        <CheckboxContainer>
          <Checkbox onChange={handleAdditionalChange} name={additionalCheckbox.name} themeColor={themeColor} value={additionalChecked}>
            {additionalCheckbox.text}
          </Checkbox>
        </CheckboxContainer>
      }
      {(error && !valid) &&
        <CheckboxContainer>
          <Error message={error} />
        </CheckboxContainer>
      }
    </Container>
  )
};

export default RadioGroup;

RadioGroup.propTypes = {
  /* HTML: (Optional) Text string to display as a top level label for group or selections. */
  label: PropTypes.string,
  /* HTML: (Optional) Text string to display after the top level label. */
  helperText: PropTypes.string,
  /* HTML: Name attribute for the individual input elements. */
  name: PropTypes.string,
  /* CSS: Hex value for color theme */
  themeColor: PropTypes.string,
  /* JSX: (Required) Option objects that render the actual radio input elements; should at minimum include text and value props. */
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  /* CSS: (Optional) String px value to adjust the margin-bottom value for the group. */
  margin: PropTypes.string,
  /* JSX: Set to true if desired output elements should utilize the ImageRadioButton instead of the default RadioButton. */
  imageRadio: PropTypes.bool,
  /* CSS: Set to true if fields should be listed vertically instead of inline. */
  vertical: PropTypes.bool,
}

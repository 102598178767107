import _ from "lodash";
import { v4 as uuid } from 'uuid';

export const filterEntity = (thing, predicate, path=[]) => {
  if (Array.isArray(thing)) {
    return thing.filter((item, index) => predicate(item, [...path, index])).
                 map((item, index) => filterEntity(item, predicate, [...path, index]));
  } else if (_.isPlainObject(thing)) {
    const clone = _.cloneDeep(thing);
    // filter any keys that are arrays too
    Object.keys(clone).forEach(key => {
      clone[key] = filterEntity(clone[key], predicate, [...path, key]);
    })
    return clone;
  } else {
    return thing;
  }
};

export const transformEntity = (thing, transform, path=[]) => {
  if (Array.isArray(thing)) {
    return thing.map((item, index) => transformEntity(item, transform, [...path, index]));
  } else if (_.isPlainObject(thing)) {
    const clone = transform(_.cloneDeep(thing));
    // filter any keys that are arrays too
    Object.keys(clone).forEach(key => {
      clone[key] = transformEntity(clone[key], transform, [...path, key]);
    })
    return clone;
  } else {
    return thing;
  }
};

export const inVisibilityGroup = (group_value) => {
  if ((typeof(PB_VISIBILITY_GROUPS) === 'undefined' || PB_VISIBILITY_GROUPS === true))
  {
    return true
  }
  return PB_VISIBILITY_GROUPS.includes(group_value);
}

export const inVisibilityGroupList = (group_list) => {
  return (group_list||[]).filter(group_value => inVisibilityGroup(group_value)).length > 0;
}

export const evaluateVisibilityField = (item) => {
  if ((typeof(PB_VISIBILITY_GROUPS) === 'undefined' || PB_VISIBILITY_GROUPS === true) || !item.__visibility)
  {
    return true
  }

  return inVisibilityGroupList(item.__visibility.show_for) && !inVisibilityGroupList(item.__visibility.except_for)
}

export const isItemVisible = (item) => (
  item && (!item.__hidden && evaluateVisibilityField(item))
)

export const addItem = (contents, parent_uuid, newData) => {
  const newItem = { ...newData, __uuid: uuid() }
  contents[newItem.__uuid] = newItem;
  const parent = contents[parent_uuid || '0']
  if (parent) {
    if (parent.children) {
      parent.children.splice(parent.children.length, 0, newItem.__uuid);
    } else {
      parent.children = [newItem.__uuid];
    }
  } else {
    // TODO how to present this case?  parent given but not in state
  }
};

export const updateItem = (contents, newItem) => {
  if (!contents) {
    return;
  }
  contents[newItem.__uuid] = { ...newItem };
};

export const deleteItem = (contents, uuid) => {
  if (!contents) {
    return;
  }
  delete contents[uuid];
  Object.values(contents).forEach(content => {
    const index = content.children?.indexOf(uuid);
    if (index > -1) {
      content.children.splice(index, 1);
    }
  })
}

export const scrollToItem = (id) => {
  setTimeout(() => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({behavior: 'smooth'});
    }
  }, 100);
}

export const moveItem = (contents, moveData) => {
  // moveData: { source: { parentId: uuid, index: integer }, destination: { parentId: uuid, index: integer } }
  const { source, destination } = moveData;

  const item = contents[source.parentId].children.splice(source.index, 1)[0];
  const newParent = contents[destination.parentId];
  if (newParent.children) {
    newParent.children.splice(destination.index, 0, item);
  } else {
    newParent.children = [item];
  }
};

const PageContainerMetadata = {
  allowableParents: false,
  allowableChildren: true
};

export const getAllowedChildComponentTypes = (componentHash, parentComponentName) => {
  const componentAddMetadata = parentComponentName ? componentHash[parentComponentName] : PageContainerMetadata;

  let allowedChildTypes = [];
  if (!componentAddMetadata.allowableChildren) {
    // no allowed children
  } else if (Array.isArray(componentAddMetadata.allowableChildren)) {
    // directly specified list of children
    allowedChildTypes = componentAddMetadata.allowableChildren;
  } else {
    // build inverse of "allowableParents" containing this component
    allowedChildTypes = Object.entries(componentHash).map(([name, component]) => {
        if (component.allowableParents === true || (Array.isArray(component.allowableParents) && component.allowableParents.indexOf(parentComponentName) !== -1)) {
          return name;
        } else {
          return null;
        }
      }
    ).filter(c => !!c);
  }

  const componentPalette = Object.fromEntries(Object.entries(componentHash).sort().filter(([key, value]) => allowedChildTypes.indexOf(key) !== -1 && !value.preventCreate));
  return componentPalette;
};

const defaultComponentFactory = (editorMetadata) => (
  Object.fromEntries(
    Object.entries(editorMetadata).map(
      ([key, value]) => {
        const response = value?.[1]?.default || (_.isPlainObject(value?.[0]) ? defaultComponentFactory(value?.[0]) : null);
        return [key, response];
      }
    ).filter(
      ([key, value]) => value
    )
  )
);

export const componentFactory = (componentName, componentMetadata) => {
  const { editors, createNew } = componentMetadata;
  const coreData = {component: componentName, __uuid: uuid()};
  if (createNew) {
    return _.merge({children: []}, createNew(), coreData);
  }
  if (editors) {
    return _.merge({children: []}, defaultComponentFactory(componentMetadata?.editors), coreData)
  }
  return coreData;
};

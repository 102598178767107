import React from 'react'
import Result from './oc-results'
import GroupedResult from "./oc-grouped-results";
import Pagination from '../oc-pagination';

const paginate = (array, page_number, page_size) => (
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  array.slice((page_number - 1) * page_size, page_number * page_size)
)

const ResultList = ({results, resultComponent, resultHeaderComponent, resultComponentStyle, resultGridSize, grouping, sortContent={}, pagination, appliedFilters, locale, showLogo=false}) => {
  const groupingFields = sortContent.fields ? Object.keys(sortContent.fields).filter(field => sortContent.fields[field]?.group) : [];
  const isGroupingSortSelected = (groupingFields.indexOf(sortContent.props?.selected) >= 0);
  const ResultComponent = grouping && isGroupingSortSelected ? GroupedResult : Result;

  return(
    <>
      {
        results.map((result, index) => {
          return (
            <ResultComponent key={result.key || index}
                             resultHeaderComponent={resultHeaderComponent}
                             result={
                               {
                                 ...result,
                                 data: pagination && !pagination.server ? paginate(result.data, pagination.page, pagination.perPage) : result.data
                               }
                             }
                             resultComponent={resultComponent}
                             resultComponentStyle={resultComponentStyle}
                             resultGridSize={resultGridSize}
                             appliedFilters={appliedFilters}
                             grouping={grouping}
                             locale={locale}
                             showLogo={showLogo}
            />
          )
        })
      }

      { pagination && pagination.totalPages > 1 && <Pagination value={ pagination.page || 1 } totalPages={ pagination.totalPages } onChange={ val => pagination.updateParams("page", val) } /> }
    </>
  )
}

export default ResultList;

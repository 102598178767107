export default function getFileSize(number) {
  if (!number) {
    return '';
  }
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB'];
  function base() {
    return 1024;
  }
  function smaller_than_base() {
    return number < base();
  }
  function exponent() {
    const max = units.size - 1;
    let exp = Math.floor(Math.log(number) / Math.log(base()));
    if (exp > max) exp = max;
    return exp;
  }
  function unit_key() {
    return smaller_than_base() ? 0 : exponent();
  }
  let number_to_format = number;
  if (number < base()) {
    number_to_format = Math.floor(number);
  } else {
    const human_size = number / (Math.pow(base(), exponent()));
    number_to_format = Math.round(human_size * 100) / 100;
  }
  return `${number_to_format} ${units[unit_key()]}`;
}

import PropTypes from 'prop-types';
import React from 'react';

import { BasicContent } from './BasicContent';
import L77Loosefill from '../../Insulation/SubmittalSheets/L77Loosefill/Index';

function CMSL77Loosefill({ content }) {
  return (
    <>
      <BasicContent {...content.copy} />
      <L77Loosefill />
    </>
  );
}

CMSL77Loosefill.propTypes = {
  content: PropTypes.object.isRequired,
};

export default CMSL77Loosefill;


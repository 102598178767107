import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';

const BannerStyles = styled.div`
  .banner {
    text-align: center;
    height: 71px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 7px;
    background-color: ${props => props.mode === 'dark' ? 'black' : 'white'};
  }

  .icon {
    color: ${props => props.mode === 'dark' ? 'white' : 'black'};
    margin: 14px;

    &:hover {
      color: #D40f7d;
    }
  }

  .captionText {
    color: ${props => props.mode === 'dark' ? 'white' : 'black'};
  }

  .facebook-icon {
    display: ${props => props.facebook ? '' : 'none'};
  }

  .twitter-icon {
    display: ${props => props.twitter ? '' : 'none'};
  }

  .linkedin-icon {
    display: ${props => props.linkedin ? '' : 'none'};
  }

  .pinterest-icon {
    display: ${props => props.pinterest ? '' : 'none'};
  }
`

const SocialMediaShareBanner = (props) => {
  let mode = props.mode || 'dark'
  return (
      <BannerStyles mode={mode} facebook={props.facebook} twitter={props.twitter} linkedin={props.linkedin} pinterest={props.pinterest}>
        <div className="banner">
          <span className="captionText">{props.text}: </span>
          <a className="facebook-icon" target="_blank" href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location.href + "&quote=" + props.shareMessage}>
            <i className="fa fa-facebook-square fa-2x icon"></i>
          </a>
          <a className="twitter-icon" target="_blank" href={"https://twitter.com/intent/tweet?url=" + window.location.href + "&text=" + props.shareMessage}>
            <i className="fa fa-twitter fa-2x icon"></i>
          </a>
          <a className="linkedin-icon" target="_blank" href={"https://www.linkedin.com/sharing/share-offsite/?url=" + window.location.href}>
            <i className="fa fa-linkedin-square fa-2x icon"></i>
          </a>
          <a className="pinterest-icon" target="_blank" href={"http://pinterest.com/pin/create/button/?url=" + window.location.href}>
            <i className="fa fa-pinterest fa-2x icon"></i>
          </a>
        </div>
      </BannerStyles>
    )
};

export default SocialMediaShareBanner;

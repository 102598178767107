import { useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import pym from 'pym.js';

//Components

// Styled Components
const Wrapper = styled.div`
	position: relative;
	padding-bottom: ${props => props.pymjs ? '24px' : (props.mobileHeight ? props.mobileHeight : '56.25%')};
	padding-top: 24px;
	height: ${props => props.pymjs ? 'auto' : '0'};

	@media(min-width: 768px) {
		padding-bottom: ${props => props.pymjs ? '24px' : (props.tabletHeight ? props.tabletHeight : '56.25%')};
	}

	@media(min-width: 1200px) {
		padding-bottom: ${props => props.pymjs ? '24px' : (props.desktopHeight ? props.desktopHeight : '56.25%')};
	}
`;

const IframeObject = styled.iframe`
	position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: ${props => props.src ? 'none' : '1px solid black'};
  background: ${props => props.src ? 'none' : '#f5f5f5'};
`;

// Iframe
const Iframe = ({ src, desktopHeight, tabletHeight, mobileHeight, pymjs, iframeId, scrolling }) => {

	const createPym = () => {
		let pymParent = new pym.Parent(iframeId, src, {});
	}

	useEffect(() => {
	  { (iframeId && src && pymjs) &&
	  	createPym();
	  }  
	}, [iframeId, src]);

	return (
		<Wrapper id={ iframeId } desktopHeight={ desktopHeight } tabletHeight={ tabletHeight } mobileHeight={ mobileHeight } pymjs={ pymjs } >
			{ !pymjs && <IframeObject src={ src } scrolling={ scrolling } /> }
		</Wrapper>
	)
};

export default Iframe;

Iframe.propTypes = {
	/** url of the iframe src **/
	src: PropTypes.string,
	/** Height of the iframe in windows wider than 1200px **/
	desktopHeight: PropTypes.string,
	/** Height of the iframe in windows wider than 768px **/
	tabletHeight: PropTypes.string,
	/** Height of the iframe in windows no wider than 767px **/
	mobileHeight: PropTypes.string,
	/** whether or not to use pym.js to setting heights **/
	pymjs: PropTypes.bool,
	/** iframe id **/
	iframeId: PropTypes.bool,
	/** whether or not to allow scrolling on your iframe: Options: 'auto' and 'no' **/
	scrolling: PropTypes.string,
}
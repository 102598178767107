import styled from '@emotion/styled';

const ListContainer = styled.ul`
  list-style: none;
  padding-left: 0px;
  margin: 0;
  cursor: default;
`;

const List = ({ className, children }) => (
  <ListContainer className={ className }>
    { children }
  </ListContainer>
);

export default List;

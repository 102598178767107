import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Theme from '../../../../themes';
import _ from "lodash";

import Link from '../../../OC/oc-link';
import { Flags, Flag } from './Flags';
import { formatEditLink, formatViewPublishedLink, formatViewDraftLink } from '../../../PageBuilder/helpers/path';

const StyledLink = styled(Link)`
  &:hover {
    color: ${Theme.colors.brand};
  }

  &:focus, &:focus-visible {
    outline-color: ${Theme.colors.brand};
  }
`;

const Grid = ({ columns, rows, styling }) => (
  <div css={[
    css`
      display: grid;
      align-items: start;
      grid-template-columns: repeat(${ columns.length }, auto);
      header { font-weight: bold; }
    `,
    ..._.castArray(styling)
  ]} >
    { columns.map((heading, x) => ( <header key={ heading }>{ _.isFunction(heading) ? heading(x) : heading }</header> )) }
    { rows.map((row, y) => row.map((cell, x) => <div key={ `[${x}][${y}]` }>{ _.isFunction(cell) ? cell(x, y) : cell }</div> )) }
  </div>
);

const getRowActions = (row, onDuplicate, onDelete, onRedirect) => ([
  onRedirect && {
    label: row.redirect ? 'Manage Redirect...' : 'Redirect...',
    icon: 'share',
    onClick: () => onRedirect(row)
  },
  _.castArray(row.status).includes(Flags.Status.Published) && {
    label: 'View Live page',
    icon: 'eye',
    onClick: () => {
      window.open(formatViewPublishedLink({ path: row.published_route, slug: '' }, row.locale), '_blank')
      return;
    }
  },
  _.castArray(row.status).includes(Flags.Status.Draft) && {
    label: 'View Draft',
    icon: 'eye',
    onClick: () => { window.open(formatViewDraftLink(row.id, row.locale), '_blank') }
  },
  {
    label: 'Edit',
    icon: 'pencil-square-o',
    onClick: () => { window.location = formatEditLink(row.id, row.locale) }
  },
  onDuplicate && {
    label: 'Duplicate',
    icon: 'files-o',
    onClick: () => onDuplicate(row)
  },
  onDelete && {
    label: 'Delete',
    icon: 'trash',
    onClick: () => onDelete(row)
  }
]);

const isDefinitionShowingLocalizedName = (definition) => (
  definition.usage === 'route'
)

const RedirectWrapper = ({redirect, children}) => redirect ? <em>{ children }</em> : <span>{ children }</span>

const Variants = ({ definition, list, onDuplicate, onDelete, onRedirect, compact=false, showName=true, showDots=true, showLocale=true }) => {
  const columns = [
    showLocale ? 'Location & Language' : null,
    showName && isDefinitionShowingLocalizedName(definition) ? 'Page Name' : null,
    'Last Edited',
    'Last Published',
    'Status',
    showDots ? '' : null
  ].filter(x => x !== null);

  return (
    <Grid
      styling={css`
      grid-template-columns: repeat(${compact ? columns.length : (columns.length - (showDots ? 2 : 1))}, auto) ${compact ? '' : '140px'} ${showDots ? '16px' : ''};
      ${ compact ? '' : 'padding-left: 30px;'}
      padding-bottom: 12px;
      font-size: 12px;
      ${ compact ? '' : 'grid-gap: 16px;' }
    `}
      columns={ columns }
      rows={
        list.map((row) => [
          showLocale ? <RedirectWrapper redirect={ row.redirect }><StyledLink href={ formatEditLink(row.id, row.locale) } analytics={{ track: 'pb-variant' }} >{ row.locale_name }</StyledLink></RedirectWrapper> : null,
          showName && isDefinitionShowingLocalizedName(definition) ? <RedirectWrapper redirect={ row.redirect }><StyledLink href={ formatEditLink(row.id, row.locale) } analytics={{ track: 'pb-variant' }} >{ row.name }</StyledLink></RedirectWrapper> : null,
          <RedirectWrapper redirect={ row.redirect }>{ row.edited ? <span>{ row.edited.by }<br/>{ row.edited.at }</span> : <span css={css` color: #585858; `} >N/A</span> }</RedirectWrapper>,
          <RedirectWrapper redirect={ row.redirect }>{ row.published ? <span>{ row.published.by }<br/>{ row.published.at }</span> : <span css={css` color: #585858; `} >N/A</span> }</RedirectWrapper>,
          <RedirectWrapper redirect={ row.redirect }><Flag status={ row.status } /></RedirectWrapper>,
          showDots ? <UI.Dots actions={ getRowActions(row, onDuplicate, row?.published?.at ? null : onDelete, definition.usage === 'route' ? onRedirect : null) } /> : null
        ].filter(x => x !== null) )
      }
    />
  );
}

export default Variants;

import { ui, select, transform, when, s } from '@owenscorning/pcb.alpha';
import Product from './Product';

const Composites = Product(
  'Product::Composites',
  {},
  {},
  {},
  {
    version: '0.1',
    displayName: 'Composites PDP',
    paths: ['composites/product'],
  }
)

export default Composites;

import { css } from '@emotion/react';
import styled from '@emotion/styled';

// Components
import { H4 } from '../oc-h';
import RichText from './RichText';
import Picture from '../oc-picture';
import MediaObject from '../oc-media-object';
import ChevronLink from '../oc-chevron-link';
import Theme from '../../../themes/index';

// Utilities
import { is_placeholder_url, placeholder_aspect } from '../../../placeholder';
import Text from './utilities/Text';

// Styled Components
export const ContentWrapper = styled.div`
  h4 {
    ${props => props.url && css`
      &:hover {
        color: ${Theme.colors.brand}
      }
    `};
  }

  a {
    &:hover {
      color: ${Theme.colors.brand}
    }
  }
  > h4 {
    margin-bottom: 8px !important;
  }
  > div {
    margin: 0 0 8px;
  }
`;

const CMSMediaObject = ({
  alt,
  aspectRatio,
  children,
  heading,
  icon,
  iconSize,
  image,
  img,
  imgSize,
  linkText,
  prehead,
  text,
  url,
  dataSheet,
  ...props
}) => {
  const crop = image?.crop;
  alt = image?.alt || alt;

  // PB-444: For some reason this component is being reused in multiple parts and we have introduced
  // an "image" and "img" props, that are basically the same, but some classes use one, and others use
  // the other. For PB-444, when there is no image selected now, then image.file === '', so running the
  // previous clause of image = image?.file || image || img, would always set `image` as an object on this
  // variable, which meant that everything was breaking. With the new structure, we simply have to check if
  // image is an object containing file, and if that's the case then we use that as the actual image, otherwise,
  // we refer to the previous implementation.
  // TODO: Properly address the underlying issue and clean all the components that are using this, to just use the
  // image prop instead of img.
  if (typeof(image?.file) !== 'undefined') {
    image = image.file;
  } else {
    image = image || img;
  }

  if (image && !is_placeholder_url(image) && crop) {
    image = Picture.Transform(image, { crop: "crop", ...crop });
  }

  // TODO consolidate with this code in oc-picture too
  const aspectRatioTransform = Picture.AspectRatioTransform(aspectRatio);
  if (is_placeholder_url(image) && aspectRatioTransform) {
    image = placeholder_aspect(image, aspectRatioTransform.aspectRatio)
  }

  return (
    <MediaObject
      alt={alt}
      img={Picture.Transform(image, [aspectRatioTransform, { width: 240, crop: 'fill' }])}
      imgSize={imgSize}
      icon={icon}
      iconSize={iconSize}
      removeExternalClass
      url={url}
      {...props}
    >
      { children ? children :
        <ContentWrapper url={url} >
          { heading && <H4 prehead={ prehead } tag="h4" font="body"><Text content={ heading } /></H4> }
          <RichText content={ text } singular />
          { dataSheet && <RichText content={dataSheet} singular/>}
          { (linkText && url) &&
            <ChevronLink analytics={{ 'element-location': 'main section text link' }}><Text content={ linkText } /></ChevronLink>
          }
        </ContentWrapper>
      }
    </MediaObject>
  );
}

export default CMSMediaObject;

import { ui } from '@owenscorning/pcb.alpha';
import Article from './Article';

const Roofing = Article(
  'Article::Roofing',
  'roofing_blog',
  [
    'SocialMediaShareBanner',
    'RoofingBlogNextSteps',
    'RelatedArticles',
    'JumpToCategory'
  ],
  ui`AttributeSet`({
    query: {
      name: 'Roofing Article Attributes',
      path: '/roofing/blog'
    }
  }),
  {
    version: '0.1',
    displayName: 'Roofing Blog Article',
    paths: ['roofing/blog'],
  }
)

export default Roofing;

export default {
  displayName: "Figure",
  allowableParents: ["Section"],
  migrator: (data) => {
    // step 1, move img/alt at root to "image" object
    const { image, img, alt, ...rest } = data;
    if (!image && (img || alt)) {
      data = { ...rest, image: { file: img, alt }};
    }

    // step 2, convert "image" to "media"
    if (data.image && !data.media) {
      data = {
        media: { type: 'image', resources: { image: data.image } },
        ...(_.omit(rest, 'image'))
      };
    }

    // PB-88: Migrate figure caption to image / media
    if (data.caption) {
      const caption = data.caption;
      const type = data.media?.type;

      if (type === 'image') {
        data.media.resources.image.caption = caption;
      } else {
        data.media.resources.caption = caption;
      }

      delete data.caption;
    }

    return data;
  },
};

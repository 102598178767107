import { css } from '@emotion/react';
import Theme from '../../themes';

const styles = {};

styles.splash = {};
styles.logo = {};

/* Splash Page */
styles.logo.container = css`
  margin: 20px auto 40px;
  display: flex;
`;

styles.splash.hero = css`
  @media(min-width: 768px) {
    background: url(https://imagecdn.owenscorning.com/ocimages/image/upload/q_auto,w_150/use-everywhere/panther/hero-panther-point-right.png) no-repeat top left;
    padding-left: 180px;
  }
`;

styles.splash.highlights = css`
  list-style-type: none;
  margin: 35px 0;
  padding: 0;

  li {
    margin: 0 0 15px;

    @media(min-width: 768px) {
      margin: 0 0 35px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    p {
      margin: 0 0 15px;

      @media(min-width: 768px) {
        margin: 5px 0 20px;
      }

      @media(min-width: 1200px) {
        margin: 10px 0 20px;
      }
    }
  }
`;

styles.contentSection = css`
  margin-top: 50px;
`;

styles.loading = css`
  align-items: center;
  background-color: rgba(255, 255, 255, .8);
  bottom: 0;
  display: flex;
  font-size: 18px;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1000;

  i {
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-name: spin;
    animation-timing-function: linear;
    color: ${Theme.colors.brand};
    margin-right: 10px;
  }

  @keyframes spin {
      from {
          transform:rotate(0deg);
      }
      to {
          transform:rotate(360deg);
      }
  }
`;

styles.contentContainer = css`
  position: relative;
`;

export default styles;

import PropTypes from 'prop-types';
import { useState } from 'react';

import { cardStyles, revertOriginalButtonStyles } from './styles';
import useReference from '../../../../../hooks/use_reference';
import connectStyleBoard from '../../../../../redux/connectors/StyleBoardConnector';
import Grid from '../../../../OC/PageBuilder/Grid';
import Card from '../../../../OC/oc-card-new';
import { RoofingShingle } from '../RoofingShingle';

export const SHINGLES_COUNT = 7;

const RoofingShingles = connectStyleBoard(
  ({ shingles, mainShingleColor, colorSelected, setColorSelected }) => {
    const [listOpen, setListOpen] = useState(false);
    const mainShingle = useReference(mainShingleColor);

    if (!shingles.length) return null;

    const isMainShingleSelected =
      !colorSelected ||
      colorSelected.results.data.shingle_colors[0].name ===
        mainShingle.results.data.shingle_colors[0].name;

    const shinglesList =
      listOpen || shingles.length <= SHINGLES_COUNT
        ? shingles
        : shingles.slice(0, SHINGLES_COUNT - 1);

    return (
      <>
        {!isMainShingleSelected && (
          <button
            type="button"
            onClick={() => setColorSelected(null)}
            className={revertOriginalButtonStyles}
          >
            Revert to original Style Board
            <i className="chevron" />
          </button>
        )}
        <p>Customize this Style Board</p>
        <h2 className="other-shingle-colors">Choose Your Shingle Color</h2>
        <Grid selectedSize={SHINGLES_COUNT} className={cardStyles}>
          {shinglesList.map((shingle, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <RoofingShingle key={index} index={index} shingle={shingle} />
          ))}
          {shingles.length > SHINGLES_COUNT && (
            <Card
              key="more-shingles"
              className={`more-shingles-card ${listOpen ? 'show-less' : ''}`}
              aspectRatio={{ selection: '1.0' }}
            >
              <button type="button" onClick={() => setListOpen(!listOpen)}>
                {!listOpen && <p>+{shingles.length - SHINGLES_COUNT + 1}</p>}
                {listOpen && (
                  <p>
                    Show <br />
                    Less
                  </p>
                )}
              </button>
            </Card>
          )}
        </Grid>
      </>
    );
  }
);

RoofingShingles.propTypes = {
  shingles: PropTypes.arrayOf(
    PropTypes.shape({
      results: PropTypes.shape({
        data: PropTypes.shape({
          shingle_colors: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
              swatch_768x768_url: PropTypes.string.isRequired,
            })
          ),
        }),
      }),
    })
  ).isRequired,
  mainShingleColor: PropTypes.shape({
    __ref: PropTypes.string,
  }),
  colorSelected: PropTypes.shape({
    results: PropTypes.shape({
      data: PropTypes.shape({
        shingle_colors: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            swatch_768x768_url: PropTypes.string.isRequired,
          })
        ),
      }),
    }),
  }),
  setColorSelected: PropTypes.func.isRequired,
};

RoofingShingles.defaultProps = {
  colorSelected: null,
};

export default RoofingShingles;

import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import mdms_api from '../mdms_api';

export const NOT_STARTED = 'not_started';
export const LOADING = 'loading';
export const SUCCESS = 'success';
export const ERROR = 'error';

/**  This is a custom hook that wraps the mdms_api.get() function.
 * so you can replace all the "then" and "catch" boilerplate with this hook
 * const { status, code, data } = useMdmsApi(url, fetchCondition);
 * if the url changes, it will fetch the new url
 * it waits for the condition to be true before fetching
 * (this can be used for debouncing, waiting for user input, or waiting for something async)
 */
const useMdmsApi = (url, _condition) => {
  const condition = !!_condition;
  const [status, setStatus] = useState(NOT_STARTED);
  const [code, setCode] = useState(null);
  const [data, setData] = useState(null);

  const useMdmsApiInternal = () => {
    setStatus(LOADING);
    console.log(`useMdmsApi fetching: ${url}`);
    mdms_api
      .get(url)
      .then(response => {
        console.log(`useMdmsApi response.status: ${response.status}`);
        if (response.status === 200) {
          response.json().then(json => {
            console.log('useMdmsApi response.json()', json);
            ReactDOM.unstable_batchedUpdates(() => {
              setCode(response.status);
              setData(json);
              setStatus(SUCCESS);
            });
          });
        } else if (response.status === 500) {
          ReactDOM.unstable_batchedUpdates(() => {
            setCode(response.status);
            setStatus(ERROR);
          });
        } else {
          setCode(response.status);
        }
      })
      .catch(error => {
        console.log(error);
        setStatus(ERROR);
      });
  };

  useEffect(() => {
    if (condition) {
      useMdmsApiInternal();
    }
  }, [url, condition]);

  return { status, code, data };
};

export default useMdmsApi;

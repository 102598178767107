import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Theme from '../../../../themes';

const Icon = styled.div`
  width: 17px;
  height: 17px;
  position: relative;
  display: inline-block;
  top: 2px;
  left: -5px;
  margin-right: 6px;
  margin-top: -2px;
  background-image: url(${ (props) => props.icon });
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

export default ({ primary = false, text, onClick, disabled = false, icon, styling, ...props }) => (
  <div
    onClick={ () => { !disabled && onClick?.() } }
    key={ text }
    css={[
      css`
        background-color: ${ primary ? Theme.colors.brand : 'transparent' };
        border: 1px solid transparent;
        color: ${ primary ? '#FFF' : '#000' };
        cursor: pointer;
        height: 38px;
        margin: 0 16px;
        padding: 9px 16px;
        transition: transform 0.1s;
        user-select: none;
      `,
      disabled ? css`
        opacity: 0.5;
        pointer-events: none;
      ` : css`
        &:hover {
          ${ !primary ? 'border: 1px solid #000;' : 'transform: scale(1.07);' }
        }
      `,
      ..._.castArray(styling)
    ]}
  >
    { icon && <Icon icon={ icon } /> } { text }
  </div>
);

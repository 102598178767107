import { ui } from '@owenscorning/pcb.alpha';
import Modular from './Modular';
import _ from 'lodash';

export default Modular(
  'Reusable', 'Page',
  {
    usage: 'name',
    displayName: 'Reusable',
    list: (UI) => ({
      sections: ui`List`.of({ modules: Modular.Module.List(UI) })({
        min: 1,
        max: 1,
        title: 'details/name',
        immutable: true,
        dropdown: false,
        singular: 'Section',
        actions: [
          {
            label: 'Settings',
            icon: 'cogs',
            onClick: Modular.Section
          }
        ]
      })
    }),
    settings: {},
    // read migration
    // we only ensure there is at least one empty section
    // currently this is because creating new is a server action which initializes to empty contents so "default" for the list above won't work (yet)
    read: ({ contents, metadata }) => (
      {
        contents: {
          sections: ((contents?.sections || []).length === 0 ? [{component: 'Section', modules: []}] : contents.sections)
        },
        metadata
      }
    )
  }
);

import { jsx, css } from '@emotion/react';
import Icon from './oc-icon'

const ICONS = {
  '.pdf': 'file-pdf-o',
  '.doc': 'file-word-o',
  '.docx': 'file-word-o',
  '.xls': 'file-excel-o',
  '.xlsx': 'file-excel-o',
  '.zip': 'file-archive-o'
}
const FILETYPES = {
  '.pdf': 'PDF',
  '.doc': 'DOC',
  '.docx': 'DOC',
  '.xls': 'XLS',
  '.xlsx': 'XLS',
  '.zip': 'ZIP'
}
const DEFAULT_ICON = 'file-o'

const icon = (extension) => (
  extension ? ICONS[extension] || 'file-o' : 'external-link'
)
const size = (sizeBytes) => {
  let humanSize
  if(sizeBytes > 1000000) {
    humanSize = (parseFloat(sizeBytes) / 1048576).toFixed(1) + ' MB'
  } else {
    humanSize = parseInt(sizeBytes / 1024) + ' KB'
  }

  if(sizeBytes) {
    return `| ${humanSize}`
  }
}
const fileType = (extension) => (
  FILETYPES[extension] || (extension && extension.replace('.', '').toUpperCase())
)

const FileBadge = ({ extension, sizeBytes }) => {
  return (
    <span className="oc-file-badge">
      <Icon type={icon(extension)} /> {fileType(extension)} {size(sizeBytes)}
    </span>
  );
};

export default FileBadge;

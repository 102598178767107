import { define, ui, s } from '@owenscorning/pcb.alpha';
import ReactCrop from 'react-image-crop';
import styled from "@emotion/styled";
import { Cloudinary } from 'cloudinary-core';
import { apiGet } from '../../PageBuilder/helpers/api';
import { debounce } from 'lodash';

import 'react-image-crop/dist/ReactCrop.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import NumberInput from '../../ComponentLibrary/input-elements/oc-numeric-input';
import Checkbox from '../../ComponentLibrary/input-elements/oc-checkbox';
import Label from '../../ComponentLibrary/input-elements/oc-label';

const CropContainer = styled.div`
  width: 100%;
`

const FieldContainer = styled.div`
  display:inline-block;
  width:48%;
  vertical-align: top;
`

const CropInput = ({ label, crop, setCrop, field, aspect }) => (
  <NumberInput
    label={ label }
    step={ 1 }
    value={ crop?.[field]||0 }
    onChange={
      e => {
        if ((field === 'width' || field === 'height') && aspect) {
          if (field === 'width') {
            const newWidth = parseInt(e.target.value);
            setCrop({
              ...crop,
              width: newWidth,
              height: parseInt(1.0 * newWidth / aspect)
            })
          } else {
            const newHeight = parseInt(e.target.value);
            setCrop({
              ...crop,
              width: parseInt(1.0 * newHeight * aspect),
              height: newHeight
            })
          }
        } else {
          setCrop({ ...crop, [field]: parseInt(e.target.value) })
        }
      }
    }
  />
)

const Cropper = ({ value, image, startAspectLocked = false, onSubmit }) => {
  const containerRef = useRef();
  const [scaledImage, setScaledImage] = useState();
  const [scaleFactor, setScaleFactor] = useState(1.0);
  const [useAspect, setUseAspect] = useState(startAspectLocked)
  const [crop, setCrop] = useState(value)
  const [pixelCrop, setPixelCrop] = useState(value)
  useEffect(() => {
    if (containerRef.current) {
      // TODO if it's a tall image may need to adjust smaller for height to fit on screen too
      const width = parseInt(0.7 * containerRef.current.offsetWidth);

      if (!image) {
        return;
      }

      const [root, path] = image?.split('/image/upload/') || []
      const [cloudname, unused] = root?.split('/').reverse() || []

      const cloudinary = cloudname ? new Cloudinary({
        cloud_name: cloudname,
        secure: true
      }) : null;

      const public_id = path.startsWith('v') ? path.split('/').slice(1).join('/') : path;
      apiGet(`/api/v1/cms/sites/${PB_SITE}/assets/image/info?cloudinary_public_id=${ public_id }`).then(info => {
        if (crop?.width === undefined || crop.width <= 0 || crop?.height === undefined || crop.height <= 0) {
          setCrop({ ...crop, width: info.width, height: info.height });
        }
        if (info.width > width) {
          const scale = (1.0 * width) / info.width;
          setScaleFactor(scale)
          if (value) {
            setPixelCrop({
              unit: 'px',
              x: scale * (value.x || 0),
              y: scale * (value.y || 0),
              width: scale * (value.width || 0),
              height: scale * (value.height || 0),
            })
          }
          setScaledImage(cloudinary?.url(path, {
            transformation: [{
              crop: 'scale',
              width: scale * info.width,
              quality: 'auto',
              fetchFormat: 'auto'
            }], type: 'upload'
          }))
        } else {
          if (value) {
            setPixelCrop({
              unit: 'px',
              ...value
            })
          }
          setScaledImage(cloudinary?.url(path, {
            transformation: [{
              quality: 'auto',
              fetchFormat: 'auto'
            }], type: 'upload'
          }))
        }
      });
    }
  }, [containerRef, image])
  const setNumberCrop = (val) => {
    setCrop(val);
    setPixelCrop({
      unit: 'px',
      x: scaleFactor * (val.x || 0),
      y: scaleFactor * (val.y || 0),
      width: scaleFactor * (val.width || 0),
      height: scaleFactor * (val.height || 0),
    });
  }
  const currentAspect = (crop ? (1.0 * crop.width) / (1.0 * crop.height) : 1.0)
  return (
    <>
      <UI.Rows paddingTop-2 gap-2>
        <UI.Row fits-3 gap-2>
          <UI.Box>
            <CropInput
              label="Width"
              field="width"
              crop={ crop }
              setCrop={ setNumberCrop }
              aspect={ useAspect ? currentAspect : undefined }
            />
          </UI.Box>
          <UI.Box>
            <CropInput
              label="Height"
              field="height"
              crop={ crop }
              setCrop={ setNumberCrop }
              aspect={ useAspect ? currentAspect : undefined }
            />
          </UI.Box>
          <UI.Box>
            <Label text="Lock Aspect" disabled={ !crop || crop.width === 0 || crop.height === 0 } />
            <Checkbox checked={ useAspect } disabled={ !crop || crop.width === 0 || crop.height === 0 } onChange={ () => setUseAspect(!useAspect) }><UI.Icon type={ useAspect ? 'lock' : 'unlock' } /></Checkbox>
          </UI.Box>
        </UI.Row>
        <UI.Row fits-3 gap-2>
          <UI.Box><CropInput label="X Position" field="x" crop={ crop } setCrop={ setNumberCrop } /></UI.Box>
          <UI.Box><CropInput label="Y Position" field="y" crop={ crop } setCrop={ setNumberCrop } /></UI.Box>
        </UI.Row>
        <UI.Row center>
          <CropContainer ref={ containerRef }>
            {
              !!scaledImage ?
                <ReactCrop
                  crop={ pixelCrop }
                  keepSelection
                  onChange={
                    newCrop => {
                      setPixelCrop(newCrop);
                      setCrop({
                        x: parseInt((1.0*newCrop.x) / scaleFactor),
                        y: parseInt((1.0*newCrop.y) / scaleFactor),
                        width: parseInt((1.0*newCrop.width) / scaleFactor),
                        height: parseInt((1.0*newCrop.height) / scaleFactor),
                      })
                    }
                  }
                  aspect={ useAspect ? currentAspect : undefined }
                >
                  <img src={ scaledImage } alt="crop preview" style={{ maxWidth: 'inherit' }} />
                </ReactCrop> :
                <><UI.Icon type="spinner" spin large /> Loading...</>
            }
          </CropContainer>
        </UI.Row>
        <UI.Row>
          <UI.CTA onClick={ () => {
            onSubmit({
              width: parseInt(crop?.width||0),
              height: parseInt(crop?.height||0),
              x: parseInt(crop?.x||0),
              y: parseInt(crop?.y||0),
            })
            Board.modal.close()
          } }>
            Submit
          </UI.CTA>
        </UI.Row>
      </UI.Rows>
    </>
  )
}

export default define`Cropper`('0.0.1')({
  edit: [
    ({ value, image, startAspectLocked, onChange }) => (
      <>
        <UI.CTA
          text="Edit Crop"
          onClick={
            () => {
              Board.modal.open({
                body: <Cropper image={ image } startAspectLocked={ startAspectLocked } onSubmit={ onChange } />,
                buttonless: true
              }, (data) => onChange(data), value)
            }
          }
        />
      </>
    ),
    {
      label: 'Crop'
    }
  ],
});


const getRequiredSuffix = function(optionalFocused = false, required, elementType) {
  if (optionalFocused) {
    if (elementType === "object" || required) {
      return null;
    }
    return (<span title="Optional" className="optional">Optional</span>);
  } else {
    if (required) {
      return (<span title="Required">*</span>);
    }
    return null;
  }
}

export { getRequiredSuffix }

import { define, ui } from '@owenscorning/pcb.alpha';

export default define`Meta`('0.0.1')({
  view: (props) => (
    <></>
  ),
  edit: ui`Form`.of({
    title: ui`Text`({ label: 'Page Title' }),
    keywords: ui`Text`({ label: 'Meta Keywords' }),
    description: ui`Text`({ label: 'Meta Description', maxlength: 155 }),
    noIndex: ui`Switch`({ label: 'No Index Tag' }),
    noFollow: ui`Switch`({ label: 'No Follow Tag' })
  })
});

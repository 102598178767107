import { jsx, css } from '@emotion/react';
import { define, ui } from '@owenscorning/pcb.alpha';

import Renderer from './../../../../OC/PageBuilder/BasicContent.jsx';


export default define`Basic Content`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/BasicContent`,
  category: ui`Modules/Categories/Page/Customizable`,
  gutterlessForReusable: true,
  view: ({ value }) => <Renderer { ...value } />,
  edit: { content: ui`Content/Basic`({ label: 'Content', startsOpen: true }) },
});

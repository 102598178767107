import { define } from '@owenscorning/pcb.alpha';
import Cookies from 'js-cookie';

export default define`Cookies`('0.0.1')({
  raw: {
    All: () => Cookies.get(),
    Set: (name, value, parameters) => Cookies.set(name, value, parameters),
    Get: (name) => Cookies.get(name),
    Delete: (name) => Cookies.remove(name)
  }
});

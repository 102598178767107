import styled from '@emotion/styled';
import _ from 'lodash';
import { useMemo } from 'react';
import queryString from 'qs';

// Reference fetching
import useReference from '../../../../../hooks/use_reference';
import { unwrapRef, wrapRef } from '../../../../../data';

// Components
import { H3 } from '../../../../ComponentLibrary/text-elements/oc-h';
import itemListResponse from './item_list_response';
import CheckboxSelector from '../../../../ComponentLibrary/filter-list/oc-filters';
import Image from '../../../../ComponentLibrary/oc-image';
import { Mobile, Tablet, Desktop } from '../../../../OC/PageBuilder/internals/Devices';
import { Screens } from '../../../../PageBuilder/editing/Viewport';
import { BasicContent } from '../../../../OC/PageBuilder/BasicContent';
import FilterListResults from '../../../../ComponentLibrary/filter-list/oc-filter-list-results';
import BimResultBlock from '../../../../ComponentLibrary/filter-result-blocks/oc-bim-result-block';

// Icons
import IconRevitFile from '../../../../ComponentLibrary/icons/icon-revit-file';

function addIfUnique(array, property, propertyIsObject = true) {
  if (propertyIsObject) {
    if (array.filter((existingProperty) => existingProperty.value === property.value).length < 1) {
      array.push(property);
    }
  } else {
    if (array.indexOf(property) == -1) {
      array.push(property);
    }
  }
}

const Sizes = {
  'xl': { [Tablet]: 738, [Desktop]: 1170 },
  'l': { [Tablet]: 738, [Desktop]: 970 },
  'm': { [Tablet]: 482, [Desktop]: 770 },
};

// Styled Components
const FullBox = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

const ModalHeader = styled(FullBox)`
  border-bottom: 1px solid #6D6D6D;
  margin-bottom: 24px;
  padding: 8px 40px 16px 0;

  h3 {
    margin-bottom: 0;
  }
`;

const ResultContainer = styled(FullBox)`
  border-bottom: 1px solid #6D6D6D;
  margin-bottom: 24px;
  padding-bottom: 4px;

  .title-callout {
    display: none;
  }
`;

const ItemListWrapper = styled.div`
  display: flex;
`;

const ItemListSet = styled.div`
  margin: 0;
  width: ${({ width }) => `${width.Mobile}px`};
  @media (min-width: ${Screens.Tablet}px) { width: ${({ width }) => `${width.Tablet}px`}; }
  @media (min-width: ${Screens.Desktop}px) { width: ${({ width }) => `${width.Desktop}px`}; }
`;

const ModalResult = styled(FullBox)``;

const ModalResultHeader = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  position: relative;
`;

const ResultImage = styled(Image)`
  display: inline-block;
  flex-shrink: 0;
  height: 60px;
  position: relative;
  width: 60px;
`;

const ResultPrehead = styled.span`
  display: inline-block;
  margin-bottom: 4px;
`;

const ResultTitle = styled(H3)`
  margin-bottom: 0px;
`;

const ModalResultLinks = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const DownloadLink = styled.a`
  align-items: center;
  display: inline-flex;
  padding-bottom: 8px;
  padding-top: 8px;
  position: relative;
  text-decoration: none;

  &:hover {
    svg {
      fill: #D40F7D;
    }
  }
`;

const IconContainer = styled.span`
  display: inline-block;
  height: 16px;
  margin-right: 8px;
  position: relative;
  width: 16px;

  svg {
    height: 100%;
    width: 100%;
  }
`;

const LinkMeta = styled.span`
  font-size: 12px;
  font-weight: 300;
  line-height: 17px;
  margin-left: 8px;
`;

const NoResult = () => <div />

const calculateFilters = (items) => {
  const filters = {
    depth: [],
    edgeType: [],
    widthLength: [],
    revitFamily: [],
    revitVersion: []
  }

  items.forEach((item) => {
    addIfUnique(filters.depth, { value: item.depth, displayName: item.depth});
    addIfUnique(filters.edgeType, { value: item.edgeType, displayName: item.edgeType});
    addIfUnique(filters.widthLength, { value: item.widthLength, displayName: item.widthLength });
    item.assets.forEach((asset) => {
      addIfUnique(filters.revitFamily, { value: asset.revitFamily, displayName: asset.revitFamily});
      addIfUnique(filters.revitVersion, { value: asset.revitVersion, displayName: asset.revitVersion});
    })
  });

  return filters;
};

const processBaseItems = (items) => {
  const baseItems = _.cloneDeep(items);

  baseItems.forEach((item) => {
    item.depth = item.depth?.toString();
    item.widthLength = `${item.width} x ${item.length}`;
    item.revitVersion = [];
    item.revitFamily = [];
    item.assets.forEach((asset) => {
      addIfUnique(item.revitFamily, asset.revitFamily, false);
      addIfUnique(item.revitVersion, asset.revitVersion, false);
    })
  });

  return baseItems;
}

const BaseItemComponent = ({ appliedFilters, ...baseItem }) => {
  const applyFiltersToAssets = (assets, filters) => {
    const assetFilters = _.pick(appliedFilters, ['revitFamily', 'revitVersion']);
    return (assets || []).filter((asset) => {
      return Object.keys(assetFilters).every(attribute => appliedFilters[attribute].includes(asset[attribute]));
    })
  };

  const assets = applyFiltersToAssets(baseItem.assets, appliedFilters);

  return (
    <ModalResult>
      <ModalResultHeader>
        <ResultImage src={baseItem.image} transform={{crop: "pad", aspectRatio: "1:1"}} />
        <FullBox>
          <ResultPrehead>{baseItem.productName}</ResultPrehead>
          <ResultTitle font="body">{baseItem.depth} x {baseItem.widthLength}, {baseItem.edgeType}</ResultTitle>
        </FullBox>
      </ModalResultHeader>

      <ModalResultLinks>
        {assets.map((asset) => (
          <DownloadLink
            key={asset.id}
            href={asset.url}
            target="_blank"
            data-track="file-download"
            data-track-file-type={`.${asset.extension}`}
            data-track-file-name={asset.name}
            data-track-doc-type="BIM Object"
          >
            <IconContainer><IconRevitFile /></IconContainer>
            {asset.revitFamily} | Revit {asset.revitVersion}
            <LinkMeta>{asset.extension?.toUpperCase()}</LinkMeta>
          </DownloadLink>
        ))
        }
      </ModalResultLinks>
    </ModalResult>
  )
}

const BimProductGroupModal = ({ productGroup }) => {
  const size = 'xl';
  const Width = Sizes[size];
  const topLevelFilters = (({
    depth,
    edgeType,
    widthLength,
    revitFamily,
    revitVersion,
    ...paramsFilters
  }) => paramsFilters)(queryString.parse(new URL(document.location).search, {ignoreQueryPrefix: true}));
  const reference = wrapRef(
    'Cms::DataProvider',
    {
      dataset: 'bim_base_items',
      parameters: { id: productGroup.id, filters: topLevelFilters },
      language: Board.build.language
    }
  );
  const { results, error, loading } = useReference(reference);
  const baseItems = useMemo(
    () => processBaseItems(results?.data || []), [results]
  );
  const baseFilters = useMemo(
    () => calculateFilters(baseItems), [baseItems]
  );

  const filters = {
    depth: {
      component: CheckboxSelector,
      displayLimit: 5,
      displayName: 'Panel Thickness (in.)',
      options: baseFilters.depth,
      valueType: 'singular',
    },
    edgeType: {
      component: CheckboxSelector,
      displayLimit: 5,
      displayName: 'Edge Type',
      options: baseFilters.edgeType,
      valueType: 'singular',
    },
    widthLength: {
      component: CheckboxSelector,
      displayLimit: 5,
      displayName: 'Width & Length (in.)',
      options: baseFilters.widthLength,
      valueType: 'singular',
    },
    revitFamily: {
      component: CheckboxSelector,
      displayLimit: 5,
      displayName: 'Revit Family Type',
      options: baseFilters.revitFamily,
      valueType: 'multiple',
    },
    revitVersion: {
      component: CheckboxSelector,
      displayLimit: 5,
      displayName: 'Revit Version',
      options: baseFilters.revitVersion,
      valueType: 'multiple',
    },
  }

  const dataset = itemListResponse({
    items: baseItems,
    filters,
    Component: BaseItemComponent,
  });

  const Component = dataset.Component;

  return(
    <>
      <ModalHeader>
        <H3 font='body'>Download BIM Object</H3>
      </ModalHeader>

      <ResultContainer>
        <BimResultBlock {...productGroup} ctaOnClick={null} pdpLink={null} />
      </ResultContainer>

      <ItemListWrapper>
        <ItemListSet width={Width} size={size}>
          <BasicContent/>
          <UI.Row>
            <FilterListResults
              resultComponent={(props) => <Component {...props} productImage={productGroup.image} productName={productGroup.label} />}
              noResultComponent={NoResult}
              {...dataset}
              loading={loading}
              updatesPathFromParams={false}
            />
          </UI.Row>
        </ItemListSet>
      </ItemListWrapper>
    </>
  );
};

export default BimProductGroupModal;

import { jsx, css } from '@emotion/react';
import { define, ui } from '@owenscorning/pcb.alpha';

import Renderer from './../../../../OC/PageBuilder/NGXCalculator.jsx';


export default define`NGX Calculator`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/NGXCalculator`,
  category: ui`Modules/Categories/Page/Prebuilt`,
  view: ({ value }) => <Renderer { ...value } />,
  edit: {
    copy: ui`Content/Basic`({ label: 'Content' })
  },
});

import { useRef, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import RootListItem from './oc-root-list-item';
import ParentListItem from './oc-parent-list-item';
import ChildListItem from './oc-child-list-item';
import List from './oc-list';
import ContentGroupDropdown from '../../ComponentLibrary/oc-content-group-dropdown';

const optionShape = {
  value: PropTypes.string,
  name: PropTypes.string,
};
optionShape.children = PropTypes.arrayOf(PropTypes.shape(optionShape));
const optionPropType = PropTypes.shape(optionShape);

const remapOptionByValue = (remappedByValue, option, parent, depth) => {
  remappedByValue[option.value] = { ...option, depth, parent };
  if (option.children) {
    option.children.forEach(c => remapOptionByValue(remappedByValue, c, remappedByValue[option.value], depth+1))
  }
}

// create a lookup of value to option, including a parent traversal
const remapOptionsByValue = (options) => {
  const remappedByValue = {};
  options.forEach(c => remapOptionByValue(remappedByValue, c, null, 1))
  return remappedByValue;
}

const HierarchicalFilter = (props) => {
  const rootRef = useRef();
  const [lastInteractionType, setLastInteractionType] = useState(null);

  const { name: rootText, value: rootValue, href: rootHref } = props.root || {};
  const { unwrapped, showEmpty, filterCounts = {} } = props;
  const hierarchyParts = (typeof props.value === 'string') ? props.value.split('/') : Array.wrap(props.value);
  const flatValue = hierarchyParts?.join('/');

  const remappedByValue = remapOptionsByValue(props.options)

  const selectedOption = remappedByValue[(props.value || props.defaultExpanded || '')];
  const depth = selectedOption?.depth || 0;
  const leaf = selectedOption;
  const parents = [];
  let visitor = selectedOption;
  while (visitor?.parent) {
    parents.unshift(visitor.parent);
    visitor = visitor.parent;
  }

  const leafHasChildren = leaf && leaf.children && leaf.children.length > 0;
  let children;

  if (leafHasChildren) {
    parents.push(leaf);
    children = leaf.children;
  } else if (parents.length > 0) {
    children = parents[parents.length - 1].children;
  } else {
    children = props.options;
  }

  const showRoot = flatValue && rootText;
  const parentsShown = parents.filter(a => (showEmpty || (typeof(filterCounts[a.value])==='undefined' || filterCounts[a.value] > 0))).length > 0
  const childrenShown = (children||[]).filter(a => (showEmpty || (typeof(filterCounts[a.value])==='undefined' || filterCounts[a.value] > 0))).length > 0

  if (!showRoot && !parentsShown && !childrenShown) { return null; }

  const Wrapper = unwrapped ? Fragment : ContentGroupDropdown

  return (
    <>
      <Wrapper label={props.title}>
        <List className={props.className}>
          {
            showRoot && (
              <RootListItem
                ref={rootRef}
                rootText={rootText}
                href={rootHref}
                onClick={ (event) => {
                  setLastInteractionType(event.type)
                  props.onChange(props.filterAttribute, rootValue)
                } }
                toFocusForAccessibility={props.groupHeadingRef}
                selected={flatValue}
              />
            )
          }
          {
            parents.map((a, i) => {
              const showParent = showEmpty || (typeof(filterCounts[a.value])==='undefined' || filterCounts[a.value] > 0);
              return showParent && (
                <ParentListItem
                  count={filterCounts[a.value]}
                  disableBackArrow={props.defaultExpanded === a.value}
                  hasChildren={Boolean(a.children)}
                  key={a.value}
                  lastInteractionType={lastInteractionType}
                  name={a.name}
                  onClick={
                    (event) => {
                      setLastInteractionType(event.type)
                      props.onChange(props.filterAttribute, a.value)
                    }
                  }
                  selected={flatValue === a.value}
                  title={props.title}
                  toFocusForAccessibility={rootRef}
                />
              )
            })
          }
          {
            children
            ? (
              children.map((child, index) => {
                const showChild = showEmpty || (typeof(filterCounts[child.value])==='undefined' || filterCounts[child.value] > 0);
                let disabled = filterCounts[child.value] === 0
                return showChild && (
                  <ChildListItem
                    count={filterCounts[child.value]}
                    key={child.value}
                    name={child.name}
                    nested={showRoot || parentsShown}
                    rootShown={showRoot}
                    onClick={
                      (event) => {
                        if (disabled) return
                        setLastInteractionType(event.type)
                        props.onChange(props.filterAttribute, child.value)
                      }
                    }
                    disabled={disabled}
                    title={props.title}
                    selected={flatValue === child.value}
                  />
                )
              })
            )
            : null
          }
        </List>
      </Wrapper>
    </>
  );
}

HierarchicalFilter.filterValueType = 'singular';
HierarchicalFilter.getChipInfo = (filter, filterValue) => {
  const remappedByValue = remapOptionsByValue(filter.options)
  if (remappedByValue[filterValue]) {
    return [{ ...remappedByValue[filterValue] }];
  }
}

HierarchicalFilter.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(optionPropType).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  showEmpty: PropTypes.bool,
  root: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    href: PropTypes.string
  })
};

export default HierarchicalFilter;

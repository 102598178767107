
import { createRef, Component } from 'react';
import { jsx, css } from '@emotion/react';

import { cmsEditorConnector } from '../redux/connector';
import pym from 'pym.js';

import Gutter from '../../OC/PageBuilder/internals/Gutter';
import Devices from "../../OC/PageBuilder/internals/Devices";

class Viewport extends Component {
  static Device = Devices;
  static Default = Viewport.Device.Desktop;

  static Screens = {
    [ Viewport.Device.Desktop ]: 1200,
    [ Viewport.Device.Tablet ]: 768,
    [ Viewport.Device.Mobile ]: 375
  };

  static Width(device, type) {
    return ( Viewport.Screens[device] ) + ( Gutter(type)[device] * 2 );
  }

  constructor(props) {
    super(props);

    this.containerIdentifier = 'viewport-iframe-container';
    this.containerReference = createRef();

    this.frameIdentifier = 'viewport-iframe';
    this.frameSource = `/en-us/cms/contents/edit/${ this.props.content_id }/${ this.props.language.toLowerCase() }/view/${ this.props.instance }`;

    this.resize = this.resize.bind(this);
    this.state = { ratio: 1.0 };
  }

  componentDidMount() {
    new pym.Parent(this.containerIdentifier, this.frameSource, { id: this.frameIdentifier });
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  componentDidUpdate(oldProps) {
    if (oldProps.device != this.props.device) this.resize();
  }

  get device() { return this.props.device || Viewport.Default; }

  get containerWidth() { return this.containerReference?.current?.getBoundingClientRect()?.width; }
  get frameWidth() { return Viewport.Width(this.device); }

  resize() {
    this.setState({ ratio: this.containerWidth ? this.containerWidth / this.frameWidth : 1.0 });
  }

  render() {
    return (
      <div css={css`
        padding: 0 24px 24px 0;
      `} >
        <div css={css`
          height: calc(100vh - 216px);
          width: calc(100vw - 468px);
          min-width: 462px;
          min-height: 154px;
          border: 1px solid #959595;
          background-color: #f8f8f8;
          position: relative;
          z-index: 20;
          overflow-x: hidden;
          overflow-y: auto;
        `} >
          <div>
            <div
              id={ this.containerIdentifier }
              ref={ this.containerReference }
              css={css`
                text-align: center;
                line-height: 0px;

                iframe {
                  width: ${ this.frameWidth }px;
                  min-height: calc(100vh - 218px);
                  display: inline-block;
                }

                ${ this.state.ratio < 1.0 && css`
                  height: 0px;

                  iframe {
                    display: block;
                    min-height: 0;
                    transform-origin: 0 0;
                    transform: scale(${ this.state.ratio });
                  }
                `}
              `}
            />
          </div>
        </div>
      </div>
    );
  }
};

const Screens = Viewport.Screens;
export { Screens };

export default cmsEditorConnector(Viewport);

import { define } from '@owenscorning/pcb.alpha';
import { Children, Component, createRef, isValidElement } from 'react';
import { jsx, css } from '@emotion/react';
import stripBoardProps from '../../props';

class Text extends Component {
  static Throttle = 50;

  get controlled() { return this.props.controlled; }
  get value() { return this.controlled ? this.props.value : this.state.value; }

  constructor(props) {
    super(props);
    this.state = { value: props.value, focused: false };
    this.reference = createRef();
  }

  render() {
    return <div css={ css` position: relative; ` } >
      { this.props.icon && <div css={ css` position: absolute; left: 11px; top: 15px; pointer-events: none; ` } >
        <UI.Icon type="calendar-o" color={ this.state.focused ? 'brand' : 'greyShade2' } />
      </div> }
      {
        ((Tag, { name, onChange, validated=true, ...props }) => (
          <Tag
            { ...stripBoardProps(props) }
            ref={ this.reference }
            id={ name }
            name={ name }
            className="inpt"
            type="text"
            value={ this.value }
            onChange={ (event) => {
              if (this.controlled && this.controlled === 'value') return onChange?.(event.target.value);
              if (this.controlled) return onChange?.(event);
              this.setState({ value: event.target.value });
              if (!this.throttle) this.throttle = setTimeout(
                () => { this.throttle = null; onChange?.(this.state.value); },
                Text.Throttle
              );
            } }
            onFocus={ () => this.setState({ focused: true }) }
            onBlur={ () => this.setState({ focused: false }) }
            css={css`
              padding: 14px 8px;
              ${ this.props.icon && css` padding-left: 36px; ` }
              width: 100%;
              color: ${ UI.Theme.colors.black };
              border: 1px solid ${ UI.Theme.colors.greyShade1 };
              font-size: 16px;
              ${ !validated && css` border-color: ${ UI.Theme.colors.redShade1 }; color: ${ UI.Theme.colors.redShade1 }; ` }
              &:focus { border-color: ${ UI.Theme.colors.brand }; }
              outline: none !important;
            `}
          />
        ))(
          this.props.multiline ? 'textarea' : 'input',
          this.props
        )
      }
    </div>;
  }
};

export default define`Text`('0.0.1')({
  prepare: () => ({
    edit: (props) => <Text { ...props } />,
    view: UI.Theme.Taggable(({ children }) => {
      const parsed = (child) => _.isString(child) ? <UI.Text.Parsing content={ child } /> : <UI.Text.Rich content={ child } />;
      return _.isString(children) || ( !isValidElement(children) && _.isPlainObject(children) )
        ? parsed(children)
        : Children.map(
          children,
          (child) => _.isString(child) || ( !isValidElement(child) && _.isPlainObject(child) )
            ? parsed(child)
            : child
        );
    })
  })
});

import React, { useMemo } from 'react';
import { Cloudinary, Transformation } from '@cloudinary/url-gen';
import { scale, fill, crop } from '@cloudinary/url-gen/actions/resize';
import { source } from '@cloudinary/url-gen/actions/overlay';
import { image } from '@cloudinary/url-gen/qualifiers/source';
import { text } from '@cloudinary/url-gen/qualifiers/source';
import { Position } from '@cloudinary/url-gen/qualifiers/position';
import { TextStyle } from '@cloudinary/url-gen/qualifiers/textStyle';
import { compass } from '@cloudinary/url-gen/qualifiers/gravity';

import {
  isUrlCloudinary,
  getComponentsFromCloudinaryUrl,
} from '../../helpers/cloudfront_helper';
import _ from 'lodash';
import styled from '@emotion/styled';

// Frame is used so that area doesn't shrink every time a new image is being placed
const Frame = styled.div`
  min-width: 100%;
  min-height: 623.85px;

  @media (max-width: 1199px) and (min-width: 768px) {
    min-height: 600px;
  }
  @media (max-width: 767px) {
    min-height: 290px;
  }
`;

export const CLOUDFRONT_IMAGE_CDN_DOMAIN = 'imagecdn.owenscorning.com';

const addOverlayImage = ({
  cloudinaryBaseImage,
  src,
  isUnderlay,
  gravity = 'center',
  height = 650,
  width = 500,
  quality = 'auto',
  position = { x: 12, y: 8 },
  allowOverflow = false,
  cropSettings = {},
  isTiled,
  basedImageWidth = 500,
  widthBased = false,
}) => {
  if (!src) return cloudinaryBaseImage;
  const generateImageSrc = () =>
    source(
      image(getComponentsFromCloudinaryUrl(src.file).public_id).transformation(
        new Transformation()
          .resize(scale(widthBased ? width : null, widthBased ? null : height))
          .quality(quality)
          .resize(
            cropSettings.width || cropSettings.height
              ? crop(cropSettings.width || null, cropSettings.height || null)
                  .gravity(compass(cropSettings?.gravity || 'center'))
                  .x(cropSettings?.x || 0)
                  .y(cropSettings?.y || 0)
              : ''
          )
      )
    );

  let newImage = cloudinaryBaseImage;
  const numberOfImages = isTiled ? Math.ceil(basedImageWidth / width) : 1;
  for (let i = 0; i < numberOfImages; i += 1) {
    const imageGenerated = generateImageSrc().position(
      new Position()
        .gravity(compass(gravity || 'center'))
        .offsetY(position?.y || 0)
        .offsetX((position?.x || 0) + i * width)
        .allowOverflow(allowOverflow)
    );
    if (isUnderlay) {
      newImage = cloudinaryBaseImage.underlay(imageGenerated);
    } else {
      newImage = cloudinaryBaseImage.overlay(imageGenerated);
    }
  }

  return newImage;
};

const addOverlayText = ({
  cloudinaryBaseImage,
  isUnderlay,
  isText,
  txt = ' ',
  fontSize = 18,
  gravity = 'north_west',
  position,
  textStyle = 'Satisfy',
  fontWeight = 'normal',
  allowOverflow = true,
  letterSpacing,
}) => {
  if (!isText) return cloudinaryBaseImage;
  const generateSource = () =>
    source(
      text(
        encodeURI(txt),
        new TextStyle(textStyle, fontSize)
          .fontWeight(fontWeight)
          .letterSpacing(letterSpacing || 0)
      )
    ).position(
      new Position()
        .gravity(compass(gravity))
        .offsetY(position?.y || 0)
        .offsetX(position?.x || 0)
        .allowOverflow(allowOverflow)
    );

  return isUnderlay
    ? cloudinaryBaseImage?.underlay(generateSource())
    : cloudinaryBaseImage?.overlay(generateSource());
};

const blank =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==';

export const useCloudinaryPictureOverlay = ({
  baseLayer = {},
  overlays = [],
}) => {
  const cld = useMemo(() => {
    return new Cloudinary({
      cloud: {
        cloudName: 'ocimages',
      },
      url: {
        secureDistribution: CLOUDFRONT_IMAGE_CDN_DOMAIN,
        secure: true,
      },
    });
  }, []);

  const imageUrl = useMemo(() => {
    if (!baseLayer?.src?.file || _.isEmpty(baseLayer?.src)) return blank;
    if (!isUrlCloudinary(baseLayer?.src?.file)) return blank;
    const img = cld
      .image(getComponentsFromCloudinaryUrl(baseLayer?.src?.file)?.public_id)
      .resize(
        fill()
          .width(baseLayer?.width || 0.5)
          .relative()
      )
      .resize(
        baseLayer?.cropSettings
          ? crop()
              .width(baseLayer.cropSettings.width || 0.5)
              .height(baseLayer.cropSettings.height || 0.5)
              .gravity(compass(baseLayer?.cropSettings?.gravity || 'center'))
          : baseLayer?.scaleSettings
          ? scale(
              baseLayer.scaleSettings.width || null,
              baseLayer.scaleSettings.height || null
            )
          : ''
      )
      .quality(baseLayer?.quality || 50);

    if (_.isEmpty(overlays) || !img) return img.toURL();

    overlays?.reduce(
      (acc, current) => {
        if (!current?.isText) {
          if (!isUrlCloudinary(current.src?.file)) return { image: img };
          return {
            image: addOverlayImage({
              cloudinaryBaseImage: acc?.image,
              ...current,
            }),
          };
        }
        if (current?.isText) {
          return {
            image: addOverlayText({
              cloudinaryBaseImage: acc?.image,
              ...current,
            }),
          };
        }
      },
      { image: img }
    );

    return img.toURL();
  }, [cld, baseLayer, overlays]);

  return {
    imageUrl,
  };
};

export const PictureOverlay = ({ baseLayer = {}, overlays = [], ...props }) => {
  const { imageUrl } = useCloudinaryPictureOverlay({ baseLayer, overlays });

  return (
    <>
      <Frame>
        <picture>
          {/* {
        !!parsed?.variants &&  parsed.variants.map((variant, i) => (
            <source
              key={ `${i}`+ JSON.stringify(parsed.base) }
              srcSet={ variant.url }
              media={ variant.media }
            />
          ))
        } */}
          {/* <img {...props} src={blankImage ? Picture.Blank : parsed.base} /> */}
          <img key={JSON.stringify(imageUrl)} src={imageUrl || blank} />
        </picture>
      </Frame>
    </>
  );
};

import {jsx, css} from '@emotion/react';
import { Component, useState } from 'react';
import styled from "@emotion/styled"
import NumberFormat from 'react-number-format';
import {get, set, cloneDeep, isEqual, isEmpty} from 'lodash';
import {ComparisonTable} from './ComparisonTable';
import {calculatorConnector} from './CelluloseConnectors.jsx';
import {calculatorTracker} from '../CalculatorTracker.jsx';

const XInputFormat = ({what, format, ...props}) => {
  let value = get(props, what);
  let updateFunction = (updateObj) => {
    props.setParam(what, parseFloat(updateObj.value));
    props.setCalculations({});
  }

  return (
    <NumberFormat onValueChange={updateFunction} { ...format } value={value}/>
  )
}
const InputFormat = calculatorConnector(XInputFormat);

const XData = ({what, calculations, format = {prefix: '$', thousandSeparator: true}, ...props}) => {
  let value = get(calculations, what);

  if(value === undefined)
    value = get(props, what);

  if(value){
    return <NumberFormat thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} displayType="text" {...format} value={value} />
  } else {
    return <span className="sr-only">no value</span>
  }

}

const Data = calculatorConnector(XData);

const calcLayout = css`
  display: grid;
  column-gap: 30px;
  row-gap: 24px;

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 2fr;
  }

  .oc-tooltip {
    color: #6d6d6d;

    .tooltip.right:before {
      transform-origin: 6px 9px;
    }
  }
`

const calcSidebar = css`
  background: #e6e6e6;
  padding: 25px 15px;

  @media (min-width: 768px) and (max-width: 1199px) {
    display: flex;

    > div {
      flex: 1;

      &:first-child {
        margin-right: 16px;
      }

      &:last-child {
        margin-left: 16px;
      }
    }
  }

  @media (max-width: 1199px) {
    margin-left: -15px;
    margin-right: -15px;
  }

  h3 {
    border-bottom: 1px solid #000;
    padding-bottom: 4px;
  }

  > div {
    margin-bottom: 24px;
  }

  input[type="radio"] + label {
    margin-right: 16px;
  }

  input[type="text"] {
    padding: 11px;
    width: 80px;
    text-align: right;
    font-size: 16px;
  }

  .center {
    text-align: center;
  }

  .blockSelect {
    display: block;
    padding: 11px;
    width: 100%;
  }
`

const dropdownField = css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  select {
    padding: 11px;
    font-size: 16px;
  }
`

const footnotes = css`
  list-style: none;
  counter-reset: localCounter;
  font-size: 12px;
  padding-left: 0;

  li {
    counter-increment: localCounter;
    margin-bottom: 3px;

    &::before {
      content: counter(localCounter);
      font-size: 8px;
      margin-right: 3px;
      position: relative;
      bottom: 2px;
    }
  }
`

const miniHeading = css`
  font-family: OCRoboto;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  text-transform: uppercase;
  margin-bottom: 4px;
  display: block;
`

const bigPink = css`
  font-family: Oswald;
  font-size: 34px;
  font-weight: 500;
  line-height: 34px;
  text-align: left;
  color: #D40F7D;
  text-transform: uppercase;
  margin-bottom: 12px;
  display: block;
`

const Fieldset = styled.fieldset`
  padding: 0;
  border: 0;
  margin: 0;

  legend {
    width: 100%;
  }
`

class XCalculatorLogic extends Component {
  constructor(props) {
  super(props);

    this.makeCalculations = this.makeCalculations.bind(this);
    this.makeCalculations();
  }

  makeCalculations() {
    let calculations = {};

    // Calculation order is NOT the same as the table order
    // Reason for this is that assumptions affect the computations

    // Braces are so that variables won't bleed out of the scope.

    // Assumptions
    // Material Cost  (assumption.material_cost)
    {
      let l77_material_cost =
          get(this.props, 'l77.cost_per_bag') /
          get(this.props, 'l77.coverage_per_bag');

      set(calculations, 'assumption.material_cost.l77', l77_material_cost);

      let cellulose_material_cost =
          get(this.props, 'cellulose.cost_per_bag') /
          get(this.props, 'cellulose.coverage_per_bag');

      set(calculations, 'assumption.material_cost.cellulose', cellulose_material_cost);
    }
    // Bags per day (assumption.bags_per_day)
    {
      set(calculations, 'assumption.bags_per_day.l77', 175)
      set(calculations, 'assumption.bags_per_day.cellulose', get(this.props, 'second_truck_used') ? 425 : 180)
    }
    // Bags per year (assumption.bags_per_year)
    {
      let l77 = get(calculations, 'assumption.bags_per_day.l77');
      set(calculations, 'assumption.bags_per_year.l77', l77 * 5 * 50)

      let cellulose = get(calculations, 'assumption.bags_per_day.cellulose')
      set(calculations, 'assumption.bags_per_year.cellulose', cellulose * 5 * 50)

    }
    // Coverage per day (assumption.coverage_per_day)
    {
      let l77 = get(calculations, 'assumption.bags_per_day.l77');
      let l77_cov = get(this.props, 'l77.coverage_per_bag');

      let cellulose = get(calculations, 'assumption.bags_per_day.cellulose');
      let cellulose_cov = get(this.props, 'cellulose.coverage_per_bag');

      set(calculations, 'assumption.coverage_per_day.l77', l77 * l77_cov);
      set(calculations, 'assumption.coverage_per_day.cellulose', cellulose * cellulose_cov);
    }
    // Coverage per year (assumption.coverage_per_year)
    {
      let l77 = get(calculations, 'assumption.bags_per_year.l77');
      let l77_cov = get(this.props, 'l77.coverage_per_bag');
      set(calculations, 'assumption.coverage_per_year.l77',  l77 * l77_cov);


      let cellulose_cov = get(this.props, 'cellulose.coverage_per_bag');
      let cellulose = get(calculations, 'assumption.bags_per_year.cellulose');
      set(calculations, 'assumption.coverage_per_year.cellulose',  cellulose * cellulose_cov);
    }
    // Laborers (assumption.laborers)
    {
      set(calculations, 'assumption.laborers.l77', 2)
      set(calculations, 'assumption.laborers.cellulose', 2)
    }
    // Hourly Rate (assumption.hourly_rate)
    {
      let l77 = get(this.props, 'l77.hourly_rate', 25)
      set(calculations, 'assumption.hourly_rate.l77', l77)

      let cellulose = get(this.props, 'cellulose.hourly_rate', 25)
      set(calculations, 'assumption.hourly_rate.cellulose', cellulose)

    }
    // Hours Worked per day (assumption.worked_per_day)
    {
      set(calculations, 'assumption.worked_per_day.l77', 8)
      set(calculations, 'assumption.worked_per_day.cellulose', get(this.props, 'second_truck_used') ? 8 : 6)
    }
    // Annual wages (assumption.annual_wages )
    {
      let l77_h = get(calculations, 'assumption.hourly_rate.l77')
      let l77_l = get(calculations, 'assumption.laborers.l77')
      let l77_wpd = get(calculations, 'assumption.worked_per_day.l77')
      set(calculations, 'assumption.annual_wages.l77', l77_h*l77_l*5*50*l77_wpd)

      let cellulose_h = get(calculations, 'assumption.hourly_rate.cellulose')
      let cellulose_l = get(calculations, 'assumption.laborers.cellulose')
      let cellulose_wpd = get(calculations, 'assumption.worked_per_day.cellulose')
      set(calculations, 'assumption.annual_wages.cellulose', cellulose_h*cellulose_l*5*50*cellulose_wpd)
    }
    // Labor Burden (assumption.labor_burden) - 30%
    {
      let l77 = get(calculations, 'assumption.annual_wages.l77')
      set(calculations, 'assumption.labor_burden.l77', l77*0.30)

      let cellulose = get(calculations, 'assumption.annual_wages.cellulose')
      set(calculations, 'assumption.labor_burden.cellulose', cellulose*0.30)
    }

    // Revenue
    // Coverage Per Year (revenue.cov_per_year)
    {
      let l77_c = get(this.props, 'l77.coverage_per_bag')
      let l77_b = get(calculations, 'assumption.bags_per_year.l77')
      set(calculations, 'revenue.cov_per_year.l77', l77_c*l77_b)

      let cellulose_c = get(this.props, 'cellulose.coverage_per_bag')
      let cellulose_b = get(calculations, 'assumption.bags_per_year.cellulose')
      set(calculations, 'revenue.cov_per_year.cellulose', cellulose_c*cellulose_b)
    }
    // Annual Revenue (revenue.annual)
    {
      let l77 = get(this.props, 'l77.your_sell_price');
      let l77_cpy = get(calculations, 'revenue.cov_per_year.l77')
      set(calculations, 'revenue.annual.l77', l77*l77_cpy)

      let cellulose = get(this.props, 'cellulose.your_sell_price');
      let cellulose_cpy = get(calculations, 'revenue.cov_per_year.cellulose')
      set(calculations, 'revenue.annual.cellulose', cellulose*cellulose_cpy)
    }
    // Costs
    // Material cost (cost.material)
    {
      let l77_bpy = get(calculations, 'assumption.bags_per_year.l77')
      let l77_cpb = get(this.props, 'l77.cost_per_bag')
      set(calculations, 'cost.material.l77', l77_bpy * l77_cpb)

      let cellulose_bpy = get(calculations, 'assumption.bags_per_year.cellulose')
      let cellulose_cpb = get(this.props, 'cellulose.cost_per_bag')
      set(calculations, 'cost.material.cellulose', cellulose_bpy * cellulose_cpb)
    }
    // Truck operating cost (cost.truck_operating)
    {
      set(calculations, 'cost.truck_operating.l77', 62500);

      let cellulose_t = get(this.props, 'second_truck_used')

      set(calculations, 'cost.truck_operating.cellulose', cellulose_t ? 125000 : 62500)
    }
    // Labor cost (cost.labor)
    {
      let l77_aw = get(calculations, 'assumption.annual_wages.l77')
      let l77_lb = get(calculations, 'assumption.labor_burden.l77')
      set(calculations, 'cost.labor.l77', l77_aw+l77_lb)

      let cellulose_aw = get(calculations, 'assumption.annual_wages.cellulose')
      let cellulose_lb = get(calculations, 'assumption.labor_burden.cellulose')
      set(calculations, 'cost.labor.cellulose', cellulose_aw+cellulose_lb)
    }
    // Additional Material Handling Costs (cost.additional_material_handling)
    {
      set(calculations, 'cost.additional_material_handling.l77', 0)

      let cellulose = get(calculations, 'cost.material.cellulose')
      set(calculations, 'cost.additional_material_handling.cellulose', cellulose * 0.02)
    }
    // Annual Total Costs (cost.annual_total)
    {
      let l77_mc = get(calculations, 'cost.material.l77')
      let l77_toc = get(calculations, 'cost.truck_operating.l77')
      let l77_lc = get(calculations, 'cost.labor.l77')
      let l77_amhc = get(calculations, 'cost.additional_material_handling.l77')
      set(calculations, 'cost.annual_total.l77', l77_mc + l77_toc + l77_lc + l77_amhc);

      let cellulose_mc = get(calculations, 'cost.material.cellulose')
      let cellulose_toc = get(calculations, 'cost.truck_operating.cellulose')
      let cellulose_lc = get(calculations, 'cost.labor.cellulose')
      let cellulose_amhc = get(calculations, 'cost.additional_material_handling.cellulose')
      set(calculations, 'cost.annual_total.cellulose', cellulose_mc + cellulose_toc + cellulose_lc + cellulose_amhc);

    }

    // Total Margin (total.margin)
    {
      let l77_r = get(calculations, 'revenue.annual.l77')
      let l77_c = get(calculations, 'cost.annual_total.l77')
      set(calculations, 'total.margin.l77', l77_r-l77_c)

      let cellulose_r = get(calculations, 'revenue.annual.cellulose')
      let cellulose_c = get(calculations, 'cost.annual_total.cellulose')
      set(calculations, 'total.margin.cellulose', cellulose_r-cellulose_c)
    }
    // Total Margin (percent)
    {
      let l77_m = get(calculations, 'total.margin.l77')
      let l77_r = get(calculations, 'revenue.annual.l77')

      set(calculations, 'total.margin_percent.l77', l77_r ? l77_m/l77_r*100 : 0);

      let cellulose_m = get(calculations, 'total.margin.cellulose')
      let cellulose_r = get(calculations, 'revenue.annual.cellulose')

      set(calculations, 'total.margin_percent.cellulose', cellulose_r ? cellulose_m/cellulose_r*100 : 0);
    }

    this.props.setCalculations(calculations);

    if(Object.keys(calculations).length && this.props.logData) {
      this.props.logData({
        rvalue:  get(this.props,'rvalue'),
        brand:  get(this.props,'brand'),
        second_truck_used:  get(this.props,'second_truck_used'),
        l77_cost_per_bag: get(this.props,'l77.cost_per_bag'),
        l77_your_sell_price: get(this.props,'l77.your_sell_price'),
        l77_hourly_rate: get(this.props,'l77.hourly_rate'),
        cellulose_cost_per_bag: get(this.props,'cellulose.cost_per_bag'),
        cellulose_your_sell_price: get(this.props,'cellulose.your_sell_price'),
        cellulose_hourly_rate: get(this.props,'cellulose.hourly_rate')
      });
    }
  }
  componentDidUpdate() {
    if(isEmpty(this.props.calculations))
      this.makeCalculations();
  }
  // This is logic only component, it doesn't render
  render() { return null; }

}
export const CalculatorLogic = calculatorTracker('cellulose', calculatorConnector(XCalculatorLogic));

class XCalculatorCellulose extends Component {

  render() {
    const other = this.props.data[this.props.brand];

    return (
      <>
        <div className="header">
          <h5 className="prehead">PROPINK® Savings Calculator</h5>
          <h2>
            Calculate your Potential
          </h2>

          <p>Simplify your life and build your business by making the smart choice. ProPink® L77 Loosefill Insulation brings increased coverage, faster applications and the highest rated R-value per square inch to every job. Use the calculator below to see the difference between ProPink® L77 and competitor products.</p>
        </div>
        <div css={calcLayout}>
          <CalculatorLogic />
          <Sidebar/>
          <div>
            <Revenue/>
            <Assumptions/>
            <Footnotes/>
          </div>
        </div>
      </>
     )
  }
}
class XSidebar extends Component {
  constructor(props) {
    super(props);
    this.updateBrand = this.updateBrand.bind(this);
    this.updateR = this.updateR.bind(this);
    this.updateTruckUsed = this.updateTruckUsed.bind(this);
  }

  updateBrand(brand) {
    this.props.setParams({
      brand: brand,
      cellulose: {
        coverage_per_bag: this.props.data[brand][this.props.rvalue],
        cost_per_bag: ''
      }
    })

    this.props.setCalculations({});
  }

  updateR(e) {
    this.props.setParams({
      rvalue: e.target.value,
      cellulose: {
        coverage_per_bag: this.props.data[this.props.brand][e.target.value],
        your_sell_price: ''
      },
      l77: {
        your_sell_price: '',
        coverage_per_bag: this.props.data['l77'][e.target.value]
      }
    })

    this.props.setCalculations({});
  }

  updateTruckUsed(e) {
    this.props.setParam('second_truck_used', e.target.value == 'true');
    this.props.setCalculations({});
  }

  render() {
    const dollarsFormat = {decimalScale: 2, fixedDecimalScale: true, prefix: '$'}
    return (
      <div className="sidebar" css={calcSidebar}>
        <div>
          <h3 className="body-font">Select Cellulose Brand to Compare</h3>
          <div css={dropdownField}>
            <label htmlFor="brand">Cellulose Brand</label>
            <select
              id="brand"
              value={this.props.brand}
              onChange={(e) => this.updateBrand(e.target.value)}
              data-track="select-by"
              data-track-select-category="Cellulose Brand"
              data-track-select-option={this.props.brand}
            >
              <option value="greenfiber">Greenfiber INS735</option>
              <option value="applegate">Applegate Loose-fill</option>
              <option value="nuwool">Nu-Wool</option>
            </select>
          </div>
          <br/>

          <div>
            <Fieldset>
              <legend><h3 className="body-font">Average Attic R-Value</h3></legend>

              <input id="R-19" name="rvalue" type="radio" onChange={this.updateR} checked={this.props.rvalue === "R-19" } value="R-19" data-track="toggle" data-track-option="R19" />
              <label htmlFor="R-19">R-19</label>

              <input id="R-30" name="rvalue" type="radio" onChange={this.updateR} checked={this.props.rvalue === "R-30" } value="R-30" data-track="toggle" data-track-option="R30" />
              <label htmlFor="R-30">R-30</label>

              <input id="R-38" name="rvalue" type="radio" onChange={this.updateR} checked={this.props.rvalue === "R-38" } value="R-38" data-track="toggle" data-track-option="R38" />
              <label htmlFor="R-38">R-38</label>

              <input id="R-49" name="rvalue" type="radio" onChange={this.updateR} checked={this.props.rvalue === "R-49" } value="R-49" data-track="toggle" data-track-option="R49" />
              <label htmlFor="R-49">R-49</label>

              <input id="R-60" name="rvalue" type="radio" onChange={this.updateR} checked={this.props.rvalue === "R-60" } value="R-60" data-track="toggle" data-track-option="R60" />
              <label htmlFor="R-60">R-60</label>
            </Fieldset>
          </div>
        </div>
        <div>
        <h3 className="body-font">Enter Values to Compare</h3>
        <p>Enter your local pricing for an accurate cost comparison.</p>
          <div>
            <table>
              <thead>
                <tr>
                  <th><span className="sr-only">Financial Variables</span></th>
                  <th>ProPink® L-77</th>
                  <th>Cellulose</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Coverage Per Bag at <span className="nowrap">{this.props.rvalue} (sq. ft.)</span></td>
                  <td className="center"><InputFormat format={{displayType: 'text'}} what="l77.coverage_per_bag"/></td>
                  <td className="center"><InputFormat format={{displayType: 'text'}} what="cellulose.coverage_per_bag"/></td>
                </tr>
                <tr>
                  <td>Cost Per Bag</td>
                  <td><InputFormat format={dollarsFormat} what="l77.cost_per_bag"/></td>
                  <td><InputFormat format={dollarsFormat} what="cellulose.cost_per_bag"/></td>
                </tr>
                <tr>
                  <td>Your Sell Price <span className="nowrap">(per sq. ft.)</span></td>
                  <td><InputFormat format={dollarsFormat} what="l77.your_sell_price"/></td>
                  <td><InputFormat format={dollarsFormat} what="cellulose.your_sell_price"/></td>
                </tr>
                <tr>
                  <td>Hourly Rate for Installers</td>
                  <td><InputFormat format={dollarsFormat} what="l77.hourly_rate"/></td>
                  <td><InputFormat format={dollarsFormat} what="cellulose.hourly_rate"/></td>
                </tr>
                <tr>
                  <td>Second Truck Used <div className="oc-tooltip"><i className="fa fa-info-circle" aria-hidden="true" /><div className="tooltip right">Due to the size of cellulose equipment and density of material in bags, it takes an additional truck to be able to blow cellulose for a full day compared to L77.</div></div></td>

                  <td className="center">N/A</td>
                  <td>
                    <select
                      className="blockSelect"
                      onChange={this.updateTruckUsed}
                      id=""
                      name=""
                      data-track="select-by"
                      data-track-select-category="Second Truck Used"
                      data-track-select-option={this.props.second_truck_used ? "Yes" : "No"}
                    >
                      <option value="false">No</option>
                      <option value="true" checked={this.props.second_truck_used}>Yes</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}
export const Sidebar = calculatorConnector(XSidebar);

export const Revenue = () => (
  <div>
    <ComparisonTable>
      <table className="threeUp">
        <thead>
          <tr>
            <th><span className="sr-only">Variables</span></th>
            <th className="colProPink">Owens Corning ProPink L77</th>
            <th>Cellulose</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="tableSubhead" colspan="3">Revenue</td>
          </tr>
          <tr>
            <td className="tableIndent">Coverage per year (sq. ft.)&sup2;</td>
            <td className="center"><Data format={{ decimalScale: 0 }} what="assumption.coverage_per_year.l77"/></td>
            <td className="center"><Data format={{ decimalScale: 0 }} what="assumption.coverage_per_year.cellulose"/></td>
          </tr>
          <tr>
            <td className="tableIndent">Sell Price (per sq. ft.)</td>
            <td className="center"><Data what="l77.your_sell_price"/></td>
            <td className="center"><Data what="cellulose.your_sell_price"/></td>
          </tr>
          <tr>
            <td className="tableIndent">Annual Revenue</td>
            <td className="center"><span className="pink"><Data what="revenue.annual.l77"/></span> <div className="oc-tooltip"><i className="fa fa-info-circle" aria-hidden="true" /><div className="tooltip right">More productivity means more revenue, even if you charged the same price per sq. ft.</div></div></td>
            <td className="center"><Data what="revenue.annual.cellulose"/></td>
          </tr>
          <tr>
            <td className="tableSubhead" colspan="3">Costs&sup3;</td>
          </tr>
          <tr>
            <td className="tableIndent">Material Cost&sup2;</td>
            <td className="center"><Data what="cost.material.l77"/></td>
            <td className="center"><Data what="cost.material.cellulose"/></td>
          </tr>
          <tr>
            <td className="tableIndent">Truck Operating Cost&#x2074;</td>
            <td className="center"><Data what="cost.truck_operating.l77"/></td>
            <td className="center"><Data what="cost.truck_operating.cellulose"/></td>
          </tr>
          <tr>
            <td className="tableIndent">Labor Cost&#x2075;</td>
            <td className="center"><Data what="cost.labor.l77"/></td>
            <td className="center"><Data what="cost.labor.cellulose"/></td>
          </tr>
          <tr>
            <td className="tableIndent">Additional Material Handling Costs (2%)&#x2076;</td>
            <td className="center"><Data what="cost.additional_material_handling.l77"/></td>
            <td className="center"><Data what="cost.additional_material_handling.cellulose"/> <div className="oc-tooltip"><i className="fa fa-info-circle" aria-hidden="true" /><div className="tooltip left">Because you're handling more bags of cellulose, there are more costs associated with their handling, storage, etc.</div></div></td>
          </tr>
          <tr>
            <td className="tableIndent"><b>Annual Total Costs</b>&sup3;</td>
            <td className="center"><Data what="cost.annual_total.l77"/></td>
            <td className="center"><Data what="cost.annual_total.cellulose"/></td>
          </tr>
          <tr>
            <td className="tableSubhead">TOTAL MARGIN</td>
            <td className="center colProPink">
              <Data what="total.margin.l77"/><br/>
              <Data format={{suffix: '%'}} what="total.margin_percent.l77"/>
            </td>
            <td className="center">
              <Data what="total.margin.cellulose"/><br/>
              <Data format={{suffix: '%'}} what="total.margin_percent.cellulose"/>
            </td>
          </tr>
        </tbody>
      </table>
    </ComparisonTable>

    <div>
      <p>
        <span css={miniHeading}>Total margin</span>
        <span css={bigPink}>
          Up to <Data what="total.margin.l77"/>
        </span>
        <Data format={{suffix: '%'}} what="total.margin_percent.l77"/>
      </p>
    </div>

  </div>
)

export const Footnotes = () => (
  <ol css={footnotes}>
    <li>Pricing obtained from publicly-available sources and is used for illustrative purposes only. Actual prices may vary depending on reseller and location. The prices used in the comparison chart are not to be construed as offer to sell.</li>
    <li>Annual calculations based on number of hours worked per day (as indicated in assumptions above), 5 days per week, 50 weeks per year. Coverage is based on the daily bag rate multiplied by the coverage per bag at the specified R-Value, 5 days a week, 50 weeks a year.</li>
    <li>Costs and net revenue exclude standard overhead.</li>
    <li>Annual truck operating cost based on market study conducted by Resource Development Partners, LLC, commissioned by Owens Corning – 2020</li>
    <li>Annual labor rates are based on the input hourly rate, number of hours worked per day (as indicated in assumptions above), 5 days per week, 50 weeks per year with a 30% labor burden. Labor Burden includes costs such as payroll taxes, employee benefits, and insurance.</li>
    <li>Includes warehouse and additional material handling costs, based on market study conducted by Resource Development Partners, LLC, commissioned by Owens Corning – 2020</li>
    <li>Assumes an average attic is approximately 1500 sq. ft.</li>
  </ol>
)

export const Assumptions = () => {

  let [ showAssumptions, changeAssumptionsState ] = useState(false);

  const assButton = css`
    color: black;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-size: 16px;
  `

  return (
    <div className="assumptions">
      <button data-track-accordion="Calculation Assumptions" data-track={`${showAssumptions ? 'conceal' : 'reveal'}`} css={assButton} onClick={() => {changeAssumptionsState(!showAssumptions)}}>
        <span className="button-content"><i className={`fa fa-${showAssumptions ? 'minus' : 'plus'}-circle`}></i> {showAssumptions ? "Hide" : "Show"} assumptions used in calculations</span>
      </button>
      {showAssumptions && <AssumptionsContent />}
    </div>
  )
}

export const AssumptionsContent = () => (
  <>
    <h4>Assumptions used in calculations</h4>
    <ComparisonTable>
      <table className="threeUp">
        <thead>
          <tr>
            <th><span className="sr-only">Variables</span></th>
            <th className="colProPink">Owens Corning ProPink L77</th>
            <th>Cellulose</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Material cost (per sq. ft.)</td>
            <td className="center"><Data what="assumption.material_cost.l77"/></td>
            <td className="center"><Data what="assumption.material_cost.cellulose"/></td>
          </tr>
          <tr>
            <td>Bags per day</td>
            <td className="center"><Data format={{ decimalScale: 0 }} what="assumption.bags_per_day.l77"/></td>
            <td className="center"><Data format={{ decimalScale: 0 }} what="assumption.bags_per_day.cellulose"/></td>
          </tr>
          <tr>
            <td>Bags per year</td>
            <td className="center"><Data format={{ decimalScale: 0 }} what="assumption.bags_per_year.l77"/></td>
            <td className="center"><Data format={{ decimalScale: 0 }} what="assumption.bags_per_year.cellulose"/></td>
          </tr>
          <tr>
            <td>Coverage per day (sq. ft.)</td>
            <td className="center"><Data format={{ decimalScale: 0 }} what="assumption.coverage_per_day.l77"/></td>
            <td className="center"><Data format={{ decimalScale: 0 }} what="assumption.coverage_per_day.cellulose"/></td>
          </tr>
          <tr>
            <td>Coverage per year (sq. ft.)</td>
            <td className="center"><Data format={{ decimalScale: 0 }} what="assumption.coverage_per_year.l77"/></td>
            <td className="center"><Data format={{ decimalScale: 0 }} what="assumption.coverage_per_year.cellulose"/></td>
          </tr>
          <tr>
            <td>Laborers</td>
            <td className="center"><Data format={{ decimalScale: 0 }} what="assumption.laborers.l77"/></td>
            <td className="center"><Data format={{ decimalScale: 0 }} what="assumption.laborers.cellulose"/></td>
          </tr>
          <tr>
            <td>Hourly Rate</td>
            <td className="center"><Data what="assumption.hourly_rate.l77"/></td>
            <td className="center"><Data what="assumption.hourly_rate.cellulose"/></td>
          </tr>
          <tr>
            <td>Hours Worked per day</td>
            <td className="center"><Data format={{ decimalScale: 0 }} what="assumption.worked_per_day.l77"/> hours</td>
            <td className="center"><Data format={{ decimalScale: 0 }} what="assumption.worked_per_day.cellulose"/> hours <div className="oc-tooltip"><i className="fa fa-info-circle" aria-hidden="true" /><div className="tooltip left">Due to the increase in cellulose needed for the same amount of coverage, a team will go through the amount that can fit in a truck in 6 hours, causing them to either have to reload or end the day early.</div></div></td>
          </tr>
          <tr>
            <td>Annual Wages</td>
            <td className="center"><Data what="assumption.annual_wages.l77"/></td>
            <td className="center"><Data what="assumption.annual_wages.cellulose"/></td>
          </tr>
          <tr>
            <td>Labor Burden (30%)</td>
            <td className="center"><Data what="assumption.labor_burden.l77"/></td>
            <td className="center"><Data what="assumption.labor_burden.cellulose"/></td>
          </tr>
        </tbody>
      </table>
    </ComparisonTable>
  </>
)

export const CalculatorCellulose = calculatorConnector(XCalculatorCellulose);

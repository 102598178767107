import _ from 'lodash';
import Providers from './Providers';

const shouldBeAvailable = (element) => {
  // By default, always allow component in all tenants if the specification
  // is not provided
  if (element.availableIn === undefined) {
    return true;
  }

  return element.availableIn.includes(PB_SITE);
}

export default {
  'for': (module) => {
    return _.mapValues(_.pickBy(_.omitBy(_.mapValues(Providers, module), _.isNil), shouldBeAvailable), (v, k) => (
      // merge in an overridden view to try to use "parameters[dataset]" instead of just parameters
      // this forces the params to be namespaced by their dataset in case of switching
      _.merge(
        {},
        v,
        v.old_view ? { old_view: (params) => v.old_view((params||{})[k]) } : {},
        v.search ? { search: (params) => v.search((params||{})[k]) } : {})
    ));
  }
}

/*

TODO:

Currently fetch isn't working, most likely needs
CLOUDINARY_URL set up for javascript to access so it
can sign uploads. Please only use Cloudinary Pub IDs
until this message goes away.

Note, uses Array.wrap which is a sort of a polyfill loaded globally

USAGE:

The base transform object can be replaced with a string
name of a preset you want to use. You can find presets
in the static object Presets in the Picture class.

All transformation objects use key names from:
  cloudinary.com/documentation/transformation_reference
using their full names (not URL short names)

Variant transforms override base transform properties,
so you only have to set transformations you want
through all variants in the base transformation


Use Picture as a component like below to
create a picture element with the variants becoming
sources and the base source/transform becoming
the fallback img tag at the bottom

  <UI.Picture
    source="cloudinary/image/id"
    transform="example"
  />

  <UI.Picture
    source="cloudinary/image/id"
    transform={{ width: 100, height: 100, quality: 60 }}
    variants={[
      {
        width: 200,
        height: 200,
        media: '(min-width: 800px)'
      },
      {
        width: 400,
        height: 400,
        media: '(min-width: 1200px)'
      }
    ]}
  />

The aspectRatio param can be used to chain an optional aspect ratio transformation to the base transform as in:
  <UI.Picture
    source="cloudinary/image/id"
    transform={{ width: 100, height: 100, quality: 60 }}
    aspectRatio={{ selection: '2' }}
  />
or
  <UI.Picture
    source="cloudinary/image/id"
    transform={{ width: 100, height: 100, quality: 60 }}
    aspectRatio={{ selection: 'custom', custom: '2.1' }}
  />

You can also use static methods on the imported
Picture class for more programmatic tasks.


Using Picture.Transform you can get Transformation
URLs for Cloudinary Pub IDs or full URLs (full URLs
are fetched by Cloudinary), for example:

  UI.Picture.Transform(
    "cloudinary/image/id",
    "example"
  )

  UI.Picture.Transform("cloudinary/image/id", {
    width: 100,
    height: 100,
    crop: 'thumb'
  })


The Picture.Background function generates a set of background
images, the first sitting outside of a media query and the
variants sitting in their own media queries

Use the "styles" key in any transform to add css after
their respective background-image css

  UI.Picture.Background(
    "cloudinary/image/id",
    { styles: css` background-position: 100px 10px; ` }
  )

  UI.Picture.Background(
    "cloudinary/image/id",
    {
      width: 100,
      height: 100,
      crop: 'thumb',
      quality: 60,
      styles: css`
        background-position: center;
      `
    },
    [
      {
        width: 200,
        height: 200,
        media: '(min-width: 800px)',
        styles: css`
          background-size: contain;
        `
      },
      {
        width: 400,
        height: 400,
        media: '(min-width: 1200px)',
        styles: css`
          background-size: cover;
        `
      }
    ]
  )


The static method Picture.Sources can also be used to
get transformation URLs and other settings for rendering,
but I'm not sure why you'd want to use this outside
of extending rendering options.

  UI.Picture.Sources(
    "cloudinary/image/id",
    "hero"
  )

  UI.Picture.Sources(
    "cloudinary/image/id",
    { width: 100, height: 100, quality: 60 },
    [
      {
        width: 200,
        height: 200,
        media: '(min-width: 800px)'
      },
      {
        width: 400,
        height: 400,
        media: '(min-width: 1200px)'
      }
    ]
  )

*/

import { ui, define } from '@owenscorning/pcb.alpha';

import { Component } from 'react';
import { css } from '@emotion/react';

import _ from 'lodash';
import OcPicture from '../../../OC/oc-picture';

class Picture extends Component {
  static Defaults = OcPicture.Defaults;
  static Cloudinary = OcPicture.Cloudinary;
  static get Presets() { return OcPicture.Presets; };

  static Preset(transform) {
    return OcPicture.Preset(transform);
  }

  static AspectRatioTransform(aspectRatio) {
    return OcPicture.AspectRatioTransform(aspectRatio);
  }

  static Transform(source, transformation, aspectRatioTransform) {
    return OcPicture.Transform(source, transformation, aspectRatioTransform);
  }

  static Sources(source, transform, variants, aspectRatioTransform) {
    return OcPicture.Sources(source, transform, variants, aspectRatioTransform);
  }

  static Background(source, transform, variants) {
    return OcPicture.Background(source, transform, variants);
  }

  constructor(props) {
    super(props);
  }

  render() {
    const { source, blankImage, transform, aspectRatio, variants, icon, ...props } = this.props;

    const aspectRatioTransform = Picture.AspectRatioTransform(aspectRatio);

    let img = source;
    console.log('UI.Placeholder:', UI.Placeholder);
    // if (UI.Placeholder.is(img) && aspectRatioTransform) {
    //   img = UI.Placeholder.aspect(img, aspectRatioTransform.aspectRatio)
    // }

    const parsed = Picture.Sources(img, transforms, variants, aspectRatioTransform);

    return (
      <div css={ css` line-height: 0; position: relative; ` } >
        <picture css={ css` &, img { max-width: 100%; } ` } >
          {
            parsed.variants.map((variant, index) => (
              <source
                key={ index }
                srcSet={ variant.url }
                media={ variant.media }
              />
            ))
          }
          <img { ...props } src={ blankImage ? Picture.Blank : parsed.base } />
        </picture>
        {
          icon && <div
            css={ css`
              background-color: white;
              position: absolute;
              bottom: 0;
              left: 0;
              padding: 10.5px;
              font-size: 21px;
            ` }
          >
            <UI.Icon brand type={ icon } />
          </div>
        }
      </div>
    );
  }
}

export default define`Picture`('0.0.1')({
  prepare: () => {
    const View = UI.Theme.Taggable(Picture);
    View.Placeholder = (props) => <View { ...props } transform={{  }} />

    _.each(
      UI.Picture.Transforms,
      (transform, name) => View[_.capitalize(name)] = (props) => <View { ...props } transform={ name } />
    );

    return {
      gutterlessForReusable: true,
      view: View,
      edit: {
        source: ui`Upload/Image`({ label: "Banner Image" }),
        alt: ui`Text`({ label: "Alt Text" })
      }
    };
  }
});

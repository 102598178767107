import moment from 'moment';
import jwtDecode from 'jwt-decode';

const getCookie = (name) => {
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(';').map(function(c) {
    return c.trim().split('=');
  }).filter(function(c) {
    return c[0] === name
  });
  return cookies.length > 0 ? cookies[0][1] : null;
};

const setCookie = (name, value) => {
  document.cookie = `${name}=${value}`;
};

const deleteCookie = (name) => {
  setCookie(name, '');
}

const rejectErrorFromResponse = r => {
  const err = new Error();
  err.name = r.statusText;
  err.message = r.statusText;
  err.status = r.status;
  err.response = r;
  return err;
};

const decoded = (jwt = null) => {
  let cookiedJwt = jwt || getCookie('jwt');
  try {
    if (cookiedJwt) {
      // if there are 2 components, it is "headless"
      // if there are 3 components, throw away and reconstruct the head
      const components = cookiedJwt.split('.');
      const header = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9'; // matches ruby-jwt lib "typ: JWT, alg: HS256"
      const realJwt = `${header}.${components.slice(-2).join('.')}`;
      const decodedJwt = jwtDecode(realJwt);
      if (decodedJwt && decodedJwt.exp) {
        if (moment.unix(decodedJwt.exp).isSameOrBefore(moment())) {
          throw 'invalid jwt';
        } else {
          return decodedJwt;
        }
      }
    }
  } catch (err) {
    deleteCookie('jwt');
  }
  return null;
};

const jwt = () => {
  let cookiedJwt = getCookie('jwt');
  if (decoded()) {
    return cookiedJwt;
  }
  return null;
};

// TODO: this is coupling with host site a bit
const fetchJwt = () =>
  fetch('/global/users/jwt', { credentials: 'same-origin' }).then(r => {
    if (r.status === 200) {
      return r.headers.get('jwt');
    } else {
      throw rejectErrorFromResponse(r);
    }
  });

const jwtPromise = () => {
  let cookiedJwt = jwt();
  const startJwt = cookiedJwt || fetchJwt();
  return Promise.resolve(startJwt)
}

const jwtFetch = (url, options) => {
  return new Promise((resolve, reject) => {
    const fetchApi = token => {
      setCookie('jwt', token);
      return fetch(url, {
        ...options,
        headers: {
          ...((options || {}).headers || {}),
          Authorization: `Bearer ${token}`,
        },
      });
    };

    jwtPromise().then(token => fetchApi(token))
      .then(r => {
        if (r.status === 200) {
          // ok
          resolve(r);
        } else if (r.status === 401 && jwt()) {
          // retry after forcing a new jwt call, if we used the cookie value above, else reject
          return fetchJwt()
            .then(token => fetchApi(token))
            .then(r => {
              if (r.status === 200) {
                // ok
                resolve(r);
              } else {
                throw rejectErrorFromResponse(r);
              }
            });
        } else {
          //throw rejectErrorFromResponse(r);
          resolve(r);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

const unauthenticated = (options) => {
  if (typeof window === 'object') {
    // TODO: this is coupling with host site a bit
    window.location = options.unauthenticated_url||`/global/users/login?return_url=${encodeURIComponent(
      window.location.pathname
    )}`;
  }
};

const fetchUsingJWT = (url, options) => {
  options = options||{};
  options.unauthenticated = options.unauthenticated||unauthenticated;

  return jwtFetch(url, options,).catch(err => {
    if (err.status === 401) {
      console.log("401");
      options.unauthenticated(options);
    } else {
      throw err;
    }
  });
};

export { fetchUsingJWT, jwt, jwtPromise, decoded };
export default jwtFetch;

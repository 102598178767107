
/*
I wrap {children} in a Link with no text-decoration
if {url} is present, otherwise I just return {children}
without modification.

If an alternative wrapping element is required in the case
of a missing {url}, a custom element can be chosen by
passing in the optional {element} prop.
*/

import { createElement } from 'react';
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled'
import Link from './oc-link';

const CleanLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
`

const WrapperLink = ({ url, children, element, analytics, ...props }) => {
  if (url) {
    return (
      <CleanLink className="wrapper-link" href={url} analytics={analytics} {...props}>
        {children}
      </CleanLink>
    )
  } else {
    if (element) {
      return createElement(element, props, children);
    } else {
      return (
        <>
          {children}
        </>
      )
    }
  }
}

export default WrapperLink;

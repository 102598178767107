import styled from '@emotion/styled';

import { H3 } from '../text-elements/oc-h';

export const ResultWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid black;
  padding: 16px 0;
  position: relative;
  align-items: center;

  @media (min-width: 1200px) {
    padding: 24px 0;
  }
`;
export const Media = styled.div`
  margin-right: 8px;
  align-self: flex-start;

  @media (min-width: 768px) {
    margin-right: 16px;
  }

  img {
    width: 72px;
  }
`;
export const ResultInfo = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;
export const Name = styled(H3)`
  display: inline-block;
  padding: 0 8px 0 0;
  margin-bottom: 8px;
  text-transform: capitalize;
`;
export const ItemNumber = styled.span`
  display: inline-block;
  position: relative;
  margin-right: 8px;
`;
export const Distance = styled.div`
  font-size: 14px;
  font-style: italic;
  display: inline-block;
  line-height: 18px;
  padding-bottom: 2px;
  color: #333;
`;
export const Address = styled.div`
  line-height: 18px;
  display: block;
  margin-bottom: 8px;
  text-transform: capitalize;
`;
export const MobileSwitch = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: 768px) {
    flex-direction: column;
  }
`;
export const Divider = styled.span`
  margin: 0 8px;

  @media (min-width: 1200px) {
    margin: 0 16px;
  }
`;
export const ActionLink = styled.a`
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  .icon {
    margin-right: 8px;
    margin-bottom: -3px;
  }
`;
export const Notes = styled.div`
  margin-top: 8px;
  font-size: 14px;
  color: #333;
  text-transform: capitalize;
`;
export const MainCta = styled.div`
  padding-top: 10px;
  align-self: flex-start;

  @media (min-width: 768px) {
    padding: 0;
  }
  a {
    white-space: nowrap;
  }
`;

export const Badges = styled.div`
  line-height: 18px;
  display: block;
  margin-bottom: 8px;
  margin-top: 8px;
  text-transform: capitalize;

  img {
    margin-right: 16px;
    margin-bottom: -3px;
    width: 20px;
  }
`;

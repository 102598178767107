import { ui } from '@owenscorning/pcb.alpha';
import IconPimLink from '../../../ComponentLibrary/icons/icon-pim-link';

export default ui`Form`.of({
  type: ui`Choices`.of({
    image: 'Image',
  })({
    default: 'image',
    disabled: true,
    mode: ui`Choices/Mode/Dropdown`,
    tip: "Field connected to PIM product.",
    tipIcon: IconPimLink
  }),
  resources: {
    image: ui`Image`({
      label: 'Image',
      compact: true,
      tip: "Field connected to PIM product.",
      tipIcon: IconPimLink
    })
  }
})({
  label: 'Media',
  noPadding: true
});

import { toast } from './toast';
import Api from '../../../mdms_api';

export const apiSubmit = (method, url, options, ...values) => {
  let apiCallPromise = method(url, ...values).then(response => {
    if (response.status >= 200 && response.status < 300) {
      if (response.status === 204)
        return response;
      return response.json()
    } else {
      throw response;
    }
  });
  return apiCallPromise.catch(err => {
    if (!options?.signal || !options.signal.aborted) {
      if (err instanceof Error) {
        if (!options.suppressToast) {
          toast.error(`Failed. The message returned from the server was: ${ err.message }.`, { toastId: err.message })
        }
        throw err;
      }

      console.error('Error in api call (not instanceof Error): ', { err })

      return err.json().then((responseJson) => {
        if (!options.suppressToast) {
          toast.error(`Failed. The message returned from the server was: ${ err.status } ${ err.statusText } ${ JSON.stringify(responseJson) }.`, { toastId: err.status })
        }
        throw err;
      }).catch(jsonErr => {
        throw err;
      })
    }
  })
};
export const apiGet = (url, options={}) => (
  apiSubmit(Api.secure_get, url, options, undefined)
);
export const apiPost = (url, values) => (
  apiSubmit(Api.secure_post, url, {}, values)
);
export const apiPut = (url, values) => (
  apiSubmit(Api.secure_put, url, {}, values)
);
export const apiDelete = (url) => (
  apiSubmit(Api.secure_delete, url, {}, undefined)
);
export const apiUpload = (url, file, progressCallback) => (
  apiSubmit(Api.secure_upload, url, {}, file, progressCallback)
)

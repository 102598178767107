import AlertMessage from '../../OC/oc-alert-message';

export default ({ message, title = "Note", type = 'info' }) => {
  return (
    <AlertMessage
      alertIcon={true}
      dismissable={false}
      message={`<b>${title}:</b> ${message}`}
      style={{ marginBottom: '0px' }}
      textSize="13px"
      type={type}
    />
  );
};

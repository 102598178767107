import ReactOnRails from 'react-on-rails';
import { Provider } from 'react-redux';
import { cmsConnector, cmsUsersConnector } from "../PageBuilder/redux/connector";
import displayName from "../helpers/displayName";

const wrappedInReduxProvider = function(WrappedComponent) {
  function wrappedInReduxProvider(props) {
    const store = ReactOnRails.getStore("CmsStore");
    return (
      <Provider store={store}>
        <WrappedComponent {...props} />
      </Provider>
    )
  }

  wrappedInReduxProvider.displayName = `wrappedInReduxProvider(${displayName(WrappedComponent)})`

  return wrappedInReduxProvider
}

const ReduxWrapper = (usersOnly, Component) => wrappedInReduxProvider(usersOnly ? cmsUsersConnector(Component) : cmsConnector(Component));
export default ReduxWrapper;

import styled from '@emotion/styled';
import Theme from '../../../themes';

const Switch = styled.label`
  background-color: #FFFFFF;
  border-radius: 16px;
  border: 1px solid ${props => props.checked ? Theme.colors.brand : "#6D6D6D"};
  display: inline-block;
  height: 25px;
  position: relative;
  transition-delay: 0.2s;
  transition: all 0.3s;
  width: 45px;

  &:after {
    background-color: ${props => props.checked ? Theme.colors.brand : "#6D6D6D"};
    border-radius: 50%;
    content: '';
    height: 19px;
    left: ${props => props.checked ? "22px" : "2px"};
    position: absolute;
    top: 2px;
    transition: background-color 0.3s, left 0.4s;
    width: 19px;
  }

  input {
    color: #6D6D6D;
    font-size: 10px;
    height: 0px;
    width: 0px;

    &:before {
      content: 'ON';
      left: 4px;
      opacity: 0;
      position: absolute;
      top: 6px;
      transition-delay: 0.2s;
      transition: all 0.5s;
    }

    &:checked {
      color: ${Theme.colors.brand};

      &:before {
        opacity: 1;
      }
    }
  }
`;

export default ({ onChange, value, disabled }) => (
  <Switch checked={value}>
    <input checked={value} disabled={!!disabled} onChange={(e) => onChange(e.target.checked)} type="checkbox" />
  </Switch>
);

import { jsx, css } from '@emotion/react';

const alertBarStyles = css`
  padding: 8px 10px;
  text-align: center;
  font-size: 14px;
  margin-bottom: 25px;
`;

const alertBarThemeStyles = {
  black: css`
    background: black;
    color: white;

    a {
      color: white;
    }
  `,
  orange: css`
    background: #FFF1E5;
    color: #7E3900;

    a {
      color: #7E3900;
    }
  `
};

const AlertBar = ({ children, themeColor="black" }) => {
  return (
    <div css={[alertBarStyles, alertBarThemeStyles[themeColor]]}>
      {children}
    </div>
  )
}

export default AlertBar;

import { define, ui, when, s, select } from '@owenscorning/pcb.alpha';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { useState } from 'react';
import styled from '@emotion/styled'
import _ from 'lodash';

import { definedCardsPerRowDesktop, definedCardsPerRowTablet, definedCardsPerRowMobile } from '../../CardSetSettings.jsx';
import { Mobile, Tablet, Desktop } from '../../../../OC/PageBuilder/internals/Devices';
import { Screens } from '../../../../PageBuilder/editing/Viewport';
import { BasicContent } from '../../../../OC/PageBuilder/BasicContent.jsx';
import ComparisonCard from '../../../../OC/oc-comparison-card.jsx';
import Card from '../../../../OC/PageBuilder/Card.jsx';

import { unwrapRef, wrapRef } from '../../../../../data';
import useReference from '../../../../../hooks/use_reference';
import placeholder_url from '../../../../../placeholder';
import Data from '../../../Data';

const Sizes = {
  'xl': { [Mobile]: 345, [Tablet]: 738, [Desktop]: 1170 },
  'l': { [Mobile]: 345, [Tablet]: 738, [Desktop]: 970 },
  'm': { [Mobile]: 225, [Tablet]: 482, [Desktop]: 770 },
};

const CardContainer = styled.div`
  display: flex;
  justify-content: ${props => (props.containerAlignment == 'Center') ? 'center' : 'flex-start'};
`;

const CardSet = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => (props.containerAlignment == 'Center') ? 'center' : 'flex-start'};
  margin: 0;
  text-align: ${props => props.textAlignment || 'left'};
  width: ${({ width }) => `${width.Mobile}px`};

  @media (min-width: ${Screens.Tablet}px) {
    width: ${({ width }) => `${width.Tablet}px`};
  }

  @media (min-width: ${Screens.Desktop}px) {
    width: ${({ width }) => `${width.Desktop}px`};
  }

  .card-set-content, .card-row {
    position: relative;
    width: 100%;
  }

  .card-set-content {
    display: inline-block;
  }

  .card-row {
    display: flex;
    justify-content: ${props => (props.containerAlignment == 'Center') ? 'center' : 'flex-start'};

    > div {
      ${props => props.cardLimit && `
        max-width: ${props.cardLimit?.mobile};

        @media (min-width: ${Screens.Tablet}px) {
          max-width: ${props.cardLimit?.tablet};
        }

        @media (min-width: ${Screens.Desktop}px) {
          max-width: ${props.cardLimit?.desktop};
        }
      `}

      ${props => props.cardWidth && `
        > div {
          max-width: ${props.cardWidth?.mobile};

          @media (min-width: ${Screens.Tablet}px) {
            max-width: ${props.cardWidth?.tablet};
          }

          @media (min-width: ${Screens.Desktop}px) {
            max-width: ${props.cardWidth?.desktop};
          }
        }
      `}

      .oc-cta-container {
        justify-content: ${props => props.textAlignment || 'left'};
      }
    }

    ${props => props.reduceHeadingSize && `
      h3 {
        font-size: 18px;
        line-height: 18px;

        @media(min-width: 768px) {
          font-size: 20px;
          line-height: 20px;
        }
      }
    `}
  }
`;

const reduceHeadingSize = (settings = {}) => {
  let cardFormat = settings.format;
  let cardSize = settings.cardSize;
  let itemsPerRow = settings.itemsPerRow;
  let smallHeadingCards = ['xs', 'sm', 'md'];

  if (cardFormat == 'auto' && itemsPerRow > 3) return true;
  if (cardFormat == 'defined' && smallHeadingCards.includes(cardSize)) return true;
  return false;
};

const cardLimitPerRow = (settings = {}) => {
  const desktopMax = _.last(definedCardsPerRowDesktop(settings.cardSize));
  const tabletMax = _.last(definedCardsPerRowTablet(settings.cardSize));
  const mobileMax = _.last(definedCardsPerRowMobile(settings.cardSize));
  return ({
    desktop: `calc(((100% / ${desktopMax}) * ${settings.itemsPerRow}) + 24px)`,
    tablet: `calc(((100% / ${tabletMax}) * ${settings.itemsPerRowTablet}) + 24px)`,
    mobile: `calc(((100% / ${mobileMax}) * ${settings.itemsPerRowMobile}) + 24px)`
  });
};

const cardWidthBySize = (cardSize) => {
  const desktopSizeFactor = _.last(definedCardsPerRowDesktop(cardSize));
  const tabletSizeFactor= _.last(definedCardsPerRowTablet(cardSize));
  const mobileSizeFactor = _.last(definedCardsPerRowMobile(cardSize));
  return ({
    desktop: `calc((1194px / ${desktopSizeFactor}) - 24px)`,
    tablet: `calc((762px / ${tabletSizeFactor}) - 24px)`,
    mobile: `calc((369px / ${mobileSizeFactor}) - 24px)`
  });
};

const Renderer = ({
  cards: cardsProp,
  copy,
  data,
  parent,
  settings = {},
  socialShare,
  type: { source = 'custom' } = { source: 'custom' },
}) => {
  const [cards, setCards] = useState(cardsProp);
  const Component = (settings?.cardType === 'comparison') ? ComparisonCard : Card;
  const textAlignment = settings?.textAlignment || 'left';
  const cardFormat = settings?.format || 'auto';
  const cardSize = settings?.cardSize || 'xl';
  const containerAlignment = settings?.alignment || 'Left';
  const size = settings?.containerSize?.selection || 'xl';
  const width = Sizes[size];

  let desktopFits = (cardFormat == 'auto') ? (settings?.itemsPerRow || 3) : null;
  let tabletFits = (cardFormat == 'auto') ? (settings?.itemsPerRowTablet || 2) : null;
  let mobileFits = (cardFormat == 'auto') ? (settings?.itemsPerRowMobile || 1) : null;
  let cardLimit = (cardFormat == 'defined' ? cardLimitPerRow(settings) : null);
  let cardWidth = (cardFormat == 'defined') ? cardWidthBySize(cardSize) : null;
  let cardTransform = "cards";

  let reference = source === 'data' ? data?.reference : null;
  // TODO: remove me after a bit, data.reference being nil is an old bit of data pre PB-295
  // TODO: this fall back is crashing when reference is undefined
  if (typeof(reference) === 'undefined' && data?.dataset && data?.parameters) {
    reference = wrapRef('Cms::DataProvider', {
      type: 'CardSet',
      dataset: data.dataset,
      parameters: data.parameters[data.dataset],
      language: Board.build.language
    })
  }

  // TODO: it also seems we could remove this bit, if we can remove the top-level
  // "dataset" and "parameters" and make part of Data/Parameters
  const [type, value] = reference ? unwrapRef(reference) : []
  if (value?.dataset !== data?.dataset) {
    reference = null;
  }

  const { results, error, loading } = useReference(reference);
  const adapter = Data.for('CardSet')[data?.dataset];
  useDeepCompareEffectNoCheck(() => {
    if (source === 'data') {
      setCards(adapter?.view(results));
    } else {
      setCards(cardsProp);
    }
  }, [source, data, results, cardsProp])

  // OCR-6328: When CardSet parent is ModuleSet module:
  // - We need to force cards to be full-width
  // - Card components need to not have a transform
  if (parent == "ModuleSet") {
    desktopFits = 1;
    tabletFits = 1;
    mobileFits = 1;
    cardTransform = null;
  }

  return (
    <CardContainer containerAlignment={containerAlignment}>
      <CardSet
        cardLimit={cardLimit}
        cardWidth={cardWidth}
        containerAlignment={containerAlignment}
        reduceHeadingSize={reduceHeadingSize(settings)}
        textAlignment={textAlignment}
        size={size}
        width={width}
      >
        <BasicContent className="card-set-content" { ...copy } />

        <UI.Row
          className="card-row"
          center={(containerAlignment === "Left") ? "" : true}
          fits={desktopFits}
          tablet-fits={tabletFits}
          mobile-fits={mobileFits}
          gap-3
        >
          {cards?.map((card, index) => (
            <Component
              img={card?.image?.file}
              crop={card?.image?.crop}
              aspectRatio={settings?.aspectRatio}
              alt={card?.alt}
              prehead={card?.prehead}
              heading={card?.heading}
              posthead={card?.posthead}
              text={card?.text}
              linkText={card?.linkText}
              linkUrl={card?.url}
              url={card?.url}
              icon={card?.icon}
              socialShare={socialShare}
              transform={cardTransform}
              ctaSwitch={card?.ctaSwitch}
              ctaProps={card?.cta}
            />
          ))}
        </UI.Row>
      </CardSet>
    </CardContainer>
  );
};

export default define`Card Set`('0.0.1')({
  gutterlessForReusable: true,
  thumbnail: ui`Modules/Page/Thumbnails/CardSet`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value }) => <Renderer { ...value } />,
  edit: {
    copy: ui`Content/Basic`,
    type: ui`Form`.of({
      source: ui`Choices`.of({
        'custom': 'Custom',
        'data': 'Data Driven'
      })({
        label: 'Select Type',
        default: 'custom',
        mode: ui`Choices/Mode/Dropdown`
      }),
      [s._]: ui`Tip`.of('Select the type of cards you would like to create or pull data from.'),
    })({
      label: 'Select Type',
    }),
    data: ui`Form`.of({
      dataset: ui`Data/Choices`.of('CardSet'),
      parameters: ui`Data/Parameters`.of('CardSet')({ dataset: select`../dataset` }),
      reference: ui`Data/Reference`({
        type: 'CardSet',
        dataset: select`../dataset`,
        parameters: select`../parameters`,
      })
    })({
      label: 'Select Details',
      visible: when `../type/source`.is.equal.to('data').then(true).otherwise(false)
    }),
    settings: ui`CardSetSettings`({
      label: "Layout"
    }),
    socialShare: ui`Switch`({ label: 'Card Image Social Sharing', default: false }),
    socialShareTip: ui`Tip`.of("By enabling image social share we will be showing share options on this module's images."),
    cards: ui`List`.of({
      image: ui`Image`({
        askAlt: false,
        crop: true,
        label: 'Image Path',
        defaultImage: placeholder_url(340,190)
      }),
      alt: ui`Text`({ label: 'Alt Text' }),
      prehead: ui`Text`({ label: 'Prehead' }),
      heading: ui`Text`({ label: 'Heading' }),
      posthead: ui`Text/Rich`({ label: 'Posthead', visible: when`../../../settings/cardType`.is.equal.to('comparison').then(true).otherwise(false) }),
      ctaSwitch: ui`Switch`({ label: 'Use call-to-action', default: false }),
      [s._]: ui`Tip`.of('Choose between default Link Text or a CTA Button(s) features'),
      text: ui`Text/Rich`({ label: 'Text' }),
      linkText: ui`Text`({ label: 'Link Text', visible: when`../ctaSwitch`.is.equal.to(false) }),
      url: ui`Text`({ label: 'URL', visible: when`../ctaSwitch`.is.equal.to(false) }),
      cta: ({ value, path, Board }) => {
        // note : backward compatibility, updating cta existing object value into an array of cta object
        if (!!value && !_.isArray(value) && _.isObjectLike(value)) {
          Board?.Change([{ ...value }], path)
        }
        return (<Subschema>
          {[
            ui`CTA/List`(
              {
                default: [{}],
                visible: when`../ctaSwitch`.is.equal.to(true)
              })
          ]}
        </Subschema>)
      }
    })({
      max: 48,
      singular: 'Card',
      title: 'heading',
      label: 'Cards',
      visible: when `../type/source`.is.equal.to('custom').then(true).otherwise(false)
    })
  },
});

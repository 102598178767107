import { ui, define, when, select } from '@owenscorning/pcb.alpha';

const defaultChoices = {
  image: 'Image',
  video: 'Looping Video',
  youtube: 'YouTube'
};

const Media = ({ choices= defaultChoices, askImageCaption = false }) => (
  <Subschema>
    {
      ui`Form`.of({
        type: ui`Choices`.of(choices)({ default: 'image' }),
        resources: {
          image: ui`Image`({
            askCaption: askImageCaption,
            crop: true,
            label: 'Image',
            visible: when`../../type`.is.equal.to('image')
          }),
          video: ui`Form`.of({ url: ui`Text`({ label: 'Cloudinary Video Url' }) })({
            visible: when`../../type`.is.equal.to('video')
          }),
          youtube: ui`Form`.of({ url: ui`Text`({ label: 'YouTube ID' }) })({
            label: 'YouTube',
            visible: when`../../type`.is.equal.to('youtube')
          }),
          caption: ui`Text/Rich`({
            label: 'Caption',
            visible: when`../../type`.isnt.equal.to('image')
          }),
          captionAlignment: ui`Choices`.of({
            'left': 'Left',
            'center': 'Center'
          })({
            label: 'Caption Alignment',
            default: 'left',
            visible: when`../../type`.isnt.equal.to('image')
          })
        },
      })({ label: 'Media' })
    }
  </Subschema>
)

export default Media;

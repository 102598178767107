import { ui } from '@owenscorning/pcb.alpha';
import _ from 'lodash';

// TODO: remove when async choices with param is a thing
const getShingleLineData = _.memoize(() => {
  if (typeof(window) !== 'undefined' && typeof(window.PB_SHINGLE_LINES) !== 'undefined' && window.PB_SHINGLE_LINES) {
    return window.PB_SHINGLE_LINES;
  }
  return [];
});

export default ui`Choices`.of(getShingleLineData())({
  label: 'Shingle Line'
});

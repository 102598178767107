import styled from '@emotion/styled';
import {useState, useEffect, useRef} from 'react';

// Components
import CalculatorForm from './PinkBarVsSteelForm';
import CalculatorResults from './PinkBarVsSteelResults';

const CalculatorContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;

	@media(min-width: 768px) {
		flex-direction: row;
	}
`;

const CalcFormContainer = styled.div`
	background: #e6e6e6 url(https://imagecdn.owenscorning.com/ocimages/image/upload/v1654696619/composites/pinkbar-vs-steel-rebar/pinkbar-panther-v2.png) no-repeat 96% 98%;
	background-size: 250px;
	padding: 15px 15px 165px;
	width: 100%;

	@media(min-width: 768px) {
		width: 33.333%;
		background-size: 150px;
		padding: 15px 15px 100px;
	}

	@media(min-width: 1200px) {
		background-size: 250px;
	}
`;

const CalcResultsContainer = styled.div`
	width: 100%;

	@media(min-width: 768px) {
		width: 66.666%
	}
`;

const PinkBarVsSteelCalculator = ({laborRate='62.50'}) => {
	const [post, setPost] = useState({['spacing']: '12', ['laborCost']: laborRate});
	const [info, setInfo] = useState({});
	const [resultsVisible, setResultsVisible] = useState(false);
	const [showReset, setShowReset] = useState(false);
	const scrollToDiv = useRef();
	
	let estLaborRate;

	useEffect(() => {
		estLaborRate = post.laborCost ? post.laborCost : laborRate;
	}, [post]);

	useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler); 
  }, []);
    
  const scrollHandler = () => {
    if(window.pageYOffset + window.innerHeight >= scrollToDiv.current.offsetTop) {
    	setResultsVisible(true);
    } else {
    	setResultsVisible(false);
    }
  }

	const inputChange = (e) => {
		const { name, value } = e.target;

    setPost(prevState => (
      { ...prevState, [name]: value }
    ));
	};

	const formSubmit = (e) => {
		e.preventDefault();

		if(post.length && post.width) {
			const sticksCarriedPerTripSteel = 5;
			const sticksCarriedPerTripPinkbar = 20;

			let slabArea = post.length * post.width;
			let barsPerSQFT = (2 * 12) / (post.spacing);
			let sticksRequiredForJob = (slabArea * barsPerSQFT) / 20;
			let steelWt = 13.4 * sticksRequiredForJob;
			let pinkBarWt = 2.14 * sticksRequiredForJob;
			let weightPerTripSteel = sticksCarriedPerTripSteel * 13.4;
	    let weightPerTripPinkbar = sticksCarriedPerTripPinkbar * 20 * 0.107;
	    let timeToReplaceSteelRebar = (steelWt * 240) / 2000;
	    let timeToPlacePinkbarRebarMin = timeToReplaceSteelRebar * 0.33;
	    let timeToPlacePinkbarRebarMax = timeToReplaceSteelRebar * 0.5;
	    let estLabourCostSteel = Math.round((timeToReplaceSteelRebar / 60) * 4 * estLaborRate);
	    let estLabourCostPinkbarMin = Math.round((timeToPlacePinkbarRebarMin / 60) * 4 * estLaborRate);
	    let estLabourCostPinkbarMax = Math.round((timeToPlacePinkbarRebarMax / 60) * 4 * estLaborRate);
	    let estMaterialCostSteel = Math.round(sticksRequiredForJob * 7);
	    let estMaterialCostPinkbar = Math.round(sticksRequiredForJob * 5.5);
	    let estTotalCostSteel = estLabourCostSteel + estMaterialCostSteel;
	    let estTotalCostPinkbarMin = estLabourCostPinkbarMin + estMaterialCostPinkbar;
	    let estTotalCostPinkbarMax = estLabourCostPinkbarMax + estMaterialCostPinkbar;
	    let estLabourSavingMin = estLabourCostSteel - estLabourCostPinkbarMin;
	    let estLabourSavingMax = estLabourCostSteel - estLabourCostPinkbarMax;
	    let estMaterialSaving = estMaterialCostSteel - estMaterialCostPinkbar;
	    let estTotalSavingMin = estTotalCostSteel - estTotalCostPinkbarMin;
	    let estTotalSavingMax = estTotalCostSteel - estTotalCostPinkbarMax;

	  	setInfo(prevState => (
	      { ...prevState, 
	      	['slabSize']: `${post.length}x${post.width}`,
	      	['rebarSpacing']: post.spacing,
	      	['sticksRequired']: sticksRequiredForJob,
	      	['steelWeight']: steelWt,
	      	['pinkBarWeight']: pinkBarWt,
	      	['sticksPerTripSteel']: sticksCarriedPerTripSteel,
	      	['sticksPerTripPinkBar']: sticksCarriedPerTripPinkbar,
	      	['wtPerTripSteel']: weightPerTripSteel,
	      	['wtPerTripPinkBar']: weightPerTripPinkbar,
	      	['truckToSiteTripSteel']: (sticksRequiredForJob / sticksCarriedPerTripSteel) + 0.3,
	      	['truckToSiteTripPinkbar']: (sticksRequiredForJob / sticksCarriedPerTripPinkbar) + 0.3,
	      	['timeToPlaceRebarSteel']: timeToReplaceSteelRebar,
	      	['timeToPlaceRebarPinkbarMin']: timeToPlacePinkbarRebarMin,
	      	['timeToPlaceRebarPinkbarMax']: timeToPlacePinkbarRebarMax,
	      	['estimatedLabourCostSteel']: estLabourCostSteel,
	      	['estimatedLabourCostPinkbarMin']: estLabourCostPinkbarMin,
	    		['estimatedLabourCostPinkbarMax']: estLabourCostPinkbarMax,
	    		['estimatedMaterialCostSteel']: estMaterialCostSteel,
	    		['estimatedMaterialCostPinkbar']: estMaterialCostPinkbar,
	    		['estimatedTotalCostSteel']: estTotalCostSteel,
	    		['estimatedTotalCostPinkbarMin']: estTotalCostPinkbarMin,
					['estimatedTotalCostPinkbarMax']: estTotalCostPinkbarMax,
					['estimatedLabourSavingMin']: estLabourSavingMin,
	    		['estimatedLabourSavingMax']: estLabourSavingMax,
	    		['estimatedMaterialSaving']: estMaterialSaving,
	    		['estimatedTotalSavingMin']: estTotalSavingMin,
	    		['estimatedTotalSavingMax']: estTotalSavingMax,
	      }
	    ));

	    setPost(prevState => ({
			  ...prevState,
			  ['lengthError']: null,
			  ['widthError']: null
			}));

	    setShowReset(true);

	    const element = scrollToDiv.current;
		  const headerOffset = 110;
		  const elementPosition = element.getBoundingClientRect().top;
		  const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

	    {!resultsVisible &&
		    window.scrollTo({
		      top: offsetPosition,
		      behavior: "smooth"
		    });
	    }
	  
	  } else {
	  	setPost(prevState => ({
			  ...prevState,
			  ['lengthError']: post.length ? null : "Please enter a valid length",
			  ['widthError']: post.width ? null : "Please enter a valid width"
			}));
	  }
	};

	const reset = () => {
   setInfo({});
   setPost({'spacing': '12', 'length': '', 'width': '', laborCost: '62.50'});
   setShowReset(false);
  }

	return (
		<CalculatorContainer>
			<CalcFormContainer>
				<CalculatorForm inputChange={inputChange} post={post} formSubmit={formSubmit} reset={reset} showReset={showReset}/>
			</CalcFormContainer>

			<CalcResultsContainer ref={scrollToDiv} >
				<CalculatorResults 
					slabSize={info.slabSize}
					rebarSpacing={info.rebarSpacing}
					sticksRequired={info.sticksRequired}
					steelWeight={info.steelWeight}
					pinkBarWeight={info.pinkBarWeight}
					sticksPerTripSteel={info.sticksPerTripSteel}
					sticksPerTripPinkBar={info.sticksPerTripPinkBar}
					weightPerTripSteel={info.wtPerTripSteel}
					weightPerTripPinkBar={info.wtPerTripPinkBar}
					truckToSiteTripSteel={info.truckToSiteTripSteel}
					truckToSiteTripPinkbar={info.truckToSiteTripPinkbar}
					timeToPlaceRebarSteel={info.timeToPlaceRebarSteel}
					timeToPlaceRebarPinkbarMin={info.timeToPlaceRebarPinkbarMin}
					timeToPlaceRebarPinkbarMax={info.timeToPlaceRebarPinkbarMax}
					estimatedLabourCostSteel={info.estimatedLabourCostSteel}
					estimatedLabourCostPinkbarMin={info.estimatedLabourCostPinkbarMin}
					estimatedLabourCostPinkbarMax={info.estimatedLabourCostPinkbarMax}
					estimatedMaterialCostSteel={info.estimatedMaterialCostSteel}
					estimatedMaterialCostPinkbar={info.estimatedMaterialCostPinkbar}
					estimatedTotalCostSteel={info.estimatedTotalCostSteel}
					estimatedTotalCostPinkbarMin={info.estimatedTotalCostPinkbarMin}
					estimatedTotalCostPinkbarMax={info.estimatedTotalCostPinkbarMax}
					estimatedLabourSavingMin={info.estimatedLabourSavingMin}
					estimatedLabourSavingMax={info.estimatedLabourSavingMax}
					estimatedMaterialSaving={info.estimatedMaterialSaving}
					estimatedTotalSavingMin={info.estimatedTotalSavingMin}
					estimatedTotalSavingMax={info.estimatedTotalSavingMax}
				/>
			</CalcResultsContainer>
		</CalculatorContainer>
	)
};

export default PinkBarVsSteelCalculator;
import _ from "lodash";
import { connect } from 'react-redux';
import produce from "immer";
import cablecar from 'redux-cablecar';
import { CMS_JOIN, CMS_LEAVE, CMS_SAVING, CMS_SYNC_HELLO, CMS_SYNC_SELECT_ITEM } from "../../Actions";
import { scrollToItem } from "../helpers/content";
import {findContentItemById, findPathById} from "../helpers/tree";
import { config } from "../config";

// TODO - we used to get this from window global until we did react_on_rails SSR, need to re-evaluate for multiuser
const cmsInitial = {}

// shape of redux cms state:
/*

{
  users: {
    instance: '2c87a79c-445b-4789-ab92-680b8c8e9f1d',
    '2c87a79c-445b-4789-ab92-680b8c8e9f1d': {
      name: 'First Last',
      selection: 'b04ecd64-6a0f-4a56-870f-4c495b364c20',
      color: '#9bfc4b'
    }
  }
}

 */

const userReducer = {
  users: produce((draft, action) => {
    switch (action.type) {
      case CMS_SYNC_HELLO:
      case CMS_JOIN: {
        // this comes from cablecar as another user starts editing this content
        const { instance, selection, name, color } = action;
        const newUser = { selection, name, color }
        console.log(`user '${name}' joined`);
        draft[instance] = newUser;
        break;
      }
      case CMS_LEAVE: {
        const { instance } = action;
        console.log(`user instance '${instance}' left`);
        delete draft[instance];
        break;
      }
      case CMS_SYNC_SELECT_ITEM: {
        const { instance = draft.instance, value_uuid } = action;
        if (draft.instance === instance) {
          scrollToItem(value_uuid);
        }
        draft[instance].selection = value_uuid;
        break;
      }
    }
  }, null)
};

export default userReducer;

const findSelection = (cms) => {
  const content = cms.content;
  const contents = content?.contents;
  const selection = cms.users?.[cms.users.instance]?.selection;
  return (
    content?.contentVersion === 1 ?
      findContentItemById(contents, selection) :
      _.get(contents, findPathById(contents, selection))
  );
};

export const usersConnector = connect(
  (state) => ({
    users: state.cms.users,
    selection: state.cms.users?.[state.cms.users.instance]?.selection,
    selectedItem: findSelection(state.cms),
    currentUser: state.cms.users?.[state.cms.users.instance],
  })
);

const CABLECAR_SERVER_ACTION_PREFIX = 'CMS_SYNC_';

const cablecarConnector = (store) => {
  // TODO - figure this out if we want to enable multiuser since cmsInitial is now empty
  const {
    content_id,
    users: {
      instance,
      [instance]: {
        selection,
        color
      }
    },
  } = getInitial('cms');

  const options = {
    params: {
      content_id,
      instance,
      selection,
      color,
    },
    prefix: CABLECAR_SERVER_ACTION_PREFIX
  };

  cablecar.connect(store, 'CmsChannel', options);
};

let bootstrapped = false;
export const usersMiddleware = store => {
  if (config.enableMultiuser && !bootstrapped) {
    bootstrapped = true;
    setTimeout(() => cablecarConnector(store), 0);
  }
  return next => action => {
    if (!config.enableMultiuser || typeof action === 'function' || action.type.startsWith('persist/')) {
      return next(action);
    }

    const {
      current_version_id,
      jwt,
      users: {
        instance,
        [instance]: {
          name,
          selection,
          color
        }
      }
    } = store.getState().cms;
    // for "sync" actions heading to server, append jwt, and our last known version id for concurrency checks
    const newAction = action.CableCar__Action ? action : {
      ...action,
      jwt,
      instance,
      current_version_id,
      timestamp: Date.now()
    };
    if (action.type) {
      if (action.type === 'CMS_JOIN') {
        store.dispatch({type: CMS_SYNC_HELLO, instance, selection, name, color})
      } else if (action.type === CMS_SYNC_HELLO) {
        // no op
      } else if (action.type.startsWith(CABLECAR_SERVER_ACTION_PREFIX) && action.type !== 'CMS_SYNC_SELECT_ITEM') {
        store.dispatch({
          type: CMS_SAVING
        })
      }
    }
    // pass on to cable car for routing
    return cablecar(store)(next)(newAction);
  }
}

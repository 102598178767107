import React, { useEffect } from "react";

const AnimatedAttentionBarWrapper = ({ wrapperComponent, reference, inView, styles, children, wrapperProps, target, animationTimeline, animate, animationBeginCallback }) => {
  const Wrapper = wrapperComponent || 'div';
  useEffect(() => {
    if (animate) {
      animationTimeline
        .add({
          targets: target.current,
          opacity: [0, 1],
          backgroundColor: ['transparent', '#000'],
          duration: 400,
          easing: 'easeInOutQuad',
          begin: animationBeginCallback,
        });

      if (inView) {
        animationTimeline.play();
      }
    }
  }, [inView, animate]);

  return (
    <div ref={reference}>
      <Wrapper ref={target} {...wrapperProps} css={styles}>
        {children}
      </Wrapper>
    </div>
  );
};

export default AnimatedAttentionBarWrapper;

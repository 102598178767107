import { Children } from 'react';
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const GridContainer = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(${props => props.colMobile}, 1fr);

  @media (min-width: 768px) {
    grid-template-columns: repeat(${props => props.colTablet}, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(${props => props.colDesktop}, ${props => props.colWidth ? props.colWidth : '1fr'});
  }
`

const Grid = ({ children, selectedSize, selectedSizeTablet, className, colWidth }) => {
  let cl = Children.toArray(children).length;

  let gridSize = () => {
    // Manual override
    if ( selectedSize != null) {
      return selectedSize;
    }

    if (cl === 1 || cl === 5) {
      return 3;
    }
    if (cl >= 2 && cl <= 4) {
      return cl;
    }
    if (cl % 4 === 0) {
      return 4;
    }
    if (cl % 3 === 0) {
      return 3;
    }
    return 4;
  }

  let gridSizeTablet = () => {
    if ( selectedSizeTablet != null) {
      return selectedSizeTablet;
    }

    if (cl === 1 || cl === 5) {
      return 3;
    }
    if (cl % 3 === 0) {
      return 3;
    }
    if (cl % 2 === 0) {
      return 2;
    }
    return 3;
  }

  let gridSizeMobile = () => {
    if (cl >= 5) {
      return 2;
    }

    if (cl <= 4) {
      return 1;
    }

    return 1;
  }

  return (
    <GridContainer colDesktop={gridSize()} colTablet={gridSizeTablet()} colMobile={gridSizeMobile()} colWidth={colWidth} className={className}>
      {children}
    </GridContainer>
  )
}

export default Grid;

Grid.propTypes = {
  /** Where you put the content that you want in grids */
  children: PropTypes.node,
  /** How many grids you want in a row */
  selectedSize: PropTypes.string,
  /** How many grids you want in a row on tablet */
  selectedSizeTablet: PropTypes.string,
  /** Specify a class for specific styling */
  className: PropTypes.string,
  /** Specify a column width. This is just for desktop. Tablet and mobile will be fluid */
  colWidth: PropTypes.string
}

import { Component } from "react";

class Icon extends Component {
  render() {
    let { type, ...props } = this.props;
    return (
      <span {...props} >
        <i className={`fa fa-${ type }`} aria-hidden="true" />
      </span>
    );
  }
}

export default Icon;

// Libraries
import styled from "@emotion/styled";

// Components
import IconPimLink from "../../../../../ComponentLibrary/icons/icon-pim-link";

// Styled Components
const ResultItem = styled.a`
  color: #000000;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 17px;
  padding: 8px 16px;
  text-decoration: none;

  &.linked {
    color: #6D6D6D;
    cursor: not-allowed;
  }
`;

const ProductTitle = styled.strong`
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 4px;
  width: 100%;
`;

const PIMLinkIcon = styled(IconPimLink)`
  margin-right: 8px;
`;

const ResultContent = ({ result }) => {
  return (
    <>
      <ProductTitle>{result.id}</ProductTitle>
      <span><strong>Brand:</strong> {result.brand}</span>
      <span><strong>Name:</strong> {result.name}</span>
      <span><strong>Series:</strong> {result.serie}</span>
      <span><strong>Insulation Type:</strong> {result.insulationType}</span>
    </>
  );
};

const InsulationSearchResult = ({ result, selectProduct, unwrapped = false }) => {
  if (unwrapped) return (<ResultContent result={result} />);

  return (
    <ResultItem
      className={result.isAlreadyLinked ? "linked" : null}
      onClick={() => selectProduct(result)}
    >
      <ResultContent result={result} />
      {result.isAlreadyLinked &&
        <span><PIMLinkIcon />Product already connected to a PDP</span>
      }
    </ResultItem>
  );
};

export default InsulationSearchResult;

import styled from '@emotion/styled';

// Components
import {connectLocation} from "../../location/LocationConnectors";
import Text from '../../OC/oc-text';
import Modal from "../../Modal";
import Table from '../../ComponentLibrary/oc-table';
import Image from "../../ComponentLibrary/oc-image";

const TextWrapper = styled.css`
  font-size: 19px!important;
  font-weight: bold;
`;

const MapImage = styled(Image)`
  outline: 0px;
`;

const RValModal = connectLocation(({t, onClose, open}) => {
	return (
    <Modal name={t('contractors.request_quote')} open={open} onClose={onClose} size="sm">
      <MapImage src='https://res.cloudinary.com/ocimages/image/upload/v1693307453/insulation/residential/atticat/rvalue-map.png' />
      <h3>Know your R-value</h3>
      <TextWrapper><Text content="Does your home measure up?"/></TextWrapper>
      <p>The U.S. Department of Energy has recommendations for minimum R-value in attics that are based on where you live. The minimum recommendations for most of the country are R-49 or R-60. The chart below illustrates what is recommended for your Climate Zone. Insulating performance is measured by a common standard referred to as the R-value. R-value measures resistance to heat flow and is usually related to the thickness of the insulation. The higher the R-value, the greater the insulating power.</p>
      <Table striped>
        <tbody>
        <tr>
          <td><strong>Zone</strong></td>
          <td><strong>Uninsulated Attic</strong></td>
          <td><strong>Existing 3–4 inches of Insulation</strong></td>
        </tr>
        <tr>
          <td><strong>1</strong></td>
          <td><strong>Value of the CR30–R49</strong></td>
          <td><strong>R19–R38</strong></td>
        </tr>
        <tr>
          <td><strong>2</strong></td>
          <td><strong>R30–R49</strong></td>
          <td><strong>R38–R49</strong></td>
        </tr>
        <tr>
          <td><strong>3</strong></td>
          <td><strong>R30–R49</strong></td>
          <td><strong>R38–R49</strong></td>
        </tr>
        <tr>
          <td><strong>4 EXCEPT MARINE</strong></td>
          <td><strong>R60</strong></td>
          <td><strong>R49</strong></td>
        </tr>
        <tr>
          <td><strong>4 MARINE AND 5</strong></td>
          <td><strong>R60</strong></td>
          <td><strong>R49</strong></td>
        </tr>
        <tr>
          <td><strong>6</strong></td>
          <td><strong>R60</strong></td>
          <td><strong>R49</strong></td>
        </tr>
        <tr>
          <td><strong>7 AND 8</strong></td>
          <td><strong>R60</strong></td>
          <td><strong>R49</strong></td>
        </tr>
        </tbody>
      </Table>
    </Modal>
	)
});

export default RValModal;

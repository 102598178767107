import DocLink from './DocumentLink';

let DocList = ({type_name, documents}) => (
  <ul className="list-unstyled doc-link-list">
    {documents.map((doc,i) =>
      <li key={i}>
        <DocLink
          type="list"
          document={doc}
          text={doc.value}
          type_name={type_name}
          track={{'element-location': 'accordion'}}
        />
      </li>
    )}
  </ul>
);

export default DocList;

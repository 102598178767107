import { Component, useState } from "react";
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";

const HeaderRow = styled.tr`
    border-top: 3px solid #BCBCBC;
    font-weight:700;
    font-size: 14px;

    td:first-child{
      font-size: 16px;
    }
`;

const Offset = styled.tr`
    background-color: #f8f8f8;
    border-top: 1px solid #bcbcbc;
    border-bottom: 1px solid #bcbcbc;
    padding: 13px 8px 7px 8px;
    text-align: center;
    width: 20%;
    min-width: 115px;
`;

const ShingleTable = styled.table`
  border-collapse: collapse;
  font-size: 14px;
  overflow-x: auto;
  display: block;
 td {
    padding: 9px 8px 7px 8px;
    text-align: center;
    width: 20%;
    min-width: 115px;
    border-top: 2px solid #BCBCBC;
    border-bottom: 2px solid #BCBCBC;
    border-right: 1px solid #BCBCBC;
  }
  tr > :last-child{
    border-right: none;
  }
`;

class DurationShingleTable extends Component {

  render() {

    return (

      <ShingleTable>
        <tbody>
        <HeaderRow>
          <td>ATTRIBUTES</td>
          <td>Duration®</td>
          <td>Duration® Designer</td>
          <td>Duration MAX®</td>
          <td>Duration® Premium</td>
          <td>Duration FLEX®</td>
          <td>Duration STORM®</td>
          <td>Duration® COOL Plus</td>
          <td>Duration® COOL</td>
        </HeaderRow>
       <tr>
         <td>Price</td>
         <td>$$$</td>
         <td>$$$</td>
         <td>$$$$</td>
         <td>$$$</td>
         <td>$$$$</td>
         <td>$$$$</td>
         <td>$$$</td>
         <td>$$$</td>
       </tr>
        <HeaderRow>
          <td>COLOR</td>
        </HeaderRow>
        <tr>
          <td>TruDefinition® Platform</td>
          <td><i className="fa fa-check" aria-hidden="true"></i></td>
          <td><i className="fa fa-check" aria-hidden="true"></i></td>
          <td><i className="fa fa-check" aria-hidden="true"></i></td>
          <td>-</td>
          <td><i className="fa fa-check" aria-hidden="true"></i></td>
          <td><i className="fa fa-check" aria-hidden="true"></i></td>
          <td><i className="fa fa-check" aria-hidden="true"></i></td>
          <td><i className="fa fa-check" aria-hidden="true"></i></td>
        </tr>
        <Offset>
          <td>Available Colors</td>
          <td>18 Colors Available Regionally</td>
          <td>8 Colors Available Regionally</td>
          <td>10 Colors Available Regionally</td>
          <td>6 Colors Available Regionally</td>
          <td>9 Colors Available Regionally</td>
          <td>7 Colors Available Regionally</td>
          <td>6 Colors Available Regionally</td>
          <td>10 Colors Available Regionally</td>
        </Offset>
        <HeaderRow>
          <td>PERFORMANCE</td>
        </HeaderRow>
        <Offset>
          <td>SureNail® Technology</td>
          <td><i className="fa fa-check" aria-hidden="true"></i></td>
          <td><i className="fa fa-check" aria-hidden="true"></i></td>
          <td><i className="fa fa-check" aria-hidden="true"></i></td>
          <td><i className="fa fa-check" aria-hidden="true"></i></td>
          <td><i className="fa fa-check" aria-hidden="true"></i></td>
          <td><i className="fa fa-check" aria-hidden="true"></i></td>
          <td><i className="fa fa-check" aria-hidden="true"></i></td>
          <td><i className="fa fa-check" aria-hidden="true"></i></td>
        </Offset>
        <tr>
          <td>Limited Lifetime Warranty <a href = "https://www.owenscorning.com/en-us/roofing/duration-series-shingles#disclaimer3">³</a></td>
          <td><i className="fa fa-check" aria-hidden="true"></i></td>
          <td><i className="fa fa-check" aria-hidden="true"></i></td>
          <td><i className="fa fa-check" aria-hidden="true"></i></td>
          <td><i className="fa fa-check" aria-hidden="true"></i></td>
          <td><i className="fa fa-check" aria-hidden="true"></i></td>
          <td><i className="fa fa-check" aria-hidden="true"></i></td>
          <td><i className="fa fa-check" aria-hidden="true"></i></td>
          <td><i className="fa fa-check" aria-hidden="true"></i></td>
        </tr>
        <Offset>
          <td>Wind Resistance <a href = "https://www.owenscorning.com/en-us/roofing/duration-series-shingles#disclaimer4">⁴</a></td>
          <td>130 MPH</td>
          <td>130 MPH</td>
          <td>130 MPH</td>
          <td>130 MPH</td>
          <td>130 MPH</td>
          <td>130 MPH</td>
          <td>130 MPH</td>
          <td>130 MPH</td>
        </Offset>
        <tr>
          <td>Algae Resistance <a href = "https://www.owenscorning.com/en-us/roofing/duration-series-shingles#disclaimer4">⁴</a></td>
          <td>10 Years</td>
          <td>10 Years</td>
          <td>-</td>
          <td>-</td>
          <td>10 Years</td>
          <td>10 Years</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <Offset>
          <td>UL2218 Impact Resistance Rating</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>Class 4</td>
          <td>Class 4</td>
          <td>-</td>
          <td>-</td>
        </Offset>
        <tr>
          <td>Solar Reflectivity</td>
          <td>1 Color Available Regionally</td>
          <td>-</td>
          <td>2 Colors</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>6 Colors</td>
          <td>10 Colors</td>
        </tr>
        <Offset>
          <td>WeatherGuard® Technology</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td><i className="fa fa-check" aria-hidden="true"></i></td>
          <td>-</td>
          <td>-</td>
        </Offset>
        <HeaderRow><td>AVAILABILITY</td></HeaderRow>
        <Offset>
          <td>Shingle Availability</td>
          <td>Nationwide <br></br> <a href = "https://www.owenscorning.com/roofing/shingles/trudefinition-duration/">View Shingles</a></td>
          <td>Nationwide <br></br> <a href = "https://www.owenscorning.com/roofing/shingles/trudefinition-duration-designer/">View Shingles</a></td>
          <td>Regionally</td>
          <td>Regionally</td>
          <td>Regionally</td>
          <td>Regionally</td>
          <td>Regionally</td>
          <td>Regionally</td>
        </Offset>
       </tbody>
      </ShingleTable>
    );
  };
};

export default DurationShingleTable;

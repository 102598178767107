import PropTypes from 'prop-types';

import { Divider as StyledDivider } from '../result-block-styled-components';

const Divider = ({ prior, contactInfo = [] }) => {
  const last = contactInfo[contactInfo.length - 1];
  if (prior !== last && contactInfo.includes(prior)) {
    // show a divider when the prior component is showing
    // unless it's the last contact info component
    return <StyledDivider>|</StyledDivider>;
  }
  return null;
};

Divider.propTypes = {
  prior: PropTypes.string,
  contactInfo: PropTypes.array,
};

Divider.defaultProps = {
  prior: '',
  contactInfo: [],
};

export default Divider;

import React, { useState } from 'react';
import { jsx, css } from '@emotion/react';
import LabelWrapper from '../../LabelWrapper';
import Text from '../../../OC/oc-text';
import { CountryAndStates } from './country_state';
import SelectField from './select_field';
import styled from '@emotion/styled';
import { getRequiredSuffix } from "../../../../helpers/form_libs";

const addressField = css`
  input, select {
    margin-bottom: 10px;
  }

  .error{
    color: #d4074d;
    font-size: 12px;
    font-weight: bold;
  }

  .FieldsRow {
    display: flex;
    flex-direction: row;

    @media (max-width: 700px) {
      flex-direction: column;
    }

    .country-select.fieldItem {
      width: 100%;
    }

    .fieldItem {
      width: 50%;

      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
`
const mr20 = css`
  margin-right: 20px;
`
const mb20 = css`
  margin-bottom: 1em;
`

const Error = styled.p`
  color: #940420 !important;
  font-size: 12px;
  font-weight: bold;
`;
// TODO: We may want to collapse AddressField and AddressStateSelect into one component
// and use some sort of prop to order to position of the address fields to be more flexible

export default function AddressStateSelect({formData, onChange, schema, uiSchema, errorSchema, formContext}) {
  const [state, setState] = useState([]);
  const optionalFocused = formContext?.optionalFocused;

  // BUG: city or zip field if entered and deleted, formData = { city: "", zip: "" }
  // Submits as is even if the field is required
  const deleteEmptyField = () => {
    Object.keys(formData).forEach(key => {
      if (formData[key] === ''){
        delete formData[key];
      }
    })
  }

  return (
    <div css={addressField}>
        <SelectField
          options={CountryAndStates}
          selectTitle='Select Country / Region'
          value={formData?.country || ''}
          error={errorSchema.country}
          required={(schema.required || []).includes('country')}
          handleChange={(e) => {
            onChange({...formData, country: e.target.value});
            const currentCountry = CountryAndStates.find((item) => item.label === e.target.value);
            setState(currentCountry.states);
          }}
          name={schema.properties.country.title?.trim?.()}
          css={mb20}
          elementType={schema.properties.country.type}
          formContext={formContext}
        />

      <div className='fieldItem' css={mb20}>
        <SelectField
          selectTitle='Select State / Province'
          options={state}
          error={errorSchema.country}
          value={formData?.state || ''}
          required={(schema.required || []).includes('state')}
          handleChange={(e) => onChange({...formData, state: e.target.value})}
          name={schema.properties.state.title?.trim?.()}
          elementType={schema.properties.country.type}
          formContext={formContext}
        />
      </div>

      <div css={mb20}>
        <LabelWrapper htmlFor="address-line1">
          <Text content={ schema.properties.addressLine1.title?.trim?.() } />{getRequiredSuffix(optionalFocused, (schema.required || []).includes('addressLine1'), schema.properties.addressLine1.type)}
        </LabelWrapper>
        <input
          id="address-line1"
          className="form-control"
          type="text"
          placeholder={uiSchema?.addressLine1?.placeholder}
          onChange={(e) => onChange({...formData, addressLine1: e.target.value})}
          onBlur={deleteEmptyField}
          name={schema.properties.addressLine1.title}
          value={formData?.addressLine1 || ''}
          required={(schema.required || []).includes('addressLine1')}
        />
        { (errorSchema.addressLine1 && errorSchema.addressLine1.__errors.length > 0) &&
            <Error aria-describedby={'addressLine1'} aria-errormessage className="error" role="alert">
              Please enter a valid address
            </Error>
          }
      </div>
      {/* Making address line 2 optional */}
      { schema.properties?.addressLine2 &&
        <div css={mb20}>
          <LabelWrapper htmlFor="address-line2">
            <Text content={ schema.properties.addressLine2.title?.trim?.() } />{getRequiredSuffix(optionalFocused, (schema.required || []).includes('addressLine2'), schema.properties.addressLine2.type)}
          </LabelWrapper>
          <input
            id="address-line2"
            className="form-control"
            type="text"
            placeholder={uiSchema?.addressLine2?.placeholder}
            name={schema.properties.addressLine2.title}
            onChange={(e) => onChange({...formData, addressLine2: e.target.value})}
            value={formData?.addressLine2 || ''}
          />
        </div>
      }
      <div className="FieldsRow">
        <div className='fieldItem' css={mr20}>
          <LabelWrapper htmlFor="city">
            <Text content={ schema.properties.city.title?.trim?.() } />{getRequiredSuffix(optionalFocused, (schema.required || []).includes('city'), schema.properties.city.type)}
          </LabelWrapper>
          <input
            name={schema.properties.city.title}
            id="city"
            className="form-control"
            type="text"
            placeholder={uiSchema?.city?.placeholder}
            onChange={(e) => onChange({...formData, city: e.target.value})}
            onBlur={deleteEmptyField}
            value={formData?.city || ''}
            required={(schema.required || []).includes('city')}
          />
          { (errorSchema.city && errorSchema.city.__errors.length > 0) &&
            <Error aria-describedby={'city'} aria-errormessage className="error" role="alert">
              Please enter a valid {schema.properties.city.title}
            </Error>
          }
        </div>
        <div className="fieldItem">
          <LabelWrapper htmlFor="zip">
            <Text content={ schema.properties.zip.title?.trim?.() } />{getRequiredSuffix(optionalFocused, (schema.required || []).includes('zip'), schema.properties.zip.type)}
          </LabelWrapper>
          <input
            name={schema.properties.zip.title}
            maxLength={schema.properties.zip?.maxLength || 6}
            id="zip"
            className="form-control"
            type="text"
            placeholder={uiSchema?.zip?.placeholder}
            onChange={(e) => onChange({...formData, zip: e.target.value})}
            onBlur={deleteEmptyField}
            value={formData?.zip || ''}
            required={(schema.required || []).includes('zip')}
          />
          { (errorSchema.zip && errorSchema.zip.__errors.length > 0) &&
            <Error aria-describedby={'zip'} aria-errormessage className="error" role="alert">
              Please enter a valid {schema.properties.zip.title}
            </Error>
          }
        </div>
      </div>
    </div>
  );
}

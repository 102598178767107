import * as timeago from 'timeago.js';

const EN_US = ['second', 'minute', 'hour', 'day', 'week', 'month', 'year'];

const localeFunc = (diff, idx) => {
  if (idx === 0) return ['just now', 'right now'];
  let unit = EN_US[Math.floor(idx / 2)];
  if (diff > 1) unit += 's';
  if (unit === 'seconds') diff = '';
  return [`${diff} ${unit} ago`, `in ${diff} ${unit}`];
};
timeago.register('short-en-us', localeFunc);

export const timeAgo = (timestamp) => (
  timeago.format(timestamp, 'short-en-us')
);

export const formatDatetime = (value) => new Intl.DateTimeFormat('en-US', { timeStyle: "medium", dateStyle: "short" }).format(new Date(value));

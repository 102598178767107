import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useAccessibleInteractivity } from "../../../hooks";
import Theme from '../../../themes/index';
import PropTypes from 'prop-types';

const RadioButtonLabel = styled.label`
  align-items: flex-start;
  color: ${props => props.disabled ? '#666666' : '#000000' };
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  opacity: ${props => props.disabled ? .5 : 1 };
  position: relative;

  &:hover {
    cursor: ${props => props.disabled ? 'default' : 'pointer' };
  }

  &:focus {
    outline-offset: ${props => props.disabled ? '0' : '2px' };
    outline: ${props => props.disabled ? 'none' : `${props.themeColor} solid 2px` };
  }

  &:focus:not(:focus-visible) {
    outline: none !important;
  }

  &:focus-visible {
    outline-offset: ${props => props.disabled ? '0' : '2px' };
    outline: ${props => props.disabled ? 'none' : `${props.themeColor} solid 2px` };
  }

  input[type="radio"]:checked {
    background-color: white;
    border-color: ${props => props.disabled ? '#666666' : props.themeColor };
  }

  input[type="radio"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #D1D1D1;
    cursor: ${props => props.disabled ? 'default' : 'pointer' };
    height: 16px;
    margin: 0 8px 0 0;
    width: 16px;
  }

  input[type="radio"] ~ .radio {
    background: ${props => props.themeColor};
    border-radius: 6px;
    display: none;
    height: 8px;
    left: 4px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
  }

  input[type="radio"]:checked ~ .radio {
    display: block;
  }
`;

const ImageBlock = styled.div`
  display: inline-block;
  height: 75px;
  margin-bottom: 16px;
  position: relative;
  width: 75px;
`;

const IconContainer = styled(ImageBlock)`
  svg {
    bottom: 0;
    // fill: ${props => props.checked ? props.themeColor : '#585858' };
    left: 0;
    position: absolute;
  }
`;

const Image = styled(ImageBlock)`
  background-color: transparent;
  background-image: url('${props => props.imageUrl}');
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: contain;
`;

const InputContainer = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`;

const ImageRadioButton = ({
  children,
  defaultChecked=false,
  disabled=false,
  icon,
  imageAlt,
  imageUrl,
  name,
  themeColor = Theme.colors.brand,
  onChange,
  value,
  ...props
}) => {
  const [checked, setChecked] = useState(defaultChecked);
  const radioButton = useRef(null);

  const updateChecked = () => {
    setChecked(!checked);
    {onChange && onChange}
  }

  return (
    <RadioButtonLabel
      disabled={disabled}
      role="button"
      tabIndex="-1"
      themeColor={themeColor}
    >
      {icon &&
        <IconContainer checked={checked}>
          {icon}
        </IconContainer>
      }

      {imageUrl &&
        <Image
          aria-label={imageAlt || `${value} radio selector`}
          role="img"
          imageUrl={imageUrl}
        />
      }

      <InputContainer>
        <input
          defaultChecked={checked}
          disabled={disabled}
          name={name}
          onChange={updateChecked}
          readOnly={disabled ? "readonly" : null}
          ref={radioButton}
          tabIndex={disabled ? "-1" : "0"}
          type="radio"
          value={value}
          { ...props }
        />
        { children }
        <span
          aria-checked={checked}
          aria-disabled={disabled}
          className="radio"
          role="checkbox"
        />
      </InputContainer>
    </RadioButtonLabel>
  );
};

export default ImageRadioButton;

ImageRadioButton.propTypes = {
  /* This is where you put the label of the checkbox */
  children: PropTypes.string,
  /* If you want the checkbox checked on load */
  defaultChecked: PropTypes.bool,
  /* Set this to true, if you want the checkbox disabled */
  disabled: PropTypes.bool,
  /* Pass an Icon component for the image */
  // icon: Proptypes.element, // Using type 'element' seems to break the story?
  /* Set alt text for the image */
  imageAlt: PropTypes.string,
  /* Set the image url to be displayed on the input */
  imageUrl: PropTypes.string,
  /* Set a name for the radio group */
  name: PropTypes.string,
  /* Hex value for color theme */
  themeColor: PropTypes.string,
  /* Call the function you want to fire when changed */
  onChange: PropTypes.func,
  /* Set a value for the radio */
  value: PropTypes.string
}

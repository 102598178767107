import { define, ui, when, select } from '@owenscorning/pcb.alpha';
import React from 'react';

export default define`Groups`('0.0.1')({
  view: () => <div> </div>,
  edit: ({ groups }) => (
    <Subschema>
      {ui`List`.of(
        ui`Form`.of({
          title: ui`Text`({ label: 'Title', default: select`../groupId` }),
          hideHeading: ui`Switch`({
            label: 'Hide Group Heading',
            default: false,
            tip: 'Heading bar hides, but the group elements remain visible.',
          }),
          groupId: ui`Hidden`(),
          templated: ui`Hidden`({ value: true }),
          thumbnail: ui`Image`({ label: 'Thumbnail Image' }),
          hasLearnMore: ui`Switch`({
            label: 'Show "Learn More" dropdown',
            default: false,
          }),
          learnMoreContent: ui`Text/Rich`({
            label: 'Learn More Content',
            visible: when`../hasLearnMore`.is.equal
              .to(true)
              .then(true)
              .otherwise(false),
          }),
        })
      )({
        title: 'groupId',
        max: groups.length,
      })}
    </Subschema>
  ),
});

import { updateWith, get } from 'lodash';

const replaceInString = (string, vars) =>
  string.replace(/{([a-z0-9]*)}/gi, (_match, $1) => vars[$1]);

const stringReplacer = (vars = {}) => string =>
  string && replaceInString(string, vars);

// this is to avoid the used befored defined error, since they reference each other
let updateInObject;
const updateInArray = (array, paths, replacer) => {
  array.forEach(item => {
    updateInObject(item, paths, replacer);
  });
};

updateInObject = (obj, paths, replacer) => {
  Object.entries(paths).forEach(([path, nextPaths]) => {
    const value = get(obj, path);
    if (Array.isArray(value)) {
      updateInArray(value, nextPaths, replacer);
    } else {
      updateWith(obj, path, replacer);
    }
  });
};

const interpolatePaths = paths => (content, vars) => {
  if (!content) {
    return;
  }
  const replacer = stringReplacer(vars);

  updateInObject(content, paths, replacer);
};

// whitelist keys. when it encounters arrays, it applies to all.
const basicContentPaths = {
  'bodyCopy.ops': { insert: true },
  'heading.ops': { insert: true },
  ctas: { text: true },
  prehead: true,
};

export const interpolateString = (string, vars) => stringReplacer(vars)(string);

// mutates content in place
export const interpolateContent = (content, obj) =>
  interpolatePaths(basicContentPaths)(content, obj);

import PropTypes from 'prop-types';
import React from 'react';

import TabularOptions from './TabularOptions';
import UnorderedOptions from './UnorderedOptions';

function OptionList({
  options,
  selectId,
  removeId,
  isSelected,
  tabularOptions,
}) {
  const toggleOption = event => {
    const { id } = event.target;

    if (isSelected(id)) {
      removeId(id);
    } else {
      selectId(id);
    }
  };

  if (tabularOptions) {
    return (
      <TabularOptions
        options={options}
        isSelected={isSelected}
        toggleOption={toggleOption}
      />
    );
  }

  return (
    <UnorderedOptions
      options={options}
      isSelected={isSelected}
      toggleOption={toggleOption}
    />
  );
}

OptionList.propTypes = {
  options: PropTypes.array.isRequired,
  selectId: PropTypes.func.isRequired,
  removeId: PropTypes.func.isRequired,
  isSelected: PropTypes.func.isRequired,
  tabularOptions: PropTypes.bool,
};

OptionList.defaultProps = {
  tabularOptions: false,
};

export default OptionList;

import { define } from '@owenscorning/pcb.alpha';

export default define`Copy`('0.0.1')({
  batch: () => UI.Theme.Component.Group(
    'copy',
    ({ children, ...props }) => <span { ...props } >
      <UI.Text>{ children }</UI.Text>
    </span>
  )
});

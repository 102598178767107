import { useEffect, useState } from 'react';
import qs from 'qs';
import { define, ui } from '@owenscorning/pcb.alpha';
import _ from 'lodash';
import cms_api from '../../../../../cms_api';
import { connectLocation } from '../../../../location/LocationConnectors';

const getStandardsData = _.memoize(
  async () => {
    const response = await cms_api.get_single_contents({
      filter: {
        type: 'Cms::Content::Attribute',
        language_iso_code: Board.build.language,
        route: '/roofing',
        name: 'Standards',
      },
      fields: {
        '*': 'contents',
      },
    });
    return _.keyBy(response?.items, r => r.value);
  }
);

const Renderer = connectLocation(({
  richText,
  t,
  locale: { code }
}) => {
  return (
    <UI.Box margin={ ['0', '0'] }>
      <UI.H3>{ t('pdp.roofing.sections.applicable_codes_and_standards') }</UI.H3>
      <UI.Text>{ richText }</UI.Text>
    </UI.Box>
  );
});

const ApplicableStandards = define`Applicable Standards & Approvals`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/Placeholder`,
  view: ({ value }) => {
    const [lookup, setLookup] = useState({});
    useEffect(() => {
      getStandardsData().then(lookup => setLookup(lookup))
    }, [])

    if (!lookup) {
      return null;
    }

    const standards = Board.settings.attributes?.standards;
    if (_.isEmpty(standards)) {
      return null;
    }

    const rich = {
      ops: standards.filter(item => lookup[item]?.label).map(item => [
        {
          "insert": lookup[item].label
        },
        {
          "attributes": {
            "list": "bullet"
          },
          "insert": "\n"
        },
      ]).flat()
    }

    return <Renderer richText={rich} />
  }
})

export default ApplicableStandards;

import { css } from '@emotion/css';

export const cardStyles = css`
  cursor: pointer;
  .selectedCard {
    & > :first-child {
      border: 5px solid #d40f7d;
    }

    .body-font {
      color: #d40f7d;
    }
  }

  .shingle-name-prehead {
    font-size: 14px;
  }

  .body-font {
    font-size: 16px;
  }

  .more-shingles-card {
    div {
      background: #e6e6e6;
      height: 146.57px;

      button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        border: 0;
        height: 100%;
        width: 100%;
      }

      p {
        font-size: 24px;
        font-weight: 700;
        color: #000;
        text-decoration: underline;
        display: flex;
      }
    }
  }

  .show-less {
    margin-bottom: 24px;

    div {
      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
      }
    }
  }
`;

export const revertOriginalButtonStyles = css`
  display: flex;
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  font-family: 'OCRoboto', sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 20px;

  &:hover {
    color: #d40f7d;

    .chevron::before {
      border-top-color: #d40f7d;
    }
  }

  .chevron {
    position: relative;
    display: block;
    height: 16px;
    transform: rotate(-90deg);
    margin-left: 25px;
    margin-top: 11px;

    &:hover {
      color: #d40f7d;
    }
  }

  .chevron::before,
  .chevron::after {
    position: absolute;
    display: block;
    content: '';
    border: 8px solid transparent; /*Adjust chevron size*/
  }

  .chevron::before {
    top: 0;
    border-top-color: #000;
  }

  .chevron::after {
    top: -1.5px; /*Adjust thickness*/
    border-top-color: #fff; /*Match chevron background colour*/
  }
`;

import { jsx, css } from '@emotion/react';
import { define, ui, when } from '@owenscorning/pcb.alpha';

import Renderer from './../../../../OC/PageBuilder/ContactUs.jsx';

// TODO - i'd rather the defaults go the other way, but baby steps...
import { Presets, availablePresets, defaultPreset } from '../../../../OC/PageBuilder/ContactUs';

export default define`Contact Us`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/ContactUs`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value }) => <Renderer { ...value } />,
  edit: {
    preset: ui`Choices`.of(availablePresets[PB_SITE])({
      label: 'Preset',
      visible: PB_SITE === 'www.owenscorning.com',
      default: defaultPreset[PB_SITE],
    }),
    custom: [ {
      title: ui`Text`({ label: 'Title', default: Presets.pink.title }),
      text: ui`Text`({ label: 'Text', default: Presets.pink.text }),
      phoneLabel: ui`Text`({ label: 'Phone Label', default: Presets.pink.phoneLabel }),
      phoneReal: ui`Text`({ label: 'Real Phone Number', default: Presets.pink.phoneReal }),
      email: ui`Text`({ label: 'Email Address', default: Presets.pink.email }),
      emailUsText: ui`Text`({ label: '"Email Us" Label', default: 'Email Us' }),
      callUsText: ui`Text`({ label: '"Call Us" Label', default: 'Call Us' }),
      formText: ui`Text`({ label: '"Form" Label', default: 'Form' }),
      form: ui`Modules/Page/Form`,
    }, {
      label: 'Custom',
      visible: when`../preset`.is.equal.to('custom')
    } ]
  },
});

import {jsx, css} from '@emotion/react';
import { Component } from 'react';
import styled from "@emotion/styled"
import {get, set, cloneDeep, isEqual} from 'lodash';
import NumberFormat from 'react-number-format';
import {fiberglassData} from './static'
import {ComparisonTable} from './ComparisonTable';
import {calculatorConnector, dataConnector} from './FiberglassConnectors.jsx';
import {calculatorTracker} from '../CalculatorTracker.jsx';

const LocalFormat = ({value}) => (
  <NumberFormat displayType="text" value={value} prefix="$" decimalScale={2} fixedDecimalScale={true}/>
)
const XCalculation = ({what, rvalue, calculations, decimalScale = 2, fixedDecimalScale = true}) => {
  let value = get(calculations, rvalue + '.' + what)
  if(what.includes('percent'))
    return <NumberFormat displayType="text" value={decimalScale > 0 ? Math.round(value * (10 * decimalScale)) / (10 * decimalScale) : Math.round(value * 100)} suffix="%" decimalScale={decimalScale ? decimalScale : 1} fixedDecimalScale={decimalScale > 0 ? fixedDecimalScale : false}/>
  return (
    <NumberFormat displayType="text" value={decimalScale > 0 ? value : Math.round(value)} prefix="$" decimalScale={decimalScale ? decimalScale : 2} thousandSeparator={true} fixedDecimalScale={decimalScale > 0 ? fixedDecimalScale : false}/>
  )
}
const Calculation = dataConnector(XCalculation);

const XData = ({what, rvalue, data, multiplier = 1, decimalScale = 2, ...props}) => {
  let dataString = what.replace(/RV/g, rvalue);
  let value = get(data, dataString) * multiplier;

  return ( <NumberFormat decimalScale={decimalScale} displayType="text" value={decimalScale > 0 ? value : Math.round(value)} {...props} /> )
}
const Data = dataConnector(XData);

const calcLayout = css`
  @media (min-width: 1200px) {
    display: grid;
    column-gap: 30px;
    row-gap: 24px;
    grid-template-columns: 1fr 2fr;
  }
`

const footnotes = css`
  list-style: none;
  counter-reset: localCounter;
  font-size: 12px;
  padding-left: 0;

  li {
    counter-increment: localCounter;
    margin-bottom: 3px;

    &::before {
      content: counter(localCounter);
      font-size: 8px;
      margin-right: 3px;
      position: relative;
      bottom: 2px;
    }
  }
`

class XCalculatorFiberglass extends Component {
  constructor(props) {
    super(props);

    let initialPrices = {
      owens: get(props, 'prices.owens', 50.00),
      knauf: get(props, 'prices.knauf', 49.50),
      johns: get(props, 'prices.johns', 49.50),
      cteed: get(props, 'prices.cteed', 49.50),
    }
    this.props.setPrices(initialPrices);
    this.makeCalculations();

    this.updatePrice = this.updatePrice.bind(this);
    this.updateR = this.updateR.bind(this);
    this.makeCalculations = this.makeCalculations.bind(this);


  }
  makeCalculations() {
    const {rvalue, data, prices, setCalculations} = this.props;
    let calculations = {};

    for(const company of ["owens", "knauf", "johns", "cteed"]) {
      set(calculations, [rvalue, '1000sq', company], prices[company] * data[rvalue][rvalue + ' Bags / 1000ft'][company]);
      set(calculations, [rvalue, '2000sq', company], prices[company] * data[rvalue][rvalue + ' Bags / 1000ft'][company] * 2);

      // case 'cost_diff_percent':
      if(company === 'owens') {
        set(calculations, [rvalue, 'cost_diff_percent', company], '-');
      } else {
        let oc_cost = get(calculations, [rvalue, '1000sq', 'owens'])
        let cur_cost = get(calculations, [rvalue, '1000sq', company])

        set(calculations, [rvalue, 'cost_diff_percent', company], ((cur_cost/oc_cost) - 1) * 100);
      }

      // case 'cost_yearly':
      if(company === "owens") {
        set(calculations, [rvalue, 'cost_yearly', company], prices[company] * 200 * 5 * 50)
      } else {

        let oc_yearly = get(calculations, [rvalue, 'cost_yearly', 'owens'])
        let percents = get(calculations, [rvalue, 'cost_diff_percent', company]) + 1
        set(calculations, [rvalue, 'cost_yearly', company], oc_yearly + ( oc_yearly * (percents / 100) ))
      }

      if(company === "owens") {
        set(calculations, [rvalue, 'cost_yearly_diff', company], '-');
      } else {
        let oc_yearly = get(calculations, [rvalue, 'cost_yearly', 'owens'])
        let cur_yearly = get(calculations, [rvalue, 'cost_yearly', company])
        set(calculations, [rvalue, 'cost_yearly_diff', company], cur_yearly - oc_yearly)
      }
    }

    // Take cost_yearly_diff, get the values,
    let savings = Math.max(...Object.values(get(calculations, [rvalue, 'cost_yearly_diff'])).filter(o => typeof(o) === 'number'));
    set(calculations, [rvalue, 'annual_savings'], savings);

    setCalculations(calculations);

    if (calculations && this.props.logData) {
      this.props.logData({
        project_type: 'Attic',
        rvalue: get(this.props,'rvalue'),
        cteed: get(this.props,'prices.cteed'),
        johns: get(this.props,'prices.johns'),
        knauf: get(this.props,'prices.knauf'),
        owens: get(this.props,'prices.owens')
      });
    }
  }

  componentDidUpdate(prevProps) {
    if(!isEqual(prevProps.prices, this.props.prices))
      this.makeCalculations();
    if(!isEqual(prevProps.rvalue, this.props.rvalue))
      this.makeCalculations();
  }

  updateR(what) {
    return (evt) => {
      this.props.setRValue(evt.target.value);
    }
  }

  updatePrice(who) {
    return (updateObj) => {
      let val = updateObj.value
      this.props.setPrice(who, val);
    }
  }

  render() {
    return (
      <>
        <div className="header">
          <h5 className="prehead">PROPINK® Savings Calculator</h5>
          <h2>
            Calculate Your Potential
          </h2>

          <p>Simplify your life and build your business by making the smart choice. ProPink® L77 Loosefill Insulation brings increased coverage, faster applications and the highest rated R-value per square inch to every job. Use the calculator below to see the difference between ProPink® L77 and competitor products.</p>
        </div>
        <div css={calcLayout}>
          <Sidebar/>
          <div>
            <Results/>
            <CoverageComparison/>
          </div>
        </div>
      </>
    )
  }
}

const calcSidebar = css`
  background: #e6e6e6;
  padding: 25px 15px;

  @media (max-width: 1199px) {
    margin-bottom: 32px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    display: flex;

    > div {
      flex: 1;

      &:first-child {
        margin-right: 16px;
      }

      &:last-child {
        margin-left: 16px;
      }
    }
  }

  @media (max-width: 1199px) {
    margin-left: -15px;
    margin-right: -15px;
  }

  h3 {
    border-bottom: 1px solid #000;
    padding-bottom: 4px;
  }

  > div {
    margin-bottom: 24px;
  }

  input[type="radio"] + label {
    margin-right: 16px;
  }

  input[type="text"] {
    padding: 11px;
    width: 80px;
    text-align: right;
    font-size: 16px;

    @media (min-width: 768px) and (max-width: 1199px) {
      width: 160px;
    }
  }

  .disabled {
    color: #666;
  }
`

const splitField = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
`

const miniHeading = css`
  font-family: OCRoboto;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  text-transform: uppercase;
  margin-bottom: 4px;
  display: block;
`

const bigPink = css`
  font-family: Oswald;
  font-size: 34px;
  font-weight: 500;
  line-height: 34px;
  text-align: left;
  color: #D40F7D;
  text-transform: uppercase;
  margin-bottom: 12px;
  display: block;
`

const Fieldset = styled.fieldset`
  padding: 0;
  border: 0;
  margin: 0;

  legend {
    width: 100%;
  }
`

const XSidebar = ({prices,rvalue, setRValue, setPrice}) => (
  <div className="sidebar" css={calcSidebar}>
    <div>
      <div className="projectType">
        <Fieldset>
          <legend><h3 className="body-font">Project Type</h3></legend>
          <input name="project_type" type="radio" id="attic" value="attic" checked data-track="toggle" data-track-option="Attic" /> <label htmlFor="attic">Attic</label>
          <input name="project_type" type="radio" disabled={true} id="walls" value="walls" data-track="toggle" data-track-option="Walls" /> <label className="disabled" htmlFor="walls">Walls (coming soon)</label>
        </Fieldset>
      </div>
      <br/>
      <div>
        <Fieldset>
          <legend><h3 className="body-font">Desired R-Value</h3></legend>
          <input name="rvalue" id="R19" type="radio" onChange={(e) => setRValue(e.target.value)} checked={rvalue === "R19" } value="R19" data-track="toggle" data-track-option="R19" />
          <label htmlFor="R19">R19</label>

          <input name="rvalue" id="R30" type="radio" onChange={(e) => setRValue(e.target.value)} checked={rvalue === "R30" } value="R30" data-track="toggle" data-track-option="R30" />
          <label htmlFor="R30">R30</label>

          <input name="rvalue" id="R38" type="radio" onChange={(e) => setRValue(e.target.value)} checked={rvalue === "R38" } value="R38" data-track="toggle" data-track-option="R38" />
          <label htmlFor="R38">R38</label>

          <input name="rvalue" id="R49" type="radio" onChange={(e) => setRValue(e.target.value)} checked={rvalue === "R49" } value="R49" data-track="toggle" data-track-option="R49" />
          <label htmlFor="R49">R49</label>

          <input name="rvalue" id="R60" type="radio" onChange={(e) => setRValue(e.target.value)} checked={rvalue === "R60" } value="R60" data-track="toggle" data-track-option="R60" />
          <label htmlFor="R60">R60</label>
        </Fieldset>
      </div>
    </div>
    <div>
      <h3 className="body-font">Pricing Per Bag of Fiberglass</h3>
      <p>Enter your local pricing for an accurate cost comparison.</p>
      <label htmlFor=""></label>
      <div css={splitField}>
        <label htmlFor="">Owens Corning ProPink L77</label>
        <NumberFormat onValueChange={(obj) => setPrice('owens', parseFloat(obj.value))} value={prices.owens} prefix="$" decimalScale={2} fixedDecimalScale={true}/>
      </div>
      <div css={splitField}>
        <label htmlFor="">Knauf Jet Stream Ultra</label>
        <NumberFormat onValueChange={(obj) => setPrice('knauf', parseFloat(obj.value))} value={prices.knauf} prefix="$" decimalScale={2} fixedDecimalScale={true}/>
      </div>
      <div css={splitField}>
        <label htmlFor="">Johns Manville Climate Pro</label>
        <NumberFormat onValueChange={(obj) => setPrice('johns', parseFloat(obj.value))} value={prices.johns} prefix="$" decimalScale={2} fixedDecimalScale={true}/>
      </div>
      <div css={splitField}>
        <label htmlFor="">CertainTeed Insulsafe SP</label>
        <NumberFormat onValueChange={(obj) => setPrice('cteed', parseFloat(obj.value))} value={prices.cteed} prefix="$" decimalScale={2} fixedDecimalScale={true}/>
      </div>
    </div>
  </div>
)
export const Sidebar = calculatorConnector(XSidebar);

const XResults = ({prices}) => (
  <div className="results">
    <ComparisonTable>
      <div className="oc-table-responsive">
        <table className="fiveUp oc-table">
          <thead>
            <tr>
              <th><span className="sr-only">Cost Variables</span></th>
              <th class="colProPink">Owens Corning ProPink L77</th>
              <th>Knauf Jet Stream Ultra</th>
              <th>Johns Manville Climate Pro</th>
              <th>CertainTeed Insulsafe SP</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Cost per bag</td>
              <td className="center"><LocalFormat value={prices.owens} /></td>
              <td className="center"><LocalFormat value={prices.knauf} /></td>
              <td className="center"><LocalFormat value={prices.johns} /></td>
              <td className="center"><LocalFormat value={prices.cteed} /></td>
            </tr>
            <tr>
              <td>Cost for average 2000 sq. ft. attic</td>
              <td class="center colProPink"><Calculation what='2000sq.owens' /></td>
              <td className="center"><Calculation what='2000sq.knauf' /></td>
              <td className="center"><Calculation what='2000sq.johns' /></td>
              <td className="center"><Calculation what='2000sq.cteed' /></td>
            </tr>
            <tr>
              <td>Cost difference compared to L77</td>
              <td className="center"><span className="sr-only">No difference</span></td>
              <td className="center"><Calculation what='cost_diff_percent.knauf' decimalScale="1" /></td>
              <td className="center"><Calculation what='cost_diff_percent.johns' decimalScale="1" /></td>
              <td className="center"><Calculation what='cost_diff_percent.cteed' decimalScale="1" /></td>
            </tr>
            <tr>
              <td>Estimated yearly cost</td>
              <td class="center colProPink"><Calculation what='cost_yearly.owens'/></td>
              <td className="center"><Calculation what='cost_yearly.knauf'/></td>
              <td className="center"><Calculation what='cost_yearly.johns'/></td>
              <td className="center"><Calculation what='cost_yearly.cteed'/></td>
            </tr>
            <tr className="row-highlight">
              <td>Estimated annual cost difference</td>
              <td className="center"><span className="sr-only">No difference</span> - </td>
              <td className="center"><Calculation what='cost_yearly_diff.knauf' decimalScale="0" /><br/>More</td>
              <td className="center"><Calculation what='cost_yearly_diff.johns' decimalScale="0" /><br/>More</td>
              <td className="center"><Calculation what='cost_yearly_diff.cteed' decimalScale="0" /><br/>More</td>
            </tr>
          </tbody>
        </table>
      </div>
    </ComparisonTable>
    <div>
      <p>
        <span css={miniHeading}>Annual savings</span>
        <span css={bigPink}>
          Up to <Calculation what='annual_savings'/>
        </span>
        based on prices entered and a use of 200 bags per day, 5 days per week, 50 weeks a year
      </p>
    </div>
  </div>
)
export const Results = calculatorConnector(XResults);

export const CoverageComparison = () => (
  <div>
    <h3 className="body-font">Coverage Comparison</h3>
    <ComparisonTable>
      <div className="oc-table-responsive">
        <table className="fiveUp oc-table">
          <thead>
            <tr>
              <th><span className="sr-only">Coverage Variables</span></th>
              <th class="colProPink">Owens Corning ProPink L77</th>
              <th>Knauf Jet Stream Ultra</th>
              <th>Johns Manville Climate Pro</th>
              <th>CertainTeed Insulsafe SP</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Bag weight (lbs.)</td>
              <td className="center colProPink"><Data what="all.Bag Weight (LB).owens"/></td>
              <td className="center"><Data what="all.Bag Weight (LB).knauf"/></td>
              <td className="center"><Data what="all.Bag Weight (LB).johns"/></td>
              <td className="center"><Data what="all.Bag Weight (LB).cteed"/></td>
            </tr>
            <tr>
              <td>Maximum coverage per bag (in sq. ft.)</td>
              <td className="center colProPink"><Data what="RV.RV Coverage.owens"/></td>
              <td className="center"><Data what="RV.RV Coverage.knauf"/></td>
              <td className="center"><Data what="RV.RV Coverage.johns"/></td>
              <td className="center"><Data what="RV.RV Coverage.cteed"/></td>
            </tr>
            <tr>
              <td>Minimum bags needed per 1,000 sq. ft.</td>
              <td className="center colProPink"><Data what="RV.RV Bags / 1000ft.owens" decimalScale={2} fixedDecimalScale={true} /></td>
              <td className="center"><Data what="RV.RV Bags / 1000ft.knauf" decimalScale={2} fixedDecimalScale={true} /></td>
              <td className="center"><Data what="RV.RV Bags / 1000ft.johns" decimalScale={2} fixedDecimalScale={true} /></td>
              <td className="center"><Data what="RV.RV Bags / 1000ft.cteed" decimalScale={2} fixedDecimalScale={true} /></td>
            </tr>
            <tr>
              <td>Coverage vs Owens Corning</td>
              <td className="center colProPink"><Data multiplier="100" decimalScale={0} suffix="%" what="RV.Coverage vs L77.owens"/></td>
              <td className="center"><Data multiplier="100" decimalScale={0} suffix="%" what="RV.Coverage vs L77.knauf"/></td>
              <td className="center"><Data multiplier="100" suffix="%" what="RV.Coverage vs L77.johns"/></td>
              <td className="center"><Data multiplier="100" decimalScale={0} suffix="%" what="RV.Coverage vs L77.cteed"/></td>
            </tr>
          </tbody>
        </table>
      </div>
    </ComparisonTable>

    <ol css={footnotes}>
      <li>All values calculated using whole bag amounts needed to complete.</li>
      <li>Annual savings calculated based on prices entered and a use of 200 bags per day, 5 days per week, 50 weeks a year.</li>
    </ol>
  </div>
)

export const CalculatorFiberglass = calculatorTracker('fiberglass', calculatorConnector(XCalculatorFiberglass));

import React from 'react';
import SignaturePad from 'react-signature-pad-component';

export default class SignaturePadWidget extends React.Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.onClear = this.onClear.bind(this);
		this.onResize = this.onResize.bind(this);
		this.signaturePad = null;
	}
	componentDidMount() {
		if (this.signaturePad) {
			if (this.props.value) {
				this.signaturePad.fromDataURL(this.props.value);
			}
		}
		window.addEventListener("resize", this.onResize);
	}
	componentWillUnmount() {
		window.removeEventListener("resize", this.onResize);
	}
	onChange() {
		if (this.signaturePad) {
			this.props.onChange(this.signaturePad.toDataURL());
		}
	}
	onClear() {
		if (this.signaturePad) {
			this.props.onChange(undefined);
		}
	}
	onResize() {
		if (this.signaturePad && this.props.value) {
			this.signaturePad.fromDataURL(this.props.value);
		}
	}
	render() {
		return <SignaturePad clearButton="true" onClear={this.onClear} onEnd={this.onChange} ref={c => this.signaturePad = c} />;
	}
}

import { Quill } from 'react-quill';

export default {
  clipboard: { matchVisual: false },
  keyboard: {
    bindings: {
      linebreak: {
        key: 13,
        shiftKey: true,
        handler: function(range) {
          this.quill.insertEmbed(range.index, 'breaker', true, Quill.sources.USER);
          this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
          return false;
        }
      }
    }
  }
};

import React from 'react';
import { jsx } from '@emotion/react';
import { connectLocation } from "../../../location/LocationConnectors";
import styled from "@emotion/styled";

const ListHeaderElement = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1.5em 0;
  position: relative;
  width: 100%;

  @media screen and (min-width: 1200px) {
    flex-wrap: no-wrap;
    padding-bottom: 2em;
    padding-top: 2em;
  }
`;

const ContentHeader = connectLocation(({ t, children, primaryActive, activatePrimary, secondaryActive, activateSecondary, headerStyle }) => {
  return (
    <ListHeaderElement css={headerStyle}>
      {React.cloneElement(children, {
        primaryActive: primaryActive,
        activatePrimary: activatePrimary,
        secondaryActive: secondaryActive,
        activateSecondary: activateSecondary
      })}
    </ListHeaderElement>
  )
});

// ListHeader.WrappedComponent.displayName = 'ContentHeader';

export default ContentHeader;

import { define, ui, when, s, select } from '@owenscorning/pcb.alpha';
import { Fragment, useState } from 'react';
import styled from '@emotion/styled'

// Components
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { Mobile, Tablet, Desktop } from '../../../../OC/PageBuilder/internals/Devices';
import { Screens } from '../../../../PageBuilder/editing/Viewport';
import { BasicContent } from '../../../../OC/PageBuilder/BasicContent.jsx';
import MediaObject from '../../../../OC/PageBuilder/MediaObject.jsx';
import Data from '../../../Data';

const Sizes = {
  'xl': { [Tablet]: 738, [Desktop]: 1170 },
  'l': { [Tablet]: 738, [Desktop]: 970 },
  'm': { [Tablet]: 482, [Desktop]: 770 },
};

const MediaObjectContainer = styled.div`
  display: flex;
  justify-content: ${ props => props.alignment === 'left' ? 'flex-start' : 'center'};

  .border-b {
    border-bottom: 1px solid black;
  }
`;

const MediaObjectSet = styled.div`
  margin: 0;
  width: auto;

  @media (min-width: ${Screens.Tablet}px) { width: ${({ width }) => `${width.Tablet}px`}; }
  @media (min-width: ${Screens.Desktop}px) { width: ${({ width }) => `${width.Desktop}px`}; }
`;

const Renderer = ({ settings, copy, mediaObjects: mediaObjectsProp, type: { source = 'custom' } = { source: 'custom' }, data }) => {
  const [mediaObjects, setMediaObjects] = useState(mediaObjectsProp);
  const alignment = settings?.alignment || 'left';
  const size = settings?.containerSize?.selection || 'xl';
  const Width = Sizes[size];

  useDeepCompareEffectNoCheck(() => {
    if (source === 'data') {
      const adapter = Data.for('MediaObjectSet')[data?.dataset];
      if (adapter?.old_view) {
        adapter.old_view(data?.parameters).then(result => setMediaObjects(result));
      }
    } else {
      setMediaObjects(mediaObjectsProp)
    }
  }, [source, data, mediaObjectsProp]);

  return (
    <MediaObjectContainer alignment={alignment}>
      <MediaObjectSet size={size} width={Width}>
        <BasicContent {...copy} />
        <UI.Row
          gap-3
          fits={settings?.itemsPerRow || 3}
          tablet-fits={settings?.itemsPerRowTablet || 2}
          mobile-fits-1
          {...((alignment !== "left") && { center: "x" })}
        >
          {mediaObjects?.map((mo, index) => (
            <Fragment key={index}>
              {(settings?.itemsPerRow === '1' && settings?.rowBorder && index > 0) &&
                <div className="border-b" style={{ 'margin-bottom': '24px' }} />
              }
              <MediaObject
                aspectRatio={settings?.aspectRatio}
                iconSize={settings?.iconSize}
                imgSize={settings?.imgSize}
                {...mo}
              />
            </Fragment>
          ))}
        </UI.Row>
      </MediaObjectSet>
    </MediaObjectContainer>
  );
}

const sizesHash = {
  '1': ['Square (1.0) cards', '144x144px'],
  '2': ['Wide (2.0) cards', '144x72px'],
  none: undefined,
  '2.5': ['Extra Wide (2.5) cards', '144x58'],
  '0.77': ['Tall (0.77) cards', '144x188px'],
  custom: ['Custom cards', '144px']
};

export { Renderer };
export default define`Media Object Set`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/MediaObject`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value }) => <Renderer { ...value } />,
  gutterlessForReusable: true,
  edit: {
    copy: ui`Content/Basic`,
    type: ui`Form`.of({
      source: ui`Choices`.of({
        'custom': 'Custom',
        'data': 'Data Driven'
      })({
        label: 'Select Type',
        default: 'custom',
        mode: ui`Choices/Mode/Dropdown`
      }),
      [s._]: ui`Tip`.of('Select the type of media objects you would like to create or pull data from.'),
    })({
      label: 'Select Type',
    }),
    data: ui`Form`.of({
      dataset: ui`Data/Choices`.of('MediaObjectSet'),
      parameters: ui`Data/Parameters`.of('MediaObjectSet')({ dataset: select`../dataset` })
    })({
      label: 'Select Details',
      visible: when`../type/source`.is.equal.to('data').then(true).otherwise(false)
    }),
    imageSizeInfo: ui`ImageSizeInfo`(
      {
        moduleName: 'Media Object Set',
        sizesHash: sizesHash,
        selectedSize: select`../settings/aspectRatio`
      },
    ),
    settings: ui`Form`.of({
      imgSize: ui`Choices`.of({
        'small': 'Default',
        'large': 'Large',
        'xl': 'XL',
        'icon': 'Icon',
      })({
        label: 'Image Size/Type',
        default: 'small',
        visible: when`../../data/dataset`.is.equal.to('documents').and.when`../../type/source`.is.equal.to('data').then(false).otherwise(true)
      }),
      iconSize: ui`Choices`.of({
        'small': 'Small',
        'large': 'Large'
      })({
        label: 'Icon Size',
        default: 'large',
        mode: ui`Choices/Mode/Dropdown`,
        visible: when`../imgSize`.is.equal.to('icon').then(true).otherwise(false)
      }),
      itemsPerRow: ui`Choices`.of({
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4'
      })({
        label: 'Number of items per row (at desktop)',
        default: '3'
      }),
      itemsPerRowTablet: ui`Choices`.of({
        '1': '1',
        '2': '2',
      })({
        label: 'Number of items per row (at tablet)',
        default: '2'
      }),
      aspectRatio: ui`AspectRatio`({
        default: { selection: '1' },
        visible: when`../../data/dataset`.is.equal.to('documents').and.when`../../type/source`.is.equal.to('data').then(false).otherwise(true)
      }),
      alignment: ui`Choices`.of({
        'left': 'Left',
        'center': 'Center'
      })({
        label: 'Alignment',
        default: 'left'
      }),
      containerSize: ui`Form`.of({
        selection: ui`Choices`.of({
          'xl': 'XL',
          'l': 'Large',
          'm': 'Medium',
        })({
          label: 'Container Size (at desktop or tablet)',
          default: 'xl',
          mode: ui`Choices/Mode/Dropdown`,
        })
      }),
      rowBorder: ui`Switch`({ label: 'Insert dividing lines between rows of 1 media object', visible: when`../itemsPerRow`.is.equal.to('1') })
    })({
      label: 'Settings'
    }),
    mediaObjects: ui`List`.of(
      ui`MediaObject`({
        imgSize: select`../../settings/imgSize`,
        iconSize: select`../../settings/iconSize`
      })
    )({
      min: 2, max: 16,
      singular: 'Media Object',
      title: 'heading',
      label: 'Media Objects',
      visible: when `../type/source`.is.equal.to('custom').then(true).otherwise(false)
    })
  },
});

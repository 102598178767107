import _ from 'lodash';
import { define } from '@owenscorning/pcb.alpha';

const Pathing = {}

Pathing.Options = (paths=[]) => _.sortBy(paths.map((path) => ({
    value: path.path || '',
    name: path.segment || 'Site Root',
    children: Pathing.Options(path.children)
  })), (value) => value.name.toLowerCase());
export default define`Pathing`({
  raw: () => Pathing
});

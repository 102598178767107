import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';

const Selected = styled.div`
  padding: 10px;
  background: #eeeeee;
  margin: 0 -15px 15px;
`;

const SelectedTitle = styled.div`
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
`;

export default function SelectedItems({
  categories,
  isSelected,
  optionIdentifier,
}) {
  const categoriesWithSelectedOptions = categories.filter(category =>
    category.options.some(option => isSelected(option.id))
  );

  return (
    <Selected>
      <SelectedTitle>Selected Items:</SelectedTitle>
      <ul>
        {categoriesWithSelectedOptions.map(category => (
          <li key={category.name}>
            {category.name}
            <ul>
              {category.options.map(option => {
                if (isSelected(option.id)) {
                  return <li key={option.id}>{option[optionIdentifier]}</li>;
                }
                return null;
              })}
            </ul>
          </li>
        ))}
      </ul>
    </Selected>
  );
}

SelectedItems.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  isSelected: PropTypes.func.isRequired,
  optionIdentifier: PropTypes.string.isRequired,
};

import PropTypes from 'prop-types';

const CustomizedComponent = ({
  showComponent,
  getProps,
  Component,
  attributes,
  resource,
  options,
  t,
  children,
}) => {
  if (showComponent === false) {
    return null;
  }
  const { show, ...props } = getProps(attributes, resource, options, t);
  if (show === false) {
    return null;
  }

  return (
    <Component {...props} t={t}>
      {props.children || children}
    </Component>
  );
};

CustomizedComponent.propTypes = {
  showComponent: PropTypes.bool,
  getProps: PropTypes.func.isRequired,
  Component: PropTypes.elementType.isRequired,
  attributes: PropTypes.object,
  resource: PropTypes.object,
  options: PropTypes.object,
  t: PropTypes.func.isRequired,
  children: PropTypes.node,
};

CustomizedComponent.defaultProps = {
  showComponent: true,
  attributes: {},
  resource: {},
  options: {},
  children: null,
};

export default CustomizedComponent;

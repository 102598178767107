import styled from '@emotion/styled';
import IconBase from './icon-base';

const IconInfo = ({ className, fill="#6D6D6D", height="14px", hoverColor="#D40F7D", width="14px" }) => (
  <IconBase className={className} fill={fill} height={height} hoverColor={hoverColor} viewBox="0 0 14 14" width={width}>
    <path fillRule="evenodd" clipRule="evenodd" d="M7 0C3.13428 0 0 3.13541 0 7C0 10.8669 3.13428 14 7 14C10.8657 14 14 10.8669 14 7C14 3.13541 10.8657 0 7 0ZM7 3.10484C7.65473 3.10484 8.18548 3.6356 8.18548 4.29032C8.18548 4.94505 7.65473 5.47581 7 5.47581C6.34527 5.47581 5.81452 4.94505 5.81452 4.29032C5.81452 3.6356 6.34527 3.10484 7 3.10484ZM8.24193 10.6129C8.42899 10.6129 8.58064 10.4612 8.58064 10.2742V9.59678C8.58064 9.40972 8.42899 9.25807 8.24193 9.25807H7.90323V6.43548C7.90323 6.24843 7.75157 6.09677 7.56452 6.09677H5.75806C5.57101 6.09677 5.41935 6.24843 5.41935 6.43548V7.1129C5.41935 7.29996 5.57101 7.45161 5.75806 7.45161H6.09677V9.25807H5.75806C5.57101 9.25807 5.41935 9.40972 5.41935 9.59678V10.2742C5.41935 10.4612 5.57101 10.6129 5.75806 10.6129H8.24193Z" />
  </IconBase>
);

export default IconInfo;

import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { navigate } from '../../helpers/sticky_nav';

const useOCStickyNav = ({ wrapperRef, containerRef, navRef, items }) => {
  const history = useHistory();
  const [hovering, setHovering] = useState();

  const centralizeNavHorizontalScroll = navItem => {
    if (!navItem) return;

    const elementLeft = navItem.offsetLeft || 0;
    const differenceFromCenter =
      elementLeft - wrapperRef.current.clientWidth / 2;
    // eslint-disable-next-line no-param-reassign
    containerRef.current.scrollLeft = differenceFromCenter;
  };

  const highlightNavItem = () => {
    const navHeight = navRef.current?.getBoundingClientRect()?.height;
    const current = items.reduce((result, { anchor }) => {
      const element = document.getElementById(anchor);
      if (element) {
        const { top } = element.getBoundingClientRect();
        return top - navHeight <= 0 ? anchor : result;
      }
      return result;
    }, null);
    if (current && current !== hovering) {
      centralizeNavHorizontalScroll(
        document.querySelector(`#stickynav-slider #menu-${current}`)
      );
      setHovering(current);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', highlightNavItem);
    return () => window.removeEventListener('scroll', highlightNavItem);
  }, [highlightNavItem]);

  const onClick = (e, key) => {
    navigate(key);
    history.push(`#${key}`);
    e.preventDefault();
  };

  return { hovering, onClick };
};

export default useOCStickyNav;

// As the presentation is pretty much set, we're using hardcode information to display
// as expected. Eventually all of this should end up in MDMS
export const CATEGORIES = [
  {label: 'build_your_roof.premium_performance', items: ['weatherlock_flex', 'titanium_psu30', 'deck_defense', 'titanium', 'titanium_udl', 'titanium_fire_resistant']},
  {label: 'build_your_roof.premium_wind', items: ['duraridge_hip_ridge']},
  {label: 'build_your_roof.high_performance', items: ['proarmor', 'rhinoroof_u20']},
  {label: 'build_your_roof.for_improved_aesthetics', items: ['decoridge_hip_ridge', 'rizeridge_hip_ridge']},
  {label: 'build_your_roof.commonly_used', items: ['weatherlock_mat', 'weatherlock_g', 'rhinoroof_granulated', 'starter_strip', 'proedge_hip_ridge', 'starter_roll', 'starter_strip_plus']},
  {label: 'build_your_roof.economy_protection', items: ['fiberglas_reinforced']},
  {label: 'build_your_roof.alternate_option', items: [-1]},
  {label: 'build_your_roof.other', items: [-1]},
]

export const RECOMMENDED = [
  'starter_strip',
  'starter_strip_plus',
  'duraridge_hip_ridge',
  'weatherlock_mat',
  'inflow_vent',
  '4_foot_strip_ridge_vent_with_weather_protector',
  'proarmor',
  'trudefinition-duration-flex-estate-gray'
]

export const IGNORED = [
  'titanium_udl50', 'titanium_udl30', 'fiberglas_reinforced_felt',
  'berkshire_hip_ridge', 'proedge_flex_hip_ridge',
  'proedge_storm_hip_ridge', 'longridge_hip_ridge', 'weatherguard_hp_hip_ridge',
  'decoridge_duration_premium_cool', 'weatherlock_specialty_tile_and_metal',
  'weatherlock_specialty_tile_and_metal_fl'
]

export const TRANSFORMATIONS = {
  'titanium_udl': (p) => {
    p.name = "Titanium®";
    return p;
  },
  'proarmor': (p) => {
    p.slug = 'proarmor_new';
    return p;
  },
  'trudefinition-duration': (p) => {
    p.name = 'TruDefinition Duration®';
    return p;
  }
};

export const VENTILATION_EXAMPLE_RIDGE_PRODUCTS =[
  '4_foot_strip_ridge_vent_with_weather_protector', '4_foot_strip_ridge_vent'
];

export const CUSTOMIZE_HARDCODED_PRODUCTS = [
  {uid: "hardcoded_4_foot_strip", name: "VentSure® 4-Foot Strip Heat & Moisture Ridge Vent"},
  {uid: "hardcoded_metal_slant", name: "VentSure® Metal Slant Back Vent"},
  {uid: "hardcoded_intake_vents", name: "VentSure® InFlow® Intake Vents"}
];

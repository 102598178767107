import Blots from './Blots';

const Actions = {};

Blots.Blocks.forEach((blot) => (
  Actions[blot.blotName] = function() {
    this.quill.format(blot.blotName, true);
  }
));

export default Actions;

import styled from '@emotion/styled';
import {useState, useEffect, useRef} from 'react';

// Components
import ProCatForm from './ProCatForm';
import ProCatResults from './ProCatResults';
import {proCatData} from './ProCatData';

const CalculatorContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;

	@media(min-width: 768px) {
		flex-direction: row;
	}
`;

const CalcFormContainer = styled.div`
	background: #e6e6e6;
	padding: 15px;
	width: 100%;

	@media(min-width: 768px) {
		width: 33.333%;
	}
`;

const CalcResultsContainer = styled.div`
	width: 100%;

	@media(min-width: 768px) {
		width: 66.666%
	}
`;

const ProCatCalculator = ({costPerBag, installers, laborRate, markup, footnotes}) => {
	const [input, setInput] = useState({['rvalue']: 'R49', ['costPerBag']: costPerBag, ['installers']: installers, ['laborCost']: laborRate, ['markup']: markup});
	const [proCatInfo, setProCatInfo] = useState({});
	const [resultsVisible, setResultsVisible] = useState(false);
	const scrollToDiv = useRef();

	useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler); 
  }, []);
    
  const scrollHandler = () => {
    if(window.pageYOffset >= scrollToDiv.current.offsetTop) {
    	setResultsVisible(true);
    } else {
    	setResultsVisible(false);
    }
  };

  const inputChange = (e) => {
		const { name, value } = e.target;

    setInput(prevState => (
      { ...prevState, [name]: value }
    ));
	};

	const formSubmit = (e) => {
		e.preventDefault();

		if(input.atticSqFt && input.currentDepth) {
			let setUpTime = 1.5;
			let targetInsulationDepth = proCatData[input.rvalue].thickness;
			let insulationNeeded = targetInsulationDepth - input.currentDepth;
			let dataMatch = Object.values(proCatData).filter(v => v.thickness == insulationNeeded).map(match => (match.numberOfBags));
			let numberOfBags = Math.round(dataMatch * (input.atticSqFt/1000));

			let blowTime = (numberOfBags*7)/60;
			let materialCost = numberOfBags*input.costPerBag;
			let crewTime = blowTime + setUpTime;
			let totalCrewTime = crewTime.toFixed(1)*input.installers;
			let laborCost = totalCrewTime*input.laborCost;
			let totalCost = Number(laborCost) + Number(materialCost);
			let markupPercent = input.markup/100;
			let markup = totalCost*markupPercent;
			let customerCost = totalCost + markup;
			let margin = customerCost - totalCost;
			
			setProCatInfo(prevState => (
	      { ...prevState,
	      	['targetInsulationDepth']: targetInsulationDepth,
	      	['insulationNeeded']: insulationNeeded,
	      	['bagsNeeded']: numberOfBags,
	      	['blowTime']: blowTime,
	      	['totalMaterialCost']: materialCost,
	      	['totalLaborCost']: laborCost,
	      	['totalCost']: totalCost,
	      	['customerCost']: customerCost,
	      	['margin']: margin
	      }
	    ));

			setInput(prevState => ({
			  ...prevState,
			  ['sqFtError']: null,
			  ['currentDepthError']: null
			}));

			const element = scrollToDiv.current;
		  const headerOffset = 110;
		  const elementPosition = element.getBoundingClientRect().top;
		  const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

	    {!resultsVisible &&
		    window.scrollTo({
		      top: offsetPosition,
		      behavior: "smooth"
		    });
	    }

		} else {
			setInput(prevState => ({
			  ...prevState,
			  ['sqFtError']: input.atticSqFt ? null : "Please enter your attic's square ft",
			  ['currentDepthError']: input.currentDepth ? null : "Please enter the current depth of insulation in your attic",
			}));
		}
	}

	return (
		<CalculatorContainer>
			<CalcFormContainer>
				<ProCatForm 
					inputChange={inputChange} 
					input={input} 
					formSubmit={formSubmit}
				/>
			</CalcFormContainer>

			<CalcResultsContainer ref={scrollToDiv} >
				<ProCatResults 
					targetInsulationDepth={proCatInfo.targetInsulationDepth}
					insulationNeeded={proCatInfo.insulationNeeded}
					bagsNeeded={proCatInfo.bagsNeeded}
					blowTime={proCatInfo.blowTime}
					totalMaterialCost={proCatInfo.totalMaterialCost}
					totalLaborCost={proCatInfo.totalLaborCost}
					totalCost={proCatInfo.totalCost}
					customerCost={proCatInfo.customerCost}
					margin={proCatInfo.margin}
					footnotes={footnotes}
				/>
			</CalcResultsContainer>
		</CalculatorContainer>
	)
};

export default ProCatCalculator;
import styled from '@emotion/styled';
import { jsx, css } from '@emotion/react';
import IconBase from './icon-base';

const IconCheck = ({fill, width, height, hoverColor, className}) => (
	<IconBase className={className} viewBox="0 0 12 9" width={width} height={height} fill={fill} hoverColor={hoverColor} >
		<path d="M11.8547 1.0848L10.9136 0.146569C10.7187 -0.0477426 10.4014 -0.0490232 10.205 0.143761L4.30235 5.93454L1.80554 3.44516C1.61065 3.25085 1.29338 3.24957 1.0969 3.44231L0.148199 4.37303C-0.0482736 4.56577 -0.0495681 4.87956 0.145359 5.07391L3.93621 8.85343C4.1311 9.04774 4.44838 9.04902 4.64485 8.85624L11.8519 1.78568C12.0483 1.5929 12.0495 1.27911 11.8547 1.0848Z" />
	</IconBase>
);

export default IconCheck;
import { useRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from "prop-types";
import Theme from '../../../themes';

import { useAccessibleInteractivity } from "../../../hooks";
import { Icon } from '@iconify/react';

// Components
import Image from '../oc-image';

const TabButton = styled.li`
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: ${props => props.tabSize === 'small' ? 'capitalize' : 'uppercase'};
  color: ${props => props.active ? Theme.colors.brand : '#6D6D6D'};
  border-bottom: ${props => props.active ? `4px solid ${Theme.colors.brand}` : '1px solid #6D6D6D'};
  list-style-type: none;
  display: flex;
  flex-direction: ${props => props.tabSize === 'small' ? 'row' : 'column'};
  align-items: center;
  justify-content: flex-end;

  @media(min-width: 1200px) {
    font-size: ${props => props.tabSize === 'small' ? '14px' : '16px'};
    line-height: ${props => props.tabSize === 'small' ? '18px' : '20px'};
  }

  ${props => props.tabSize == 'small' && css`
    padding: ${props.active ? '8px 8px 5px' : '8px'};
  `};

  ${props => props.tabSize == 'large' && css`
    padding: ${props.active ? '8px 24px 5px' : '8px 24px'};

    @media(min-width: 1200px) {
      padding: ${props.active ? '8px 48px 5px' : '8px 48px'};
    }
  `};

  &:hover {
    background: ${props => props.tabSize === 'small' ? Theme.colors.brand : 'transparent'};
    border-color: ${Theme.colors.brand};
    color: ${props => props.tabSize === 'small' ? 'white' : Theme.colors.brand};
    cursor: pointer;
  }

  &:focus {
    outline-offset: 0;
    outline: ${Theme.colors.brand} solid 2px;
  }

  &:focus:not(:focus-visible) {
    outline: none!important;
  }

  &:focus-visible {
    outline-offset: 0;
    outline: ${Theme.colors.brand} solid 2px;
    z-index: 1;
  }

  a {
    text-decoration: none;
    color: inherit;
    display: flex;

    &:hover {
      color: white;
    }
  }
`;

const IconContainer = styled.span`
  margin: ${props => props.tabSize == 'small' ? '0 4px 0 0' : '0 0 8px'};
  display: inline-flex;
  align-items: center;

  svg {
    width: ${props => props.tabSize == 'small' ? '15px' : '50px'};
    height: ${props => props.tabSize == 'small' ? '15px' : '50px'};
  }

  @media(min-width: 1200px) {
    margin: ${props => props.tabSize == 'small' ? '0 4px 0 0' : '0 0 16px'};

    svg {
      width: ${props => props.tabSize == 'small' ? '15px' : '72px'};
      height: ${props => props.tabSize == 'small' ? '15px' : '72px'};
    }
  }
`;


const TabContent = ({ tabGraphic = '', label = '', tabSize = '', icon = '', iconImage = '' }) => {
  const isGraphicText = tabGraphic === 'text';
  const isGraphicIcon = tabGraphic === 'icon';

  return (
    <>
      {!isGraphicText && (
        <IconContainer tabSize={tabSize}>
          {isGraphicIcon ? <Icon icon={icon} /> : <Image src={iconImage} />}
        </IconContainer>
      )}
      {label}
    </>
  )
}


const Tab = ({label, tabSize='small', active, onClick, url, icon, iconImage, className, tabGraphic}) => {
  const tabButton = useRef();
  useAccessibleInteractivity(tabButton, onClick);

  const TabContentElement = <TabContent tabGraphic={tabGraphic} label={label} tabSize={tabSize} icon={icon} iconImage={iconImage} />;

  return (
    <TabButton
      className={ className }
      aria-checked={ active }
      active={active}
      data-track="view-change"
      data-track-tab-name={ label }
      data-track-view={ label }
      icon={icon}
      onClick={ onClick }
      ref={ tabButton }
      role="radio"
      tabSize={tabSize}
    >
      {url
        ? (<a href={url} tabIndex='-1'>{TabContentElement}</a>)
        : TabContentElement
      }
    </TabButton>
  );
}

export default Tab;

Tab.propTypes = {
  /** Set the text of the Tab */
  label: PropTypes.string,
  /** You can set the size of the tabs as small or large. Default is small. */
  tabSize: PropTypes.string,
  /** Set the tab as active or inactive */
  active: PropTypes.bool,
  /** Call the function you would like to execute on click */
  onClick: PropTypes.func,
  /** If you would like the tab to link instead of showing content, use the url prop */
  url: PropTypes.string,
  /** Set the icon for the Tab */
  icon: PropTypes.string,
  /** Set the icon's image */
  iconImage: PropTypes.string
}

import styled from '@emotion/styled';
import IconBase from './icon-base';

const IconMapMarker = ({ className, fill, hoverColor, height = "44px", width = "33px" } ) => (
  <IconBase className={className} viewBox="0 0 33 44" width={width} height={height} fill={fill} hoverColor={hoverColor} aria-hidden="true" role="img">
    <path d="M15.1181 42.9043L15.1176 42.9044L15.1455 42.8851L15.1181 42.9043ZM15.2156 42.836C12.9748 39.5862 11.0648 36.8643 9.43826 34.5463C6.52093 30.3887 4.51538 27.5306 3.14806 25.2564C2.08951 23.4958 1.4324 22.1144 1.03662 20.7777C0.641976 19.4449 0.5 18.1322 0.5 16.5002C0.5 7.66271 7.66271 0.5 16.5002 0.5C25.3376 0.5 32.5003 7.66271 32.5003 16.5002C32.5003 18.1322 32.3583 19.4446 31.9637 20.7771C31.5679 22.1133 30.9108 23.4941 29.8523 25.254C28.4862 27.5254 26.4829 30.3794 23.5697 34.5299C21.9415 36.8497 20.029 39.5745 17.7846 42.8289C17.641 43.0354 17.4497 43.2042 17.2269 43.321C17.0038 43.4381 16.7557 43.4995 16.5037 43.5C16.2518 43.5005 16.0034 43.4402 15.7798 43.3242C15.5562 43.2081 15.3639 43.0398 15.2193 42.8334L15.2156 42.836Z" />
  </IconBase>
);

export default IconMapMarker;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import _ from 'lodash';

// Components
import Image from '../oc-image';
import Heading from './Heading';
import RichText from './RichText';
import CtaList from './CtaList';
import Theme from '../../../themes/index';

// Styled Components
const Spacer = styled.div`
  & + * {
    margin-top: ${ props => props.double ? '32px' : '16px' }
  }

  a {
    &:hover {
      color: ${Theme.colors.brand};
    }
  }
`;

const PostHead = styled.span`
  color: #6D6D6D;
  display: block;
  font-family: "OCRoboto", sans-serif;
  font-size: 16px;
  line-height: 16px;
  margin: 16px 0 0;
  text-transform: uppercase;

  @media (min-width: 1200px) {
    font-size: 18px;
    line-height: 21px;
  }
`;

const BasicContent = ({
  className,
  prehead,
  heading,
  headingClass,
  headingTag,
  posthead,
  bodyCopy,
  banner,
  ctas,
  bodyFont,
  theme,
  children,
  crop,
  ...props
}) => {
  const transform = crop ? { crop: 'crop', ...crop } : null;

  return (
    <>
      { ( !_.isEmpty(prehead) || !_.isEmpty(heading) || !_.isEmpty(bodyCopy) || !_.isEmpty(ctas) ) &&
        <Spacer className={className} double={!_.isEmpty(ctas)} ref={props.slideAnimRef}>
          <Heading
            prehead={ prehead }
            mainHeading={ heading }
            headingClass={headingClass}
            headingTag={headingTag}
            headingFont={ bodyFont && 'body' }
            theme={ theme && 'vidawool' }
          />
          {posthead &&
            <PostHead><RichText content={posthead} /></PostHead>
          }
          <RichText content={ bodyCopy } />
          { banner?.source && <Image
            css={ css` width: 100%; height: auto; ` }
            src={ banner.source }
            alt={ banner.alt }
            transform={transform}
          /> }
          { children }
          <CtaList ctas={ ctas } />
        </Spacer>
      }
    </>
  )
}

const CMSBasicContent = ({ content }) => (
  <BasicContent { ...content } />
);

export default CMSBasicContent;
export { BasicContent };

import styled from '@emotion/styled';

// Components
import Text from './oc-text';
import RichText from './oc-rich-text';

// Styled Components
const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1170px;
  padding: 16px 0;
  position: relative;
  width: 100%;

  .prehead {
    text-transform: uppercase;

    @media (max-width: 767px) {
      font-size: 14px;
    }

    @media (min-width: 768px) {
      line-height: 18px;
    }
  }

  h1 {
    letter-spacing: 0.01em;

    @media (min-width: 1200px) {
      max-width: 770px;
    }
  }
`;

const PostHead = styled.span`
  color: #6D6D6D;
  display: block;
  font-family: "OCRoboto", sans-serif;
  font-size: 16px;
  line-height: 16px;
  margin: 16px 0 0;
  text-transform: uppercase;

  @media (min-width: 1200px) {
    font-size: 18px;
    line-height: 21px;
  }
`;

const NoImageHero = ({ prehead, h1, posthead, bodyCopy, ...props }) => {
  return (
    <HeroContainer>
      {prehead && <span className="prehead tier3"><Text content={ prehead } /></span>}
      <h1 className="tier3"><RichText content={h1} inline /></h1>
      {posthead && <PostHead><Text content={ posthead } /></PostHead>}
      {bodyCopy && <RichText content={ bodyCopy } />}
    </HeroContainer>
  );
}

export default NoImageHero;

import { define, ui } from '@owenscorning/pcb.alpha';
import { Edit } from '../Page/FeaturedList.jsx';

export default define`Featured List`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/FeaturedList`,
  view: ({ value }) => {
    return <UI.Modules.Page.FeaturedList value={ value } />
  },
  edit: Edit({ size: { default: "m" } })
});

import { define, ui } from '@owenscorning/pcb.alpha';
import React from 'react';

export default define`Features`('0.0.1')({
  view: () => <div> </div>,
  edit: ({ filterInfo }) => {
    const filters = filterInfo?.filters || [];
    const options = Object.fromEntries(
      filters.map(filter => [filter.filterId, filter.title])
    );

    return (
      <Subschema>
        {[
          ui`Form`.of({
            features: ui`Choices`.of({
              ...options,
            })({
              label: 'Features',
              mode: ui`Choices/Mode/Dropdown`,
              multiple: true,
            }),
          }),
        ]}
      </Subschema>
    );
  },
});

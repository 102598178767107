import styled from "@emotion/styled";
import RichText from "../../OC/PageBuilder/RichText";

const TipContainer = styled.div`
  align-items: flex-start;
  color: #585858;
  display: flex;
  ${props => props.noTopMargin ? '' : 'margin-top: -18px;'}
  position: relative;
`;

const IconContainer = styled.div`
  font-size: 18px;
  margin-right: 8px;
  position: relative;
  transform: translateY(50%);
`;

const TextContainer = styled.span`
  font-size: 12px;
  line-height: 17px;
`;

export default ({ icon='info-circle', iconComponent, noTopMargin }) => {
  let IconComponent = iconComponent;

  const renderIcon = (iconComponent, icon) => {
    if (!iconComponent && !icon) return null;

    return (
      <IconContainer>
        {iconComponent ? <IconComponent /> : <UI.Icon type={icon} />}
      </IconContainer>
    );
  };

  return (
    <TipContainer noTopMargin={noTopMargin}>
      {renderIcon(iconComponent, icon)}
      <TextContainer>
        <RichText content={contents} />
      </TextContainer>
    </TipContainer>
  );
};

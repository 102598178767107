// NOTE, the types/values in here must closely match in oc_shared_views/app/helpers/oc_shared_views/visibility_group_helper.rb
// also consider old .com repo ApplicationController#visibility_groups!
export default [
  { label: 'All Users', value: 'all:all' },
  // this set of "feature" values in particular needs to be in sync with "pb_feat_groups" in that file
  { label: 'Features', options: [ { label: 'Affiliate/Contractor Referrals (no FAC)', value: 'feature:contractor_referral' } ] },
  // this set of "feature" values ALSO needs to be in "pb_feat_groups"
  {
    label: 'Retail Training',
    options: [
      { label: 'The Home Depot', value: 'feature:thd_retail_training' },
      { label: 'Lowes', value: 'feature:lowes_retail_training' },
      { label: 'Menards', value: 'feature:menards_retail_training' },
      { label: 'Contractor', value: 'feature:contractor_retail_training' },
    ]
  },
  {
    label: 'OCCN-US',
    options: [
      { label: 'Platinum Contractor', value: 'contractor:occn_us:3' },
      { label: 'Preferred Contractor', value: 'contractor:occn_us:2' },
      { label: 'Contractor Rewards', value: 'contractor:occn_us:1'}
    ]
  },
  {
    label: 'OCCN-CAN',
    options: [
      { label: 'Platinum Contractor', value: 'contractor:occn_can:3' },
      { label: 'Preferred Contractor', value: 'contractor:occn_can:2' },
      { label: 'Contractor Rewards', value: 'contractor:occn_can:1'}
    ]
  },
  {
    label: 'PADP',
    options: [
      { label: 'Diamond', value: 'contractor:padp:4' },
      { label: 'Gold', value: 'contractor:padp:3' },
      { label: 'Silver', value: 'contractor:padp:2'},
      { label: 'Bronze', value: 'contractor:padp:1'}
    ]
  },
  { label: 'CEE', options: [ { label: 'Certified Energy Expert', value: 'contractor:cee' } ] },
  {
    label: 'Regions',
    options: [
      { label: 'United States', value: 'region:US' },
      { label: 'Canada', value: 'region:CA' },
    ]
  },
  {
    label: 'Plants',
    options: [
      { label: 'Atlanta', value: 'roofing_plant:1070' },
      { label: 'Brookville', value: 'roofing_plant:1098' },
      { label: 'Compton', value: 'roofing_plant:1012' },
      { label: 'Denver', value: 'roofing_plant:1040' },
      { label: 'Irving', value: 'roofing_plant:1292' },
      { label: 'Jacksonville', value: 'roofing_plant:1050' },
      { label: 'Kearny', value: 'roofing_plant:1182' },
      { label: 'Medina', value: 'roofing_plant:1212' },
      { label: 'Minneapolis', value: 'roofing_plant:1152' },
      { label: 'Portland', value: 'roofing_plant:1237' },
      { label: 'Savannah', value: 'roofing_plant:1469' },
      { label: 'Summit', value: 'roofing_plant:1093' },
      { label: 'Memphis', value: 'roofing_plant:1272' }
    ]
  }
];

import { useState } from 'react';
import { jsx, css } from '@emotion/react';
import { connectLocation } from '../location/LocationConnectors';
import Cta from './oc-cta';
import Cookies from 'js-cookie';
import { H3 } from './oc-h';

const flexBannerStyles = css`
  background: black;
  color: white;
  max-width: 400px;
  margin: 0 auto 16px;
  display: flex;

  @media(min-width: 768px) {
    max-width: 768px;
  }

  @media(min-width: 1200px) {
    max-width: 1170px;
  }

  .image {
    display: none;

    @media(min-width: 768px) {
      display: inline-flex;
      flex: 174px 0 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    @media(min-width: 1200px) {
      flex: 378px 0 0;
    }

    img, picture {
      width: 100%;

      &.flex-cover {
        height: 100%;
        display: flex;
        object-fit: cover;
      }
    }
  }

  .content {
    padding: 16px 24px;
    position: relative;

    h2, h3 {
      color: white;
    }

    .closeBtn {
      position: absolute;
      top: 16px;
      right: 16px;
      font-size: 32px;
      line-height: 16px;
      color: white;

      &:hover {
        cursor: pointer;
        color: #d40f7d;
      }

      &:focus {
        outline: 2px solid #d40f7d;
        outline-offset: 2px;
      }
    }
  }
`;
const hiddenFlexBanner = css`
  display: none;
`;

const FlexBanner = ({ img, imgTablet, imgAlt, prehead, heading, copy, cta_text, cta_url, destination, cookie, imgCover, hStyle='h2', dataRegion }) => {
  const [closeBanner, setcloseBanner] = useState(false);
  const onClick = () => {
    setcloseBanner(true);
    Cookies.set(cookie, true);
  }

  return (
    <>
    {!Cookies.get(cookie) &&
      <div css={closeBanner ? hiddenFlexBanner : flexBannerStyles} data-am-region={dataRegion} >
        {img &&
          <div className="image">
            { imgTablet ?
              <picture className={imgCover && 'flex-cover'}>
                <source srcset={img} media="(min-width: 1200px)" />
                <source srcset={imgTablet} media="(min-width: 768px)" />
                <img src={img} className={imgCover && 'flex-cover'} />
              </picture>
            :
              <img src={img} alt={imgAlt} className={imgCover && 'flex-cover'} />
            }
          </div>
        }

        <div className="content">
          <span className="closeBtn"
            onClick={onClick}
            tabIndex="0"
            onKeyPress={(event) => {
            if (event.charCode == "13") {
              onClick();
              }
            }}
          >&times;</span>

          <H3 prehead={ prehead } >{heading}</H3>
          <p dangerouslySetInnerHTML={{ __html: copy }} />
          {cta_url && <Cta href={cta_url} data-track="learn-more" data-track-destination={destination} data-track-element-location="main section CTA">{cta_text}</Cta>}
        </div>
      </div>
    }
    </>
  )
}

export default FlexBanner;

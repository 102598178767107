import styled from "@emotion/styled";

const SortContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

const SelectContainer = styled.div`
  align-items: center;
  display: flex !important;

  @media screen and (max-width: 768px) {
    width: 100%;

    select {
      flex-grow: 1;
    }
  }

  label {
    font-weight: 400;
    margin-right: 16px;
  }
`;

const SortDropDown = (props) => {
  return (
    <SortContainer css={props.styleProp} className="oc-form" autoComplete="off">
      <SelectContainer className="select-container">
        <label htmlFor="sort-by">{props.label}</label>
        <select
          tabIndex="0"
          id="sort-by"
          onChange={evt => props.updateParams("sort", evt.target.value)}
          value={props.selected}
        >
          {props.options.map(option => <option key={option.value} value={option.value}>{option.displayName}</option>)}
        </select>
      </SelectContainer>
    </SortContainer>
  );
};

export {SortDropDown};

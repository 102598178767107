/*
Helper to convert object key names in analytics track object to DTM-friendly format

Converts:

track: {
  name: 'MyName',
  type: 'MyType'
}

to:

track: {
  data-track-name: 'MyName',
  data-track-type: 'MyType'
 }
 */
const transform = require('object-key-transform');

function fix_key(key) {
  // Test whether passed key is for base data-track attribute
  if ( key === 'track' ) {
    return 'data-track';
  } else {
    return 'data-track-' + key;
  }

}

export default (track) => {

  var trackCopy = track ? {...track} : null;

  track ? transform(trackCopy, fix_key) : null;

  return trackCopy;
}

import { Component } from 'react';
import pym from 'pym.js';
import { navigate } from './helpers/sticky_nav';
import PropTypes from 'prop-types';

export class IFramedForm extends Component {
  constructor() {
    super();

    this.uniqueId = _.uniqueId('iframe_');
  }

  createPym = () => {
    this.pym = new pym.Parent(this.uniqueId, this.props.src, {})
    if (this.props.scrollOnSuccess) {
      this.pym.onMessage('success', success => {
        if (success) {
          navigate(this.uniqueId);
          if (this.props.reloadOnSuccess) {
            setTimeout(() => window.location.reload(), typeof(this.props.reloadOnSuccess) === 'number' ? this.props.reloadOnSuccess : 30000);
          }
        }
      });
    }
  }

  componentDidMount() {
    this.createPym();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.src !== prevProps.src) {
      this.pym.remove();
      this.createPym();
    }
  }

  componentWillUnmount() {
    this.pym.remove();
  }

  render() {
    return (
      <div id={ this.uniqueId } data-src={ this.props.src }/>
    )
  }
}

IFramedForm.propTypes = {
  src: PropTypes.string,
  scrollOnSuccess: PropTypes.bool,
  reloadOnSuccess: PropTypes.oneOfType([PropTypes.bool,PropTypes.number])
}

export default IFramedForm;

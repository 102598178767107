import { define, ui } from '@owenscorning/pcb.alpha';
import styled from '@emotion/styled';

// Components
import Cta from '../../../../OC/PageBuilder/Cta';

// Styled Components
const Container = styled.div`
  margin: 0 auto;

  @media (${ () => UI.Viewport.from.Tablet }) {
    width: 736px;
  }

   @media (${ () => UI.Viewport.from.Desktop }) {
    width: 770px;
  }
`;

const CtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 8px;
  position: relative;

  @media (${ () => UI.Viewport.from.Tablet }) {
    align-items: center;
    flex-direction: row;
  }
`;

export default define`Text`('0.0.1')({
  thumbnail: ui`Modules/Article/Thumbnails/Text`,
  view: ({ value }) => {
    let ctas = value.ctas || [];

    return (
      <Container>
        <UI.Box tablet-padding-0-8 mobile-padding-0 >
          <UI.Text.Rich singular content={value.content} />

          {(ctas.length > 0) &&
            <CtaContainer>
              {ctas.map((cta, index) => (
                <Cta key={index} standalone {...cta} />
              ))}
            </CtaContainer>
          }
        </UI.Box>
      </Container>
    );
  },
  edit: {
    content: ui`Text/Rich`({ label: 'Content' }),
    ctas: ui`CTA/List`({ min: 1, max: 3 })
  }
});

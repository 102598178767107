import { appendAspectRatio, imageObjectFromProps } from '../../../migrations';

export default {
  displayName: 'Featured List',
  allowableParents: ['Section'],
  migrator: (data) => {
    data = appendAspectRatio(data);
    data = appendAspectRatio(data, 'aspectRatioCard');

    // PB-444: MediaObject images were using the UI`Upload/Image`, but now we are using
    //         UI`Image` component. This means that the structure is different and
    //         we need to alter it here in order to not break previously existing
    //         cards.
    const transformedMediaObjects = data.mediaObjects?.map((mediaObject) => {
      if (typeof mediaObject.image === 'string') {
        return {
          ...mediaObject,
          image: {
            file: mediaObject.image,
            crop: undefined,
          }
        };
      } else {
        return mediaObject;
      }
    });
    data.mediaObjects = transformedMediaObjects;

    if (data) {
      const { cards, ...rest } = data;
      data = { cards: (cards||[]).map(card => imageObjectFromProps(card, 'img', 'img', 'alt')), ...rest };
    }
    return data;
  },
};

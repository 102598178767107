import { define, ui, when, select } from '@owenscorning/pcb.alpha';
import Renderer from './../../../../OC/PageBuilder/Figure';

const sizesHash = {
  'full-bleed': ['Full Bleed', '2680px'],
  'xl': ['XL', '2340px'],
  'l': ['Large', '1940px'],
  'm': ['Medium', '1540px'],
  's': ['Small', '740px'],
  'thm': ['Thumbnail', '340px']
};

export default define`Figure`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/Figure`,
  category: ui`Modules/Categories/Page/Customizable`,
  gutterlessForReusable: true,
  gutterless: true,
  view: ({ value }) => <Renderer { ...value } />,
  edit: {
    size: ui`Choices`.of({
      'full-bleed': 'Full Bleed',
      xl: 'XL',
      l: 'Large',
      m: 'Medium',
      s: 'Small',
      thm: 'Thumbnail'
    })({
      label: 'Image Size',
      default: 'full-bleed'
    }),
    imageSizeInfo: ui`ImageSizeInfo`({
      visible: when`../media/type`.is.equal.to('image'),
      moduleName: 'Figure',
      sizesHash: sizesHash,
      selectedSize: select`../size`
    }),
    media: ui`Media`({ askImageCaption: true }),
    imageUrl: ui`Text`({
      label: 'Image Url',
      visible: when`../media/type`.is.equal.to('image')
    }),
    socialShare: ui`Switch`({ label: 'Image Social Sharing', default: false }),
    socialShareTip: ui`Tip`.of("By enabling image social share we will be showing share options on this module's images."),
  },
});

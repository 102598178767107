import { ui, when, s } from '@owenscorning/pcb.alpha';
import { expandRef, wrapRef } from '../../../../data';
import { apiGet } from '../../../PageBuilder/helpers/api';
import getFormatFromMime from '../../../helpers/get_format_from_mime';
import FeaturedDocument from '../../../OC/oc-featured-document';
import useReference from '../../../../hooks/use_reference';
import FileBadge from '../../../OC/oc-file-badge';
import _ from 'lodash';
import { localeSort, parentLocales } from '../../../location/locales';
import qs from 'qs';
import Theme from '../../../../themes/index'

// Assets
import IconPimLink from '../../../ComponentLibrary/icons/icon-pim-link';
import { formatDatetime } from "../../../PageBuilder/helpers/time";
import getFileSize from "../../../helpers/get_file_size";
import cms_api from "../../../../cms_api";
import { digChoicesFromData } from "../../Library/AttributeSet";
import itemListResponse, { generateFilterOptions } from "./helpers/item_list_response";
import { connectLocation } from "../../../location/LocationConnectors";
import styled from "@emotion/styled";
import { H3 } from "../../../ComponentLibrary/text-elements/oc-h";
import pathToContent from "./helpers/path_to_content";
import getFileBasename from "../../../helpers/get_file_basename";

let searchAbortController;

const refSearchMetadata = (startOpen, resultMapper) => ({
  startOpen,
  label: 'Document',
  dataset: 'document_builder',
  set: (value, path) => {
    // TODO: handle empty/null value?
    const ref = wrapRef('Cms::Content', value.ref);
    expandRef(ref).then(result => resultMapper(result, path))
    return ref;
  },
  get: (value) => {
    const { results, error, loading } = useReference(value);
    if (loading) {
      return <i>Loading...</i>
    } else if (error) {
      return <span>{ error }</span>
    }
    return results;
  }
});

const commonSearchMethod = (considerCurrentLanguage, dataMapper) => async ({ filter } = {}) => {
  if (!filter) {
    return null;
  }
  if (searchAbortController) searchAbortController.abort();
  searchAbortController = new AbortController();
  const query = {
    filter: {
      type: `Cms::Content::Document`,
      published: true,
    },
    fields: {
      '*': 'name,metadata,language_summary,content_uuid',
    },
    q: filter,
    include: 'users'
  };
  const language = Board.build.language;
  const languages = Array.wrap(language).concat(parentLocales[language]);
  if (considerCurrentLanguage) {
    query.filter.language_iso_code = languages;
  }
  const response = await apiGet(`/api/v2/cms/sites/${PB_SITE}/contents?${qs.stringify(query, { arrayFormat: 'brackets', encodeValuesOnly: true })}`, { signal: searchAbortController.signal });
  const metaUsers = _.reduce(response.included?.filter(i => i.type === 'user'), (result, item) => {
    result[item.id] = item
    return result
  }, {})
  const output = [];
  response.data.forEach(r => {
    Array.wrap(dataMapper(r, response, metaUsers, languages)).forEach(r => output.push(r))
  });
  return output;
};

const Container = styled.a`
  width: 100%;
  margin: 16px 0;
  text-decoration: none;
  :hover > *{
    color: initial;
  }
  :hover > h3 {
    color: ${Theme.colors.brand};
    text-decoration: none;
  }
  :focus {
    outline: none;
  }
`;

const Heading = styled(H3)`
  margin-bottom: 0;
`

const DocumentDetails = styled.span`
  font-size: 12px;
`;

const ColumnedSection = styled.div`
  font-size: 14px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;

  @media(min-width: 768px) {
    gap: 16px;
    flex-direction: row;
  }
`;

const Column = styled.div`
  width: 100%;

  @media(min-width: 768px) {
    width: 50%;
  }
`;

const Info = styled.span`
  font-size: 14px;
  margin-top: 8px;
  display: block;

  strong {
    margin-left: 8px;
  }
`;

const getDocFilename = (result) => {
  if (!result) return '';
  const url = result?.type === 'upload' ? result.document.url : result.external?.url
  return url ? getFileBasename(url) : '';
};

const getDocFormat = (result) => (
  getFormatFromMime(result?.contents?.type === "upload" ? result.contents?.document?.mime_type : null)
)

const bestLanguageKey = (obj, lang) => {
  const matchedLanguages = _.intersection(Object.keys(obj || {}), [lang].concat(parentLocales[lang])).sort((a, b) => localeSort({ code: a }, { code: b }))
  const bestMatch = _.last(matchedLanguages);
  return obj[bestMatch];
};

const DocBuilderResultBlock = connectLocation(({ t, labels, ...result }) => {
  const docFormat = getDocFormat(result);
  const size = getFileSize(result?.contents?.document?.size_bytes);
  const countries = bestLanguageKey(labels?.['Country Availability'], Board.build.language);
  const docTypes = bestLanguageKey(labels?.['Document Type'], Board.build.language);
  const languages = bestLanguageKey(labels?.['Language'], Board.build.language);

  const lookedUpCountries = Array.wrap(result?.metadata?.settings?.attributes?.country || []).map(v => countries?.items?.[v]?.label).filter(i => i);
  const lookedUpDocTypes = Array.wrap(result?.metadata?.settings?.attributes?.document_type || []).map(v => docTypes?.items?.[v]?.label).filter(i => i);
  const lookedUpLanguages = Array.wrap(result?.metadata?.settings?.attributes?.language || []).map(v => languages?.items?.[v]?.label).filter(i => i);

  return (
    <Container href={ pathToContent(result) }
               data-track="file-download"
               data-track-file-name={ getDocFilename(result) }
               data-track-element-location="search results"
               data-track-doc-type="Document Builder">

      <Heading font="body">{result?.metadata?.settings?.attributes?.title || result?.name}</Heading>

      <DocumentDetails>
        {(docFormat || size) && `${docFormat} ${size}`}
      </DocumentDetails>

      <ColumnedSection>
        <Column>
          {
            docTypes?.singular && !_.isEmpty(lookedUpDocTypes) && <Info>{docTypes?.singular} <strong>{lookedUpDocTypes.join(', ')}</strong></Info>
          }
          {
            languages?.singular && !_.isEmpty(lookedUpLanguages) && <Info>{languages?.singular} <strong>{lookedUpLanguages.join(', ')}</strong></Info>
          }
        </Column>
        <Column>
          {
            countries?.singular && !_.isEmpty(lookedUpCountries) && <Info>{countries?.singular} <strong>{lookedUpCountries.join(', ')}</strong></Info>
          }
        </Column>
      </ColumnedSection>
    </Container>
  )
});

const searchFields = [
  ['metadata', 'settings', 'attributes', 'keywords'],
  ['metadata', 'settings', 'attributes', 'title'],
];

const docListItemListResponse = (items, labels, showFilters, enabledFilters, allFilters) => {
  let results = items;
  if (items && !Array.isArray(items)) {
    results = items.results;
  }
  return itemListResponse(
    {
      items: results,
      filters: generateFilterOptions(showFilters, enabledFilters, allFilters, null),
      Component: (props) => <DocBuilderResultBlock labels={labels} {...props} />,
      searchFields,
      enableSearch: true,
    },
  )
}

const mapping = {
  SpecificationsAndLiterature: {
    availableIn: ['www.paroc.com'],
    name: 'Document Builder',
    meta: {
      type: ui`Choices`.of({
        document: 'Document Builder',
        link: 'Link'
      })({
        label: 'Type',
        default: 'document',
        disabled: when`~linked_object`.is.present.and.when`~linked_object/source`.is.containing("pim_"),
        tip: when`~linked_object`.is.present.and.when`~linked_object/source`.is.containing("pim_").then("Field connected to PIM product."),
        tipIcon: IconPimLink
      }),
      document: ui`Search`({
        ...refSearchMetadata(when`../document`.isnt.present.then(true).otherwise(false), x => x),
        visible: when`../type`.is.equal.to('document')
      }),
      link: ui`Url`({
        label: 'Document Link',
        disabled: when`~linked_object`.is.present.and.when`~linked_object/source`.is.containing("pim_"),
        tip: when`~linked_object`.is.present.and.when`~linked_object/source`.is.containing("pim_").then("Field connected to PIM product."),
        tipIcon: IconPimLink,
        visible: when`../type`.is.equal.to('link')
      }),
      featured: ui`Switch`({
        label: 'Featured?',
        visible: when`../document`.is.present
      })
    },
  },
  RichText: {
    availableIn: ['www.paroc.com'],
    name: 'Document Builder',
    meta: {
      document: ui`Search`(refSearchMetadata(true, x => x))
    },
  },
  Accordion: {
    availableIn: ['www.paroc.com'],
    name: 'Document Builder',
    old_view: async ({ categories } = {}) => {
      return Promise.all((categories || []).map(({ documents = [], ...rest }) => {
        return Promise.all(documents.map(document => {
          if (!document) return null;
          if (document.type === 'link') return document;
          if (!document.document) return null;
          return expandRef(document.document)
        })).then(expandedDocuments => {
          return {
            ...rest,
            documents: documents.map((d, i) => ({ ...d, document: { ...d.document, __data: expandedDocuments[i] } })).filter(Boolean)
          }
        })
      })).then(result => result.filter(Boolean));
    }
  },
  ItemList: {
    availableIn: ['www.paroc.com', 'www.owenscorning.com'],
    name: 'Document Builder',
    meta: async () => {
      const query = {
        filter: {
          type: 'Cms::Content::AttributeSet',
          name: 'Document Attributes',
          route: '/',
        },
        fields: {
          '*': 'contents,metadata'
        }
      }
      const data = await cms_api.get_single_for_language(query, Board.build.language)
      const hasFilters = !!data;
      const filterChoices = hasFilters ? await digChoicesFromData('metadata.settings.attributes', data) : null;
      const taxonomies = hasFilters ? Object.fromEntries(Object.entries(filterChoices).map(([key, entry]) => [key, entry.label])) : {}
      const filters = hasFilters ? ui`Form`.of(
        Object.fromEntries(Object.entries(filterChoices).map(([key, { of, ...entry }]) => ([
          key,
          ui`Choices`.of(of)({ ...entry, multiple: true})
        ])))
      ) : null;
      const defaultEnabledFilterKeys = Object.keys(taxonomies);
      const enabledFilters = hasFilters ? ui`ChoicesDraggable`.of(taxonomies)({
        // ** here is where we need to add the filter UI type
        label: 'Filters',
        visible: when`../showFilters`.is.equal.to(true).then(true).otherwise(false),
        default: {
          enabledFilters: defaultEnabledFilterKeys,
          orderedList: Object.keys(taxonomies).map(k => ({[k]: defaultEnabledFilterKeys.indexOf(k) >= 0}))
        },
      }) : null
      return {
        source: ui`Choices`.of({
          'all': 'All Items',
          'filtered': 'Filtered',
          'specific': 'Select Specific Items'
        })({
          label: 'Select Structure',
          default: 'all',
          mode: ui`Choices/Mode/Dropdown`
        }),
        filters: ui`List/Item`.of(filters)({
          standalone: true,
          title: 'Document Attributes',
          label: 'Filters',
          visible: when`../source`.is.equal.to('filtered')
        }),
        items: ui`List`.of({
          item: ui`Search`({
            startOpen: when`../item`.isnt.present.then(true).otherwise(false),
            label: 'Document',
            dataset: 'document_builder',
            set: (value, path) => {
              // TODO: handle empty/null value?
              const ref = wrapRef('Cms::Content', { type: 'Document', id: value.content_uuid })
              expandRef(ref).then(result => {
                //Board.Change(result.metadata?.settings?.general?.proper_name, _.concat(_.initial(path), 'proper_name'));
              })
              return ref;
            },
            get: (value) => {
              const { results, error, loading } = useReference(value);
              if (loading) {
                return <i>Loading...</i>
              } else if (error) {
                return <span>{error}</span>
              }
              return results;
            }
          }),
        })({
          singular: 'Document',
          title: 'proper_name',
          label: 'Documents',
          visible: when`../source`.is.equal.to('specific').then(true).otherwise(false)
        }),
        showFilters: ui`Switch`({
          label: 'Filter Pane',
          default: hasFilters,
          disabled: !hasFilters,
        }),
        [s._]: ui`Tip`.of(`Cannot find AttributeSet <b>Document Attributes</b> in a matching language`)({
          visible: !hasFilters,
        }),
        [s._]: ui`Tip`.of('If the item list you wish to display is to be very long, we recommend turning on the Filter Side Pane to allow users to filter down the results to their needs.')({
          visible: hasFilters,
        }),
        enabledFilters
      }
    },
    view: (data = null) => {
      const { data: items, meta: { parameters, filters, labels } } = (data || { meta: {} });
      const { /*productSource, filters, items=[],*/ showFilters, enabledFilters } = parameters || {};
      return docListItemListResponse(items, labels, showFilters, enabledFilters, filters || {})
    }
  },
  // ProductsInSolution: {
  //   availableIn: ['www.owenscorning.com'],
  //   name: 'DMS Document',
  //   fetch_data: async ({ items = [] }) => {
  //     if (_.isEmpty(items)) return;
  //
  //     const categories = items.map(async (x) => {
  //       if (!x?.product) return;
  //
  //       const product = await expandRef(x?.product);
  //       if (!product) return;
  //
  //       // For ProductsInSolution, documents now keep coming from the usual product setting path, but we also
  //       // want to return the visibility for these that might be located within the product->documents path
  //       return {
  //         productCategories: _.get(product, ['metadata', 'settings', 'documents', 'categories']),
  //         documentsVisibility: _.get(x, ['documents']) || []
  //       };
  //     });
  //
  //     return Promise.all(categories);
  //   }
  // },
  MediaObjectSet: {
    availableIn: ['www.paroc.com'],
    name: 'Document Builder',
    meta: {
      items: ui`List`.of({
        document: ui`Search`(refSearchMetadata(false, x => x)),
      })({
        singular: 'Document',
        title: 'title',
        label: 'Documents',
      })
    },
    old_view: async ({ items } = {}) => {
      return Promise.all((items||[]).map(({ title, description, prehead, type, document }) => {
        if (!document) return null;
        return (document.__data ? Promise.resolve(document.__data) : expandRef(document)).then(result => {
          let mime_type = null;
          let size_bytes = null;
          let url = null;
          if (result.type === 'link' && result.document?.data) {
            mime_type = result.document.data.mime_type
            size_bytes = result.document.data.size_bytes
            url = result.document.data.url
          } else if (result?.contents?.type === 'upload') {
            mime_type = result?.contents?.document?.mime_type
            size_bytes = result?.contents?.document?.size_bytes
            url = pathToContent(result)
          }
          return {
            prehead: prehead,
            imgSize: 'document',
            aspectRatio: '0.77',
            image: result?.thumb ? {
              alt: result?.metadata?.settings?.attributes?.title || result?.title,
              file: result?.contents?.thumbnail || result?.thumb?.url
            } : null,
            url,
            heading: result?.metadata?.settings?.attributes?.title || title,
            text: result?.metadata?.settings?.attributes?.short_description || description,
            target: '_blank',
            linkText: (mime_type || size_bytes) ? <FileBadge extension={ mime_type ? getFormatFromMime(mime_type) : null } sizeBytes={ size_bytes } /> : '',
            analytics: {
              ...(result?.analytics || {}),
              'element-location': "main section text link"
            }
          };
        })
      }).filter(Boolean));
    }
  },
  CtaDocumentSet: {
    availableIn: ['www.paroc.com'],
    name: 'Document Builder',
    meta: {
      item: ui`Form`.of({
        document: ui`Search`(refSearchMetadata(false, (x) => x)),
      })({
        singular: 'Document',
        title: 'title',
        label: 'Documents',
      })
    },
    old_view: async ({ item } = {}) => (await expandRef(item?.document))
  },
  Search: {
    name: 'Document Builder',
    meta: {},
    tabs: [
      {
        label: 'Current Language',
        search: commonSearchMethod(true, (r, response, metaUsers, languages) => {
          const matchingLanguages = _.intersection(languages, Object.keys(r.language_summary).filter(l => r.language_summary[l].published_at)).sort((a, b) => localeSort({ code: a }, { code: b }))
          const usedLanguage = _.last(matchingLanguages);
          return {
            ref: { type: 'Document', id: r.id },
            Name: r.name,
            'Last Published': <span>{ metaUsers[r.language_summary[usedLanguage]?.published_by]?.name || 'Unknown' }<br/>{ formatDatetime(r.language_summary[usedLanguage]?.published_at) }</span>,
          };
        }),
      },
      {
        label: 'All Languages',
        search: commonSearchMethod(false, (r, response, metaUsers) => {
          const matchingLanguages = Object.keys(r.language_summary).filter(l => r.language_summary[l].published_at).sort((a, b) => localeSort({ code: a }, { code: b }))
          return matchingLanguages.map(l => (
            {
              ref: { type: 'Document', id: r.id, language: l },
              Name: r.name,
              Language: l,
              'Last Published': <span>{ metaUsers[r.language_summary[l]?.published_by]?.name || 'Unknown' }<br/>{ formatDatetime(r.language_summary[l]?.published_at) }</span>,
            }
          ));
        }),
      }
    ],
    preview: (result) => result && <FeaturedDocument
      title={ null }
      description={ <div style={ { marginTop: '-16px' } }>
        <b style={ { fontSize: '16px' } }>Name: { result.name }</b><br />
        <div style={{ fontSize: "12px" }}>
          Title: {result.metadata?.settings?.attributes?.title}<br />
          Description: {result.metadata?.settings?.short_description}<br />
          Doc Type: { getDocFormat(result) }<br />
          Language: {result.language_iso_code}
        </div>
      </div> }
      url={ pathToContent(result) }
      thumbUrl={ result.contents?.thumbnail }
      hideBadge />
  }
}

export default mapping;

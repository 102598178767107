(function() {
  var decodedCookie = decodeURIComponent(document.cookie);
  var zipCookies = decodedCookie.split(';').map(function(c) {
    return c.trim().split('=');
  }).filter(function(c) {
    return c[0] === 'UserInfoZip'
  });
  if (zipCookies.length > 1) {
    // we must be getting a value from "www.owenscorning.com" (session) and ".owenscorning.com" (persistent)
    // blow up the session value
    document.cookie = 'UserInfoZip=; Max-Age=0; path=/;'
  }
  var localeCookies = decodedCookie.split(';').map(function(c) {
    return c.trim().split('=');
  }).filter(function(c) {
    return c[0] === 'oc_locale'
  });
  if (localeCookies.length > 1) {
    // we must be getting a value from "www.owenscorning.com" (session) and ".owenscorning.com" (persistent)
    // blow up the session value
    document.cookie = 'oc_locale=; Max-Age=0; path=/;'
  }
}).call(this);

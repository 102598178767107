import { useRef } from 'react';
import styled from '@emotion/styled';
import Picture from './oc-picture';
import {H3, H5} from './oc-h';
import ChevronLink from './oc-chevron-link';
import Text from './PageBuilder/utilities/Text';
import RichText from './PageBuilder/RichText';
import placeholder_url from '../../placeholder';
import ImageShareLinks from './oc-image-share-links';
import Cta from './PageBuilder/Cta';
import _ from 'lodash';

const CardWrapper = styled.div`
	h3 {
		margin-bottom: 8px;
	}

	.posthead-wrapper {
		div {
			margin: 0;
		}

		p {
			margin: 0 0 16px;
			font-size: 14px;
			margin-bottom: 16px;

			@media(min-width: 1200px) {
				font-size: 16px;
			}
		}
	}
`;

const Media = styled.div`
  margin-bottom: 16px;
  position: relative;

  img {
    display: block;
    max-width: 100%;
    width: 100%;
  }
`

const ComparisonCard = ({ prehead, heading, posthead, img, imgAlt, noImage, text, linkUrl, linkText, aspectRatio, socialShare, transform = "cards", ctaSwitch, ctaProps, ...props }) => {
  const CtaButtonRef = useRef(null);

  const handleCtaButtonClick = () => {
   if(CtaButtonRef.current){
    CtaButtonRef.current?.click();
   }
  
  };
  if (aspectRatio) { aspectRatio['custom'] = aspectRatio['custom']?.toString(); }

	return (
		<CardWrapper {...props}>
      <span { ...(!!ctaSwitch && {onClick: handleCtaButtonClick})}>
			{ heading && <H3 prehead={ prehead }>{ heading }</H3> }
			{ posthead && <div className="posthead-wrapper"><RichText content={ posthead } /></div> }
      </span>
      { img &&
        <Media>
          <Picture
            source={img}
            transform={transform}
            aspectRatio={ aspectRatio }
            /*
              Alt text only shows when there is no URL, for accessibility reasons
              This can be changed when card is refactored to use a single link
              around all of its contents. - WP 4/23/21
            */
            alt={!linkUrl && imgAlt}
            { ...(!!ctaSwitch && {onClick: handleCtaButtonClick})}
          />

          {socialShare && <ImageShareLinks imageUrl={img} />}
        </Media>
      }

      <RichText content={ text } />

      { !(!!ctaSwitch) && linkUrl && <ChevronLink url={linkUrl}>{linkText}</ChevronLink> }
      {!!ctaSwitch && (_.isArray(ctaProps) ? ctaProps?.map((props) => (
        <div>
          <Cta
            {...props}
            forwardRef={CtaButtonRef}
          />
        </div>
      )) : <div
      >
        <Cta
          {...ctaProps}
          forwardRef={CtaButtonRef}
        /></div>)
      }
    </CardWrapper>
  );
};

export default Text.Parse(ComparisonCard, 'prehead', 'heading', 'linkText');

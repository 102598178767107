import { useState } from 'react';
import Interweave, { Matcher, Filter } from 'interweave';
import Cookies from '../../../PageBuilder/helpers/cookies';
import XRegExp from 'xregexp';
import _ from "lodash";

const hasNative = (flag) => {
  try {
    new RegExp('', flag)
    return true;
  } catch(error) {
    return false;
  }
}

const hasNativeU = hasNative('u')

class TrademarkMatcher extends Matcher {
  getSymbolOutput(symbol) {
    switch (symbol) {
      case '🅪': // MC in case your editor's font doesn't support it
        return 'MC';
      case '🅫': // MD in case your editor's font doesn't support it
        return 'MD';
      case '®':
      case '™':
        return symbol;
      default:
        throw 'Unsupported';
    }
  }

  getClassName(symbol) {
    switch (symbol) {
      case '🅪': // MC in case your editor's font doesn't support it
      case '🅫': // MD in case your editor's font doesn't support it
        return 'mc-md';
      case '®':
      case '™':
        return null;
      default:
        throw 'Unsupported';
    }
  }

  match(str) {
    const regexResult = str.match(XRegExp("(^|\\s|-)(\\p{L}+)([®™🅪🅫])", hasNativeU ? 'u' : ''));
    if (regexResult) {
      const [ match, leader, text, symbol ] = regexResult
      return {
        index: regexResult.index,
        length: match.length,
        match,
        valid: true,

        // custom props passed to replaceWith
        leader,
        text,
        symbol: this.getSymbolOutput(symbol),
        className: this.getClassName(symbol),
      };
    }

    return null;
  }

  replaceWith(children, props) {
    const { leader, text, symbol, className } = props;
    return (
      <>
        { leader }<span className="nowrap">{ className ? <>{ text }<span className={ className }>{ symbol }</span></> : `${text}${symbol}` }</span>
      </>
    );
  }

  asTag() {
    return 'span';
  }
}

class ReferralFACFilter extends Filter {
  attribute(_,value) {return value;}

  node(name, nod) {
    if (Cookies.isReferred() && name === 'a' && nod.getAttribute('href').includes('roofing/contractors')) {
      nod.removeAttribute('href');
      nod.style.all = 'unset';
    }

    return nod;
  }
}

const globalMatchers = [ new TrademarkMatcher("Trademark") ];
const globalFilters = [ new ReferralFACFilter() ];
export { globalMatchers as Matchers };

const Text = ({ matchers = [], filters = [], content, ...props }) => {
  if (!_.isString(content)) {
    // awkward, but we can't return undefined from a component or react blows up
    return content || null;
  }
  content = content?.trim();
  return _.isEmpty(content) ? null : <span>
    <Interweave
      matchers={ [ ...globalMatchers, ...matchers ] }
      filters={ [ ...globalFilters, ...filters ] }
      content={ content }
      { ...props }
    />
  </span>;
};

Text.Apply = (subject, mask, path=[]) => {
  const dug = path.length >= mask.length;
  if (_.isPlainObject(subject)) return _.mapValues(subject, (child, key) => dug || mask[path.length] == key ? Text.Apply(child, mask, path.concat(key)) : child);
  if (_.isArray(subject)) return subject.map((child) => Text.Apply(child, mask, path));
  if (dug && _.isString(subject)) return <Text content={ subject } />;
  return subject;
};

Text.Parse = (Component, ...parsing) => ({ ...props }) => <Component
  { ..._.omit(props, parsing) }
  { ..._.reduce(parsing, (result, prop) => {
    const base = prop.split('.')[0];
    return Object.assign(result, { [base]: Text.Apply( result[base], prop.split('.').slice(1) ) });
  }, props) }
/>;

export default Text;

const DefaultTextInput = (props) => {
  return (
    <input
      aria-invalid={props.rawErrors?.length > 0}
      className={props.classNames || props.className || 'form-control'}
      disabled={props.disabled}
      id={props.id}
      onChange={(event) => props.onChange(event.target.value)} 
      placeholder={props.placeholder}
      required={props.required}
      type={props.type || props?.options?.inputType || "text"}
      value={props.value}
      name={props.name}
    />
  );
};

export { DefaultTextInput };

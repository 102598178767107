import { H1, H2, H3 } from '../oc-h';
import RichText from './RichText';

const Heading = ({ prehead, mainHeading, headingClass="", headingFont, headingTag="h2" }) => {
  let tags = { "h1": H1, "h2": H2, "h3": H3 };
  let TagComponent = tags[headingTag] || H2;
  let headingClasses = [headingClass].filter(Boolean);

  if (!mainHeading) return <></>;

  return (
    <TagComponent className={headingClasses.join(" ")} prehead={prehead} font={headingFont} key={mainHeading}>
      <div>
        <RichText content={mainHeading} inline />
      </div>
    </TagComponent>
  );
};

export default Heading;

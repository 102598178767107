import _ from 'lodash';
import React from 'react';

import {
  Directions,
  Divider,
  Phone,
  Email,
  Name,
  Website,
  CallNowCTA,
  Features,
  Badges,
  Distance,
} from './block-parts';
import CustomizedComponent from './block-parts/CustomizedComponent';
import { formatAddress } from './format-address';
import { formatPhone, phoneHref } from './format-phone';
import ResultBlock from './oc-result-block';
import { Address, MobileSwitch } from './result-block-styled-components';
import { connectLocation } from '../../location/LocationConnectors';

const isPresent = value => Boolean(value) && _.toString(value).length > 0;

const formatDistance = ({ distance, t, units }) =>
  t(`generic_locator.${units}_away`, { distance: Math.round(distance) });

const CustomResultBlock = connectLocation(props => {
  const {
    resource: resourceData,
    t,
    locale: { code },
  } = props;
  const options = _.merge(
    {
      units: 'mi',
      showAddress: true,
      showDistance: true,
      showDirections: true,
      showPhone: true,
      showEmail: true,
      showWebsite: true,
      showCTA: true,
      showFeatures: true,
      showBadges: false,
    },
    props.options || {}
  );

  // attributes (computed values for the subject), can serve as an intermediary layer to DRY up logic shared between components
  const attributeFuncs = _.merge(
    {
      logo: (_attrs, data) => data.logo,
      name: (_attrs, data) => data.name,
      address: (_attrs, data) => formatAddress(data, code),
      buyType: (_attrs, data) => data.typeDisplayName || data.location_type,
    },
    props.attributes || {}
  );
  // these are just defaults, override as necessary
  const componentMetaDatas = _.merge(
    {
      Name: {
        getProps: attrs => ({
          children: attrs.name,
        }),
        Component: Name,
      },
      Distance: {
        showComponent: options.showDistance,
        getProps: (_attrs, data, opts) => ({
          distance: data.distance,
          units: opts.units,
          formatter: formatDistance,
        }),
        Component: Distance,
      },
      Address: {
        showComponent: options.showAddress,
        getProps: attrs => ({
          children: attrs.address,
        }),
        Component: Address,
      },
      Directions: {
        showComponent: options.showDirections,
        getProps: attrs => ({
          label: t('generic_locator.directions'),
          address: attrs.address,
          buyType: attrs.buyType,
          name: attrs.name,
        }),
        Component: Directions,
      },
      Phone: {
        showComponent: options.showPhone,
        getProps: (attrs, data) => ({
          show: isPresent(data.phone),
          label: formatPhone(data.phone, code),
          href: phoneHref(data.phone),
          buyType: attrs.buyType,
          name: attrs.name,
        }),
        Component: Phone,
      },
      Email: {
        showComponent: options.showEmail,
        getProps: (attrs, data) => ({
          show: isPresent(data.email),
          email: data.email,
          label: t('generic_locator.email'),
          buyType: attrs.buyType,
          name: attrs.name,
        }),
        Component: Email,
      },
      Website: {
        showComponent: options.showWebsite,
        getProps: (attrs, data) => ({
          show: isPresent(data.website),
          website: data.website,
          label: t('generic_locator.website'),
          buyType: attrs.buyType,
          name: attrs.name,
        }),
        Component: Website,
      },
      CTA: {
        showComponent: options.showCTA,
        getProps: (attrs, data) => ({
          show: isPresent(data.phone),
          href: phoneHref(data.phone),
          label: t('generic_locator.call_now'),
          buyType: attrs.buyType,
          name: attrs.name,
        }),
        Component: CallNowCTA,
      },
      Features: {
        showComponent: options.showFeatures,
        getProps: (_attrs, data) => ({
          show: true,
          features: {
            products_stocked: {
              title: 'Products Stocked',
              items: data.products_stocked,
            },
          },
        }),
        Component: Features,
      },
      Badges: {
        showComponent: options.showBadges,
        getProps: (_attrs, data) => ({
          show: isPresent(data.badges),
          badges: data.badges || [],
        }),
        Component: Badges,
      },
    },
    props.components || {}
  );

  const attributes = {};
  Object.entries(attributeFuncs).forEach(([key, fn]) => {
    Object.defineProperty(attributes, key, {
      get() {
        if (fn && typeof fn === 'function') {
          return fn(attributes, resourceData, options, t);
        }
        return undefined;
      },
    });
  });
  const components = {};
  Object.entries(componentMetaDatas).forEach(([key, meta]) => {
    Object.defineProperty(components, key, {
      get() {
        if (meta?.Component && meta?.getProps) {
          return ({ children }) => (
            <CustomizedComponent
              showComponent={meta.showComponent}
              getProps={meta.getProps}
              Component={meta.Component}
              attributes={attributes}
              resource={resourceData}
              options={options}
              t={t}
            >
              {children}
            </CustomizedComponent>
          );
        }
        return undefined;
      },
    });
  });

  const contactInfo = ['Directions', 'Phone', 'Email', 'Website']
    .map(key => {
      const comp = componentMetaDatas[key];
      const { showComponent } = comp;
      const { show } = comp.getProps(attributes, resourceData, options, t);
      return showComponent !== false && show !== false ? key : null;
    })
    .filter(Boolean);

  return (
    <ResultBlock media={attributes.logo} alt={attributes.name}>
      <div>
        <components.Name />
        <components.Distance />
        <components.Address />
        <MobileSwitch>
          <div>
            <components.Directions />
            <Divider prior="Directions" contactInfo={contactInfo} />
            <components.Phone />
            <Divider prior="Phone" contactInfo={contactInfo} />
            <components.Email />
            <Divider prior="Email" contactInfo={contactInfo} />
            <components.Website />
            <components.Features />
          </div>
        </MobileSwitch>
        <components.Badges />
      </div>
      <components.CTA />
    </ResultBlock>
  );
});

CustomResultBlock.WrappedComponent.displayName = 'CustomResultBlock';

export default CustomResultBlock;

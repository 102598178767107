import React, { useState } from 'react';
import { connectLocation } from "../../../location/LocationConnectors";
import wrappedInReduxProvider from "../../../wrappedInReduxProvider";
import styled from "@emotion/styled";

// Generic Components
import FilterOpener from '../../oc-filter-opener';
import FilterChips from '../../filter-list/oc-filter-chips';
import { Loader } from "../../../BuildYourRoof/loading"

// Content Components
import ContentHeader from './ContentHeader';
import FilterContainer from './FilterContainer';
import PrimaryContentContainer from './PrimaryContentContainer';
import ResultList from '../../filter-list/oc-result-list'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

// Styled Components
const DesktopFilterControls = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1.5em;
  position: relative;
  width: 100%;

  @media screen and (min-width: 1200px) {
    display: ${props => props.visible ? 'flex' : 'none !important'};
    padding-bottom: 2em;
  }
`;

const FilterLink = styled(FilterOpener)`
  margin: 0 32px 0 0;
`;

const ContainerElement = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4em;
  position: relative;
`;

const LoaderContent = styled(Loader)`
  align-items: first baseline;
  padding-top: 90px;
`;

const TabContentContainer = connectLocation(({
  t,
  headerContent,
  sortContent,
  allowSearch,
  filterHeading = "Filters",
  resultCount,
  appliedFilters,
  filters,
  filterContent,
  filterReset,
  noResultContent,
  tabs,
  defaultActiveTab,
  filterChipsVisible,
  resultHeaderComponent,
  results,
  loading,
}) => {
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(defaultActiveTab || tabs.filter(tab => tab.default)[0]?.name)

  const toggleFilterOverlay = () => {
    const filtersContainer = document.getElementById('filters-container');
    overlayOpen ? enableBodyScroll(filtersContainer) : disableBodyScroll(filtersContainer);
    setOverlayOpen(!overlayOpen);
  }

  const toggleTab = (name) => {
    setActiveTab(name);

    if (headerContent.props?.updateParams) {
      headerContent.props.updateParams("tab", name);
    }
  }

  const activeTabObj = tabs.filter(tab => tab.name === activeTab)[0];

  const contentHeaderProps = Object.assign(headerContent.props, {
      toggleTab: toggleTab,
      activeTab: activeTab,
      activeTitle: activeTabObj.title,
      sortContent: sortContent,
    });

  const HeaderContent = headerContent.component;
  const NoResultComponent = noResultContent;
  return (
    <>
      {HeaderContent &&
      <ContentHeader headerStyle={headerContent.props?.headerStyle}>
        <HeaderContent {...contentHeaderProps} />
      </ContentHeader>
      }

      {filterContent &&
      <DesktopFilterControls visible={filterChipsVisible}>
        <FilterLink onClick={() => toggleFilterOverlay()} />
        <FilterChips
          appliedFilters={appliedFilters}
          filterReset={filterReset}
          filters={filters}
          updateParams={headerContent.props.updateParams}
        />
      </DesktopFilterControls>
      }

      <ContainerElement>
        {filterContent &&
        <FilterContainer
          heading={filterHeading}
          resultCount={resultCount}
          overlay={false}
          open={overlayOpen}
          closeFunction={() => toggleFilterOverlay()}
          filterReset={filterReset}
        >
          {filterContent}
        </FilterContainer>
        }
 
        {loading
          ? <LoaderContent />
          : (/*
              Hide filters and results block if: search input is present and result
              count is zero after searching for any text
            */
            allowSearch && NoResultComponent && headerContent.props.searchValue && resultCount === 0
              ? <NoResultComponent searchValue={headerContent.props.searchValue} />
              : (
                <>
                  <PrimaryContentContainer primaryActive={true} filterContent={filterContent}>
                    <ResultList resultComponentStyle={activeTabObj.displayStyle}
                                resultHeaderComponent={resultHeaderComponent}
                                results={results}
                                resultComponent={activeTabObj.component}
                                grouping={activeTabObj.grouping}
                                sortContent={sortContent}
                    />
                  </PrimaryContentContainer>
                </>
              )
          )
        }
      </ContainerElement>
    </>
  )
});


export default wrappedInReduxProvider(connectLocation(TabContentContainer));

import React from 'react';
import FilterToggle, {MULTISELECT_STRATEGY} from "../../FilterToggle";
import ContentGroupDropdown from "../oc-content-group-dropdown";
import styled from '@emotion/styled';
import { connectLocation } from '../../location/LocationConnectors';

export const ColorWrapper = styled.ul`
  padding-left: 4px;
  display: flex;
	flex-direction: row;
	flex-wrap: wrap;
  list-style: none;

  li {
    margin-bottom: 7px;

    &:last-child {
      margin-bottom: 0;
    }

    &.active{
     label{
        outline-offset: 2px;
        outline-color: #d40f7d;
        outline-style: solid;
        outline-width: 2px;
      }
    }
  }

	label {
	  margin-right: 10px;
		width: 64px;
		height: 55px;
		justify-content: center;
		display: flex;
		align-items: center;
		font-size: 13px;
		border: 1px solid #949494;
		color: white;

		&[color=brown] {
			background-color: #69513A;
		}

		&[color=green] {
			background-color: #4C5D4C;
		}

		&[color=gray] {
			background-color: #595959;
		}

		&[color=red] {
			background-color: #AF0E21;
		}

		&[color=blue] {
			background-color: #3B5D6D;
		}

		&[color=purple] {
			background-color: #50415d;
		}

		&[color=black] {
			background-color: black;
		}

		&[color=white] {
			color: black;

			input[type=checkbox]:checked + .check {
				border-color: black;
			}
		}

		input[type=checkbox] {
			visibility: hidden;
			position: absolute;
			left: 0;
			top: 0;

			&:checked + .check {
		 		height: 19px;
	  		width: 29px;
	  		border-left: 7px solid white;
	  		border-bottom: 7px solid white;
	  		left: -12px;
	  		top: -12px;
	  		position: absolute;
        transform: rotate(-45deg);
			}

			&:checked ~ .label-text {
				visibility: hidden;
			}

		}
	}

	span.label-text {
		width: auto!important;
	}

  label:focus{
    outline-style: none;
  }
`

const FilterListColorWrapper = styled(ColorWrapper)`
  margin: 0;
  padding: 0;
`

const ColorFilter = ({ options, defaultValue, onChange, filterAttribute, title, filtersConfig, filterCounts, translationKey, t }) => {
  const translatedDisplayName = (option) => {
    return option.translationKey ? t(option.translationKey) : option.displayName;
  };

  const displayTitle = translationKey ? t(translationKey) : title;

  return(
    <ContentGroupDropdown label={displayTitle}>
      <FilterListColorWrapper className="oc-filter-list no-border" aria-labelledby="filter_color">
        {
          options.map(option => (
            <FilterToggle
              key={option.value}
              filters={{colors: (defaultValue || [])}}
              filterKey={"colors"}
              defaultEnabled={false}
              name={translatedDisplayName(option)}
              resultsCount={filterCounts[option.value]}
              value={option.value}
              labelProps={{color: option.value}}
              onToggleFilter={(props) => onChange(filterAttribute, props.colors)}
              filterFunc={null}
              filtersConfig={filtersConfig}
            />
          ))
        }
      </FilterListColorWrapper>
    </ContentGroupDropdown>
  )
}

ColorFilter.filterValueType = 'multiple';
ColorFilter.getChipInfo = (filter, filterValues) => {
  if (Array.isArray(filterValues)){
    return filterValues.map(filterValue => ({ ...filter.options.filter(option => option.value === filterValue)[0] }))
  }
}

export default connectLocation(ColorFilter);

import styled from '@emotion/styled';

import { connectLocation } from '../../../../location/LocationConnectors';

const Fieldset = styled.fieldset`
  display: inline-block;
  margin-bottom: 1em;
  position: relative;
  width: 100%;
  text-wrap: nowrap;

  @media screen and (min-width: 768px) {
    width: calc(50% - 1em);

    &:nth-last-of-type(2) {
      margin-bottom: 0;
    }
  }
  label {
    max-width: 100%;
  }
  select {
    width: 100%;
  }
`;

const SelectLanguageField = connectLocation(
  ({ t, defaultLanguage, onWidgetDefaultLanguageChange }) => (
    <div className="oc-form">
      <Fieldset>
        <legend className="h4">
          {t('design_inspire.widget.select_widget_language')}
        </legend>
        <label className="select-container">
          <select
            value={defaultLanguage}
            onChange={onWidgetDefaultLanguageChange}
            aria-label={t('design_inspire.widget.select_widget_language')}
          >
            <option value="en-US">English</option>
            <option value="es-US">Spanish</option>
          </select>
        </label>
      </Fieldset>
    </div>
  )
);

export default SelectLanguageField;

import _ from 'lodash';
import Blots from '../components/Builder/Library/Text/Rich/Blots';
import { Quill } from 'react-quill';
import escapeHtml from './escape_html';
const Delta = Quill.import('delta');

export default class DeltaRenderer {
  constructor(value) {
    this.value = new Delta(value)
  }

  reset() {
    this.state = {
      output: [],
      buffer: '',
      container: ''
    }
  }

  render() {
    this.reset()
    this.splitOpsByLines().forEach(({ops, attributes, block}) => {
      this.setContainerForAttributes(attributes);
      this.state.buffer += this.applyAttributes(ops.map(op => {
        if (_.isString(op.insert)) {
          return this.applyAttributes(op.insert, op.attributes);
        } else if (_.isPlainObject(op.insert)) {
          return Object.entries(op.insert || {}).reduce((current, [key, value]) => {
            const blotEmbed = Blots.Embeds.named(key);
            const blotBlock = Blots.BlockEmbeds.named(key);
            const blot = blotEmbed || blotBlock;
            if (blot && value) {
              return blot.renderToString(value)
            } else {
              return current;
            }
          }, '')
        }
      }).join(''), (!attributes?.list && !block) ? { p: true } : attributes);
    })

    this.flush();
    return this.state.output.join('');
  }

  isBlock(blotName) {
    return !!(Blots.Blocks.named(blotName) || Blots.BlockEmbeds.named(blotName))
  }

  splitOpsByLines() {
    const lines = [];
    let currentLine = { ops: [] };
    this.value.ops.forEach(op => {
      if (_.isString(op.insert)) {
        op = _.cloneDeep(op);
        while (op.insert.length > 0 && op.insert[0] === '\n') {
          // finish prev line with these attributes
          if (currentLine.ops.length === 0) {
            currentLine.ops.push({ insert: '<br>' });
          }
          currentLine.attributes = { ...op.attributes };
          if (Object.keys(currentLine.attributes||{}).filter(x => this.isBlock(x)).length > 0) {
            currentLine.block = true;
          }
          lines.push(currentLine);
          currentLine = { ops: [] };
          op.insert = op.insert.slice(1);
        }
        const splits = op.insert.split('\n');
        splits.forEach((split, index) => {
          const value = escapeHtml(split) || ((index > 0 && index < splits.length-1) ? '<br>' : '');
          if (value) {
            currentLine.ops.push({ insert: value, attributes: (index < splits.length - 1 ? {} : op.attributes) });
          }
          if (currentLine.ops.length > 0) {
            currentLine.attributes = { ...op.attributes };
            if (Object.keys(currentLine.attributes || {}).filter(x => Blots.Blocks.named(x)).length > 0) {
              currentLine.block = true;
            }
            if (currentLine.block || index < splits.length - 1) {
              lines.push(currentLine);
              currentLine = { ops: [] };
            }
          }
        });
      } else if (_.isPlainObject(op.insert)) {
        if (Object.keys(op.insert) > 1) {
          console.warn('more than one operation in insert: ', op.insert);
        }
        const key = Object.keys(op.insert)[0]
        const value = op.insert[key];
        const blockblot = Blots.BlockEmbeds.named(key);
        if (blockblot && value) {
          if (currentLine.ops.length > 0) {
            if (Object.keys(currentLine.attributes||{}).filter(x => Blots.Blocks.named(x)).length > 0) {
              currentLine.block = true;
            }
            lines.push(currentLine);
            currentLine = { ops: [] };
          }
          currentLine.ops.push(op);
          currentLine.attributes = { ...op.attributes };
          currentLine.block = true;
          lines.push(currentLine);
          currentLine = { ops: [] };
        } else {
          currentLine.ops.push(op);
        }
      }
    })
    if (currentLine.ops.length > 0) {
      if (Object.keys(currentLine.attributes||{}).filter(x => Blots.Blocks.named(x)).length > 0) {
        currentLine.block = true;
      }
      lines.push(currentLine);
      currentLine = { ops: [] };
    }
    return lines;
  }

  setContainerForAttributes(attributes) {
    if (attributes && attributes.list) {
      const newContainer = attributes.list === 'bullet' ? 'ul' : 'ol';
      if (newContainer !== this.state.container) {
        this.setContainer(newContainer);
      }
    } else if (!attributes?.list) {
      this.setContainer('');
    }
  }

  setContainer(newContainer) {
    this.flush();
    this.state.container = newContainer;
  }

  applyAttributes(output, attributes) {
    if (!output && attributes?.p) {
      return output;
    }
    return Object.entries(attributes || {}).reduce((current, [key, value]) => {
      switch (key) {
        case 'p':
          return value ? `<p>${ current }</p>` : current
        case 'bold':
          return value ? `<strong>${ current }</strong>` : current;
        case 'italic':
          return value ? `<em>${ current }</em>` : current;
        case 'list':
          let indent = attributes?.indent;
          let indentClass = indent ? `ql-indent-${indent}` : "";
          return `<li class="${indentClass}" data-list="${value}">${ current }</li>`;
        case 'link':
          return Blots.Inlines.named('link').renderToString(value, current);
        case 'script':
          return value === 'super' ? `<sup>${ current }</sup>` : `<sub>${ current }</sub>`;
        default: {
          const blot = Blots.Blocks.named(key) || Blots.Inlines.named(key);
          if (blot && value) {
            return `<${ blot.tagName.toLowerCase() }${ blot.className ? ` class="${ blot.className }"` : '' }>${ current }</${ blot.tagName.toLowerCase() }>`
          } else {
            return current;
          }
        }
      }
    }, output)
  }

  flush() {
    if (this.state.buffer) {
      this.state.container ?
        this.state.output.push(`<${ this.state.container }>${ this.state.buffer }</${ this.state.container }>`) :
        this.state.output.push(this.state.buffer)
    }
    this.state.buffer = '';
  }
}

import { useEffect, useState } from 'react';

const useSelectLanguageField = ({ locale }) => {
  const { code = 'en-US' } = locale;
  const storedDefaultLanguage = window.localStorage.getItem('widgetDefaultLanguage');
  const [defaultLanguage, setDefaultLanguage] = useState(storedDefaultLanguage || code);

  useEffect(() => {
    const handleLocalStorageLanguageChange = (e) => {
      if (e.key === 'widgetDefaultLanguage' && e.newValue) {
        setDefaultLanguage(e.newValue);
      }
    }

    window.addEventListener('storage', handleLocalStorageLanguageChange);

    return () => {
      window.removeEventListener('storage', handleLocalStorageLanguageChange);
    };
  }, []);

  const onWidgetDefaultLanguageChange = (e) => {
    setDefaultLanguage(e.target.value);
    window.localStorage.setItem('widgetDefaultLanguage', e.target.value);
  }

  return {
    defaultLanguage,
    onWidgetDefaultLanguageChange
  }
}

export default useSelectLanguageField;

import Cta from './oc-cta.jsx';
import { css } from '@emotion/react';
import Text from './oc-text';
import { H3, H6 } from './oc-h';
import { useInView } from "react-intersection-observer";
import { useEffect, useRef } from "react";
import anime from "animejs";
import AnimateTextContainer from "./shared/oc-animated-text-container";
import AnimatedAttentionBarWrapper from "./shared/oc-animate-wrapper";

const attentionBarWithColumnsStyles = ({noMargin}) => css`
  background: black;
  padding: 20px;
  margin-bottom: 35px;
  color: white;

  @media (min-width: 768px) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    width: 100%;
    margin: 0 auto 30px;
    padding: 30px;
    align-items: center;
  }

  @media (min-width: 1200px), screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    justify-content: left;
    width: 100%;
    padding: 35px;
    margin-bottom: 70px;
  }

  h2,h3 {
    color: white;
    text-align: center;
    flex: none;

    @media(min-width: 768px) {
      flex: 1;
      text-align: left;
    }

    @media (min-width: 1200px), screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      margin: 0 35px 0 0;
    }
  }

  .ab-columns {
    display: flex;
    flex-direction: row;
    padding: 0;

    @media(max-width: 767px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .col {
      display: inline-flex;
      align-items: start;
      flex-direction: column;
      justify-content: center;
      padding: 0;

      &:first-of-type {
        margin-bottom: 30px;

        @media(min-width: 768px) {
          border-right: 1px solid white;
          padding-right: 15px;
          margin-right: 15px;
          margin-bottom: 0;
        }

        @media(min-width: 1200px) {
          padding-right: 30px;
          margin-right: 30px;
        }
      }

      h6 {
        color: white!important;
        padding-right: 30px;
      }

      .cta-container {
        display: block;
        width: auto;
        margin: 0 auto;

        @media (min-width: 1200px) {
          display: flex;
          margin: 0;
        }
      }

      .ab-cta {
        margin: 0 auto;
        text-align: center;

        & + .ab-cta {
          margin-top: 20px;
        }

        @media(min-width: 768px) and (max-width: 1199px) {
          margin: 0;
          text-align: left;
          width: auto;

          & + .ab-cta {
            margin-top: 10px;
          }
        }

        @media(min-width: 1200px) {
          margin: 0 15px;

          &:first-of-type {
            margin-left: 0;
          }

          &:last-of-type {
            margin-right: 0;
          }

          & + .ab-cta {
            margin-top: 0;
          }
        }
      }
    }
  }

  ${noMargin && css`
    margin-bottom: 0px !important;
  `}
`;

const Content = {
  enUs: {
    title: 'Find Our Products',
    col1Heading: 'Commercial and Industrial:',
    col1CtaUrl: '/en-us/insulation/commercial/find-a-sales-rep',
    col1CtaText: 'Owens Corning Sales',
    col2Heading: 'Residential:',
    col2Ctas: [
      {
        text: 'Distribution & Retail',
        url: '/en-us/insulation/residential/distributors-near-me'
      },
      {
        text: 'Insulation Contractors',
        url: '/en-us/insulation/find-a-professional'
      }
    ]
  },
  enCa: {
    title: 'Find Our Products',
    col1Heading: 'Commercial and Industrial:',
    col1CtaUrl: 'https://www.owenscorning.com/dms/501040CA',
    col1CtaText: 'Owens Corning Experts',
    col2Heading: 'Residential:',
    col2Ctas: [
      {
        text: 'Distribution & Retail',
        url: 'http://insulation.owenscorning.ca/homeowners/dealer-locator/'
      }
    ]
  },
  frCa: {
    title: 'Trouvez nos Produits',
    col1Heading: 'Commercial et industriel:',
    col1CtaUrl: 'http://fr-insulation.owenscorning.ca/uploadedFiles/architects/OC_Building%20Science%20Team%20Contact%20PDF%20Page_FRE_FINAL.PDF',
    col1CtaText: 'Experts Owens Corning',
    col2Heading: 'Résidentiel:',
    col2Ctas: [
      {
        text: 'Distribution et vente au détail',
        url: 'http://fr-insulation.owenscorning.ca/homeowners/dealer-locator/?LangType=3084'
      }
    ]
  }
}

const AttentionBarWithColumns = ({ layout="enUs", noMargin=false, animationProps}) => {
  const {animate, flashAnimRef, slideAnimRef } = animationProps || {animate: false, flashAnimRef: useRef(null), slideAnimRef: useRef(null)};
  const lang = Content[layout];
  const [inViewRef, inView] = useInView({
    threshold: 1,
    triggerOnce: true,
  });
  const animationTimeline = anime.timeline({
    autoplay: false,
  });

  useEffect(() => {
    if (animate) {
      AnimateTextContainer(inView, slideAnimRef.current, animationTimeline);
    }
  }, [inView, animate]);


  return (
    <AnimatedAttentionBarWrapper
      wrapperProps={{
        className: 'flash-bar-columns',
        layout: layout,
      }}
      target={flashAnimRef}
      animate={animate}
      reference={inViewRef}
      inView={inView}
      animationTimeline={animationTimeline}
      styles={attentionBarWithColumnsStyles ({noMargin })}
    >
      <div ref={slideAnimRef} className={'bar-columns-text-container'}>
        <H3><Text content={lang.title} /></H3>
      </div>

      <div class="ab-columns">
        <div class="col">
          <H6><Text content={lang.col1Heading} /></H6>
          <div class="ab-cta">
            <Cta href={lang.col1CtaUrl} color="black" color="black">
              <Text content={lang.col1CtaText} />
            </Cta>
          </div>
        </div>

        <div class="col">
          <H6><Text content={lang.col2Heading} /></H6>

          <div class="cta-container">
            {lang.col2Ctas.map((cta) => (
                <div class="ab-cta">
                  <Cta href={cta.url} color="black">
                    <Text content={cta.text} />
                  </Cta>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </AnimatedAttentionBarWrapper>
  )
};

export default AttentionBarWithColumns;


import { SET_COLOR_SELECTED } from '../actions/StyleBoardActions';

const DEFAULT_INITIAL_STATE = {
  colorSelected: null,
};

const styleBoardReducer = (state = DEFAULT_INITIAL_STATE, action) => {
  const newState = { ...state };

  if (action.type === SET_COLOR_SELECTED) {
    newState.colorSelected = action.colorSelected;
    return newState;
  }

  return state;
};

export default styleBoardReducer;

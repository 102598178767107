import Image from '../../../OC/oc-image';
import InfoIcon from '../../../OC/oc-info-icon';
import { openReusableModal } from '../../../PageBuilder/helpers/modal';

const LegendItem = ({ item }) => {
  const { icon = {}, details = {}, label } = item;
  const { reusable } = details;
  const { alt, file } = icon;

  const onClick = () => openReusableModal(reusable, label);
  return (
    <div>
      {file && <Image src={file} alt={alt} />}
      {label && <span>{label}</span>}
      {details && reusable && <InfoIcon clickHandler={onClick} />}
    </div>
  );
};

export default LegendItem;

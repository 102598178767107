import _ from 'lodash';

class Themes {
  constructor(base, themes) {
    Object.keys(themes).map(( name ) => { this[name] = name; });
    this.themes = {
      base: base,
      ...themes
    };
  }

  get(theme, selector, ...args) {
    if (Array.isArray(selector)) return selector.map((part) => ( Array.isArray(part) ? this.get(theme, ...part) : this.get(theme, part) ));

    const base = _.get(this.themes.base, selector);
    const style = _.get(this.themes[theme], selector);

    return [ _.isFunction(base) ? base(...args) : base, _.isFunction(style) ? style(...args) : style ];
  }
}

export default Themes;

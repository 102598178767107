import { useState } from 'react';
import styled from '@emotion/styled';
import { jsx, css } from '@emotion/react';
import Cta from '../../OC/oc-cta';
import Modal from '../../Modal';
import { connectLocation } from "../../location/LocationConnectors";

const HotspotHeroContainer = styled.div`
  background-image: url(https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,q_auto/v1634298172/insulation/commercial/hero-mobile.jpg);
  background-size: 370px;
  background-position: center 133px!important;
  background-repeat: no-repeat;
  margin: 0 0 35px;

  @media screen and (min-width: 768px) {
    background-image: url(https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,q_auto/v1634298172/insulation/commercial/hero-tablet.jpg);
    background-size: 768px 531px!important;
    background-position: bottom center!important;
    margin: 0 -15px 35px;
  }

  @media screen and (min-width: 1200px), screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    background-image: url(https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,q_auto/v1634298172/insulation/commercial/hero-desktop.jpg);
    background-size: 1340px 910px!important;
  }
`;

const HeroGrid = styled.div`
	display: grid;
  @supports (display: grid) {
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(5, 90px) 63px;
  }

  @media (min-width: 768px) {
    @supports (display: grid) {
      grid-template-columns: 1fr repeat(12, 64px) 1fr;
      grid-template-rows: repeat(26, 20px);
    }
  }

  @media (min-width: 1200px), screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    -ms-grid-columns: 1fr 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 1fr;
    -ms-grid-rows: 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px;

    @supports (display: grid) {
      grid-template-columns: 1fr repeat(12, 100px) 1fr;
      grid-template-rows: repeat(26, 35px);
    }

    .fill {
      display: none;
      background: white;

      @media(min-width: 768px) {
      	display: block;
      }
    }
  }
`;

const Main = styled.div`
	background: white;
	z-index: 1;
  padding-top: 0;
  position: relative;
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  padding: 0 15px 0;
  background: transparent;
  text-align: right;

  @media screen and (min-width: 768px) {
    background: white;
    position: relative;
    grid-column: 6 / 12;
    grid-row: 1 / 8;
  }

  @media screen and (min-width: 1200px) {
    position: relative;
    grid-column: 6 / 12;
    grid-row: 1 / 8;
    padding: 15px 30px 0;
  }

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    position: relative;
    -ms-grid-column: 6;
    -ms-grid-column-span: 6;
    -ms-grid-row: 1;
    -ms-grid-row-span: 8;
  }
`;

const FillTop = styled.div`
	position: relative;
	background: white;
	z-index: 1;
	display: none;

  @media(min-width: 768px) {
  	display: block;
    grid-column: 1 / 6;
    grid-row: 1 / 1;
  }

  @media screen and (min-width: 1200px) {
    grid-column: 1 / 6;
    grid-row: 1 / 1;
  }

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    -ms-grid-column: 1;
    -ms-grid-column-span: 6;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
  }
`;

const InteractiveContainer = styled.div`
	position: relative;
  grid-column: 1 / 1;
  grid-row: 2 / 6;
  padding: 0;
  margin-top: 15px;

  @media screen and (min-width: 768px) {
    position: relative;
    grid-column: 2 / 14;
    grid-row: 11 / 27;
    margin: 0;
  }

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    position: relative;
    -ms-grid-column: 2;
    -ms-grid-column-span: 12;
    -ms-grid-row: 11;
    -ms-grid-row-span: 16;
    padding: 15px;
  }
`;

const MenuContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width: 75%;

  @media(min-width: 768px) {
    right: 15px;
    transform: translateX(0);
    left: auto;
    width: auto;
  }
`;

const MenuGroup = styled.div`
  background: white;
  margin-bottom: 10px;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 50%);
  padding: 10px 20px;

  @media(min-width:  1200px) {
    padding: 15px 20px;
  }
`;

const GroupHeading = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 10px;

  @media(min-width:  1200px) {
    font-size: 21px;
    line-height: 21px;
    margin-bottom: 15px;
  }
`;

const MenuCTAStyle = css`
  display: block;
  line-height: 16px;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 0;
  border-bottom: 1px solid #e6e6e6;
  color: #333333;
  text-decoration: none;

  @media(min-width: 1200px) {
    line-height: 18px;
    font-size: 18px;
    padding: 10px 0;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  &:hover, &:focus, &.selected, &.hover {
    color: #d40f7d;
    cursor: pointer;
    text-decoration: underline;
  }

  &:focus {
    outline-offset: 2px;
    outline-color: #d40f7d;
    outline-style: solid;
    outline-width: 2px;
  }
`;

const HotSpotTrigger = styled.span`
  ${MenuCTAStyle}
`
const MenuLink = styled.a`
  ${MenuCTAStyle}
  padding: 0!important;
  position: relative;

  &:after {
    content: '\f054';
    color: black;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'fontawesome';
  }

  &:hover {
    &:after {
      color: #d40f7d;
    }
  }
`

const HotSpot = styled.span`
  color: black;
  background: white;
  font-weight: 700;
  position: absolute;
  border-radius: 15px;
  border: 2px solid white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, .75);
  display: none;

  @media(min-width: 768px) {
    display: block;
    font-size: 12px;
    line-height: 12px;
    padding: 0 3px;
  }

  @media(min-width: 1200px) {
    font-size: 26px;
    line-height: 26px;
    padding: 0 6px;
  }

  &:hover, &.selected, &.hover {
    color: white;
    background: #d40f7d;
    box-shadow: 0px 0px 17px rgba(212, 15, 125, 1);
    cursor: pointer;
    text-decoration: none;

    @media(min-width: 1200px) {
      box-shadow: 0px 0px 35px rgba(212, 15, 125, 1);
    }
  }

  &.roofs {
    @media(min-width: 768px) {
      top: 0;
      left: 410px;
    }

    @media(min-width: 1200px) {
      top: 0;
      left: 660px;
    }
  }

  &.perimeter-walls {
    @media(min-width: 768px) {
      top: 80px;
      left: 200px;
    }

    @media(min-width: 1200px) {
      top: 130px;
      left: 225px;
    }
  }

  &.roof-decks {
    @media(min-width: 768px) {
      top: 0;
      left: 410px;
    }

    @media(min-width: 1200px) {
      top: 0;
      left: 660px;
    }
  }

  &.walls {
    @media(min-width: 768px) {
      top: 80px;
      left: 200px;
    }

    @media(min-width: 1200px) {
      top: 130px;
      left: 225px;
    }
  }

  &.foundations {
    @media(min-width: 768px) {
      bottom: 10px;
      left: 375px;
    }

    @media(min-width: 1200px) {
      bottom: 30px;
      left: 900px;
    }
  }
`;

const InfoModalBody = styled.div`
  a {
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media(min-width: 768px) {
    a {
      margin-right: 16px;
      margin-bottom: 0;

      &:last-of-type {
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }

  .columns {
    display: flex;
    flex-direction: column-reverse;

    @media(min-width: 768px) {
      flex-direction: row;
    }
  }

  .content-column {
    margin-bottom: 15px;

    @media(min-width: 768px) {
      margin-bottom: 0;
      flex-basis: 53%
    }

    hr {
      margin: 15px 0;
    }

    &.image {
      text-align: center;

      @media(min-width: 768px) {
        flex-basis: 47%;

        .image-container {
          padding-left: 35px;
        }
      }

      img {
        max-width: 100%;
      }
    }
  }

  .pink {
    color: #d40f7d;
  }
`;

const CommercialInteractiveHero  = connectLocation(({ t }) => {
  const applications =  t('commercial.applications');
  const hotspots = t('commercial.hotspots');
  const modals = t('commercial.applications.children');

  const [hovered, setHovered] = useState('');
  const [selected, setSelected] = useState('');
  const [active, setActive] = useState(false);

  const onClose = () => {
    setActive(false);
    setSelected('');
  }

	return (
		<HotspotHeroContainer>
			<HeroGrid>
				<Main className="main rball-header">
          <span className="prehead" >{ t('commercial.hero_prehead') }</span>
          <h1 className="tier2">
            { t('commercial.hero_heading_black') }
            <span className="pink">{ t('commercial.hero_heading_pink') }</span>
          </h1>
          <p></p>
        </Main>

        <InteractiveContainer className="test">
          <MenuContainer>
            <h5><strong>{ t('commercial.interactive_heading') }</strong></h5>

            {
              applications.map(app => (
                <MenuGroup>
                  {app.children ?
                    <>
                    <GroupHeading>{t('commercial.trigger_heading')}</GroupHeading>

                    {
                      app.children.map(child => (
                        <HotSpotTrigger
                          className={(hovered === child.class || selected === child.class) && 'hover'}
                          onMouseEnter={() => setHovered(child.class)}
                          onMouseLeave={() => setHovered('')}
                          onClick={() => setSelected(child.class)}
                          onKeyPress = {(e) => {
                            if (e.key === 'Enter' || e.key === 'Space') {
                              setSelected(child.class);
                            }
                          }}
                          tabIndex="0"
                          role="menuitem"
                          aria-haspopup="true"
                          aria-expanded="false"
                          data-track="select-by"
                          data-track-select-category="Commercial Applications"
                          data-track-select-option={child.name}
                        >
                          {child.name}
                        </HotSpotTrigger>
                      ))
                    }
                    </>
                  :
                    <MenuLink href={app.target}
                      role="menuitem"
                      data-track="select-by"
                      data-track-select-category="Commercial Applications"
                      data-track-select-option={app.name}
                    >
                      {app.name}
                    </MenuLink>
                  }
                </MenuGroup>
              ))
            }
          </MenuContainer>

          {
            hotspots.map(hotspot => (
              <HotSpot
                className={`${(hovered === hotspot.class_name || selected === hotspot.class_name) && 'hover'} ${hotspot.class_name}`}
                onMouseEnter={() => setHovered(hotspot.class_name)}
                onMouseLeave={() => setHovered('')}
                data-track="select-by"
                data-track-select-category="Commercial Applications"
                data-track-select-option={hotspot.select_object}
                tabindex="-1"
                onClick={() => setSelected(hotspot.class_name)}
                onKeyPress = {(e) => {
                  if (e.key === 'Enter' || e.key === 'Space') {
                    setSelected(hotspot.class_name);
                  }
                }}
              >
                +
              </HotSpot>
            ))
          }

          {
            applications.map(app => (
              <>
                {app.children &&
                  <>
                    { app.children.map(modal => (
                        <Modal name={modal.heading} size="sm" open={modal.class === selected && (() => setActive(true))} onClose={onClose}>
                          <InfoModalBody>
                            <h3>{modal.heading}</h3>

                            <p>{modal.text}</p>

                            <Cta href={modal.target}
                              data-track="learn-more"
                              data-track-element-location="hero CTA"
                              data-track-destination={modal.link_text_destination}
                              dangerouslySetInnerHTML={{__html: modal.link_text}}
                            />

                            {
                              modal.target2 &&
                              <Cta href={modal.target2}
                                color="white"
                                data-track="learn-more"
                                data-track-element-location="hero CTA"
                                data-track-destination={modal.link_text2_destination}
                                dangerouslySetInnerHTML={{__html: modal.link_text2}}
                              />

                            }
                          </InfoModalBody>
                        </Modal>
                      ))
                    }
                  </>
                }
              </>
            ))
          }
        </InteractiveContainer>

        <FillTop />
			</HeroGrid>
		</HotspotHeroContainer>
	);
});

export default CommercialInteractiveHero;

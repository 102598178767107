import AtticatCalculator from '../../composites/Atticat/AtticatCalculator';
import RichText from './RichText';
import { BasicContent } from './BasicContent';


const CMSAtticatCalculator = ({...content}) => {
  return (
    <>
      <BasicContent { ...content.copy } />
      <AtticatCalculator />
      <RichText content={content.footnotes} />
    </>
  )
};

export default CMSAtticatCalculator;

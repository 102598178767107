import { createElement } from 'react';

const CTAButton = ({ element = 'a', text, children, overrides = {}, dispatch=null, ...props }) => (
  createElement(element,
    {role: 'button', className: "oc-cta-button", ...props, ...overrides},
    overrides.text || text || children
    )
);


export default CTAButton;

import { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { css } from '@emotion/react';
import styled from "@emotion/styled";

import { H3 } from './oc-h';
import Text from './oc-text';
import anime from "animejs";
import AnimateTextContainer from "./shared/oc-animated-text-container";
import AnimatedAttentionBarWrapper from "./shared/oc-animate-wrapper";

const attentionBarStyles = ({ noMargin }) => css`
  align-items: center;
  background: black;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 35px;
  padding: 20px;
  width: 100%;

  @media (min-width: 768px) {
    gap: 32px;
    justify-content: space-between;
    margin: 0 auto 30px;
    padding: 30px;
  }

  @media (min-width: 1200px), screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex-direction: row;
    margin-bottom: 70px;
    padding: 35px;
  }

  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6,
  .prehead {
    color: white !important;
  }

  h2, h3, h4, h5, h6 {
    display: flex;
    margin: 0;
  }

  .cta-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: auto;

    @media (min-width: 768px) {
      flex-direction: row;
      gap: 32px;
    }
  }

  ${noMargin && css`
    margin-bottom: 0px !important;
  `}
`;

const HeadingContainer = styled.div`
  align-items: center;
  display: flex;
`;

const AttentionBarButtons = ({ children, heading, headingLevel, noMargin=false, animationProps, ctas }) => {
  const {animate, flashAnimRef, slideAnimRef } = animationProps || {animate: false, flashAnimRef: useRef(null), slideAnimRef: useRef(null)};
  const [inViewRef, inView] = useInView({
    threshold: 1,
    triggerOnce: true, // set this flag to false if you want to animate the text on every scroll into view
  });

  const animationTimeline = anime.timeline({
    autoplay: false,
  });

  useEffect(() => {
    if (animate) {
      AnimateTextContainer(inView, slideAnimRef.current, animationTimeline);
    }
  }, [inView, animate]);

  const handleAnimationBegin = () => {
    if(ctas.find(cta => cta.destination === 'pricespider')){
      if (typeof (PriceSpider) !== 'undefined') {
        PriceSpider.rebind();
      }
    }
  }

  return (
    <AnimatedAttentionBarWrapper
      wrapperProps={{
        className: 'flash-bar-buttons',
      }}
      target={flashAnimRef}
      animate={animate}
      reference={inViewRef}
      inView={inView}
      animationTimeline={animationTimeline}
      animationBeginCallback={handleAnimationBegin}
      styles={attentionBarStyles({ noMargin })}
    >
      <HeadingContainer ref={slideAnimRef}>
        <H3><Text content={heading} /></H3>
      </HeadingContainer>

      <div className="cta-container">
        { children }
      </div>
    </AnimatedAttentionBarWrapper>
  )
};

export default AttentionBarButtons;

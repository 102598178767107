import styled from '@emotion/styled';

import { Mobile, Tablet, Desktop } from './internals/Devices';
import { Screens } from '../../PageBuilder/editing/Viewport';
import { BasicContent } from './BasicContent';
import { BlockLinks, BlockLink } from '../oc-block-links';
import Cta from './Cta';

import Text from './utilities/Text';

import Picture from '../oc-picture';
import { is_placeholder_url, placeholder_aspect } from '../../../placeholder';

const Sizes = {
  'xl': { [Mobile]: 345, [Tablet]: 738, [Desktop]: 1170 },
  'l': { [Mobile]: 345, [Tablet]: 738, [Desktop]: 970 },
  'm': { [Mobile]: 225, [Tablet]: 482, [Desktop]: 770 },
};

const BlockLinkWrapper = styled.div`
  display: flex;
  justify-content: ${ (props) => props.alignment === 'Left' ? 'flex-start' : 'center' };
`;

const BlockLinkContainer = styled.div`
  margin: 0;
  width: ${ ({ width }) => `${ width.Mobile }px` };
  @media (min-width: ${ Screens.Tablet }px) { width: ${({ width }) => `${ width.Tablet}px` }; }
  @media (min-width: ${ Screens.Desktop }px) { width: ${({ width }) => `${ width.Desktop}px` }; }
`;

const Row = styled.div`
  padding-top: ${ (props) => props.index > 0 ? 32 : 0 }px;
`;

const CMSBlockLinkSet = ({ copy, blocks, showCta = true, cta, containerSize , alignment }) => {
  const size = containerSize?.selection || 'xl';
  const Width = Sizes[size];

  const calculatedImage = (image, index) => {
    let file = image?.file
    let crop = image?.crop
    if (file && !is_placeholder_url(file) && crop) {
      file = Picture.Transform(file, { crop: 'crop', ...crop })
    }
    let aspectRatio = blocks[index]?.aspectRatio;

    if (aspectRatio === undefined || aspectRatio === null) {
      return file;
    }

    const aspectRatioTransform = Picture.AspectRatioTransform(aspectRatio);
    if (is_placeholder_url(file) && aspectRatioTransform) {
      file = placeholder_aspect(file, aspectRatioTransform.aspectRatio)
    }

    return Picture.Transform(file, [{ width: file?.width, crop: 'fill' }], aspectRatioTransform);
  }

  return (
    <BlockLinkWrapper alignment={ alignment }>
      <BlockLinkContainer width={ Width } size={ size }>
        <BasicContent { ...copy } />
        { blocks?.map((row, index) => {
          row = Array.isArray(row) ? row : row.row;
          return (
            <Row key={ index } index={ index } >
              <BlockLinks fullWidth noMargin >
                { row?.map((block, i) => (
                  <BlockLink
                    key={ i }
                    url={ block?.url }
                    text={ <Text content={ block?.heading } /> }
                    img={ calculatedImage(block?.image, index) || block?.image }
                    imgAlt={ block?.image ? (block?.image?.alt || block?.alt) : null }
                    analytics={{ 'element-location': 'main section card' }}
                    fullWidth
                  />
                )) }
              </BlockLinks>
            </Row>
          )
        }) }
        { !!showCta &&
          <Cta { ...cta } centered spaced />
        }
      </BlockLinkContainer>
    </BlockLinkWrapper>
  )
}

export default CMSBlockLinkSet;

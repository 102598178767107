import { jsx, css } from '@emotion/react';
import NumberFormat from 'react-number-format';

import { ComparisonTable } from './ComparisonTable';
import Heading from '../../OC/PageBuilder/Heading';

const gwpCalculator = css`
  display: flex;
  flex-direction: column;

  @media(min-width: 1200px) {
    flex-direction: row;
  }

  .select-project-type {
    display: flex;
    flex-direction: column;
    padding: 24px 15px;
    max-width: 100%;
    height: 133px;
    background: #E6E6E6;
    margin-bottom: 24px;

    @media(min-width: 1200px) {
      width: 470px;
      margin-right: 30px;
    }

    .text-container {
      border-bottom: 1px solid #000000;
    }

    .project-type-form {
      display: flex;
      margin-top: 16px;
    }

    &.fr {
      #project-type-selector {
        text-transform: none;
      }
    }

    &.long-options {
      @media(max-width: 768px) {
        height: 146px;
      }

      @media(min-width: 1200px) {
        height: 155px;
      }

      .project-type-form {
        display: block;

        .select-container {
          display: block;
          margin-left: 0px;
          margin-top: 8px;
        }

        @media(min-width: 768px) and (max-width: 1200px) {
          display: flex;
          align-items: center;

          .select-container {
            display: inline-block;
            margin-left: 8px;
            margin-top: 0px;
            width: 350px;
          }
        }
      }
    }

    .select-container {
      position: relative;
      display: inline-block;
      width: 100%;
      margin-left: 8px;

      @media(min-width: 768px) {
        width: 250px;
      }

      @media(min-width: 1200px) {
        width: 100%;
      }

      &:after {
        content: '\f107';
        display: inline;
        font-family: 'fontawesome';
        font-size: 18px;
        color: #333;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        pointer-events: none;
      }

      select {
        color: black;
        background: white;
        border: 1px solid #6d6d6d;
        padding: 10px;
        font-size: 16px;
        line-height: 19px;
        border-radius: 0;
        width: 100%;
        text-transform: capitalize;
        -webkit-appearance: none;
        -moz-appearance: none;
      }
    }
  }

  .results {
    width: 100%;
  }

  .miniHeading {
    font-family: OCRoboto;
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    text-transform: uppercase;
    margin-bottom: 4px;
    display: block;
  }

  .bigPink {
    font-family: Oswald;
    font-size: 34px;
    font-weight: 500;
    line-height: 34px;
    text-align: left;
    color: #D40F7D;
    text-transform: uppercase;
    margin-bottom: 8px;
    display: block;
  }

  .miniText {
    font-size: 12px;
    line-height: 17px;
  }
`

export const GwpCalculator = ({
  t,
  tNumber,
  locale,
  projectTypes,
  projectType,
  setProjectType,
  boardFeetUsedInProject,
  foamularNgxData,
  foamularData,
  duPontStyrofoamData,
}) => {
  const boardFtSuffix = ` ${t('foamular_ngx.gwp_reduction_calculator_table.board_feet_unit')}`;
  const isFrench = locale?.code === 'fr-CA'

  return (
    <div
      id="gwp-reduction-calculator"
      style={{ marginBottom: '0' }}
    >
      <div css={gwpCalculator}>
        <div className={`select-project-type${isFrench ? ' long-options fr' : ''}`}>
          <div className="text-container">
            <h4>{t('foamular_ngx.gwp_reduction_calculator_form_title')}</h4>
          </div>
          <form className="project-type-form">
            <span dangerouslySetInnerHTML={{ __html: t('foamular_ngx.gwp_reduction_calculator_select_title') }}></span>
            <div className="select-container">
              <select
                id="project-type-selector"
                value={projectType}
                onChange={e => setProjectType(e.target.value)}
                data-track="select-by"
                data-track-select-category="Project Type"
              >
                {projectTypes.map(pt => (
                  <option value={pt}>{t(`foamular_ngx.gwp_reduction_calculator_options.${pt}`)}</option>
                ))}
              </select>
            </div>
          </form>
        </div>
        <div className="results">
          <ComparisonTable>
            <div className="oc-table-responsive">
              <table className="fourUP oc-table">
                <thead>
                  <tr>
                    <th dangerouslySetInnerHTML={{ __html: t('foamular_ngx.gwp_reduction_calculator_table.value')}}></th>
                    <th className="colProPink" dangerouslySetInnerHTML={{ __html: t('foamular_ngx.gwp_reduction_calculator_table.foamular_ngx')}}></th>
                    <th dangerouslySetInnerHTML={{ __html: t('foamular_ngx.gwp_reduction_calculator_table.foamular')}}></th>
                    <th dangerouslySetInnerHTML={{ __html: t('foamular_ngx.gwp_reduction_calculator_table.dupont_styrofoam')}}></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t('foamular_ngx.gwp_reduction_calculator_table.board_feet')}</td>
                    <td className="center">
                      <NumberFormat
                        value={boardFeetUsedInProject}
                        displayType="text"
                        thousandSeparator={tNumber.thousandSeparator}
                        decimalSeparator={tNumber.decimalSeparator}
                        suffix={boardFtSuffix}
                        decimalScale={0}
                      />
                    </td>
                    <td className="center">
                      <NumberFormat
                        value={boardFeetUsedInProject}
                        displayType="text"
                        thousandSeparator={tNumber.thousandSeparator}
                        decimalSeparator={tNumber.decimalSeparator}
                        suffix={boardFtSuffix}
                        decimalScale={0}
                      />
                    </td>
                    <td className="center">
                      <NumberFormat
                        value={boardFeetUsedInProject}
                        displayType="text"
                        thousandSeparator={tNumber.thousandSeparator}
                        decimalSeparator={tNumber.decimalSeparator}
                        suffix={boardFtSuffix}
                        decimalScale={0}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>{t('foamular_ngx.gwp_reduction_calculator_table.gwp_impact')}</td>
                    <td className="center colProPink">
                      <NumberFormat
                        value={foamularNgxData.gwpImpactOverBoardFeetProduct}
                        displayType="text"
                        thousandSeparator={tNumber.thousandSeparator}
                        decimalSeparator={tNumber.decimalSeparator}
                        suffix=" kg"
                      />
                    </td>
                    <td className="center">
                      <NumberFormat
                        value={foamularData.gwpImpactOverBoardFeetProduct}
                        displayType="text"
                        tthousandSeparator={tNumber.thousandSeparator}
                        decimalSeparator={tNumber.decimalSeparator}
                        suffix=" kg"
                      />
                    </td>
                    <td className="center">
                      <NumberFormat
                        value={duPontStyrofoamData.gwpImpactOverBoardFeetProduct}
                        displayType="text"
                        thousandSeparator={tNumber.thousandSeparator}
                        decimalSeparator={tNumber.decimalSeparator}
                        suffix=" kg"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>{t('foamular_ngx.gwp_reduction_calculator_table.total_emission')}</td>
                    <td className="center colProPink">
                      <NumberFormat
                        value={foamularNgxData.totalEmissions}
                        displayType="text"
                        thousandSeparator={tNumber.thousandSeparator}
                        decimalSeparator={tNumber.decimalSeparator}
                        suffix=" kg"
                        decimalScale={2}
                      />
                    </td>
                    <td className="center">
                      <NumberFormat
                        value={foamularData.totalEmissions}
                        displayType="text"
                        thousandSeparator={tNumber.thousandSeparator}
                        decimalSeparator={tNumber.decimalSeparator}
                        suffix=" kg"
                        decimalScale={2}
                      />
                    </td>
                    <td className="center">
                      <NumberFormat
                        value={duPontStyrofoamData.totalEmissions}
                        displayType="text"
                        thousandSeparator={tNumber.thousandSeparator}
                        decimalSeparator={tNumber.decimalSeparator}
                        suffix=" kg"
                        decimalScale={2}
                      />
                    </td>
                  </tr>
                  <tr className="row-highlight">
                    <td>{t('foamular_ngx.gwp_reduction_calculator_table.additional_emissions')}</td>
                    <td className="center"><span className="sr-only">No difference</span> - </td>
                    <td className="center">
                      <NumberFormat
                        value={foamularData.additionalEmissions}
                        displayType="text"
                        thousandSeparator={tNumber.thousandSeparator}
                        decimalSeparator={tNumber.decimalSeparator}
                        suffix=" kg"
                        decimalScale={2}
                      />
                    </td>
                    <td className="center">
                      <NumberFormat
                        value={duPontStyrofoamData.additionalEmissions}
                        displayType="text"
                        thousandSeparator={tNumber.thousandSeparator}
                        decimalSeparator={tNumber.decimalSeparator}
                        suffix=" kg"
                        decimalScale={2}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ComparisonTable>
          <div>
            <p>
              <span className="miniHeading" dangerouslySetInnerHTML={{ __html: t('foamular_ngx.gwp_reduction_emission_reduction_heading')}}></span>
              <span className="bigPink" dangerouslySetInnerHTML={{ __html: t('foamular_ngx.gwp_reduction_emission_reduction_percent')}}></span>
              <span className="miniText" dangerouslySetInnerHTML={{ __html: t('foamular_ngx.gwp_reduction_emission_reduction_comparison')}}></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

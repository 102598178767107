import { jsx, css } from '@emotion/react';

export default ({ type, subtitle, actions, user }) => (
  <div css={css`
    background-color: #F8F8F8;
    position: relative;
    z-index: 9999;
  `} >
    <div css={css`
      box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.25);
      background-color: #FFF;
      width: 100%;
      height: 88px;
      padding: 24px;
    `} >
      <div css={css` display: flex; `} >
        <div css={css`
          display: block;
          width: 40px;
          height: 40px;
          margin-right: 21px;
          background-image: url(${ UI.Images.Logo });
          background-size: auto 40px;
          background-repeat: no-repeat;
        `} />
        <div>
          <div css={css`
            font-family: Oswald;
            font-size: 20px;
            font-weight: bold;
            line-height: 20px;
            text-transform: uppercase;
            color: #000;
            ${ !subtitle && css`
              margin-top: 9px;
            ` }
          `} >
            Visual { type } Builder
          </div>
          { subtitle && <div css={css`
            color: #000;
            padding-top: 2px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 220px;
          `} >
            { subtitle }
          </div> }
        </div>
        <div css={css`
          padding-left: 32px;
          display: flex;
        `} >
          { actions?.map((props, i) => <UI.Header.Action key={ `${i}-${props.text}` } { ...props } /> ) }
        </div>
        <div css={css`
          display: flex;
          align-items: center;
          margin-left: auto;
        `} >
          <UI.Header.User name={ user.name } avatar={ user.avatar } />
        </div>
      </div>
    </div>
  </div>
);

import React from "react";
import styled from "@emotion/styled";

// Imported Components
import IconMapMarker from "./icons/icon-map-marker-solid";
import MapPopup from "./oc-map-popup";
import Theme from '../../themes';

// Styled Components
const PinContainer = styled.div`
  display: inline-block;
  height: 44px;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 33px;

  &.active {
    z-index: 1;
  }

  &:after {
    color: ${props => props.color};
    content: "${props => props.label}";
    display: inline-block;
    font-family: OCRoboto;
    font-size: 18px;
    font-weight: bold;
    left: 0;
    line-height: 18px;
    pointer-events: none;
    position: absolute;
    text-align: center;
    top: 8px;
    width: 100%;
  }
`;

const Pin = styled(IconMapMarker)`
  cursor: pointer;
  display: inline-block;
  height: 100%;
  left: 0;
  overflow: visible;
  position: absolute;
  stroke: ${props => props.color};
  stroke-width: 2px;
  top: 0;
  width: 100%;
`;

const Popup = styled(MapPopup)`
  left: 50%;
  top: -35%;
  transform: translate(-50%, -100%);
  width: 347px;
`;

const MapPin = ({ active, className, label, lat, lng, location, onClick, onKeyDown, tabIndex = "0", ...props }) => {
  const brandColor = Theme.colors.brand;
  const color = active ? brandColor : "#FFFFFF";
  const fill = active ? "#FFFFFF" : brandColor;
  const classes = [className];

  if (active) classes.push("active");

  return (
    <PinContainer
      className={classes.join(" ").trim()}
      color={color}
      fill={fill}
      label={label}
      lat={lat}
      lng={lng}
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabIndex={tabIndex}
      role="button"
      aria-label={location ? location.name : label}
      {...props}
    >
      <Pin fill={fill} color={color} height="44px" width="33px" />

      {location && active && (
        <Popup location={location} />
      )}
    </PinContainer>
  );
};

export default MapPin;

import _ from 'lodash';
import {
  setParams as setParamsInUrl,
  getParams,
  subscribeToParamUpdate,
} from '../../components/helpers/params';
import { setUrlParams as setParamsInStore } from '../actions/URLSearchParamActions';

// update URL when store.urlParams is changed
const storeParamWatcher = store => {
  let currentParamsInStore;
  let previousParamsInStore;
  return () => {
    previousParamsInStore = currentParamsInStore;
    currentParamsInStore = _.get(store.getState(), 'urlParams');
    if (!_.isEqual(currentParamsInStore, previousParamsInStore)) {
      setParamsInUrl(currentParamsInStore);
    }
  };
};

export default store => {
  const unsubscribe = store.subscribe(storeParamWatcher(store));

  // update store when URL params change
  subscribeToParamUpdate(() => {
    const nextParams = getParams();
    store.dispatch(setParamsInStore(nextParams));
  });

  return unsubscribe;
};

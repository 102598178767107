export const fiberglassData = {
  "rvalue": "R30",
  "all": {
    "Bag Weight (LB)": {
      "owens": 32,
      "knauf": 32,
      "johns": 31.5,
      "cteed": 31
    }
  },
  "R30": {
    "R30 Coverage": {
      "owens": 77,
      "knauf": 73.3,
      "johns": 77.1,
      "cteed": 72.5
    },
    "R30 Inches": {
      "owens": 10.5,
      "knauf": 10.375,
      "johns": 10.8,
      "cteed": 11.5
    },
    "R30 Bags / 1000ft": {
      "owens": 13,
      "knauf": 13.6,
      "johns": 13,
      "cteed": 13.79310345
    },
    "Coverage vs L77": {
      "owens": 1,
      "knauf": 0.955882353,
      "johns": 1.001,
      "cteed": 0.9425
    },
    "SQFT Weight": {
      "owens": 0.416,
      "knauf": 0.437,
      "johns": 0.41,
      "cteed": 0.427
    }
  },
  "R38": {
    "R38 Coverage": {
      "owens": 59.9,
      "knauf": 56.2,
      "johns": 59,
      "cteed": 55.7
    },
    "R38 Inches": {
      "owens": 13.25,
      "knauf": 13,
      "johns": 13.4,
      "cteed": 14.5
    },
    "R38 Bags / 1000ft": {
      "owens": 16.7,
      "knauf": 17.8,
      "johns": 17,
      "cteed": 17.9
    },
    "Coverage vs L77": {
      "owens": 1,
      "knauf": 0.938202247,
      "johns": 0.985,
      "cteed": 0.932960894
    },
    "SQFT Weight": {
      "owens": 0.534,
      "knauf": 0.569,
      "johns": 0.53,
      "cteed": 0.556
    }
  },
  "R49": {
    "R49 Coverage": {
      "owens": 45,
      "knauf": 42.5,
      "johns": 44.6,
      "cteed": 42.6
    },
    "R49 Inches": {
      "owens": 16.75,
      "knauf": 16.375,
      "johns": 16.9,
      "cteed": 18.25
    },
    "R49 Bags / 1000ft": {
      "owens": 22.2,
      "knauf": 23.5,
      "johns": 22.4,
      "cteed": 23.4741784
    },
    "Coverage vs L77": {
      "owens": 1,
      "knauf": 0.944680851,
      "johns": 0.991,
      "cteed": 0.94572
    },
    "SQFT Weight": {
      "owens": 0.711,
      "knauf": 0.753,
      "johns": 0.7,
      "cteed": 0.727
    }
  },
  "R60": {
    "R60 Coverage": {
      "owens": 35.8,
      "knauf": 33.6,
      "johns": 35.8,
      "cteed": 34.5
    },
    "R60 Inches": {
      "owens": 20,
      "knauf": 19.75,
      "johns": 20.2,
      "cteed": 21.75
    },
    "R60 Bags / 1000ft": {
      "owens": 28,
      "knauf": 29.7,
      "johns": 27.9,
      "cteed": 28.9
    },
    "Coverage vs L77": {
      "owens": 1,
      "knauf": 0.942760943,
      "johns": 1,
      "cteed": 0.968858131
    },
    "SQFT Weight": {
      "owens": 0.895,
      "knauf": 0.952,
      "johns": 0.88,
      "cteed": 0.897
    }
  },
  "R19": {
    "R19 Coverage": {
      "owens": 125,
      "knauf": 119.3,
      "johns": 125.4,
      "cteed": 116.2
    },
    "R19 Inches": {
      "owens": 7,
      "knauf": 6.75,
      "johns": 7,
      "cteed": 7.5
    },
    "R19 Bags / 1000ft": {
      "owens": 8,
      "knauf": 8.4,
      "johns": 8,
      "cteed": 8.6
    },
    "Coverage vs L77": {
      "owens": 1,
      "knauf": 0.952380952,
      "johns": 1.003,
      "cteed": 0.930232558
    },
    "SQFT Weight": {
      "owens": 0.256,
      "knauf": 0.268,
      "johns": 0.25,
      "cteed": 0.267
    }
  }
}
export const celluloseData = {
  applegate: {
    'R-19': 57.4,
    'R-30': 31.9,
    'R-38': 24,
    'R-49': 17.9,
    'R-60': 13.7,
  },
  greenfiber: {
    'R-19': 48,
    'R-30': 28.2,
    'R-38': 21.3,
    'R-49': 15.6,
    'R-60': 12,
  },
  nuwool: {
    'R-19': 50.5,
    'R-30': 30.1,
    'R-38': 23.3,
    'R-49': 17.7,
    'R-60': 14.3,
  },
  l77: {
    'R-19': 125,
    'R-30': 77,
    'R-38': 59.9,
    'R-49': 45,
    'R-60': 35.8,
  }
}

import _ from 'lodash';
import * as Modules from '../../editing/metadata/modules';
import * as Sections from '../../editing/metadata/sections';
import * as Layouts from '../../editing/metadata/layout';
import {transformEntity} from "../../helpers/content";

export const migrateObject = (savedObject, meta, key) => {
  if (meta?.migrator) {
    const loggableObject = _.cloneDeep(savedObject)
    const migratedData = meta.migrator(key ? savedObject[key] : savedObject);
    const output = key ? _.merge({}, savedObject, { [key]: migratedData }) : migratedData;
    console.log('migrateObject', loggableObject, output);
    return output;
  }
  return savedObject;
}

const hierarchicalContentDataMigration = (contents) => {
  return transformEntity(contents, (item) => {
    if (item?.component) {
      if (item.component === 'Section') {
        // TODO - other section types
        return migrateObject(item, Sections[item.component], null);
      } else {
        return migrateObject(item, Modules[item.component], null);
      }
    }
    if (item?.type) {
      if (item.type === 'Section') {
        // TODO - other section types
        return migrateObject(item, Sections[item.type], 'data');
      } else {
        return migrateObject(item, Modules[item.type], 'data');
      }
    }
    return item;
  });
}

const migrateLayoutModules = (modules) => {
  return Object.fromEntries(Object.entries(modules||{}).map(([k, v]) => {
    return [k, migrateObject(v, Modules[k], null)];
  }));
};

const metaDataMigration = (metadata) => {
  const newMetadata = _.cloneDeep(metadata)

  if (newMetadata) {
    newMetadata.layouts = Object.fromEntries(Object.entries(newMetadata.layouts || {}).map(([k, v]) => {
        return [k, { ...migrateObject(v, Layouts[k], null), modules: migrateLayoutModules(v.modules) }];
      })
    );
  }

  return newMetadata;
}

export default function migrate_content(content) {
  content = content || {};
  content.contents = content.contents || {}

  if (content.contentType === 'Cms::Content::BusinessService') {
    // skip migrating BusinessService via old module migrations
    return content
  }

  if (content.contentType === 'Cms::Content::Page') {
    content.metadata = metaDataMigration(content.metadata);
  }

  content.contents = hierarchicalContentDataMigration(content.contents);

  return content;
};

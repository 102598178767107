import styled from '@emotion/styled';
import Theme from '../../../../themes';

const Add = styled.div`
  font-size: 14px;
  font-style: ${ ({ full }) => full ? 'italic' : 'normal' };
  text-align: center;
  padding-top: ${ ({ minimal }) => minimal ? 24 : 16 }px;

  & span:hover {
    color: ${Theme.colors.brand};
    cursor: ${ ({ full }) => full ? 'not-allowed' : 'copy' };
    user-select: none;
  }
`;

export default ({ singular='', full, empty, max, addable, minimal, filled, onClick }) => {
  addable = addable || `+ Add ${ singular }`;
  filled = filled || `Max ${ singular } Limit Reached ${ max && `(${ max })` }`;

  return <Add full={ full } empty={ empty } minimal={ minimal }>
    <span onClick={ onClick ? () => onClick(full) : null }>
      { full ? filled : addable }
    </span>
  </Add>
};

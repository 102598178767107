import { get } from 'lodash';

const retrieveImage = (element) => {
  return get(
    element,
    [
      'metadata',
      'settings',
      'images',
      'images',
      get(element, 'metadata.settings.images.list_item_image')
    ]
  )
}

export default retrieveImage

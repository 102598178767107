import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';

const OptionList = styled.ul`
  list-style: none;
  columns: 125px 3;
  padding: 10px 10px;
  font-size: 13px;
  margin-bottom: 0;
  background: white;

  @media (min-width: 768px) {
    margin-top: 0;
    font-size: 16px;
  }

  li {
    padding: 10px 0;
    -webkit-column-break-inside: avoid;
    column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;

    @media (min-width: 1050px) {
      padding: 5px 0;
    }
  }

  :global(.checkbox) {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

function UnorderedOptions({ options, isSelected, toggleOption }) {
  return (
    <OptionList>
      {options.map(option => (
        <li key={option.id}>
          <input
            type="checkbox"
            id={option.id}
            checked={isSelected(option.id)}
            onChange={toggleOption}
          />
          <span
            style={{ fontWeight: isSelected(option.id) ? 'bold' : 'normal' }}
          >
            {option.name}
          </span>
        </li>
      ))}
    </OptionList>
  );
}

UnorderedOptions.propTypes = {
  options: PropTypes.array.isRequired,
  isSelected: PropTypes.func.isRequired,
  toggleOption: PropTypes.func.isRequired,
};

export default UnorderedOptions;

import React from 'react';
import { jsx, css } from '@emotion/react';
import RichText from '../../../OC/oc-rich-text';
import Text from '../../../OC/oc-text';
import LabelWrapper from '../../LabelWrapper';
import StateSelect from './state_select';
import { getRequiredSuffix } from "../../../../helpers/form_libs";

const addressField = css`
  input, select {
    margin-bottom: 7px;
  }

  .twoFieldsRow {
    display: flex;
    flex-direction: row;

    @media (max-width: 700px) {
      flex-direction: column;
    }

    .fieldItem {
      width: 50%;

      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
`

const errorAlert = css`
  color: #940420;
  font-size: 12px;
  font-weight: bold;
`

export default function UsAddressField({
  formData = {},
  onChange,
  schema = {},
  uiSchema = {},
  formContext,
  errorSchema,
}) {
  const optionalFocused = formContext?.optionalFocused;

  const ErrorDisplay = ({field, schemaProp, errorSchema}) =>
    ((schema.required || []).includes(field) && errorSchema) ?
      <p aria-describedby={field} aria-errormessage css={errorAlert} role="alert">
        {schemaProp.title?.trim?.()} is required
      </p> : null

  return (
    <div css={addressField}>
      <div className="form-group field field-object">
      <LabelWrapper htmlFor="address-line1">
        <Text content={ schema.properties.addressLine1.title?.trim?.() } />{getRequiredSuffix(optionalFocused, (schema.required || []).includes('addressLine1'), schema.properties.addressLine1.type)}
      </LabelWrapper>
      <input
        id="address-line1"
        className="form-control"
        type="text"
        onChange={(e) => onChange({...formData, addressLine1: e.target.value})}
        name={schema.properties.addressLine1.title}
        value={formData.addressLine1 || ''}
        required={(schema.required || []).includes('addressLine1')}
      />
      { uiSchema?.addressLine1?.['ui:help'] && <RichText content={ uiSchema?.addressLine1?.['ui:help'] } singular /> }
      <ErrorDisplay field='addressLine1' schemaProp={schema.properties.addressLine1} errorSchema={errorSchema?.addressLine1} />
      </div>
      {/* Making address line 2 optional */}
      { schema.properties?.addressLine2 &&
        <div className="form-group field field-object">
          <LabelWrapper htmlFor="address-line2">
            <Text content={ schema.properties.addressLine2.title?.trim?.() } />{getRequiredSuffix(optionalFocused, (schema.required || []).includes('addressLine2'), schema.properties.addressLine2.type)}
          </LabelWrapper>
          <input
            id="address-line2"
            className="form-control"
            type="text"
            name={schema.properties.addressLine2.title}
            onChange={(e) => onChange({...formData, addressLine2: e.target.value})}
            value={formData.addressLine2 || ''}
            required={(schema.required || []).includes('addressLine2')}
          />
          { uiSchema?.addressLine2?.['ui:help'] && <RichText content={ uiSchema?.addressLine2?.['ui:help'] } singular /> }
          <ErrorDisplay field='addressLine2' schemaProp={schema.properties.addressLine2} errorSchema={errorSchema?.addressLine2} />
        </div>
      }
      <div className="form-group field field-object">
        <div className="twoFieldsRow">
          <div className='fieldItem' style={{ marginRight: '20px'}}>
            <LabelWrapper htmlFor="city">
              <Text content={ schema.properties.city.title?.trim?.() } />{getRequiredSuffix(optionalFocused, (schema.required || []).includes('city'), schema.properties.city.type)}
            </LabelWrapper>
            <input
              name={schema.properties.city.title}
              id="city"
              className="form-control"
              type="text"
              onChange={(e) => onChange({...formData, city: e.target.value})}
              value={formData.city || ''}
              required={(schema.required || []).includes('city')}
            />
            { uiSchema?.city?.['ui:help'] && <RichText content={ uiSchema?.city?.['ui:help'] } singular /> }
            <ErrorDisplay field='city' schemaProp={schema.properties.city} errorSchema={errorSchema?.city} />
          </div>
          <div className='fieldItem'>
            <LabelWrapper htmlFor="state">
              <Text content={ schema.properties.state.title?.trim?.() } />{getRequiredSuffix(optionalFocused, (schema.required || []).includes('state'), schema.properties.state.type)}
            </LabelWrapper>
            <StateSelect id="state"
                         onChange={ (newValue) => onChange({...formData, state: newValue}) }
                         value={formData.state || ''} />
            { uiSchema?.state?.['ui:help'] && <RichText content={ uiSchema?.state?.['ui:help'] } singular /> }
            <ErrorDisplay field='state' schemaProp={schema.properties.state} errorSchema={errorSchema?.state} />
          </div>
        </div>
      </div>
      <div className="twoFieldsRow">
        <span className="zip-code fieldItem" style={{ marginRight: '20px'}}>
          <LabelWrapper htmlFor="zip">
            <Text content={ schema.properties.zip.title?.trim?.() } />{getRequiredSuffix(optionalFocused, (schema.required || []).includes('zip'), schema.properties.zip.type)}
          </LabelWrapper>
          <input
            name={schema.properties.zip.title}
            id="zip"
            className="form-control"
            type="text"
            onChange={(e) => onChange({...formData, zip: e.target.value})}
            value={formData.zip || ''}
            required={(schema.required || []).includes('zip')}
          />
          { uiSchema?.zip?.['ui:help'] && <RichText content={ uiSchema?.zip?.['ui:help'] } singular /> }
          <ErrorDisplay field='zip' schemaProp={schema.properties.zip} errorSchema={errorSchema?.zip} />
        </span>
      </div>
    </div>
  );
}

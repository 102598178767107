import _ from 'lodash';
import { createContext, useEffect, useState } from 'react';

import cmsApi from '../../../../cms_api';

export const BadgesContext = createContext();

export const BadgesProvider = ({ path, name }) => ({ children }) => {
  const [response, setResponse] = useState(null);
  useEffect(() => {
    cmsApi
      .get_single_contents({
        type: 'Attribute',
        language: Board.build.language,
        path,
        name,
      })
      .then(data => {
        setResponse(data);
      });
  }, [path, name]);

  const value = _.merge({}, response, { path, name });

  return (
    <BadgesContext.Provider value={value}>{children}</BadgesContext.Provider>
  );
};

import { ui } from '@owenscorning/pcb.alpha';
import { useEffect, useState, useMemo } from 'react';
import { connectLocation } from '../../location/LocationConnectors';
import styled from "@emotion/styled";
import Theme from '../../../themes';
import _ from 'lodash';

// Components
import { H4 } from '../../ComponentLibrary/text-elements/oc-h';
import ContentGroupDropdown from '../../ComponentLibrary/oc-content-group-dropdown';
import InputList from '../../ComponentLibrary/input-elements/oc-input-list';
import Checkbox from '../../ComponentLibrary/input-elements/oc-checkbox';
import Text from '../../OC/oc-text';
import InfoIcon from '../../OC/oc-info-icon';
import Modal from '../../Modal';
import Reusable from '../../OC/PageBuilder/Reusable';
import Picture from '../../OC/oc-picture';
import { isOptionSelected } from '../../helpers/oc-filter-list-helper';
import Conditional from "../../Conditional";

const nameFormatter = (name) => name.replaceAll('_', ' ').replace(/(^|\s)\S/g, function(letter) { return letter.toUpperCase() });

const CollapsibleLink = styled.a`
  color: #333333;
  cursor: pointer;
  display: block;
  font-size: 15px;
  margin-top: 12px;

  &:hover {
    color: ${Theme.colors.brand};
  }

  i {
    margin-right: 8px;
  }
`;

const GroupHeader = styled(H4)`
  color: black;
  font-size: 16px!important;
  line-height: 18px;

  @media(min-width: 1200px) {
    font-size: 18px!important;
  }

  &:not(:first-child) {
    margin-top: 24px;
  }
`;

const FlexSpan = styled.span`
  display: flex;
`

const limitFn = (options, displayLimit, collapsed)=>{
  if (!collapsed || !_.isNumber(displayLimit)) return options
  return options?.filter((option, index)=> !!(displayLimit && index < displayLimit && option))
}

const CheckboxFilter = ({ options, filterCounts, defaultValue, onChange, filterAttribute, title, displayLimit, expandText, collapseText, loading, showCount=true, onComponentChange, groupOptionsBy, translationKey, t }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [modalState, setModalState] = useState({});
  if (showCount) {
    options = options.filter(o => filterCounts?.[o.value] > 0);
  }

  useEffect(() => {
    setModalState({});
  }, [options.map(o => o.value).join(':')])

  if (options.length === 0) { return null; }

  const handleClick = (e) => {
    e.preventDefault();
    setCollapsed(!collapsed);
  }
  const onInfo = (option) => {
    if (typeof(Board) !== 'undefined') {
      const reusable = option.details;
      return () => Board.modal.open({
        ...(reusable.title && { title: reusable.title || option.displayName }),
        body: ui`Modules/Page/Reusable|view`({ value: reusable.reusable, gutterlessChild: true })
      })
    } else {
      setModalState({ ...modalState, [option.value]: true })
    }
  }

  const limitedOptions = (!collapsed || !_.isNumber(displayLimit))? options : limitFn(options, displayLimit, collapsed)

  const translatedDisplayName = (option) => {
    return option.translationKey ? t(option.translationKey) : option.displayName;
  };

  const displayTitle = translationKey ? t(translationKey) : title;

  const CheckBoxOption = (option, key) => {
    const CustomDisplayComponent = option.displayComponent;

    const isChecked = isOptionSelected(option.value, defaultValue);

    return(
      <>
        <Checkbox
          data-track={isChecked ? "remove-filter" : "filter"}
          data-track-filter-name={nameFormatter(filterAttribute)}
          data-track-filter-option={option.displayName}
          checked={isChecked}
          disabled={filterCounts[option.value] === 0 && !isChecked || loading} // disabled if count = 0 and not checked
          key={key}
          onClick={() => { onChange(filterAttribute, option.value) }}
          onChange={(e) =>{ onComponentChange && onComponentChange(e, filterAttribute, option.value) }}
        >
          <FlexSpan style={{width:'100%', justifyContent: 'space-between'}}>
            <FlexSpan style={{width:'90%'}}>
              {
                option.icon && option.icon?.file &&
                <span style={{ marginRight: '10px', width: '20px', height: '20px' }} aria-hidden>
                  <Picture source={ option.icon?.file } transform={{ width: 20 }} alt={ option.icon?.alt || option.displayName } />
                </span>
              }
              <div>
                {option.displayComponent ?  <CustomDisplayComponent /> : <Text content={translatedDisplayName(option)} />}
                {showCount ? <span style={{ marginLeft: '4px' }}>({filterCounts[option.value]})</span> : null}
              </div>
            </FlexSpan>
            <span style={{ display: 'flex'}}>
              {option.showInfo && <InfoIcon clickHandler={option.infoIconProps.onClick} enabled={true}/>}
            </span>
          </FlexSpan>
        </Checkbox>
        {
          option.details && option.details.reusable && option.details.reusable.reference &&
          <>
            <InfoIcon clickHandler={onInfo(option)} enabled={!(filterCounts[option.value] === 0 && !(defaultValue || []).includes(option.value) || loading)}/>
            {
              typeof(Board) === 'undefined' &&
                <Modal open={ modalState[option.value] } onClose={ () => setModalState({ ...modalState, [option.value]: false }) }>
                  <h3 className="h4 heading">{ option.details?.title }</h3>
                  <Reusable gutterlessChild { ...option.details?.reusable } />
                </Modal>
            }
          </>
        }
      </>
    );
  };

  const renderCheckboxList = (limitedOptions) => {
    if (groupOptionsBy) {
      const groupedLimitedOptions = _.groupBy(limitedOptions, groupOptionsBy);
      return(Object.keys(groupedLimitedOptions).map((groupOption) => (
        <>
          <GroupHeader font="body">{groupOption}</GroupHeader>
          <InputList>
            {groupedLimitedOptions[groupOption].map((option, index)  => (
              CheckBoxOption(option, `${groupOption}-${index}`)
            ))}
          </InputList>
        </>
      )));
    } else {
      return(
        <InputList>
          {limitedOptions.map((option, index) => CheckBoxOption(option, index))}
        </InputList>
      );
    }
  }
  return(<>
    <ContentGroupDropdown label={displayTitle}>
      {renderCheckboxList(limitedOptions)}
      { _.isNumber(displayLimit) && options?.length > displayLimit &&
      (collapsed
          ? (<CollapsibleLink className="expand-filters" onClick={handleClick}><i className="fa fa-plus-circle"/><span
            className="filters-text">{expandText}</span></CollapsibleLink>)
          : (<CollapsibleLink className="expand-filters" onClick={handleClick}><i className="fa fa-minus-circle"/><span
            className="filters-text">{collapseText}</span></CollapsibleLink>))
      }
    </ContentGroupDropdown>
  </>)
}

CheckboxFilter.filterValueType = 'multiple';
CheckboxFilter.getChipInfo = (filter, filterValues) => {
  if (Array.isArray(filterValues)){
    return filterValues.map(filterValue => ({ ...filter.options.filter(option => option.value === filterValue)[0] }))
  }
}

export default connectLocation(CheckboxFilter);

import { ui, select, transform, when, s } from '@owenscorning/pcb.alpha';
import { css } from '@emotion/react';
import _ from 'lodash';
import OCColorPairing from '../../../Roofing/DesignAndInspire/ColorPairing';
import Content from './Content';

const colorFilter = {
  redAndPink: 'Red & Pink',
  orange: 'Orange',
  yellow: 'Yellow',
  green: 'Green',
  blue: 'Blue',
  purple: 'Purple',
  brown: 'Brown',
  neutralAndGray: 'Neutral & Gray',
  blackAndWhite: 'Black & White',
}

const ColorPairing = Content(
  'ColorPairing',
  (UI) => ({
    version: '0.2',
    displayName: 'Color Pairing',
    paths: ['roofing/design-inspire/shingles-colors-style-boards'],
    read: ({contents, metadata}) => {
      if (!_.isEmpty(contents) && !Array.isArray(contents)) {
        return { contents, metadata };
      }
      return {
        contents: contents ? contents[0] : {},
        metadata
      };
    },
    write: ({contents, metadata}) => {
      return {
        contents: [contents],
        metadata
      };
    },
    sidebar: {
      builder: ui`Form`.of({
        subtitle: ui`Text`({ label: 'Pre Head' }),
        colorName: ui`Text`({ label: 'Color Name' }),
        featured: ui`Switch`({ label: 'Featured?' }),
        shingleColor: ui`Shingle/Color`,
        otherShingleColors: ui`List`.of(
          ui`Shingle/Color`
        )({
          label: 'Other Shingles that can be used with this Style Board',
          singular: 'Other Shingle'
        }),

        // this is unusual to do this but there are graphical elements that disappear from the sizes
        // so it's not just an alternate crop/size
        heroImage: ui`Form`.of({
          mobile: ui`Image`({ label: 'Mobile' }),
          tablet: ui`Image`({ label: 'Tablet' }),
          desktop: ui`Image`({ label: 'Desktop' }),
        })({ label: 'Hero Image' }),
        description: ui`Text/Rich`({ label: 'Description' }),
        colorPalette: ui`Form`.of({
          primary: ui`Color`({ label: 'Primary', default: '#d40f7d' }),
          secondary: ui`Form`.of({
            one: {
              hex: ui`Color`({ default: '#d40f7d'}),
              name: ui`Text`({ label: 'Name' })
            },
            two: {
              hex: ui`Color`({ default: '#d40f7d'}),
              name: ui`Text`({ label: 'Name' })
            },
            three: {
              hex: ui`Color`({ default: '#d40f7d'}),
              name: ui`Text`({ label: 'Name' })
            },
          })({ label: 'Secondary' })
        })({ label: 'Color Palette' }),
        exampleImg: ui`Image`({ label: 'Example Image' }),

      }),
      settings: {
        meta: ui`Meta`({ label: 'Color Pairing Metadata' }),
        filtering: ui`Form`.of({
          mainHouseColor: ui`Choices`.of(colorFilter)({
            label: 'Main House Color',
            mode: ui`Choices/Mode/Dropdown`,
            multiple: true
          }),
          accentColor: ui`Choices`.of(colorFilter)({
            label: 'Accent Color',
            mode: ui`Choices/Mode/Dropdown`,
            multiple: true
          })
        })({
          label: 'Filtering'
        })
      }
    },
    view: ColorPairing.Renderer(UI),
  })
)

ColorPairing.Renderer = (UI, parameters={}) => ({
  contents: ({ value }) => (
    <Content.PreventClicks>
      <OCColorPairing { ...value } />
    </Content.PreventClicks>
  )
});

export default ColorPairing;

import { jsx, css } from '@emotion/react';

export default ({ onClick, styling, children }) => (
  <div
    css={[
      css`
        width: 100%;
        padding: 7px 11px;
        background-color: #FFF;
        cursor: pointer;
        user-select: none;
        font-size: 16px;
        white-space: nowrap;
        text-align: left !important;

        i {
          width: 22px;
          text-align: center;
        }

        &:hover {
          background-color: #F1F1F1;
        }
      `,
      ..._.castArray(styling)
    ]}
    onClick={ () => onClick?.() }
  >
    { children }
  </div>
);

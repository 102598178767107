import _ from "lodash";
import React, {Fragment} from "react";

const GroupedResult = ({result, resultComponent, grouping, showLogo=false}) => {
  const groups = _.groupBy(result.data, grouping?.key);
  const GroupComponent = grouping?.component;
  const groupSort = grouping?.sort || ((a,b) => (b.toString().localeCompare(a.toString())));
  return(
    <>
      {
        Object.entries(groups).sort(([groupKey1, groupValue1], [groupKey2, groupValue2]) => groupSort(groupKey1, groupKey2)).map(([groupKey, groupValue]) => {
          return (
            <GroupComponent key={ groupKey } groupKey={ groupKey} title={groupValue.length > 1 ? grouping.title + 's' : grouping.title}>
              {groupValue.map((item, i) => (
                <Fragment key={item.list_number}>
                  {item.showLogo = showLogo}
                  {resultComponent(item)}
                </Fragment>
              ))}
            </GroupComponent>
          );
        })
      }
    </>
  );
}

export default GroupedResult;

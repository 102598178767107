import { ClassNames } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import OptionList from './OptionList';
import SpecificationInput from './SpecificationInput';
import Accordion from '../../../ComponentLibrary/oc-accordion';

const CategoryDiv = styled.div`
  border: 1px solid #d7d7d7;
  border-bottom: 0;
  background: #eeeeee;

  &:last-child {
    border-bottom: 1px solid #d7d7d7;
  }
`;

const Content = styled.div`
  background: #eeeeee;
  margin: -16px;
  padding: 16px;
`;

const Description = styled.div`
  padding-bottom: 20px;
  font-size: 14px;
`;

const FeatureList = styled.ul`
  columns: 250px 2;
  padding-left: 12px;

  li {
    margin-left: 1em;
  }
`;

export default function Category({
  categoryId,
  name,
  description,
  features,
  options,
  selectId,
  removeId,
  isSelected,
  tabularOptions,
  updateSpecifications,
  specification,
  allowSpecifications,
}) {
  const [open, setOpen] = useState(false);

  return (
    <CategoryDiv>
      <ClassNames>
        {({ css }) => (
          <Accordion
            label={name}
            className={css({
              cursor: 'pointer',
              borderBottom: 0,
              background: open ? '#d7d7d7' : '#eeeeee',
            })}
            labelClassName={css({ fontSize: '20px', fontWeight: 'bold' })}
            onToggle={() => setOpen(!open)}
          >
            <Content>
              {description ? <Description>{description}</Description> : null}
              {features.length > 0 ? (
                <FeatureList>
                  {features.map(feature => (
                    <li key={feature}>{feature}</li>
                  ))}
                </FeatureList>
              ) : null}
              {allowSpecifications ? (
                <SpecificationInput
                  categoryId={categoryId}
                  updateSpecifications={updateSpecifications}
                  specification={specification}
                />
              ) : null}
              <OptionList
                options={options}
                tabularOptions={tabularOptions}
                selectId={selectId}
                removeId={removeId}
                isSelected={isSelected}
              />
            </Content>
          </Accordion>
        )}
      </ClassNames>
    </CategoryDiv>
  );
}

Category.propTypes = {
  categoryId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectId: PropTypes.func.isRequired,
  removeId: PropTypes.func.isRequired,
  isSelected: PropTypes.func.isRequired,
  tabularOptions: PropTypes.bool,
  updateSpecifications: PropTypes.func.isRequired,
  specification: PropTypes.string,
  allowSpecifications: PropTypes.bool,
};

Category.defaultProps = {
  description: '',
  tabularOptions: false,
  specification: '',
  allowSpecifications: false,
};

import { ui, select, transform, when, s } from '@owenscorning/pcb.alpha';
import Product from './Product';

const Geosynthetics = Product(
  'Product::Geosynthetics',
  {},
  {},
  {},
  {
    version: '0.1',
    displayName: 'Geosynthetics PDP',
    paths: ['geosynthetics/product'],
  }
)

export default Geosynthetics;

import IconBase from './icon-base';

const IconRevitFile = ({ className, fill="#000000", height="16px", hoverColor="#D40F7D", width="16px" }) => (
  <IconBase className={className} fill={fill} height={height} hoverColor={hoverColor} viewBox="0 0 16 16" width={width}>
    <g clip-path="url(#clip0_1118_960)">
      <path d="M13.5594 3.05859L10.9375 0.436719C10.6562 0.155469 10.275 -0.00390625 9.87813 -0.00390625H3.5C2.67188 -0.00078125 2 0.671094 2 1.49922V14.4992C2 15.3273 2.67188 15.9992 3.5 15.9992H12.5C13.3281 15.9992 14 15.3273 14 14.4992V4.12109C14 3.72422 13.8406 3.33984 13.5594 3.05859ZM10 1.01484C10.0875 1.03672 10.1656 1.08047 10.2312 1.14609L12.8531 3.76797C12.9187 3.83359 12.9625 3.91172 12.9844 3.99922H10V1.01484ZM13 14.4992C13 14.7742 12.775 14.9992 12.5 14.9992H3.5C3.225 14.9992 3 14.7742 3 14.4992V1.49922C3 1.22422 3.225 0.999219 3.5 0.999219H9V4.24922C9 4.66484 9.33437 4.99922 9.75 4.99922H13V14.4992Z" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5 6V13.7746H6.84615V11.0306C6.84615 11.0306 7.5 11.0306 7.67692 11.0306C8.54955 12.5855 8.78462 14.2973 11 13.9575V12.5855C9.24615 12.0367 9.52308 10.5733 9.52308 10.5733C9.52308 10.5733 10.8154 9.75009 10.8154 8.10371C10.8154 6.45733 8.96923 6 8.32308 6C7.67692 6 5 6 5 6ZM6.93846 9.65862V7.37198C6.93846 7.37198 8.78462 7.09759 8.78462 8.46957C8.78462 9.84155 6.93846 9.65862 6.93846 9.65862Z" />
    </g>
  </IconBase>
);

export default IconRevitFile;

import _ from 'lodash';

const Prepare = (value) => {
  if (_.isPlainObject(value) || _.isArray(value)) return _.reduce(value, (result, property, key) => {
    if (property?.__hidden) return result;
    if (property?.__visibility && PB_VISIBILITY_GROUPS && PB_VISIBILITY_GROUPS !== true) {
      if (!_.intersection(PB_VISIBILITY_GROUPS, property.__visibility?.show_for || []).length) return result;
      if (_.intersection(PB_VISIBILITY_GROUPS, property.__visibility?.except_for || []).length) return result;
    }
    result[key] = Prepare(property);
    return result;
  }, _.isArray(value) ? [] : {});
  return value;
};

export default value => Prepare(value)


import styled from '@emotion/styled';
import {useState} from 'react';
import NumberFormat from "react-number-format";

// Components
import { connectLocation } from "../../location/LocationConnectors";
import Table from '../../ComponentLibrary/oc-table';
import Grid from '../../ComponentLibrary/oc-grid';
import InfoIcon from '../../ComponentLibrary/oc-info-icon';

const SavingsContainer = styled.div`
	margin-bottom: 16px;
`;

const SavingsInfo = styled.span`
	font-size: 14px;

	.savings {
		display: block;
		font-weight: 700;

		&.large {
			color: #d40f7d;
			font-size: 20px;
			line-height: 22px;

			@media(min-width: 1200px) {
				font-size: 30px;
				line-height: 32px;
			}
		}
	}

	@media(min-width: 1200px) {
			font-size: 16px;
	}
`;

const Info = styled(InfoIcon)`
	position: absolute;
	right: 8px;
	top: 50%;
	transform: translateY(-50%);
`;

const CalculatorResults = ({
	slabSize, 
	rebarSpacing, 
	sticksRequired,
	steelWeight,
	pinkBarWeight,
	sticksPerTripSteel,
	sticksPerTripPinkBar,
	weightPerTripSteel,
	weightPerTripPinkBar,
	truckToSiteTripSteel,
	truckToSiteTripPinkbar,
	timeToPlaceRebarSteel,
	timeToPlaceRebarPinkbarMin,
	timeToPlaceRebarPinkbarMax,
	estimatedLabourCostSteel,
	estimatedLabourCostPinkbarMin,
	estimatedLabourCostPinkbarMax,
	estimatedMaterialCostSteel,
	estimatedMaterialCostPinkbar,
	estimatedTotalCostSteel,
	estimatedTotalCostPinkbarMin,
	estimatedTotalCostPinkbarMax,
	estimatedLabourSavingMin,
	estimatedLabourSavingMax,
	estimatedMaterialSaving,
	estimatedTotalSavingMin,
	estimatedTotalSavingMax
}) => (
	<>
		<SavingsContainer>
			<Grid selectedSize={3} selectedSizeTablet={2}>
				<SavingsInfo>
					Estimated Labor Savings:
					<span className="savings">{(estimatedLabourSavingMin && estimatedLabourSavingMax) ? <><NumberFormat decimalScale={0} prefix="$" thousandSeparator={true} displayType="text" value={estimatedLabourSavingMax}/> - <NumberFormat decimalScale={0} prefix="$" thousandSeparator={true} displayType="text" value={estimatedLabourSavingMin}/></> : '-'}</span>
				</SavingsInfo>

				<SavingsInfo>
					Estimated Material Savings:
					<span className="savings">{estimatedMaterialSaving ? <NumberFormat decimalScale={0} prefix="$" thousandSeparator={true} displayType="text" value={estimatedMaterialSaving}/> : '-'}</span>
				</SavingsInfo>

				<SavingsInfo>
					Estimated Total Savings:
					<span className="savings large">{(estimatedTotalSavingMin && estimatedTotalSavingMax) ? <><NumberFormat decimalScale={0} prefix="$" thousandSeparator={true} displayType="text" value={estimatedTotalSavingMax}/> - <NumberFormat decimalScale={0} prefix="$" thousandSeparator={true} displayType="text" value={estimatedTotalSavingMin}/></> : '-'}</span>
				</SavingsInfo>

				<SavingsInfo>
					<a href="/composites/product/flatworkfiberglassrebar/where-to-buy" data-track="find-a-professional" data-track-professional="composites-sales-representative" className="oc-cta-button">Where to Buy</a>
				</SavingsInfo>
				
			</Grid>
		</SavingsContainer>

    <Table striped>
      <thead>
      <tr>
        <th scope="col" className="condensed"></th>
        <th scope="col" className="condensed">#4 Steel</th>
        <th scope="col" className="pink-text condensed">#3 PINKBAR®</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Slab Dimensions (ft)</td>
        <td className="slab-dimensions estimate">{slabSize && slabSize}</td>
        <td className="slab-dimensions estimate">{slabSize && slabSize}</td>
      </tr>
      <tr>
        <td>Rebar Spacing (in)</td>
        <td className="rebar-spacing estimate">{rebarSpacing && rebarSpacing}</td>
        <td className="rebar-spacing estimate">{rebarSpacing && rebarSpacing}</td>
      </tr>
      <tr>
        <td># of 20' Sticks Required</td>
        <td className="sticks-required estimate">{sticksRequired && <NumberFormat decimalScale={0} thousandSeparator={true} displayType="text" value={sticksRequired}/>}</td>
        <td className="sticks-required estimate">{sticksRequired && <NumberFormat decimalScale={0} thousandSeparator={true} displayType="text" value={sticksRequired}/>}</td>
      </tr>
      <tr>
        <td>Weight (lbs)</td>
        <td className="steel-weight estimate">{steelWeight && <NumberFormat decimalScale={1} thousandSeparator={true} displayType="text" value={steelWeight}/>}</td>
        <td className="pinkbar-weight pink-text estimate"><strong>{pinkBarWeight && <>{<NumberFormat decimalScale={1} thousandSeparator={true} displayType="text" value={pinkBarWeight}/>} <Info tooltip tooltipPosition="left" tooltipText="7x less weight on the project!" /></>}</strong></td>
      </tr>
      <tr>
        <td># of Sticks Carried per Trip</td>
        <td className="sticks-carried-per-trip-steel estimate">{sticksPerTripSteel && sticksPerTripSteel}</td>
        <td className="sticks-carried-per-trip-pinkbar pink-text estimate"><strong>{sticksPerTripPinkBar && <>{sticksPerTripPinkBar} <Info tooltip tooltipPosition="left" tooltipText="Carry 4x the number of bars and still lighter!" /></>}</strong></td>
      </tr>
      <tr>
        <td>Weight per Trip (lbs)</td>
        <td className="weight-per-trip-steel estimate">{weightPerTripSteel && <NumberFormat decimalScale={1} thousandSeparator={true} displayType="text" value={weightPerTripSteel}/>}</td>
        <td className="weight-per-trip-pinkbar pink-text estimate"><strong>{weightPerTripPinkBar && <NumberFormat decimalScale={1} thousandSeparator={true} displayType="text" value={weightPerTripPinkBar}/>}</strong></td>
      </tr>
      <tr>
        <td># of Trips from Truck to Site</td>
        <td className="truck-to-site-trip-steel estimate">{truckToSiteTripSteel && <NumberFormat decimalScale={0} thousandSeparator={true} displayType="text" value={truckToSiteTripSteel}/>}</td>
        <td className="truck-to-site-trip-pinkbar pink-text estimate"><strong>{truckToSiteTripPinkbar && <NumberFormat decimalScale={0} thousandSeparator={true} displayType="text" value={truckToSiteTripPinkbar}/>}</strong></td>
      </tr>
      <tr>
        <td>Time To Place Rebar (min)</td>
        <td className="time-to-place-rebar-steel estimate">{timeToPlaceRebarSteel && <NumberFormat decimalScale={1} thousandSeparator={true} displayType="text" value={timeToPlaceRebarSteel}/>}</td>
        <td className="time-to-place-rebar-pinkbar pink-text estimate"><strong>{(timeToPlaceRebarPinkbarMin && timeToPlaceRebarPinkbarMax) && <><NumberFormat decimalScale={1} thousandSeparator={true} displayType="text" value={timeToPlaceRebarPinkbarMin}/> - <NumberFormat decimalScale={1} thousandSeparator={true} displayType="text" value={timeToPlaceRebarPinkbarMax}/> <Info tooltip tooltipPosition="left" tooltipText="Save 50% or more on time!" /></>}</strong></td>
      </tr>
      <tr>
        <td>Estimated Labor Costs</td>
        <td className="estimated-labour-cost-steel estimate">{estimatedLabourCostSteel && <NumberFormat decimalScale={0} prefix="$" thousandSeparator={true} displayType="text" value={estimatedLabourCostSteel}/>}</td>
        <td className="estimated-labour-cost-pinkbar pink-text estimate"><strong>{(estimatedLabourCostPinkbarMin && estimatedLabourCostPinkbarMax) && <><NumberFormat decimalScale={0} prefix="$" thousandSeparator={true} displayType="text" value={estimatedLabourCostPinkbarMin}/> - <NumberFormat decimalScale={0} prefix="$" thousandSeparator={true} displayType="text" value={estimatedLabourCostPinkbarMax}/></>}</strong></td>
      </tr>
      <tr>
        <td>Estimated Material Costs</td>
        <td className="estimated-material-cost-steel estimate">{estimatedMaterialCostSteel && <NumberFormat decimalScale={0} prefix="$" thousandSeparator={true} displayType="text" value={estimatedMaterialCostSteel}/>}</td>
        <td className="estimated-material-cost-pinkbar pink-text estimate"><strong>{estimatedMaterialCostPinkbar && <><NumberFormat decimalScale={0} prefix="$" thousandSeparator={true} displayType="text" value={estimatedMaterialCostPinkbar}/> <Info tooltip tooltipPosition="left" tooltipText="Up Front Material Savings!" /></>}</strong></td>
      </tr>
      <tr>
        <td>Estimated Total Costs</td>
        <td className="estimated-total-cost-steel estimate">{estimatedTotalCostSteel && <NumberFormat decimalScale={0} prefix="$" thousandSeparator={true} displayType="text" value={estimatedTotalCostSteel}/>}</td>
        <td className="estimated-total-cost-pinkbar pink-text estimate"><strong>{(estimatedTotalCostPinkbarMin && estimatedTotalCostPinkbarMax) && <><NumberFormat decimalScale={0} prefix="$" thousandSeparator={true} displayType="text" value={estimatedTotalCostPinkbarMin}/> - <NumberFormat decimalScale={0} prefix="$" thousandSeparator={true} displayType="text" value={estimatedTotalCostPinkbarMax}/></>}</strong></td>
      </tr>
      </tbody>
    </Table>
	</>
);

export default CalculatorResults;
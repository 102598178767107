import RoofingWidgets from '../../ComponentLibrary/layout/RoofingWidgets/oc-roofing-widgets';

const CMSRoofingWidgets = ({ arrangement, ...props }) => (
  <>
    <RoofingWidgets
      widgetOption={arrangement?.widgetOption}
      contentOrder={arrangement?.contentOrder}
      columns={arrangement?.columns}
      {...props}
    >
    </RoofingWidgets>
  </>
);

export default CMSRoofingWidgets;

import { ui, select } from '@owenscorning/pcb.alpha';
import _ from 'lodash';

// TODO: remove when async choices with param is a thing
const getShingleColorData = _.memoize(() => {
  if (typeof(window) !== 'undefined' && typeof(window.PB_SHINGLE_COLORS) !== 'undefined' && window.PB_SHINGLE_COLORS) {
    return window.PB_SHINGLE_COLORS;
  }
  return [];
});

export default ui`Form`.of({
  line: ui`Shingle/Line`,
  color: ui`Form`.of({
    // TODO: don't expose "__ref" we need a ui`Reference` which does this and clears __data if changed to allow better caching
    __ref: [
      ui`Choices`.of([]),
      {
        contents: select`../../line`.from(getShingleColorData()),
        mode: ui`Choices/Mode/Dropdown`
      }
    ]
  })({ label: 'Shingle Color' })
});

import styled from '@emotion/styled';
import { useState } from 'react';

// Components
import Form from '../../ComponentLibrary/forms/oc-form';
import Fieldset from '../../ComponentLibrary/input-elements/oc-fieldset';
import TextInput from '../../ComponentLibrary/input-elements/oc-text-input';
import RadioButton from '../../ComponentLibrary/input-elements/oc-radio-button';
import FormButton from '../../ComponentLibrary/input-elements/oc-form-button';
import SelectDropdown from '../../ComponentLibrary/input-elements/oc-select-dropdown';
import {connectLocation} from "../../location/LocationConnectors";
import RValModal from './AtticatRValModal';
import InfoIcon from '../../OC/oc-info-icon';

const InputContainer = styled.div`
	display: flex;
	margin: 0!important;

	span {
		margin: 58px 8px 0;
	}
`;

const Legend = styled.legend`
  color: black;
  font-weight: 700;
  margin-bottom: 8px;
  margin-bottom: ${props => props.areaInputSeperator ? '25px' : '8px'};
  width: 100%;
`;

const HelperText = styled.span`
  color: #585858;
  font-size: 12px;
  font-weight: normal;
  margin-left: 8px;
  display: inline;
`;

const ErrorContainer = styled.span`
	i {
    color: #940420;
    display: block;
    font-size: 14px;
 		margin: 20px 0 0px!important;
	}
`;

const ColoredLine = styled.hr`
  border-top: solid black;
  margin-bottom: 20px;
  margin-top: 10px;
`;

const FormButtonContainer = styled.css`
  width: 60%;
`;

const Heading = styled.css `
  font-family: 'OCROBOTO';
  font-weight: bold;
  font-size: 32px;
  color: black;
`;

const NextLineHelperText = styled.span`
  color: #585858;
  font-size: 12px;
  font-weight: normal;
  margin-left: 2px;
  display: inline;
`;

const CalculatorForm = connectLocation(({t, locale, formSubmit, inputChange, post}) => {

  const [rvalOpen, rvalChangeState] = useState(false);
  const NONE = t('atticat.none');
  const SINGULAR = t('atticat.singular_inch');
  const MULTIPLE = t('atticat.plural_inch');
  function insulation_progress_inch_convertor(x) {
    return x < 1 ? NONE : x == 1 ? x + ' ' + SINGULAR : x + ' ' + MULTIPLE;
  }

  function insulation_progress_metre_convertor(x) {
    return x < 1 ? NONE : x + 'cm';
  }

  function us_r_values() {
    let y = [13, 19, 22, 26, 30, 38, 44, 49, 60];
    return y.sort().map((number) => { return {value: number, text: 'R'+number}});
  }
  
  function ca_r_values() {
    let x = Array.from(Array(5).keys()).slice(1);
    let y = x.map((number) => { return number * 20});
    return y.sort().map((number) => { return {value: number, text: 'R'+number}});
  }

  const max_insulation_progress_inch = 19;
  const insulation_progress_inch_value = Array.from(Array(max_insulation_progress_inch).keys());
  const insulation_progress_inch_hash = insulation_progress_inch_value.map((number) => { return {value: number, text: insulation_progress_inch_convertor(number)} });

  const insulation_progress_metre_value = [0, 2, 5, 7, 10, 12, 15, 17, 20, 22, 25, 27, 30, 33, 35, 38, 40, 43, 45];
  const insulation_progress_metre_hash = insulation_progress_metre_value.map((number) => { return {value: number, text: insulation_progress_metre_convertor(number)} });

  // let insulationProgress = post.measurementSystem == 'metric' ? insulation_progress_metre_hash : insulation_progress_inch_hash;
  // let insulationProgressDefault = '0';
  let rValue = ['en-CA', 'fr-CA'].includes(locale?.code) ? ca_r_values() : us_r_values();
  let rValueDefault = ['en-CA', 'fr-CA'].includes(locale?.code) ? "80" : "60";
	
	return (
		<>
      <RValModal open={rvalOpen} onClose={() => rvalChangeState(false)}/>
			<Heading>{t('atticat.form_heading')}</Heading>
      <ColoredLine/>

      <Form onSubmit={formSubmit}>
        <Fieldset horizontal={true} legend={t('atticat.measurement_system')} helperText={t('atticat.measurement_system_helper')} onChange={inputChange}>
			    <RadioButton name="measurementSystem" id="imperial" value="imperial" checked={post.measurementSystem == 'imperial'} >{t('atticat.imperial')}</RadioButton>
			    <RadioButton name="measurementSystem" id="metric" value="metric" checked={post.measurementSystem == 'metric'} >{t('atticat.metric')}</RadioButton>
			  </Fieldset>

        <Fieldset legend={t('atticat.attic_area')} helperText={ post.measurementSystem == 'metric' ? t('atticat.sq_mt_helper') : t('atticat.sq_ft_helper')}>
          <TextInput name="atticArea" touched={true} onChange={inputChange} value={post.atticArea} />
        </Fieldset>

        <Legend areaInputSeperator={true}>{t('atticat.or')}</Legend>

        <Fieldset multipleFields={true}>
          <InputContainer>
            <div>
              <Legend>{t('atticat.length')} <HelperText>{ post.measurementSystem == 'metric' ? t('atticat.mt_helper') : t('atticat.ft_helper')}</HelperText></Legend>
              <TextInput name="length" placeholder={t('atticat.length')} touched={true} onChange={inputChange} value={post.length} />
            </div>
            <span><strong>X</strong></span>
            <div>
              <Legend>{t('atticat.width')} <HelperText>{ post.measurementSystem == 'metric' ? t('atticat.mt_helper') : t('atticat.ft_helper')}</HelperText></Legend>
              <TextInput name="width" placeholder={t('atticat.width')} touched={true} onChange={inputChange} value={post.width} />
            </div>
          </InputContainer>
          {post.atticError && <ErrorContainer><i className="fa fa-times-circle" aria-hidden="true"> {post.atticError} </i></ErrorContainer>}
        </Fieldset>

        {/* <Fieldset legend={t('atticat.insulation_required_heading')} helperText={t('atticat.insulation_required_heading_helper')}>
          <SelectDropdown name="insulationProgress" options={ insulationProgress } defaultValue={ insulationProgressDefault } onChange={inputChange} value={post.insulationProgress} />
        </Fieldset> */}
        
        <Fieldset>
          <Legend>{t('atticat.select_r')} <HelperText>{t('atticat.select_r_helper')}</HelperText> { locale?.code == 'en-US' && <HelperText><InfoIcon clickHandler={() => rvalChangeState(true)}/></HelperText> } </Legend>
          <SelectDropdown name="RValue" options={ rValue } defaultValue={ rValueDefault } onChange={inputChange} value={post.RValue} />
        </Fieldset>

        <Fieldset>
          <FormButtonContainer>
            <FormButton buttonType="submit" data-track="form-submit" data-track-form-name="Attic" data-track-form-type="Calculator">
              {t('atticat.submit_button')}
            </FormButton>
          </FormButtonContainer>
			  </Fieldset>
      </Form>
		</>
	)
});

export default CalculatorForm;

import styled from '@emotion/styled';

import Cta from './Cta';

const CtaList = ({ ctas }) => (
  <>
    {
      ( ctas || [] ).map((cta, i) => (
        <Cta key={ i } { ...cta } />
      ))
    }
  </>
);

export default CtaList;

import { define, ui, s} from '@owenscorning/pcb.alpha';

import Renderer from '../../../../OC/PageBuilder/PoleBanner';


export default define`Pole Banner`( '0.0.1' )( {
  thumbnail: ui`Modules/Page/Thumbnails/PoleBanner`,
  category: ui`Modules/Categories/Page/Prebuilt`,
  view: ( { value } ) => <Renderer {...value} />,
  edit: {
    settings: {
      textStyling: ui`Choices`.of( {
          default: 'Default',
          custom: 'Custom'
      } )( {
          label: 'Text Style',
          default: 'default',
      } ),
      [s._]: ui`Tip`.of( 'Default will override content styling and maintain default css styling' )
    },
    content: [
      ui`List`.of(
        {
          first_number: ui`Number`({
            label: 'First Number',
            min: 0,
            step: 1,
            default: 9,
          }),
          out_of_text: ui`Text`( { label: 'Out of', default: 'Out Of' } ),
          second_number: ui`Number`({
            label: 'Second Number',
            min: 0,
            step: 1,
            default: 10,
          }),
          title: ui`Text`( { label: 'Title', default: 'CONTRACTORS AGREE' } ),
          content: ui`Text/Rich`( { label: 'Content', default: 'THAT SHINGLES ARE MORE SECURELY FASTENED WHEN NAILED THROUGH A COMMON BOND IN THE NAILING ZONE†' } ),
        },
        {
          title: ui`Text`( { label: 'Title', default: 'CONTRACTORS AGREE' } ),
          content: ui`Text/Rich`( { label: 'Content', default: 'THAT SHINGLES ARE MORE SECURELY FASTENED WHEN NAILED THROUGH A COMMON BOND IN THE NAILING ZONE†' } ),
        }
      )
      ({
        min: 2, max: 4,
        singular: 'Pole',
        label: 'Contractor Pole',
        default: [{
          first_number: 9,
          second_number: 10,
          out_of_text: 'Out Of',
          title: 'CONTRACTORS AGREE',
          content: {ops: [{insert: 'THAT SHINGLES ARE MORE SECURELY FASTENED WHEN NAILED THROUGH A COMMON BOND IN THE NAILING ZONE†'}]},
        },
        {
          first_number: 9,
          second_number: 10,
          out_of_text: 'Out Of',
          title: 'CONTRACTORS AGREE',
          content: {ops: [{insert: 'THAT SURENAIL® TECHNOLOGY GIVES THE MOST VISIBLE NAIL LINE†'}]},
        }
        ]
      }),
    ],
    image: ui`Image`( { label: 'Image source' } ), [s._]: ui`Tip`.of( 'Add an image 46 × 72 that will be used for both sides, keep blank for default image' ),
  },
} );

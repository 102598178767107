import { ui, define, when, select } from '@owenscorning/pcb.alpha';
import { useEffect, useState } from 'react';

export default ({ crop, defaultImage, askAlt = true, askCaption = false, value }) => {
  const valueFile = value?.file;
  const [settings, setSettings] = useState({
    file: ui`Upload/Image`({
      default: defaultImage
    })
  })

  useEffect(() => {
    let extraSettings = {}
    if (crop) {
      extraSettings.crop = ui`Cropper`({
        image: select`../file`,
        startAspectLocked: false,
        visible: when`../file`.is.present.then(true)
      })
    }
    if (askCaption) {
      extraSettings.caption = ui`Text/Rich`({ label: 'Caption' });
      extraSettings.captionAlignment = ui`Choices`.of({
        'left': 'Left',
        'center': 'Center'
      })({
        label: 'Caption Alignment',
        default: 'left'
      });
    }
    if (askAlt) {
      extraSettings.alt = ui`Text`({ label: 'Alt Text' });
    }

    setSettings({...settings, ...extraSettings});
  }, [crop, askAlt])

  useEffect(() => {
    if (typeof valueFile === undefined)
      return;

    // If there is no file, then we should reset the crop
    // to the original state.
    if (valueFile?.length <= 0) {
      value.crop = undefined;
    }
  }, [valueFile])

  return(
    <Subschema>{[
      ui`Form`.of(settings)({
        label: 'Image'
      })
    ]}</Subschema>
  );
};

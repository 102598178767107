import _ from 'lodash';
import Refs from './refs';
import { refComponents, wrapRef, unwrapRef, limitSplit } from "./helpers";

const expandedRefs = {};

export { wrapRef, unwrapRef, limitSplit };

export const expandRef = async (ref, ...args) => {
  if (ref && ref.hasOwnProperty('__ref')) {
    const { __ref, __data } = ref;
    if (expandedRefs[__ref]) {
      return expandedRefs[__ref];
    }
    if (__data) {
      expandedRefs[__ref] = __data;
      return expandedRefs[__ref];
    }
    const [type, arg] = refComponents(__ref);
    const usableType = type.replaceAll(/::/g,'.')
    const typedef = _.get(Refs, usableType);
    if (typedef) {
      return expandedRefs[__ref] = await typedef(arg, ...args);
    } else {
      throw `Unknown __ref type ${type} (${usableType})`;
    }
  } else {
    return ref;
  }
};

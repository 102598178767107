import { define, ui, s } from '@owenscorning/pcb.alpha';
import styled from '@emotion/styled';

import { BasicContent } from '../../../../OC/PageBuilder/BasicContent';
import { Mobile, Tablet, Desktop } from '../../../../OC/PageBuilder/internals/Devices';
import { Screens } from '../../../../PageBuilder/editing/Viewport';

const Sizes = {
  'xl': { [Mobile]: 345, [Tablet]: 738, [Desktop]: 1170 },
  'l': { [Mobile]: 345, [Tablet]: 738, [Desktop]: 970 },
  'm': { [Mobile]: 345, [Tablet]: 482, [Desktop]: 770 },
};

const TableWrapper = styled.div`
  display: flex;
  justify-content: ${ (props) => props.alignment === 'Left' ? 'flex-start' : 'center' };
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0;
  width: ${({ width }) => `${width.Mobile}px`};

  @media (min-width: ${ Screens.Tablet }px) {
    width: ${({ width }) => `${width.Tablet}px` };
  }

  @media (min-width: ${ Screens.Desktop }px) {
    width: ${({ width }) => `${width.Desktop}px` };
  }
`;

const Renderer = ({ copy, containerSize, alignment, table, after }) => {
  const size = containerSize?.selection || 'xl';
  const Width = Sizes[size];

  return (
    <TableWrapper alignment={alignment}>
      <TableContainer size={size} width={Width}>
        <BasicContent {...copy} />
        <UI.Table value={table} />
        <UI.Text.Rich content={after} />
      </TableContainer>
    </TableWrapper>
  );
};

export default define`PimProductsTable`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/Table`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value }) => <Renderer { ...value } />,
  edit: {
    containerSize: ui`Form`.of({
      selection: ui`Choices`.of({
        'xl': 'XL',
        'l': 'Large',
        'm': 'Medium',
      })({
        label: 'Container Size',
        default: 'xl',
        mode: ui`Choices/Mode/Dropdown`
      })
    }),
    alignment: ui`Choices`.of([
      'Left',
      'Center'
    ])({
      label: 'Alignment',
      default: 'Left'
    }),
    tablePlaceholder: ui`Product/PimConnected`({
      label: "Table",
      noPadding: false,
      tip: "Table data is linked to the connected PIM product and pulled from the API."
    }),
    after: ui`Text/Rich`({
      sublabel: 'Text After Table'
    })
  },
});

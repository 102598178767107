import { ui } from '@owenscorning/pcb.alpha';
import Article from './Article';

const ArticleParoc = Article(
  'Article::Paroc',
  'paroc_articles',
  [
    'SocialMediaShareBanner',
    'RelatedArticles',
    'StonewoolBenefits',
    'SustainabilityContent',
    'BlogCategories'
  ],
  ui`AttributeSet`({
    query: {
      name: 'Article Attributes',
      path: '/'
    }
  }),
  {
    version: '0.1',
    displayName: 'Paroc Article',
    paths: ['article'],
  }
)

export default ArticleParoc;

import { useRef, useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import { useAccessibleInteractivity } from "../../../hooks";
import Theme from '../../../themes/index';
import PropTypes from 'prop-types';
import IconChevron from '../icons/icon-chevron';

// Components
import Label from './oc-label';
import InputError from './oc-input-error';

// Styled Components
const SelectContainer = styled.div`
  background-color: #FFFFFF;
  border-color: ${props => props.valid ? '#585858' : '#940420' };
  border-color: ${props => props.disabled && '#A8A8A8 !important' };
  border-style: solid;
  border-width: 1px;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;

  &:hover {
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
  }

  &:focus-within {
    border-color: ${props => props.themeColor};
    outline: none;
  }
`;

const Select = styled.select`
  appearance: none;
  background-color: transparent;
  border: none;
  color: ${props => props.valid ? '#585858' : '#940420' };
  color: ${props => props.disabled && '#A8A8A8 !important' };
  cursor: pointer;
  font-size: 16px;
  margin: 0; // Reset for mobile user agent styles
  outline: none;
  padding: 14px 30px 14px 8px;
  width: 100%;
`;

const Option = styled.option`
  //
`;

const Chevron = styled(IconChevron)`
  display: inline-block;
  pointer-events: none;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
`;

const SelectDropdown = ({
  value: valueFromProps,
  onChange: onChangeFromProps,
  defaultValue,
  disabled = false,
  multiple,
  error,
  helperText,
  infoFunction,
  label,
  name,
  themeColor = Theme.colors.brand,
  options,
  required = false,
  valid = true
}) => {
  const selectElement = useRef();

  const isControlled = typeof valueFromProps != 'undefined'
  const hasDefaultValue = typeof defaultValue != 'undefined'
  const [internalValue, setInternalValue] = useState(
    hasDefaultValue ? defaultValue : ''
  )
  const value = isControlled ? valueFromProps : internalValue

  const onChange = useCallback(
    event => {
      // When the user types, we will call props.onChange if it exists.
      // We do this even if there is no props.value (and the component
      // is uncontrolled.)
      if (onChangeFromProps) {
        onChangeFromProps(event)
      }
      // If the component is uncontrolled, we need to update our
      // internal value here.
      if (!isControlled) {
        setInternalValue(typeof(event) === 'object' && event && event.hasOwnProperty('target') ? event.target.value : event)
      }
    },
    [onChangeFromProps, isControlled]
  );

  const defaultHelperText = helperText || (required ? "Required" : null);

  return (
    <>
      {(label || helperText) && <Label forId={name} text={label} helperText={defaultHelperText} disabled={disabled} valid={valid} infoFunction={infoFunction} required={required} />}

      <SelectContainer disabled={disabled} valid={valid} themeColor={themeColor}>
        <Select
          multiple={multiple}
          required={required}
          disabled={disabled}
          id={name}
          name={name}
          onChange={onChange}
          ref={selectElement}
          valid={valid}
          value={value}
        >
          {options &&
            options.map((option) => {
              const text = typeof option === "string" ? option : option.text;
              const value = typeof option === "string" ? option : option.value;

              return (
                <Option key={value} value={value}>
                  {text}
                </Option>
              )
            })
          }
        </Select>
        <Chevron className="chevron" fill={valid ? "#000000" : "#940420"} width="16" />
      </SelectContainer>

      {(error && !valid) && <InputError message={error} />}
    </>
  );
}

export default SelectDropdown;

SelectDropdown.propTypes = {
  /** The text above the select field that identifies the select field */
  label: PropTypes.string,
  /** The name for the selection field */
  name: PropTypes.string,
  /** Hex value for color theme */
  themeColor: PropTypes.string,
  /** The callback for change */
  onChange: PropTypes.func,
  /** The value for the selection field */
  value: PropTypes.string,
  /** The value for the selection field */
  defaultValue: PropTypes.string,
  /** If the field is valid */
  valid: PropTypes.bool,
  /** the error message that gets shown */
  error: PropTypes.string,
  /** set to true if you want the field disabled */
  disabled: PropTypes.bool,
  /** an array of options for the select */
  options: PropTypes.array,
  /* HTML: Determines whether or not the input is required */
  required: PropTypes.bool,
  /* HTML: Determines whether multiple selection allowed */
  multiple: PropTypes.bool,
  /** help text for the label */
  helperText: PropTypes.string,
  /** a function for the label info icon */
  infoFunction: PropTypes.func,
}

import { ui } from '@owenscorning/pcb.alpha';
import styled from '@emotion/styled';
import _ from 'lodash';
import { SortDropDown } from '../../../SDS/SortDropDown';
import ColorFilter from '../../../ComponentLibrary/filter-tree/oc-color-filter';
import CheckboxSelector from '../../../ComponentLibrary/filter-list/oc-filters';
import Shingle from './helpers/Shingle';

const priceRanges = [
  { value: '5', translationKey: 'shingles.price_ranges[4]' },
  { value: '4', translationKey: 'shingles.price_ranges[3]' },
  { value: '3', translationKey: 'shingles.price_ranges[2]' },
  { value: '2', translationKey: 'shingles.price_ranges[1]' },
  { value: '1', translationKey: 'shingles.price_ranges[0]' },
];

const shinglesResponse = (shingleLines) => {
  const availableColors = [],
    availableColorNames = [],
    availableShingleLines = [],
    parsedShingles = [],
    warrantyOptions = [];

  shingleLines.forEach(shingleLine => {
    availableShingleLines.push({value: shingleLine.uid, displayName: shingleLine.proper_name, style: shingleLine.visual_style});
    warrantyOptions.push({value: shingleLine.warranty, displayName: shingleLine.warranty});
    shingleLine.shingle_colors?.forEach((shingleColor) => {
      // TODO: Consider changing the data provider to only return valid data, i.e., shingle colors with gallery images
      shingleColor.gallery_images?.forEach((galleryImage, index) => {
        // Push as many gallery images we have for each shingle color. The images are the indexing
        // we use for this component, not the color itself.
        parsedShingles.push({
          colorName: shingleColor.name,
          featured: false,
          galleryImage: galleryImage,
          item: shingleColor,
          lineName: shingleLine.proper_name,
          price: shingleLine.price_uid,
          primary_shingle_colors: shingleColor.colors,
          shingle_color_names: [shingleColor.uid],
          shingle_lines: [shingleLine.uid],
          warranty: shingleLine.warranty,
        });

        // Only add shingleColor and availableColorNames once.
        if (index == 0) {
          // TODO: Consider returning this from the MDMS provider, or add to the array without
          // duplicates, maybe using Set, instead of adding everything and removing duplcates later.
          shingleColor.colors.forEach(primaryColor => availableColors.push({value: primaryColor, translationKey: `shingles.colors.${primaryColor}`}));
          availableColorNames.push({value: shingleColor.uid, displayName: shingleColor.name});
        }
      });
    });
  });

  const filters = {
    primary_shingle_colors: {
      component: ColorFilter,
      displayName: 'Primary Shingle Color Blend',
      props: { translationKey: 'shingles.filters.colors' },
      options: _.uniqBy(availableColors, 'value'),
      valueType: 'multiple',
    },
    shingle_color_names: {
      component: CheckboxSelector,
      displayLimit: 5,
      displayName: "Shingle Color Names",
      props: { translationKey: 'shingles.filters.color_names' },
      options: _.sortBy(_.uniqBy(availableColorNames, 'value'), 'displayName'),
      valueType: 'multiple',
    },
    shingle_lines: {
      component: CheckboxSelector,
      displayName: "Shingle Line",
      options: _.uniqBy(availableShingleLines, 'value'),
      props: {
        groupOptionsBy: 'style',
        translationKey: 'shingles.filters.lines',
      },
      valueType: 'multiple',
    },
    price: {
      component: CheckboxSelector,
      displayName: 'Price',
      props: { translationKey: 'shingles.filters.prices' },
      options: priceRanges,
      valueType: 'multiple',
    },
    warranty: {
      component: CheckboxSelector,
      displayName: 'Warranty',
      props: { translationKey: 'shingles.filters.warranty' },
      options: _.uniqBy(warrantyOptions, 'value'),
      valueType: 'multiple',
    },
  };

  return {
    lists: [{ name: 'results', data: parsedShingles }],
    filters,
    Component: Shingle,
    searchFields: ['colorName', 'warranty', 'lineName', 'primary_shingle_colors', 'shingle_color_names', 'shingle_lines'],
    enableSearch: true,
  };
};

export default {
  ItemList: {
    availableIn: ['www.owenscorning.com'],
    name: 'Roofing - Shingle Gallery',
    settings: {
      resultComponent: {
        resultComponentStyle: 'grid',
        resultGridSize: 2,
      },
    },
    view: (data = null) => {
      // Note: Not using the meta just yet, since we are not allowing filtering and extra
      // configuration on the page builder for MVP.
      const { data: items, meta: { parameters, filters } } = (data || { meta: {} });

      return shinglesResponse((items || []));
    }
  },
};

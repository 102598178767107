import { define, ui, when, select } from '@owenscorning/pcb.alpha';
// when we rewrite Renderer, be aware of ./Builder/Library/Modules/Product/RoofingIntroContentAndImage.jsx
import Renderer from './../../../../OC/PageBuilder/ContentAndImage.jsx';
import IconPimLink from '../../../../ComponentLibrary/icons/icon-pim-link.jsx';
import _ from 'lodash';

const sizesHash = {
  '1-2': ['1/3 Text & 2/3 Image', '1540px'],
  '1-1': ['1/2 Text and 1/2 Image', '1140px'],
  '2-1': ['2/3 Text and 1/3 Image', '740px'],
  'noMedia': null
};

export default define`Content & Image`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/ContentAndImage`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value }) => {
    let content = _.cloneDeep(value?.content) || {};
    let connectedToPim = !_.isEmpty(Board.Value.linkedObject);
    content.bodyAttributes = connectedToPim ? content?.bodyAttributes : null;
    content.footnotes = connectedToPim ? content?.footnotes : null;
    return (<Renderer { ...value } content={content} />);
  },
  edit: ({
    contentOptions = {
      bodyCopy: when`~linked_object`.is.present.and.when`~linked_object/source`.is.equal.to("pim_insulation").then(false).otherwise(true)
    },
    ...props
  }) => <UI.Form { ...props } contents={{
    arrangement: {
      layout: ui`Choices`.of({
        '1-2': '1/3 text and 2/3 media',
        '1-1': '1/2 and 1/2 layout',
        '2-1': '2/3 text and 1/3 media',
        'noMedia': 'No Media'
      })({
        label: 'Layout',
        default: '1-1',
        disabled: when`~linked_object`.is.present.and.when`~linked_object/source`.is.equal.to("pim_insulation"),
        tip: when`~linked_object`.is.present.and.when`~linked_object/source`.is.equal.to("pim_insulation").then("Field connected to PIM product."),
        tipIcon: IconPimLink
      }),
      containerSize: ui`Form`.of({
        selection: ui`Choices`.of({
          'xl': 'XL',
          'l': 'Large',
          'm': 'Medium',
        })({
          label: 'Container Size',
          default: 'xl',
          mode: ui`Choices/Mode/Dropdown`,
          disabled: when`~linked_object`.is.present.and.when`~linked_object/source`.is.equal.to("pim_insulation"),
          tip: when`~linked_object`.is.present.and.when`~linked_object/source`.is.equal.to("pim_insulation").then("Field connected to PIM product."),
          tipIcon: IconPimLink
        })
      }),
      alignment: ui`Choices`.of({
        'left': 'Left',
        'center': 'Center'
      })({
        label: 'Alignment',
        default: 'left',
        visible: when`../../arrangement/containerSize/selection`.is.equal.to('xl').then(false).otherwise(true)
      }),
      contentOrder: ui`Choices`.of({
        'first': 'Left',
        'last': ' Right'
      })({
        label: 'Text Position (Desktop/Tablet)',
        default: 'first',
        visible: when`../../arrangement/layout`.is.equal.to('noMedia').then(false).otherwise(true)
      }),
      contentOrderMobile: ui`Choices`.of({
        'last': 'After Image',
        'first': 'Before Image'
      })({
        label: 'Text Position (Mobile)',
        default: 'last',
        visible: when`../../arrangement/layout`.is.equal.to('noMedia').then(false).otherwise(true)
      }),
    },
    imageSizeInfo: ui`ImageSizeInfo`({
      visible: when`../media/type`.is.equal.to('image'),
      moduleName: 'Figure',
      sizesHash: sizesHash,
      selectedSize: select`../arrangement/layout`
    }),
    media: when`~linked_object`.is.present.and.when`~linked_object/source`.is.equal.to("pim_insulation").then(
      ui`Product/PimMedia`({ label: "Media" })
    ).otherwise(
      ui`Media`({ visible: when`../arrangement/layout`.is.equal.to('noMedia').then(false).otherwise(true) })
    ),
    imageUrl: ui`Text`({
      label: 'Image Url (Optional)', visible: when`../media/type`.is.equal.to('image'),
      visible: when`~linked_object`.is.present.and.when`~linked_object/source`.is.equal.to("pim_insulation").then(false).otherwise(true),
    }),
    content: ui`Product/InsulationBasicContent`({ contentOptions })
  }} />
});

/* eslint-disable react/react-in-jsx-scope */
import ReactDOM from 'react-dom';

import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import { v4 as uuid } from 'uuid';

import _ from 'lodash';

import Theme from '../../../../themes';

const Container = styled.div`
  position: fixed;
  z-index: 999999999;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 16px;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
`;

const Window = styled.div`
  padding: 16px;
  box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.4);
  width: ${ (props) => Modal.Width[props.size] }px;
  background-color: #FFF;
  margin: auto;
  position: relative;
`;

const Close = styled.div`
  font-size: 22px;
  position: absolute;
  z-index: 1000;
  right: 16px;
  top: 16px;
  user-select: none;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.whiteCloseButton ? 'white' : 'inherit'};

  &:focus, &:hover {
    color: ${Theme.colors.brand};
  }
`;

const Buttons = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 16px;
`;

const Preface = styled.div`
  padding-bottom: 16px;
`;

const Progress = styled.div`
  font-size: 14px;
  padding-bottom: 24px;
  i {
    font-size: 21px;
    position: relative;
    top: 2px;
  }
`;

// color the first one pink, rest white.
const WHITE = 'white';
const PINK = 'pink';
const buttonColor = (name) => {
    return name?.toLowerCase() === "cancel" ? WHITE : PINK;
};

function ModalButton({ action, field, name }) {
  const onClick = Board(() => action?.(Board.Value.modals[field]));
  const color = buttonColor(name);
  return (
    <UI.CTA color={color} onClick={onClick}>
      {name}
    </UI.CTA>
  );
}

function ModalButtons({ buttons, buttonless, field }) {
  if (_.isEmpty(buttons) || buttonless) return null;

  return (
    <Buttons>
      {_.map(buttons, (action, name) => (
        <ModalButton
          action={action}
          field={field}
          name={name}
        />
      ))}
    </Buttons>
  );
}

const Modal = ({ size='lg', title, preface, body, whiteCloseButton=false, buttons, buttonless=false, onClose, field="body" }) => (
  ReactDOM.createPortal((
    <Container>
      <Overlay onClick={ () => onClose() } />
      <Window size={ size || Modal.Size.Medium } >
        <Close onClick={ () => onClose() } whiteCloseButton={whiteCloseButton} ><UI.Icon type="times" /></Close>
        { title && <h3>{ title }</h3> }
        { preface && <Preface>{ preface }</Preface> }
        { body && <Subschema field={ field } >{ body }</Subschema> }
        <ModalButtons buttons={buttons} buttonless={buttonless} field={field} />
      </Window>
    </Container>
  ), document.body)
);

Modal.Size = { Large: 'lg', Medium: 'md', Small: 'sm' };
Modal.Width = { lg: 900, md: 700, sm: 600 };

const getModalId = (modal) => (
  _.isString(modal) ? modal : modal.__id
);

export default ({ open }) => {
  if (!Board.modal) Board.Set({
    modal: {
      stack: new Set(),
      get current() { return Array.from(Board.modal?.stack||[])?.at(-1) },
      reference: {},
      variables: {},
      close: () => {
        const remainingModals = Array.from(Board.modal.stack).slice(0, -1);
        const reference = remainingModals.at(-1)?.reference || {};
        const variables = remainingModals.at(-1)?.variables || {};
        return Board.Set({ modal: { ...Board.modal, stack: new Set(remainingModals), reference, variables } })
      },
      open: (modal, reference, initial, variables={}) => {
        if (!_.isString(modal)) {
          // this is a schematic passed in, generate some id to store results in board
          modal = {  __id: uuid(), ...modal };
        }
        return Board.Change(
          initial, [ 'modals', getModalId(modal) ],
          () => Board.Set({ modal: { ...Board.modal, stack: Board.modal.stack.add({ modal, reference, variables }), reference, variables } })
        )
      }
    }
  });
  return <>
    { Array.from(open||[]).map(current => (
      <Modal
        onClose={ Board(() => Board.modal.close()) }
        field={ getModalId(current.modal) }
        { ...(_.isString(current.modal) ? contents[current.modal] : current.modal) }
      />
      )
    ) }
  </>;
};

import _ from 'lodash';
import { useContext } from 'react';

import LocatorContext from './context';
import CustomResultBlock from '../../../ComponentLibrary/filter-result-blocks/oc-custom-result-block';

function getValueType(dataType) {
  return (
    {
      select: 'singular',
      multi_select: 'multiple',
    }[dataType] || 'none'
  );
}

function setFeatures(features, filterInformation, components) {
  if (!features) {
    components.Features = { showComponent: false };
    return;
  }
  // use the filter information to get the translated labels
  const { filters = [] } = filterInformation;
  const getFeatureProps = (fKey, data) => {
    const filter = filters.find(f => f.filterId === fKey);
    const title = filter?.title || fKey;
    const options = filter?.options || [];
    const labels = Object.fromEntries(options.map(opt => [opt.key, opt.label]));
    const valueType = getValueType(filter?.data_type);
    let items = [];
    if (valueType === 'singular') {
      items = data[fKey] ? [labels[data[fKey]]] : [];
    } else if (valueType === 'multiple') {
      let unlabeledItems = data[fKey] || [];
      if (!Array.isArray(unlabeledItems)) {
        console.log(`expected an array for ${fKey} but got`, unlabeledItems);
        unlabeledItems = [];
      }
      items =
        unlabeledItems.map(
          f => labels[f] || console.log(`missing translation for${fKey}:${f}`)
        ).filter(item => item) || [];
    }

    return {
      title,
      items,
    };
  };
  components.Features = {
    getProps: (attrs, data) => {
      const featureProps = {};
      features.forEach(fKey => {
        featureProps[fKey] = getFeatureProps(fKey, data);
      });
      return {
        show: features.length > 0,
        features: featureProps,
      };
    },
  };
}

const ResultComponent = ({ ...resource }) => {
  const {
    features,
    filterInformation,
    units,
    resultComponentOptions,
  } = useContext(LocatorContext);

  const options = _.assign(
    {
      units,
    },
    resultComponentOptions.options || {}
  );
  const components = _.assign({}, resultComponentOptions.components || {});
  const attributes = _.assign({}, resultComponentOptions.attributes || {});

  setFeatures(features, filterInformation, components);

  return (
    <CustomResultBlock
      resource={resource}
      options={options}
      components={components}
      attributes={attributes}
    />
  );
};

export default ResultComponent;

import styled from "@emotion/styled";

const Video = styled.video`
  display: inline-block;
  position: relative;
  width: 100%;
`;

const CloudinaryVideo = ({ src, className }) => {
  return (
    <Video src={src} muted autoPlay loop className={className}></Video>
  )
}

export default CloudinaryVideo;

import { useState } from 'react';
import { css } from '@emotion/react';
import RichText from './oc-rich-text';

const alertMessageStyle = ({ alertIcon, textSize }) => css`
  align-items: center;
  display: flex;
  font-size: ${textSize ? textSize : '16px'};
  justify-content: space-between;
  line-height: 16px;
  padding: 12px 16px;

  &.page-alert {
    padding-left: 20px;
    padding-right: 20px;
  }

  .copy-container {
    display: inline-flex;
    justify-content: flex-start;
    line-height: 18px;
    position: relative;

    ${alertIcon && `
      &:before {
        content: '\\f071';
        font-family: 'fontawesome';
        margin-right: 12px;
      }
    `}

    a {
      color: inherit;
    }
  }
`;

const TYPE_STYLES = {
  black: css`
    background: #000000;
    color: #FFFFFF;
  `,
  error: css`
    background: #F7DBDB;
    color: #660014;
  `,
  info: css`
    background: #DBDDF5;
    color: #060B6E;
  `,
  other: css`
    background: #FFF1E5;
    color: #7E3900;
  `,
  success: css`
    background: #D7F9D7;
    color: #053B05;
  `,
  warning: css`
    background: #FFFBEE;
    color: #6C5100;
  `,
};

const dismisserStyle = css`
  cursor: pointer;
  margin-left: 1em;
`;

const AlertMessage = ({ className, dismissable = true, message, title = '', type = 'info', alertIcon = false, textSize, style }) => {
  const [dismissed, setDismissed] = useState(false);

  const dismiss = () => setDismissed(true);

  if (dismissed) return null;

  return (
    <div className={className} css={[alertMessageStyle ({ alertIcon, textSize }), TYPE_STYLES[type], style]}>
      <div className="copy-container">
        {title && (<strong>{title}:&nbsp;</strong>)}
        <RichText content={message} inline={true} />
      </div>

      {dismissable && (
        <span css={dismisserStyle} onClick={dismiss}>
          <i className="icon-close" title="Remove message" />
        </span>
      )}
    </div>
  );
}

export default AlertMessage;

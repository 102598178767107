import { BasicContent } from './BasicContent';
import FullBleedContent from '../oc-full-bleed-content';

const CMSFullBleedContent = ({
  layout,
  content,
  img,
  alt,
  hotSpots,
  ...props
}) => (
  <FullBleedContent
    img={img}
    imgAlt={alt}
    height={layout?.height}
    contentSide={layout?.contentSide}
    aspectRatio={layout?.aspectRatio}
    noMargin
    hotSpots={hotSpots}
    {...props}
  >
    <BasicContent {...content} />
  </FullBleedContent>
);

export default CMSFullBleedContent;

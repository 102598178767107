
import { useCallback } from 'react';
import { css, jsx } from '@emotion/react';
import { MaterialPicker } from 'react-color';

const ColorInput = ({ onChange, value }) => {
  const onChangeCallback = useCallback((color) => onChange(color.hex));
  return (
    <div css={css`
      display: flex;
      .material-picker {
        width: 128px !important;
        height: 128px !important;
      }
    `} >
      <MaterialPicker color={ value } onChange={ onChangeCallback } />
      <div css={ css` border: solid 1px rgb(238, 238, 238); margin-left: 18px; width: 100%; height: 128px; background-color: ${ value }; ` } />
    </div>
  );
}

export default ColorInput;

import { useState } from 'react';
import Grid from "../../OC/PageBuilder/Grid";

const Result = ({result, resultComponent, resultHeaderComponent, resultComponentStyle, resultGridSize = 3, appliedFilters, locale, showLogo=false}) => {

  const [collapsed, setCollapsed] = useState(false);

  let Component = resultComponent;

  return(
    <>
      { resultComponentStyle === "grid"
        ? (<Grid selectedSize={resultGridSize}>
            {result.data.length > 0 && resultHeaderComponent && resultHeaderComponent(result.headerData)}
            {!collapsed && result.data.map((item, index) => {
              return <Component key={index} appliedFilters={appliedFilters} locale={locale} {...item} />
            })}
          </Grid>)
        : (<>
            {result.data.length > 0 && resultHeaderComponent && resultHeaderComponent(result.headerData)}
            {!collapsed && result.data.map((item, index) => {
              return <Component key={index} appliedFilters={appliedFilters} locale={locale} showLogo={showLogo} {...item} />
            })}
          </>)
      }
    </>
  )
}
export default Result;

const combinedAddress = ({address, address_2}) => [address, address_2].filter(Boolean).join(', ');

const formatUkAddress = location => {
  const { city = '' } = location;
  const postal = location.postal || location.zip;
  const address = combinedAddress(location);
  // often in UK, the postal code is associated with the city, so they place it before the city
  return `${address}, ${postal} ${city}`;
};

const formatUsAddress = location => {
  const { city = '', state = '' } = location;
  const postal = location.postal || location.zip;
  const address = combinedAddress(location);
  const fullAddress = `${address}, ${city}, ${state} ${postal}`;
  return fullAddress;
};

export const formatAddress = (location, localeCode) => {
  const [, cCode] = localeCode.split('-');
  switch (cCode) {
    case 'US':
    case 'CA':
      return formatUsAddress(location);
    default:
      return formatUkAddress(location);
  }
};

import _ from 'lodash';
import {connect} from 'react-redux';
import {VIDEO_METADATA_UPDATE_DATA} from "../Actions";
import getReduxInitial from "../helpers/redux_initial";

const DEFAULT_INITIAL_STATE = {
  videos: []
}

const videoMetadataInitial = _.assign({}, DEFAULT_INITIAL_STATE, getReduxInitial('video_metadata', {}));

export const videoMetadataReducer = (state = videoMetadataInitial, action) => {
  const updatedState = _.cloneDeep(state);
  if (action.type === VIDEO_METADATA_UPDATE_DATA) {
    updatedState.videos = updatedState.videos.map(data => {
      if (data.identifier === action.data.identifier)
        data.seizure_warning = action.data.seizure_warning
      return data
    })
    return updatedState
  }
  return updatedState
}


export const updateSeizureWarning = (data) => {
  return {type: VIDEO_METADATA_UPDATE_DATA, data}
}

export const VideoMetadataConnector = connect(
  ({ videoMetadata }) => {
    return (
      {videos: videoMetadata.videos}
    );
  },{updateSeizureWarning}
)

import { board, ui } from '@owenscorning/pcb.alpha';
import Library from '../../Builder/Library';

export default board`ReusableBoard`('0.0.1')(
  Library,
  () => ({}),
  (props) => ({
    variables: {
      ...Library.Types.Page(Library).variables,
      language: props.language
    },
    schematic: ui`Modules/Page/Reusable|view`({ value: props, gutterlessChild: props.gutterlessChild })
  })
);

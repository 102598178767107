import _ from 'lodash';
import ProductItem from './helpers/ProductItem';
import ProductItemList from './helpers/ProductItemList';
import { connectLocation } from '../../../location/LocationConnectors';
import retrieveImage from './helpers/retrieveImage';
import pathToContent from './helpers/path_to_content';
import handleExternalUrl from "./helpers/handle_external_url";


const ProductListItem = connectLocation(({ aspectRatio, ...product }) => {
  const product_thumb = retrieveImage(product);
  const generalContent = product.metadata?.settings?.general;
  const teaser_copy = generalContent?.long_description || generalContent?.short_description;
  const availability = product.metadata?.settings?.availability;
  const product_url = availability?.status === 'external' ? handleExternalUrl(availability?.externalUrl) : pathToContent(product);


  return (
    <ProductItem
      aspectRatio={ aspectRatio }
      key={ product.uid }
      heading={ generalContent?.proper_name }
      img={ product_thumb }
      text={ teaser_copy }
      url={ product_url }
    />
  );
})

export default ProductItemList({
  common: {
    type: 'Product::Paroc'
  },
  search: {
    name: 'Paroc Products',
    preview: result => ({
      heading: result.metadata?.settings?.general?.proper_name || result.name
    })
  },
  list: {
    availableIn: ['www.paroc.com'],
    name: 'Products - Paroc',
    attributeSet: {
      name: 'Product Attributes',
      path: ''
    },
    dataset: 'products_paroc',
    view: ProductListItem,
    enableSearch: true,
  }
})

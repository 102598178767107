import { first } from 'lodash';
import placeholder_url from '../../../../placeholder';
import pathToContent from './helpers/path_to_content';
import ArticleItemList from './helpers/ArticleItemList';
import { makeT } from "../../../location/locales";

const mapItemFromData = (content) => {
  if (!content) {
    return null;
  }
  const { metadata } = content;
  const { settings: { details = {} } = {} } = {} = metadata;
  const t = makeT(Board.build.language);
  return (
    {
      metadata,
      image: details?.image?.file ? details.image : { file: placeholder_url(370,185) },
      img: details?.image?.file || placeholder_url(370,185),
      alt: details?.image?.alt,
      prehead: [
        details?.taxonomies?.primary_category || first(details?.taxonomies?.categories)
      ].filter(x => x).join(' • '),
      heading: details?.information?.title,
      text: details?.information?.description,
      linkText: t('builder.data.read_more'),
      url: pathToContent(content),
      icon: 'newspaper-o'
    }
  );
};

export default
  ArticleItemList({
    search: {
      name: 'Lumber Blog Articles',
      type: 'Article::Lumber',
      preview: result => ({
        preheading: result.category,
        heading: result.proper_name
      })
    },
    list: {
      availableIn: ['www.owenscorning.com'],
      name: 'Blog - Lumber',
      attributeSet: {
        name: 'Blog - Lumber',
        path: 'composites/lumber'
      },
      dataset: 'lumber_blog',
      itemListMap: mapItemFromData,
      cardSetMap: mapItemFromData,
      categoryFeaturesMap: mapItemFromData,
      enableSearch: true,
    }
  })

import { useState, useEffect } from 'react';
import { jsx, css } from '@emotion/react';
import NumberFormat from 'react-number-format';

const summaryBox = css`
  background: #F8F8F8;
  display: flex;
  padding: 24px 10px;
  margin-top: 12px;
  margin-bottom: 24px;

  @media(min-width: 768px) {
    padding: 24px 0;
  }

  @media(min-width: 1200px) {
    padding: 32px 85px;
    margin-top: 16px;
  }

  section {
    margin 0;
    max-width: 100%;
  }

  .bold {
    font-size: 18px;
    line-height: 22px;
    color: black;
    margin-bottom: 24px;
    font-weight: bold;

    @media(min-width: 768px) {
      margin: 0 15px;
    }

    @media(min-width: 1200px) {
      font-size: 21px;
      line-height: 23px;
      margin: 0;
    }
  }

  .pink {
    color: #D40F7D;
  }

  .images-container {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    max-width: 100%;

    @media(min-width: 768px) {
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 0;
    }

    @media(min-width: 1200px) {
      margin-top: 24px;
    }

    .img-text-container {
      max-width: 100%;
      flex: 1;

      @media(min-width: 768px) {
        min-width: 40%;
        margin: 24px 15px 0 15px;
      }

      @media(min-width: 1200px) {
        min-width: 0;
        margin: 0;
      }

      &.not-first {
        margin-top: 24px;

        @media(min-width: 1200px) {
          margin-top: 0;
          margin-left: 30px;
        }
      }

      .img-container {
        max-width: 100%;
        flex: 1;
        margin-bottom: 8px;
      }
    }
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const SummaryBox = ({ t, tNumber, projectData, projectType, projectEmissionSaving }) => {
  const projectName = t(`foamular_ngx.gwp_reduction_calculator_options.${projectType}`).toLowerCase();
  const frInfiniteUne = projectName.includes('école') ? 'e ' : ' ';
  let avgCopy = `${t('foamular_ngx.gwp_reduction_summary_average')}${frInfiniteUne}`;
  let saveCopy = t('foamular_ngx.gwp_reduction_summary_save');

  if (projectName.includes('10 panneaux')) {
    avgCopy = '';
    saveCopy = saveCopy.replace('économise', 'économisent');
  }


  return (
    <div css={summaryBox}>
      <section>
        <div className="bold">
          <span dangerouslySetInnerHTML={{ __html: avgCopy}}></span>
          <span>{projectName}</span>
          {' '}
          <span dangerouslySetInnerHTML={{ __html: saveCopy}}></span>
          {' '}
          <span className="pink">
            <NumberFormat
              value={projectEmissionSaving}
              displayType="text"
              thousandSeparator={tNumber.thousandSeparator}
              decimalSeparator={tNumber.decimalSeparator}
              suffix={` ${t('foamular_ngx.gwp_reduction_summary_unit')}`}
              decimalScale={0}
            />
          </span>
          {' '}
          <span dangerouslySetInnerHTML={{ __html: t('foamular_ngx.gwp_reduction_summary_in_comparison')}}></span>
        </div>
        <div className="images-container">
          {projectData.map((d, i) => (
            <div className={`img-text-container ${i > 0 ? 'not-first' : null}`} key={`${projectName}-${d.translation}`}>
              <div className="img-container">
                <img src={t(`foamular_ngx.${d.translation}.image`)} />
              </div>
              <h4 className="text">
                <span>{t(`foamular_ngx.${d.translation}.description`)} </span>
                <span className="pink">
                  <NumberFormat
                    value={d.value === 80.2 ? 80 : d.value}
                    displayType="text"
                    thousandSeparator={tNumber.thousandSeparator}
                    decimalSeparator={tNumber.decimalSeparator}
                  />
                </span>
                <span> {d.value > 1 ? t(`foamular_ngx.${d.translation}.units`) : t(`foamular_ngx.${d.translation}.units`).replace('foyers', 'foyer')}</span>
              </h4>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

import styled from '@emotion/styled';
import IconBase from './icon-base';

const IconMapMarker = ({ className, fill, hoverColor, height, width } ) => (
  <IconBase className={className} viewBox="0 0 384 512" width={width} height={height} fill={fill} hoverColor={hoverColor} aria-hidden="true" role="img">
    <path d="M172.27,501.67C27,291,0,269.41,0,192,0,86,86,0,192,0S384,86,384,192c0,77.41-27,99-172.27,309.67a24,24,0,0,1-39.46,0ZM192,272a80,80,0,1,0-80-80A80,80,0,0,0,192,272Z" transform="translate(0 0)" />
  </IconBase>
);

export default IconMapMarker;

import styled from '@emotion/styled';

// Components
import {H3} from '../ComponentLibrary/text-elements/oc-h';
import Button from '../ComponentLibrary/oc-button';
import AnimatedAttentionBarWrapper from "./shared/oc-animate-wrapper";
import { useInView } from "react-intersection-observer";
import anime from "animejs";
import { useEffect, useRef } from "react";
import AnimateTextContainer from "./shared/oc-animated-text-container";

const AttentionBarContainer = styled.div`
	background: black;
	display: flex;
	flex-direction: column;

	@media(min-width: 768px) {
		flex-direction: row;
	}
`;

const Section = styled.div`
	padding: 16px;
	color: white;

	h3 {
		color: white;
	}

	&:first-of-type {
			border-bottom: 1px solid white;
	}

	@media(min-width: 768px) {
		width: 50%;

		&:first-of-type {
			border-bottom: none;
			border-right: 1px solid white;
		}
	}
`;

const CtaWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;

	@media(min-width: 1200px) {
		flex-direction: row;

		a {
			width: 50%;
		}
	}
`;

const AttentionBarRoofing = ({animationProps}) => {
  const {animate, flashAnimRef, slideAnimRef } = animationProps || {animate: false, flashAnimRef: useRef(null), slideAnimRef: useRef(null)};
  const slideAnimRef2 = useRef(null);
  const [inViewRef, inView] = useInView({
    threshold: 1,
    triggerOnce: true, // set this flag to false if you want to willAnimate the text on every scroll into view
  });

  const animationTimeline = anime.timeline({
    autoplay: false,
  });

  useEffect(() => {
    if (animate) {
      AnimateTextContainer(inView, [slideAnimRef.current, slideAnimRef2.current], animationTimeline);
    }
  }, [inView, animate]);

  return (
    <AnimatedAttentionBarWrapper
      wrapperComponent={AttentionBarContainer}
      wrapperProps={{
        className: 'flash-bar-roofing',
      }}
      target={flashAnimRef}
      animate={animate}
      reference={inViewRef}
      inView={inView}
      animationTimeline={animationTimeline}
    >
      <Section>
        <div ref={slideAnimRef}><H3>Find Our Products</H3></div>
        <CtaWrapper>
          <Button href="/roofing/basics/find-distributor-retailer" color="black" data-track="where-to-buy"
                  data-track-buy-type="retailer">Retail Stores</Button>
          <Button href="/roofing/basics/find-distributor-retailer" color="black" data-track="where-to-buy"
                  data-track-buy-type="distributor">Distributors</Button>
        </CtaWrapper>
      </Section>

      <Section>
        <div ref={slideAnimRef2}><H3>Find a Professional</H3></div>

        <CtaWrapper>
          <Button href="/roofing/basics/contact-us" color="black" data-track="find-a-professional"
                  data-track-professional="roofing-sales-representative">Owens Corning Expert</Button>
          <Button href="/roofing/contractors" color="black" data-track="find-a-professional"
                  data-track-professional="contractor">Roofing Contractors</Button>
        </CtaWrapper>
      </Section>
    </AnimatedAttentionBarWrapper>
  )
}

export default AttentionBarRoofing;


import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import Theme from '../../../themes/index';

/*
  Heading components are named for their visual appearance, and will default to using the same tag for their actual HTML output.
  * tag: [h1 - h6] - allows override of HTML tag used in output
  * font: [body, heading] - specifies whether body or heading font should be used

  Heading levels must be imported individually, eg:
  import { h2, h3 } from './oc-h';
*/

const InternalH1 = ({ tag = 'h1', className, children }) => {
  const Tag = `${tag}`;

  return <Tag className={className}>{children}</Tag>;
};

const InternalH2 = ({ tag = 'h2', font, className, underline, children }) => {
  const Tag = `${tag}`;

  return <Tag className={className} undlerline={underline} >{children}</Tag>;
};

const InternalH3 = ({ tag = 'h3', font, className, underline, children }) => {
  const Tag = `${tag}`;

  return <Tag className={className} undlerline={underline} >{children}</Tag>;
};

const InternalH4 = ({ tag = 'h4', font, className, underline, children }) => {
  const Tag = `${tag}`;

  return <Tag className={className} undlerline={underline} >{children}</Tag>;
};

const InternalH5 = ({ tag = 'h5', font, className, underline, children }) => {
  const Tag = `${tag}`;

  return <Tag className={className} undlerline={underline} >{children}</Tag>;
};

const InternalH6 = ({ tag = 'h6', font, className, underline, children }) => {
  const Tag = `${tag}`;

  return <Tag className={className} undlerline={underline} >{children}</Tag>;
};

export const H1 = styled(InternalH1)`
  // This selector exists solely to prevent overrides from global styles in headings.scss
  html body & {
    font-family: ${props =>
      props.font === 'body' ? Theme.fonts.body : Theme.fonts.heading};
    text-transform: ${Theme.text.headingTextTransform};

    .highlight {
      color: ${Theme.colors.brand};
    }
  }
`;

export const H2 = styled(InternalH2)`
  // This selector exists solely to prevent overrides from global styles in headings.scss
  html body & {
    font-family: ${props =>
      props.font === 'body' ? Theme.fonts.body : Theme.fonts.heading};
    font-weight: ${props => (props.font === 'body' ? 700 : 500)};
    text-transform: ${props =>
      props.font === 'body' ? 'none' : Theme.text.headingTextTransform};
    line-height: ${props => (props.font === 'body' ? '30px' : '34px')};
    font-size: ${props => (props.font === 'body' ? '30px' : '32px')};
    ${props => props.underline && css`
      border-bottom: 1px solid black;
      padding-bottom: 4px;
    `}

    @media (min-width: 1200px) {
      line-height: ${props => (props.font === 'body' ? '32px' : '42px')};
      font-size: ${props => (props.font === 'body' ? '32px' : '40px')};
    }
  }

  html:not([lang^='en']) & {
    font-size: ${props => (props.font === 'body' ? '21px' : '28px')};
    line-height: ${props => (props.font === 'body' ? '24px' : '36px')};

    @media (min-width: 1200px) {
      font-size: ${props => (props.font === 'body' ? '24px' : '34px')};
      line-height: ${props => (props.font === 'body' ? '26px' : '42px')};
    }
  }
`;

export const H3 = styled(InternalH3)`
  // This selector exists solely to prevent overrides from global styles in headings.scss
  html body & {
    font-family: ${props =>
      props.font === 'body' ? Theme.fonts.body : Theme.fonts.heading};
    font-weight: ${props => (props.font === 'body' ? 700 : 500)};
    text-transform: ${props =>
      props.font === 'body' ? 'none' : Theme.text.headingTextTransform};
    line-height: ${props => (props.font === 'body' ? '24px' : '26px')};
    font-size: 24px;
    ${props => props.underline && css`
      border-bottom: 1px solid black;
      padding-bottom: 4px;
    `}

    @media (min-width: 1200px) {
      line-height: ${props => (props.font === 'body' ? '28px' : '30px')};
      font-size: 28px;
    }
  }

  html:not([lang^='en']) & {
    line-height: ${props => (props.font === 'body' ? '22px' : '30px')};

    @media (min-width: 1200px) {
      font-size: ${props => (props.font === 'body' ? '21px' : '26px')};
      line-height: ${props => (props.font === 'body' ? '23px' : '30px')};
    }
  }
`;

export const H4 = styled(InternalH4)`
  // This selector exists solely to prevent overrides from global styles in headings.scss
  html body & {
    font-family: ${Theme.fonts.body};
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 5px;
    font-weight: 700;
    color: black;
    ${props => props.underline && css`
      border-bottom: 1px solid black;
      padding-bottom: 4px;
    `}

    @media (min-width: 1200px) {
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }
`;

export const H5 = styled(InternalH5)`
  // This selector exists solely to prevent overrides from global styles in headings.scss
  html body & {
    font-family: ${Theme.fonts.body};
    color: black;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    margin-bottom: 5px;
    text-transform: none;
    ${props => props.underline && css`
      border-bottom: 1px solid black;
      padding-bottom: 4px;
    `}

    @media (min-width: 1200px) {
      font-size: 18px;
      line-height: 18px;
    }
  }
`;

export const H6 = styled(InternalH6)`
  // This selector exists solely to prevent overrides from global styles in headings.scss
  html body & {
    font-family: ${Theme.fonts.body};
    font-weight: 700;
    margin-bottom: 5px;
    text-transform: uppercase;
    color: black;
    font-size: 16px;
    line-height: 16px;
    ${props => props.underline && css`
      border-bottom: 1px solid black;
      padding-bottom: 4px;
    `}
  }
`;

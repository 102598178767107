import * as ACTIONS from "../Actions";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const ENCLOSURE_INITIAL = {
  wallbuilderVisible: true,
  wallbuilderData: { hierarchical: { type: null }, filters: { cladding: null } },
  filtersActive: true
};

export const enclosureReducer = (state = ENCLOSURE_INITIAL, action) => {
  switch (action.type) {
  case ACTIONS.ENCLOSURE_WALLBUILDER_INTERACTION:
    if(!state.filtersActive)
      return state;
    return Object.assign({}, state, {filtersActive: false});
  case ACTIONS.ENCLOSURE_WALLBUILDER_DISPATCH:
    return Object.assign({}, state,
                         {wallbuilderData: action.value},
                         {filtersActive: true},
                         {wallbuilderVisible: false}
                        )
  case ACTIONS.ENCLOSURE_WALLBUILDER_RESET:
    return Object.assign({}, state,
                         {wallbuilderData: ENCLOSURE_INITIAL.wallbuilderData},
                         {filtersActive: true}
                        )
  case ACTIONS.ENCLOSURE_WALLBUILDER_SET_VISIBILITY:
    return Object.assign({}, state, {wallbuilderVisible: action.value})
  case ACTIONS.ENCLOSURE_FILTERS_RESET:
    return Object.assign({}, state,
                         {filtersActive: true},
                         {wallbuilderData: ENCLOSURE_INITIAL.wallbuilderData},
                         {wallbuilderVisible: true})
  case ACTIONS.ENCLOSURE_FILTERS_INTERACTION:
    if(!state.wallbuilderVisible)
      return state;
    return Object.assign({}, state, {wallbuilderVisible: false})
  default:
    return state;
  }
}

const ENCLOSURE = {
  wallbuilderDispatch: ({type, cladding}) => ({
    type: ACTIONS.ENCLOSURE_WALLBUILDER_DISPATCH,
    value: { hierarchical: { type }, filters: { cladding } }
  }),
  wallbuilderReset: () => ({
    type: ACTIONS.ENCLOSURE_WALLBUILDER_RESET
  }),
  wallbuilderInteraction: () => ({
    type: ACTIONS.ENCLOSURE_WALLBUILDER_INTERACTION
  }),
  wallbuilderSetVisibility: (value) => ({
    type: ACTIONS.ENCLOSURE_WALLBUILDER_SET_VISIBILITY,
    value
  }),
  filtersInteraction: () => ({
    type: ACTIONS.ENCLOSURE_FILTERS_INTERACTION
  }),
  filtersReset: () => ({
    type: ACTIONS.ENCLOSURE_FILTERS_RESET
  })
};

export const wallbuilderConnector = connect(
  (state) => ({
    wallbuilderVisible: state.enclosure.wallbuilderVisible,
    externalFilters: state.enclosure.wallbuilderData,
    showOverlay: !state.enclosure.filtersActive,
  }),
  (dispatch) => bindActionCreators(ENCLOSURE, dispatch)
);

import { appendAspectRatio, migrateArticleDataSetParameters, migrateDataSetParameters } from '../../../migrations';
import { unwrapRef, wrapRef } from '../../../../../data';

export default {
  displayName: 'Item List',
  allowableParents: ['Section'],
  migrator: (contents) => {
    contents.data = migrateDataSetParameters(contents.data, contents.data?.dataset)
    if (contents.data?.dataset === 'products_roofing' && !contents.data?.parameters?.products_roofing.attributeSet) {
      contents.data.parameters = contents.data.parameters || {}
      contents.data.parameters.products_roofing = contents.data.parameters.products_roofing || {}
      contents.data.parameters.products_roofing.attributeSet = {
        name: 'Roofing PDP Attributes',
        path: 'roofing'
      }
      if (contents.data.reference) {
        const [type, value, data] = unwrapRef(contents.data.reference) || [];
        value.parameters.attributeSet = contents.data.parameters.products_roofing.attributeSet;
        contents.data.reference = wrapRef(type, value, data);
      }
    } else if (contents.data?.dataset === 'iss' || contents.data?.dataset === 'roofing_blog') {
      contents.data = migrateArticleDataSetParameters(contents.data, contents.data.dataset)
    }
    return contents;
  },
};

import { define, ui, when } from '@owenscorning/pcb.alpha';

import { wrapRef } from '../../../data';
import useReference from '../../../hooks/use_reference';

export default define`Attribute`('0.0.1')({
  view: () => null,
  edit: ui`Search`({
    startOpen: when`.`.isnt.present.then(true).otherwise(false),
    label: 'Attribute',
    dataset: 'attributes',
    set: value => {
      const ref = wrapRef('Cms::Content', {
        type: 'Attribute',
        id: value.content_uuid,
      });
      return ref;
    },
    get: value => {
      const { results, error, loading } = useReference(value);
      if (loading) {
        return <i>Loading...</i>;
      }
      if (error) {
        return <span>{error}</span>;
      }
      return results;
    },
  }),
});

import { getZipInfo } from "../locales";

export default function trySubmitZip(
  locale,
  zip,
  postalRequired,
  onSuccess,
  setError
) {
  const localeCode = locale.code;
  const zipInfo = getZipInfo(localeCode);
  const shouldShowZip = zipInfo && postalRequired;
  if (shouldShowZip) {
    if (zipInfo.validator(zip, postalRequired)) {
      fetch(`${MDMS_URL}/api/v1/validzip?zip=${zip}&c_code=${localeCode}`)
        .then(result => result.json())
        .then(data => {
          if (data.valid) {
            onSuccess(locale, data.zip_code || zip);
          } else {
            setError(true);
          }
        })
        .catch(error => console.error('Error:', error));
    } else {
      setError(true);
    }
  } else {
    onSuccess(locale, zip);
  }
}

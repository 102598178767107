import GwpCalculatorContainer from '../../Insulation/FoamularNgx/GwpCalculatorContainer';
import BasicContent from "./BasicContent";


const NGXCalculator = ({ copy }) => {
  return (
    <>
      <BasicContent content={ copy } />
      <GwpCalculatorContainer />
    </>
  )
};

export default NGXCalculator;

import { define, ui, when } from '@owenscorning/pcb.alpha';

import Renderer from './../../../../OC/PageBuilder/FeaturedList.jsx';
import placeholder_url from '../../../../../placeholder';

const Edit = (editOptions) => ({
  size: ui`Choices`.of({
    xl: 'XL',
    l: 'Large',
    m: 'Medium',
  })({
    label: 'Container Size',
    default: editOptions?.size?.default || 'xl',
    mode: ui`Choices/Mode/Dropdown`
  }),
  alignment: ui`Choices`.of({'left': 'Left', 'center': 'Center'})({
    label: 'Alignment',
    default: 'center',
    visible: when`../size`.is.equal.to('xl').then(false).otherwise(true)
  }),
  copy: [ { heading: ui`Text`({ label: 'Heading', default: 'Heading' }) }, { label: 'Copy' } ],
  mediaObjects: ui`List`.of(ui`MediaObject`)({
    imageSizeInfo: ui`ImageSizeInfo`(
      {
        moduleName: 'FeaturedList'
      },
    ),
    min: 2, max: 5,
    singular: 'Media Object',
    title: 'heading',
    label: 'Media Objects'
  }),
  card: [ {
    imageSizeInfo: ui`ImageSizeInfo`(
      {
        moduleName: 'Featured List'
      },
    ),
    img: ui`Image`({ crop:true, defaultImage: placeholder_url(340,190) }),
    prehead: ui`Text`({ label: 'Prehead' }),
    heading: ui`Text`({ label: 'Heading' }),
    text: ui`Text/Rich`({ label: 'Body Copy' }),
    linkText: ui`Text`({ label: 'Link Text' }),
    url: ui`Text`({ label: 'URL' })
  }, {
    label: 'Card'
  } ],
  settings: ui`Form`.of({
    aspectRatio: ui`AspectRatio`({ label: 'Aspect Ratio of Media Objects', default: { selection: '1' } }),
    aspectRatioCard: ui`AspectRatio`({ label: 'Aspect Ratio of Card' }),
  })({
    label: 'Layout'
  }),
});

export { Edit };
export default define`Featured List`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/FeaturedList`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value }) => <Renderer { ...value } />,
  edit: Edit()
});

const getExtension = (url) => {
  try {
  const [_, extension, __] = url.match(/(\.[a-z0-9]{3,})(\?.+)*$/) || []
  return extension
  } catch (e) {
    // console.log('Failed to get file extension', e)
    return null
  }
}

export default getExtension

import { define } from '@owenscorning/pcb.alpha';

export default define`Clamp`('0.0.1')({
  raw: () => (ref) => {
    const offset = { x: 0, y: 0 };
    if (!ref.current) return offset;

    const box = ref.current?.getBoundingClientRect();
    if (box.x < 0) offset.x = Math.abs(box.x);
    if (box.y < 0) offset.y = Math.abs(box.y);
    if (box.x + box.width > window.innerWidth) offset.x = -( box.x + box.width - window.innerWidth );
    if (box.y + box.height > window.innerHeight) offset.y = -( box.y + box.height - window.innerHeight );

    return offset;
  }
});

import styled from '@emotion/styled';
import React, {useState, useEffect, useRef, useContext} from 'react';

// Components
import CalculatorForm from './AtticatForm';
import CalculatorResults from './AtticatResults';
import {connectLocation} from "../../location/LocationConnectors";
import LocationModalOpener from '../../ComponentLibrary/oc-location-modal-opener';
import useValidZip from '../../../hooks/use_valid_zip';
import Text from '../../OC/oc-text';

const CalculatorContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;

	@media(min-width: 768px) {
		flex-direction: row;
	}
`;

const CalcFormContainer = styled.div`
  background: #e6e6e6;
	background-size: 250px;
	padding: 15px 15px 165px;
	width: 100%;

	@media(min-width: 768px) {
		width: 35.333%;
		background-size: 150px;
		padding: 15px 15px 100px;
	}

	@media(min-width: 1200px) {
		background-size: 250px;
	}
`;

const CalcResultsContainer = styled.div`
	width: 100%;

	@media(min-width: 768px) {
		width: 66.666%
	}
`;

const LocationContainer = styled.div`
  margin-bottom: 25px;
  margin-top: 25px;

  span {
    font-size: 28px;
    font-weight: bold;
    color: #000;
    margin-right: 10px;
  }
`;

const TextWrapper = styled.css`
  color: gray!important;
  font-size: 19px!important;
`;

const AtticatCalculator = connectLocation(({t, zip, locale}) => {
  const { valid, city, region } = useValidZip(zip, locale.code);
  const default_measurement_system = 'imperial';
  const r_value = ['en-CA', 'fr-CA'].includes(locale?.code) ? '80' : '60';
	const [post, setPost] = useState({['measurementSystem']: default_measurement_system, ['insulationProgress']: "0", ['RValue']: r_value});
	const [info, setInfo] = useState({});
	const [resultsVisible, setResultsVisible] = useState(false);
	const scrollToDiv = useRef();

  const coverageByRValueCA = {
    "12": 162.0,
    "16": 121.5,
    "20": 97.2,
    "24": 81.0,
    "28": 69.4,
    "32": 60.7,
    "36": 53.2,
    "40": 47.4,
    "44": 42.6,
    "48": 38.8,
    "50": 37.2,
    "52": 35.5,
    "56": 32.9,
    "60": 30.5,
    "64": 28.5,
    "68": 26.7,
    "70": 25.9,
    "72": 25.1,
    "76": 23.7,
    "80": 22.3
  }

  const coverageByRValueUS = {
    "13": 169.49,
    "19": 111.11,
    "22": 95.24,
    "26": 79.36,
    "30": 68.49,
    "38": 52.63,
    "44": 44.64,
    "49": 40,
    "60": 31.75
  }

  const blownInchesByRValueCA = {
    "12": 4.5,
    "16": 6.0,
    "20": 7.4,
    "24": 8.9,
    "28": 10.4,
    "32": 11.9,
    "36": 13.3,
    "40": 14.6,
    "44": 16.0,
    "48": 17.4,
    "50": 18.0,
    "52": 18.8,
    "56": 20.1,
    "60": 21.5,
    "64": 22.9,
    "68": 24.2,
    "70": 24.9,
    "72": 25.6,
    "76": 26.9,
    "80": 28.5
  }

  const blownInchesByRValueUS = {
    "13": 5,
    "19": 7,
    "22": 8,
    "26": 9.5,
    "30": 10.75,
    "38": 13.5,
    "44": 15.5,
    "49": 17,
    "60": 20.5
  }

	useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler); 
  }, []);
    
  const scrollHandler = () => {
    if(window.pageYOffset + window.innerHeight >= scrollToDiv.current.offsetTop) {
    	setResultsVisible(true);
    } else {
    	setResultsVisible(false);
    }
  }

	const inputChange = (e) => {
		const { name, value } = e.target;
    if(name == 'measurementSystem') {
      setPost(prevState => (
        { ...prevState, [name]: value, ['insulationProgress']: '0' }
      ));
    } else {
	  	setPost(prevState => (
        { ...prevState, [name]: value }
      ));
	  }
	};

	const formSubmit = (e) => {
		e.preventDefault();

    const validLength = post.length && !isNaN(post.length) && post.length > 0;
    const validWidth = post.width && !isNaN(post.width) && post.width > 0;
    const validAtticArea = post.atticArea && !isNaN(post.atticArea) && post.atticArea > 0

    const validAttic = (validLength && validWidth) ^ validAtticArea;

		if(validAttic) {
      let blown_inches = ['en-CA', 'fr-CA'].includes(locale?.code) ? blownInchesByRValueCA : blownInchesByRValueUS;
      let coverage = ['en-CA', 'fr-CA'].includes(locale?.code) ? coverageByRValueCA : coverageByRValueUS;
      let targetTotalInchesOfInsulation = post.measurementSystem == 'metric' ? blown_inches[post.RValue] * 2.54 : blown_inches[post.RValue];
      let inchesOfInsulationNeeded = targetTotalInchesOfInsulation - parseInt(post.insulationProgress);
      let square_feet = validAtticArea ? post.atticArea : post.length * post.width;
      square_feet = post.measurementSystem == 'metric' ? square_feet * 10.7639 : square_feet;
      let bagsOfAttiCatNeeded = Math.ceil(square_feet / coverage[post.RValue]);

      setInfo(prevState => (
	      { ...prevState, 
	      	['targetTotalInchesOfInsulation']: targetTotalInchesOfInsulation,
	      	['inchesOfInsulationNeeded']: inchesOfInsulationNeeded,
	      	['bagsOfAttiCatNeeded']: bagsOfAttiCatNeeded,
          ['measurementSystem']: post.measurementSystem,
	      }
	    ));

	    setPost(prevState => ({
			  ...prevState,
			  ['atticError']: null
			}));

	    const element = scrollToDiv.current;
		  const headerOffset = 110;
		  const elementPosition = element.getBoundingClientRect().top;
		  const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

	    {!resultsVisible &&
		    window.scrollTo({
		      top: offsetPosition,
		      behavior: "smooth"
		    });
	    }
	  
	  } else {
	  	setPost(prevState => ({
			  ...prevState,
			  ['atticError']: validAttic ? null : t('atticat.attic_error'),
			}));
	  }
	};

	return (
    <>
      <TextWrapper><Text content={t('atticat.title')} /></TextWrapper>
      <h2>{t('atticat.heading')}</h2>
      <p>{t('atticat.description')}</p>
      <LocationContainer>
        {valid && <span>{t('atticat.locator_recommendation')}{city}, {region}</span>}
        {!valid && <span tag="h2" font="body">{t('generic_locator.invalid_zip')}</span>}
        <LocationModalOpener ariaLabel="change zipcode" dataTrack="change-zip-open" dataTrackModalName="Region Selector" text={t('atticat.locator_change')} />
      </LocationContainer>
      <CalculatorContainer>
        <CalcFormContainer>
          <CalculatorForm inputChange={inputChange} post={post} formSubmit={formSubmit}/>
        </CalcFormContainer>

        <CalcResultsContainer ref={scrollToDiv} >
          <CalculatorResults 
            targetTotalInchesOfInsulation={info.targetTotalInchesOfInsulation}
            inchesOfInsulationNeeded={info.inchesOfInsulationNeeded}
            bagsOfAttiCatNeeded={info.bagsOfAttiCatNeeded}
            measurementSystem={info.measurementSystem}
          />
        </CalcResultsContainer>
      </CalculatorContainer>
    </>
	)
});

export default AtticatCalculator;

import { useState } from "react";
import { debounce } from "lodash";
import styled from "@emotion/styled";
import Theme from "../../../../themes";

// Components
import Input from "../../../ComponentLibrary/input-elements/oc-input-base";
import InsulationSearchResult from "./PIM/Insulation/SearchResult";
import ParocSearchResult from "./PIM/Paroc/SearchResult";

// Utilities
import { wrapRef } from '../../../../data';
import { Loader } from "../../../BuildYourRoof/loading"
import useReference from '../../../../hooks/use_reference';
import placeholder_url from "../../../../placeholder";

// Styled Components
const InputContainer = styled.div`
  display: inline-block;
  margin-top: 8px;
  position: relative;
  width: 100%;

  input + div {
    > div {
      top: 0;
    }
  }
`;

const LoaderContent = styled(Loader)`
  align-items: first baseline;
  background-color: transparent;
  justify-content: flex-end;
  padding-right: 16px;
  padding-top: 45px;
  z-index: 1;
`;

const ResultsContainer = styled.div`
  border-bottom: 1px solid #585858;
  border-left: 1px solid #585858;
  border-right: 1px solid #585858;
  display: inline-block;
  padding: 8px 0;
  position: relative;
  width: 100%;
`;

const ResultItem = styled.a`
  color: #000000;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 17px;
  padding: 8px 16px;
  text-decoration: none;

  &.linked {
    color: #6D6D6D;
    cursor: not-allowed;
  }

  .product-group {
    font-size: 16px;
    line-height: 18px;
  }
`;

const NoResult = styled(ResultItem)`
  cursor: default;

  &:hover {
    color: inherit;
  }
`;

const SelectedProduct = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: ${props => props.padding};
  position: relative;
`;

const ProductTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: ${props => props.margin || 0};
  width: 100%;
`;

const ProductInfo = styled.div`
  display: flex;
  margin-bottom: 8px;
  position: relative;
`;

const ProductImage = styled.img`
  display: inline-block;
  flex-shrink: 0;
  height: 88px;
  margin-right: 16px;
  position: relative;
  width: 88px;
`;

const ProductItem = styled(ResultItem)`
  padding: 0;
`;

const ProductData = ProductItem.withComponent("div");

const ProductLinks = styled.div`
  align-items: center;
  display: flex;
  padding-left: 104px;
  width: 100%;

  a {
    border-right: 1px solid #333333;
    cursor: pointer;
    font-size: 14px;
    margin-right: 16px;
    padding-right: 16px;

    &:last-of-type {
      border: 0;
      padding: 0;
    }

    &:hover {
      color: ${Theme.colors.brand};
    }
  }
`;

const searchResultMapping = {
  pim_insulation: InsulationSearchResult,
  pim_paroc: ParocSearchResult
};

const LinkPIM = ({
  language,
  onChange,
  onChangeProduct,
  path,
  pimSource,
  product = null,
  displayChangeLinks = true,
  style,
  ...props
}) => {
  const [selectedProduct, setSelectedProduct] = useState(product);
  const [searchTerm, setSearchTerm] = useState("");
  const SearchResultComponent = searchResultMapping[pimSource];
  let reference = null;

  if (searchTerm && searchTerm.length > 0) {
    reference = wrapRef(
      'Cms::DataProvider',
      {
        type: "ItemList",
        dataset: "bim_product_groups",
        parameters: { filters: { id: searchTerm }, linked_source: pimSource },
        language: language || 'en-US'
      }
    );
  }

  const { results, error, loading } = useReference(reference);
  const resultData = results?.data || [];

  const debouncedSearch = debounce(query => {
    if (!query) return false;
    setSearchTerm(query);
  }, 500);

  const selectProduct = (product) => {
    if (product.isAlreadyLinked) return false;
    onChange(product);
    onChangeProduct(product, path);
    setSelectedProduct(product);
  };

  const changeProduct = () => {
    setSelectedProduct(null);
  };

  const removeProduct = () => {
    onChange({});
    onChangeProduct(null, path);
    setSelectedProduct(null);
    setSearchTerm("");
  };

  if (selectedProduct) {
    let productImageUrl = selectedProduct?.metadata?.connection?.image || selectedProduct?.productImage?.[0]?.key || placeholder_url(88,88);
    return(
      <SelectedProduct padding={style?.selectedItemPadding}>
        <ProductTitle margin="16px">PIM Product</ProductTitle>
        <ProductInfo>
          <ProductImage src={productImageUrl} />
          <ProductData>
            <SearchResultComponent result={selectedProduct?.metadata?.connection || selectedProduct} unwrapped />
          </ProductData>
        </ProductInfo>
        {displayChangeLinks &&
          <ProductLinks>
            <a onClick={() => changeProduct()}>Change</a>
            <a onClick={() => removeProduct()}>Remove</a>
          </ProductLinks>
        }
      </SelectedProduct>
    )
  }

  return (
    <InputContainer>
      <Input
        defaultValue={searchTerm}
        label="Search Products"
        onChange={(event) => debouncedSearch(event.target.value)}
        required
      />
      { (searchTerm.length > 0) && loading && <LoaderContent /> }

      { !loading && results && !error &&
        <ResultsContainer>
          {(resultData.length === 0) &&
            <NoResult>
              <span>No results found for <strong>{searchTerm}</strong></span>
            </NoResult>
          }

          {resultData.slice(0, 8).map((result, index) =>
            <SearchResultComponent key={index} result={result} selectProduct={selectProduct} />
          )}
        </ResultsContainer>
      }
      <UI.Tip noTopMargin>Search by Product Group ID</UI.Tip>
    </InputContainer>
  )
};

LinkPIM.displayName = 'Build.LinkPIM';

export default LinkPIM;

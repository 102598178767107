
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';

import _ from 'lodash';

const Ring = styled.div`
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    box-sizing: border-box;
    position: absolute;
    left: 0px;
    top: 0px;

    ${(props) => (
      {
        [Flags.Status.Published]: css`
          background-color: ${ UI.Theme.colors.published };
        `,
        [Flags.Status.Unpublished]: css`
          background-color: #E6E6E6;
          border: 0.5px solid #959595;
        `,
        [Flags.Status.Draft]: css`
          border: 2px solid #DBA400;
        `
      }[props.type]
    )}
  }

  ${(props) => {
    if (props.split != -1) {
      return css`
        width: 4px;
        ${ props.split == 1 && css`
          &:after {
            left: -4px;
          }
        `}
      `;
    }
  }}
`;

const Label = styled.div`
  margin-left: 4px;
  font-size: 12px;
`;

const Flags = ({ list }) => list.map((flag) => (
  <Flag
    key={ flag[0] }
    styling={css` margin-right: 16px; font-size: 12px; `}
    status={ flag[0] }
    label={ flag[1] }
    title={ flag[2] }
  />
));

Flags.Status = {
  Published: 'Published',
  Unpublished: 'Unpublished',
  Draft: 'Draft',
  Redirected: 'Redirected',
  Warning: 'Warning',
};

const Components = {
  [Flags.Status.Warning]: 'WarningIcon',
  [Flags.Status.Redirected]: 'RedirectIcon',
  [Flags.Status.Published]: 'Ring',
  [Flags.Status.Unpublished]: 'Ring',
  [Flags.Status.Draft]: 'Ring',
}

const Flag = ({ status, label=undefined, styling, title }) => {
  status = _.castArray(status).slice(0, 2);
  if (label === undefined) label = status.join(' with ');

  return (
    <div css={[
      css` display: flex; align-items: center; `,
      ..._.castArray(styling)
    ]} title={ title }>
      <div css={css` display: flex; `} >
        { status.map((type, index) => {
          switch (Components[type]) {
            case 'Ring':
              return ( <Ring key={ index } type={ type } split={ status.length >= 2 ? index : -1 } /> )
            case 'WarningIcon':
              return ( <UI.Icon key={ index } type="exclamation-triangle" color="warning" /> )
            case 'RedirectIcon':
              return ( <UI.Icon key={ index } type="share" color="published" /> )
          }
        }) }
      </div>
      { label && <Label>{ label }</Label> }
    </div>
  );
};

export { Flag, Flags };
export default Flags;

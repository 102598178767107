
import { css, jsx } from '@emotion/react';
import styled from "@emotion/styled";

import _ from 'lodash';

import {
  Editor,
  EditorState,
  EditorBlock,
  CompositeDecorator,
  convertFromRaw
} from 'draft-js';

import { DraftUtils } from '../../PageBuilder/helpers/draft';
import 'draft-js/dist/Draft.css';

import Icon from '../oc-icon';
import Link from '../oc-link';

const DocumentFileTypes = [ 'pdf', 'powerpoint', 'video', 'audio', 'text', 'excel', 'image', 'word', 'code', 'archive' ];
const INTERNAL_URL_REGEX = /^(https?:\/\/|)((w\.)|(www\.)|(w-devel\.)|(w-stage\.)|)owenscorning\.com/;

const RichTextStyle = {
  Draft: css`
    .public-DraftEditor-content { padding: 8px; & > div { margin-top: -1em; } }
    .public-DraftStyleDefault-ltr { margin: 1em 0; }
  `,
  Styles: {
    SUPERSCRIPT: { fontSize: '0.8em', verticalAlign: 'super' },
    SUBSCRIPT: { fontSize: '0.8em', verticalAlign: 'sub' },
    PINK: { color: '#d40f7d' }
  },
  Blocks: {
    'header-two-oswald': { component: (props) => <h2 {...props} >{ props.children }</h2> },
    'header-two-roboto': { component: (props) => <h2 {...props} className="body-font" >{ props.children }</h2> },
    'header-three-oswald': { component: (props) => <h3 {...props} >{ props.children }</h3> },
    'header-three-roboto': { component: (props) => <h3 {...props} className="body-font" >{ props.children }</h3> },
    'header-four-roboto': { component: (props) => <h4 {...props} >{ props.children }</h4> },
    'header-five-roboto': { component: (props) => <h5 {...props} >{ props.children }</h5> },
    'header-six-roboto': { component: (props) => <h6 {...props} >{ props.children }</h6> },
    'small-body-copy': {
      component: (props) => <span {...props} >{ props.children }</span>,
      css: css` font-size: 14px; `
    },
    disclaimer: {
      component: (props) => <span {...props} >{ props.children }</span>,
      css: css` font-size: 12px; `
    },
    document: {
      component: (props) => {
        return (
          <div {...props} >
            <Link
              target="_blank"
              href={ `/dms/${ props.document }` }
              analytics={{ 'element-location': 'main section text link' }}
              target="_blank"
              css={css`
                text-decoration: none;
                &:hover {
                  .download-link-text {
                    text-decoration: underline;
                  }
                }
              `}
              contentEditable={ false }
              readOnly
            >
              <Icon
                type={ `file${ DocumentFileTypes.includes(_.toLower(props.type)) ? `-${ props.type }` : '' }-o` }
                css={css`
                  text-decoration: none;
                  padding-right: 10px;
                `}
              />
              <span className="download-link-text" >{ props.text }</span>
              <span css={css`
                text-decoration: none;
                font-weight: 300;
                font-size: 12px;
                padding-left: 8px;
              `} >{ _.toUpper(props.type) } | { props.size }</span>
            </Link>
          </div>
        );
      }
    }
  },
  Entities: {
    LINK: (props) => {
      let url = props.contentState.getEntity(props.entityKey).getData().url;
      let relative = true;

      if (!url.startsWith('mailto:') && !url.startsWith('tel:')) {
        if (!url.match(/^[a-zA-Z]+:\/\//) && url.split('/')[0].includes('.')) url = `http://${ url }`;
        if (!url.match(/^[a-zA-Z]+:\/\//) && !url.startsWith('/')) url = `/${ url }`;
        relative = url.includes(window.location.hostname) || url.match(INTERNAL_URL_REGEX) || !url.match(/^[a-zA-Z]+:\/\//);
        if (url.includes('dms/')) relative = false;
      }

      return (
        <Link
          href={ url.replace(INTERNAL_URL_REGEX, '') || 'https://www.owenscorning.com/' }
          analytics={{ 'element-location': 'main section text link' }}
          target={ relative ? null : '_blank' }
        >
          { props.children }
        </Link>
      );
    }
  }
};

const BlockRenderer = (editable) => (contentBlock) => {
  const Type = contentBlock.getType();
  const Data = contentBlock?.getData()?.toObject() || {};
  const Block = RichTextStyle.Blocks[Type === 'atomic' ? Data?.type : Type];
  if (!Block) return;
  return {
    component: (props) => (
      <Block.component css={ Block?.css } { ...Data?.props } >
        <EditorBlock { ...props } />
      </Block.component>
    ),
    editable: editable,
    props: {}
  };
};

const EntityRenderer = new CompositeDecorator(_.map(RichTextStyle.Entities, (component, type) => ({
  strategy: (block, callback, state) => ( DraftUtils.Entities.all(block, callback, state, type) ),
  component: component
})));

const RichTextWrapper = styled.div`
  ${ (props) => !props.inline && css` margin: 0.96em 0; ` }
  & .public-DraftEditor-content > div > *:first-child { margin-top: 0 !important; }
  & .public-DraftEditor-content > div > *:last-child { margin-bottom: 0 !important; }
  & .DraftEditor-editorContainer { border: 0 !important; }
  & figure { margin-left: 0px !important; margin-right: 0px !important; }
`;

export { RichTextStyle, RichTextWrapper, BlockRenderer, EntityRenderer, DocumentFileTypes };
export default ({ content, inline=false }) => {
  let render = {};

  try {
    render.content = JSON.parse(content);
    if (_.isPlainObject(render.content)) {
      render.isHTML = false;
    } else {
      render.content = (content||'').toString();
      render.isHTML = true;
    }
  } catch(e) {
    render.content = content;
    render.isHTML = true;
  }

  if (!render.isHTML) {
    render.editor = EditorState.createWithContent(
      convertFromRaw(render.content),
      EntityRenderer
    );
  }

  if (!render.content) return null;
  if (render.isHTML) return <RichTextWrapper inline={ inline } dangerouslySetInnerHTML={{ __html: render.content }} />;
  return (
    <RichTextWrapper inline={ inline } >
      <Editor
        editorState={ render.editor }
        customStyleMap={ RichTextStyle.Styles }
        blockRendererFn={ BlockRenderer(false) }
        readOnly
      />
    </RichTextWrapper>
  );
};

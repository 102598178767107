import { define } from '@owenscorning/pcb.alpha';
import { css } from '@emotion/react';

const Flags = {
  Parse: (props, overrides={}) => {
    const flags = { ...Flags, ...overrides };
    return _.reduce(props, (result, prop, key) => flags[key] ? `${ result } fa-${ flags[key] === true ? key : flags[key] }` : result, '');
  },
  'rotate-90': true,
  'rotate-180': true,
  'rotate-270': true,
  'flip-h': 'flip-horizontal',
  'flip-v': 'flip-vertical',
  spin: true,
  pulse: true,
  fixed: 'fw',
  large: 'lg',
  x2: '2x',
  x3: '3x',
  x4: '4x',
  x5: '5x'
};

const Icon = ({ image, type, overrides, ...props }) => {
  // If not an image, return fontawesome tag
  if (!image) return (<i className={`fa fa-${ type }${ Flags.Parse(props, overrides) }`} aria-hidden="true" />);

  // If image, return img tag or icon component
  if (_.isString(image)) return (<img css={css`width: 100%;`} src={image} aria-hidden="true" />);
  return image;
};

export default define`Icon`('0.0.1')({
  prepare: () => UI.Theme.Taggable(({ action, type, stack, ...props }) => {
    const Inside = stack
      ? <span className={ `fa-stack${ Flags.Parse(props) }` } >
        { _.map(stack, (type) => {
          const parameters = type.split(' ');
          const sizes = {
            large: 'stack-lg',
            x1: 'stack-1x',
            x2: 'stack-2x',
            x3: 'stack-3x',
            x4: 'stack-4x',
            x5: 'stack-5x'
          };
          if (!_.intersection(_.keys(sizes), parameters).length) parameters.push('x1');
          return <Icon
            type={ parameters[0] }
            { ...UI.Theme.Taggable.Parse(_.tail(parameters)) }
            overrides={ sizes }
            { ...props }
          />;
        } ) }
      </span>
      : <span { ...props } >
        <Icon type={ type } { ...props } />
      </span>;

    return action ? <UI.Button action={ action } { ...props } >{ Inside }</UI.Button> : Inside;
  })
});

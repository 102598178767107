import { define, ui, when } from '@owenscorning/pcb.alpha';
import Renderer from '../../../../TPRS/TPRSScroller';

export default define`TPRS Scroller`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/Table`, // TO-DO need to update the thumbnail
  category: ui`Modules/Categories/Page/Prebuilt`,
  view: ({ value }) => <Renderer {...value} />,
  edit: {
    editable: ui`Switch`({
      label: 'Editable',
      default: false
    }),
    sectionContent: ui`Form`.of({
      seal: ui`List/Item`.of(ui`Form`.of({
        descriptionHeading: ui`Text`({
          label: "Seal Description Heading",
          default: "Helps Create a Water-Proof Barrier"
        }),
        description: ui`Text/Rich`({
          label: "Seal Description",
          default: "Block water resulting from ice damming, wind-driven rains and normal water flow. A properly sealed roof helps prevent rot and mold."
        }),
        bullet1Heading: ui`Text`({
          label: "Bullet 1 Heading",
          default: "Ice & Water Barrier"
        }),
        bullet1Content: ui`Text/Rich`({
          label: "Bullet 1 Content",
          default: {
            ops: [
              { insert: "Helps protect the roof where water has a tendency to collect or flow, including valleys, eaves, chimneys and skylights." },
              { attributes: { list: "bullet" }, insert: "\n" },
              { insert: "Helps prevent damage associated with shingle blow-off, wind-driven rain and ice damming." },
              { attributes: { list: "bullet" }, insert: "\n" },
              {
                attributes: { link: `${location.origin}/roofing/components/products?category[]=ice-water-barrier` },
                insert: "Explore Ice & Water Barrier"
              }
            ]
          }
        }),
        bullet2Heading: ui`Text`({
          label: "Bullet 2 Heading",
          default: "Synthetic Underlayment"
        }),
        bullet2Content: ui`Text/Rich`({
          label: "Bullet 2 Content",
          default: {
            ops: [
              { insert: "Provides a water shedding barrier under shingles for your roof's last line of defense." },
              { attributes: { list: "bullet" }, insert: "\n" },
              { insert: "Helps guard against damage from wind-driven rain." },
              { attributes: { list: "bullet" }, insert: "\n" },
              {
                attributes: { link: `${location.origin}/roofing/components/products?category[]=underlayment` },
                insert: "Explore Synthetic Underlayment"
              }
            ]
          }
        })
      }))({
        compact: true,
        title: "Seal",
        standalone: true
      }),
      defend: ui`List/Item`.of(ui`Form`.of({
        descriptionHeading: ui`Text`({
          label: "Defend Description Heading",
          default: "Helps Protect Against Nature's Elements"
        }),
        description: ui`Text/Rich`({
          label: "Defend Description",
          default: "Add a tough, yet beautiful layer of defense with strong adhesion that resists blow offs and helps shed water away from the roof deck. Helps protect the most vulnerable areas such as eaves and peaks."
        }),
        bullet1Heading: ui`Text`({
          label: "Bullet 1 Heading",
          default: "Starter Shingles"
        }),
        bullet1Content: ui`Text/Rich`({
          label: "Bullet 1 Content",
          default: {
            ops: [
              { insert: "Ensure a straight edge and effective seal along the eaves and rake—areas that are vulnerable to high winds." },
              { attributes: { list: "bullet" }, insert: "\n" },
              {
                attributes: { link: `${location.origin}/roofing/components/products?category[]=starter-shingles` },
                insert: "Explore Starter Shingles"
              }
            ]
          }
        }),
        bullet2Heading: ui`Text`({
          label: "Bullet 2 Heading",
          default: "Shingles"
        }),
        bullet2Content: ui`Text/Rich`({
          label: "Bullet 2 Content",
          default: {
            ops: [
              { insert: "Long-lasting protection and enhanced appearance for your roof's first line of defense." },
              { attributes: { list: "bullet" }, insert: "\n" },
              { insert: "Provide protection and curb appeal in a wide array of colors and styles." },
              { attributes: { list: "bullet" }, insert: "\n" },
              {
                attributes: { link: `${location.origin}/roofing/shingles` },
                insert: "Explore Shingles"
              }
            ]
          }
        }),
        bullet3Heading: ui`Text`({
          label: "Bullet 3 Heading",
          default: "Hip & Ridge Shingles"
        }),
        bullet3Content: ui`Text/Rich`({
          label: "Bullet 3 Content",
          default: {
            ops: [
              { insert: "Add extra protection and stylish dimension along the hips and ridge—areas that are vulnerable to high winds." },
              { attributes: { list: "bullet" }, insert: "\n" },
              { insert: "Designed to match and complement Owens Corning® shingles." },
              { attributes: { list: "bullet" }, insert: "\n" },
              {
                attributes: { link: `${location.origin}/roofing/components/products?category[]=hip-ridge-shingles` },
                insert: "Explore Hip & Ridge Shingles"
              }
            ]
          }
        }),
      }))({
        compact: true,
        title: "Defend",
        standalone: true
      }),
      breathe: ui`List/Item`.of(ui`Form`.of({
        descriptionHeading: ui`Text`({
          label: "Breathe Description Heading",
          default: "Optimize Airflow in your Attic"
        }),
        description: ui`Text/Rich`({
          label: "Breathe Description",
          default: "Reduces heat and moisture buildup that can lead to ice damming, roof deterioration, and mold infestation. This helps air flow through the attic to manage temperature and moisture."
        }),
        bullet1Heading: ui`Text`({
          label: "Bullet 1 Heading",
          default: "Intake Ventilation"
        }),
        bullet1Content: ui`Text/Rich`({
          label: "Bullet 1 Content",
          default: {
            ops: [
              { insert: "Proper ventilation helps create a balanced flow where warm, humid air is continually replaced by cool, dry air." },
              { attributes: { list: "bullet" }, insert: "\n" },
              {
                attributes: { link: `${location.origin}/roofing/components/products?category[]=intake-ventilation` },
                insert: "Explore Intake Ventilation"
              }
            ]
          }
        }),
        bullet2Heading: ui`Text`({
          label: "Bullet 2 Heading",
          default: "Exhaust Ventilation"
        }),
        bullet2Content: ui`Text/Rich`({
          label: "Bullet 2 Content",
          default: {
            ops: [
              { insert: "Helps manage attic temperature and humidity to help protect the interior components of your roof from heat and moisture damage and reduce the likelihood of problems such as ice damming." },
              { attributes: { list: "bullet" }, insert: "\n" },
              {
                attributes: { link: `${location.origin}/roofing/components/products?category[]=exhaust-ventilation` },
                insert: "Explore Exhaust Ventilation"
              }
            ]
          }
        })
      }))({
        compact: true,
        title: "Breathe",
        standalone: true
      }),
      comfort: ui`List/Item`.of(ui`Form`.of({
        descriptionHeading: ui`Text`({
          label: "Breathe Description Heading",
          default: "Optimize Value, Comfort and Performance"
        }),
        description: ui`Text/Rich`({
          label: "Breathe Description",
          default: "Optimize value, comfort and performance by adding attic insulation to meet current Department of Energy recommendations."
        }),
        bullet1Heading: ui`Text`({
          label: "Bullet 1 Heading",
          default: "Blow-In Insulation"
        }),
        bullet1Content: ui`Text/Rich`({
          label: "Bullet 1 Content",
          default: {
            ops: [
              { insert: "Creates a buffer between the comfortable air inside the living area and extreme heat or cold outside." },
              { attributes: { list: "bullet" }, insert: "\n" },
              {
                attributes: { link: `${location.origin}/insulation/residential/products/attics?category[]=fiberglass-insulation&producttype[]=insulation` },
                insert: "Explore Blow-In Insulation"
              }
            ]
          }
        }),
      }))({
        compact: true,
        title: "Comfort",
        standalone: true
      })
    })({
      label: "Section Content",
      visible: when`../editable`.is.equal.to(true)
    }),
  },
});

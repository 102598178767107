import * as React from 'react';
import { css, cx } from '@emotion/css';
import _ from 'lodash';

const linkError = css`
  outline: 1px dashed red;
`;

export const parseAnalytics = (analytics) => {
  if (!analytics || !('track' in analytics)) {
    return false;
  }

  return _
    .chain(analytics)
    .omitBy(_.isBoolean)
    .mapKeys((value, key) => (
      /^data-track/.exec(key) ? key : `data${ key != 'track' ? '-track' : '' }-${key}`
    ))
    .value();
}

export const connectAnalytics = (Component) => (
  class extends React.Component {

    render() {
      let { analytics, ...props } = this.props;

      if (analytics = parseAnalytics(analytics)) {
        return <Component { ...props } { ...analytics } />
      } else if (process.env.NODE_ENV === 'development' && !props['data-track']) {
        return <Component { ...props } data-track="DATA TRACK MISSING" title="Missing analytics attributes" className={ cx(props.className, linkError) } />
      } else {
        return <Component { ...props } />
      }
    }

  }
);

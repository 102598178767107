import { jsx, css } from '@emotion/react';
import { define, ui } from '@owenscorning/pcb.alpha';

import Renderer from './../../../../OC/PageBuilder/TemperatureRange.jsx';


export default define`Temperature Range`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/TemperatureRange`,
  category: ui`Modules/Categories/Page/Prebuilt`,
  view: ({ value }) => <Renderer { ...value } />,
  edit: {},
});

import styled from '@emotion/styled';

const FormElement = styled.form`
	margin: 0;
  display: block;
`;

const Form = ({children, onSubmit, id}) => {
	
	return(
		<FormElement id={id} onSubmit={onSubmit} >
			{children}
		</FormElement>
	)
}

export default Form;
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HeadingContext } from './Section';

const Container = styled.div`
  ${({ gapless, paddingTop }) => (!gapless) && css`
    padding-top: ${ paddingTop.desktop }px;
    @media (${ UI.Viewport.to.Desktop }) { padding-top: ${ paddingTop.tablet }px; }
    &:first-child { padding-top: 0px; }
  `}
`;

const Module = styled.div`
  ${ ({ gutterless }) => !gutterless && css`
    padding: 0 ${ Board.Gutter.Desktop }px;
    @media (${ UI.Viewport.to.Desktop }) { padding: 0 ${ Board.Gutter.Tablet }px; }
    @media (${ UI.Viewport.to.Tablet }) { padding: 0 ${ Board.Gutter.Mobile }px; }
  ` }
`;

export default ({ selectable=true, prior, selector, onClick, children, gapless=false, styles, ...props }) => {
  const moduleType = Board.build.moduleType;
  const containerPadding = {
    "Article": { desktop: (Board.Gap.Desktop / 2), tablet: (Board.Gap.Tablet / 2) }
  };
  const paddingTop = containerPadding[moduleType] || { desktop: Board.Gap.Desktop, tablet: Board.Gap.Tablet };

  return (
    <HeadingContext.Consumer>
      {({ headingLevel } = {}) => (
        <Container gapless={ (!!headingLevel && !prior) || gapless } paddingTop={paddingTop} css={ styles }>
          <UI.Selection selectable={ selectable } unwrapped={ !selectable } onClick={ onClick } selector={ selector }>
            <Module { ...props } >{ children }</Module>
          </UI.Selection>
        </Container>
      )}
    </HeadingContext.Consumer>
  );
};

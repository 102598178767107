import _ from 'lodash';

import cms_api from '../../../../../cms_api';

const queryHash = (type, language, q) =>
  _.merge(
    {
      filter: {
        type: `Cms::Content::${type}`,
        language_iso_code: language,
        'metadata.redirect': 'null',
        metadata: {
          'settings.availability.status': ['active', 'external'],
          'settings.availability.visibility': 'listed',
        },
      },
      fields: { '*': 'contents,metadata,language_iso_code,content_uuid' },
    },
    q ? { q } : {}
  );

const queryProducts = (type, language, q, options = {}) => cms_api.get_all(queryHash(type, language, q), options)

export default queryProducts;

import _ from 'lodash';

const getLabel = (optionValue) => (
  _.isPlainObject(optionValue) ? optionValue.label : optionValue
);

const getValue = (optionValue) => (
  _.isPlainObject(optionValue) ? (optionValue.value || getLabel(optionValue)) : optionValue
);

const getItemOptions = (optionValue) => {
  const { children, options, ...other } = (_.isPlainObject(optionValue) ? optionValue : {});
  return other
};

// for react-select we can pass it array of { label: , value: , ... }
// incoming might be simple array, where each item is a string and same value/label
// it most likely is an object of { value: label }
// it may be an array already formatted for react-select (e.g. groups): [ { label: , options: [] } ]
const getOption = (o) => {
  if (typeof(o) === 'string') {
    return {
      value: o,
      label: o
    }
  } else if (o.children && !_.isEmpty(o.children)) {
    const { children, ...rest } = o;
    return _.merge({
      value: getValue(rest),
      label: getLabel(rest),
      children: getOptions(children)
    }, getItemOptions(rest))
  } else if (o.options && !_.isEmpty(o.options)) {
    const { options, ...rest } = o;
    return _.merge({
      value: getValue(rest),
      label: getLabel(rest),
      children: getOptions(options)
    }, getItemOptions(rest))
  } else {
    return _.merge({
      value: getValue(o),
      label: getLabel(o)
    }, getItemOptions(o))
  }
};

export const getOptions = (options) => {
  const val = (
    options instanceof Array ?
      options.map(o => getOption(o)) :
      _.map(options, (optionValue, key) => {
        const { children, options, ...rest } = typeof(optionValue) === 'string' ? { value: key, label: optionValue } : optionValue;
        return _.merge({
                         value: getValue(key),
                         label: getLabel(optionValue)
                       }, children && !_.isEmpty(children) ? {
                         children: getOptions(children)
                       } : {}, options && !_.isEmpty(options) ? {
                         children: getOptions(options)
                       } : {},
                       getItemOptions(rest)
                     )
        }
      )
  );
  //console.log('getOptions', options, val)

  return val;
}

export const getReactSelectOptions = (options) => {
  return getOptions(options).map(o => {
      const { children, ...rest } = o;
      return _.merge({}, rest, children && children.length > 0 ? { options: getReactSelectOptions(children) } : {})
    }
  )
}

// https://stackoverflow.com/a/54470906
const getPath = (obj, predicate) => {
  var path = [];
  var found = false;

  function search(haystack) {
    for (var key in haystack) {
      path.push(key);
      if (predicate(key, haystack[key])) {
        found = true;
        break;
      }
      if (Array.isArray(haystack[key]['children'])) {
        path.push('children');
        search(haystack[key]['children']);
        if (found) break;
        path.pop();
      }
      path.pop();
    }
  }

  search(obj);
  return path;
};

export const getReactSelectedOptions = (options, value) => {
  const normalizedOptions = getOptions(options);

  const paths = Array.wrap(value||[]).map(inValue =>
    getPath(normalizedOptions, (k, option) => option.value === inValue)
  )
  return paths.map(path => _.get(normalizedOptions, path));
}

export const getParentReactSelectOption = (options, selectedOption) => {
  const normalizedOptions = getOptions(options);

  const path = getPath(normalizedOptions, (k, option) => option.value === selectedOption.value);
  if (path.length > 0) {
    path.pop();
    path.pop();
    return _.get(normalizedOptions, path);
  }
  return null;
}

import { useRef } from 'react';
import styled from '@emotion/styled'
import { jsx, css } from '@emotion/react';
import Theme from '../../themes';
import {useAccessibleInteractivity} from "../../hooks";

const TabButton = styled.button`
  display: flex;
  align-items: center;
  padding: 8px;
  border: none;
  font-size: 13px;
  color: #6D6D6D;
  border-bottom: 1px solid #6D6D6D;
  background-color: transparent;
  min-width: 100px;
  justify-content: center;

  ${props => (props.icon && css`
    &:before {
      content: '\\f00b';
      padding-right: 8px;
      font-family: 'fontawesome';
    }
  `)}

  &:hover {
    background: ${Theme.colors.brand};
    color: white;
    cursor: pointer;

    ${props => (props.icon && css`
      &:before {
        color: white;
      }
    `)}
  }

  &:focus {
    outline-offset: 0;
    outline: #d40f7d solid 2px;
  }

  &:focus:not(:focus-visible) {
    outline: none!important;
  }

  &:focus-visible {
    outline-offset: 0;
    outline: #d40f7d solid 2px;
    z-index: 1;
  }

  &.active {
    color: ${Theme.colors.brand};
    border-bottom: 2px solid ${Theme.colors.brand};
    padding-bottom: 5px;
    background: white;

    &:hover {
      cursor: default;
    }

    ${props => (props.icon && css`
      &:before {
        color: ${Theme.colors.brand};
      }
    `)}
  }

  span {
    width: 100%;
  }
`;

const Icon = styled.i`
  margin-right: 8px;
`;

const Tab = ({label, active, onClick, icon, iconImage}) => {
  const tabButton = useRef();
  useAccessibleInteractivity(tabButton, onClick);

  return (
    <TabButton
      aria-checked={ active }
      className={`${active ? 'active' : null}`}
      data-track="view-change"
      data-track-tab-name={ label }
      data-track-view={ label }
      icon={icon}
      onClick={ onClick }
      ref={ tabButton }
      role="radio"
    >
      { iconImage && <Icon>{iconImage}</Icon> } { label }
    </TabButton>
  );
}

export default Tab;

import styled from '@emotion/styled';

import LegendItem from './LegendItem';
import useReference from '../../../../hooks/use_reference';

const LegendWrapper = styled.div`
  margin-top: 10px;
  div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    img {
      width: 30px;
      height: 20px;
      object-fit: contain;
      margin-right: 5px;
    }
    span {
      font-size: 15px;
      margin-right: 5px;
    }
  }
`;

const LegendView = ({ value: { attribute } }) => {
  const { results } = useReference(attribute);
  const { items } = results?.contents || {};
  if (items && items.length) {
    return (
      <LegendWrapper>
        {items.map(item => (
          <LegendItem key={item.value} item={item} />
        ))}
      </LegendWrapper>
    );
  }

  return null;
};

export default LegendView;

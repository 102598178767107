import _ from "lodash";

export const trackEvent = (name, data) => {
  if (typeof(window) !== 'undefined' && window._satellite) {
    window._satellite.track(name, data);
  }
}

export const mergeDigitalData = (...data) => {
  if (typeof(window) !== 'undefined') {
    _.merge(window.digitalData, ...data);
  }
}

export const dataTrackAttribute = (path, locale = 'en-US') => {
  const regExp = new RegExp(`^\/${locale}`, 'i');
  const pathWithoutQuery = path.split('?')[0]
  let pathWitoutLocale = pathWithoutQuery.replace(regExp, '');
  let pathFragments = _.filter(pathWitoutLocale.replace(/_/g, '-').split('/'), _.size);
  return ['oc', ...pathFragments].join(' | ');
}

import { define, ui } from '@owenscorning/pcb.alpha';

export default define`OpenGraph`('0.0.1')({
  view: (props) => (
    <></>
  ),
  edit: ui`Form`.of({
    title_alert: ui`AlertMessage`({ message: 'Limit to 60 characters in length.' }),
    og_title: ui`Text`({ label: 'Open Graph Title' }),
    description_alert: ui`AlertMessage`({ message: 'Limit to 60 characters in length.' }),
    og_description: ui`Text`({ label: 'Open Graph Description' }),
    image_alert: ui`AlertMessage`({ message: 'Images should be a minimum of 600x315, but ideally 1200x630. Stick to a 1.91:1 aspect ratio for best results.' }),
    og_image: ui`Upload/Image`({
      label: 'Open Graph Image'
    }),
    extension_alert: ui`AlertMessage`({ message: 'If using Google Chrome, install the extension “Social Share Preview” to enable your browser to preview the open graph share prior to publishing.' }),
  })
});

import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';

import TextInput from '../../../ComponentLibrary/input-elements/oc-text-input';

const Container = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
`;

const Column = styled.div`
  flex: 1;
  max-width: 48%;
`;

function SheetOptions({ contractor, project, setContractor, setProject }) {
  const handleContractorChange = e => {
    setContractor(e.target.value);
  };

  const handleProjectChange = e => {
    setProject(e.target.value);
  };

  return (
    <Container>
      <Column>
        <TextInput
          name="contractor"
          label="Contractor:"
          onChange={handleContractorChange}
          value={contractor}
        />
      </Column>
      <Column>
        <TextInput
          name="project"
          label="Project:"
          onChange={handleProjectChange}
          value={project}
        />
      </Column>
    </Container>
  );
}

SheetOptions.propTypes = {
  contractor: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  setContractor: PropTypes.func.isRequired,
  setProject: PropTypes.func.isRequired,
};

export default SheetOptions;

import {connect} from 'react-redux';
import {celluloseData} from './static';
import {cloneDeep, set, has, get, sample, merge} from 'lodash';
import {
  PROPINK_CELLULOSE_CALC_SET_PARAM,
  PROPINK_CELLULOSE_CALC_SET_CALCULATIONS,
} from '../Actions';

const initialState = {
  rvalue: 'R-30',
  brand: 'greenfiber',
  l77: {
    coverage_per_bag: 77,
    cost_per_bag: 50.00,
    your_sell_price: 1.33,
    hourly_rate: 25,
  },
  cellulose: {
    coverage_per_bag: 28.2,
    cost_per_bag: 16.00,
    your_sell_price: 0.90,
    hourly_rate: 25,
  },
  second_truck_used: false,
  data: celluloseData,
  calculations: {},
}

export const celluloseCalculatorReducer = (state = initialState, action) => {
  let newState = cloneDeep(state);

  if(action.type === PROPINK_CELLULOSE_CALC_SET_PARAM && has(action, 'param') && has(action, 'value')) {
    set(newState, action.param, action.value)
    return newState;
  }
  if(action.type === PROPINK_CELLULOSE_CALC_SET_PARAM && has(action, 'obj')) {
    merge(newState, action.obj);
    return newState;
  }

  if(action.type === PROPINK_CELLULOSE_CALC_SET_CALCULATIONS) {
    set(newState, 'calculations', action.calculations)
    return newState;

  }
  return state;
}


export const setParam = (param, value) => ({type: PROPINK_CELLULOSE_CALC_SET_PARAM, param, value});
export const setParams = (obj) => ({type: PROPINK_CELLULOSE_CALC_SET_PARAM, obj: obj});

export const setCalculations = (calculations) => ({type: PROPINK_CELLULOSE_CALC_SET_CALCULATIONS, calculations});

export const calculatorConnector = connect(
  ({celluloseCalculator}) => ({
    ...celluloseCalculator
  }),
  {setParam, setParams, setCalculations}
);

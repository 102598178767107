import { Global, css } from '@emotion/react';
import styled from '@emotion/styled';

// eslint-disable-next-line import/no-cycle
import UnderFilterContent from '../../../OC/PageBuilder/Locator/UnderFilterContent';
import { connectLocation } from '../../../location/LocationConnectors';
import FilterPaneButtons from '../../filter-list/oc-filter-pane-buttons';
import { FilterSectionHeading } from '../../text-elements/filters';

const Curtain = styled.div`
  background-color: rgba(0, 0, 0, 0.25);
  display: ${props => props.open ? 'block' : 'none'};
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
  @media(min-width: 1200px) {
    display: none;
  }
`;

const ContainerElement = styled.div`
  background-color: #FFFFFF;
  display: ${props => props.open ? 'inline-flex' : 'none'};
  flex-direction: column;
  height: 100%;
  left: 0;
  padding: 1.5em 1.5em 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 110;

  @media screen and (min-width: 1200px) {
    display: ${props => (props.overlay && !props.open) ? 'none' : 'inline-flex'};
    position: ${props => props.overlay ? 'fixed' : 'relative'};
    max-width: ${props => props.overlay ? '318px' : '270px'};

    ${props => !props.overlay && `
      padding: 0;
    `}
  }
`;

const ContainerHeader = styled.div`
  display: inline-flex;
  justify-content: space-between;
  padding-bottom: 0.75em;
  position: relative;
  width: 100%;
`;

const CloseButton = styled.a`
  color: #585858;
  cursor: pointer;
  display: inline-block;
  height: 1em;
  padding: 1em;
  position: relative;
  right: -0.5em;
  text-decoration: none;
  width: 1em;

  &:after {
    content: "\\e800";
    display: inline-block;
    font-family: "icomoon";
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @media screen and (min-width: 1200px) {
    display: ${props => props.overlay ? 'inline-block' : 'none'};
  }
`;

const HideWebChat = () => {
  const styles = css`
    .cx-widget .cx-webchat {
      z-index: 109;
    }
  `
  return <Global styles={styles} />
}

const bodyScroll = css`
  body {
    position: fixed;
    left: 0;
    right: 0;
  }
`;

const ScrollableChildren = styled.div`
  display: inline-block;
  overflow-y: auto;
  margin-bottom: 3.5em;
  position: relative;
  width: 100%;
`;

const FilterContainer = connectLocation(({ t, heading, children, filterReset, overlay, open, closeFunction, resultCount }) => {
  const onReset = () => {
    filterReset();
    closeFunction();
  };

  return (
    <>
      <Curtain open={open} onClick={closeFunction} />
      <ContainerElement data-am-region="Filters" id="filters-container" overlay={overlay} open={open}>
        <ContainerHeader>
          <FilterSectionHeading>{heading} {open && `(${resultCount})`}</FilterSectionHeading>
          <CloseButton
            aria-hidden="true"
            aria-label="Close Modal"
            data-track="close-modal"
            data-track-modal-name="Filter Overlay"
            onClick={closeFunction}
            overlay={overlay}
            tabindex="-1"
          />
        </ContainerHeader>
        <ScrollableChildren>
          {children}
        </ScrollableChildren>
        <FilterPaneButtons filterReset={onReset} apply={closeFunction} visible={open} count={resultCount}/>
        {!open && <UnderFilterContent />}
      </ContainerElement>
      {open && <HideWebChat />}
      {open && <Global styles={bodyScroll} />}
    </>
  )
});

// FilterContainer.WrappedComponent.displayName = 'FilterContainer';

export default FilterContainer;

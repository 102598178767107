import { define, ui, select, when } from '@owenscorning/pcb.alpha';
import placeholder_url from '../../../../placeholder';

export default define`Media Object`('0.0.1')({
  edit: ({ imgSize, iconSize, onchange, value, ...props }) => {
    return (
      <UI.Form>
        {{
          image: ui`Image`({
            askAlt: false,
            crop: true,
            label: 'Image Path',
            defaultImage: placeholder_url(340,190),
            visible: (imgSize !== "icon")
          }),
          icon: ui`Text`({
            label: 'Icon Code from Iconify',
            default: 'prime:file-pdf',
            visible: (imgSize === "icon")
          }),
          iconColor: ui`Choices`.of({
            "pink": "Brand",
            "black": "Black"
          })({
            label: "Icon Color",
            default: "pink",
            visible: (imgSize === "icon")
          }),
          alt: ui`Text`({
            label: 'Image Alt Text',
          }),
          prehead: ui`Text`({
            label: 'Prehead',
          }),
          heading: ui`Text`({
            label: 'Heading',
          }),
          text: ui`Text/Rich`({
            label: 'Body Copy',
          }),
          linkText: ui`Text`({
            label: 'Link Text',
          }),
          url: ui`Text`({
            label: 'URL',
          })
        }}
      </UI.Form>
    );
  }
});

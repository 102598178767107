import PropTypes from 'prop-types';

import { Name as StyledName } from '../result-block-styled-components';

const Name = ({ children }) => (
  <StyledName font="body" className="name">
    {children}
  </StyledName>
);

Name.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Name;

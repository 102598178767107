import _ from 'lodash';
import { canUseDOM } from './dom';
import qs from 'qs';

const stringifyOptions = {
  encodeValuesOnly: true,
  arrayFormat: 'brackets',
  skipNulls: true,
};
export const stringify = obj => {
  return qs.stringify(obj, stringifyOptions);
};

const parseOptions = {
  ignoreQueryPrefix: true,
};
export const parse = str => {
  return qs.parse(str, parseOptions);
};

const mergePathParam = (values) => {
  if (!canUseDOM()) { return values }
  if (typeof(PB_WILDCARD_BASE) !== 'undefined' && typeof(PB_WILDCARD) !== 'undefined') {
    const { pathname } = document.location;
    let wildcard = pathname.match(new RegExp(PB_WILDCARD_BASE.replace('/*', '/(?<param>.*)')))?.groups?.param;
    if (wildcard && wildcard.indexOf(',') !== -1) { wildcard = wildcard.split(',') }
    values = {
      ...values,
      ...(wildcard ? { [PB_WILDCARD]: wildcard } : {})
    }
  }
  return values
}

const getParams = () => {
  const queryStr = canUseDOM() ? window?.location?.search : '';

  return mergePathParam(parse(queryStr));
};

const getPathAndQuery = (params) => {
  if (typeof(PB_WILDCARD_BASE) !== 'undefined' && typeof(PB_WILDCARD) !== 'undefined') {
    let { [PB_WILDCARD]: wildcard, ...rest } = params;
    if (Array.isArray(wildcard)) { wildcard = wildcard.join(',') }
    return {
      pathname: '/' + PB_WILDCARD_BASE.replace('/*', wildcard ? `/${wildcard}` : ''),
      queryParams: rest
    }
  }
  return {
    pathname: document.location.pathname,
    queryParams: params
  }
}

const setParams = params => {
  if (!canUseDOM()) { return }
  const { pathname, queryParams } = getPathAndQuery(params);
  const hash = window.location.hash;
  let paramStr = stringify(queryParams);
  // don't add trailing '?' if params are blank
  if (paramStr.length > 0) {
    history.replaceState(null, '', `${pathname}?${paramStr}${hash}`);
  } else {
    history.replaceState(null, '', `${pathname}${hash}`);
  }
};

// install some hooks URL update events. popstate doesn't seem reliable.
const installHistoryHooks = () => {
  let currentParams;
  let previousParams;
  // emit paramsChanged if params are different
  const emitIfParamsChanged = () => {
    previousParams = currentParams;
    currentParams = getParams();
    if (!_.isEqual(currentParams, previousParams)) {
      window.dispatchEvent(new Event('paramsChanged'));
    }
  };

  const { pushState, replaceState } = window.history;
  window.history.pushState = function (...args) {
    pushState.apply(window.history, args);
    emitIfParamsChanged();
  };

  window.history.replaceState = function (...args) {
    replaceState.apply(window.history, args);
    emitIfParamsChanged();
  };
};
// trying to not blow up SSR. not sure if necessary or not.
if (canUseDOM()) {
  installHistoryHooks();
}

// only fired when URL params are changed (by hash comparison)
const subscribeToParamUpdate = callback => {
  canUseDOM() &&
    window?.addEventListener &&
    window.addEventListener('paramsChanged', callback);
};

export { getParams, setParams, subscribeToParamUpdate };

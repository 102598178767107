import { ui } from '@owenscorning/pcb.alpha';

import Product from './Product';
import { wrapRef } from '../../../../../data';

const defaultCategories = [
  'Data Sheets',
  'Sell Sheets',
  'Brochures',
  'Guide Specifications',
  'Technical Documents',
  'Warranty',
  'Installation Instructions',
  'Environmental Product Declarations',
  'Case Studies',
  'LEED',
  'UL Testing',
  'Safety Data Sheets',
];

const Insulation = Product(
  'Product::Insulation',
  ui`AttributeSet`({
    query: {
      name: 'Insulation PDP Attributes',
      path: '/insulation',
    },
  }),
  build => [
    {
      title: 'Specifications & Literature',
      initial: [
        {
          type: 'SpecificationsAndLiterature',
          data: { categories: defaultCategories?.map(x => ({ title: x })) },
        },
      ],
    },
    {
      title: 'Advertisement',
      initial: [
        {
          type: 'Reusable',
          data: {
            reference: wrapRef('Cms::Content', {
              type: 'Reusable',
              path: 'insulation',
              name: 'Enclosure Solutions Banner',
            }),
          },
        },
      ],
    },
    {
      title: 'eBooks & Guides',
      initial: [
        {
          type: 'Reusable',
          data: {
            reference: wrapRef('Cms::Content', {
              type: 'Reusable',
              path: 'insulation',
              name: 'Insulation Ebooks',
            }),
          },
        },
      ],
    },
    {
      title: 'Help & FAQs',
      initial: [
        {
          type: 'Reusable',
          data: {
            reference: wrapRef('Cms::Content', {
              type: 'Reusable',
              path: 'insulation',
              name: "We're Here to Help",
            }),
          },
        },
        {
          type: 'Accordion',
          data: {
            copy: {
              prehead: '',
              heading: {
                ops: [
                  {
                    insert: 'Frequently Asked Questions',
                  },
                ],
              },
              bodyCopy: {
                ops: [
                  {
                    insert: '',
                  },
                ],
              },
              banner: {},
              ctas: [],
              bodyFont: false,
            },
            accordions: [],
          },
        },
      ],
    },
  ],
  {},
  {
    version: '0.1',
    displayName: 'Insulation PDP',
    paths: ['insulation/products'],
    allowsPIMLink: true,
  },
  [
    {
      title: 'Intro',
      unmovables: [true],
      modules: ['BasicContent', 'MediaObjectSet', 'CardSet', 'Reusable'],
      // TODO, only for roofing component
      initial: ['InsulationIntroContentAndImage'],
      showInSticky: false,
    },
    {
      title: 'Specifications & Literature',
      unmovables: [true],
      modules: ['FullBleedContent', 'Reusable', 'AttentionBarPicker'],
      initial: ['SpecificationsAndLiterature'],
      showHeading: true,
    },
    {
      title: 'Advertisement',
      modules: ['FullBleedContent', 'Reusable'],
      showInSticky: false,
    },
    {
      title: 'Certifications',
      modules: ['BasicContent', 'CardSet'],
      initial: ['ProductCertifications'],
      showHeading: true,
    },
    {
      title: 'Performance Attributes',
      modules: ['BasicContent', 'CardSet'],
      initial: [
        {
          type: 'MediaObjectSet',
          data: {
            copy: {
              prehead: '',
              heading: '',
              bodyCopy: null,
            },
            settings: {
              itemsPerRow: '2',
            },
          },
        },
      ],
      showHeading: true,
    },
    {
      title: 'Resources',
      modules: ['ContentAndImage', 'BasicContent', 'Gallery', 'Reusable'],
      initial: [
        {
          type: 'Gallery',
          data: {
            settings: {
              thumbnail: 'large',
            },
          },
        },
      ],
      showHeading: true,
    },
    {
      title: 'Technical Information',
      modules: [
        'BasicContent',
        'CardSet',
        'ContentAndImage',
        'FullBleedContent',
        'Table',
      ],
      initial: ['Table'],
      showHeading: true,
    },
    {
      title: 'Applications',
      modules: ['BasicContent', 'MediaObjectSet'],
      initial: ['ProductApplications'],
      showHeading: true,
    },
    {
      title: 'Related Products',
      modules: ['BasicContent', 'ContentAndImage', 'FullBleedContent'],
      initial: [
        {
          type: 'MediaObjectSet',
          data: {
            copy: {
              prehead: '',
              heading: '',
              bodyCopy: null,
            },
            settings: {
              itemsPerRow: '2',
            },
          },
        },
      ],
      showHeading: true,
    },
    {
      title: 'Help & FAQs',
      modules: ['BasicContent', 'Reusable', 'Accordion'],
      hidden: true,
    },
    {
      title: 'eBooks & Guides',
      modules: ['Reusable'],
      showHeading: true,
    },
    {
      title: 'Contact Us',
      modules: [],
      initial: ['ContactUs'],
    },
  ]
);

export default Insulation;

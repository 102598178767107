import { define, ui, when, s, select } from '@owenscorning/pcb.alpha';
import placeholder_url from '../../../../../placeholder';
import Modular from '../../Types/Modular';

import Renderer from './../../../../OC/PageBuilder/TabbedContent';

// We can restrict the modules that can be added to the TabbedContent
// module by passing an array of modules to the onlyModules prop.
const onlyModulesList = () => {
  if(!Board?.opts?.onlyModules) return null; // If onlyModules is not defined, no restrictions are applied
  const onlyModules = Board?.opts?.onlyModules;
  return onlyModules.find((modules) => modules.type === 'TabbedContent')?.modules
}

const Edit = (editOptions) => ({
  size: ui`Choices`.of({
    xl: 'XL',
    l: 'Large',
    m: 'Medium',
  })({
    label: 'Container Size',
    default: editOptions?.size?.default || 'xl',
    mode: ui`Choices/Mode/Dropdown`
  }),
  alignment: ui`Choices`.of({'left': 'Left', 'center': 'Center'})({
    label: 'Alignment',
    default: 'center',
    visible: when`../size`.is.equal.to('xl').then(false).otherwise(true)
  }),
  copy: ui`Content/Basic`,
  settings: ui`Form`.of({
    tabSize: ui`Choices`.of({
      'small': 'Small',
      'large': 'Large'
    })({
      label: 'Tab Size',
      default: 'large',
      mode: ui`Choices/Mode/Dropdown`
    }),
    [s._]: ui`Tip`.of('Select the tab size you would like'),
    tabsAlign: ui`Choices`.of({
      'left': 'Left',
      'right': 'Right',
      'center': 'Center'
    })({
      label: 'Tab Alignment',
      default: 'center',
      mode: ui`Choices/Mode/Dropdown`
    }),
    [s._]: ui`Tip`.of('Select alignment of your tabs'),
    iconImage: ui`Choices`.of({
      'icon': 'Icon',
      'image': 'Image',
      'text': 'Text Only',
    })({
      label: 'Tab Graphic',
      default: 'icon',
      mode: ui`Choices/Mode/Dropdown`
    }),
    [s._]: ui`Tip`.of('Select Icons or Images for your tabs'),
  }),
  tabs: ui`List`.of(({path})=> <Subschema>{
    ui`Form`.of({
      defaultTab: ui`Switch`({ label: 'Active by Default', default: false}),
      icon: ui`Text`({ label: 'Icon Code from Iconify', default: 'akar-icons:shipping-box-01', visible: when`../../../settings/iconImage`.is.equal.to('icon').then(true).otherwise(false) }),
      iconImage: ui`Upload/Image`({ label: 'Image Path', default: placeholder_url(340,190), visible: when`../../../settings/iconImage`.is.equal.to('image').then(true).otherwise(false)}),
      heading: ui`Text`({ label: 'Tab Label', default: 'Tab Label' }),
      url: ui`Text`({ label: 'URL' }),
      moduleSeparator: ui`Switch`({
        label: "Module Separator",
        default: false
      }),
      tabContent: Modular.Module.List(UI, null, onlyModulesList(), (index) => Modular.TabContent([...(path.slice(3)), 'tabContent', index]))
    })
  }</Subschema>)({
    label: 'Tabs',
    singular: 'Tab',
    title: 'heading'
  }),
});

export { Edit };
export default define`Tabbed Content`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/TabbedContent`,
  prepare: () => ( {
    view: ({ value }) => <Renderer { ...value } />,
    edit: Edit(),
  })
});

import { css, Global } from '@emotion/react';

import Theme from '.';

const globalThemeStyles = css`
  a,
  button,
  input,
  select,
  textarea {
    &:focus,
    &:focus-visible {
      outline-color: ${Theme.colors.brand};
    }
  }
  a:hover {
    color: ${Theme.colors.brand};
  }
`;

const GlobalThemeStyles = () => <Global styles={globalThemeStyles} />;

export default GlobalThemeStyles;

import _ from 'lodash';
import {connect} from 'react-redux';

// Dev setup
import {GENERIC_LOCATOR_LOAD_DATA, GENERIC_LOCATOR_SET_MAX_DISTANCE} from "../../Actions";
import getReduxInitial from "../../helpers/redux_initial";

const DEFAULT_INITIAL_STATE = {
  api: {
    data: []
  },
  maxDistance: 250,
  loaded: false
}

const genericLocatorInitial = _.assign({}, DEFAULT_INITIAL_STATE, getReduxInitial('genericLocator', {}));

export const genericLocatorReducer = (state = genericLocatorInitial, action) => {
  if (action.type === GENERIC_LOCATOR_LOAD_DATA) {
    const newState = _.cloneDeep(state);
    newState.api = action.data
    newState.loaded = true
    return newState;
  }

  if (action.type === GENERIC_LOCATOR_SET_MAX_DISTANCE) {
    const newState = _.cloneDeep(state);
    newState.maxDistance = action.maxDistance;
    return newState;
  }

  return state;
}

const INVALID_ZIP_ERROR = 'zip_code_not_found';

export const updateAPI = (data) => ({type: GENERIC_LOCATOR_LOAD_DATA, data})
export const setMaxDistance = (maxDistance) => ({type: GENERIC_LOCATOR_SET_MAX_DISTANCE, maxDistance})

export const GenericLocatorConnectors = connect(
  ({ genericLocator }) => {
    const resourcesList = _.get(genericLocator, "api.data", []);

    return (
      {
        city: _.get(genericLocator, 'api.city'),
        region: _.get(genericLocator, 'api.region'),
        genericLocator,
        resourcesList,
        filteredResourcesCount: resourcesList.length,
        filters: genericLocator.filters,
        invalidZip: _.get(genericLocator, 'api.meta.errors', []).includes(INVALID_ZIP_ERROR),
        loaded: genericLocator.loaded,
        maxDistance: genericLocator.maxDistance
      }
    );
  },
  {updateAPI, setMaxDistance}
)

const AnimateTextContainer = (inView, target, animationTimeline) => {
  animationTimeline
    .add({
      targets: target,
      opacity: [0, 1],
      translateX: [150,0],
      duration: 400,
      delay: 300,
      easing: 'easeInOutQuad',
    });

  if (inView) {
    animationTimeline.play();
  }
};
export default AnimateTextContainer;

import PropTypes from 'prop-types';

import { ActionLink } from '../result-block-styled-components';

const fixUrl = url => {
  if (!url || url.trim() === '') {
    return null;
  }

  if (/^https?:\/\//.test(url)) {
    return url;
  }

  return `http://${url}`;
};

const Website = ({ website, label, buyType, name }) => (
  <ActionLink
    href={fixUrl(website)}
    target="_blank"
    rel="noopener"
    tabIndex={0}
    data-track="website"
    data-track-buy-type={buyType}
    data-track-supplier-name={name}
  >
    {label}
  </ActionLink>
);

Website.propTypes = {
  label: PropTypes.string.isRequired,
  website: PropTypes.string.isRequired,
  buyType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Website;

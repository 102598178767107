// adapted from https://dev.to/gethackteam/from-higher-order-components-hoc-to-react-hooks-2bm9

import { useState, useEffect } from "react";
import { expandRef } from '../data';

const refCache = {};

function useReference(refObj, ...args) {
  //console.log('useReference', refObj)
  const refCacheElement = refCache[refObj?.__ref] = _.cloneDeep(refObj?.__data || refCache[refObj?.__ref]);

  const [results, setResults] = useState(refCacheElement);
  const [loading, setLoading] = useState(refObj && !results);
  const [error, setError] = useState('');

  useEffect(() => {
    async function fetchData() {
      if (refObj) {
        setLoading(true);
        try {
          const data = refCache[refObj?.__ref] = _.cloneDeep(await expandRef(refObj, ...args));
          if (data) {
            setResults(data);
            setError('')
          }
        } catch (error) {
          setError(error.message);
        }
        setLoading(false);
      }
    }
    if (refCacheElement) {
      setResults(refCacheElement);
      setError('')
    } else {
      fetchData();
    }
  }, [refObj?.__ref]);

  return refObj === null ? {
    error: '',
    loading: true,
    results: null
  } : {
    error,
    loading,
    results
  };
}

export default useReference;

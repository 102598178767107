import { useEffect } from 'react';
import loadable from '@loadable/component';

const Component = loadable(props => import(`./${props.component}`));

const showFooter = () => {
  const footer = document.getElementById('oc-footer');
  if (footer) {
    footer.style.display = 'block';
  }
};

const FallbackElement = ({ajaxLoaded}) => {
  useEffect(() => {
    return () => {
      if (!ajaxLoaded) {
        setTimeout(() => showFooter());
      }
    }
  }, []);
  return <div />;
}

const ToggleFooter = ({ component, ajaxLoaded, ...otherProps }) => {
  if (component === 'ToggleFooter')
    return <div />;
  return <Component component={component} showFooter={showFooter} { ...otherProps } fallback={<FallbackElement ajaxLoaded={ajaxLoaded} />} />;
}

export default ToggleFooter;

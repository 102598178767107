import { jsx, css } from '@emotion/react';

const baseStyles = css`
  table {
    border-collapse: collapse;
    font-size: 14px;
    width: 700px;
    table-layout: fixed;

    @media(min-width: 768px) {
      width: 100%;
    }

    &.threeUp {
      td,
      th {
        width: 33%;
      }
    }

    &.fourUp {
      td,
      th {
        width: 25%;
      }
    }

    &.fiveUp {
      td,
      th {
        width: 20%;
      }
    }

    @media (min-width: 1200px) {
      margin-bottom: 16px;
    }

    tbody tr:nth-child(odd) td {
      background: #f8f8f8;
    }

    th {
      padding: 11px 9px;
      border-top: 2px solid #bcbcbc;
      border-bottom: 2px solid #bcbcbc;
      border-left: 1px solid #bcbcbc;
      font-size: 14px;
      text-transform: none;

      &:first-child {
        border-left: 0;
        width: 35%;

        @media (min-width: 768px) {
          width: 40%;
        }

        @media (min-width: 1200px) {
          width: 39%;
        }
      }
    }

    td {
      border-bottom: 1px solid #bcbcbc;
      padding: 11px 9px;
      border-left: 1px solid #bcbcbc;

      &:first-child {
        border-left: 0;
      }

      &.center {
        text-align: center;
      }
    }

    .colProPink,
    .pink {
      color: #d40f7d;
    }

    .tableSubhead {
      text-transform: uppercase;
      font-weight: 700;
    }

    .tableIndent {
      padding-left: 29px;
    }

    .row-highlight td {
      background: #d1d1d1 !important;
      font-weight: 700;

      &:first-child {
        text-transform: uppercase;
      }
    }
  }
`

export const ComparisonTable = ({ children }) => (
  <div css={baseStyles}>
    {children}
  </div>
)

export default {
  displayName: 'Attention Bar',
  allowableParents: [],
  migrator: (data) => {
    if (data && !data.layout) {
      data = { ...data, layout: 'custom' }
    }
    data = { ...data, component: 'AttentionBarPicker', settings: { attentionBarType: 'noImage', closeButton: false } }
    if (data.layout === 'multiCTA') {
      data = { ...data, copy: { heading: data.heading?.heading } }
    }
    return data;
  },
};

import React from "react";
import { jsx, css } from '@emotion/react';
import Text from '../../../OC/oc-text';
import LabelWrapper from '../../LabelWrapper';
import styled from '@emotion/styled';
import { getRequiredSuffix } from "../../../../helpers/form_libs";
import Theme from '../../../../themes/index';
import IconChevron from "../../../ComponentLibrary/icons/icon-chevron";


const Error = styled.p`
  color: #940420 !important;
  font-size: 12px;
  font-weight: bold;
`;

const SelectContainer = styled.div`
  background-color: #FFFFFF;
  border-color: ${props => props.valid ? '#D1D1D1' : '#940420' };
  border-color: ${props => props.disabled && '#A8A8A8 !important' };
  border-style: solid;
  border-width: 1px;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;

  &:hover {
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
  }

  &:focus-within {
    border-color: ${props => props.themeColor};
    outline: none;
  }
`;

const Select = styled.select`
  appearance: none;
  background-color: transparent;
  border: none;
  color: ${props => props.valid ? '#585858' : '#940420' };
  color: ${props => props.disabled && '#A8A8A8 !important' };
  cursor: pointer;
  font-size: 16px;
  margin: 0; // Reset for mobile user agent styles
  outline: none;
  padding: 14px 30px 14px 8px;
  width: 100%;
`;

const Chevron = styled(IconChevron)`
  display: inline-block;
  pointer-events: none;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
`;

export default ({
  value, name, required, handleChange, options=[],
  selectTitle='Please Select', error, className,
  formContext, elementType, showLabel=true,
  valid = true, disabled = false,
  themeColor = Theme.colors.brand,
  }) => {
  const optionalFocused = formContext?.optionalFocused;

  return (
    <>
      { showLabel &&
        <LabelWrapper htmlFor={name}>
          <Text content={ name } />{getRequiredSuffix(optionalFocused, required, elementType)}
        </LabelWrapper>
      }
      <SelectContainer disabled={disabled} valid={valid} themeColor={themeColor} className={`country-select ${className}`}>
        <Select
          disabled={disabled}
          valid={valid}
          name={name} id={name}
          className="form-control"
          onChange={handleChange}
          value={value}
          required={required}>
          <option value=""> {selectTitle} </option>
          {options?.map(option => (
            <option key={option.id} value={option.label}>{option.label}</option>
          ))
          }
        </Select>
        <Chevron className="chevron" fill={valid ? "#000000" : "#940420"} width="16" />
        { (error && error.__errors.length > 0) &&
          <Error aria-describedby={name} aria-errormessage className="error" role="alert">
            Please enter a valid {name}
          </Error>
        }
      </SelectContainer>
    </>
  );
}

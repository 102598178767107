export function anchor(value) {
  return value && value.toLowerCase().replace(/[^\w]+/gi, '-')
}

const getAdjustedScrollY = () => {
  const stickyNavs = document.getElementsByClassName('oc-stickynav');
  if (stickyNavs.length > 0) {
    const baseStickyNavHeightPixels = 113;
    const actualStickyNavHeightPixels = stickyNavs[0].clientHeight || baseStickyNavHeightPixels;
    return actualStickyNavHeightPixels;
  }
  return 0;
}

export function navigate(key) {
  const header = document.getElementById(key);
  if (header) {
    window.scroll({
      left: 0,
      top: header.getBoundingClientRect().top + window.scrollY - getAdjustedScrollY(),
      behavior: 'smooth',
    });
  }
}

import styled from '@emotion/styled';
import { jsx, css } from '@emotion/react';

const TabList = styled.div`
  display: inline-flex;
  margin-bottom: 30px;

  @media(min-width: 768px) {
    align-self: start;
    margin-left: auto;
    margin-bottom: 0;
  }
`;

export default ({ children, className }) => (
  <TabList className={className} role="radiogroup" aria-label="Choose your view">
    { children }
  </TabList>
)

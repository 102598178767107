import _ from "lodash";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import produce from "immer";
import {
  CMS_APPLY_CONFLICT,
  CMS_EDIT_CONTENT,
  CMS_EDIT_METADATA,
  CMS_SET_CONTENTS,
  CMS_SYNC_ERROR, CMS_SYNC_SELECT_ITEM
} from "../../Actions";

const SET_MIGRATION = 'CMS/content/SET_MIGRATION';

import { toast } from "../helpers/toast";
import { applyPatches } from "../helpers/patch";

// shape of redux cms state:
/*
{
  content: {
    contents: {
      //whatever, example is
      header: [
      ],
      sections: [
        {
          component: 'Section',
          children: [
            {
              component: 'Module'
              ...
            }
          ]
        }
      ]
    },
    metadata: {}
  }
}
 */

const contentReducer = {
  content: produce((draft, action) => {
    switch (action.type) {
      // content tree modification actions
      // all of these actions update current state, but store in list of "untracked" changes for push to server
      // server will take changes and apply and/or reject for client to reapply on top
      case CMS_EDIT_CONTENT: {
        const { contents } = action;
        draft.contents = contents;
        break;
      }
      case CMS_EDIT_METADATA: {
        const { metadata } = action;
        draft.metadata = metadata;
        break;
      }
      case CMS_SET_CONTENTS: {
        const { contents, metadata } = action.newData;
        draft.contents = contents;
        draft.metadata = metadata;
        break;
      }
      case SET_MIGRATION: {
        const { contents, metadata } = action.newData;
        draft.contents = contents;
        draft.metadata = metadata;
        break;
      }
      case CMS_APPLY_CONFLICT: {
        const { their_contents } = action;
        draft.metadata = their_contents.metadata;
        draft.contents = their_contents.contents;
        break;
      }
      // end content tree modification actions

      case CMS_SYNC_ERROR: {
        toast.warn(action.message);
        break;
      }
    }
  }, null)
};

export default contentReducer;

export const actions = {
  selectItem: (uuid) => ({
    type: CMS_SYNC_SELECT_ITEM,
    value_uuid: uuid,
    optimistic: true
  }),
  editContent: (fullContents) => ({
    type: CMS_EDIT_CONTENT,
    contents: fullContents
  }),
  editMetadata: (fullMetadata) => ({
    type: CMS_EDIT_METADATA,
    metadata: fullMetadata
  }),
  setMigration: (newData) => ({
    type: SET_MIGRATION,
    newData: newData
  }),
  setContents: (newData) => ({
    type: CMS_SET_CONTENTS,
    newData: newData
  }),
};

export const contentConnector = connect(
  (state) => ({
    contentType: state.cms.content.contentType,
    contents: state.cms.content.contents,
    metadata: state.cms.content.metadata,
    linkedObject: state.cms.content.linkedObject,
  }),
  (dispatch) => bindActionCreators(actions, dispatch)
);

import { ui } from '@owenscorning/pcb.alpha';
import styled from '@emotion/styled';
import _ from 'lodash';
import { connectLocation } from '../../../../location/LocationConnectors';
import Card from '../../../../OC/oc-card-new';
import Cta from '../../../../OC/oc-cta';
import RichText from '../../../../OC/PageBuilder/RichText';
import { H3 } from '../../../../ComponentLibrary/text-elements/oc-h';

const ShingleCard = styled.a`
  text-decoration: none;
`;

// Feels a bit hacky. This is just making the image on the modal be full width.
const ModalCard = styled(Card)`
  > div:first-child {
    margin: -16px -16px 16px;
  }
`;

const shingleColorUrl = (shingleLine, shingleColor) => {
  return `/${Board.build.language}/roofing/shingles/${shingleLine}?color=${shingleColor}`;
};

const Shingle = connectLocation(({ aspectRatio, locale: {code}, t, ...shingle}) => {

  const shingleModalBody = (shingleColor) => {
    return(
      <ModalCard
        transform={null}
        aspectRatio={{selection: "none"}}
        img={shingleColor.galleryImage}
        socialShare={shingleColor.socialShare}
      >
        <RichText content={`${t('shingles.card_title', {shingle: shingleColor.lineName})}`} inline/>
        <H3 font='body'>{shingleColor.colorName}</H3>
        <Cta
          href={shingleColorUrl(shingleColor.shingle_lines[0], shingleColor.shingle_color_names[0])}
          target="_blank"
        >
          {t('shingles.explore')}
        </Cta>
      </ModalCard>
    );
  };

  // TODO: Consider this modal opening function might be needed to be moved to the ItemList
  // component, since the final product requires pagination from the modal.
  const handleShingleSelection = (event, shingleColor) => {
    event.preventDefault();
    Board.modal.open({
      body: () => shingleModalBody(shingleColor),
      whiteCloseButton: true,
    });
  };

  return (
    <ShingleCard
      target="_blank"
      href={shingleColorUrl(shingle.shingle_lines[0], shingle.shingle_color_names[0])}
      onClick={(event) => handleShingleSelection(event, shingle)}
    >
      <Card
        transform={null}
        aspectRatio={shingle.aspectRatio}
        img={shingle.galleryImage}
      >
        <RichText content={`${t('shingles.card_title', {shingle: shingle.lineName})}`} inline/>
        <H3 font='body'>{shingle.colorName}</H3>
      </Card>
    </ShingleCard>
  );
});

export default Shingle

import styled from '@emotion/styled';
import { connectLocation } from '../../location/LocationConnectors';

const Table = styled.table`
  margin-bottom: 1em;
`;

const Stars = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
`;

const SideHeading = styled.span`
  font-weight: 700;
  text-transform: uppercase;
`;

const Fact = styled.span`
  display: block;
  margin-bottom: 8px;
  padding-left: 16px;
  position: relative;
  text-align: left;

  &.plus, &.minus {
    &:before {
      left: 0;
      position: absolute;
    }
  }

  &.plus {
    &:before {
      color: green;
      content: '+';
    }
  }

  &.minus {
    &:before {
      color: red;
      content: '–';
    }
  }
`;

const LegendBox = styled.div`
  display: inline-block;
  padding-bottom: 0.25em;
  padding-top: 0.25em;
  position: relative;
  width: 100%;
`;

const LegendItem = styled.div`
  display: inline-block;
  min-width: 6em;
`;

const Green = styled.span`
  color: green;
`;

const Red = styled.span`
  color: red;
`;

const Strong = styled.strong`
  display: inline-block;
  margin-left: 0.25em;
`;

const VidaWoolComparisonTable = connectLocation(({ t }) => {

  return (
    <>
      <div className="oc-table-responsive">
        <Table className="oc-table table-striped align-top">
          <thead>
            <tr>
              <th></th>
              <th>Coco Coir</th>
              <th>Soil Potting Mix</th>
              <th>Vidawool™ Mineral Wool</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><SideHeading>Water And Nutrient Dispersion</SideHeading></td>
              <td>
                <Stars style={{ color: 'orange' }} >* *</Stars>
                <Fact className="plus">Loose structure promotes root growth and water/nutrient uptake</Fact>
                <Fact className="minus">May require mixing with other materials to optimize drainage/nutrients</Fact>
              </td>
              <td>
                <Stars style={{ color: 'red' }}>*</Stars>
                <Fact className="plus">Naturally nutrient-rich</Fact>
                <Fact className="minus">Nutrients may be supplied slowly over time, requiring amendments</Fact>
              </td>
              <td>
                <Stars style={{ color: 'green' }} >* * *</Stars>
                <Fact className="plus">Designed with Hydro-Xtend™ water dispersion technology</Fact>
                <Fact className="plus">Offers even water dispersion and nutrient availability</Fact>
                <Fact className="plus">Provides predictable water retention</Fact>
              </td>
            </tr>
            <tr>
              <td><SideHeading>Labor Savings</SideHeading></td>
              <td>
                <Stars style={{ color: 'orange' }}>* *</Stars>
                <Fact className="plus" className="plus">pH neutral</Fact>
                <Fact className="minus">May need to be pre-rinsed to reduce salt content</Fact>
              </td>
              <td>
                <Stars style={{ color: 'red' }}>*</Stars>
                <Fact className="minus">Usually requires custom mixing</Fact>
              </td>
              <td>
                <Stars style={{ color: 'green' }}>* * *</Stars>
                <Fact className="plus">Designed to work as a system for easy transplantation from stage to stage</Fact>
                <Fact className="plus">No material mixing required</Fact>
                <Fact className="minus">Must be conditioned to proper pH before use</Fact>
              </td>
            </tr>
            <tr>
              <td><SideHeading>Repeatability/Error Reduction</SideHeading></td>
              <td>
                <Stars style={{ color: 'red' }}>*</Stars>
                <Fact className="minus">Loose material requires care in transplanting</Fact>
              </td>
              <td>
                <Stars style={{ color: 'red' }}>*</Stars>
                <Fact className="minus">Mix content itself may very</Fact>
                <Fact className="minus">No pre-set planting guides</Fact>
              </td>
              <td>
                <Stars style={{ color: 'green' }}>* * *</Stars>
                <Fact className="plus">Pre-drilled holes and pre-cut plant guides reduce guesswork</Fact>
              </td>
            </tr>
            <tr>
              <td><SideHeading>Efficient Water Use</SideHeading></td>
              <td>
                <Stars style={{ color: 'orange' }} >* *</Stars>
                <Fact className="plus">Excellent wettability</Fact>
                <Fact className="minus">Decomposes during growing, affecting water retention</Fact>
              </td>
              <td>
                <Stars style={{ color: 'red' }} >*</Stars>
                <Fact className="minus">Mixes can be hydrophobic</Fact>
              </td>
              <td>
                <Stars style={{ color: 'green' }} >* * *</Stars>
                <Fact className="plus">Hydro-Xtend™ water dispersion technology prevents formation of dry zones</Fact>
                <Fact className="plus">Tight wrapping prevents pooling away from plant</Fact>
              </td>
            </tr>
            <tr>
              <td><SideHeading>Plant Steerability</SideHeading></td>
              <td>
                <Stars style={{ color: 'red' }} >*</Stars>
                <Fact className="minus">Additional nutrients may be required</Fact>
              </td>
              <td>
                <Stars style={{ color: 'orange' }} >* *</Stars>
                <Fact className="plus">Highly customizable</Fact>
                <Fact className="minus">May require many inputs</Fact>
              </td>
              <td>
                <Stars style={{ color: 'green' }} >* * *</Stars>
                <Fact className="plus">Inert material: customizable to your growth practices</Fact>
              </td>
            </tr>
            <tr>
              <td><SideHeading>Pest/Disease Resistance</SideHeading></td>
              <td>
                <Stars style={{ color: 'orange' }} >* *</Stars>
                <Fact className="plus">Has some natural pest/disease-resistant properties</Fact>
                <Fact className="minus">Additional chemical treatments can interfere with plant growth</Fact>
              </td>
              <td>
                <Stars style={{ color: 'red' }} >*</Stars>
                <Fact className="minus">May require use of inoculants or bio controls due to susceptibility to pathogen growth</Fact>
              </td>
              <td>
                <Stars style={{ color: 'green' }} >* * *</Stars>
                <Fact className="plus">Sterile material resists growth of mold/fungus and infestation</Fact>
              </td>
            </tr>
            <tr>
              <td><SideHeading>Sustainability of Material</SideHeading></td>
              <td>
                <Stars style={{ color: 'orange' }} >* *</Stars>
                <Fact className="plus">Derived from natural material</Fact>
                <Fact className="plus">Reusable</Fact>
                <Fact className="minus">Overseas production and shipping increases transportation/fuel consumption</Fact>
              </td>
              <td>
                <Stars style={{ color: 'orange' }} >* *</Stars>
                <Fact className="plus">Derived from natural material</Fact>
                <Fact className="minus">Peat content can affect sustainability profile</Fact>
              </td>
              <td>
                <Stars style={{ color: 'orange' }} >* *</Stars>
                <Fact className="plus">Made from minimum 70% recycled content</Fact>
                <Fact className="plus">North American manufacture and distribution reduces transportation carbon footprint</Fact>
                <Fact className="minus">Not generally recycled or reused at end of life</Fact>
              </td>
            </tr>
          </tbody>
        </Table>

        <LegendBox>
          <LegendBox>
            <LegendItem>
              <Green>+</Green>
              <Strong>Pros</Strong>
            </LegendItem>
            <LegendItem>
              <Green>* * *</Green>
              <Strong>Most advantages</Strong>
            </LegendItem>
          </LegendBox>
          <LegendBox>
            <LegendItem>
              <Red>–</Red>
              <Strong>Cons</Strong>
            </LegendItem>
            <LegendItem>
              <Red>*</Red>
              <Strong>Least advantages</Strong>
            </LegendItem>
          </LegendBox>
        </LegendBox>
      </div>
    </>
  )
});

export default VidaWoolComparisonTable;
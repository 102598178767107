import { ui } from '@owenscorning/pcb.alpha';
import Article from './Article';

const InsulationResidential = Article(
  'Article::InsulationResidential',
  'insulation_residential_blog',
  [
    'SocialMediaShareBanner',
    'RelatedArticles',
    'JumpToSolutionSuite'
  ],
  ui`AttributeSet`({
    query: {
      name: 'Blog - Insulation Homeowner',
      path: '/insulation'
    }
  }),
  {
    version: '0.1',
    displayName: 'Insulation Homeowner Article Builder',
    paths: ['insulation/residential/blog'],
  }
)

export default InsulationResidential;

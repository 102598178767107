import _ from 'lodash';
export default ({ value={}, onChange }) => {
  if (_.isEmpty(value)) value = {};

  return <>
    <UI.List.Visibility.Toggle
      hidden={value.__hidden}
      onToggle={(hidden) => onChange(_.set(value, '__hidden', hidden))}
    />
    <UI.List.Visibility.Groups
      item={value}
      onSubmit={(value) => onChange(value)}
    />
  </>
}

import { ui, when } from '@owenscorning/pcb.alpha';

export default ui`Form`.of({
  selection: ui`Choices`.of({
    none: 'None / Freeform',
    ['2.5']: 'Extra Wide (2.5)',
    ['2']: 'Wide (2.0)',
    ['1']: 'Square (1.0)',
    ['0.77']: 'Tall / Document (0.77)',
    custom: 'Custom'
  })({
    label: 'Image Aspect Ratio',
    default: '2',
    mode: ui`Choices/Mode/Dropdown`
  }),
  custom: ui`Number`({
    min: 0.01,
    default: 1.00,
    precision: 2,
    label: 'Custom Aspect Ratio Value',
    flags: {
      Right: [
        <a onClick={
          () => Board.modal.open({
            body: <div>The aspect ratio is the width/height ratio, so if you'd like the width to be 3 times the height, then 3.0 is the ratio value.</div>
          })
        } style={{ cursor: 'pointer' }}>Learn More</a>
      ]
    },
    visible: when`../selection`.is.equal.to('custom').then(true).otherwise(false)
  })
});

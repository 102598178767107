import { connect } from 'react-redux';

import { setColorSelected } from '../actions/StyleBoardActions';

const connectStyleBoard = connect(
  state => ({ ...state.styleBoard }),
  { setColorSelected },
  null,
  { forwardRef: true }
);

export default connectStyleBoard;

import styled from '@emotion/styled';
import {useState} from 'react';
import NumberFormat from "react-number-format";

// Components
import Table from '../../ComponentLibrary/oc-table';
import {H3, H5} from '../../ComponentLibrary/text-elements/oc-h';

const ResultsContainer = styled.div`
	td {
		height: 54px;
		width: 50%;
	}
`;

const PinkText = styled.span`
	color: #d40f7d;
	display: block;
	font-weight: 700;
`;

const HighlightedResult = styled.div`
	font-size: 34px;
	font-family: 'Oswald';
	color: #d40f7d;
`;

const Footnotes = styled.div`
	margin: 32px 0 0;
`;

const ProCatResults = ({
	targetInsulationDepth,
	insulationNeeded,
	bagsNeeded,
	blowTime,
	totalMaterialCost,
	totalLaborCost,
	totalCost,
	customerCost,
	margin,
	footnotes
}) => (
	<ResultsContainer>
		<H3 tag="h2" font="body">Materials and Time Estimates</H3>

		<Table striped margin="0 0 32px">
			<tbody>
				<tr>
					<td><strong>Target Total Inches of Insulation</strong></td>
					<td>
						{targetInsulationDepth ? 
							<><PinkText>{targetInsulationDepth} inches</PinkText> (Calculated based on DoE R-Value selected)</>
						:
							'-'
						}
					</td>
				</tr>
				<tr>
					<td><strong>Inches of Insulation Needed</strong></td>
					<td>
						{insulationNeeded ? 
							<><PinkText>{insulationNeeded} inches</PinkText> Target - Current = Need</>
						:
							'-'
						}
					</td>
				</tr>
				<tr>
					<td><strong>Bags Needed</strong></td>
					<td>
						{bagsNeeded ? 
							<PinkText><NumberFormat decimalScale={1} thousandSeparator={true} displayType="text" value={bagsNeeded}/></PinkText>
						:
							'-'
						}
					</td>
				</tr>
				<tr>
					<td><strong>Time to Blow In</strong></td>
					<td>
						{blowTime ? 
							<PinkText><NumberFormat decimalScale={1} thousandSeparator={true} displayType="text" value={blowTime}/> Hours</PinkText>
						:
							'-'
						}
					</td>
				</tr>
			</tbody>
		</Table>

		<H3 tag="h2" font="body">Cost Estimates</H3>

		<Table striped margin="0 0 32px">
			<tbody>
				<tr>
					<td><strong>Total Material Cost</strong></td>
					<td>
						{totalMaterialCost ? 
							<PinkText><NumberFormat decimalScale={2} prefix="$" thousandSeparator={true} displayType="text" value={totalMaterialCost}/></PinkText>
						:
							'-'
						}
					</td>
				</tr>
				<tr>
					<td><strong>Total Labor Cost</strong></td>
					<td>
						{totalLaborCost ? 
							<PinkText><NumberFormat decimalScale={2} prefix="$" thousandSeparator={true} displayType="text" value={totalLaborCost}/></PinkText>
						:
							'-'
						}
					</td>
				</tr>
				<tr>
					<td><strong>Total Direct Cost</strong></td>
					<td>
						{totalCost ?
							<PinkText><NumberFormat prefix="$" decimalScale={2} thousandSeparator={true} displayType="text" value={totalCost}/></PinkText>
						:
							'-'
						}
					</td>
				</tr>
				<tr>
					<td><strong>Customer Final Cost</strong></td>
					<td>
						{customerCost ?
							<PinkText><NumberFormat prefix="$" decimalScale={2} thousandSeparator={true} displayType="text" value={customerCost}/></PinkText>
						:
							'-'
						}
					</td>
				</tr>
			</tbody>
		</Table>

		<h5>Potential Gross Margin</h5>
		{margin ?
			<HighlightedResult><NumberFormat prefix="$" decimalScale={2} thousandSeparator={true} displayType="text" value={margin}/></HighlightedResult>
		:
			<HighlightedResult>-</HighlightedResult>
		}

		{ footnotes &&
			<Footnotes>
				{footnotes}
			</Footnotes>
		}
	</ResultsContainer>
);

export default ProCatResults;
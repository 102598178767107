import _ from 'lodash';

import { InputWrapper } from './InputWrapper';
import Label from '../../ComponentLibrary/input-elements/oc-label';
import TextInput from '../../ComponentLibrary/input-elements/oc-text-input';
import Icon from '../../OC/oc-icon';

// TODO: this could be moved to a helper
const titleCase = str => _.startCase(_.toLower(str));

const Error = ({ message }) => (
  <p>
    <Icon type="times-circle" /> {message}
  </p>
);

export const ZipPostalPicker = ({
  zipInfo,
  onChangeZip,
  userT,
  error: isError,
  zip,
}) => {
  const error = isError ? (
    <Error
      message={userT(
        `owenscorning.components.region_selector.invalid_${zipInfo.field_name}`
      )}
    />
  ) : null;

  return (
    <InputWrapper error={isError}>
      <Label
        text={titleCase(
          userT(`owenscorning.components.region_selector.${zipInfo.field_name}`)
        )}
      />
      <TextInput
        name={zipInfo.field_name}
        onChange={e => onChangeZip(e.target.value)}
        valid={!isError}
        error={error}
        touched
        value={zip}
      />
    </InputWrapper>
  );
};

import { jsx, css } from '@emotion/react';

export default ({ template='auto / repeat(9999, 1fr)', gap=0 }) => (
  <div css={ css`
    display: grid;
    grid-template: ${ template };
    gap: ${ gap }px;
  ` } >
    <Contents />
  </div>
);

import PinkBarVsSteelCalculator from '../../composites/PinkBarVsSteel/PinkBarVsSteelCalculator';
import RichText from './RichText';
import { BasicContent } from './BasicContent';


const CMSPinkBarVsSteelCalculator = ({content, calculator}) => {
  return (
    <>
      <BasicContent { ...content.copy } />
      <PinkBarVsSteelCalculator laborRate={calculator.laborRate}/>
      <RichText content={content.footnotes} />
    </>
  )
};

export default CMSPinkBarVsSteelCalculator;
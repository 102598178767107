import { appendAspectRatio, migrateArticleDataSetParameters, migrateDataSetParameters } from '../../../migrations';

export default {
  displayName: 'Category Feature',
  allowableParents: ['Section'],
  migrator: (data) => {
    data = appendAspectRatio(data)
    data = appendAspectRatio(data, 'aspectRatioCard')
    if (data.categories) {
      data.categories = data.categories.map(c => {
        c = migrateDataSetParameters(c, data.data?.dataset)
        if (data.data?.dataset === 'iss' || data.data?.dataset === 'roofing_blog') {
          c = migrateArticleDataSetParameters(c, data.data.dataset)
        }

        // PB-444: MediaObject images were using the UI`Upload/Image`, but now we are using
        //         UI`Image` component. This means that the structure is different and
        //         we need to alter it here in order to not break previously existing
        //         cards.
        if (typeof c.mediaObject?.image === 'string') {
          c.mediaObject = {
            ...c.mediaObject,
            image: {
              file: c.mediaObject.image,
              crop: undefined,
            }
          }
        }

        return c;
      })
    }
    return data;
  },
};

import { useState } from 'react';
import styled from '@emotion/styled';
import { jsx, css } from '@emotion/react';
import Cta from '../../OC/oc-cta';
import Modal from '../../Modal';
import Picture from '../../OC/oc-picture';
import { connectLocation } from "../../location/LocationConnectors";

const HotspotTopHeroContainer = styled.div`
  @media screen and (min-width: 768px) {
    background-size: 768px 240px!important;
    background-position: bottom center!important;
    background-image: url(${(props) => ((props.locale === 'en-CA' || props.locale === 'fr-CA') ? 'https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,q_auto/v1678994320/roofing/roofing-components/hero/roofing-components-hero-top.png' : 'https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,q_auto/v1678994320/roofing/roofing-components/hero/roofing-components-hero-top.png')});
    background-repeat: no-repeat;
  }

  @media screen and (min-width: 1200px), screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    background-size: 1340px 315px!important;
    background-image: url(${(props) => ((props.locale === 'en-CA' || props.locale === 'fr-CA') ? 'https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,q_auto/v1678994320/roofing/roofing-components/hero/roofing-components-hero-top.png' : 'https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,q_auto/v1678994320/roofing/roofing-components/hero/roofing-components-hero-top.png')});
  }
`;

const HotspotHeroContainer = styled.div`
  background-image: url(${(props) => ((props.locale === 'en-CA' || props.locale === 'fr-CA') ? 'https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,q_auto/v1678994328/roofing/roofing-components/hero/roofing-components-hero-tablet.png' : 'https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,q_auto/v1678994328/roofing/roofing-components/hero/roofing-components-hero-tablet.png')});
  background-size: 530px;
  background-position-x: -100px !important;
  background-position-y: 220px !important;
  margin-bottom: 35px;
  background-repeat: no-repeat;

  @media screen and (min-width: 768px) {
    background-image: url(${(props) => ((props.locale === 'en-CA' || props.locale === 'fr-CA') ? 'https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,q_auto/v1678994328/roofing/roofing-components/hero/roofing-components-hero-tablet.png' : 'https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,q_auto/v1678994328/roofing/roofing-components/hero/roofing-components-hero-tablet.png')});
    background-size: 768px 514px!important;
    background-position: bottom center!important;
  }

  @media screen and (min-width: 1200px), screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    background-image: url(${(props) => ((props.locale === 'en-CA' || props.locale === 'fr-CA') ? 'https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,q_auto/v1678994335/roofing/roofing-components/hero/roofing-components-hero-desktop.png' : 'https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,q_auto/v1678994335/roofing/roofing-components/hero/roofing-components-hero-desktop.png')});
    background-size: 1340px 686px!important;
  }
`;

const TopHeroGrid = styled.div`
	display: grid;
  @supports (display: grid) {
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(1, 30px) 40px;
  }

  @media (min-width: 768px) {
    @supports (display: grid) {
      grid-template-columns: 1fr repeat(12, 64px) 1fr;
      grid-template-rows: repeat(12, 20px);
    }
  }

  @media (min-width: 1200px), screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    -ms-grid-columns: 1fr 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 1fr;
    -ms-grid-rows: 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px;

    @supports (display: grid) {
      grid-template-columns: 1fr repeat(12, 100px) 1fr;
      grid-template-rows: repeat(9, 35px);
    }

    .fill {
      display: none;
      background: white;

      @media(min-width: 768px) {
      	display: block;
      }
    }
  }
`;

const HeroGrid = styled.div`
	display: grid;
  @supports (display: grid) {
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(24, 24px) 40px;
  }

  @media (min-width: 768px) {
    @supports (display: grid) {
      grid-template-columns: 1fr repeat(12, 64px) 1fr;
      grid-template-rows: repeat(28, 20px);
    }
  }

  @media (min-width: 1200px), screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    -ms-grid-columns: 1fr 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 1fr;
    -ms-grid-rows: 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px 35px;

    @supports (display: grid) {
      grid-template-columns: 1fr repeat(12, 100px) 1fr;
      grid-template-rows: repeat(20, 35px);
    }

    .fill {
      display: none;
      background: white;

      @media(min-width: 768px) {
      	display: block;
      }
    }
  }
`;

const Main = styled.div`
	background: white;
	z-index: 1;
  padding-top: 0;
  position: relative;
  grid-column: 1 / 1;
  grid-row: 1 / 6;
  padding: 0 15px 0 0;
  background: transparent;
  text-align: right;

  @media screen and (min-width: 768px) {
    background: white;
    position: relative;
    grid-column: 5 / 11;
    grid-row: 1 / 12;
    padding: 0 30px 0 0;
  }

  @media screen and (min-width: 1200px ) {
    position: relative;
    grid-column: 5 / 11;
    grid-row: 1 / 10;
    padding-top: 15px;
  }

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    position: relative;
    -ms-grid-column: ${(props) => props.locale === 'fr-CA' ? 4 : 5};
    -ms-grid-column-span: ${(props) => props.locale === 'fr-CA' ? 8 : 7};
    -ms-grid-row: 1;
    -ms-grid-row-span: 10;
  }
`;

const TextContent = styled.div`
  position: relative;
  grid-column: 1 / 1;
  grid-row: 5 / 7;
  background: transparent;
  text-align: right;
  z-index: 1;
  padding: 15px;

  @media screen and (min-width: 768px) {
    background: white;
    position: relative;
    grid-column: 5 / 11;
    grid-row: 8 / 12;
    padding-top: 0;
    display: block;
    text-align: left;
  }

  @media screen and (min-width: 1200px) {
    position: relative;
    grid-column: 6 / 11;
    grid-row: 7 / 11;
  }

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    position: relative;
    -ms-grid-column: 8;
    -ms-grid-column-span: 3;
    -ms-grid-row: 7;
    -ms-grid-row-span: 2;
  }
`;

const FillTop = styled.div`
	position: relative;
	background: white;
	z-index: 1;
	display: none;

  @media(min-width: 768px) {
  	display: block;
    grid-column: 1 / 6;
    grid-row: 1 / 1;
  }

  @media screen and (min-width: 1200px) {
    grid-column: 3 / 5;
    grid-row: 8 / 18;
  }

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    -ms-grid-column: 1;
    -ms-grid-column-span: 6;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
  }
`;

const SecondFillTop = styled.div`
	position: relative;
	background: white;
	z-index: 1;
	display: none;

  @media(min-width: 768px) {
  	display: none;
    grid-column: 1 / 6;
    grid-row: 1 / 1;
  }

  @media screen and (min-width: 1200px) {
    grid-column: 1 / 5;
    grid-row: 1 / 1;
  }

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    -ms-grid-column: 1;
    -ms-grid-column-span: 6;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
  }
`;

const ThirdFillTop = styled.div`
	position: relative;
	background: white;
	z-index: 1;
	display: none;

  @media(min-width: 768px) {
  	display: block;
    grid-column: 1 / 6;
    grid-row: 1 / 1;
  }

  @media screen and (min-width: 1200px) {
    grid-column: 14 / 15;
    grid-row: 7 / 10;
  }

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    -ms-grid-column: 1;
    -ms-grid-column-span: 6;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
  }
`;

const FourthFillTop = styled.div`
	position: relative;
	background: white;
	z-index: 1;
	display: none;

  @media(min-width: 768px) {
  	display: block;
    grid-column: 4 / 14;
    grid-row: 0 / 0;
  }

  @media screen and (min-width: 1200px) {
    display: none;
  }

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    -ms-grid-column: 1;
    -ms-grid-column-span: 6;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
  }
`;

const InteractiveContainer = styled.div`
	position: relative;
  grid-column: 1 / 1;
  grid-row: 3 / 23;
  padding: 0;
  margin-top: 15px;

  @media screen and (min-width: 768px) {
    position: relative;
    grid-column: 2 / 14;
    grid-row: 5 / 27;
    margin: 0;
  }

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    position: relative;
    -ms-grid-column: 2;
    -ms-grid-column-span: 12;
    -ms-grid-row: 13;
    -ms-grid-row-span: 16;
    padding: 15px;
  }
`;

const MenuContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;

  @media(min-width: 768px) {
    right: 15px;
    transform: translateX(0);
    left: auto;
    width: auto;
  }

  h5 {
    margin-bottom: 10px;
    font-size: 13px;

    @media(min-width: 768px) {
      font-size: 16px;
    }

    @media(min-width: 1200px) {
      margin-bottom: 30px;
      font-size: 20px;
    }
  }

  .fac-cta {
    width: 100%;
  }
`;

const MenuGroup = styled.div`
  background: white;
  margin-bottom: 10px;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 50%);
  padding: 10px 20px;

  @media(min-width:  1200px) {
    padding: 15px 20px;
  }
`;

const GroupHeading = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 10px;

  @media(min-width:  1200px) {
    font-size: 21px;
    line-height: 21px;
    margin-bottom: 15px;
  }
`;

const MenuCTAStyle = css`
  display: block;
  line-height: 16px;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 0;
  border-bottom: 1px solid #e6e6e6;
  color: #333333;
  text-decoration: none;

  @media(min-width: 1200px) {
    line-height: 18px;
    font-size: 18px;
    padding: 10px 0;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  &:hover, &:focus, &.selected, &.hover {
    color: #d40f7d;
    cursor: pointer;
    text-decoration: underline;
  }

  &:focus {
    outline-offset: 2px;
    outline-color: #d40f7d;
    outline-style: solid;
    outline-width: 2px;
  }
`;

const HotSpotTrigger = styled.span`
  ${MenuCTAStyle}
`
const MenuLink = styled.a`
  ${MenuCTAStyle}
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:after {
    content: '\f054';
    color: black;
    font-family: 'fontawesome';
  }

  &:hover {
    &:after {
      color: #d40f7d;
      text-decoration: none;
    }
  }
`

const HotSpot = styled.span`
  color: black;
  background: white;
  font-weight: 700;
  position: absolute;
  border-radius: 15px;
  border: 2px solid white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, .75);
  display: none;

  @media(min-width: 768px) {
    display: block;
    font-size: 17px;
    line-height: 16px;
    padding: 0 4px;
  }

  @media(min-width: 1200px) {
    font-size: 26px;
    line-height: 26px;
    padding: 0 6px;
  }

  &:hover, &.selected, &.hover {
    color: white;
    background: #d40f7d;
    box-shadow: 0px 0px 17px rgba(212, 15, 125, 1);
    cursor: pointer;
    text-decoration: none;

    @media(min-width: 1200px) {
      box-shadow: 0px 0px 35px rgba(212, 15, 125, 1);
    }
  }

  &.ice-water {
    @media(min-width: 768px) {
      top: 136px;
      left: 161px;
    }

    @media(min-width: 1200px ) {
      top: 209px;
      left: 202px;
    }
  }

  &.synthetic-underlayment {
    @media(min-width: 768px) {
      top: 86px;
      left: 254px;
    }

    @media(min-width: 1200px ) {
      top: 85px;
      left: 378px;
    }
  }

  &.starter-shingles {
    @media(min-width: 768px) {
      top: 158px;
      left: 200px;
    }

    @media(min-width: 1200px ) {
      top: 234px;
      left: 288px;
    }
  }

  &.laminate-shingles {
    @media(min-width: 768px) {
      bottom: 391px;
      left: 310px;
    }

    @media(min-width: 1200px ) {
      bottom: 530px;
      left: 468px;
    }
  }

  &.hip-and-ridge {
    @media(min-width: 768px) {
      top: 1px;
      left: 280px;
    }

    @media(min-width: 1200px ) {
      top: -48px;
      left: 404px;
    }
  }

  &.intake-ventilation {
    @media(min-width: 768px) {
      bottom: 250px;
      left: 247px;
    }

    @media(min-width: 1200px ) {
      bottom: 255px;
      left: 359px;
    }
  }

  &.exhaust-ventilation {
    @media(min-width: 768px) {
      bottom: 422px;
      left: 366px
    }

    @media(min-width: 1200px ) {
      bottom: 580px;
      left: 565px;
    }
  }

  &.loosefill-insulation {
    @media(min-width: 768px) {
      bottom: 294px;
      left: 361px
    }

    @media(min-width: 1200px ) {
      bottom: 342px;
      left: 543px;
    }
  }
`;

const StyledImage = styled.img`
  height: 32px;
`

const InfoModalBody = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 65px;

  @media(min-width: 768px) {
    flex-direction: row;
  }

  a {
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media(min-width: 768px) {
    a {
      margin-right: 16px;
      margin-bottom: 0;

      &:last-of-type {
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }

  .columns {
    display: flex;
    flex-direction: column-reverse;

    @media(min-width: 768px) {
      flex-direction: row;
    }
  }

  .content-column {
    margin-bottom: 15px;

    @media(min-width: 768px) {
      margin-bottom: 0;
      flex-basis: 53%
    }

    hr {
      margin: 15px 0;
    }

    &.image {
      text-align: center;

      @media(min-width: 768px) {
        flex-basis: 47%;

        .image-container {
          padding-left: 35px;
        }
      }

      img {
        max-width: 100%;
      }
    }
  }

  .pink {
    color: #d40f7d;
  }
`;

const ModalColumnContent = styled.div`
  margin-bottom: 15px;

  @media(min-width: 768px) {
    margin-bottom: 0;
    flex-basis: 53%
  }

  .oc-cta-button {
    margin-top: 15px;
  }

  hr {
    margin: 15px 0;
  }
`;

const ModalColumnImage = styled.div`
  text-align: center;

  @media(min-width: 768px) {
    flex-basis: 47%;

    .image-container {
      padding-left: 35px;
    }
  }

  img {
    max-width: 100%;
  }
`;

const RoofingComponentsInteractiveHero = connectLocation(({ t, locale }) => {
  const applications = t('components.hero.interactive_hero.applications');
  const hotspots = t('components.hero.interactive_hero.hotspots');
  const modals = t('components.hero.interactive_hero.modals');

  const [hovered, setHovered] = useState('');
  const [selected, setSelected] = useState('');
  const [active, setActive] = useState(false);

  const onClose = () => {
    setActive(false);
    setSelected('');
  }

  return (
    <>
      <HotspotTopHeroContainer>
        <TopHeroGrid>
          <Main className="main rball-header" locale={locale.code}>
            <span className="prehead" >{t('components.hero.prehead')}</span>
            <h1 className="tier2">
              {t('components.listing_heading')}
            </h1>
          </Main>

          <TextContent>
            <p>{t('components.hero.p')}</p>
          </TextContent>
          <FillTop />
          <SecondFillTop />
          <ThirdFillTop />
          <FourthFillTop />
        </TopHeroGrid>
      </HotspotTopHeroContainer>
      <HotspotHeroContainer locale={locale.code} >
        <HeroGrid>
          <InteractiveContainer className="test">
            <MenuContainer>
              <h5><strong>{t('components.hero.interactive_hero.heading')}</strong></h5>

              <MenuGroup>
                {
                  applications.map(menuItem => (
                      menuItem.image ?
                        <StyledImage src={menuItem.image} /> :
                        <HotSpotTrigger
                          className={(hovered === menuItem.data_target || selected === menuItem.data_target) && 'hover'}
                          onMouseEnter={() => setHovered(menuItem.data_target)}
                          onMouseLeave={() => setHovered('')}
                          onClick={() => setSelected(menuItem.data_target)}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter' || e.key === 'Space') {
                              setSelected(menuItem.data_target);
                            }
                          }}
                          tabIndex="0"
                          role="menuitem"
                          aria-haspopup="true"
                          aria-expanded="false"
                          data-track="select-by"
                          data-track-select-category="Commercial Applications"
                          data-track-select-option={menuItem.name}
                        >
                          {menuItem.name}
                        </HotSpotTrigger>
                  ))
                }
              </MenuGroup>
            </MenuContainer>

            {
              hotspots.map(hotspot => (
                <HotSpot
                  className={`${(hovered === hotspot.data_target || selected === hotspot.data_target) && 'hover'} ${hotspot.class_name}`}
                  onMouseEnter={() => setHovered(hotspot.data_target)}
                  onMouseLeave={() => setHovered('')}
                  data-track="select-by"
                  data-track-select-category="Commercial Applications"
                  data-track-select-option={hotspot.select_object}
                  tabindex="-1"
                  onClick={() => setSelected(hotspot.data_target)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === 'Space') {
                      setSelected(hotspot.data_target);
                    }
                  }}
                >
                  +
                </HotSpot>
              ))
            }

            {modals.map(modal => (
              <Modal name={modal.heading} size="md" open={modal.id === selected && (() => setActive(true))} onClose={onClose}>
                <InfoModalBody>
                  <ModalColumnContent>
                    <h2 className="h5">{modal.heading}</h2>
                    <h3 className="h3" dangerouslySetInnerHTML={{ __html: modal.content_heading }} />

                    <p className="pr-2">{modal.text}</p>

                    <Cta href={modal.cta_url}
                      data-track="learn-more"
                      data-track-element-location="hero CTA"
                      data-track-destination={modal.data_track_destination}
                      dangerouslySetInnerHTML={{ __html: t('residential.modal_cta_text') }}
                    />
                  </ModalColumnContent>

                  <ModalColumnImage>
                    <Picture source={modal.img} />
                  </ModalColumnImage>
                </InfoModalBody>
              </Modal>
            ))
            }

          </InteractiveContainer>
        </HeroGrid>
      </HotspotHeroContainer>
    </>
  )
})

export default RoofingComponentsInteractiveHero;

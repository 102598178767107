import { define, ui } from '@owenscorning/pcb.alpha';

import Renderer from '../../../../OC/PageBuilder/PadpPromoCalc';


export default define`PADP Promo Funds Calculator`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/PadpPromoCalc`,
  category: ui`Modules/Categories/Page/Prebuilt`,
  view: ({ value }) => <Renderer { ...value } />,
  edit: {
    content: ui`Form`.of({
      copy: ui`Content/Basic`,
    })({ label: 'Content' }),
  },
});

import * as jsonpatch from 'fast-json-patch';

export const generatePatch = (doc1, doc2) => (
  jsonpatch.compare(doc1, doc2)
);

export const applyPatches = (doc, patchList) => (
  jsonpatch.applyPatch(doc, patchList).newDocument
)

if (String && String.prototype && !String.prototype.startsWith) {
  Object.defineProperty(String.prototype, 'startsWith', {
    value: function(search, rawPos) {
      var pos = rawPos > 0 ? rawPos|0 : 0;
      return this.substring(pos, pos + search.length) === search;
    }
  });
}

export const findConflicts = (patchList1, patchList2) =>
{
  if (!patchList1) return false;
  if (!patchList2) return false;
  for (let p1 of patchList1) {
    for (let p2 of patchList2) {
      const p1Path = p1.path;
      const p1From = p1.from || '';
      const p2Path = p2.path;
      const p2From = p2.from || '';
      if (
        p1.path.startsWith(p2.path) || (p2.from && p1.path.startsWith(p2.from))
        || p2.path.startsWith(p1.path) || (p1.from && p2.path.startsWith(p1.from))
      ) {
        return true;
      }
    }
  }
  return false;
};

import styled from '@emotion/styled';
import Icon from '../OC/oc-icon.jsx';

const AjaxOverlayWrapper = styled.div`
  position: relative;
  height: 100%;
  min-height: 50px;
`;

const LoaderProgress = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: white;
  opacity: .8;
  span {
    position: absolute;
    left: 50%;
    width: 18px;
  }
`;

const Spinner = styled.div`
  padding: 10px;
  height: 28px;
`;

const AjaxOverlay = ({show, fetching, children, message}) => (
  <AjaxOverlayWrapper>
    {children}
    {
      (show||fetching) &&
      <LoaderProgress>
        {fetching && <Spinner>
                        <Icon type="spinner" className="fa-spin fa-lg"/>
                      </Spinner>
        }
        {message &&
          <div>
            {message}
          </div>
        }
      </LoaderProgress>
    }
  </AjaxOverlayWrapper>
);

export default AjaxOverlay

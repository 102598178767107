import { jsx, css } from '@emotion/react';
import { define, ui, when, s } from '@owenscorning/pcb.alpha';

import Iframe from '../../../../ComponentLibrary/oc-iframe';
import { BasicContent } from '../../../../OC/PageBuilder/BasicContent.jsx';

const Renderer = ({ copy, pym, iframeId, iframeSrc, iframeSettings }) => {

  return (
    <>
      <BasicContent { ...copy } />
      <Iframe
        pymjs={ pym?.pymjs }
        src={ iframeSrc?.src }
        iframeId={ iframeId?.id }
        scrolling={ iframeSettings?.scrolling}
        desktopHeight={ iframeSettings?.desktopHeight}
        tabletHeight={ iframeSettings?.tabletHeight}
        mobileHeight={ iframeSettings?.mobileHeight}
      />
    </>
  )
};

export default define`Iframe`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/Iframe`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value }) => <Renderer { ...value } />,
  edit: {
    copy: ui`Content/Basic`,
    pym: ui`Form`.of({
      pymjs: ui`Switch`({
        label: 'Use pym.js for height',
        default: false
      }),
      [s._]: ui`Tip`.of('To use pym.js, you must be able to add a script to the page you are iframing.'),
    }),
    iframeId: ui`Form`.of({
      id: ui`Text`({label: 'Iframe Id'})
    })({
      visible: when`../pym/pymjs`.is.equal.to(true)
    }),
    iframeSrc: ui`Form`.of({
      src: ui`Text`({label: 'source of iframe'})
    }),
    iframeSettings: ui`Form`.of({
      scrolling: ui`Choices`.of({
        'auto': 'Auto',
        'no': 'No Scrolling'
      })({
        label: 'Scrolling',
        default: 'auto' 
      }),
      desktopHeight: ui`Text`({label: 'Desktop Height'}),
      tabletHeight: ui`Text`({label: 'Tablet Height'}),
      mobileHeight: ui`Text`({label: 'Mobile Height'}),
      [s._]: ui`Tip`.of('You can use px, %, em, or rem. Example: 300px')
    })
    ({
      label: 'Iframe Settings',
      visible: when`../pym/pymjs`.is.equal.to(false)
    })
  },
});
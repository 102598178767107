import { useState } from 'react';
import styled from '@emotion/styled';

// Components
import { BasicContent } from './BasicContent';
import Tab from '../../ComponentLibrary/tabs/Tab';
import TabGroup from '../../ComponentLibrary/tabs/TabGroup';

// Utilities
import { Mobile, Tablet, Desktop } from './internals/Devices';
import { Screens } from '../../PageBuilder/editing/Viewport';

// Styled Components
const ModuleContainer = styled.div`
  display: block;
  margin: ${props => (props.alignment == "center") ? "0 auto" : "0"};
  position: relative;
  width: 100%;

  @media (min-width: ${Screens.Tablet}px) {
    ${({ size, width }) => size == 'full-bleed' ? `max-width: none` : `max-width: ${width.Tablet}px` };
  }

  @media (min-width: ${Screens.Desktop}px) {
    ${({ size, width }) => size == 'full-bleed' ? `max-width: none` : `max-width: ${width.Desktop}px` };
  }
`;

const ContentContainer = styled.div`
  display: ${props => props.active ? 'block' : 'none'};
`;

const TabModule = styled.div`
  padding-top: 48px;

  ${props => props.separator && `
    border-bottom: 2px solid #000000;
    padding-bottom: 48px;

    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }
  `}
`;

const Sizes = {
  'xl': { [Mobile]: 345, [Tablet]: 738, [Desktop]: 1170 },
  'l': { [Mobile]: 345, [Tablet]: 738, [Desktop]: 970 },
  'm': { [Mobile]: 225, [Tablet]: 608, [Desktop]: 770 },
};

const SectionRenderer = ({ separator, value }) => {
  return (
    <>
      {(value || []).map(module => <ModuleRenderer separator={separator} value={module} />)}
    </>
  );
};

const ModuleRenderer = ({ separator, value }) => {
  const definition = UI.Modules?.Page?.[value.type];
  const Component = definition?.modes?.view || (({ value }) => <div></div>);
  return (
    <TabModule separator={separator}>
      <Component value={value?.data} />
    </TabModule>
  );
};

const CMSTabbedContent = ({ size = "xl", alignment = "center", settings, tabs, copy, }) => {
  let active = tabs?.findIndex(tab => tab?.defaultTab == true);
  const [isActive, setIsActive] = useState(active >= 0 ? active : [0]);
  const width = Sizes[size];

  const onClickHandler = (i) => {
    setIsActive(i);
  };

  return (
    <ModuleContainer alignment={alignment} size={size} width={width}>
      <BasicContent { ...copy } />
      <TabGroup tabsAlign={settings?.tabsAlign} tabSize={settings?.tabSize} tabCount={tabs?.length} >
        {tabs &&
          tabs.map((tab, index) => {
            return (
              <Tab
                key={`t-${index}`}
                tabSize={settings?.tabSize}
                tabGraphic={settings?.iconImage}
                url={tab.url}
                active={(isActive == index)}
                onClick={() => onClickHandler(index)}
                label={tab.heading}
                icon={tab.icon}
                iconImage={tab.iconImage}
              />
            )
          })
        }
      </TabGroup>

      {tabs &&
        tabs.map((tab, index) => (
          <>
            {!tab.url &&
              <ContentContainer key={`t-${index}`} active={isActive == index}>
                <SectionRenderer separator={tab.moduleSeparator} value={tab.tabContent} />
              </ContentContainer>
            }
          </>
        ))
      }
    </ModuleContainer>
  );
};

export default CMSTabbedContent;

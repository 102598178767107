import {jsx, css} from '@emotion/react';

const styles = {};

styles.stepHeadingIntro = css`
  margin-bottom: 30px;

  .stepHeading {
    margin-bottom: 0;
  }

  @media print {
    margin-bottom: 30px;
  }
`;

styles.alertSpacing = css`
  margin-bottom: 16px;
`;

styles.optionCategories = css`
  margin-bottom: 30px;

  .categoryHeading {
    font-size: 14px;
    font-family: 'OCRoboto';
    font-weight: 500;
    margin: 0 0 10px;
  }

  ul {
    list-style-type: none;
    margin: 0 0 30px;
    padding: 0;
  }
`;

styles.step_item = css`
  padding: 10px 15px;
  margin: 0;
  border-left: 4px solid white;
  display: flex;

  &:hover {
    border-color: #D1D1D1;
    background-color: #F8F8F8;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.15);
    cursor: pointer;
  }

  p {
    margin-top: 0;
    font-size: 14px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

styles.active_step_item = css`
  ${styles.step_item}

  border-color: #D40f7d;
  background-color: #E6E6E6;

  &:hover {
    border-color: #D40f7d;
    background-color: #E6E6E6;
    box-shadow: none;
    cursor: default;
  }
`;

styles.optionName = css`
  font-size: 16px;
  font-weight: 700;
`;

styles.headingInfo = css`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  align-items: center;

  .headingPricingContainer {
    margin-left: auto;
  }
`;

styles.stepItemContent = css`
  width: 100%;
`;

styles.stepItemImage = css`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  margin-right: 10px;
`;

styles.badgeContainer = css`
  margin-bottom: 5px;
  flex: 0 0 100%;

  .badge {
    background-color: #6D6D6D;
    color: white;
    font-size: 11px;
    line-height: 13px;
    font-weight: 700;
    text-align: center;
    padding: 3px 6px;
    text-transform: uppercase;
    border-radius: 15px;
  }
`;

styles.shingleColorsList = css`
  list-style-type: none;
  display: flex;
  margin: 5px 0 0;
  flex-wrap: wrap;

  .colorTooltip {
    display: none;
    position: absolute;
    bottom: -38px;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    text-align: center;
    font-size: 14px;
    color: #333;
    padding: 5px;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.25);
    min-width: 100px;
    white-space: nowrap;

    &:before {
      content:'';
      display:block;
      width:0;
      height:0;
      position:absolute;
      border-left: 8px solid transparent;
      border-bottom: 8px solid white;
      border-right:8px solid transparent;
      top:-8px;
      left:50%;
      transform: translateX(-50%);
    }
  };

  li {
    margin: 0 3px 3px 0;
    position: relative;
    border: 2px solid transparent;

    @media(min-width: 1200px) {
      &:nth-child(6n) {
        margin-right: 0;
      }
    }

    &:hover {
      border-color: black;
      cursor: pointer;

      .colorTooltip {
        display: block;
        z-index: 100;
      }
    }
  }

  img {
    width: 50px;
    height: 50px;
    display: block;
  }
`;

styles.active_shingle_color = css`
  border-color: #D40F7D !important;
`;

styles.optionsContainer = css`
  margin: 20px 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #D1D1D1;

  label {
    display: flex;
    border: 1px solid #D1D1D1;
    padding: 8px 10px;
    font-size: 14px;
    margin: 0 0 -1px;
    align-items: center;

    &:first-of-type {
      margin-top: 15px;
    }

    &:last-of-type {
      margin-bottom: 20px;
    }

    &:focus {
      border-color: #D40F7D;
    }

    &:hover {
      border-color: #D40F7D;
      z-index: 100;
      position: relative;
      cursor: pointer;
    }

    &.option-selected {
      border-color: #D40F7D;
      z-index: 100;
      position: relative;
    }
  }

  input {
    border: 1px solid #D1D1D1;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-right: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:checked {
      background: radial-gradient(#D40F7B 40%, #ffffff 72%);
      border-color: #D40F7D;
    }
  }
`;

styles.ctaContainer = css`
  padding-top: 20px;
  border-top: 1px solid #D1D1D1;
  margin-top: 20px;
`;

styles.summaryList = css`
  list-style-type: none;
  margin: 15px 0 30px;
  padding: 0;

  @media print {
    margin: 15px 0 5px;
  }

  li {
    margin-bottom: 15px;

    span {
      padding-top: 3px;
      display: inline-block;
    }
  }

  .summaryEditContainer {
    display: flex;
    border-bottom: 1px solid #D1D1D1;
    padding-bottom: 3px;
    align-items: center;

    .stepSummaryHeading {
      font-size: 18px;
      font-weight: 700;
      padding-top: 0px;
    }

    a {
      margin-left: auto;
      font-size: 14px;

      @media print {
        display: none;
      }
    }
  }
`;

styles.actions = css`
  margin-top: 45px;
  margin-bottom: 30px;

  a {
    margin: 0px 10px;

    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }

    span {
      padding-left: 8px;
    }
  }

  @media print {
    display: none;
  }
`;

styles.explore = css`
  padding: 31px 0 24px;
  margin-bottom: 30px;
  display: flex;

  @media (max-width:767px) {
    display: block;
  }

  .explore-copy, .explore-links {
    margin: 0;
  }

  .explore-copy {
    padding-right: 100px;

    @media (max-width:767px) {
      padding-right: 0px;
    }
  }

  .explore-links {
    flex: 0 0 448px;
    display: flex;

    @media (max-width:1027px) {
      flex: 1 0 auto;
      display: block;
      padding-right: 20px;
    }

    .explore-link-column {
      width: 50%;

      @media (max-width:1027px) {
        width: 100%;
      }

      &:first-child {
        padding-right: 12px;
      }

      &:last-child {
        padding-left: 12px;
      }

      a {
        display: block;
        padding: 10px 0;
        text-decoration: none;

        &:first-child {
          border-bottom: 1px solid #D1D1D1;
        }

        i {
          color: #6D6D6D;
          padding-right: 4px;
        }
      }

      @media (max-width:1027px) {
        padding: 0px !important;

        a {
          border: 0 !important;
        }
      }
    }
  }

  @media print {
    display: none;
  }
`;

styles.legal = css`
  font-size: 12px;

  @media print {
    border-top: 1px solid #d1d1d1;
    border-bottom: 1px solid #d1d1d1;
    padding: 20px 0;
  }
`;

styles.disabled = css`
  background-color: #A8A8A8;
  border-color: #A8A8A8;
  cursor: not-allowed;

  &:hover {
    transition: none;
    background-color: #A8A8A8;
    color: white;

    &:before {
      display: none;
    }
  }
`;

styles.dropdown = css`
  display: flex;

  p {
    padding-right: 10px;
  }

  .fa {
    font-size: 12px;
    color: #333;
  }
`;

styles.shinglePrice = css`
  display: inherit;
  margin-top: -7px;
`;

styles.activeCollapserTarget = css`
  margin: -10px -15px 0px;
  padding: 10px 15px 0px;
  cursor: pointer;
`;

styles.learnMoreIcon = css`
  margin-left: 5px;
`;

styles.nextBtn = css`
  margin-bottom: 10px;
`;

export default styles;

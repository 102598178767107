import { useContext } from 'react';

import ContentHeader from './ContentHeader';
import Map from './Map';
import NoResults from './NoResults';
import LocatorContext from './context';
import { CannotLocalize, InvalidZip } from './errorState';
import LocatorProvider, { CANNOT_LOCALIZE, INVALID_ZIP } from './provider';
import ResultComponent from './resultComponent';
import ResultHeaderComponent from './resultContentHeader';
import FilterListResults from '../../../ComponentLibrary/filter-list/oc-filter-list-results';
import Container from '../../oc-container';

const Locator = () => {
  const {
    lists,
    tabs,
    userLocation,
    filters,
    showLogo,
    error,
    zip,
    code,
  } = useContext(LocatorContext);

  if (error === CANNOT_LOCALIZE) {
    return <CannotLocalize zip={zip} code={code} />;
  }
  if (error === INVALID_ZIP) {
    return <InvalidZip zip={zip} code={code} />;
  }

  return (
    <FilterListResults
      contentHeader={{ component: ContentHeader }}
      filters={filters}
      lists={lists}
      primaryActiveDefault={tabs.defaultTab() == tabs.TABS.PRIMARY} //  "primary" means resultComponent, i.e. LIST
      resultComponent={ResultComponent}
      resultHeaderComponent={ResultHeaderComponent}
      secondaryContent={Map}
      userLocation={userLocation}
      noResultComponent={NoResults}
      allowSearch
      filterChipsVisible
      showLogo={showLogo}
    />
  );
};

const LocatorContainer = (props = {}) => (
  <LocatorProvider {...props}>
    <Container maxWidth="1170">
      <Locator />
    </Container>
  </LocatorProvider>
);

export default LocatorContainer;

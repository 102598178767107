import { ui } from '@owenscorning/pcb.alpha';
import Article from './Article';

const InsulationIndustrial = Article(
  'Article::InsulationIndustrial',
  'insulation_industrial_blog',
  [
    'SocialMediaShareBanner',
    'RelatedArticles',
    'FoamglasBlogReusable'
  ],
  ui`AttributeSet`({
    query: {
      name: 'Blog - Foamglas',
      path: '/insulation'
    }
  }),
  {
    version: '0.1',
    displayName: 'Foamglas Blog',
    paths: ['insulation/industrial/blog'],
  }
)

export default InsulationIndustrial;

import { Grouped } from '../PageBuilder/interface/vessels/Grouping';
import Labels from './Labels';
const theme = Grouped.Theme.Standard;

const LabelWrapper = ({htmlFor, children}) => (
  <Labels css={ Grouped.Theme.get(theme, 'Labels') } >
    <label htmlFor={ htmlFor } css={ Grouped.Theme.get(theme, 'Label') } >
      { children }
    </label>
  </Labels>
)

export default LabelWrapper;

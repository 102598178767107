import styled from '@emotion/styled';

// Assets
import Facebook from '../Builder/Library/Images/Social/FacebookSquare';
import Linkedin from '../Builder/Library/Images/Social/Linkedin';
import Pinterest from '../Builder/Library/Images/Social/PinterestSquare';
import { connectLocation } from "../location/LocationConnectors";

// Styled Components
const Container = styled.div`
  align-items: center;
  background-color: #000000;
  bottom: 0;
  color: #FFFFFF;
  display: ${props => ["thm", "s"].includes(props.imageSize) ? "none" : "inline-flex"};
  font-family: "Oswald";
  font-size: 14px;
  font-weight: 700;
  left: 0;
  line-height: 14px;
  padding-left: 4px;
  padding-right: 4px;
  position: absolute;
  text-transform: uppercase;

  @media screen and (min-width: 787px) {
    display: ${props => (props.imageSize == "thm") ? "none" : "inline-flex"};
  }

  span {
    display: none;
    padding: 10px 8px;

    @media screen and (min-width: 787px) {
      display: inline-block;
    }
  }

  a {
    color: #FFFFFF;
    display: inline-block;
    padding: 10px 8px;

    &:hover {
      svg {
        fill: #D40F7D;
      }
    }

    svg {
      fill: #FFFFFF;
      height: 15px;
      width: 15px;
    }
  }
`;

const ImageShareLinks = connectLocation(({t, imageUrl, imageSize }) => {
  return (
    <Container imageSize={imageSize}>
      <span>{t('components.oc-image.share-links.share')}</span>
      <a href={`https://www.facebook.com/sharer/sharer.php?u=${imageUrl}`} target="_blank"><Facebook /></a>
      <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${imageUrl}`} target="_blank"><Linkedin /></a>
      <a href={`http://pinterest.com/pin/create/button/?url=${imageUrl}`} target="_blank"><Pinterest /></a>
    </Container>
  );
});

export default ImageShareLinks;

import styled from '@emotion/styled';
import Theme from '../../../themes';

const ThumbnailContainer = styled.div`
  ${props => props.onClick && `
    cursor: pointer;

    img {
      border: 2px solid #959595;
    }

    &:hover {
      color: ${Theme.colors.brand};

      img {
        border-color: ${Theme.colors.brand};
      }
    }
  `}
`;

const Image = styled.img`
  display: block;
  object-fit: cover;
  width: 100%;
`;

const Subtitle = styled.div`
  font-size: 18px;
  margin-top: 8px;
`;

export default ({ image, subtitle, onClick }) => (
  <ThumbnailContainer onClick={ onClick }>
    <Image src={ image } />
    <Subtitle>{ subtitle }</Subtitle>
  </ThumbnailContainer>
);

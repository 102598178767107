import {jsx, css} from '@emotion/react';

const styles = {};

styles.model3D = css`
  margin-bottom: 50px;

  @media(min-width: 768px) {
    position: sticky;
    top: 0;
  }

  .model-instructions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    @media print {
      display: none;
    }

    span {
      font-size: 12px;
      margin: 0 5px;
      display: flex;

      img {
        margin-right: 5px;
      }
    }
  }
`;

styles.modelCanvas = css`
  width: 100%;
`;

export default styles;

import { Component } from 'react';

import Contents from './Contents';
import { connectFeatureFlag } from '../../FeatureFlag/FeatureFlagConnectors';
import Modal from '../../Modal';
import { connectLocation } from '../LocationConnectors';
import { makeT, getZipInfo } from '../locales';
import trySubmitZip from './trySubmitZip';

const LocationModalView = connectFeatureFlag(
  ({
    locationModalOpen = false,
    closeLocationModal,
    enabledFeatureFlags,
    ...props
  }) => (
    <Modal
      className="location-modal"
      name="Region Selector"
      open={locationModalOpen}
      onClose={closeLocationModal}
      size="sm"
    >
      <Contents {...props} />
    </Modal>
  )
);

class LocationModalController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      zip: props.zip,
      locale: props.locale,
    };
  }

  componentDidMount() {
    const { forceReload, forceReloadRedirectPath } = this.props;
    const locationChangeShouldForceReload = forceReload;

    this.props.setLocationModalForceReload({ locationChangeShouldForceReload, forceReloadRedirectPath });
    // If locale selection is disabled, force first available locale
    if(!this.props.showLocale && this.props.availableLocales.length > 0) {
      this.updateLocation({ locale: this.props.availableLocales[0] })
    }
  }

  onSuccess(locale, zip) {
    this.setState({ error: null });
    this.props.changeLocation({ locale, zip });
    this.props.closeLocationModal();
  };

  onSubmit() {
    const { locale, zip } = this.state;
    const { postalRequired, showZip } = this.props;
    trySubmitZip(
      locale,
      zip,
      postalRequired || showZip,
      (loc, zi) => this.onSuccess(loc, zi),
      () => this.setState({ error: true })
    );
  }

  updateLocation(attributes) {
    this.setState(({ zip, locale }) => ({ zip, locale, ...attributes }))
  }

  render() {
    const { locationModalOpen, closeLocationModal, userLocale, showLocale, postalRequired, hideFlag, translationPrefix, availableLocales, mismatched, showZip } = this.props;
    const shouldShowZip = showZip || postalRequired;
    const t = makeT(this.state.locale.code);

    return (
      <LocationModalView
        onChangeZip={zip => this.updateLocation({ zip: zip.toUpperCase() })}
        onSubmit={() => this.onSubmit()}
        showLocale={showLocale}
        showZip={shouldShowZip}
        hideFlag={hideFlag}
        translationPrefix={translationPrefix}
        setLocaleCode={localeCode => this.updateLocation({ locale: availableLocales.find(l => l.code === localeCode) })}
        locationModalOpen={locationModalOpen}
        closeLocationModal={closeLocationModal}
        availableLocales={availableLocales}
        mismatched={mismatched}
        userLocale={ userLocale }
        t={t}
        {...this.state}
      />
    );
  }
};

export default connectLocation(LocationModalController);

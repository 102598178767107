class Cookies {
  static getAll = () => {
    const decoded = decodeURIComponent(document.cookie);
    const cookieArr = decoded.split(';');
    const hash = {};
    cookieArr.forEach(c => {
      const [cKey, cValue] = c.trim().split('=');
      hash[cKey] = cValue;
    });

    return hash;
  }

  static get = (name) => {
    return this.getAll()[name];
  }

  static getFeatureFlags = () => {
    const json = this.get('feature_flags_v2');
    if (json) return JSON.parse(json);

    return {enabled: []};
  }

  static isReferred = () => {
    return this.getFeatureFlags().enabled.includes('contractor_referral');
  }
}

export const USER_ZIP_COOKIE = 'UserInfoZip2';
export const USER_LOCALE_COOKIE = 'oc_locale2';

export default Cookies;

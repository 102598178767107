import { createContext, useEffect, useState } from 'react';
import { anchor as stickyNavAnchor } from '../../helpers/sticky_nav';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

// Component Imports
import Heading from '../../OC/PageBuilder/Heading';

// Styled Components
const Section = styled.section`
  margin: 0;
  max-width: none;
  padding: 0px;

  ${ ({ background }) => background == 'grey' && css`
    background-color: #E6E6E6;
    padding: 64px 0px;
  `}

  @media (${ () => UI.Viewport.to.Desktop }) {
    padding: 0px;
    ${ ({ background }) => background == 'grey' && css` padding: 24px 0px; `}
  }

  .section-heading {
    margin-bottom: 24px;
    text-align: ${props => props.headingAlignment};
  }
`;

const Separator = styled.div`
  padding: ${ (props) => props.compact ? (Board.Gap.Desktop / 2) : Board.Gap.Desktop }px;
  margin: 0 ${ () => Board.Gutter.Desktop }px;

  @media (${ () => UI.Viewport.to.Desktop }) {
    padding: ${ (props) => props.compact ? (Board.Gap.Tablet / 2) : Board.Gap.Tablet }px;
    margin: 0 ${ () => Board.Gutter.Tablet }px;
  }

  ${
    ({ details, prior }) => (
      details?.separator === 'show' ||
      (
        details?.separator === 'auto' &&
        details?.background === 'white' &&
        prior?.background === 'white'
      )
    )
    ? css`
      position: relative;
      height: 2px;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0px;
        width: 100%;
        height: 2px;
        background-color: #000;
      }
    `
    : css`
      padding-bottom: 0px !important;
    `
  }
`;

const getHeadingContextStateValue = (details) => {
  const forceHeadingHierarchy = !!details?.forceHeadingHierarchy;
  const headingLevel = !!details?.useNameAsHeading ? details?.initialHeadingLevel : null;
  return {
    forceHeadingHierarchy,
    headingLevel,
    headingInUse: forceHeadingHierarchy ? headingLevel : null,
  };
}

const Component = ({ selectable=true, index, details=Component.Defaults.details, sticky, gapless, prior, onClick, selector, children, ...props }) => {
  const [headingContextValue, setHeadingContextValue] = useState(getHeadingContextStateValue(details));
  const heroData = Board?.Value?.contents?.layout?.Header?.Hero;
  const tier3Hero = heroData?.type == "tier_3";
  const minimalHero = (heroData?.showHeroImage == "small" || heroData?.showHeroImage == "none");
  const minimizeSeparator = (index == 0) && tier3Hero && minimalHero;
  const headingFont = details?.bodyFont ? "body" : "heading";
  const headingAlignment = details?.headingAlignment || 'left';

  useEffect(() => {
    setHeadingContextValue(getHeadingContextStateValue(details))
  }, [details?.forceHeadingHierarchy, details?.useNameAsHeading, details?.initialHeadingLevel]);

  return (
    <>
      {!gapless && <Separator details={ details } prior={ prior } compact={minimizeSeparator} key={ `${ index }-separator` } />}
      <UI.Selection selectable={ selectable } unwrapped={ !selectable } onClick={ onClick } selector={ selector }>
        <UI.Anchor name={ stickyNavAnchor(sticky?.name || details?.name) } />
        <Section
          background={ details?.background }
          data-am-region={ details?.name || `Section ${ index+1 }` }
          headingAlignment={headingAlignment}
          key={ `${ index }-section` }
          { ...props }
        >
          {details?.useNameAsHeading &&
            <UI.Module gapless selectable={false}>
              <Heading
                headingClass="section-heading"
                headingFont={headingFont}
                headingTag={headingContextValue.headingLevel}
                mainHeading={details?.name}
              />
            </UI.Module>
          }
          <HeadingContext.Provider value={ headingContextValue }>
            { children }
          </HeadingContext.Provider>
        </Section>

      </UI.Selection>
    </>
  );
}

Component.Defaults = {
  details: { separator: 'auto', background: 'white' }
}

const HeadingContext = createContext();
HeadingContext.displayName = 'HeadingContext'

export { HeadingContext };

export default Component;

import { jsx, css } from '@emotion/react';

const base = ({ gray, padding, maxWidth, marginBottom }) => css`
  margin-bottom: 32px;

  @media (min-width: 768px) {
    margin-bottom: 40px;
  }

  @media (min-width: 1200px) {
    margin-bottom: 48px;

    ${maxWidth && `
      max-width: ${maxWidth}px;
      margin-right: auto;
      margin-left: auto;
    `}
  }

  ${gray === true &&
  `
    background: #e6e6e6;

    margin-left: -15px;
    margin-right: -15px;

    @media (min-width: 768px) {
      max-width: 798px;

    }

    @media (min-width: 1200px) {
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  `}

  ${padding === true &&
  `
    padding-top: 40px;
    padding-bottom: 40px;
  `}
`
const innerContent = css`
  margin: 0 15px;

  @media (min-width: 1200px) {
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
  }
`

const Container = ({ gray, padding, children, maxWidth }) => {
  const content = gray ? <div css={innerContent}>{children}</div> : children;

  return (
    <div css={[base ({ padding, gray, maxWidth })]}>
      {content}
    </div>
  )
}

export default Container;

import React from 'react';
import Theme from '../../../themes/index';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

// Components
import RadioButton from '../../ComponentLibrary/input-elements/oc-radio-button';
import ImageRadioButton from '../../ComponentLibrary/input-elements/oc-image-radio-button';

const Container = styled.div`
  display: inline-block;
  position: relative;
  margin-bottom: ${props => props.margin};
  width: 100%;
`;

const LabelContainer = styled.div`
  width: auto;
`;

const Group = styled.div`
  display: flex;
  flex-direction: ${props => props.vertical ? 'column' : 'row' };
  flex-wrap: wrap;
  justify-content: space-around;

  ${props => !props.imageRadio && `
    @media screen and (max-width: 767px) {
      background-color: #FFFFFF;
      border-left: 1px solid #000000;
      border-right: 1px solid #000000;
      border-top: 1px solid #000000;
      flex-direction: column;
    }
  `}

  @media screen and (min-width: 768px) {
    flex-direction: ${props => props.vertical ? 'column' : 'row' };
  }

  label {
    margin-bottom: 0.5em;
    display: flex;
    align-items: center;

    ${props => !props.vertical && `
      margin-right: 4em;
    `}

    ${props => props.label && `
      &:first-of-type {
        margin-bottom: 0.5em;
      }
    `}

    &:last-of-type {
      margin-right: 0;
    }

    ${props => !props.imageRadio && `
      @media screen and (max-width: 767px) {
        border-bottom: 1px solid #000000;
        margin: 0;
        padding: 16px 8px;

        input[type="radio"] ~ .radio {
          left: 12px;
          top: 22px;
        }
      }
    `}
  }
`;

const RadioGroup = ({
  margin = "40px",
  themeColor = Theme.colors.brand,
  onBlur,
  onChange,
  options,
  required,
  ...props
}) => {
  const { inputElements, imageRadio = false, vertical = false } = options;
  const Component = imageRadio ? ImageRadioButton : RadioButton;

  return (
    <Container margin={margin}>
      <Group
        imageRadio={imageRadio}
        onBlur={onBlur}
        onChange={(event) => onChange(event.target.value)}
        vertical={vertical}
      >
        {inputElements.map((option, optionIndex) =>
          <LabelContainer key={`label_for_${optionIndex}`}>
            <Component key={`option_${optionIndex}`} themeColor={themeColor} value={option.value} required={required} {...option}>
              {option.text}
            </Component>
          </LabelContainer>
        )}
      </Group>
    </Container>
  )
};

export default RadioGroup;

RadioGroup.propTypes = {
  /* CSS: Hex value for color theme */
  themeColor: PropTypes.string,
  /* JSX: (Required) Option objects that render the actual radio input elements; should at minimum include text and value props. */
  options: PropTypes.object.isRequired,
  /* CSS: (Optional) String px value to adjust the margin-bottom value for the group. */
  margin: PropTypes.string,
}

import { jsx, css } from '@emotion/react';
import { define, ui, s } from '@owenscorning/pcb.alpha';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { useState } from 'react';
import Data from '../../../Data';
import { highlightSettingsSection } from "../../../../OC/PageBuilder/utilities/shared";

const generateDocOp = (obj) => {
  if (obj.type === 'link') {
    return [
      {
        attributes: {
          link: obj.link
        },
        insert: obj.title
      },
      {
        insert: {
          breaker: true
        }
      },
      {
        insert: "\n"
      }
    ]
  } else {
    return [
      { insert: { document: obj }}
    ]
  }
}

const Renderer = ({ ...props }) => {
  const itemsPerRow = props?.itemsPerRow;
  const products = Board.settings?.associatedProducts || [];
  const [resolvedProducts, setResolvedProducts] = useState([]);

  useDeepCompareEffectNoCheck(() => {
    const adapter = Data.for('ProductsInSolution')['products_insulation']
    adapter.fetch_data(
      { documentGeneratorFn: generateDocOp, ...products }
    ).then(result => setResolvedProducts(result))
  }, [products]);

  const mediaObjectSetSettings = {
    mediaObjects: resolvedProducts,
    settings: { imgSize: 'large', itemsPerRow: itemsPerRow },
    type: { source: 'custom' }
  };

  return(
    <>
      { resolvedProducts?.length > 0 &&
        <UI.Modules.Page.MediaObjectSet
          value={mediaObjectSetSettings}
        />
      }
    </>
  )
}

const ProductsInSolution = define`Products In This Solution`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/Placeholder`,
  view: ({ value }) => <Renderer { ...value } />,
  edit: {
    [s._]: ui`Tip`.of('Please configure your related products on the settings section.')({ noTopMargin: true, noPadding: true }),
    [s._]: ui`RawLink`.of('Go to settings')({
      onClick: () => {
        highlightSettingsSection('sidebar-pane','associatedProducts');
        Board?.associatedProductOpener(true);
      },
      noTopMargin: true,
      icon: 'external-link',
    }),
    itemsPerRow: ui`Choices`.of([1, 2, 3])({
      label: "Number of items per row",
      default: 2,
      mode: ui`Choices/Mode/Dropdown`
    }),
    data: ui`Text`({
      default: 'nothing',
      visible: false
    }),
  },
});

export default ProductsInSolution;

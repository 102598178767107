import { createPortal } from "react-dom";
import { canUseDOM } from "./dom";

export function createUniversalPortal(children, elementFunc) {
  if (!canUseDOM()) {
    return null;
  }
  const element = elementFunc ? elementFunc() : null;
  return createPortal(children, element);
}

import PropTypes from 'prop-types';

import { Distance as StyledDistance } from '../result-block-styled-components';

const Distance = ({ distance, units, formatter, t }) => (
  <StyledDistance>{formatter({ distance, units, t })}</StyledDistance>
);

Distance.propTypes = {
  distance: PropTypes.number,
  units: PropTypes.string,
  formatter: PropTypes.func,
  t: PropTypes.func,
};

Distance.defaultProps = {
  distance: 0,
  units: 'mi',
  formatter: () => '',
  t: () => '',
};

export default Distance;

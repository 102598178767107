import { connectLocation } from './location/LocationConnectors';

const LocaleSpecific = ({ children, locale: { code }, only = [], except = [] }) => {
  const allowed = only.length == 0 || only.some(locale => code == locale)
  const forbidden = except.length != 0 && except.some(locale => code == locale)

  if (allowed && !forbidden) {
    return children;
  }

  return null;
}

export default connectLocation(LocaleSpecific);

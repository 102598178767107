import {
  BYR_EDITOR_CHANGE_API_DATA,
  BYR_EDITOR_CHANGE_DATA,
  BYR_EDITOR_CHANGE_ZIP, BYR_EDITOR_SET_LOADING, BYR_EDITOR_SET_RECOMMENDED,
  BYR_EDITOR_SET_EDIT, BYR_EDITOR_TOGGLE_VISIBILITY, BYR_EDITOR_LOAD_CONFIGURATION, BYR_EDITOR_CHANGE_LEADS_EMAIL,
  BYR_EDITOR_CHANGE_STEP_ORDER,
} from "../Actions";
import {connect} from 'react-redux';
import {GENERIC_LOADER} from "./step";
import {logByrConfiguration} from "./analytics";
import _ from "lodash";
import {CUSTOMIZE_HARDCODED_PRODUCTS, TRANSFORMATIONS} from "./hardcode";
import getReduxInitial from "../helpers/redux_initial";


// Why debounced? Usually single click in editor might create few actions (like - switch from edit -> new & load new data)
// we don't want this data to be logged twice, so we wait and run the last function that ran in the last 100ms.
// Since events are fired rapidly, 100ms should be enough (probably 10ms would be enough)
// If you stumble upon it and have time to restructure, feel free to do it. - akaminski
const debouncedLogByrConfiguration = _.debounce(logByrConfiguration, 100);
// This file contains helpers & logic that integrates BYR Customize Editor with Redux

// region REDUCER Setup - this sets up Redux for communication
// Default Data
const byrEditorDefaults = {
  zip: null,
  configuration: {stepOrder: 'standard'},
  data: {},
  apiData: {},
  editExisting: false,
  loading: false

};
// Loading from server
const byrEditorInitial = _.assign({}, byrEditorDefaults, getReduxInitial('byrEditor', {}));

// Reducer - main logic
export const byrEditorReducer = (state = byrEditorInitial, action) => {
  let newState = _.cloneDeep(state);

  switch(action.type) {
    case BYR_EDITOR_SET_EDIT:
    case BYR_EDITOR_CHANGE_API_DATA:
    case BYR_EDITOR_LOAD_CONFIGURATION:
    case BYR_EDITOR_CHANGE_DATA:
    case BYR_EDITOR_CHANGE_ZIP:
    case BYR_EDITOR_SET_LOADING:
      const updatedState = _.assign({}, _.cloneDeep(state), _.omit(action, 'type'));
      debouncedLogByrConfiguration(updatedState.data, updatedState.zip, updatedState.configuration);
      return updatedState;
    case BYR_EDITOR_TOGGLE_VISIBILITY:
      let {uid, category} = action;
      let catArray = newState.data[category].hidden || [];
      if(_.includes(catArray, uid))
        newState.data[category].hidden = _.without(catArray, uid);
      else
        newState.data[category].hidden = catArray.concat(uid);
      debouncedLogByrConfiguration(newState.data, newState.zip);
      return newState;
    case BYR_EDITOR_SET_RECOMMENDED:
      newState.data[action.category].recommended = action.uid;
      debouncedLogByrConfiguration(newState.data, newState.zip);
      return newState;
    case BYR_EDITOR_CHANGE_LEADS_EMAIL:
      _.set(newState, 'configuration.configuration.leadsEmail', action.leadsEmail);
      return newState;
    case BYR_EDITOR_CHANGE_STEP_ORDER:
      console.log(action);
      _.set(newState, 'configuration.configuration.stepOrder', action.order)
      _.set(newState, 'configuration.stepOrder', action.order)
      return newState
    default:
      return state;
  }
};

//endregion Reducer

// region Action creators - they create "ACTION" object to use with Redux
export const setEdit = (editExisting) => ({type: BYR_EDITOR_SET_EDIT, editExisting});
export const setLoading = (loading) => ({type: BYR_EDITOR_SET_LOADING, loading});
export const changeZip = (zip) => ({type: BYR_EDITOR_CHANGE_ZIP, zip});
export const changeData = (data) => ({type: BYR_EDITOR_CHANGE_DATA, data});
export const changeAPIData = (apiData) => ({type: BYR_EDITOR_CHANGE_API_DATA, apiData});
export const updateLead = (leadsEmail) => ({ type: BYR_EDITOR_CHANGE_LEADS_EMAIL, leadsEmail });
export const changeStepOrder = (order) => ({ type: BYR_EDITOR_CHANGE_STEP_ORDER, order });


const remainingProducts = (category, productUid) => {
  let hidden = _.get(category, 'hidden', []);
  let allProducts = _.get(category, 'products', []).map(i => i.uid);

  return _.difference(allProducts, hidden, [productUid]);
};
const isLastProduct = (category, productUid) => {
  return remainingProducts(category, productUid).length === 0
};
// toggleVisibility and setRecommended are thunks (i.e. functions that return functions)
// thus funky syntax.
// They are also action creators, but there is additional logic.


// toggleVisibility - changes visibility, but disallows removing visibility of the last product
//                    and also launches additional BYR_EDITOR_SET_RECOMMENDED if removed product was recommended
export const toggleVisibility = (category, uid) => (dispatch, getState) =>  {
  let state = getState();
  let hidden = _.get(getState(), ['byrEditor', 'data', category, 'hidden'], []);
  // Check if the current is the only one
  let catHash = _.get(state, ['byrEditor', 'data', category]);
  let remaining = remainingProducts(catHash, uid);
  if(remaining.length ===0)
    return;

  // If we disabled recommended item, set new recommended

  if(catHash.recommended === uid && !hidden.includes(uid)) {
    return;
  }

  dispatch({type: BYR_EDITOR_TOGGLE_VISIBILITY, category, uid});
};

// setRecommended - changing recommended item - one can set only those, which are not hidden
export const setRecommended = (category, uid) => (dispatch, getState) => {
  // Don't allow setting to hidden
  let hidden = _.get(getState(), ['byrEditor', 'data', category, 'hidden'], []);
  if(hidden.includes(uid))
    return;

  dispatch({type: BYR_EDITOR_SET_RECOMMENDED, category, uid});

};

export const loadDataFromZip = (zip) => (dispatch, getState) => {
  dispatch(setLoading(true));

  return fetch('/roofing/build-your-roof/customize-data?zip=' + zip)
    .then(response => response.json())
    .then(data => {
      loadData(data)(dispatch,getState);
      dispatch(setLoading(false));
    });
};

// loadData - loads the data from data or reparsers APIData
export const loadData = (inputData) => (dispatch, getState) => {

  const {products, shingles} = _.isEmpty(inputData) ? _.get(getState(), 'byrEditor.apiData') : inputData;

  const configurationProducts = _.get(getState(), 'byrEditor.configuration.configuration.products', []);

  const buildStepConfig = (unprocessedProducts, category) => {

    let products;
    if(category === 'shingles')
      products = unprocessedProducts;
    else {
      products =
        GENERIC_LOADER((t) => t, unprocessedProducts[category])
          .map(i => i.items).flat();
    }

    products.map(value => {
      if(_.has(TRANSFORMATIONS, value.uid))
        return TRANSFORMATIONS[value.uid](value);
      else
        return value;
    })
    const uids = products
      .filter(p => !p.hidden)
      .map(p => p.uid);

    let hidden = _.chain(configurationProducts).filter('hidden').map(p => p.uid)
      .intersection(uids).value();
    let recommended = _.chain(configurationProducts).filter('recommended').map(p => p.uid)
      .intersection(uids).first().value();
    return {
      products,
      hidden,
      uids,
      recommended: ((recommended || _.get(products.find(p => p.recommended), 'uid', '')) || uids[0] ),
    };
  };
  const data = {
    shingles: buildStepConfig(shingles, 'shingles'),
    barriers: buildStepConfig(products, 'ice_water_barrier'),
    underlayments: buildStepConfig(products, 'underlayment'),
    starterShingles: buildStepConfig(products, 'starter_shingles'),
    ridges: buildStepConfig(products, 'hip_ridge_shingles'),
    exhaustVentilations: buildStepConfig(products, 'exhaust_ventilation'),
  };
  if(_.isEmpty(data)) {
    dispatch({type: BYR_EDITOR_CHANGE_API_DATA, apiData: data});
  }
  dispatch({type: BYR_EDITOR_CHANGE_DATA, data});
};

export const loadConfiguration = (configurationSlug) => (dispatch, getState) => {
  let state = getState();
  let configuration = _.find(_.get(state, 'byrEditor.apiData.configurations', []), ['slug', configurationSlug]) || {};


  if(_.isNull(configurationSlug))
    configuration = {};

  dispatch({type: BYR_EDITOR_LOAD_CONFIGURATION, configuration});
  let configurationZip = _.get(configuration, 'configuration.zip');
  if(configurationZip) {
    loadDataFromZip(configurationZip)(dispatch, getState);
  } else {
    dispatch(loadData());
  }

};

//endregion Action Creators

// region Connector functions - those functions connect React components with Redux

// Customize Connector - one for Customize component

// Customize - which properties from Redux are going to be available as props
const stateToProps = ({byrEditor}) => ({
  apiData: byrEditor.apiData,
  data: byrEditor.data,
  loading: byrEditor.loading,
  products: byrEditor.data.products,
  configuration: byrEditor.configuration,
  shingles: byrEditor.data.shingles,
  zip: _.get(byrEditor, 'configuration.configuration.zip', byrEditor.zip || ''),
  editExisting: byrEditor.editExisting,
  leadsEmail: _.get(byrEditor, 'configuration.configuration.leadsEmail', ''),
  stepOrder: _.get(byrEditor, 'configuration.configuration.stepOrder', 'standard')
});

// Customize - which dispatch are available as props for customize
const dispatchToProps = {
  changeZip, changeData, setLoading,
  loadData, loadDataFromZip, changeAPIData,
  setEdit, toggleVisibility, updateLead,
  changeStepOrder
};

export const customizeReduxConnector  = connect(stateToProps, dispatchToProps);

// StepOption Connector
const stepOptionStateToProps = ({byrEditor}, {category, option}) => ({
  hiddenProducts: _.get(byrEditor, ['data', category, 'hidden'], []),
  recommendedProduct: _.get(byrEditor, ['data', category, 'recommended']),
  disabled: isLastProduct(_.get(byrEditor, ['data', category]), option.uid) || category === 'exhaustVentilations'
});
const stepOptionDispatchToProps = (dispatch) => ({
  toggleVisibility: (category, uid) => dispatch(toggleVisibility(category, uid)),
  setRecommended: (category, uid) => dispatch(setRecommended(category, uid)),
});
export const stepOptionReduxConnector = connect(stepOptionStateToProps, stepOptionDispatchToProps);

const stepStateToProps = ({byrEditor}, {category}) => {
  if(category === 'exhaustVentilations') {
    // We hardcode products for that part
    return {products: CUSTOMIZE_HARDCODED_PRODUCTS};
  } else {
    return { products: _.get(byrEditor, ['data', category, 'products'], [])};
  }
};

export const stepReduxConnector = connect(stepStateToProps);

const comparisionFunction = (a,b) => {
  return Date.parse(a.updated_at) < Date.parse(b.updated_at)
}
// CreateNewOrEditConnector
const createNewOrEditStateToProps = ({byrEditor}) => ({
  editExisting: byrEditor.editExisting,
  configurations: _.cloneDeep(byrEditor.apiData.configurations).sort(comparisionFunction) || [],
});
const createNewOrEditDispatchToProps = {setEdit, loadDataFromZip, loadConfiguration};

export const createNewOrEditReduxConnector = connect(createNewOrEditStateToProps, createNewOrEditDispatchToProps);

// AppLogo for BYR when we have
const contractorNameStateToProps = ({byrConfig}) => ({
  contractorName: _.get(byrConfig, 'companyName')
})

export const ContractorNameReduxConnector = connect(contractorNameStateToProps);
//endregion

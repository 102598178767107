import { jsx, css } from '@emotion/react';
import { useMemo, memo } from 'react';

const Dots = (({ actions, reference, children, ...props }) => {
  const openCSS = useMemo(() => css`
    background-color: #585858;
    background-image: url(${ UI.Images.EllipsisWhite });
  `, []);

  const beltCSS = useMemo(() => css`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #585858;
    background-color: #FFF;
    background-image: url(${ UI.Images.EllipsisGrey });
    background-position: center 2px;
    background-size: auto 9px;
    background-repeat: no-repeat;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: #585858;
      background-image: url(${ UI.Images.EllipsisWhite });
    }
  `, []);

  return (
    <UI.Belt
      actions={ actions }
      reference={ reference }
      openCSS={ openCSS }
      { ...props }
    >
      <div css={ beltCSS }/>
      { children }
    </UI.Belt>
  );
});

export default Dots;

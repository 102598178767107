import styled from '@emotion/styled';
import _ from 'lodash';
import { useContext } from 'react';

import LocatorContext from './context';
import { NO_NEARBY_LOCATIONS } from './provider';
import Cta from '../../oc-cta';
import { BasicContent } from '../BasicContent';
import { interpolateContent } from '../../../PageBuilder/helpers/interpolate';

const SpacerDiv = styled.div`
  margin: 1em 0;
`;
function BlockCTA(props) {
  return (
    <SpacerDiv>
      <Cta {...props} />
    </SpacerDiv>
  );
}

const NoContentWrapper = styled.div`
  @media (min-width: 1200px) {
    width: 67%;
  }
`;

function NoResults() {
  const {
    zip,
    distance,
    units,
    setUrlParams,
    urlParams,
    noResultsContent,
    status,
  } = useContext(LocatorContext);
  const {
    basicContent,
    includeFindNearest,
    findNearstCtaText,
  } = noResultsContent;
  interpolateContent(basicContent, { zip, distance, units });
  const findNearest = () => {
    setUrlParams(_.assign({}, urlParams, { distance: null, find_nearest: 1 }));
  };
  if (status == 'loading') {
    return <div> </div>;
  }

  if (status === NO_NEARBY_LOCATIONS) {
    return (
      <NoContentWrapper>
        <p>There are no locations within the max search radius.</p>
        <p>Try another location.</p>
      </NoContentWrapper>
    );
  }

  return (
    <NoContentWrapper>
      <BasicContent {...basicContent} />
      {includeFindNearest && (
        <BlockCTA
          onClick={findNearest}
          color="white"
          analytics={{
            track: 'learn-more',
            destination: 'Find Nearest Location',
          }}
        >
          {findNearstCtaText}
        </BlockCTA>
      )}
    </NoContentWrapper>
  );
}

export default NoResults;

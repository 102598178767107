import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Theme from '../../../themes';

const Labels = styled.div`
  display: flex;
  align-items: baseline;
  ${ ({ title }) => title && css` border-top: 1px solid #000; ` }
`;

const Label = styled.label`
  color: #000;
  display: block;
  padding-bottom: ${ ({ sublabel }) => sublabel ? 4 : 8 }px;
  ${ ({ title }) => title && css` font-size: 24px; padding: 28px 0 16px; ` }
  font-weight: bold;
`;

const Sublabel = styled.label`
  color: #000;
  display: block;
  padding-bottom: 8px;
  font-size: 14px;
`;

const Flag = styled.div`
  color: #585858;
  font-size: 12px;
  padding-left: 8px;
  ${ (props) => props.from == 'right' && css` margin-left: auto; ` }

  a {
    &:hover {
      color: ${Theme.colors.brand};
    }
  }
`;

const Issue = ({ copy, ...props }) => (
  <div css={ css`
    position: relative;
    top: -15px;
    font-size: 12px;
    color: #940420;
    padding-bottom: 4px;

    i {
      font-size: 18.9px;
      padding-right: 5px;
      position: relative;
      top: 2px;
    }
  ` } >
    <UI.Icon type="times-circle" /> { copy }
  </div>
);

const Socket = (title) => ({ Component, name, field, parameters, validation, first, last, ...props }) => {
  let { label, sublabel, flags, showErrors, tipIcon, tip, compact, noPadding } = parameters;
  let padding = noPadding ? 0 : (compact ? 8 : 32);

  return (
    <div css={ css`
      &:first-child > div { border-top: 0; }
      &:last-child > div { padding-bottom: 0px; }
    ` } >
      <Labels title={ title ? label : undefined } >
        { label && <Label sublabel={ sublabel } htmlFor={ field } title={ title ? title : undefined } >{ label }</Label> }
        { flags?.Left?.map((flag) => <Flag from="left" >{ flag }</Flag> ) }
        { flags?.Right?.map((flag) => <Flag from="right" >{ flag }</Flag> ) }
      </Labels>
      { sublabel && <Sublabel htmlFor={ field } title={ title } >{ sublabel }</Sublabel> }
      <div css={ css`padding-bottom: ${padding}px;` } >
        { Component }
        { tip && <UI.Tip iconComponent={tipIcon} noTopMargin>{ tip }</UI.Tip> }
      </div>
      { showErrors && validation && validation?.map((message) => <Issue copy={ message } />) }
    </div>
  );
};

Socket.Titled = Socket(true);
Socket.Untitled = Socket(false);

export default ({ field, title, label }) => <>
  <Contents field={ field } socket={ title ? Socket.Titled : Socket.Untitled } />
</>;

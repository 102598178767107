import {CalculatorFiberglass} from '../../ProPink/CalculatorFiberglass';
import Container from '../../OC/oc-container';
import RichText from './RichText';
import { BasicContent } from './BasicContent';
import { Provider } from "react-redux";
import {store} from '../../Store'


const CMSFiberglassCalculator = ({...content}) => {
  return (
    <>
      <BasicContent { ...content.copy } />
      <Provider store={store}>
        <Container maxWidth="1170"><CalculatorFiberglass/></Container>
      </Provider>
      <RichText content={content.footnotes} />
    </>
  )
};

export default CMSFiberglassCalculator;

import _ from 'lodash';
import querystring from 'qs';

import useMdmsApi, { SUCCESS } from '../../../../hooks/use_mdms_api';

const defaultPerPage = '75';
export const MAX_DISTANCE = '500';

function apiUrl(uid, lat, lng, filters, radius, units) {
  // null for radius means we want to fetch the distance
  const query = `${querystring.stringify(
    _.omitBy(
      {
        lat,
        lng,
        radius,
        units,
        per_page: defaultPerPage,
        ...filters,
      },
      _.isNil
    )
  )}`;
  return `/api/v3/locations/${uid}${!radius ? '/radius' : ''}?${query}`;
}

export function fetchDistance({ uid, lat, lng, filters, units }, condition) {
  const { status, code, data } = useMdmsApi(
    apiUrl(uid, lat, lng, filters, null, units),
    condition
  );
  if (code === 404) {
    return [SUCCESS, { radius: MAX_DISTANCE }];
  }
  return [status, data];
}

export function fetchLocations(
  { uid, lat, lng, distance, filters, units },
  condition
) {
  const { status, data } = useMdmsApi(
    apiUrl(uid, lat, lng, filters, distance, units),
    condition
  );
  return [status, data];
}

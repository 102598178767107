const ROOT_ID = '0';

// TODO - possible optimization is send only the props we need in data instead of entire content
// (today we need entire object due to selectItem action, but could be uuid and component name?)
const buildAtlaskitTreeNode = (content) => (
  {
    data: { ...content },
    id: content.__uuid,
    children: content.children || [],
    hasChildren: !!content.children && content.children.length > 0
  }
);

const buildAtlaskitTree = (contents) => {
  const contentTreeNodes = Object.entries(contents).map(([k,v]) => (
    [k, buildAtlaskitTreeNode(v)]
  ))

  return Object.fromEntries(new Map(contentTreeNodes))
};

export const buildAtlaskitTreeFromContent = (contents) => (
  { rootId: ROOT_ID, items: buildAtlaskitTree(contents||[]) }
);

const getPath = (obj, predicate) => {
  var path = [];
  var found = false;

  function search(haystack) {
    for (var key in haystack) {
      path.push(key);
      if (predicate(haystack[key])) {
        found = true;
        break;
      }
      if (Array.isArray(haystack[key]['children'])) {
        path.push('children');
        search(haystack[key]['children']);
        if (found) break;
        path.pop();
      }
      path.pop();
    }
  }

  search(obj);
  return path;
};

export const findPathById = (contents, id) => (
  getPath(contents, c => c.__uuid === id)
)

export const findContentItemById = (contents, id) => {
  if (!contents || !id) {
    return null;
  }
  return contents[id];
};

export const findContentItemByParentIdAndIndex = (contents, parent_uuid, index) => {
  if (!contents || !parent_uuid || !index) {
    return null;
  }
  return contents[parent_uuid].children[index];
};

const buildHierarchicalNodes = (nodes, children) => (
  (children||[]).map(node => {
    const { children, ...dataWithoutChildren } = nodes[node];
    const newChildren = buildHierarchicalNodes(nodes, children);
    return newChildren ? { ...dataWithoutChildren, children: newChildren } : dataWithoutChildren;
  })
);

export const hierarchicalTree = (flatTree) => {
  return buildHierarchicalNodes(flatTree, flatTree[ROOT_ID]?.children)
};


const buildTreeNode = (content, func) => (
  {
    ...content,
    children: content.children ? content.children.map(c => func(c)) : [],
  }
);

const buildTreeNodes = (content, func) => (
  [
    buildTreeNode(content, func)
  ].concat(content.children ? content.children.flatMap(c => buildTreeNodes(c, func)) : [])
);

const buildTree = (contents, key, func) => {
  const treeItems = buildTreeNodes({
    [key]: func(null),
    children: contents,
  }, func);

  return Object.fromEntries(new Map(
    treeItems.map(i => [func(i), i]),
  ))
};

export const flattenTree = (contents, key='__uuid', func=(i=>(i ? i[key] : ROOT_ID))) => (
  buildTree(contents||[], key, func)
);

import {useState} from 'react';
import styled from '@emotion/styled';
import {jsx, css} from '@emotion/react';
import PropTypes from 'prop-types';

// Components
import IconInfo from './icons/icon-info';

// Styled Components
const InfoIconContainer = styled.span`
  cursor: pointer;
  display: inline-block;
  position: relative;

  svg {
    fill: ${props => props.color ? props.color : '#6D6D6D'};

    &:hover {
      fill: #D40F7D;
    }
  }
`;

const ToolTip = styled.div`
  background: white;
  padding: 8px;
  font-size: 14px;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 25%);
  position: absolute;
  width: 150px;
  color: black;
  font-weight: 500;
  text-align: left;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: calc(50% - 5px);
    border: 4px solid black;
    box-sizing: border-box;
    transform-origin: 0 0;
    transform: rotate(45deg);

    ${props => props.tooltipPosition === 'left' && css`
      left: 100%;
      border-color: white white transparent transparent;
      box-shadow: 4px -4px 4px -2px rgb(0 0 0 / 10%);
    `};

    ${props => props.tooltipPosition === 'right' && css`
      right: 100%;
      border-color: transparent transparent white white;
      box-shadow: -4px 4px 4px -2px rgb(0 0 0 / 10%);
    `};
  }

  ${props => props.tooltipPosition === 'left' && css`
    right: calc(100% + 10px);
    top: 50%;
    transform: translateY(-50%);
  `};

  ${props => props.tooltipPosition === 'right' && css`
    left: calc(100% + 10px);
    top: 50%;
    transform: translateY(-50%);
  `};

  ${props => props.tooltipPosition === 'top' && css`
    left: 50%;
    bottom: calc(100% + 10px);
    transform: translateX(-50%);
  `};

  ${props => props.tooltipPosition === 'bottom' && css`
    left: 50%;
    top: calc(100% + 10px);
    transform: translateX(-50%);
  `};
`;

const InfoIcon = ({ clickHandler, color='#6D6D6D', tooltip=false, tooltipText, tooltipPosition, enabled=true, size='14px', ...props }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const hoverEvent = () => {
    tooltip ? setShowTooltip(!showTooltip) : null
  };

  return (
    <InfoIconContainer
      aria-label="More Information"
      color={color}
      onClick={clickHandler}
      onKeyPress={(e) => {
        if (e.key === 'Enter' || e.key === 'Space') {
          clickHandler()
        }
      }}
      role="button"
      tabIndex={enabled ? 0 : -1}
      onMouseEnter={hoverEvent}
      onMouseLeave={hoverEvent}
      {...props}
    >
      <IconInfo height={size} width={size} />

      {showTooltip && 
        <ToolTip tooltipPosition={tooltipPosition}>
          {tooltipText}
        </ToolTip>
      }
    </InfoIconContainer>
  )
};

export default InfoIcon;

InfoIcon.propTypes = {
  /* JS: Function to pass to the onClick / onKeyPress bindings */
  clickHandler: PropTypes.func,
  /* CSS: Optional color property override, default color is #6D6D6D */
  color: PropTypes.string,
  /* HTML: Toggles the tab-index attribute for keyboard navigation */
  enabled: PropTypes.bool,
  /* CSS: Optional font-size property override, default is 16px */
  size: PropTypes.string
}

import {jsx, css} from '@emotion/react';

const styles = {};

/* Section */
styles.contentSection = css`
  max-width: none;
  margin: 10px auto 35px;

  @media(min-width: 1200px) {
    max-width: 1170px;
    margin-bottom: 70px;
  }

  @media print {
    margin: 10px auto 15px;
  }
`;

/* Columns */
styles.stepColumns = css`
  flex-direction: column-reverse;
  display: flex;

  @media print, (min-width: 768px) {
    flex-direction: row;
  }

  > .col {
    @media print, (min-width: 768px) {
      padding: 0 15px;
    }

    &:first-child {
      @media print, (min-width: 768px) {
        width: 50%;
        padding-left: 0;
      }

      @media (min-width: 1200px) {
        width: 33.333%;
      }
    }

    &:last-child {
      @media print, (min-width: 768px) {
        width: 50%;
        padding-right: 0;
      }

      @media print, (min-width: 1200px) {
        width: 66.666%;
      }
    }

    &:nth-child(2) {
      @media(min-width: 768px) {
        border-left: 1px solid #BCBCBC;
      }

      @media print {
        border: none;
      }
    }
  }

  .oc-cta-button {
    width: 100%;
  }
`;

/*  HeaderIcon */
styles.iconHeading = css`
  font-size: 28px;
  font-family: 'Oswald';
  display: flex;
  align-items: center;
  margin: 0;

  img {
    margin-right: 10px;
  }
`;

styles.stepHeading = css`
  margin-bottom: 15px;
  font-size: 18px;
  text-transform: uppercase;

  img {
    width: 17px;
    margin-right: 5px;
  }
`;

/*  Step Menu */
styles.stepTab = css`
  font-size: 15px;
  color: #A8A8A8;;
  padding: 7px 20px 6px;
  text-align: center;
  text-transform: uppercase;
  display: none;
  width: 100%;

  @media(min-width: 768px) {
    display: inline-flex;
    border-bottom: 1px solid #A8A8A8;
    align-items: center;
  }

  .stepNumber {
    display: none;

    @media(min-width: 768px) {
      background: #A8A8A8;
      width: 18px;
      height: 18px;
      border-radius: 14px;
      margin-right: 6px;
      color: white;
      font-size: 14px;
      line-height: 14px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  .completed {
    color: #D40F7D;
    margin-right: 6px;
    font-size: 18px;
    line-height: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .mobileProgress {
    display: block;
    color: white;
    font-size: 11px;

    @media(min-width: 768px) {
      display: none;
    }
  }
`;

styles.currentStep = css`
  display: inline-block;
  color: white;

  @media(min-width: 768px) {
    color: black;
    border-bottom: 3px solid #D40F7D;
    padding: 7px 20px 4px;
  }

  .stepNumber {
    background: #D40F7D;
  }
`;

styles.completedStep = css`
  @media(min-width: 768px) {
    color: black;
    border-bottom: 3px solid #D40F7D;
    padding: 7px 20px 4px;

    &:hover {
      color: white;
      background: #D40F7D;
      cursor: pointer;

      .completed {
        color: white;
      }
    }
  }

  .stepNumber {
    display: none;
  }
`;

/*  Price Widget */
styles.priceBadge = css`
  background-color: black;
  border-radius: 15px;
  padding: 2px 4px;
  color: #959595;
  margin-left: auto;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1.0px;

  .active {
    color: white;
  }
`;

styles.headerPriceBadge = css`
  color: #959595;
  font-weight: 700;
  letter-spacing: 1.0px;

  .active {
    color: black;
  }
`;

styles.mobilePrevNext = css`
  color: white;
  font-size: 9px;
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  border: none;
  background: transparent;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media(min-width: 768px) {
    display: none;
  }

  i {
    color: white;
    font-size: 28px;
  }
`;

styles.mobilePrev = css`
  left: 15px;
`;

styles.mobileNext = css`
  right: 15px;
`;

export default styles;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Theme from '../../themes';

// Components
import { Icon } from '@iconify/react';
import Image from './oc-image.jsx';
import WrapperLink from './oc-wrapper-link';

// Styles / Styled Components
const imgSizes = {
  'small': '72px',
  'large': '120px',
  'xl': '240px',
  'document': '88px',
  'icon': '48px'
};

const iconColors = {
  "pink": Theme.colors.brand,
  "black": "#000000",
};

const base = ({ reverse, imgSize, usePadding }) => css`
  & > * {
    align-items: ${imgSize === 'icon' ? 'center' : 'flex-start' };
    display: flex;
    flex-direction: ${reverse ? 'row-reverse' : 'row'};

    .image-icon-wrapper {
      display: flex;
      position: relative;
    }

    .media-body {
      flex: 1;
      font-size: 14px;
      padding-left: ${reverse ? '0' : (usePadding ? '16px' : '0')};
      padding-right: ${reverse ? '10px' : '0'};

      @media (min-width: 1200px) {
        font-size: 16px;
      }
    }
  }

  .wrapper-link {
    &:hover {
      p {
        color: #333;
        cursor: pointer;
      }
    }
  }
`;

const ImageWrapper = styled.div`
  display: inline-flex;
  position: relative;

  img {
    align-self: flex-start;
    width: ${ (props) => props.imgSizes[props.imgSize] };

    @media (max-width: 1200px) {
      width: ${ (props) => props.imgSize === 'xl' ? '120px' : props.imgSizes[props.imgSize] }
    }
  }
`;

const IconContainer = styled.span`
  align-items: center;
  display: inline-flex;

  svg {
    height: ${props => props.iconSize == 'small' ? '24px' : '30px'};
    width: ${props => props.iconSize == 'small' ? '24px' : '30px'};

    &, path {
      fill: ${props => props.iconColor};
    }
  }
`;

const MediaObject = ({
  alt,
  analytics,
  children,
  className,
  icon,
  iconColor = 'pink',
  iconSize = 'large',
  img,
  imgSize = 'small',
  removeExternalClass,
  reverse,
  target,
  url,
}) => {
  let imgSrc = img;
  let iconColorCode = iconColors[iconColor];
  const usePadding = (imgSize !== 'icon' && imgSrc) || (imgSize === 'icon' && icon)

  return (
    <div css={[base ({ reverse, imgSize, usePadding })]} className={`${className} ${removeExternalClass ? '' : 'oc-media-object'}`}>
      <WrapperLink url={url} element="div" analytics={analytics} target={target}>
        <ImageWrapper imgSize={ imgSize } imgSizes={ imgSizes }>
          {(imgSrc && imgSize != 'icon') &&
            <Image src={imgSrc} alt={alt} className="img-responsive" />
          }

          {(imgSize == 'icon') &&
            <IconContainer iconColor={iconColorCode} iconSize={iconSize}>
              <Icon icon={icon} />
            </IconContainer>
          }
        </ImageWrapper>

        <div className="media-body">
          {children}
        </div>
      </WrapperLink>
    </div>
  )
}
export default MediaObject;

/*

TODO:

Currently fetch isn't working, most likely needs
CLOUDINARY_URL set up for javascript to acccess so it
can sign uploads. Please only use Cloudinary Pub IDs
until this message goes away.

Note, uses Array.wrap which is a sort of a polyfill loaded globally

USAGE:

The base transform object can be replaced with a string
name of a preset you want to use. You can find presets
in the static object Presets in the Picture class.

All transformation objects use key names from:
  cloudinary.com/documentation/transformation_reference
using their full names (not URL short names)

Variant transforms override base transform properties,
so you only have to set transformations you want
through all variants in the base transformation


Import the default export with:

  import Picture from "./oc-picture";

And use Picture as a component like below to
create a picture element with the variants becoming
sources and the base source/transform becoming
the fallback img tag at the bottom

  <Picture
    source="cloudinary/image/id"
    transform="example"
  />

  <Picture
    source="cloudinary/image/id"
    transform={{ width: 100, height: 100, quality: 60 }}
    variants={[
      {
        width: 200,
        height: 200,
        media: '(min-width: 800px)'
      },
      {
        width: 400,
        height: 400,
        media: '(min-width: 1200px)'
      }
    ]}
  />

The aspectRatio param can be used to chain an optional aspect ratio transformation to the base transform as in:
  <Picture
    source="cloudinary/image/id"
    transform={{ width: 100, height: 100, quality: 60 }}
    aspectRatio={{ selection: '2' }}
  />
or
  <Picture
    source="cloudinary/image/id"
    transform={{ width: 100, height: 100, quality: 60 }}
    aspectRatio={{ selection: 'custom', custom: '2.1' }}
  />

You can also use static methods on the imported
Picture class for more programmatic tasks.


Using Picture.Transform you can get Transformation
URLs for Cloudinary Pub IDs or full URLs (full URLs
are fetched by Cloudinary), for example:

  Picture.Transform(
    "cloudinary/image/id",
    "example"
  )

  Picture.Transform("cloudinary/image/id", {
    width: 100,
    height: 100,
    crop: 'thumb'
  })


The Picture.Background function generates a set of background
images, the first sitting outside of a media query and the
variants sitting in their own media queries

Use the "styles" key in any transform to add css after
their respective background-image css

  Picture.Background(
    "cloudinary/image/id",
    { styles: css` background-position: 100px 10px; ` }
  )

  Picture.Background(
    "cloudinary/image/id",
    {
      width: 100,
      height: 100,
      crop: 'thumb',
      quality: 60,
      styles: css`
        background-position: center;
      `
    },
    [
      {
        width: 200,
        height: 200,
        media: '(min-width: 800px)',
        styles: css`
          background-size: contain;
        `
      },
      {
        width: 400,
        height: 400,
        media: '(min-width: 1200px)',
        styles: css`
          background-size: cover;
        `
      }
    ]
  )


The static method Picture.Sources can also be used to
get transformation URLs and other settings for rendering,
but I'm not sure why you'd want to use this outside
of extending rendering options.

  Picture.Sources(
    "cloudinary/image/id",
    "hero"
  )

  Picture.Sources(
    "cloudinary/image/id",
    { width: 100, height: 100, quality: 60 },
    [
      {
        width: 200,
        height: 200,
        media: '(min-width: 800px)'
      },
      {
        width: 400,
        height: 400,
        media: '(min-width: 1200px)'
      }
    ]
  )

*/

import { Component } from 'react';
import { css } from '@emotion/react';
import { isUrlCloudinary, getComponentsFromCloudinaryUrl, CloudinaryDefaultConfig, swapResultToCloudfront } from '../../helpers/cloudfront_helper';
import { Cloudinary } from 'cloudinary-core';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { is_placeholder_url, placeholder_aspect } from '../../placeholder';

class Picture extends Component {
  static Blank = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==';

  static propTypes = {
    source: PropTypes.string.isRequired,
    blankImage: PropTypes.bool,
    transform: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    variants: PropTypes.arrayOf(PropTypes.object),
    aspectRatio: PropTypes.shape({
      selection: PropTypes.string,
      custom: PropTypes.string
    }),
    alt: PropTypes.string,
  };

  static Defaults = { fetchFormat: 'auto', quality: 'auto' };

  static AspectRatioTransform(aspectRatio) {
    const aspectRatioParam = (aspectRatio?.selection||'none') === 'none' ? null :
                              aspectRatio.selection === 'custom'         ? Number(aspectRatio.custom) :
                                                                           Number(aspectRatio.selection)

    return aspectRatioParam && !isNaN(aspectRatioParam) && aspectRatioParam > 0 ? {
      aspectRatio: aspectRatioParam,
      crop: "crop"
    } : null;
  }

  static Cloudinary = new Cloudinary(CloudinaryDefaultConfig);

  static Presets = {
    example: {
      transform: { width: 100, height: 100, crop: 'thumb' },
      variants: [
        {
          width: 200,
          height: 200,
          media: '(min-width: 768px)'
        }
      ]
    },
    cards: {
      transform: {width: 796, crop: "fill", quality: 30},
      variants: [
        {
          media: '(min-width: 1200px) and (min-resolution: 2dppx)',
          width: 1146,
          quality: 30
        },
        {
          media: '(min-width: 1200px)',
          width: 573,
          quality: 50
        },
        {
          media: '(min-width: 768px) and (min-resolution: 2dppx)',
          width: 746,
          quality: 30
        },
        {
          media: '(min-width: 768px)',
          width: 373,
          quality: 50
        },
        {
          width: 796,
          quality: 30
        },
      ]
    }
  };

  static Preset(transform) {
    if (_.isPlainObject(transform) || !(transform in Picture.Presets)) return null;
    return {
      transform: Picture.Presets[transform]?.transform || Picture.Presets[transform],
      variants: Picture.Presets[transform]?.variants
    };
  }

  static Transform = swapResultToCloudfront(this, (source, transformation, aspectRatioTransform) => {
    if (!_.isString(source)) return '';

    const isCloudinary = isUrlCloudinary(source);

    // if it's a full url (http(s)://something or //something) and not cloudinary, return it
    if (source.match(/^([a-zA-Z]+:)?\/\//) && !isCloudinary) return source;

    // at this point, we assume we are a cloudinary url or public id
    const { transforms, type, public_id } = getComponentsFromCloudinaryUrl(source)

    if (!public_id) {
      return public_id;
    }

    transformation = (Array.wrap(transformation)||[]).filter(x => x)

    const isQualitySpecified = transforms.includes('q_') || transformation.some(t => t.hasOwnProperty('quality'))

    transformation = [...transformation.map(trans => {
      // lookup a named transformation
      if (_.isString(trans)) trans = Picture.Preset(trans)?.transform || {};

      // see note here: https://cloudinary.com/documentation/resizing_and_cropping#resize_and_crop_modes
      // "specifying a width or height on URL without crop defaults to crop=scale"
      // looking at cloudinary-core source if crop/overlay/underlay not set it just skips width and height
      // so we apply the default crop = scale in these cases
      if ( (trans?.width || trans?.height) && !(trans?.crop || trans?.overlay || trans?.underlay) ) {
        trans = { ...trans, crop: 'scale' }
      }
      return trans;
    }), aspectRatioTransform, _.omit(Picture.Defaults, isQualitySpecified ? 'quality' : '')].filter(x => x);

    let newUrl = "";
    if (source?.indexOf("ocimages") >= 0) {
      const [root, path] = source?.split('/image/') || [];
      const [cloudName] = root?.split('/').reverse() || [];
      const config = _.merge(CloudinaryDefaultConfig, { cloud_name: cloudName });
      const cloudinary = new Cloudinary(config);
      newUrl = cloudinary.url(public_id, { transformation, type });
    } else {
      newUrl = Picture.Cloudinary.url(public_id, { transformation, type });
    }

    const { base_url, transforms: newTransforms } = getComponentsFromCloudinaryUrl(newUrl)

    return [base_url, transforms, newTransforms, public_id].filter(x => x).join('/');
  })

  static Sources(source, transform, variants, aspectRatioTransform) {
    if (_.isString(transform)) {
      const preset = Picture.Preset(transform);
      if (preset) transform = preset.transform;
      if (preset?.variants) variants = preset?.variants;
    }

    return {
      base: Picture.Transform(source, transform, aspectRatioTransform),
      variants: variants?.map((variant) => {
        const { media, styles, source: variantSource, ...mutation } = variant;
        return {
          url: Picture.Transform(variantSource || source, [ ...Array.wrap(transform), ...Array.wrap(mutation) ], aspectRatioTransform),
          styles: styles,
          media: media
        };
      }) || []
    };
  }

  static DefaultOrCrop(baseImage, crop, defaultImage) {
    let processedImage = baseImage || defaultImage;

    if (baseImage && crop) {
      processedImage = Picture.Transform(processedImage, { crop: 'crop', ...crop })
    }

    return processedImage;
  }

  static Background(source, transform, variants) {
    const { styles, ...transformation } = transform;
    const sources = Picture.Sources(source, transformation, variants);
    return css`
      background-image: url(${ sources.base });
      ${ styles }
      ${ sources.variants.map((variant) => (css`
        @media ${ variant?.media } {
          background-image: url(${ variant?.url });
          ${ variant?.styles }
        }
      `)) }
    `;
  }

  constructor(props) {
    super(props);
  }

  render() {
    const { source, blankImage, transform, aspectRatio, variants, ...props } = this.props;

    const aspectRatioTransform = Picture.AspectRatioTransform(aspectRatio);

    let img = source;
    if (is_placeholder_url(img) && aspectRatioTransform) {
      img = placeholder_aspect(img, aspectRatioTransform.aspectRatio)
    }

    const parsed = Picture.Sources(
      img,
      transform,
      variants,
      aspectRatioTransform
    );

    return (
      <picture>
        {
          parsed.variants.map((variant, i) => (
            <source
              key={ i }
              srcSet={ variant.url }
              media={ variant.media }
            />
          ))
        }
        <img { ...props } src={ blankImage ? Picture.Blank : parsed.base } />
      </picture>
    );
  }
}

export default Picture;

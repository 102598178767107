import { connectLocation } from "../../location/LocationConnectors";

const FilterList = connectLocation(({t, filters, filterCounts, appliedFilters, updateParams, resetFilter, loading}) => {
  return (<>
    {Object.keys(filters).map((filter, index) => {
      let FilterComponent = filters[filter].component
      let filterProps = filters[filter].props
      return (
        <FilterComponent
          filterCounts={filterCounts[filter]}
          collapseText={filters[filter].collapseText || t("filter_list.collapse_text")}
          defaultValue={appliedFilters[filter]}
          displayLimit={filters[filter].displayLimit}
          expandText={filters[filter].expandText || t("filter_list.expand_text")}
          filterAttribute={filter}
          key={index}
          loading={loading}
          name={filter}
          onChange={updateParams}
          options={filters[filter].options || []}
          resetFilter={resetFilter}
          title={filters[filter].displayName}
          value={appliedFilters[filter]}
          onComponentChange={filters[filter]?.onComponentChange}
          {...filterProps}
        />
      )})}
  </>)
});

export default FilterList;

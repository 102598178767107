import styled from '@emotion/styled';

import Link from '../oc-link';
import ContactUs from '../oc-contact-us';
import { H2 } from '../oc-h';
import RichText from './RichText';
import Text from './utilities/Text';
import { BasicContent } from './BasicContent';

export const Presets = {
  pink: {
    title: 'Contact Us',
    text: 'Even though we’re a global company, we’re always ready to answer your questions. Call to speak with an Owens Corning representative.',
    phoneLabel: '1-800-GET-PINK®',
    phoneReal: '+1-800-438-7465'
  },
  tech: {
    title: 'Contact Us',
    text: 'Even though we’re a global company, we’re always ready to answer your questions. Call to speak with an Owens Corning representative.',
    phoneLabel: '1-844-OC-INSOL',
    phoneReal: '1-800-438-7465',
    email: 'GETTECH@owenscorning.com'
  },
  pro: {
    title: 'Contact ProDesk®',
    text: 'Even though we’re a global company, we’re always ready to answer your questions. Call to speak with an Owens Corning representative.',
    phoneLabel: '1-866-PRODESK',
    phoneReal: '1-866-3375'
  },
  roofing: {
    title: 'Contact Us',
    text: 'Even though we’re a global company, we’re always ready to answer your questions. Call to speak with an Owens Corning representative so you can start your project right away.',
    phoneLabel: '1-800-ROOFING',
    phoneReal: '+1-800-766-3464'
  },
  science: {
    title: 'Contact Us',
    text: 'Our Building Science Center would love to get to know you. Give us a call or email and tell us about yourself and your project. Please note that certain resource intensive services are available only for qualifying projects or with certain basis of design specification requirements.',
    phoneLabel: '1-844-OC-INSOL',
    phoneReal: '1-800-438-7465',
    email: 'GETTECH@owenscorning.com'
  }
};

export const availablePresets = {
  'www.owenscorning.com': {
    pink: 'Get Pink',
    tech: 'Get Tech',
    roofing: 'Roofing',
    science: 'Building Science',
    pro: 'Pro Desk',
    custom: 'Custom',
  },
  'www.paroc.com': {
    custom: 'Custom',
  },
};

export const defaultPreset = {
  'www.owenscorning.com': 'pink',
  'www.paroc.com': 'custom'
}

const Container = styled.div`
  padding: 6px 0;
  max-width: 1170px;
  width: 50%;
  margin: 0 auto;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const CMSContactUs = Text.Parse(
  ContactUs,
  'phoneLabel',
  'emailUs',
  'callUs'
);

export default ({ preset, custom }) => {
  const content = preset === 'custom' ? custom : Presets[preset];

  return (
    <Container>
      <BasicContent heading={ content?.title || Presets.pink?.title } bodyCopy={ content?.text || Presets.pink?.text } />
      <CMSContactUs
        phoneLabel={ content?.phoneLabel || Presets.pink?.phoneLabel }
        phoneReal={ content?.phoneReal || Presets.pink?.phoneReal }
        email={ content?.email || Presets.pink?.email }
        emailUs={ content?.emailUsText }
        callUs={ content?.callUsText }
        formLabel={ content?.formText }
        form={ content?.form }
      />
    </Container>
  )
};

import { ui } from '@owenscorning/pcb.alpha';
import IconPimLink from '../../../ComponentLibrary/icons/icon-pim-link';

// This UI component is basically a placeholder for connected PIM fields
// in the editor sidebar when the field comes from the API and isn't editable
export default ui`Form`.of({})({
  noPadding: true,
  tip: "Field connected to PIM product.",
  tipIcon: IconPimLink
});

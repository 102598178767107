const PLACEHOLDER_LOGO =
  'https://imagecdn.owenscorning.com/ocimages/image/upload/v1639419433/insulation/residential/distributors-near-me/logo-placeholder.png';

const homeCenterLogos = {
  'home depot': 'https://imagecdn.owenscorning.com/ocimages/image/upload/v1638891619/insulation/residential/distributors-near-me/home-depot.png',
  'lowes': 'https://imagecdn.owenscorning.com/ocimages/image/upload/v1638891533/insulation/residential/distributors-near-me/lowes.png',
  'menard': 'https://imagecdn.owenscorning.com/ocimages/image/upload/v1638891538/insulation/residential/distributors-near-me/menards.png'
};
  
const distributorLogos = {
  'insulation distributors, inc': 'https://imagecdn.owenscorning.com/ocimages/image/upload/v1642102613/insulation/residential/distributors-near-me/idi-distributors.png',
  'roofing & insulation supply': 'https://imagecdn.owenscorning.com/ocimages/image/upload/v1642102613/insulation/residential/distributors-near-me/ris-insulation-supply.png'
};

const getLogoFromMap = (name, logoMap) => {
  const lowerCaseName = name.toLowerCase();
  
  for (const key in logoMap) {
    if (lowerCaseName.includes(key)) {
      return logoMap[key];
    }
  }
  
  return null;
}

const homeCenterLogo = (name) =>
  getLogoFromMap(name, homeCenterLogos);

const distributorLogo = (name) =>
  getLogoFromMap(name, distributorLogos);

export function getRoofingInsulationLogo({ name = '', logo }) {
  return (
    logo || 
    distributorLogo(name) || 
    homeCenterLogo(name) || 
    PLACEHOLDER_LOGO
  );
}

import Cookies from 'js-cookie';
import _ from 'lodash';
import qs from 'qs';

import { localeSort, parentLocales } from "./components/location/locales";
import mdms_api from './mdms_api';

const internal_get = async (query, options) => {
  const querystring = qs.stringify(query, { arrayFormat: 'brackets' });
  const url = `/api/v2/cms/sites/${PB_SITE}/published?${querystring}`;
  const response = await mdms_api.get(url, _.merge(options || {}, { headers: { 'X-PB-Groups': Cookies.get('pb_groups') } }));
  return response.json();
};

// standard query, multiple results
const get = async (query, options) => {
  return (await internal_get(query, options))?.data;
};

// standard query, loop multiple pages
const PAGE_SIZE = 100;
const get_all = async (query, options) => {
  const newQuery = _.cloneDeep(query);
  newQuery.page = { number: 1, size: PAGE_SIZE };
  let data = [];
  while (true) {
    // eslint-disable-next-line no-await-in-loop
    const response = await internal_get(newQuery, options);
    data = data.concat(response?.data || []);
    if (response?.meta?.current_page >= response?.meta?.total_pages) {
      break;
    }
    newQuery.page.number += 1;
  }
  return data;
};

// expects only a single result, gets only one
const get_single = async (query, options) => {
  const contents = await get({ ...query, page: { number: 1, size: 1 } }, options);
  return contents?.[0];
};

// expects only a single result, gets only one and returns contents field (common use case)
const get_single_contents = async (query, options) => {
  const content = await get_single(query, options);
  return content?.contents;
};

const closestMatchedLanguageResult = results => {
  const matchedLanguages = results.map(d => d.language_iso_code).sort((a, b) => localeSort({ code: a }, { code: b }))
  const bestMatch = _.last(matchedLanguages);
  const result = results.filter(d => d.language_iso_code === bestMatch)[0];
  //console.log('closestMatchedLanguageResult', { results, result })
  return result;
};

// expects API call to return 1 to X but return only the "closest language" version
const get_single_for_language = async (query, language, options) => {
  const newQuery = _.clone(query);
  newQuery.filter = newQuery.filter || {};
  newQuery.filter.language_iso_code = Array.wrap(language).concat(parentLocales[language]);
  newQuery.fields = newQuery.fields || {};
  Object.keys(newQuery.fields).forEach(key => {
    if (newQuery.fields[key].indexOf('language_iso_code') < 0) {
      const fields = newQuery.fields[key].split(',');
      fields.push('language_iso_code');
      newQuery.fields[key] = fields.join(',');
    }
  });
  //console.log('get_single_for_language', newQuery)
  return closestMatchedLanguageResult(await get(newQuery, options));
};

// same as above but returns contents field (common use case)
const get_single_for_language_contents = async (query, language, options) => {
  const content = await get_single_for_language(query, language, options);
  return content?.contents;
};

export default {
  get,
  get_all,
  get_single,
  get_single_contents,
  get_single_for_language,
  get_single_for_language_contents,
};

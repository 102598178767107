import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';

const InfoIconContainer = styled.span`
  .fa {
    color: ${props => props.color ? props.color : '#6d6d6d'};
    font-size: ${props => props.size ? props.size : '16px'};

    &:hover {
      color: #d40f7d;
      cursor: pointer;
    }
  }
`

const InfoIcon = ({ clickHandler, size, color, enabled = true, ...props }) => (
  <InfoIconContainer size={size} color={color} {...props} >
    <i
      role="button"
      aria-label="More Information"
      className="fa fa-info-circle"
      tabIndex={enabled ? 0 : -1}
      onClick={clickHandler}
      onKeyPress={(e) => {
        if (e.key === 'Enter' || e.key === 'Space') {
          clickHandler()
        }
      }}
    />
  </InfoIconContainer>
);

export default InfoIcon;

import querystring from 'qs';

import CTAButton from '../../../../CTAButton';
import { openComponentModal } from '../../../../PageBuilder/helpers/modal';
import { SchemaForm } from '../../../../forms/InnerSchemaForm';
import { connectLocation } from '../../../../location/LocationConnectors';

const EmailProfessionalModal = connectLocation(
  ({ locale: { code }, location }) => {
    let params = {};

    if (location) {
      params = {
        contractor: location.membership_number,
        contractor_email: location.email,
        contractor_name: location.name,
        origin_url: window.location.href.split('#')[0],
        lang: code?.split('-')?.[0],
      };
    }

    const query = `?${querystring.stringify(params)}`;

    return (
      <div className="wrapper">
        {location && (
          <SchemaForm
            uid={`www.owenscorning.com/${(
              code || 'en-US'
            ).toLowerCase()}/insulation/cee-contractor-form`}
            search={query}
          >
            <CTAButton
              className="oc-cta-button"
              element="button"
              text="submit"
              type="submit"
            />
          </SchemaForm>
        )}
      </div>
    );
  }
);

export const openProfessionalEmailModal = location => {
  openComponentModal(() => <EmailProfessionalModal location={location} />);
};

import { define, ui } from '@owenscorning/pcb.alpha';

import Renderer from '../../../../OC/PageBuilder/ParocCo2Calculator.jsx';


export default define`Paroc CO2 Calculator`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/ParocCo2Calculator`,
  category: ui`Modules/Categories/Page/Prebuilt`,
  view: ({ value }) => <Renderer { ...value } />,
  edit: {},
});

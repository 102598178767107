import React, { useState } from "react";
import GoogleMapReact from 'google-map-react';
import styled from "@emotion/styled";

// Imported Components
import MapPin from "./oc-map-pin";
import {connectLocation} from "../location/LocationConnectors";

// Styled Components
const MapContainer = styled.div`
  background-color: lightblue;
  display: inline-block;
  height: 100vh;
  max-height: 42em;
  position: relative;
  width: 100%;
`;

const HelperText = styled.p`
  font-size: 12px;
  padding: 0 5%;
  text-align: center;
`;

const Map = connectLocation(({ t,locale: { code: localeCode }, className, locations, extraBounds = [] })  =>  {
  const [activePin, setActivePin] = useState(null);
  const [activeCenter, setActiveCenter] = useState(null);
  const language = _.first(localeCode.split('-'))

  let defaultCenter = { lat: locations[0]?.latitude, lng: locations[0]?.longitude };
  let defaultZoom = 10;
  let windowHeight = window.screen.height;
  let windowWidth = window.screen.width;

  // Return map bounds based on list of places
  const getMapBounds = (map, maps, locations) => {
    const bounds = new maps.LatLngBounds();

    locations.forEach((place) => {
      bounds.extend(new maps.LatLng(
        place.latitude,
        place.longitude,
      ));
    });
    extraBounds.forEach(([lat, lng]) => {
      bounds.extend(new maps.LatLng(lat, lng));
    })
    return bounds;
  };

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        //Added time buffer because the zoom in/out triggered window resize in iPhone safari
        //So triggering map.fitBounds when the screen size is actually changed
        setTimeout(() => {
          if(windowHeight !== window.screen.height || windowWidth !== window.screen.width){
            map.fitBounds(bounds);
            windowHeight = window.screen.height;
            windowWidth = window.screen.width;
          }
        }, 300);
      });
    });
  };

  // Fit map to its bounds after the API is loaded
  const apiIsLoaded = (map, maps, locations) => {
    // Get bounds by our places
    const bounds = getMapBounds(map, maps, locations);
    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
  };

  const deactivatePin = (event) => {
    setActivePin(null);
    setActiveCenter(null);
  }

  const activatePin = (location, e) => {
    e.stopPropagation();
    let coordinates = { lat: location.latitude, lng: location.longitude };

    setActivePin(location.list_number);
    setActiveCenter(coordinates);
  }

  const conditionalTabIndex = (condition) => {
    return condition ? "0" : "-1";
  }

  const navigatePins = (event, location) => {
    let key = event.key;
    let locationNumber = location.list_number;

    switch (key) {
      case " ":
        event.preventDefault();
        activatePin(location, event);
        break;
      case "ArrowLeft": // "37";
      case "ArrowUp": // "38";
        event.preventDefault();
        setActivePin(null);
        let prev = document.getElementById(`pin-${locationNumber - 1}`);
        if (prev) {
          document.getElementById(`pin-${locationNumber}`).tabIndex = "-1";
          prev.tabIndex = "0";
          prev.focus();
        }
        break;
      case "ArrowRight": // "39";
      case "ArrowDown": // "40";
        event.preventDefault();
        setActivePin(null);
        let next = document.getElementById(`pin-${locationNumber + 1}`);
        if (next) {
          document.getElementById(`pin-${locationNumber}`).tabIndex = "-1";
          next.tabIndex = "0";
          next.focus();
        }
        break;
      default:
        break;
    }
  }

  return (
    <MapContainer className={className}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY, language}}
        center={activeCenter}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        onClick={(event) => deactivatePin(event)}
        onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, locations)}
        options={{ clickableIcons: false }}
        yesIWantToUseGoogleMapApiInternals
      >
        {locations.map((location, index) => {
          let locationNumber = location.list_number;
          let active = (activePin === locationNumber);
          let tabIndex = activePin ? conditionalTabIndex(active) : conditionalTabIndex(index === 0);

          return (
            <MapPin
              active={active}
              id={`pin-${locationNumber}`}
              key={locationNumber}
              label={locationNumber}
              lat={location.latitude}
              lng={location.longitude}
              location={location}
              onClick={(e) => activatePin(location, e)}
              onKeyDown={(event) => navigatePins(event, location)}
              tabIndex={tabIndex}
            />
          );
        })}
      </GoogleMapReact>

      <HelperText>
        <strong>{t("generic_locator.map_keyboard_navigation_tip_title")}:</strong> {t("generic_locator.map_keyboard_navigation_tip")}
      </HelperText>
    </MapContainer>
  );
});

export default Map;

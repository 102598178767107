import { ui, select, transform, when, s } from '@owenscorning/pcb.alpha';
import { Global, css } from '@emotion/react';

import _ from 'lodash';
import Content from './Content';
import HierarchicalFilter from '../../../ComponentLibrary/filter-tree/oc-hierarchical-filter';
import CheckboxSelector from '../../../ComponentLibrary/filter-list/oc-filters';
import FilterList from '../../../ComponentLibrary/filter-list/oc-filter-list';
import { useState } from 'react';
import pluralize from 'pluralize';
import { filterEntity, isItemVisible } from '../../../PageBuilder/helpers/content';

// max depth for tree-style
// in pre-PB data max depth is 4 in composites/insulation applications and insulation industry
const MAX_DEPTH = 5;

let ItemMeta = null
for (let i = 0; i < MAX_DEPTH; i++) {
  ItemMeta = ui`Form`.of(
    _.merge({
      label: ui`Text`({
        label: 'Display Label',
        default: 'Label',
      }),
      value: select`../multiValued`.from({
        true: ui`List`.of(ui`Text`)({ label: 'Token', singular: 'Token', title: i => i, hideable: false }),
        false: ui`Text`({ label: 'Token' })
      }),
      multiValued: ui`Switch`({
        label: 'Allow Multiple Tokens',
        default: false
      }),
      [s._]: ui`Tip`.of('Token must be unique across all items across all parents/children.<br/>' +
                        'It will be assigned in content data and used for filtering, so changing it will mean ' +
                        're-assigning content items.<br/>' +
                        'If you leave this blank, a unique value based on text will be generated upon saving.<br/>' +
                        'If you update this and it conflicts with another value, one of the values will be altered ' +
                        'upon saving.<br/>' +
                        'This value is also helpful to align across translations or map to legacy values.'),
      icon: ui`Image`({
        label: 'Icon'
      }),
      image: ui`Image`({
        label: 'Image'
      }),
      details: ui`List/Item`.of(
        ui`Form`.of({
          title: ui`Text`({
            label: 'Modal Title'
          }),
          reusable: ui`Modules/Page/Reusable`,
        })
      )({
        title: 'Info Modal',
        unwrapped: true,
        hideable: true,
      })
    },
    ItemMeta ? {
      children: ui`List`.of(
        ItemMeta
      )({
        label: 'Children',
        singular: 'Child',
        title: 'label',
      })
    } : {})
  )
}

const Attribute = Content(
  'Attribute',
  (UI) => ({
    version: '0.1',
    displayName: 'Attribute',
    usage: 'name',
    read: ({ edit = false, build, contents, metadata }) => {
      let { singular, plural, ...rest } = contents || {};
      if (edit) {
        if (!singular) {
          singular = pluralize.singular(build.name)
        }
        if (!plural) {
          plural = pluralize.plural(build.name)
        }
      }
      return { contents: { singular, plural, ...rest }, metadata }
    },
    write: ({ contents, metadata }) => {
      const tokens = {};
      const readAndGenerateTokens = (list, prefix) => {
        list.forEach(item => {
          if (!item.value) {
            // generate a token
            item.value = [prefix, _.kebabCase(item.label)].filter(_.identity).join('/')
          }
          const originalItemToken = item.value;
          let suffix = 1;
          while (tokens[item.value]) {
            // fix collision
            item.value = `${ originalItemToken }-${ suffix++ }`;
          }
          tokens[item.value] = item.value;
          if (item.children) {
            readAndGenerateTokens(item.children, item.value);
          }
        })
      }
      readAndGenerateTokens((contents?.items||[]), null)
      return { contents, metadata }
    },
    sidebar: {
      builder: ui`Form`.of({
        singular: ui`Text`({
          label: 'Singular'
        }),
        plural: ui`Text`({
          label: 'Plural',
        }),
        [s._]: ui`Tip`.of('Singular and Plural names are used in UI displays for filtering'),
        items: ui`List`.of(ItemMeta)({
          label: 'Items',
          singular: 'Item',
          title: 'label',
        })
      })
    },
    view: Attribute.Renderer(UI),
  })
);

const createFilterOptionsFromData = (data, prefix) => (
  (data||[]).map((option, index) => {
    const id = `${ prefix ? `${ prefix }-` : '' }${ index }`;
    return _.merge({}, { value: id, displayName: option.label, name: option.label, ...option }, option.children ? { children: createFilterOptionsFromData(option.children, id) } : {})
  })
)

Attribute.Renderer = (UI, parameters={}) => ({
  contents: ({ value = {} }) => {
    const { items, singular, plural } = filterEntity(value, isItemVisible);

    const anyChildren = _.some(items, (value) => (value.children||[]).length > 0);

    const [selection, setSelection] = useState('')

    const options = createFilterOptionsFromData(items, '');
    const counts = {}
    const setCounts = (list) => {
      list.forEach(item => {
        counts[item.value] = 1
        if (item.children) {
          setCounts(item.children);
        }
      })
    }
    setCounts(options)

    return (
      <Content.PreventClicks>
        <Global styles={css`
          body {
            background-color: #f8f8f8
          }
          `} />
        <br />
        <div style={{ width: '300px', display: 'flex', justifyContent: 'center', backgroundColor: 'white', padding: '8px' }}>
          <FilterList filters={
                        {
                          filter: _.merge(
                            {
                              component: anyChildren ? HierarchicalFilter : CheckboxSelector,
                              displayName: singular,
                              options,
                              displayLimit: 5,
                              valueType: 'multiple',
                            },
                            anyChildren ? { props: { root: { name: `All ${ plural }` } } } : {}
                          )

                        }
                      }
                      filterCounts={ { filter: counts } }
                      appliedFilters={ { filter: [selection] } }
                      updateParams={ (attribute, option) => { setSelection(option) } }
          />
        </div>
      </Content.PreventClicks>
    )
  }
});

export default Attribute;

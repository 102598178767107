export default {
  displayName: 'Full Bleed Content',
  allowableParents: ['Section'],
  gutterless: true,
  migrator: (data) => {
    if (data === undefined || data === null) {
      return {};
    }

    // PB-444: FullBleedContent images have been transformed to use UI`Image` instead of UI`Upload/Image`.
    //         As a consequence, we need to migrate the already set images to the new structure,
    //         so that we don't lose the existing setup and allow cropping the previously
    //         existing images.
    if (typeof data?.img === 'string') {
      data.img = {
        file: data.img,
        crop: undefined,
      };
    }

    return data;
  }
};

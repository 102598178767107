(function() {
  const openCookieSettings = (event) => {
    if (typeof Osano !== "undefined") {
      event && event.preventDefault();
      Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
    }
  };

  global.PB_FUNCTIONS = {
    openCookieSettings: openCookieSettings
  };
}).call(this);

import styled from '@emotion/styled';
import { jsx, css } from '@emotion/react';
import Viewport from '../../../Library/Viewport';
import Picture from '../../../../OC/oc-picture';
import { is_placeholder_url, placeholder_aspect } from '../../../../../placeholder';
import MediaObject from '../../../../OC/oc-media-object';
import { ContentWrapper } from '../../../../OC/PageBuilder/MediaObject';
import Text from '../../../../OC/PageBuilder/utilities/Text';
import { H3 } from '../../../../OC/oc-h';
import RichText from '../../../../OC/PageBuilder/RichText';
import DocList from '../../../../ProductList/items/DocumentList';
import Link from '../../../../OC/oc-link';
import Modal from '../../../../Modal';

// helpers
import { truncateText } from '../../../../../helpers/text_truncate_helper';

const FeatureList = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 0px;
  list-style: none;
  list-style: none;
  ${ props => props.multiple ? css`
    @media (${Viewport.from.Tablet}) {
      flex-direction: row;
    }
  ` : '' }
  li {
    flex: 1
  }
`;

const ProductItem = ({ aspectRatio, category, preheading, heading, img, text, url, features, documents, availability }) => {
  const aspectRatioTransform = Picture.AspectRatioTransform(aspectRatio);
  if (is_placeholder_url(img?.file) && aspectRatioTransform) {
    img = placeholder_aspect(img?.file, aspectRatioTransform.aspectRatio)
  } else {
    img = Picture.DefaultOrCrop(img?.file, img?.crop, img?.file)
  }

  return (
    <MediaObject
      className="embedded-video"  // TODO: i don't know why these aren't filling the available space
      imgSize="large"
      img={ Picture.Transform(img, [aspectRatioTransform, { width: 240, crop: 'fill' }]) }
      alt={ heading }
      url={ url }
      removeExternalClass
      analytics={{
        track: 'view-product',
        'product-name': heading
      }}
    >
      <ContentWrapper url={url} >
        { category }
        { heading && <H3 prehead={ preheading } tag="h4" font="body"><Text content={ heading } /></H3> }

        { availability ?
          <div className="availability" >
            <Link className="availability-open" onClick={ availability.onOpen } analytics={{ track: "open-modal", "modal-name": "Region Availability Modal", "product-name": heading }} ><div className="availability-icon" ></div> <span>View Availability Details</span></Link>
            <Modal open={ availability.open } onClose={ availability.onClose } data-am-region="Region Availability Modal" >
              <h2 >Regional Availability</h2>
              { availability.title ? <h4>{ availability.title }</h4> : null }
              { availability.description ? <p >{ availability.description }</p> : null }
              { availability.table ? <div className="availability-table" dangerouslySetInnerHTML={{ __html: availability.table }} ></div> : null }
              <a className="availability-pdp-cta" href={url} >View Product Details</a>
            </Modal>
          </div>
          : null }

        <RichText content={ truncateText(text, 320) } />

        {documents && documents.length > 0 && <DocList documents={documents} />}

        {
          features &&
          <FeatureList multiple={ features.length > 1 }>
            {
              features.map((name, index) => (
                <li key={ index }><Text content={ name } /></li>
              ))
            }
          </FeatureList>
        }
      </ContentWrapper>
    </MediaObject>
  );
}

export default ProductItem;

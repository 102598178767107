import {connect} from 'react-redux';
import _ from 'lodash';
import {PROPINK_SPRAYFOAM_SET_VARIANT} from '../Actions';

export const sprayfoamReducer = ( state = { variant: 'open_cell' }, action ) => {
  const newState = _.cloneDeep(state);

  switch(action.type) {
    case PROPINK_SPRAYFOAM_SET_VARIANT:
      const variant = action.variant;
      return _.assign({}, newState, {variant});
  }

  return state;
}

export const setVariant = (variant) => ({ type: PROPINK_SPRAYFOAM_SET_VARIANT, variant })
export const clearVariant = () => ({ type: PROPINK_SPRAYFOAM_SET_VARIANT, variant: null })

export const connectVariant = connect(
  ({ sprayfoamCalculator }) => (sprayfoamCalculator),
  { setVariant, clearVariant }
);

import displayName from '../helpers/displayName'
import { BrowserRouter } from 'react-router-dom'

export default function(WrappedComponent) {
  function wrappedInRouter(props) {
    return (
      <BrowserRouter>
        <WrappedComponent {...props} />
      </BrowserRouter>
    )
  }

  wrappedInRouter.displayName = `wrappedInRouter(${displayName(WrappedComponent)})`

  return wrappedInRouter
}

import { ui } from '@owenscorning/pcb.alpha';

function openModal(props) {
  if (typeof Board === 'undefined' || !props.body) {
    return;
  }

  const modalProps = { ...props };
  if (!modalProps.title) {
    delete modalProps.title;
  }
  Board.modal.open(modalProps);
}

export function openReusableModal(reusable, title = null, modalOptions = {}) {
  openModal({
    ...modalOptions,
    body: ui`Modules/Page/Reusable|view`({
      value: reusable,
      gutterlessChild: true,
    }),
    title,
  });
}

export function openComponentModal(component, title = null, modalOptions = {}) {
  openModal({
    ...modalOptions,
    body: component,
    title,
  });
}

import {connect} from 'react-redux';
import _ from 'lodash';
import { LOCATION_CHANGE, LOCATION_MODAL_TOGGLE, LOCATION_MODAL_FORCE_RELOAD } from '../Actions';
import { makeT, localeNumberFormat } from './locales';
import getReduxInitial from "../helpers/redux_initial";

const DEFAULT = {locale: {code: 'en-US'}};
const locationInitial = getReduxInitial('location', DEFAULT);

export const locationReducer = (state = locationInitial, action) => {
  const newState = _.cloneDeep(state);
  switch(action.type) {
    case LOCATION_CHANGE:
      const { zip, locale, address } = action.location;
      return _.assign({}, newState, { zip, locale, address });
    case LOCATION_MODAL_TOGGLE:
      const locationModalOpen = action.locationModalOpen;
      return _.assign({}, newState, { locationModalOpen });
    case LOCATION_MODAL_FORCE_RELOAD:
      const { locationChangeShouldForceReload, forceReloadRedirectPath, filtersToReset = [] } = action.forceReload;
      return _.assign({}, newState, { locationChangeShouldForceReload, forceReloadRedirectPath, filtersToReset });
    default:
      return state;
  }
};

export const changeLocation = (location) => ({type: LOCATION_CHANGE, location});
export const openLocationModal = () => ({ type: LOCATION_MODAL_TOGGLE, locationModalOpen: true })
export const closeLocationModal = () => ({ type: LOCATION_MODAL_TOGGLE, locationModalOpen: false })
export const setLocationModalForceReload = (forceReload) => ({ type: LOCATION_MODAL_FORCE_RELOAD, forceReload })

export const connectLocation = connect(
  (state) => ({ ...state.location, t: makeT(state.location.locale.code), tNumber: localeNumberFormat(state.location.locale.code) }),
  { changeLocation, openLocationModal, closeLocationModal, setLocationModalForceReload },
  null,
  { forwardRef: true }
);

import { define, ui } from '@owenscorning/pcb.alpha';
import _ from 'lodash';
import Data from '../../Data';
import { useEffect, useState } from 'react';

export default define`Data Parameters`('0.0.1')({
  view: <div></div>,
  edit: ({ contents, dataset, unwrapped, ...props }) => {
    const [formState, setFormState] = useState();
    useEffect(() => {
      const parameterMetadata = _.mapValues(Data.for(contents), 'meta')[dataset]
      if (typeof(parameterMetadata) === 'function') {
        Promise.resolve(parameterMetadata()).then(result => {
          setFormState({ dataset, result });
        })
      } else {
        setFormState({ dataset, result: parameterMetadata });
      }
    }, [contents, dataset])
    const definition = formState && formState.dataset === dataset && formState.result && ui`Form`.of(formState.result);
    // namespace the parameters by dataset, so when switching fresh set of parameters used
    return <UI.Form key={ dataset } { ...props }>{
      unwrapped ?
        definition :
        {
          [dataset]: definition
        }
    }</UI.Form>
  }
});

import { ui, select, s, when } from '@owenscorning/pcb.alpha';
import Product from './Product';
import { wrapRef } from '../../../../../data';
import { stringsFor } from '../../../../location/locales';
import _ from 'lodash';

const Roofing = Product(
  'Product::Shingle',
  ui`AttributeSet`({
    query: {
      name: 'Shingle PDP Attributes',
      path: '/roofing',
    },
  }),
  [],
  null,
  {
    version: '0.1',
    displayName: 'Shingle PDP',
    paths: ['roofing/shingles'],
  },
  [],
  'roofing'
)

export default Roofing;

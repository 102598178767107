import PropTypes from 'prop-types';

import Card from '../../../OC/oc-card-new';
import { useCloudinaryPictureOverlay } from '../../../OC/oc-picture-overlay';
import { imageOverlayConfig } from '../utils/imageOverlayConfig';

const pairingPairing = ({ pairing = {} }) => {
  const { baseLayer, overlays } = imageOverlayConfig({
    shingleColor: pairing,
    heroImage: {
      desktop: { file: pairing.img_path },
    },
  });
  baseLayer.desktop.scaleSettings = { width: 1146, height: 612 };
  overlays.desktop[0].position = { x: -15, y: 100 };
  overlays.desktop[1] = {
    ...overlays.desktop[1],
    position: { x: 345, y: 45 },
    fontSize: pairing.name?.length > 15 ? 26 : 32,
  };
  const { imageUrl } = useCloudinaryPictureOverlay({
    baseLayer: baseLayer.desktop,
    overlays: overlays.desktop,
  });

  return (
    <Card
      img={imageUrl}
      alt={pairing.img_alt}
      url={pairing.url}
      analytics={{
        track: 'learn-more',
        destination: pairing.img_alt,
      }}
      transform="none"
    >
      <h3 className="h4 body-font">{pairing.caption}</h3>
    </Card>
  );
};

pairingPairing.propTypes = {
  pairing: PropTypes.shape({
    img_path: PropTypes.string,
    img_alt: PropTypes.string,
    url: PropTypes.string,
    caption: PropTypes.string,
    name: PropTypes.string,
    style_board_swatch_desktop: PropTypes.string,
  }).isRequired,
};

export default pairingPairing;

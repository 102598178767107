import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { connectAnalytics } from './utilities/analytics';
import _ from 'lodash';
import { useSyncedRef } from '../../hooks';
import Theme from '../../themes/index';
import { forwardRef } from 'react';

/*
This component should be thought of solely as a generic "link."
You probably shouldn't be writing any styles in this component.
*/

const RawLink = ({ href, onClick, children, forwardRef, ...props }) => {
  return (
    <a
      href={ onClick ? null : href }
      onClick={ onClick ? (e) => onClick?.(e) : null }
      ref={forwardRef}
      { ...props }
    >{ children }</a>
  )
};

const connectPageBuilderMetadata = (Component) => (
  class extends React.Component {
    render() {
      let { analytics, href, formUid, ...props } = this.props;
      if (typeof PB_LINK_META !== 'undefined' && href) analytics = _.merge({}, PB_LINK_META[href]?.analytics, analytics ? analytics : {});
      if (typeof PB_LINK_META !== 'undefined' && formUid) analytics = _.merge({}, PB_LINK_META[formUid]?.analytics, analytics ? analytics : {});
      return <Component analytics={ analytics } href={ href } { ...props } />;
    }
  }
)

const Link = connectPageBuilderMetadata(connectAnalytics(RawLink));

export default Link;

import { Component } from "react";
import fixTrackVars from "../../helpers/data_track_prop_map";
import getFileSize from "../../helpers/get_file_size";
import getFileExtension from "../../helpers/get_file_extension";
import getFileBasename from "../../helpers/get_file_basename";
import getParameterByName from "../../helpers/get_url_param";
import getFormatFromMime from "../../helpers/get_format_from_mime";

function getFormat(ext) {
  switch (ext.toLowerCase()) {
    case 'pdf':
    case 'doc':
    case 'docx':
    case 'xls':
      return ext;
  }

  return null;
}

export default class DocumentLink extends Component {
  render() {
    let { type, type_name='', color, format, image, url, track, OnClick, text, size, file_type, extraClasses, description, document, card=false, keywords } = this.props;
    let href = url;

    if (document) {
      // initialize all from standard "document object format" (unless set by caller to override doc props)
      image = image || ((type === 'featured' || type === 'marquee') && document.thumb ? document.thumb.url : null);
      // multi-docs?
      url = url || document.url;
      href = url;
      if (url) {
        const extensionWithoutDot = getFileExtension(url).toLowerCase();
        const extensionWithDot = extensionWithoutDot ? `.${extensionWithoutDot}` : '';
        format = format || (document.source === 'external' ? 'external' : getFormat(extensionWithoutDot));
        format = format || getFormatFromMime(getParameterByName('mime_type', url));
        // merge the track object, so it can be at least partially supplied
        track = Object.assign({}, {
          track: 'file-download',
          'file-type': (document.source === 'external' ? 'external' : extensionWithDot),
          'file-name': document.value || getFileBasename(url),
          'doc-type': document.category
        }, document.track, track);

        //NOTE: This should be provided directly by MDMS
        if(type_name === "Code Compliance") {
          track['doc-type'] = 'Report';
        }

        size = size || (document.source === 'external' ? null : getFileSize(document.size_bytes));
        file_type = file_type || (document.source === 'external' ? '' : extensionWithoutDot);
      }

      if (document.publication_id) {
        href = `/dms/${document.publication_id}`;
      }
    }

    const { [type]: docLinkType = 'doc-link--default' } = {
      hero: 'doc-link--hero',
      featured: 'doc-link--featured',
      marquee: 'doc-link--featured doc-link--image',
      list: 'doc-link--list-item'
    };

    const { [format]: docLinkFormat } = {
      pdf: 'doc-link--pdf',
      docx: 'doc-link--docx',
      doc: 'doc-link--docx',
      xls: 'doc-link--xls',
      download: 'doc-link--download',
      external: 'doc-link--external'
    };

    const { [color]: docLinkColor = '' } = {
      white: 'doc-link--white',
      primary: 'primary',
      black: 'doc-link--black',
      blackBg: 'doc-link--bg-black',
      blackBorder: 'doc-link--black-border'
    };

    return(
      <a onClick={OnClick} title={text} href={href} className={`doc-link ${docLinkType} ${docLinkFormat} ${docLinkColor} ${extraClasses}`} data-keywords={keywords} {...fixTrackVars(track)} target="_blank">
        {image && <img src={image} />}
        <span className="name">{text}</span>
        {card ? <div><span className="type">{file_type}</span> <span className="doc-size">{size}</span></div>: size && (<span className="doc-size">{file_type && `${file_type.toUpperCase()} | `}{size}</span>)}
        {description && <p>{description}</p>}
      </a>
    );
  }
}

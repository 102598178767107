import { jsx, css } from '@emotion/react';
import { define, ui } from '@owenscorning/pcb.alpha';
import { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import cms_api from '../../../../../cms_api';

const remapOptionByValue = (remappedByValue, option, parent, depth) => {
  remappedByValue[option.value] = { ...option, depth, parent };
  if (option.children) {
    option.children.forEach(c => remapOptionByValue(remappedByValue, c, remappedByValue[option.value], depth+1))
  }
}

// create a lookup of value to option, including a parent traversal
const remapOptionsByValue = (options) => {
  const remappedByValue = {};
  options?.forEach(c => remapOptionByValue(remappedByValue, c, null, 1));
  return remappedByValue;
}

const getInsulationPdpData = _.memoize(
  async () => {
    const response = await cms_api.get_single_contents({
      filter: {
        type: 'Cms::Content::Attribute',
        language_iso_code: Board.build.language,
        route: '/insulation',
        name: 'Insulation Applications',
      },
      fields: {
        '*': 'contents',
      },
    });
    return remapOptionsByValue(response?.items);
  }
);

const listUI = (list = []) => {
  if (_.isEmpty(list)) return [];
  const res = [];
  list.forEach(y => {
    res.push({ attributes: { bold: true }, insert: y });
    res.push({ attributes: { list: 'bullet' }, insert: '\n' });
  });
  return res;
};

const sortList = (list = []) => list.sort();

const flattenedAndJoined = (arr = []) => {
  const res = [];
  arr?.forEach(x => {
    x?.length > 1 ? res.push(x.join(' > ')) : res.push(x[0]);
  });
  return res;
};

const arrayOfHierarchiesFn = (mapping = {}, applications = []) => {
  const arrayOfHierarchies = applications.map(value => {
    let application = mapping[value];
    const tree = [];
    while (application) {
      tree.push(application.label);
      application = application.parent;
    }
    return tree.reverse();
  }).filter(h => h.length > 0);
  return arrayOfHierarchies;
};
export default define`Basic Content`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/BasicContent`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value }) => {
    const [lookup, setLookup] = useState();

    const applications = Board.settings.attributes?.application;

    useEffect(() => {
      getInsulationPdpData().then(lookup => setLookup(lookup));
    }, []);

    const getApplications = (lookup, applications) =>{
        return _.flowRight(
            listUI,
            sortList,
            flattenedAndJoined,
            _.partial(arrayOfHierarchiesFn, lookup)
          )(applications);
    }

    const unorderedList = useMemo(() => {
      const list =
        !_.isEmpty(lookup) && !_.isEmpty(applications)
          ? getApplications(lookup, applications)
          : [];
      return { ops: list };
    }, [lookup, JSON.stringify(applications)]);

    if (_.isEmpty(applications)) {
      return null;
    }

    if (!lookup) {
      return null;
    }

    return (
      <UI.Box margin={['0', '0']}>
        <UI.Text>{unorderedList}</UI.Text>
      </UI.Box>
    );
  },
});

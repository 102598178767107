import { first } from 'lodash';
import placeholder_url from '../../../../placeholder';
import pathToContent from './helpers/path_to_content';
import ArticleItemList from './helpers/ArticleItemList';
import { makeT } from "../../../location/locales";

const mapItemFromData = (content) => {
  if (!content) {
    return null;
  }
  const { metadata } = content;
  const { settings} = {} = metadata;
  const { images } = {} = settings;
  const t = makeT(Board.build.language);
  return (
    {
      metadata,
      image: images?.images[images.list_item_image] || { file: placeholder_url(370,185) },
      img: images?.images[images.list_item_image]?.file || placeholder_url(370,185),
      alt: images?.images[images.list_item_image]?.alt,
      prehead: settings?.general?.prehead,
      heading: settings?.general?.proper_name,
      text: settings?.general?.short_description,
      linkText: t('builder.data.read_more'),
      url: pathToContent(content),
      icon: 'newspaper-o'
    }
  );
};

export default
  ArticleItemList({
    search: {
      name: 'Application Solutions',
      type: 'Article::Applications',
      preview: result => ({
        preheading: result.category,
        heading: result.proper_name
      })
    },
    list: {
      availableIn: ['www.owenscorning.com'],
      name: 'Application Solution',
      attributeSet: {
        name: 'Applications Attributes',
        path: 'insulation'
      },
      dataset: 'insulation_applications_blog',
      itemListMap: mapItemFromData,
      cardSetMap: mapItemFromData,
      categoryFeaturesMap: mapItemFromData,
      enableSearch: true,
      filtersBasePath: {final: 'metadata.settings.attributes', initial: 'metadata.settings.details.taxonomies'},
    }
  })

import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { connectLocation } from '../../../location/LocationConnectors';

// Styled Components
const Bar = styled.div`
	font-family: 'Oswald';
	display: flex;
	align-items: center;
	width: ${props => props.width ? `${props.width}px` : '100%' };
	text-transform: uppercase;
	margin-bottom: ${props => props.display == 'prehead' ? '8px' : '16px' };
	background-color: ${props => props.display == 'prehead' ? 'transparent' : bgColor[props.name]};
	position: relative;
	padding: ${props => props.display == 'prehead' ? '0' : '6px 6px 6px 4px'};

	img {
		display: block;
		align-self: center;
		margin: 0 5px 0 0!important;
		width: ${props => props.display == 'prehead' ? '24px!important' : props.display == 'heading' ? '28px!important' : '37px!important' };
	}
`;

const TitleStyle = styled.span`
	margin: 0;
	padding: 0;
	color: ${props => props.display == 'prehead' ? 'black' : textColor[props.name]};
	font-size: ${props => props.display == 'prehead' ? '24px' : props.display == 'heading' ? props.lang == 'fr-CA' ? '16px' : '23px' : '38px' };
	line-height: ${props => props.display == 'prehead' ? '24px' : props.display == 'heading' ? '20px' : '36px' };
`;

const H2 = TitleStyle.withComponent('h2');

const displayNames = {
	'seal': {
		'english': 'Seal',
		'es-US': 'Sellar',
		'fr-CA': 'Sceller'
	},
	'defend': {
		'english': 'Defend',
		'es-US': 'Defender',
		'fr-CA': 'Protéger'
	},
	'breathe': {
		'english': 'Breathe',
		'es-US': 'Respirar',
		'fr-CA': 'Circulation d’air'
	},
	'comfort': {
		'english': 'Comfort',
		'es-US': 'Consuelo',
		'fr-CA': 'Confort'
	}
}

const bgColor = {
	'seal': '#D40F7D',
	'defend': '#6D6D6D',
	'breathe': '#BCBCBC',
	'comfort': '#f59fc4'
}

const textColor = {
	'seal': 'white',
	'defend': 'white',
	'breathe': 'black',
	'comfort': 'black'
}

const SealDefendBreathe = connectLocation(({ locale, display='heading', name, width }) => {

	if (!name) return;
	
	const lang = locale.code;
	const displayName = lang.includes('en-') ? displayNames[name]['english'] : displayNames[name][lang];

	return (
		<Bar display={display} name={name} width={width} lang={lang} >
			<img src={`https://imagecdn.owenscorning.com/ocimages/image/upload/v1663092207/roofing/tprs/icons/icon-${name}.svg`} /> 
			{ display == 'headingLg' ? <H2 display={display} name={name} >{displayName}.</H2> : <TitleStyle lang={lang} display={display} name={name} >{displayName}.</TitleStyle> }
		</Bar>
	);
});

export default SealDefendBreathe;
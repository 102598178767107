import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';

// Components
import {H3, H2} from "../../OC/oc-h";

// Styled Components
const ColorPaletteContainer = styled.div`
`;

const ColorsContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.mode == 'horizontal' ? 'column' : 'row'};
  gap: 8px;
  height: ${props => props.height ? props.height : 'auto'};
  margin-bottom: 8px;

  ${props => props.tabletHeight && css`
    @media(min-width: 768px) and (max-width: 1199px) {
      height: ${props.tabletHeight};
    }
  `};
`;

const PrimaryColorContainer = styled.div`
  background-color: ${props => props.colorPalette?.primary || 'white'};
  width: ${props => props.mode == 'horizontal' ? '100%' : '50%'};
  height: ${props => props.mode == 'horizontal' ? '60%' : '100%'};
`;

const SecondaryColorsContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.mode == 'horizontal' ? 'row' : 'column'};
  width: ${props => props.mode == 'horizontal' ? '100%' : '50%'};
  height: ${props => props.mode == 'horizontal' ? '40%' : '100%'};
  gap: 8px;
`;

const SecondaryColor = styled.div`
  flex-basis: 33%;
`;

const Prehead = styled.span`
  margin-bottom: 8px;
  display: inline-block;
  line-height: 14px;

  @media(min-width:  1200px) {
    line-height: 16px;
  }
`;

const ColorPalette = ({prehead, heading, mode, colorPalette, height, tabletHeight}) => {

  return (

    <ColorPaletteContainer>
      <ColorsContainer mode={mode} height={height} tabletHeight={tabletHeight}>
        <PrimaryColorContainer mode={mode} colorPalette={colorPalette} />

        <SecondaryColorsContainer mode={mode} >
          <SecondaryColor style={{backgroundColor: colorPalette?.secondary?.one?.hex}} />
          <SecondaryColor style={{backgroundColor: colorPalette?.secondary?.two?.hex}} />
          <SecondaryColor style={{backgroundColor: colorPalette?.secondary?.three?.hex}} />
        </SecondaryColorsContainer>
      </ColorsContainer>

      {prehead && <Prehead>{prehead}</Prehead>}

      <H3 font="body" tag="h3">{heading}</H3>
    </ColorPaletteContainer>
  )
};

export default ColorPalette;

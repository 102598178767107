import { jsx } from '@emotion/react';
import { connectLocation } from '../../location/LocationConnectors';
import Heading from '../PageBuilder/Heading';

const RainbarrierComparisonTable = connectLocation(({ t }) => {
  const tableHeadings = t('rainbarrier.table_head');
  const tableRows = t('rainbarrier.table_rows');

  return (
    <>
      <Heading mainHeading={ t('rainbarrier.product_attributes_heading') } />

      <div className="oc-table-responsive">
        <table className="oc-table table-striped">
          <thead>
            <tr>
              { tableHeadings.map((tableHead, i) => (
                <th className="small-heading" key={i} dangerouslySetInnerHTML={{ __html: tableHead.th }} />
              ))}
            </tr>
          </thead>
          <tbody>
            { tableRows.map((tableRow, i) => (
              <tr key={i} >
                {tableRow.cells.map((cell, i) => (
                  cell.th ?
                    (<th key={i} className="left-align">{cell.th}</th>)
                  :
                    (<td key={i} dangerouslySetInnerHTML={{ __html: cell.td }} />)
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
});

export default RainbarrierComparisonTable;

import { useState, useRef } from "react";
import { jsx, css } from "@emotion/react";
import styled from '@emotion/styled'
import { useAccessibleInteractivity } from "../../hooks"
import Icon from "./oc-icon";

const Container = styled.div`
  width: 100%;
`

const Header = styled.div`
  user-select: none;
  position: relative;
  background-color: #F8F8F8;
  border-bottom: 1px solid #BCBCBC;
  padding: 10px 35px 10px 16px;
  transition: background-color 0.5s, padding-left 0.4s;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: #F2F2F2;
    padding-left: 20px;
  }

  .fa {
    font-size: 18px;
    position: absolute;
    right: 13px;
    top: 11px;
  }
`

const Content = styled.div`
  display: ${props => props.isOpen ? 'block' : 'none'};
  padding: 16px;
  background-color: white;
`

const Dropdown = ({open: defaultOpen, label, dataTrackAccordion, children, id}) => {
  const [isOpen, toggleOpen] = useState(!!defaultOpen)
  const ref = useRef()
  const onDropdownToggle = () => {
    toggleOpen(!isOpen)
  }

  useAccessibleInteractivity(ref, onDropdownToggle)

  return (
    <Container
      role="region"
    >
      <Header
        ref={ref}
        data-track={isOpen ? "conceal" : "reveal"}
        data-track-accordion={dataTrackAccordion || label}
        aria-expanded={isOpen}
        aria-label={label}
        aria-controls={id}
        role="button"
      >
        <span>{ label }</span>
        <Icon type={ isOpen ? 'minus' : 'plus' } />
      </Header>

      <Content
        id={id}
        isOpen={isOpen}
        aria-hidden={!isOpen}
      >
        { children }
      </Content>
    </Container>
  );
};

export default Dropdown;

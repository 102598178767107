import { define, ui, s, when } from '@owenscorning/pcb.alpha';

import Renderer from './../../../../OC/PageBuilder/Performance';

const defaultData = {
  Performance: [
    {
      statsHeaderRow: {
        header: 'OAKRIDGE® SHINGLES',
        subHeader: 'vs. top two competitors'
      },
      performanceStat: [
        {
          prehead: 'Up to',
          value: 2,
          posthead: 'Better',
          subject: 'NAIL PULL-THROUGH',
          scope: 'Resistance',
          hasModal: {
            options: "Yes"
          }
        },
        {
          prehead: 'Up to',
          value: 7,
          posthead: 'Better',
          subject: 'NAIL BLOW-THROUGH',
          scope: 'Resistance'
        },
        {
          prehead: 'Up to',
          value: 1.5,
          posthead: 'Better',
          subject: 'DELAMINATION',
          scope: 'Resistance'
        }
      ],
      explore: {
        buttonLabel: 'Explore OAKRIDGE',
        link: 'https://www.owenscorning.com'
      },
      pdf: {
        buttonLabel: 'Performance Brochure',
        link: 'https://owenscorning.com/dms/10023921'
      }
    },
    {
      statsHeaderRow: {
          header: 'DURATION® SERIES SHINGLES',
          subHeader: 'vs. top two competitors'
      },
      performanceStat: [
        {
          prehead: 'Up to',
          value: 2,
          posthead: 'Better',
          subject: 'NAIL PULL-THROUGH',
          scope: 'Resistance',
        },
        {
          prehead: 'Up to',
          value: 9,
          posthead: 'Better',
          subject: 'NAIL BLOW-THROUGH',
          scope: 'Resistance'
        },
        {
          prehead: 'Up to',
          value: 2.5,
          posthead: 'Better',
          subject: 'DELAMINATION',
          scope: 'Resistance'
        }
      ],
      explore: {
        buttonLabel: 'Explore Duration®',
        link: 'https://www.owenscorning.com'
      },
      pdf: {
        buttonLabel: 'Performance Brochure',
        link: 'https://owenscorning.com/dms/10023921'
      }
    }
  ]
};

export default define`Performance Module`( '0.0.1' )({
  thumbnail: ui`Modules/Page/Thumbnails/PerformancePole`,
  category: ui`Modules/Categories/Page/Prebuilt`,
  view: ({ value }) => <Renderer {...value} />,
  edit: {
    mainHeading: ui`Text`({
      label: 'Main Heading',
      default: 'THE PROOF IS IN THE PERFORMANCE'
    }),
    columnStats: [ui`List`.of({
      statsHeaderRow: ui`Form`.of({
        header: ui`Text`({
          label: 'Stats Header',
          default: 'OAKRIDGE® SHINGLES'
        }),
        subHeader: ui`Text`({
          label: 'Stats Sub-Header',
          default: 'vs. top two competitors'
        }),
      }),
      performanceStat: [
        ui`List`.of({
          prehead: ui`Text`({
            label: 'Pre-header',
            default: 'Up to'
          }),
          value: ui`Number`({
            min: 0.1,
            max: 10,
            step: 0.1,
            label: 'Performance value',
            default: 2.5
          }),
          posthead: ui`Text`({
            label: 'Post-header',
            default: 'Better'
          }),
          subject: ui`Text`({
            label: 'Subject',
            default: 'NAIL PULL-THROUgh'
          }),
          scope: ui`Text`({
            label: 'Scope',
            default: 'Resistance'
          }),
          hasModal: {
            options: ui`Choices`.of({
              Yes: 'Yes',
              No: 'No',
            })({
              label: 'Add Modal for this row?',
              default: 'Yes',
            }),
            [s._]: ui`Tip`.of( 'Option to add popup modal for this stat/row' )
          },
          modal: ui`Form`.of({
            heading: ui`Text/Rich/Header`({
              label: 'Modal Heading',
              default: 'Heading'
            }), [s._]: ui`Tip`.of( 'Add title/heading on pop up modal for this stat' ),
            body: ui`Text/Rich`({
              label: 'Modal Content',
              default: 'Tests the ability of the shingles to resist layer separation when heated to 180°F. Shingle delamination can negatively impact roof performance and aesthetics.'
            }), [s._]: ui`Tip`.of( 'Add content on pop up modal for this stat' ),
            image: ui`Image`({ label: 'Modal Image' }), [s._]: ui`Tip`.of( `Add Image 168x168 on pop up modal for this stat. You must upload an image to replace the default one` ),
          })({
            label: 'Modal',
            visible: when`../hasModal/options`.is.equal.to( 'Yes' )
          }),
        })({
          min: 1,
          label: 'Stats for column',
          singular: 'Stats - Row',
          [s._]: ui`Tip`.of( 'Each "Half column" contain rows of stats' ),
        })
      ],
      explore: ui`Form`.of({
        buttonLabel: ui`Text`({
          label: 'Button Label',
          default: 'Explore Oakridge®'
        }), [s._]: ui`Tip`.of( 'Add Label to Explore button' ),
        link: ui`Text`({
          label: 'Link to Learn More',
          default: 'https://www.owenscorning.com'
        }), [s._]: ui`Tip`.of( 'Add url/link for this column to learn more' ),
      }),
      pdf: ui`Form`.of({
        buttonLabel: ui`Text`({
          label: 'Button Label',
          default: 'Performance Brochure'
        }), [s._]: ui`Tip`.of( 'Add Label to pdf download button' ),
        link: ui`Text`({
          label: 'PDF Link',
          default: 'https://owenscorning.com/dms/10023921'
        }), [s._]: ui`Tip`.of( 'Add url/link to pdf for user to dowload' ),
      })
    })({
      singular: 'Column',
      label: 'Column Stat',
      default: defaultData.Performance
    })]
  },
});

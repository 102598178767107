import PropTypes from 'prop-types';

import { ActionLink } from '../result-block-styled-components';

const Email = ({ email, label, onClick, track }) => (
  <ActionLink
    onClick={onClick}
    href={onClick ? undefined : `mailto:${email}`}
    target="_blank"
    rel="noopener"
    tabIndex={0}
    data-track={track.name}
    data-track-buy-type={track.buyType}
    data-track-contractor={track.contractorName}
    data-track-contractor-id={track.contractorId}
    data-track-form-name={track.formName}
    data-track-professional={track.professional}
  >
    {label}
  </ActionLink>
);

Email.propTypes = {
  label: PropTypes.string.isRequired,
  email: PropTypes.string,
  onClick: PropTypes.func,
  track: PropTypes.shape({
    name: PropTypes.string,
    contractorName: PropTypes.string,
    contractorId: PropTypes.string,
    formName: PropTypes.string,
    professional: PropTypes.string,
    buyType: PropTypes.string,
  }),
};

Email.defaultProps = {
  email: undefined,
  onClick: undefined,
  track: {},
};

export default Email;

import NumericInput from 'react-numeric-input';
import { jsx, css } from '@emotion/react';
import units from '../units';
import styled from '@emotion/styled';

const Unit = styled.span`
  margin-left: 5px;
`;

export default ({onChange, validated=true, unit, ...props}) => <>
  <NumericInput
    onChange={ (valueAsNumber, valueAsString, input) => onChange(valueAsNumber) }
    css={css`
      padding: 14px 8px;
      width: 100%;
      color: #585858;
      border: 1px solid #585858;
      font-size: 16px;
      ${ !validated && css` border-color: #940420; color: #940420; ` }
    `}
    { ...props }
  />
  { unit && <Unit>{ units[unit]?.symbol }</Unit> }
</>;

import { combineReducers, createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import migrate_content from './migrate_content';
import { composeWithDevTools } from 'redux-devtools-extension';
import URLSearchParamReducer from '../../../../redux/reducers/URLSearchParamReducer';
import URLSearchParamSubscription from '../../../../redux/subscriptions/URLSearchParamSubscription';

export default function cmsStore(props, railsContext) {
  // Create a hydrated redux store, using props and the railsContext (object with
  // Rails contextual information).
  const initialState = props;

  initialState.cms.content = migrate_content(initialState.cms.content);

  const reducer = combineReducers({
    location: (state = initialState.location) => state,
    cms: (state = initialState.cms) => state,
    urlParams: URLSearchParamReducer,
  });

  const store = createStore(
    reducer,
    initialState,
    composeWithDevTools({name: `cmsStore(view) - ${initialState.cms.outerFrame ? 'outer' : 'inner'} - ${initialState.cms.content_id}/${initialState.cms.language}`})(
      applyMiddleware(thunk)
    )
  );

  URLSearchParamSubscription(store)

  return store;
}

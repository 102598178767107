import { useState, createElement, Fragment } from 'react';
import {jsx} from "@emotion/react";
import { connect } from 'react-redux';
import _ from "lodash";
import CTAButton from '../CTAButton';
import {BYR_SET_ROOF_ID} from "../Actions";
import Modal from "../Modal";
import {IFramedForm} from "../IFramedForm";
import {connectLocation} from "../location/LocationConnectors";
import {DataTrack} from "../Utilities";
import getReduxInitial from "../helpers/redux_initial";
import { SchemaForm } from '../forms/InnerSchemaForm';

// BYR Data
const byrDefaults = {};
const byrInitial = _.assign({}, byrDefaults, getReduxInitial('byrConfig', {}));
export const byrConfigReducer = (state = byrInitial, action) => {
  const newState = _.cloneDeep(state);
  switch(action.type) {
    case BYR_SET_ROOF_ID:
      newState['roofId'] = action.roofId;
      return newState;
    default:
      return state;
  }
};

export const VariableButton = (props) => {
  const { t, byrConfig } = props;
  if(_.get(byrConfig, 'slug')) {

    let formUrl = "www.owenscorning.com/en-us/roofing/contractors/fac-email-me?meta_contractor=" +
      byrConfig.membership_number + "&meta_byr_slug=" + byrConfig.slug + "&meta_byr_id=" + byrConfig.roofId;
    const [opened, setOpened] = useState(false);

    return (
      <Fragment>
        <DataTrack track="form-start" form-name="email-contractor" professional="contractor">
          <CTAButton onClick={() => setOpened(true)}>Contact</CTAButton>
        </DataTrack>
        <Modal open={opened} onClose={() => setOpened(false)} size="sm">
          <h4>{t('build_your_roof.contact')}</h4> <h5>{byrConfig.companyName}</h5>
          <SchemaForm uid={formUrl}>
            <CTAButton className="oc-cta-button" element="button" text={'submit'} type="submit" />
          </SchemaForm>
        </Modal>
      </Fragment>
    );
  }
  else
    return createElement(CTAButton, props);
};
export const VariableCTAButton = connectLocation(connect((state) => ({byrConfig: state.byrConfig}))(VariableButton));

export const connectComponentOverrides = (component, path) => {
  const connector = connect(
    state => {
      return {
        overrides: _.get(state, path, {})
      }
    });
  return connector(component);
};

export const connectOrder = connect(({byrConfig}) => ({stepOrder: byrConfig.stepOrder}));
export const setRoofId = (roofId) => ({type: BYR_SET_ROOF_ID, roofId});
export const ConfigurationConnector = connect(({byrConfig}) => ({configuration: byrConfig}), { setRoofId });

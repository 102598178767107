import _ from 'lodash';
import LinkList from './link-list';
import { jsx, css } from "@emotion/react";

const mobileStyleWithTitle = css`
  border-bottom: none !important;
  border-top: 1px solid #EEEEEE !important;
`;

const mobileStyleWithoutTitle = css`
  border-bottom: none !important;
`;

const desktopStyleWithTitle = css`
  border: none !important;
`;

const Column = ({column, mobile}) => {
  const safeChildren = (column.children || []);
  const imageColumn = false;

  if (mobile) {
    return (
      <div className={`${imageColumn ? 'image-': ''}column-display`}
           css={ column.details?.title ? mobileStyleWithTitle : mobileStyleWithoutTitle }
           data-track-sub-level={ column.details?.title }>
        <div className="column-title">
          <h4>
            {column.details?.title}
            {column.details?.is_link &&
            <a href={column.details.link?.url} className="chevron-link">{column.details.link?.title}</a>}
          </h4>
        </div>

        <LinkList items={ column.children } mobile={ mobile } />
      </div>
    )
  } else {
    return (
      <div className={`${imageColumn ? 'image-': ''}column-display`}
           data-track-sub-level={ column.details?.title }>

        <div className="column-title" css={ column.details?.title ? css`` : desktopStyleWithTitle }>
          <h4>
            { column.details?.title }
          </h4>

          {
            column.details?.is_link &&
            <a href={ column.details.link?.url } className="chevron-link">
              { column.details.link?.title }
              <span className="sr-only">{ column.details.link?.title }</span>
            </a>
          }
        </div>

        <LinkList items={ column.children } mobile={ mobile } />
      </div>
    );
  }
};

export default ({columns, mobile}) => (
  <>
    {
      columns.map((item,i) => <Column key={ i } column={ item } mobile={ mobile }/>)
    }
  </>
);

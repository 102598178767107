import MediaObject from './oc-media-object'
import Link from './oc-link'
import FileBadge from './oc-file-badge'
import getExtension from './utilities/file-extension';
import getFileBasename from '../helpers/get_file_basename';
const PLACEHOLDER_IMAGE = 'https://imagecdn.owenscorning.com/ocimages/image/upload/v1608574907/insulation/pdp/placeholder-doc.png'

const FeaturedDocument = ({ title, description, url, category, extension, sizeBytes, thumbUrl, pubId, hideBadge=false }) => {
  const dataTrack = {
    href: url,
    'target': '_blank',
    'data-track': "file-download",
    'data-track-file-type': extension || getExtension(url || ''),
    'data-track-file-name': title || getFileBasename(url || ''),
    'data-track-doc-type': category,
    'data-track-element-location': "main section text link",
    'data-track-pubid': pubId
  }

  return (
    <MediaObject img={thumbUrl || PLACEHOLDER_IMAGE} alt={title} analytics={{...dataTrack, 'data-track-element-location': "main section graphic"}}>
      { title &&
        <h6>
          <Link { ...dataTrack }>
            { title }
          </Link>
        </h6>
      }
      <p>{description}</p>
      { !hideBadge &&
        <Link { ...dataTrack }>
          <FileBadge extension={ extension || getExtension(url) } sizeBytes={ sizeBytes }/>
        </Link>
      }
    </MediaObject>
  )
}

export default FeaturedDocument

import Video from "./oc-video";
import Image from "./oc-image";
import Picture from "./oc-picture";
import CloudinaryVideo from "./oc-cloudinary-video";
import Link from "./oc-link";

const isCloudinaryVideo = (video) => {
  return (video.indexOf('cloudinary') !== -1) || (video.indexOf('imagecdn.owenscorning.com') !== -1);
};

const Media = ({ img, imageUrl, transform, variants, alt, video, className }) => {
  if (!video) {
    if (transform || variants) {
      return <Link href={ imageUrl } target="_blank"><Picture source={ img } alt={ alt } transform={ transform } variants={ variants } className={ className } /></Link>;
    } else {
      return <Link href={ imageUrl } target="_blank"><Image src={ img } alt={ alt } className={ className }/></Link>;
    }
  }

  if (isCloudinaryVideo(video)) {
    return <CloudinaryVideo src={ video } className={ className } />;
  } else {
    return <Video src={ video } className={ className } />;
  }
};

export default Media;

// see https://github.com/datasets/unece-units-of-measure/blob/master/data/units-of-measure.csv
// would like to have this in a usable dataset/ws here

export default {
  'ANN': {
    name: 'year',
    symbol: 'y',
  },
  'INH': {
    name: 'inch',
    symbol: 'in',
  },
  'FTK': {
    name: 'square feet',
    symbol: 'ft²',
  },
  'LBR': {
    name: 'pound',
    symbol: 'lb',
  },
  'KMH': {
    name: 'kilometre per hour',
    symbol: 'km/h',
  },
  'HM': {
    name: 'mile per hour',
    symbol: 'mile/h',
  },
  'MMT': {
    name: 'millimetre',
    symbol: 'mm',
  },
  'KMQ': {
    name: 'kilogram per cubic metre',
    symbol: 'kg/m³',
  }
}

import Theme from '../../../themes/index';
import PropTypes from 'prop-types';

// Components
import BaseInput from './oc-input-base';

const NumberInput = ({
  defaultValue,
  disabled = false,
  error,
  helperText,
  helperTextColor = null,
  infoFunction,
  label,
  max,
  min,
  name,
  themeColor = Theme.colors.brand,
  onBlur,
  onChange,
  placeholder,
  required = false,
  step,
  touched = false,
  valid = true,
  value,
}) => {
  const changeHook = (event) => {
    let eventValue = event.target.value;

    if (eventValue) {
      if (step >= 1) {
        event.target.value = parseInt(eventValue);
      }

      if (min && (eventValue < min)) {
        event.target.value = min;
      }

      if (max && (eventValue > max)) {
        event.target.value = max;
      }
    }

    onChange && onChange(event);
  }

  return (
    <BaseInput
      defaultValue={defaultValue}
      disabled={disabled}
      error={error}
      helperText={helperText}
      helperTextColor={helperTextColor}
      id={name}
      infoFunction={infoFunction}
      inputType="number"
      label={label}
      max={max}
      min={min}
      name={name}
      themeColor={themeColor}
      onBlur={onBlur}
      onChange={(event) => changeHook(event)}
      placeholder={placeholder}
      required={required}
      step={step}
      touched={touched}
      valid={valid}
      value={value}
    />
  );
};

export default NumberInput;

NumberInput.propTypes = {
  /* HTML: Sets the disabled attribute on the input element */
  disabled: PropTypes.bool,
  /* HTML: Error message that is displayed under the input element */
  error: PropTypes.string,
  /* HTML: Label helper text, ex: 'Required' */
  helperText: PropTypes.string,
  /* HTML: The label text above the input element */
  label: PropTypes.string,
  /* HTML: Sets the name attribute on the input element */
  name: PropTypes.string,
  /* CSS: Hex value for color theme */
  themeColor: PropTypes.string,
  /* HTML: Sets the placeholder text on the input element */
  placeholder: PropTypes.string,
  /* JSX: Controls the validated state of the label, input, and error message, etc */
  valid: PropTypes.bool,
  /* HTML: Sets the value of the number field */
  value: PropTypes.string
}

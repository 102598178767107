import { ui } from '@owenscorning/pcb.alpha';
import Article from './Article';

const Lumber = Article(
  'Article::Lumber',
  'lumber_blog',
  [
    'SocialMediaShareBanner',
    'RelatedArticles',
    'WeardeckExperts',
    'LumberContact'
  ],
  ui`AttributeSet`({
    query: {
      name: 'Blog - Lumber',
      path: '/composites/lumber'
    }
  }),
  {
    version: '0.1',
    displayName: 'Lumber Blog',
    paths: ['composites/lumber/blog'],
  }
)

export default Lumber;

import { define } from '@owenscorning/pcb.alpha';
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';

const Separator = styled.div`
  margin: ${ ({ small }) => small ? 24 : 56 }px 0;
  height: ${ ({ small }) => small ? 1 : 2 }px;
  width: 100%;
  background-color: black;
`;

export default define`Separator`({
  raw: () => {
    const Component = UI.Theme.Taggable((props) => <Separator { ...props } />);
    Component.Small = (props) => <Separator small { ...props } />;
    return Component;
  }
});

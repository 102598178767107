import cms_api from '../../../cms_api';

export default async (ref) => {
  const { type, path, name, id, language, status, date } = ref;
  if (!id && (!type || !name)) {
    throw "(type, [path], name) or (id) must be specified"
  }
  const statusText = (status || 'published').toLowerCase();
  if (statusText !== 'published') {
    throw "status must be empty or 'published' for content reference"
  }
  if (date) {
    throw "date must be empty for content reference"
  }

  const query = id ?
    {
      filter: {
        content_uuid: id,
        ...(type ? { type: `Cms::Content::${type}` } : {}),
      },
      fields: {
        '*': 'content_uuid,name,contents,metadata,language_iso_code,route'
      },
    } :
    {
      filter: {
        type: `Cms::Content::${type}`,
        route: path ? '/' + path : '/',
        name,
      },
      fields: {
        '*': 'content_uuid,contents,metadata,language_iso_code,route'
      },
    };
  if (language) {
    query.filter.language_iso_code = language;
  }
  const response = language ? await cms_api.get_single(query) : await cms_api.get_single_for_language(query, Board.build.language)
  return response;
};

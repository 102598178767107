import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import Media from "./oc-media";
import RichText from './PageBuilder/RichText';
import placeholder_url from '../../placeholder';
import { Mobile, Tablet, Desktop } from './PageBuilder/internals/Devices';

const Sizes = {
  'full': { [Mobile]: 375, [Tablet]: 768, [Desktop]: 1170 },
  'xl': { [Mobile]: 345, [Tablet]: 738, [Desktop]: 1170 },
  'l': { [Mobile]: 345, [Tablet]: 738, [Desktop]: 970 },
  'm': { [Mobile]: 225, [Tablet]: 482, [Desktop]: 770 },
};

const ContentAndImageContainer = styled.div`
  display: flex;
  flex-direction: ${ props => props.contentOrderMobile == 'first' ? 'column' : 'column-reverse' };
  margin: ${props => props.noMargin ? '0' : '0 0 20px'};
  padding: 0 0 0;
  width: ${({ width }) => `${ width.Mobile }px` };

  @media (min-width: 768px) {
    flex-direction: ${ props => props.contentOrder == 'last' && props.ratio !== 'noMedia' ? 'row-reverse' : 'row' };
    margin: ${props => props.noMargin ? '0' : '0 0 20px'};
    max-width: 768px;
    width: ${({ width }) => `${ width.Tablet }px` };
  }

  @media (min-width: 1200px), screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    margin: ${props => props.noMargin ? '0 ' : '0 0 70px'};
    max-width: 1170px;
    padding: 0 0 0;
    width: ${({ width }) => `${ width.Desktop }px` };
  }

  .content {
    @media (min-width: 768px) { margin-right: ${ props => props.contentOrder === 'first' && '30px' }; }
  }

  .img {
    display: flex;
    flex-direction: column;
    justify-content: ${ props => props.contentOrder === 'first' ? 'flex-end' : 'flex-start' };
    margin: ${ props => props.contentOrderMobile === 'last' && '0 0 20px' };

    @media (min-width: 768px) { margin-right: ${ props => props.contentOrder === 'last' && '30px' }; }

    img, video { max-width: 100%; }

    .caption {
      font-size: 12px;
      margin-top: 5px;
    }
  }

  .content {
    @media (max-width: 767px) { margin: ${ props => props.contentOrderMobile === 'first' && '0 0 20px' }; }
  }

  ${ props => props.ratio == '1-2' && css`
    .content {
      @media (min-width: 768px) { flex: 1; }
    }

    .img {
      width: 100%;
      @media (min-width: 768px) { width: 66%; }
    }
  `};

  ${ props => props.ratio == '1-1' && css`
    .content { flex-basis: 50%; }

    .img { flex-basis: 50%; }
  `};

  ${ props => props.ratio == '2-1' && css`
    .content {
      @media (min-width: 768px) { flex: 1; }
    }

    .img {
      width: 100%;
      @media (min-width: 768px) { width: 33%; }
    }
  `};
`;

const Caption = styled.div`
  margin-top: 8px;
  text-align: ${props => props.captionAlignment || 'left'};
`;

// Placeholder images that display pixel dimensions
const imgPlaceholder = {
  "1-2": placeholder_url(770,400),
  "1-1": placeholder_url(570,320),
  "2-1": placeholder_url(370,400)
}

/*
* contentOrder - [first (default), last]
* contentOrderMobile - [first, last (default)]
*/
const ContentAndImage = ({ img, imageUrl, video, alt, caption, captionAlignment='left', children, contentOrder = 'first', contentOrderMobile = 'last', containerSize, ratio = '1-2', noMargin, transform, ...props }) => {
  let image = img ? img : imgPlaceholder[ratio];
  const size = containerSize?.selection || 'xl';
  const Width = Sizes[size];

  return (
    <ContentAndImageContainer
      contentOrder={ contentOrder }
      contentOrderMobile={ contentOrderMobile }
      ratio={ ratio }
      width={Width}
      size={size}
      noMargin={noMargin}
    >
      <div className="content">
        { children }
      </div>
      { image && ratio !== 'noMedia' &&
        <div className="img">
          <Media alt={ alt } img={ image } video={ video } imageUrl={ imageUrl } transform={transform} />
          { caption &&
            <Caption captionAlignment={captionAlignment}><RichText content={caption} inline/></Caption>
          }
        </div>
      }
    </ContentAndImageContainer>
  )
}

export default ContentAndImage;

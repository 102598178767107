import api from '../../mdms_api';
import _ from 'lodash';
import qs from 'qs';

export default async (uuid) => {
  const query = {};
  if (typeof(window) !== 'undefined') {
    query.lang = document.documentElement.getAttribute('locale');
    if (typeof(window.PB_ZONE) !== 'undefined') {
      query.zone = window.PB_ZONE;
    }
  }
  const response = await api.get(`/api/v2/product/shingles/${uuid}${_.isEmpty(query) ? '' : `?${qs.stringify(query)}`}`)
  return await response.json();
};

import { jsx } from '@emotion/react';
import styled from '@emotion/styled';

const CheckboxLabel = styled.label`
  input[type="checkbox"]:checked {
    background-color: #D40F7D;
    border-color: #D40F7D;
  }

  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #949494;
    height: 20px;
    margin: 0 0.5em 0 0;
    width: 20px;
    flex: none;
  }

  input[type="checkbox"]:checked ~ .check {
    display: block;
  }

  input[type="checkbox"] ~ .check {
    border-bottom: 3px solid #FFFFFF;
    border-left: 3px solid #FFFFFF;
    display: none;
    height: 7px;
    left: 4px;
    position: absolute;
    top: 5px;
    transform: rotate(-45deg);
    width: 13px;
  }
`;

const Checkbox = ({ id, children, ...props }) => (
  <CheckboxLabel className="checkbox-label" htmlFor={ id }>
    <input
      id={ id }
      type="checkbox"
      { ...props }
    />
    { children }
    <span className="check" />
  </CheckboxLabel>
);

export default Checkbox;

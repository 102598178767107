import PropTypes from 'prop-types';
import React from 'react';

import Category from './Category';

export default function CategoryList({
  categories,
  selectId,
  removeId,
  isSelected,
  tabularOptions,
  updateSpecifications,
  specifications,
  allowSpecifications,
}) {
  return (
    <div>
      {categories.map(category => {
        const { name, description, features, options } = category;
        const categoryId = category.id;

        return (
          <Category
            key={name}
            categoryId={categoryId}
            name={name}
            description={description}
            features={features}
            options={options}
            selectId={selectId}
            removeId={removeId}
            isSelected={isSelected}
            tabularOptions={tabularOptions}
            updateSpecifications={updateSpecifications}
            specification={specifications[category.id]}
            allowSpecifications={allowSpecifications}
          />
        );
      })}
    </div>
  );
}

CategoryList.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectId: PropTypes.func.isRequired,
  removeId: PropTypes.func.isRequired,
  isSelected: PropTypes.func.isRequired,
  tabularOptions: PropTypes.bool,
  updateSpecifications: PropTypes.func.isRequired,
  specifications: PropTypes.array,
  allowSpecifications: PropTypes.bool,
};

CategoryList.defaultProps = {
  tabularOptions: false,
  specifications: [],
  allowSpecifications: false,
};

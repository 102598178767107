import { define, ui } from '@owenscorning/pcb.alpha';
import _ from 'lodash';
import React from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import useMdmsApi, { SUCCESS } from '../../../../../../hooks/use_mdms_api';

function makeFilterData(attr) {
  return {
    filterId: attr.name,
    title: _.startCase(attr.name),
    data_type: attr.data_type,
    options:
      attr?.options?.map(option => ({
        key: option,
        label: _.startCase(option),
      })) || [],
    templated: true,
  };
}

function isFilterableDataType(dataType) {
  return ['select', 'multi_select'].includes(dataType);
}

export default define`FiltersWrapper`('0.0.1')({
  view: () => <div> </div>,
  edit: ({ Board, locatorUid, value }) => {
    const url = `/api/v3/locations/${locatorUid}/metadata`;
    const { status, data } = useMdmsApi(url, locatorUid);
    const initialFilters = data?.extra_attributes?.map(makeFilterData) || [];

    useDeepCompareEffect(() => {
      // update the board only when groups change and status is success
      if (status === SUCCESS) {
        // remove filters which are not applicable to the locator
        const nextFilters = _.clone(value || []).filter(fil =>
          _.some(initialFilters, f => f.filterId === fil.filterId)
        );
        // push initial filters which are not present
        initialFilters.forEach(fil => {
          if (
            !_.some(nextFilters, f => f.filterId === fil.filterId) && // not present AND
            isFilterableDataType(fil.data_type) // fitlerable data type
          ) {
            nextFilters.push(fil);
          }
        });
        Board.Change(nextFilters);
      }
    }, [initialFilters]);

    return (
      <Subschema>
        {[
          ui`Modules/Page/Locator/Filters`({ status, filters: initialFilters }),
        ]}
      </Subschema>
    );
  },
});

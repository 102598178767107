import { useRef, useCallback } from 'react';
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import { throttle } from 'lodash';
import { useAccessibleInteractivity } from "../../../hooks";
import PropTypes from 'prop-types';

const FieldsetContainer = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: ${props => props.margin ? props.margin : '0 0 40px'};
  padding: 0;

  @media(min-width: 768px) {
    flex-direction: ${props => (props.horizontal && !props.columns) ? 'row' : 'column'};
    gap: ${props => props.columns ? '32px' : '0'};
    margin: ${props => (props.margin && !props.columns) ? props.margin : '0 0 32px'};
    ${props => props.columns && css`
      display: grid;
      grid-template-columns: 1fr 1fr;
    `};
  }

  >:not(legend) {
    margin: ${props => props.multipleFields ? '0 0 24px' : '0 0 8px'};

    @media(min-width: 768px) {
      margin: ${ props => (props.horizontal && !props.multipleFields) ? '0 32px 0 0' : props.multipleFields ? '0 0 32px' : '0 0 8px' };
      width: ${ props => props.horizontal ? 'auto' : '100%'};

      ${props => props.columns && css`
        margin: 0!important;
      `};
    }
  }
`;

const Legend = styled.legend`
  color: ${props => props.validated ? '#000000' : '#940420' };
  font-weight: 700;
  margin-bottom: 8px;
  width: 100%;
`;

const HelperText = styled.span`
  color: #585858;
  font-size: 12px;
  font-weight: normal;
  margin-left: ${props => props.helperTextBlock ? '0' : '8px'};
  display: ${props => props.helperTextBlock ? 'block' : 'inline'};
`;

const Error = styled.span`
  color: #940420;
  display: block;
  font-size: 12px;
  margin-top: 8px;
  width: 100%;
`;

const Fieldset = ({ legend, validated=true, helperText, helperTextBlock=false, error, children, columns=false, multipleFields=false, horizontal=false, margin, onChange}) => (
  <>
    <FieldsetContainer horizontal={horizontal} multipleFields={multipleFields} columns={columns} margin={margin} onChange={onChange}>
      {(legend || helperText) && <Legend horizontal={horizontal} validated={validated} >{legend}{helperText && <HelperText helperTextBlock={helperTextBlock}>{helperText}</HelperText>}</Legend>}
      {children}
      { (error && !validated) && <Error>{error}</Error> }
    </FieldsetContainer>
  </>
);

export default Fieldset;

Fieldset.propTypes = {
  /** The text above the text field that identifies the text field */
  legend: PropTypes.string,
  /** The text next to the legend used as helper text */
  helperText: PropTypes.string,
  /** Set to true if you would like the helper text to be on its own line*/
  helperTextBlock: PropTypes.bool,
  /** placeholder text */
  children: PropTypes.node,
  /** if the fields should run horizontal or not */
  horizontal: PropTypes.bool,
  /** If the field is validated */
  validated: PropTypes.bool,
  /** the error message that gets shown */
  error: PropTypes.string,
  /** Set to true if the fieldset contains multiple input fields. Set to false if it just contains 1 checkbox or radio button list. */
  multipleFields: PropTypes.bool,
  /** Set to true if you want a 2 column setup */
  columns: PropTypes.bool,
  /** Set the margin for specific instances */
  margin: PropTypes.string
}

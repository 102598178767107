// Note: this might has to be updated from time to time
export const animData = {
	"edit-shingles": {
		"in": ["icewatervalley","icewaterchimney","lumber","ridge","shinglesfront","shinglesback","underlayment","icewater","icewaterback","vent","ridge-vent","starter"],
		"out": ["box-vent","baselayer"],
		"camera": [-94, 63, 88],
		"ridge":100,
		"shinglesfront": 100,
		"shinglesback": 100,
		"starter": 0,
		"underlayment": 0,
		"icewater": 0,
		"icewaterback": 0,
		"deck": 0,
		"vent": 0,
		"lumber": 0,
		"ridge-vent": 100,
		"box-vent": 100,
		"icewaterchimney": 0,
		"icewatervalley": 0,
		"baselayer": 0
	},
	"edit-icewater": {
		"in":	["icewatervalley","icewaterchimney","lumber","icewater","icewaterback","vent","underlayment"],
		"out": ["ridge","shinglesfront","shinglesback","starter","ridge-vent","box-vent","baselayer"],
		"camera": [-116, 50, 82],
		"ridge":600,
		"shinglesfront": 600,
		"shinglesback": 600,
		"starter": 600,
		"underlayment": 140,
		"icewater": 100,
		"icewaterback": 100,
		"deck": 0,
		"vent": 0,
		"lumber": 0,
		"ridge-vent": 600,
		"box-vent": 600,
		"icewaterchimney": 185,
		"icewatervalley": 100,
		"baselayer": 0
	},
	"edit-icewater-b": {
		"in":	["icewatervalley","icewaterchimney","lumber","vent","underlayment"],
		"out": ["ridge","shinglesfront","shinglesback","starter","ridge-vent","box-vent","icewater","icewaterback","baselayer"],
		"camera": [-125, 14, 82],
		"ridge":600,
		"shinglesfront": 600,
		"shinglesback": 600,
		"starter": 600,
		"underlayment": 100,
		"icewater": 100,
		"icewaterback": 100,
		"deck": 0,
		"vent": 0,
		"lumber": 0,
		"ridge-vent": 600,
		"box-vent": 600,
		"icewaterchimney": 250,
		"icewatervalley": 1,
		"baselayer": 0
	},
	"edit-icewater-c": {
		"in":	["icewatervalley","icewaterchimney","lumber","vent","underlayment","baselayer"],
		"out": ["ridge","shinglesfront","shinglesback","starter","ridge-vent","box-vent","icewater","icewaterback"],
		"camera": [-134, 16, 67],
		"ridge":600,
		"shinglesfront": 600,
		"shinglesback": 600,
		"starter": 600,
		"underlayment": 140,
		"icewater": 100,
		"icewaterback": 100,
		"deck": 0,
		"vent": 0,
		"lumber": 0,
		"ridge-vent": 600,
		"box-vent": 600,
		"icewaterchimney": 250,
		"icewatervalley": 70,
		"baselayer": 40
	},
	"edit-icewater-d": {
		"in":	["lumber","vent","baselayer"],
		"out": ["underlayment","ridge","shinglesfront","shinglesback","starter","ridge-vent","box-vent","icewater","icewaterback","icewatervalley","icewaterchimney"],
		"camera": [-134, 35, 59],
		"ridge":600,
		"shinglesfront": 600,
		"shinglesback": 600,
		"starter": 600,
		"underlayment": 600,
		"icewater": 100,
		"icewaterback": 100,
		"deck": 0,
		"vent": 0,
		"lumber": 0,
		"ridge-vent": 600,
		"box-vent": 600,
		"icewaterchimney": 600,
		"icewatervalley": 600,
		"baselayer": 70
	},
	"edit-underlayment": {
		"in": ["icewatervalley","icewaterchimney","lumber","icewater","icewaterback","underlayment","vent"],
		"out": ["ridge","shinglesfront","shinglesback","starter","ridge-vent","box-vent","baselayer"],
		"camera": [-98, 75, 79],
		"ridge":600,
		"shinglesfront": 600,
		"shinglesback": 600,
		"starter": 600,
		"underlayment": 100,
		"icewater": 0,
		"icewaterback": 0,
		"deck": 0,
		"vent": 0,
		"lumber": 0,
		"ridge-vent": 600,
		"box-vent": 600,
		"icewaterchimney": 120,
		"icewatervalley": 0,
		"baselayer": 0
	},
	"edit-starter": {
		"in": ["icewatervalley","icewaterchimney","lumber","starter","icewater","icewaterback","underlayment","vent"],
		"out": ["ridge","shinglesfront","shinglesback","ridge-vent","box-vent","baselayer"],
		"camera": [-106, 14, 88],
		"ridge":600,
		"shinglesfront": 600,
		"shinglesback": 600,
		"starter": 100,
		"underlayment": 0,
		"icewater": 0,
		"icewaterback": 0,
		"deck": 0,
		"vent": 0,
		"lumber": 0,
		"ridge-vent": 600,
		"box-vent": 600,
		"icewaterchimney": 0,
		"icewatervalley": 0,
		"baselayer": 0
	},
	"edit-ridge": {
		"in": ["icewatervalley","icewaterchimney","lumber","ridge","shinglesfront","shinglesback","icewater","icewaterback","underlayment","vent","ridge-vent","starter"],
		"out": ["box-vent","baselayer"],
		"camera": [-96, 30, 95],
		"ridge":50,
		"shinglesfront": 0,
		"shinglesback": 0,
		"starter": 0,
		"underlayment": 0,
		"icewater": 0,
		"icewaterback": 0,
		"deck": 0,
		"vent": 0,
		"lumber": 0,
		"ridge-vent": 25,
		"box-vent": 0,
		"icewaterchimney": 0,
		"icewatervalley": 0,
		"baselayer": 0
	},
	"edit-vent": {
		"in": ["icewatervalley","icewaterchimney","lumber","ridge","shinglesfront","shinglesback","icewater","icewaterback","underlayment","vent","ridge-vent","starter"],
		"out": ["box-vent","baselayer"],
		"camera": [-136, 47, 42],
		"ridge":50,
		"shinglesfront": 0,
		"shinglesback": 0,
		"starter": 0,
		"underlayment": 0,
		"icewater": 0,
		"icewaterback": 0,
		"deck": 0,
		"vent": -100,
		"lumber": 0,
		"ridge-vent": 25,
		"box-vent": 0,
		"icewaterchimney": 0,
		"icewatervalley": 0,
		"baselayer": 0
	},
	"default": {
		"in": ["icewatervalley","icewaterchimney","lumber","ridge","shinglesfront","shinglesback","icewater","icewaterback","underlayment","vent","starter","ridge-vent"],
		"out": ["box-vent"],
		"camera": [-111, 49, 156],
		"ridge":300,
		"shinglesfront": 250,
		"shinglesback": 250,
		"starter": 180,
		"underlayment": 125,
		"icewater": 75,
		"icewaterback": 75,
		"deck": -75,
		"vent": -200,
		"lumber": -150,
		"ridge-vent": 275,
		"box-vent": 250,
		"icewaterchimney": 185,
		"icewatervalley": 75,
		"baselayer": 0
	}
}

import { Component, useState } from "react";
import Image from '../oc-image';
import { jsx } from "@emotion/react";

class DiscountGallery extends Component {
    render(){
      return(
        <table>
          <tbody>
            <tr>
              <td>
                <Image src = "https://imagecdn.owenscorning.com/ocimages/image/upload/v1624893266/roofing/contractor-network/accu-lynx.png" alt="AccuLynx" style = {{width:200, height:90}}/>
              </td>
              <td>
                <Image src= "https://imagecdn.owenscorning.com/ocimages/image/upload/v1624893262/roofing/contractor-network/apex-chat.png" alt="ApexChat" style = {{width:200, height:90}}/>
              </td>
              <td>
                <Image src = "https://imagecdn.owenscorning.com/ocimages/image/upload/v1624893255/roofing/contractor-network/art-unlimited.png" alt="ArtUnlimited" style = {{width:200, height:90}}/>
              </td>
              <td>
                <Image src = "https://imagecdn.owenscorning.com/ocimages/image/upload/v1624893289/roofing/contractor-network/company-cam.png" alt="CompanyCam" style = {{width:200, height:90}}/>
              </td>
              <td>
                <Image src = "https://imagecdn.owenscorning.com/ocimages/image/upload/v1624893297/roofing/contractor-network/eagleview.png" alt="EagleView" style = {{width:200, height:90}}/>
              </td>
              <td>
                <Image src = "https://imagecdn.owenscorning.com/ocimages/image/upload/v1624893300/roofing/contractor-network/g-four.png" alt="GFourMarketing" style = {{width:200, height:90}}/>
              </td>
          </tr>
          <tr>
              <td>
                <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/v1624893312/roofing/contractor-network/guild-quality.png" alt="GuildQuality" style={{width:200, height:90}}/>
              </td>
            <td>
              <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/v1624893306/roofing/contractor-network/home-advisor.png" alt="HomeAdvisor" style={{width:200, height:90}}/>
            </td>
            <td>
              <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/v1624893334/roofing/contractor-network/Hover.png" alt="Hover" style={{width:200, height:90}}/>
            </td>
            <td>
              <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/v1624893345/roofing/contractor-network/job-nimbus.png" alt="JobNimbus" style={{width:200, height:90}}/>
            </td>
            <td>
              <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/v1624893356/roofing/contractor-network/NCRA.png" alt="NCRA" style={{width:200, height:90}}/>
            </td>
            <td>
              <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/v1624893368/roofing/contractor-network/Paychex.png" alt="Paychex" style={{width:200, height:90}}/>
            </td>
          </tr>
          <tr>
            <td>
              <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/v1624893381/roofing/contractor-network/Podium.png" alt="Podium" style={{width:200, height:90}}/>
            </td>
            <td>
              <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/v1624893395/roofing/contractor-network/AI.jpg" alt="Predictive Sales AI" style={{width:200, height:90}}/>
            </td>
            <td>
              <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/v1624893410/roofing/contractor-network/sales-rabbit.png" alt="SalesRabbit" style={{width:200, height:90}}/>
            </td>
            <td>
              <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/v1624893325/roofing/contractor-network/sandler-training.png" alt="Sandler" style={{width:200, height:90}}/>
            </td>
            <td>
              <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/v1624893426/roofing/contractor-network/service-finanace.png" alt="ServiceFinanceCompany" style={{width:200, height:90}}/>
            </td>
            <td>
              <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/v1624893443/roofing/contractor-network/socius.png" alt="SociusMarketing" style={{width:200, height:90}}/>
            </td>
          </tr>
          <tr>
            <td>
              <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/v1624893461/roofing/contractor-network/XAP360.png" alt="XAP360" style={{width:200, height:90}}/>
            </td>
          </tr>
          </tbody>
        </table>

      );
  };
};

export default DiscountGallery;

import { appendAspectRatio, migrateArticleDataSetParameters, migrateDataSetParameters } from '../../../migrations';

export default {
  displayName: 'Media Object Set',
  allowableParents: ['Section'],
  migrator: (data) => {
    if (data === undefined || data === null) {
      return {}
    }
    data = appendAspectRatio(data);
    data.data = migrateDataSetParameters(data.data, data.data?.dataset)
    if (data.data?.dataset === 'iss' || data.data?.dataset === 'roofing_blog') {
      data.data = migrateArticleDataSetParameters(data.data, data.data.dataset)
    }

    // PB-444: MediaObject images were using the UI`Upload/Image`, but now we are using
    //         UI`Image` component. This means that the structure is different and
    //         we need to alter it here in order to not break previously existinh
    //         cards.
    const transformedMediaObjects = data.mediaObjects?.map((mediaObject) => {
      if (typeof mediaObject.image === 'string') {
        return {
          ...mediaObject,
          image: {
            file: mediaObject.image,
            crop: undefined,
          }
        };
      } else {
        return mediaObject;
      }
    });
    data.mediaObjects = transformedMediaObjects;

    return data;
  },
};

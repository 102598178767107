import _ from 'lodash';
import PropTypes from 'prop-types';
import { useContext } from 'react';

import { BadgesContext } from '../../../OC/PageBuilder/Locator/badges';
import { openReusableModal } from '../../../PageBuilder/helpers/modal';
import Image from '../../oc-image';
import {
  ActionLink,
  Badges as BadgesWrapper,
} from '../result-block-styled-components';

const Badge = ({ badge }) => {
  const { details = {}, icon = {}, label, value } = badge;
  const { title, reusable } = details;
  const { alt, file } = icon;
  const onClick = () => openReusableModal(reusable, title || label);

  return (
    <ActionLink
      onClick={onClick}
      tabIndex="0"
      data-track="tooltip"
      data-track-destination={value}
    >
      <Image src={file} alt={alt} />
    </ActionLink>
  );
};

function findBadge(value, badgeData) {
  // badgeData is either a value or an array of values
  // 'CEE' or ['CEE', 'GAF']
  return badgeData.find(b =>
    _.some(Array.wrap(b.value), bValue => bValue === value)
  );
}

const Badges = ({ badges }) => {
  const { items: badgeInfos = [] } = useContext(BadgesContext);
  if (!badges || badges?.length === 0) {
    return null;
  }
  const foundBadges = badges
    .map(badge => findBadge(badge, badgeInfos))
    .filter((badge, index, self) => self.indexOf(badge) === index) // Remove duplicates
    .filter(Boolean);

  if (foundBadges.length === 0) {
    return null;
  }

  return (
    <BadgesWrapper>
      {foundBadges.map(badge => (
        <Badge key={badge.label} badge={badge} />
      ))}
    </BadgesWrapper>
  );
};

Badges.propTypes = {
  badges: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Badge.propTypes = {
  badge: PropTypes.shape({
    details: PropTypes.shape({
      title: PropTypes.string,
      reusable: PropTypes.string,
    }),
    icon: PropTypes.shape({
      alt: PropTypes.string,
      file: PropTypes.string,
    }),
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

export default Badges;

import styled from '@emotion/styled';
import PropTypes from 'prop-types';

// Components
import Form from '../../ComponentLibrary/forms/oc-form';
import Fieldset from '../../ComponentLibrary/input-elements/oc-fieldset';
import FormButton from '../../ComponentLibrary/input-elements/oc-form-button';
import SelectDropdown from '../../ComponentLibrary/input-elements/oc-select-dropdown';
import Link from '../../ComponentLibrary/oc-link';

// Utilities import

const Legend = styled.legend`
  color: black;
  font-weight: 700;
  margin-bottom: 8px;
  margin-bottom: ${props => (props.areaInputSeperator ? '25px' : '8px')};
  width: 100%;
`;

const ColoredLine = styled.hr`
  border-top: solid black;
  margin-bottom: 20px;
  margin-top: 10px;
`;

const FormButtonContainer = styled.css`
  width: 60%;
`;

const Heading = styled.h3`
  font-family: 'OCROBOTO';
  font-weight: bold;
  color: black;
  text-transform: capitalize;
`;

const PdfLink = styled(Link)`
  outline: none;
`

const CalculatorForm = ({
  t,
  formSubmit,
  inputChange,
  sku,
  thickness,
  products,
}) => {
  function uniq(items, text) {
    const uniqItems = Array.from(new Set(items));
    uniqItems.unshift({ text: text, value: '' });
    return uniqItems;
  }

  const allProducts = products;
  const productItems = uniq(allProducts.map(item => item.sku), t('co2.dropdown_placeholder_product'));
  const selectedProducts = allProducts.filter(item => item.sku === sku);
  const thicknessItems = uniq(
    selectedProducts.map(x => ({
      value: x.thickness,
      text: Number(x.thickness),
    })),
    t('co2.dropdown_placeholder_thickness')
  );

  const DISCLAIMER_LINK_TO = '/tools-documents/calculators/co2-calculator/terms-conditions';

  return (
    <>
      <Heading>{t('co2.form_heading')}</Heading>
      <ColoredLine />

      <Form onSubmit={formSubmit}>
        <Fieldset>
          <Legend>{t('co2.product_input')}</Legend>
          <SelectDropdown
            name="sku"
            options={productItems}
            onChange={inputChange}
            value={sku}
          />
        </Fieldset>

        <Fieldset>
          <Legend>{t('co2.thickness_input')}</Legend>
          <SelectDropdown
            name="thickness"
            options={thicknessItems}
            onChange={inputChange}
            value={thickness}
          />
        </Fieldset>

        <p>{t('co2.disclaimer_text')}</p>
        <p>
          {t('co2.disclaimer')}&nbsp;
          <PdfLink href={DISCLAIMER_LINK_TO}>{t('co2.disclaimer_link')}</PdfLink>
        </p>

        <Fieldset>
          <FormButtonContainer>
            <FormButton
              buttonType="submit"
              data-track="form-submit"
              data-track-form-name="Co2"
              data-track-form-type="Calculator"
              color="#CE1126"
            >
              {t('co2.submit_button')}
            </FormButton>
          </FormButtonContainer>
        </Fieldset>
      </Form>
    </>
  );
};

export default CalculatorForm;

CalculatorForm.propTypes = {
  t: PropTypes.func.isRequired,
  formSubmit: PropTypes.func.isRequired,
  inputChange: PropTypes.func.isRequired,
  sku: PropTypes.string.isRequired,
  thickness: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      epd: PropTypes.string,
      density: PropTypes.string,
      thickness: PropTypes.string,
      base_value_1: PropTypes.string,
      base_value_2: PropTypes.string,
      lambda_value: PropTypes.string,
      scaling_factor: PropTypes.string,
    })
  ).isRequired,
};
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useState } from 'react';

/**
 * In order to open Email/Request a Quote and Question modal we need to manage
 * the state of the 3 modals. This hook will help us to manage the state of
 * them.
 */
const useRAQQuestionModal = () => {
  const [RAQQuestionOpen, RAQQuestionChangeState] = useState(false);
  const [RAQOpen, RAQChangeState] = useState(false);
  const [emailOpen, emailChangeState] = useState(false);

  const RAQQuestionOnAccept = () => {
    RAQQuestionChangeState(false);
    disableBodyScroll(document.querySelector('#raq-modal .modal-body'));
    RAQChangeState(true);
  };
  const RAQQuestionOnDeny = () => {
    RAQQuestionChangeState(false);
    emailChangeState(true);
  };
  const RAQQuestionOnClose = () => {
    RAQQuestionChangeState(false);
  };
  const RAQQuestionOnOpen = () => {
    RAQQuestionChangeState(true);
  };

  const RAQFormName = RAQOpen
    ? document.getElementById('raq-modal').dataset.formName
    : null;
  const RAQModalElementFunc = () => document.getElementById('raq-modal');
  const RAQOnOpen = () => {
    disableBodyScroll(document.querySelector('#raq-modal .modal-body'));
    RAQChangeState(true);
  };
  const RAQOnClose = () => {
    enableBodyScroll(document.querySelector('#raq-modal .modal-body'));
    RAQChangeState(false);
  };

  const emailOnClose = () => {
    enableBodyScroll(document.querySelector('#email-modal .modal-body'));
    emailChangeState(false);
  };
  const emailOnOpen = () => {
    disableBodyScroll(document.querySelector('#email-modal .modal-body'));
    emailChangeState(true);
  };
  const emailModalElementFunc = () => document.getElementById('email-modal');

  return {
    RAQFormName,
    RAQOpen,
    RAQOnOpen,
    RAQOnClose,
    RAQModalElementFunc,
    emailOpen,
    emailOnOpen,
    emailOnClose,
    emailModalElementFunc,
    RAQQuestionOpen,
    RAQQuestionOnAccept,
    RAQQuestionOnDeny,
    RAQQuestionOnClose,
    RAQQuestionOnOpen,
  };
};

export default useRAQQuestionModal;

import { openComponentModal } from '../../../../PageBuilder/helpers/modal';
import { EmailContractorModal } from '../../../../Utilities';

export const openEmailContractorModal = contractor => {
  if (!contractor) {
    return;
  }

  openComponentModal(
    () => (
      <EmailContractorModal
        schemaOnly
        contractor={contractor}
        source="insulation"
      />
    ),
    `Email to ${contractor.name}`
  );
};

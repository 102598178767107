import styled from '@emotion/styled';

const Container = styled.div`
  li::marker {
    font-weight: bold;
  }

  ol {
    padding-left: 2em;
  }
`;

export default function RoofingClaimsNextSteps ({schema}) {
  let addressTo;
  if (schema.const === "homeowner"){
    addressTo = "Homeowners:"
  }
  else {
    addressTo = "Contractors:"
  }

  return (
    <Container>
      <h2>Next Steps</h2>
      <p>
        <h3>{addressTo}</h3>
        After you submit this form, we will work to get your claim established. 
        Within 72 hours you will receive communication that includes details on 
        the next steps/requirements. In preparation of that communication we will 
        be asking for the following as part of the claim process.
      </p>
      <ol>
        <li>
          <p><strong>Proof of Purchase</strong> (one of the following)</p>
          <ul>
            <li>Contractor's invoice for installation of roof (not an estimate)</li>
            <li>Bill of sale for manufactured home</li>
            <li>Sales receipt</li>
            <li>Building permit or construction agreement for new construction</li>
          </ul>
        </li>
        <li>
          <p><strong>Proof of Ownership</strong> (one of the following)</p>
          <ul>
            <li>Deed</li>
            <li>Tax bill</li>
            <li>Court records showing ownership</li>
          </ul>
        </li>
      </ol>
    </Container>
  );
};

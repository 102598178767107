import { jsx, css } from '@emotion/react';
import { define, ui } from '@owenscorning/pcb.alpha';


import Renderer from './../../../../OC/PageBuilder/Accordion.jsx';

export default define`Accordion`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/Accordion`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value }) => <Renderer { ...value } />,
  edit: {
    copy: ui`Content/Basic`,
    containerSize: ui`Form`.of({
      selection: ui`Choices`.of({
        'xl': 'XL',
        'l': 'Large',
        'm': 'Medium',
      })({
        label: 'Container Size',
        default: 'xl',
        mode: ui`Choices/Mode/Dropdown`
      })
    }),
    alignment: ui`Choices`.of(['Left', 'Center'])({
      label: 'Alignment',
      default: 'Left'
    }),
    accordions: ui`List`.of({
      title: ui`Text`({ label: 'Accordion Title', default: 'Accordion Title' }),
      content: ui`Text/Rich`({ label: 'Accordion Content' }),
    })({
      min: 1, max: 25,
      singular: 'Accordion',
      title: 'title',
      label: 'Accordions',
      default: [{ title: 'Accordion Title' }]
    })
  },
});

import styled from '@emotion/styled';
import Picture from "./oc-picture";
import Text from './oc-text';

const getSpan = (arr) => {
  let str = arr[0];
  if(arr[1] !== 0) {
    str += ` / span ${arr[1]}`;
  }
  return str;
};

const HeroBackground = styled.div`
  &.hero-bg {
    ${props => (props.mediaType === 'image' && `background: no-repeat center url('${props.imgTablet}');`)}
    background-size: contain !important;

    @media (min-width: 1200px) {
      ${props => (props.mediaType === 'image' && `background: no-repeat center url('${props.imgDesktop}');`)}
    }
  }
`;

const Hero = styled.div`
  &.hero {
    margin-bottom: 20px;

    .fill {
      margin: -1px;
    }

    br {
      @media (min-width: 768px) and (max-width: 1200px)  {
        display: none;
      }
    }

    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);

    @media (max-width: 767px) {
      padding: 0 15px;
      ${props => props.grid.mobile.css}
    }

    @media (min-width: 768px) {
      margin-bottom: 0;
      grid-template-columns: ${props => props.grid.tablet.columns};
      grid-template-rows: ${props => props.grid.tablet.rows};
      ${props => (`grid-template-areas: \"${props.fill.tabletTranslated.join("\" \"")}\";`)}

    }

    @media (min-width: 1200px), screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      -ms-grid-columns: ${props => props.grid.desktop.ms_columns};
      -ms-grid-rows: ${props => props.grid.desktop.ms_rows};
      grid-template-columns: ${props => props.grid.desktop.columns};
      grid-template-rows: ${props => props.grid.desktop.rows};

      ${props => (`grid-template-areas: \"${props.fill.desktopTranslated.join("\" \"")}\";`)}
    }

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      ${props => Object.keys(props.fill.desktop).filter(k => k!=='dim').map(k => {
        const classMap = {tf: 'top-fill',
                          ltf: 'left-top-fill',
                          lf: 'left-fill',
                          mf: 'middle-fill',
                          rf: 'right-fill',
                          rbf: 'right-bottom-fill',
                          lbf: 'left-bottom-fill',
                          bf: 'bottom-fill'};
        return `.${classMap[k]} {
            -ms-grid-row: ${props.fill.desktop[k][0]+1};
            -ms-grid-row-span: ${props.fill.desktop[k][1]+1};
            -ms-grid-column: ${props.fill.desktop[k][2]+1};
            -ms-grid-column-span: ${props.fill.desktop[k][3]+1};
          }
        `;
      })}
    }

    .button-wrapper {
      @media screen and (min-width: 1200px), screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        justify-content: right;
        margin-right: 10px;
        flex-direction: row;
      }

      @media screen and (max-width: 1199px) {
        flex-direction: column;

        .oc-cta-container {
          padding: 0;
        }
      }

      @media screen and (max-width: 767px) {
        .oc-cta-container {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
`;

const Main = styled.div`
  &.main {
    z-index: 1;
    padding-top: 0;
    grid-column: ${props => getSpan(props.pos.mobile.column)};
    grid-row: ${props => getSpan(props.pos.mobile.row)};
    ${props => props.pos.mobile.css}

    /* .hero p is set to roboto, PB putting headers in <p> */
    p {
      font-family: "Oswald", sans-serif;
    }

    @media(min-width: 768px) {
      grid-column:  ${props => getSpan(props.pos.tablet.column)};
      grid-row: ${props => getSpan(props.pos.tablet.row)};
      ${props => props.pos.tablet.css}
    }

    @media screen and (min-width: 1200px) {
      grid-column:  ${props => getSpan(props.pos.desktop.column)};
      grid-row: ${props => getSpan(props.pos.desktop.row)};
      ${props => props.pos.desktop.css}
    }

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      -ms-grid-column: ${props => props.pos.desktop.column[0]};
      -ms-grid-column-span: ${props => props.pos.desktop.column[1]};
      -ms-grid-row: ${props => props.pos.desktop.row[0]};
      -ms-grid-row-span: ${props => props.pos.desktop.row[1]};
    }
  }
`;

const Panther = styled.div`
  &.panther {
    position: relative;
    background: transparent;
    z-index: 2;
    grid-column: ${props => getSpan(props.pos.mobile.column)};
    grid-row: ${props => getSpan(props.pos.mobile.row)};
    width: auto;
    height: auto;

    &:before {
      background-image: url('${ props => Picture.Transform(props.panther) || 'https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto/v1621524256/roofing/artist-panther.png' }');
      left: 0;
      ${props => props.pos.mobile.size && `background-size: ${`${props.pos.mobile.size[0]}px ${props.pos.mobile.size[1]}px`};`}
      ${props => props.pos.mobile.css}

      @media (min-width: 768px) {
        background-size: ${props => `${props.pos.tablet.size[0]}px ${props.pos.tablet.size[1]}px`};;
        background-position: left bottom;
        ${props => props.pos.tablet.css}
      }

      @media (min-width: 1200px), screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        background-size: ${props => `${props.pos.desktop.size[0]}px ${props.pos.desktop.size[1]}px`};
        background-position: left bottom;
        ${props => props.pos.desktop.css}
      }
    }

    @media(min-width: 768px) {
      grid-column: ${props => getSpan(props.pos.tablet.column)};
      grid-row: ${props => getSpan(props.pos.tablet.row)};
    }

    @media screen and (min-width: 1200px) {
      grid-column: ${props => getSpan(props.pos.desktop.column)};
      grid-row: ${props => getSpan(props.pos.desktop.row)};
    }

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      -ms-grid-column: ${props => props.pos.desktop.column[0]};
      -ms-grid-column-span: ${props => props.pos.desktop.column[1]};
      -ms-grid-row: ${props => props.pos.desktop.row[0]};
      -ms-grid-row-span: ${props => props.pos.desktop.row[1]};
    }
  }
`;

const TextContent = styled.div`
  &.text-content {
    grid-area: text;
    background: transparent!important;
    padding: 0;
    overflow: hidden;
    clear: both;
    z-index: 10;
    grid-column: ${props => getSpan(props.pos.mobile.column)};
    grid-row: ${props => getSpan(props.pos.mobile.row)};
    line-height: 1.3;
    ${props => props.pos.mobile.css}

    .body-text {
      ${props => props.pos.mobile.textCss}
    }

    .oc-cta-container {
      padding: 0;
      margin: 0;

      a {
        min-width: 150px;
      }

      @media screen and (min-width: 768px) {
        &:last-of-type {
          margin-right: 0;
        }
      }

      @media screen and (min-width: 1200px) {
        margin-right: 16px;

        a {
          min-width: 140px;
        }
      }
    }

    div {
      margin: 0;
    }

    a {
      margin-bottom: 16px;
    }

    .button-wrapper {
      width: auto;
      text-align: center;
      flex-direction: column;
      margin-top: 16px;

      @media screen and (min-width: 768px) {
        text-align: left;
        margin-top: 24px;
        flex-direction: row;
      }

      @media screen and (min-width: 1200px), screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        margin-top: 32px;
      }
    }

    @media(min-width: 768px) {
      grid-column: ${props => getSpan(props.pos.tablet.column)};
      grid-row: ${props => getSpan(props.pos.tablet.row)};
      padding-left: 0px;
      padding-right: 20px;
      background: transparent;
      margin-top: 10px;
      ${props => props.pos.tablet.css}

      .body-text {
        ${props => props.pos.tablet.textCss}
      }

      a {
        display: inline-flex;
      }
    }

    @media (min-width: 1200px), screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      padding-left: 42px;
      padding-right: 0;
      grid-column: ${props => getSpan(props.pos.desktop.column)};
      grid-row: ${props => getSpan(props.pos.desktop.row)};
      background: white;
      margin-top: 0;
      ${props => props.pos.desktop.css}

      .body-text {
        ${props => props.pos.desktop.textCss}
      }
    }

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      -ms-grid-column: ${props => props.pos.desktop.column[0]};
      -ms-grid-column-span: ${props => props.pos.desktop.column[1]};
      -ms-grid-row: ${props => props.pos.desktop.row[0]};
      -ms-grid-row-span: ${props => props.pos.desktop.row[1]};
    }
  }
`;

const translateFill = (grid) => {
  const populateArr = (specFill, specGrid) => {
    const arr = new Array(specGrid.dim[1]).fill('.').map(() => new Array(specGrid.dim[0]).fill('.'));
    Object.keys(specFill).forEach( fk => {
      if(fk === 'dim') return;
      const [xMin, xSpan, yMin, ySpan] = specFill[fk];
      for(let y=yMin; y<=yMin+ySpan; y++) {
        for(let x=xMin; x<=xMin+xSpan; x++) {
          arr[y][x] = fk;
        }
      }
    });
    return arr;
  };

  const joinArr = (arr) => {
    return arr.map(row => row.join(" "));
  };

  const tablet = joinArr(populateArr(grid.fill.tablet, grid.tablet));
  const desktop = joinArr(populateArr(grid.fill.desktop, grid.desktop));

  return [tablet, desktop];
};

const HeroT1 = ({ prehead, heading, body, ctas, panther, pantherAlt, grid, imgDesktop, imgTablet, imgAlt, videoWebm, videoMp4, mediaType }) => {
  const [tabletTranslated, desktopTranslated] = translateFill(grid);
  grid.fill.tabletTranslated = tabletTranslated;
  grid.fill.desktopTranslated = desktopTranslated;
  let processedImgDesktop = Picture.DefaultOrCrop(imgDesktop?.file, imgDesktop?.crop, 'http://placehold.it/1340x700');
  let processedImgTablet = Picture.DefaultOrCrop(imgTablet?.file, imgTablet?.crop, 'http://placehold.it/800x480');
  let imgDesktopAlt = (imgDesktop && imgDesktop?.alt) ? imgDesktop?.alt : imgAlt;

  return (
    <div className="hero-tier-1">
      <HeroBackground
        aria-label={(mediaType == "image") ? imgDesktopAlt : null}
        className="hero-video-background video hero-bg"
        imgDesktop={processedImgDesktop}
        imgTablet={processedImgTablet}
        mediaType={mediaType}
        role={(mediaType == "image") ? "img" : null}
      >
        <Hero className="hero" fill={grid.fill} grid={grid}>
          <Main className="main rball-header" pos={grid.main}>
            { prehead && <span className="prehead" ><Text content={ prehead } /></span> }
            <h1>{heading}</h1>
          </Main>

          <Panther className="panther" pos={grid.panther} panther={panther} aria-label={pantherAlt} role="img" />
          <TextContent pos={grid.textContent} className="text-content">
            <div className="body-text">{body}</div>

            <div className="button-wrapper flex">
              { ctas }
            </div>
          </TextContent>

          <div className="fill top-fill"></div>
          <div className="fill left-top-fill"></div>
          <div className="fill left-fill"></div>
          <div className="fill middle-fill"></div>
          <div className="fill right-fill"></div>
          <div className="fill right-bottom-fill"></div>
          <div className="fill left-bottom-fill"></div>
          <div className="fill bottom-fill"></div>
        </Hero>

        {mediaType === 'video' &&
          (<video playsInline muted autoPlay >
            <source src={videoWebm} type="video/webm" />
            <source src={videoMp4} type="video/mp4" />
          </video>)
        }
      </HeroBackground>
    </div>
  );
}

export default HeroT1;

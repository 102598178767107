import { define } from '@owenscorning/pcb.alpha';
import { wrapRef } from '../../../../data';
import { useEffect } from 'react';

export default define`Data Reference`('0.0.1')({
  view: <div></div>,
  edit: ({ type, dataset, parameters, onChange, value }) => {
    useEffect(() => {
      onChange(wrapRef('Cms::DataProvider', { type, dataset, parameters: parameters?.[dataset], language: Board.build.language }))
    }, [type, dataset, JSON.stringify(parameters || {})]);
    return null;
  }
});

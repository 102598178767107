import { define, ui } from '@owenscorning/pcb.alpha';

import Renderer from '../../../../OC/PageBuilder/VentilationCalculator';

export default define`Ventilation Calculator`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/VentilationCalculator`,
  category: ui`Modules/Categories/Page/Prebuilt`,
  view: ({ value }) => <Renderer { ...value } />,
  // edit: {},
});

import { first } from 'lodash';
import placeholder_url from '../../../../placeholder';
import pathToContent from './helpers/path_to_content';
import ArticleItemList from './helpers/ArticleItemList';
import { makeT } from "../../../location/locales";

const textFromCategory = (category, t) => {
  switch (category) {
    case 'Blog': return t('builder.data.read_more');
    case 'Case Study': return t('builder.data.read_more');
    case 'Document': return t('builder.data.read_more');
    case 'Video': return 'Watch Now';
    case 'Podcast': return 'Listen Now';
    case 'Learning Center': return 'Learn More';
    default: return t('builder.data.read_more');
  }
}

const iconFromCategory = (category) => {
  switch (category) {
    case 'Blog': return 'newspaper-o';
    case 'Case Study': return 'briefcase';
    case 'Document': return 'file';
    case 'Video': return 'video-camera';
    case 'Podcast': return 'podcast';
    case 'Learning Center': return 'lightbulb-o';
    default: return 'newspaper-o';
  }
}

const mapItemFromData = (content) => {
  if (!content) {
    return null;
  }
  const { metadata: { settings: { details = {} } = {} } = {} } = content;
  const t = makeT(Board.build.language);
  return (
    {
      image: details?.image?.file ? details.image : { file: placeholder_url(370,185) },
      img: details?.image?.file || placeholder_url(370,185),
      alt: details?.image?.alt,
      prehead: [
        details?.taxonomies?.primary_solution_center || first(details?.taxonomies?.solution_centers),
        details?.taxonomies?.primary_industry || first(details?.taxonomies?.industries),
        details?.taxonomies?.category
      ].filter(x => x).join(' • '),
      heading: details?.information?.title,
      text: details?.information?.description,
      linkText: textFromCategory(details?.taxonomies?.category, t),
      url: pathToContent(content),
      icon: iconFromCategory(details?.taxonomies?.category)
    }
  );
};

export default
  ArticleItemList({
    search: {
      name: 'ISS Blog Articles',
      type: 'Article::InsulationSolutionsSuite',
      preview: result => ({
        preheading: result.category,
        heading: result.proper_name
      })
    },
    list: {
      availableIn: ['www.owenscorning.com'],
      name: 'Blog - ISS (Insulation Solutions Suite)',
      attributeSet: {
        name: 'ISS Article Attributes',
        path: 'insulation/insulation-solutions-suite/article'
      },
      dataset: 'iss',
      itemListMap: mapItemFromData,
      cardSetMap: mapItemFromData,
      categoryFeaturesMap: mapItemFromData,
      enableSearch: true,
    }
  })

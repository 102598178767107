import {connect} from 'react-redux';
import _ from "lodash";
import {SHINGLES_ADD_RECENTLY_VIEWED} from "../../../Actions";
import getReduxInitial from "../../../helpers/redux_initial";

const maxRecentlyViewed = 6;
const recentlyViewedDefault = [];
const recentlyViewedInitial = _.union(recentlyViewedDefault, getReduxInitial('shinglesRecentlyViewed', []));
export const shinglesRecentlyViewedReducer = (state = recentlyViewedInitial, action) => {
  let newState = _.clone(state);
  switch(action.type) {
    case SHINGLES_ADD_RECENTLY_VIEWED:
      // Remove item if it's already on the list
      newState = newState.filter(fav => fav !== action.color)
      // Add item to the from of the list
      newState.unshift(action.color);
      // Limit recent views to 6 items
      newState = newState.slice(0, maxRecentlyViewed)
      return newState;
    default:
      return state;
  }
};

export const addToRecentlyViewed = (color) => ({type: SHINGLES_ADD_RECENTLY_VIEWED, color});

export const connectRecentlyViewed = connect(
  (state) => ({recentlyViewed: state.shinglesRecentlyViewed}),
  {addToRecentlyViewed}
);

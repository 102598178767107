import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';

const Table = styled.table`
  table-layout: fixed;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  border-width: 0;
  border-collapse: collapse;
`;

const HeaderColumn = styled.th`
  background: transparent;
  font-size: 12px;
  text-align: center;
  padding: 10px;
  vertical-align: bottom;
`;

const Row = styled.tr`
  text-align: center;
  padding: 10px;
  vertical-align: middle;
  border-bottom: 1px solid #d7d7d7;

  &:last-child {
    border-bottom: none;
  }
`;

const Column = styled.td`
  padding: 10px 0;
`;

function TabularOptions({ options, isSelected, toggleOption }) {
  const optionProperties = Object.getOwnPropertyNames(options[0]).filter(
    property => property !== 'id'
  );

  return (
    <div className="oc-table-responsive">
      <Table>
        <thead>
          <Row>
            <HeaderColumn>{/* Hold a column for the checkbox */}</HeaderColumn>
            {optionProperties.map(propertyName => (
              <HeaderColumn key={propertyName}>{propertyName}</HeaderColumn>
            ))}
          </Row>
        </thead>
        <tbody>
          {options.map(option => (
            <Row key={option.id}>
              <Column>
                <input
                  type="checkbox"
                  id={option.id}
                  checked={isSelected(option.id)}
                  onChange={toggleOption}
                />
              </Column>

              {optionProperties.map(propertyName => (
                <Column
                  key={`${option.id}-${propertyName}`}
                  style={{
                    fontWeight: isSelected(option.id) ? 'bold' : 'normal',
                  }}
                >
                  {option[propertyName]}
                </Column>
              ))}
            </Row>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

TabularOptions.propTypes = {
  options: PropTypes.array.isRequired,
  isSelected: PropTypes.func.isRequired,
  toggleOption: PropTypes.func.isRequired,
};

export default TabularOptions;

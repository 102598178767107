import PropTypes from 'prop-types';

import Button from '../../oc-button';
import { MainCta as CTAWrapper } from '../result-block-styled-components';

const CallNowCTA = ({ href, label, onClick, track }) => (
  <CTAWrapper>
    <Button
      onClick={onClick}
      href={onClick ? undefined : href}
      data-track={track.name}
      data-track-buy-type={track.buyType}
      data-track-contractor={track.contractorName}
      data-track-contractor-id={track.contractorId}
      data-track-form-name={track.formName}
      data-track-professional={track.professional}
    >
      {label}
    </Button>
  </CTAWrapper>
);

CallNowCTA.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string,
  track: PropTypes.shape({
    name: PropTypes.string,
    contractorName: PropTypes.string,
    contractorId: PropTypes.string,
    formName: PropTypes.string,
    professional: PropTypes.string,
    buyType: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

CallNowCTA.defaultProps = {
  href: undefined,
  onClick: undefined,
  track: {},
};

export default CallNowCTA;

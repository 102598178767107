import styled from "@emotion/styled";

// Styled Components
const PillContainer = styled.div`
  background-color: #000000;
  border-radius: 1em;
  color: #FFFFFF;
  display: inline-block;
  font-weight: 700;
  padding: 4px 8px;
  position: relative;
  text-transform: uppercase;
`;

const PillCallout = ({ className, copy }) => {
  return (
    <PillContainer className={className}>{copy}</PillContainer>
  );
};

export default PillCallout;

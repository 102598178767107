import { define, ui, when, select } from '@owenscorning/pcb.alpha';

// when we rewrite Renderer, be aware of ./Builder/Library/Modules/Product/RoofingIntroContentAndImage.jsx
import Renderer from './../../../../OC/PageBuilder/ContentAndImage.jsx';

const sizesHash = {
  '1-2': ['1/3 Text & 2/3 Image', '1540px'],
  '1-1': ['1/2 Text and 1/2 Image', '1140px'],
  '2-1': ['2/3 Text and 1/3 Image', '740px'],
  'noMedia': null
};

export default define`Content & Image`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/ContentAndImage`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value }) => <Renderer { ...value } />,
  gutterlessForReusable: true,
  edit: ({ contentOptions = {}, ...props }) => <UI.Form { ...props } contents={{
    arrangement: {
      layout: ui`Choices`.of({
        '1-2': '1/3 text and 2/3 media',
        '1-1': '1/2 and 1/2 layout',
        '2-1': '2/3 text and 1/3 media',
        'noMedia': 'No Media'
      })({
        label: 'Layout',
        default: '1-2'
      }),
      containerSize: ui`Form`.of({
        selection: ui`Choices`.of({
          'xl': 'XL',
          'l': 'Large',
          'm': 'Medium',
        })({
          label: 'Container Size',
          default: 'xl',
          mode: ui`Choices/Mode/Dropdown`
        })
      }),
      alignment: ui`Choices`.of({'left': 'Left', 'center': 'Center'})({
        label: 'Alignment',
        default: 'left',
        visible: when`../../arrangement/containerSize/selection`.is.equal.to('xl').then(false).otherwise(true)
      }),
      contentOrder: ui`Choices`.of({
        'first': 'Left',
        'last': ' Right'
      })({
        label: 'Text Position (Desktop/Tablet)',
        default: 'first',
        visible: when`../../arrangement/layout`.is.equal.to('noMedia').then(false).otherwise(true)
      }),
      contentOrderMobile: ui`Choices`.of({
        'last': 'After Image',
        'first': 'Before Image'
      })({
        label: 'Text Position (Mobile)',
        default: 'last',
        visible: when`../../arrangement/layout`.is.equal.to('noMedia').then(false).otherwise(true)
      }),
    },
    imageSizeInfo: ui`ImageSizeInfo`(
      {
        visible: when`../media/type`.is.equal.to('image'),
        moduleName: 'Figure',
        sizesHash: sizesHash,
        selectedSize: select`../arrangement/layout`
      },
    ),
    media: ui`Media`({visible: when`../arrangement/layout`.is.equal.to('noMedia').then(false).otherwise(true), askImageCaption: true}),
    imageUrl: ui`Text`({ label: 'Image Url (Optional)', visible: when`../media/type`.is.equal.to('image') }),
    content: ui`Content/Basic`({ contentOptions })
  }} />
});

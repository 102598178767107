import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Theme from '../../../themes';
import _ from 'lodash';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 16px;
  position: relative;
  transition: all 0.2s;

  &:last-child {
    margin-bottom: 0px;
  }

  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 1px solid #949494;
    flex-shrink: 0;
    height: 20px;
    margin: auto 10px auto 0;
    transition: all 0.2s;
    width: 20px;
  }

  input[type="checkbox"]:checked {
    background-color: ${Theme.colors.brand};
    border-color: ${Theme.colors.brand};
  }

  input[type="checkbox"] ~ .check {
    border-bottom: 3px solid white;
    border-left: 3px solid white;
    display: none;
    height: 7px;
    left: 4px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: rotate(-45deg) translate(25%, -4px);
    width: 13px;
  }

  input[type="checkbox"]:checked ~ .check {
    display: block;
  }

  ${ ({ partial }) => partial && css`
    opacity: 0.5;

    input[type="checkbox"] {
      border-color: #6D6D6D !important;
      background-color: #6D6D6D !important;
      position: relative;

      & ~ .check { display: none !important; }

      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        border-radius: 1px;
        transform: translate(-50%, -50%);
        background-color: #FFF;
        width: 14px;
        height: 2px;
      }
    }
  ` }

  label {
    top: 1px;
    user-select: none;
  }
`;

const Checked = {
  all: (subject) => _.isPlainObject(subject)
    ? _.reduce(subject, (result, value, key) => result ? Checked.all(value) : result, true)
    : subject,
  none: (subject) => _.isPlainObject(subject)
    ? _.reduce(subject, (result, value, key) => result ? Checked.none(value) : result, true)
    : !subject,
  some: (subject) => !Checked.all(subject) && !Checked.none(subject)
};

const Check = {
  all: (subject) => _.isPlainObject(subject)
    ? _.mapValues(subject, Check.all)
    : true,
  none: (subject) => _.isPlainObject(subject)
    ? _.mapValues(subject, Check.none)
    : false
};

export default ({ name, value, onChange, label, partial = false }) => {
  let some, all;
  let checked = value;

  if (_.isPlainObject(value)) {
    all = Checked.all(value);
    some = Checked.some(value);
    checked = all;
    partial = some;
  }

  return <Wrapper partial={ partial } >
    <input
      type="checkbox"
      name={ name }
      id={ name }
      checked={ checked }
      onChange={ (event) => {
        if (!_.isPlainObject(value)) return onChange(event.target.checked);
        if (some || Checked.none(value)) return onChange(Check.all(value));
        if (all) return onChange(Check.none(value));
      } }
    />
    <label htmlFor={ name }>{ label }</label>
    <span className="check" />
  </Wrapper>;
};

import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Theme from '../../themes/index';

// Components
import WrapperLink from './oc-wrapper-link';
import Picture from './oc-picture';
import Icon from './oc-icon';
import ImageShareLinks from './oc-image-share-links';

const Media = styled.div`
  margin-bottom: 8px;
  position: relative;

  img {
    display: block;
    max-width: 100%;
    width: 100%;
  }
`;

const IconWrapper = styled.div`
  align-items: center;
  background: white;
  bottom: 0;
  color: ${ Theme.colors.brand };
  display: flex;
  font-size: 21px;
  height: 42px;
  justify-content: center;
  left: 0;
  position: absolute;
  width: 42px;
`;

const Card = ({ img, alt, url, icon, analytics, children, aspectRatio, transform="cards", socialShare, ctaSwitch, handleCtaButtonClick, ...props }) => {

  if (aspectRatio) { aspectRatio['custom'] = aspectRatio['custom']?.toString(); }

  return (
    <div { ...props }>
      <WrapperLink url={ url } tabIndex="-1" analytics={ analytics }>
        { img &&
          <Media>
            { icon &&
              <IconWrapper>
                <Icon type={ icon } />
              </IconWrapper>
            }

            <Picture
              source={ img }
              transform={transform}
              aspectRatio={ aspectRatio }
              alt={ alt }
              { ...(!!ctaSwitch && {onClick: handleCtaButtonClick})}
            />

            {socialShare && <ImageShareLinks imageUrl={img} />}
          </Media>
        }
      </WrapperLink>

      <div>
        { children }
      </div>
    </div>
  );
};

export default Card;

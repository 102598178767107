import produce from "immer";
import { connect } from 'react-redux';
import { toast } from "../helpers/toast";

const CONNECTED = 'CMS/onlineStatus/CONNECTED';
const DISCONNECTED = 'CMS/onlineStatus/DISCONNECTED';

//TODO - rename me connection or connectionStatus

// shape of redux cms state:
/*
{
  onlineStatus: { online: true/false },
}
 */

const onlineStatusReducer = {
  onlineStatus: produce((draft, action) => {
    switch (action.type) {
      case CONNECTED:
      case 'CABLECAR_CONNECTED': {
        if (draft.offlineToastId) {
          toast.dismiss(draft.offlineToastId);
          draft.offlineToastId = null;
        }
        draft.online = true;
        break;
      }
      case DISCONNECTED:
      case 'CABLECAR_DISCONNECTED': {
        if (!draft.offlineToastId) {
          draft.offlineToastId = toast.warn('You are offline.  Changes cannot be made', {autoClose: false});
        }
        draft.online = false;
        break;
      }
    }
  }, { online: true, offlineToastId: null })
};

export default onlineStatusReducer;

export const onlineStatusConnector = connect(
  (state) => ({
    online: state.cms.onlineStatus.online
  })
);

const onlinePingRequest = (store) => (
  fetch('https://dcpd6wotaa0mb.cloudfront.net/images/online.gif').then(() => {
    store.dispatch({type: CONNECTED});
  }).catch(() => {
    store.dispatch({type: DISCONNECTED});
  })
);

let bootstrapped = false;
export const onlineStatusMiddleware = store => {
  if (!bootstrapped) {
    bootstrapped = true;
    onlinePingRequest(store);
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    if (connection) {
      connection.addEventListener('change', () => onlinePingRequest(store));
    } else {
      setInterval(() => onlinePingRequest(store), 30000)
    }
  }

  return next => action => {
    next(action);
  };
}

import {connect} from 'react-redux';
import _ from 'lodash';
import { FEATURE_FLAG_DISABLE_KEY, FEATURE_FLAG_DISABLE_ALL} from '../Actions';
import getReduxInitial from "../helpers/redux_initial";

const DEFAULT = { previewFeatureFlags: [] };
const featureFlagInitial = _.assign({}, DEFAULT, getReduxInitial('featureFlag', {}));

export const featureFlagReducer = (state = featureFlagInitial, action) => {
  const newState = _.cloneDeep(state);
  switch(action.type) {
    case FEATURE_FLAG_DISABLE_KEY:
      const flags = state.previewFeatureFlags.filter(flag => flag !== action.key);
      return _.assign({}, newState, { previewFeatureFlags: flags });
    case FEATURE_FLAG_DISABLE_ALL:
      return _.assign({}, newState, DEFAULT );
    default:
      return state;
  }
};

export const disableKey = (key) => ({type: FEATURE_FLAG_DISABLE_KEY , key});
export const disableAll = () => ({type: FEATURE_FLAG_DISABLE_ALL});

export const connectFeatureFlag = connect(
  ({featureFlag}) => ({...featureFlag}),
  {  disableKey, disableAll},
  null,
  { forwardRef: true }
);

import { jsx, css } from '@emotion/react';

export default ({ heading, subheading='', onBack=null }) => (
  <div css={css`
    display: flex;
    padding-top: 5px;
    padding-bottom: 15px;
    border-bottom: 2px solid #000;
    position: sticky;
    top: 0px;
    background-color: #FFF;
    z-index: 9999;

    &:after {
      content: '';
      background-color: #FFF;
      position: absolute;
      top: -16px;
      left: -16px;
      z-index: -1;
      width: 100%;
      height: 72px;
      padding: 0 16px;
    }
  `} >
    <div css={css`
      display: ${ onBack ? 'block' : 'none' };
      width: 33px;
    `} >
      <div
        onClick={ onBack }
        css={css`
          cursor: pointer;
          user-select: none;
          background-image: url(${ UI.Images.Back });
          background-size: 21px auto;
          background-position: 0px center;
          background-repeat: no-repeat;
          margin-top: 6px;
          width: 21px;
          height: 21px;
        `}
      />
    </div>
    <div css={css`
      flex-grow: 1;
      color: #000;
    `} >
      <div>
        <div css={css` font-size: 14px; `} >{ subheading }</div>
        <div css={css`
          text-transform: uppercase;
          font-weight: bold;
        `} >
          { heading }
        </div>
      </div>
    </div>
  </div>
);

// note we should really never see this component, so I don't care about translating it, etc.
// externally, if these things aren't set, cross-locale should have caught it
export const CannotLocalize = ({ zip, code }) => (
  <div>
    <h2>Cannot Localize</h2>
    <p>
      Zip code ({JSON.stringify(zip)}) and locale ({JSON.stringify(code)}) are
      both required to localize.
    </p>
  </div>
);
export const InvalidZip = ({ zip, code }) => (
  <div>
    <h2>Invalid Zip</h2>
    <p>
      Zip code ({JSON.stringify(zip)}) is invalid for {JSON.stringify(code)}.
    </p>
  </div>
);

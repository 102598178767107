import styled from '@emotion/styled';
import { css } from '@emotion/css';
import NumberFormat from "react-number-format";

// Components
import Table from '../../ComponentLibrary/oc-table';
import Link from '../../OC/oc-link.jsx';
import Theme from '../../../themes/index';

const Results= styled.div`
  .pink-text {
    color: ${Theme.colors.brand};
  }
`;

const FanSizeContainer = styled.div`
	margin-bottom: 25px;
	font-size: 14px;

	.fan-size {
		display: block;
		font-size: 28px;
	}

  .header {
    font-size: 28px;
    text-transform: uppercase;
    color: #000;
    line-height: 30px;
  }

  .target-cfm {
    color: #000;
    font-size: 16px;
    line-height: 18px;
  }
`;

const InfoContainer = styled.div`
  margin-top: 25px;
`;

const InfoBody = styled.div`
  margin-bottom: 25px;
  font-size: 14px;
`;

const linkError = css`
  outline: 0px!important;
`;

const TableHead = styled.thead`
  th {
    border: none;
    background: #F8F8F8;
  }
`

const TableBody = styled.tbody`
  td {
    text-align: center !important;
    font-size: 20px;
    border: none;
    background: #F8F8F8;
  }
`

const NormalTitle = styled.span `
font-weight: 400 !important;
`


const CalculatorResults = ({
	minFanSize,
	fansRequirement12Inch,
	fansRequirement10Inch,
	fansRequirement8Inch
}) => (
	<Results>
		<FanSizeContainer>
      <span className="header"><strong>Example Fan Requirements</strong></span><br/>
      (No Bends in Exhaust Line)*<br/><br/>
      <span className="fan-size pink-text"><strong>{ minFanSize ? <><NumberFormat decimalScale={0} thousandSeparator={true} displayType="text" value={minFanSize}/></> : '--'}</strong></span><br/>
      <span className="target-cfm"><strong>Target CFM</strong></span>
		</FanSizeContainer>

    <Table firstLeft noBorder>
      <TableHead>
      <tr>
        <th scope="col" className="condensed "># 12 inch Fans<br/><NormalTitle>2200 CFM</NormalTitle></th>
        <th scope="col" className="condensed ">or</th>
        <th scope="col" className="condensed "># 10 inch Fans<br/><NormalTitle>1500 CFM</NormalTitle></th>
        <th scope="col" className="condensed ">or</th>
        <th scope="col" className="condensed "># 8 inch Fans<br/><NormalTitle>600 CFM</NormalTitle></th>
      </tr>
      </TableHead>
      <TableBody>
      <tr>
        <td className="pink-text "><strong>{fansRequirement12Inch ? <><NumberFormat decimalScale={0} thousandSeparator={true} displayType="text" value={fansRequirement12Inch}/></> : '--'}</strong></td>
        <td></td>
        <td className="pink-text "><strong>{fansRequirement10Inch ? <><NumberFormat decimalScale={0} thousandSeparator={true} displayType="text" value={fansRequirement10Inch}/></> : '--'}</strong></td>
        <td></td>
        <td className="pink-text "><strong>{fansRequirement8Inch ? <><NumberFormat decimalScale={0} thousandSeparator={true} displayType="text" value={fansRequirement8Inch}/></> : '--'}</strong></td>
      </tr>
      </TableBody>
    </Table>
    <InfoContainer>
      <InfoBody>*  Bending of exhaust line will reduce CFM output</InfoBody>
      <InfoBody><strong>Use of Filters:</strong> Use of filters on fan intakes is recommended and may reduce fan output.</InfoBody>
      <InfoBody><strong>Calculation Method:</strong> For further guidance and for calculation method, visit the <Link className={linkError} href="https://www.americanchemistry.com/industry-groups/spray-foam-coalition-sfc/resources/ventilation-considerations-for-spray-polyurethane-foam-guidance-on-ventilation-during-installation-of-interior-applications-of-high-pressure-spray">ACC website.</Link></InfoBody>
      <InfoBody><strong>Estimating Dimensions:</strong> Many rooms may have complicated layouts and ceiling constructs, which can make calculating dimensions more difficult. When estimating approximate dimensions, ensure values are estimated on the larger size.</InfoBody>
      <InfoBody><strong>Duration of Fan Operation:</strong> minimum of two hours post spraying; however, longer is better.</InfoBody>
      <InfoBody><strong>Disclaimer:</strong><br/> The estimated fan requirements are based on the dimensions entered into the calculator. The fan(s) should be appropriately sized for the workspace and strategically placed to ensure an even distribution of air and fume removal. It is the contractor’s responsibility to insert accurate dimensions and ensure adequate ventilation.</InfoBody>
    </InfoContainer>
	</Results>
);

export default CalculatorResults;

import styled from '@emotion/styled';
import { connectLocation } from '../location/LocationConnectors';
import ChevronLink from './oc-chevron-link';

const TemperatureRangeContainer = styled.div`
	margin-top: 20px;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    margin-top: 0;
  }
`;

const TemperatureDisplay = styled.div`
  background: rgb(64,147,229);
  background: linear-gradient(90deg, rgba(64,147,229,1) 0%, rgba(114,91,131,1) 40%, rgba(158,70,87,1) 73%, rgba(212,55,44,1) 100%);
  padding-top: 10px;
  padding-bottom: 20px;
`;

const TemperatureMilestones = styled.ul`
	display: none;
  list-style: none;
  padding-left: 0;
  margin-left: 0;
  margin-bottom: 0;
  position: relative;

  @media (min-width: 768px) {
    display: flex;
  }

  li {
    position: relative;

    &:after {
      content: '';
      border-left: 1px solid black;
      height: 10px;
      position: absolute;
      top: 100%;
      left: 50%;
    }

    &:first-child::after {
      left: 0;
    }

    &:last-child::after {
      left: calc(100% - 1px);
    }

    &:nth-child(2) {
      margin-left: 16%;

      @media (min-width: 1200px) {
        margin-left: 18%;
      }
    }

    &:nth-child(3) {
      margin-left: 8%;

      @media (min-width: 1200px) {
        margin-left: 11%;
      }
    }

    &:nth-child(4) {
      margin-left: 33.4%;

      @media (min-width: 1200px) {
        margin-left: 35.7%;
      }
    }

    &:last-child {
      position: absolute;
      right: 0;
    }
  }
`;

const TemperatureNames = styled.ol`
  display: none;
  list-style: none;
  padding-left: 0;
  margin-left: 0;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0 10px 10px;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }

  li {
    text-align: center;

    &:first-child {
      width: 25%;
    }

    &:nth-child(2) {
      width: 11.8%;
    }

    &:nth-child(3) {
      width: 40.8%;
    }

    &:last-child {
      width: 22%;
    }
  }
`;

const Product = styled.div`
  position: relative;
  margin: 0 10px 36px;

  @media (min-width: 768px) {
    background: white;
  }

  .range {
    display: flex;
    justify-content: space-between;
    position: relative;
    color: white;
    font-size: 14px;
    width: 100%;
    padding: 0 4px 10px;
    margin-bottom: 2px;

    @media (max-width: 767px) {
      &:before,
      &:after {
        content: '';
      }

      &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 9px;
        width: calc(100% - 3px);
        border-left: 3px solid white;
        border-right: 3px solid white;
      }

      &:after {
        height: 3px;
        width: 100%;
        position: absolute;
        bottom: 3px;
        left: 3px;
        background: white;
      }
    }

    @media (min-width: 768px) {
      color: black;
      position: absolute;
      padding: 0 4px;
      margin-bottom: 0;
      top: 5px;
      left: 0;
    }

    @media (min-width: 1200px) {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .start,
  .end {
    margin-bottom: 1px;
  }

  .start {
    text-align: left;
  }

  .end {
    text-align: right;
    margin-right: -3px;

    @media (min-width: 768px) {
      margin-right: 0;
    }
  }

  &.cellular-glass {
    @media(max-width: 767px) {
      .range {
        width: calc(79.6% - 3px);
      }
    }

    @media (min-width: 768px) {
      width: 74%;
      margin-left: 3.38%;
    }
  }

  &.xps {
    @media(max-width: 767px) {
      .range {
        width: calc(31% - 3px);
        margin-left: 11.45%;
      }
    }

    @media (min-width: 768px) {
      width: 28%;
      margin-left: 14.2%;
    }
  }

  &.mineral-wool {
    @media(max-width: 767px) {
      .range {
        width: calc(47.5% - 4px);
        margin-left: 55%;
      }
    }

    @media (min-width: 768px) {
      width: 63.7%;
      margin-left: 36.3%;
    }
  }

  &.fiberglass {
    @media(max-width: 767px) {
      .range {
        width: calc(55% - 3px);
        margin-left: 28%;
      }
    }
    
    @media (min-width: 768px) {
      width: 63.4%;
      margin-left: 30%;
    }
  }
`;

const Block = styled.div`
  background: white;
  text-align: center;
  padding: 15px 0;

  @media(min-width:  768px) {
    background: transparent;
    z-index: 1;
    position: relative;
  }

  h6 {
    margin-bottom: 0;

    @media (min-width: 768px) {
      margin-bottom: 5px;
    }

    @media (min-width: 1200px) {
      margin-bottom: 5px;
    }
  }
`;

const TemperatureRange = connectLocation(({ t }) => {
  const ranges = t('mechanical.temp_ranges');
  const names = t('mechanical.temp_names');

  return (
	<TemperatureRangeContainer>
		<TemperatureMilestones>
			{
        ranges.map((range, index) => (
          <li key={index} dangerouslySetInnerHTML={{__html: range.temp_range}} />
        ))
      }
		</TemperatureMilestones>

    <TemperatureDisplay>
      <TemperatureNames>
        {
          names.map((name, index) => (
            <li key={index} dangerouslySetInnerHTML={{__html: name.temp_name}} />
          ))
        }
      </TemperatureNames>

      {/* Cellular Glass */}
      <Product className="cellular-glass">
        <div className="range">
          <div className="start" dangerouslySetInnerHTML={{__html: t('mechanical.cellular_glass.temp_start')}} />

          <div className="end" dangerouslySetInnerHTML={{__html: t('mechanical.cellular_glass.temp_end')}} />
        </div>

        <Block>
          <h6 className="uppercase">{t('mechanical.cellular_glass.heading')}</h6>

          <ChevronLink url="https://www.foamglas.com/en-us/"
            data-track="learn-more"
            data-track-destination="Foamglas Products"
            data-track-element-location="main section CTA"
          >{t('mechanical.cellular_glass.link_text')}</ChevronLink>
        </Block>
      </Product>

      {/* XPS */}
      <Product className="xps">
        <div className="range">
          <div className="start" dangerouslySetInnerHTML={{__html: t('mechanical.xps.temp_start')}} />

          <div className="end" dangerouslySetInnerHTML={{__html: t('mechanical.xps.temp_end')}} />
        </div>

        <Block>
          <h6 className="uppercase">{t('mechanical.xps.heading')}</h6>

          <ChevronLink url="/insulation/commercial/products/mechanical?category=xps-insulation&customer[]=commercial"
            data-track="learn-more"
            data-track-destination="Foamglas Products"
            data-track-element-location="main section CTA"
          >{t('mechanical.xps.link_text')}</ChevronLink>
        </Block>
      </Product>

      {/* FIBERGLASS */}
      <Product className="fiberglass">
        <div className="range">
          <div className="start" dangerouslySetInnerHTML={{__html: t('mechanical.fiberglass.temp_start')}} />

          <div className="end" dangerouslySetInnerHTML={{__html: t('mechanical.fiberglass.temp_end')}} />
        </div>

        <Block>
          <h6 className="uppercase">{t('mechanical.fiberglass.heading')}</h6>

          <ChevronLink url="/insulation/commercial/products/mechanical?category=fiberglass-insulation&customer[]=commercial"
            data-track="learn-more"
            data-track-destination="Foamglas Products"
            data-track-element-location="main section CTA"
          >{t('mechanical.fiberglass.link_text')}</ChevronLink>
        </Block>
      </Product>

      {/* MINNERAL WOOL */}
      <Product className="mineral-wool">
        <div className="range">
          <div className="start" dangerouslySetInnerHTML={{__html: t('mechanical.mineral_wool.temp_start')}} />

          <div className="end" dangerouslySetInnerHTML={{__html: t('mechanical.mineral_wool.temp_end')}} />
        </div>

        <Block>
          <h6 className="uppercase">{t('mechanical.mineral_wool.heading')}</h6>

          <ChevronLink url="/insulation/commercial/products/mechanical?customer=commercial&category[]=mineral-wool-insulation"
            data-track="learn-more"
            data-track-destination="Foamglas Products"
            data-track-element-location="main section CTA"
          >{t('mechanical.mineral_wool.link_text')}</ChevronLink>
        </Block>
      </Product>
    </TemperatureDisplay>
	</TemperatureRangeContainer>
  )
});

export default TemperatureRange;


import IconBase from './icon-base';

const IconDepth = ({ className, fill="#000000", height="15px", hoverColor="#000000", width="24px" }) => (
  <IconBase className={className} fill={fill} height={height} hoverColor={hoverColor} viewBox="0 0 24 15" width={width}>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.991 8.5899C21.6896 8.5899 21.3852 8.52548 21.0972 8.38804L11.9974 4.04444L2.89303 8.38804C2.65819 8.50009 2.40252 8.56689 2.14064 8.58461C1.87875 8.60234 1.61577 8.57065 1.36671 8.49135C0.863716 8.3312 0.447965 7.98685 0.210918 7.53407C-0.02613 7.08128 -0.0650561 6.55714 0.102702 6.07695C0.270461 5.59677 0.631162 5.19987 1.10546 4.97357L11.1021 0.201903C11.3798 0.0691314 11.6861 0 11.9966 0C12.3072 0 12.6135 0.0691314 12.8912 0.201903L22.8878 4.97357C23.2927 5.16471 23.6179 5.48123 23.8101 5.87147C24.0023 6.26172 24.0503 6.70265 23.9463 7.12229C23.8422 7.54193 23.5922 7.91551 23.2371 8.18206C22.882 8.4486 22.4427 8.59237 21.991 8.5899Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.9908 14.7941C21.8361 14.7943 21.6836 14.76 21.5454 14.6938L11.9971 10.1355L2.44588 14.6938C2.32802 14.7536 2.19883 14.7902 2.06592 14.8015C1.93301 14.8129 1.79906 14.7987 1.67197 14.7599C1.54488 14.7211 1.42721 14.6584 1.32589 14.5755C1.22457 14.4926 1.14165 14.3912 1.08201 14.2772C1.02238 14.1632 0.987228 14.039 0.978638 13.9119C0.970047 13.7848 0.988188 13.6573 1.03199 13.537C1.0758 13.4166 1.14438 13.3059 1.2337 13.2113C1.32302 13.1166 1.43127 13.04 1.55209 12.9859L11.5487 8.21422C11.6874 8.14784 11.8405 8.11328 11.9956 8.11328C12.1508 8.11328 12.3038 8.14784 12.4425 8.21422L22.4392 12.9859C22.639 13.0832 22.7989 13.2418 22.8931 13.4362C22.9872 13.6307 23.0104 13.8496 22.9587 14.0581C22.907 14.2665 22.7835 14.4523 22.608 14.5858C22.4325 14.7192 22.2151 14.7926 21.9908 14.7941Z" />
  </IconBase>
);

export default IconDepth;

import { connectLocation } from '../../../location/LocationConnectors';
import styled from '@emotion/styled';
import _ from 'lodash';
import { ui } from '@owenscorning/pcb.alpha';

// Components
import Cta from '../../../OC/oc-cta';
import Card from '../../../OC/oc-card-new';
import RichText from '../../../OC/PageBuilder/RichText';
import CheckboxSelector from '../../../ComponentLibrary/filter-list/oc-filters';
import BimResultBlock from '../../../ComponentLibrary/filter-result-blocks/oc-bim-result-block';

// Helpers
import itemListResponse from './helpers/item_list_response';
import BimProductGroupModal from './helpers/BimProductGroupModal';

const displayRange = (propertyStats) => {
  if (propertyStats.min === propertyStats.max)
    return propertyStats.min;

  return `${propertyStats.min} - ${propertyStats.max}`;
};

const bimAvailableFilters = {
  applications: { label: 'Applications', sorting: "byCount", orderDirection: "desc" },
  depth: { label: 'Panel Thickness (in.)', sorting: "numerical", orderDirection: "asc" },
  thermalResistance: { label: 'Thermal Resistance @ 75 °F (m²K/W)', sorting: "numerical", orderDirection: "asc" },
  compressiveStrength: { label: 'Compressive Strength - minimum (psi)', sorting: "numerical", orderDirection: "asc" },
  coverageArea: { label: 'Coverage Area (ft²)', sorting: "numerical", orderDirection: "asc" },
  density: { label: 'Density - minimum ASTM (kg/m³)', sorting: "numerical", orderDirection: "asc" },
  finish: { label: 'Finish', sorting: "byCount", orderDirection: "desc" },
  edgeType: { label: 'Edge Type', sorting: "byCount", orderDirection: "desc" },
  material: { label: 'Material', sorting: "byCount", orderDirection: "desc" },
  compatibleInstallLocation: { label: 'Compatible Install Location', sorting: "byCount", orderDirection: "desc" },
  compatibleInstallSurface: { label: 'Compatible Install Surface', sorting: "byCount", orderDirection: "desc" },
  standardsAndCompliance: { label: 'Standards And Compliance', sorting: "alphabetical", orderDirection: "asc" },
};

const parseFilter = (filter, orderFunction, orderDirection) => {
  if (filter) {
    const options = Object.keys(filter).map((filterKey) => {
      return { value: filterKey, displayName: filterKey }
    })
    return {
      options: _.orderBy(options, orderFunction, orderDirection),
      filterCounts: filter,
    };
  }

  return { options: [] }
}

const generateFilters = (bimObjects, filterCounts, enabledFilters) => {
  return (
    (!_.isEmpty(enabledFilters) && !_.isEmpty(filterCounts) && !_.isEmpty(bimObjects)) ? Object.fromEntries(
      (enabledFilters.enabledFilters || enabledFilters).map(filterKey => {
        let orderFunction;
        switch (bimAvailableFilters[filterKey].sorting) {
          case "byCount":
            orderFunction = ((object) => filterCounts[filterKey][object.value]);
            break;
          case "numerical":
            orderFunction = ((object) => parseFloat(object.value));
            break;
          default:
            orderFunction = ((object) => object.value.trim());
        }
        return [
          filterKey,
          {
            component: CheckboxSelector,
            displayLimit: 5,
            displayName: bimAvailableFilters[filterKey].label,
            ...parseFilter(filterCounts[filterKey], orderFunction, [bimAvailableFilters[filterKey].orderDirection]),
            valueType: 'multiple',
          }
        ]
      })
    ) : null
  )
};
const searchFields = ['label', 'material', 'compressiveStrengthStats', 'depthStats', 'thermalResistanceStats', 'coverageAreaStats'];

const bimResponse = (bimData, enabledFilters) => {
  const productGroups = bimData.product_groups || [];
  const filterCounts  = bimData.filter_counts || [];
  const bimObjects = productGroups.map((productGroup) => {
    return {
      id: productGroup.id,
      availableProducts: productGroup.total_items,
      label: `${productGroup.label} ${productGroup.productSeries[0].key}`,
      material: productGroup.material[0].key,
      compressiveStrengthStats: { ...productGroup.compressiveStrengthStats },
      depthStats: { ...productGroup.depthStats },
      thermalResistanceStats: { ...productGroup.thermalResistanceStats },
      coverageAreaStats: { ...productGroup.coverageAreaStats },
      image: productGroup.productImage?.length > 0 && productGroup.productImage[0].key,
      pdpLink: productGroup.pdpLink,
    };
  });


  const bimModalBody = (productGroup) => {
    return(
      <BimProductGroupModal productGroup={productGroup}/>
    );
  };

  const handleProductGroupSelection = (event, productGroup) => {
    event.preventDefault();
    Board.modal.open({
      body: () => bimModalBody(productGroup),
    });
  };

  return itemListResponse({
    items: bimObjects,
    filters: generateFilters(bimObjects, filterCounts, enabledFilters),
    Component: ({aspectRatio, ...productGroup}) => {
      return (
        <BimResultBlock
          ctaOnClick={(event) => handleProductGroupSelection(event, productGroup)}
          {...productGroup}
        />
      );
    },
    searchFields,
    enableSearch: false,
  });
};

export default {
  ItemList: {
    availableIn: ['www.owenscorning.com'],
    name: 'BIM',
    settings: {
      serverSideCalculation: true
    },
    meta: {
      bimEnabledFilters: ui`ChoicesDraggable`.of(
        Object.fromEntries(Object.keys(bimAvailableFilters).map(k => [k, bimAvailableFilters[k].label]))
      )({
        label: 'Filter Sections',
        default: Object.keys(bimAvailableFilters),
      }),
    },
    view: (data = null) => {
      // Note: Not using the meta just yet, since we are not allowing filtering and extra
      // configuration on the page builder for MVP.
      const { data: items, meta: { parameters, filters } } = (data || { meta: {} });
      const { bimEnabledFilters } = parameters || {};

      return bimResponse((items || {}), (bimEnabledFilters || Object.keys(bimAvailableFilters)));
    }
  },
};

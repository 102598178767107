import React, { useReducer } from 'react';
import { jsx, css } from '@emotion/react';
import { connectLocation } from '../../location/LocationConnectors';


const fieldContainer = css`
  display: flex;
  flex-direction: row;
  align-items: left;

  input {
    width: auto !important;
  }
`

const rightMargin = css`
  margin-right: -3.5rem;
`;

const labelStyle = css`
  font-size: 12px;
  font-weight: lighter;
`;

function SplitUSPhone(props) {
  const reducer = (state, action) => {
    const phoneNumberComponents = state.value.split('##');
    const areaCode = phoneNumberComponents[0];
    const number = phoneNumberComponents[1];

    const newValue = { value: state.value };
    switch (action.type) {
      case 'areaCode':
        newValue.value = action.value + '##' + number;
        break;
      case 'number':
        newValue.value = areaCode + '##' + action.value;
        break;
      default:
        throw new Error();
    }
    props.onChange(newValue.value.replace('##',' ').trim());
    return newValue;
  };
  const [state, dispatch] = useReducer(reducer, {value: props.value||'##'});
  const areaCodeId = `${props.id}_areaCode`;
  const phoneNumberId = `${props.id}_phoneNumber`;

  return (
    <div css={fieldContainer}>
      <div css={rightMargin}>
        <input type="tel" id={areaCodeId} size="6" required={props.required}
               onChange={(e) => dispatch({type: 'areaCode', value: e.target.value})} />
        <span className="seperator" aria-hidden="true">
            &nbsp;&mdash;&nbsp;
        </span>
        <label htmlFor={areaCodeId}>
          <span css={labelStyle}> { props.t('splitUsPhone.areaCode') } </span>
        </label>
      </div>
      <div>
        <input type="tel" id={phoneNumberId} size="15" required={props.required}
               onChange={(e) => dispatch({type: 'number', value: e.target.value})} />
        <label htmlFor={phoneNumberId}>
          <span css={labelStyle}> { props.t('splitUsPhone.phoneNumber') } </span>
        </label>
      </div>
    </div>
  );
}

export default connectLocation(SplitUSPhone);

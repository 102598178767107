import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';

import PDFRequester from './PDFRequester';
import SelectedItems from './SelectedItems';

const Container = styled.div`
  margin-top: 20px;
`;

const Form = styled.div`
  border: 1px solid #d7d7d7;
  padding: 0px 15px 15px 15px;
`;

export default function SheetActions({
  sheetName,
  contractor,
  project,
  categories,
  selectedIds,
  isSelected,
  optionIdentifier,
  specifications,
}) {
  return (
    <Container>
      <h3>Create Submittal Sheet</h3>
      <Form className="container">
        <SelectedItems
          categories={categories}
          isSelected={isSelected}
          optionIdentifier={optionIdentifier}
        />

        <div>
          <div>
            <p>Download your custom submittal sheet below:</p>
          </div>
          <PDFRequester
            sheetName={sheetName}
            contractor={contractor}
            project={project}
            selectedIds={selectedIds}
            specifications={specifications}
          />
        </div>
      </Form>
    </Container>
  );
}

SheetActions.propTypes = {
  sheetName: PropTypes.string.isRequired,
  contractor: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedIds: PropTypes.array.isRequired,
  isSelected: PropTypes.func.isRequired,
  optionIdentifier: PropTypes.string.isRequired,
  specifications: PropTypes.array.isRequired,
};

import {
  PROPINK_FIBERGLASS_CALC_SET_CALCULATIONS,
  PROPINK_FIBERGLASS_CALC_SET_PRICE,
  PROPINK_FIBERGLASS_CALC_SET_RVALUE
} from '../Actions.jsx';
import {cloneDeep, set, has, get} from 'lodash';
import {connect} from 'react-redux';
import {fiberglassData} from './static';

export const fiberglassCalculatorReducer = (state = {data: fiberglassData}, action) => {
  let newState = cloneDeep(state);
  switch(action.type) {

  case PROPINK_FIBERGLASS_CALC_SET_PRICE:
    if(has(action, 'who')) {
      set(newState, ['prices', action.who], action.price);
      return newState;
    }
    if(has(action, 'prices')) {
      set(newState, 'prices', action.prices);
      return newState;
    }
    break;

  case PROPINK_FIBERGLASS_CALC_SET_CALCULATIONS:
    if(has(action, 'what') && has(action, 'who')) {
      set(newState, ['calculations', action.what, action.who], action.calculation);
      return newState;
    }
    if(has(action, 'calculations')) {
      set(newState, 'calculations', action.calculations);
      return newState;
    }
    break;
  case PROPINK_FIBERGLASS_CALC_SET_RVALUE:
    set(newState, 'rvalue', action.rvalue);
    return newState;
  }

  return state;
}

export const setPrice = (who, price) => ({type: PROPINK_FIBERGLASS_CALC_SET_PRICE, who, price});
export const setPrices = (prices) => ({type: PROPINK_FIBERGLASS_CALC_SET_PRICE, prices});
export const setCalculation = (what, who, calculation) => ({type: PROPINK_FIBERGLASS_CALC_SET_PRICE, what, calculation});
export const setCalculations = (calculations) => ({type: PROPINK_FIBERGLASS_CALC_SET_CALCULATIONS, calculations});
export const setRValue = (rvalue) => ({type: PROPINK_FIBERGLASS_CALC_SET_RVALUE, rvalue})

const mapStateToProps = ({fiberglassCalculator}) => ({
    prices: get(fiberglassCalculator, 'prices', {}),
    data: get(fiberglassCalculator, 'data', {}),
    calculations: get(fiberglassCalculator, 'calculations', {}),
    rvalue: get(fiberglassCalculator, 'rvalue', 'R30')
});

export const calculatorConnector = connect(
  mapStateToProps,
  {setPrice, setPrices, setCalculation, setCalculations, setRValue}
)
export const dataConnector = connect(
  mapStateToProps
)

import { define, ui, s } from '@owenscorning/pcb.alpha';

export default define`Social Media Share Banner`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/SocialMediaShareBanner`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value: { channels = {}, content = {}, background = { color: 'dark' } } = {} }) => (
    <UI.Row back={ { dark: 'black', light: 'white' }[ background.color ] } padding-2 gap-2 center-xy >
      { content.text && <UI.Box>{ content.text }:</UI.Box> }
      <UI.Row gap-3 >
        { channels.facebook && <UI.Share.Facebook message={ content.shareMessage } /> }
        { channels.twitter && <UI.Share.Twitter message={ content.shareMessage } /> }
        { channels.linkedin && <UI.Share.Linkedin message={ content.shareMessage } /> }
        { channels.pinterest && <UI.Share.Pinterest message={ content.shareMessage } /> }
      </UI.Row>
    </UI.Row>
  ),
  edit: {
    channels: ui`Form`.of({
      facebook: ui`Switch`({ label: 'Facebook', default: true }),
      twitter: ui`Switch`({ label: 'Twitter', default: true }),
      pinterest: ui`Switch`({ label: 'Pinterest', default: true }),
      linkedin: ui`Switch`({ label: 'Linkedin', default: true })
    })({ label: 'Select Social Media Channels' }),
    background: ui`Form`.of({
      color: ui`Choices`.of({
        dark: 'Black',
        light: 'White'
      })({
        label: 'Select the Background Color',
        default: 'dark'
      })
    })({ label: 'Background' }),
    content: ui`Form`.of({
      text: ui`Text`({ label: 'Main Heading', default: 'Share this page on' }),
      shareMessage: ui`Text`({ label: 'Default Share Message', default: `Check out what I found on ${PB_SITE_NAME}!` }),
      [s._]: ui`Tip`.of('The default share message is what will appear in the content share message when a user clicks to share on one of the social channels listed.')
    })({ label: 'Content' })
  },
});

import { define, ui, when, s, select } from '@owenscorning/pcb.alpha';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { useState } from 'react';
import _ from 'lodash';

import Data from '../../../Data';

import { expandRef, unwrapRef, wrapRef } from '../../../../../data';
import { BasicContent } from '../../../../OC/PageBuilder/BasicContent.jsx';
import Card from '../../../../OC/PageBuilder/Card.jsx';
import MediaObject from '../../../../OC/PageBuilder/MediaObject.jsx';

const Renderer = ({ settings, copy, categories, data }) => {
  const [items, setItems] = useState([]);
  useDeepCompareEffectNoCheck(() => {
    // TODO: remove me after a bit, data.reference being nil is an old bit of data pre PB-295
    let reference = data?.reference;
    if (typeof(reference) === 'undefined' && data?.dataset && data?.parameters) {
      reference = wrapRef('Cms::DataProvider', { type: 'CategoryFeatures', dataset: data.dataset, parameters: data.parameters[data.dataset], language: Board.build.language })
    }

    // TODO: it also seems we could remove this bit, if we can remove the top-level "dataset" and "parameters" and make part
    // of Data/Parameters
    const [type, value] = reference ? unwrapRef(reference) : []
    if (value?.dataset !== data?.dataset) {
      reference = null;
    }

    const adapter = Data.for('CategoryFeatures')[data?.dataset];
    if (adapter) {
      Promise.all(categories.map(async (c) => {
        // TODO: remove me after a bit, data.reference being nil is an old bit of data pre PB-295
        let reference = c.reference;
        if (typeof(reference) === 'undefined' && data?.dataset && c.parameters[data.dataset]) {
          reference = wrapRef('Cms::DataProvider', { type: 'CategoryFeatures', dataset: data.dataset, parameters: c.parameters[data.dataset], language: Board.build.language })
        }

        // TODO: it also seems we could remove this bit, if we can remove the top-level "dataset" and "parameters" and make part
        // of Data/Parameters
        const [type, value] = reference ? unwrapRef(reference) : []
        if (value?.dataset !== data?.dataset) {
          reference = null;
        }

        const results = await expandRef(reference);
        return adapter.view(results)
      })).then(r => setItems(r))
    }
  }, [data, categories]);

  return (
    <>
      <BasicContent { ...copy } />
      <UI.Row
        gap-3 fits={ settings?.itemsPerRow || 3 }
        tablet-fits={ settings?.itemsPerRowTablet || 2 }
        mobile-fits-1
      >
        {
          categories?.map((category, index) => (
            <div key={index}>
              <UI.Box margin-0-0-3 ><MediaObject aspectRatio={ settings?.aspectRatio } {...category.mediaObject} /></UI.Box>
              {
                items && items[index] && items[index].map((item, index) => {
                  item = index === 0 ? _.omit(item, ['text']) : _.omit(item, ['text', 'linkText']);
                  return (
                    <>
                      { index === 0 ?
                        <>
                          <Card
                            aspectRatio={ settings?.aspectRatioCard }
                            { ...item }
                            key={index}
                          />
                          <UI.Separator.Small />
                        </>
                        :
                        <>
                          <MediaObject imgSize="icon" {...item} icon={`fa:${item.icon}`} />
                          <UI.Separator.Small />
                        </>
                      }
                    </>
                  )
                })
              }
            </div>
          ))
        }
      </UI.Row>
    </>
  )
}

const sizesHash = {
  '1': ['Square (1.0) cards', '144x144px'],
  '2': ['Wide (2.0) cards', '144x72px'],
  none: undefined,
  '2.5': ['Extra Wide (2.5) cards', '144x58'],
  '0.77': ['Tall (0.77) cards', '144x188px'],
  custom: ['Custom cards', '144px']
};

export default define`Category Feature`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/CategoryFeatures`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value }) => <Renderer { ...value } />,
  edit: {
    copy: ui`Content/Basic`,
    data: ui`Form`.of({
      dataset: ui`Data/Choices`.of('CategoryFeatures')
    })({
      label: 'Select Details',
    }),
    imageSizeInfo: ui`ImageSizeInfo`(
      {
        moduleName: 'Category Features',
        sizesHash: sizesHash,
        selectedSize: select`../settings/aspectRatio`
      },
    ),
    categories: ui`List`.of({
      mediaObject: ui`MediaObject`({ label: 'Media Object' }),
      [s._]: ui`Separator/Small`,
      parameters: ui`Data/Parameters`.of('CategoryFeatures')({ dataset: select`../../../data/dataset` }),
    })({
      min: 1, max: 8,
      label: 'Feature Categories',
      singular: 'Category',
      title: 'mediaObject/heading',
    }),
    settings: ui`Form`.of({
      itemsPerRow: ui`Choices`.of(['2', '3', '4'])({
        label: 'Number of items per row (at desktop)',
        default: '3'
      }),
      itemsPerRowTablet: ui`Choices`.of(['2', '3'])({
        label: 'Number of items per row (at tablet)',
        default: '2'
      }),
      aspectRatio: ui`AspectRatio`({ label: 'Aspect Ratio of Media Object Header', default: { selection: '1' } }),
      aspectRatioCard: ui`AspectRatio`({ label: 'Aspect Ratio of Card Header' }),
    })({
      label: 'Layout'
    }),
  },
});

// Library Imports
import { useCallback } from 'react';
import styled from '@emotion/styled';

// Component Imports
import RichText from './RichText';
import CTAButton from '../../CTAButton';

// Styled Components
const StatsHeaderRow = styled.div`
  background-color: black;
  padding: 15px 20px;

  h3 {
    color: white;
    margin: 0;
  }

  span {
    display: block;
    font-family: 'OCRoboto', sans-serif;
    font-size: 16px;
    text-transform: lowercase;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  ul {
    list-style-type: none;
    margin-top: 10px;
    padding-left: 0;
  }
`;

const Grid = styled.div`
  display: grid;

  @media screen and (min-width: 768px) {
    column-gap: 20px;
    grid-template-columns: ${props => props.fullWidth ? "100%" : "50% 50%"};
  }
`;

const StatsContainer = styled.div`
  display: flex;

  span {
    display: block;
    font-size: 62px;
    line-height: 62px;
    margin-bottom: 10px;
    margin-left: -2px;
  }
`;

const StatsBlock = styled.li`
  list-style-type: none;
  padding-bottom: 25px;
  padding-left: 12px;
  padding-top: 25px;

  &.add--border {
    border-top: 1px solid black;
  }
`;

const StatsLabel = styled.h3`
  display: flex;

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    margin-left: 5px;

    i.fa.fa-info-circle {
      color: gray;
    }
  }
`;
const StatsValueContainer = styled.div`
  color: #D40F7D;
  font-family: 'Oswald', sans-serif;
  font-size: 24px;
  line-height: 24px;
  padding-right: 18px;
  text-transform: uppercase;
`;

const StatsLabelContainer = styled.div`
  font-size: 18px;
  line-height: 18px;
  padding-right: 0;
  padding-top: 8px;
  text-transform: uppercase;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  padding-top: 10px;

  @media screen and (min-width: 768px) {
    max-width: 80%;
    min-width: 80%;
  }

  .oc-cta-button {
    margin-bottom: 10px;
  }
`;

const Container = styled.div`
  @media screen and (max-width: 768px) {
    margin: auto;
    max-width: 400px;
  }
`;

const ModalContainer = styled.div`
  align-items: start;
  display: flex;
  justify-content: space-around;
  padding-top: 40px;

  @media screen and (min-width: 768px) {}
`;

const ModalContentContainer = styled.div`
  flex: 2;

  h3 {
    margin: 0;
  }

  p {
    margin-top: 0;
  }

  .css-1j88btx-Wrapper-Wrapper.e14zjuty0 {
    margin-top: 0;
  }

  @media screen and (min-width: 768px) {
    margin-left: 40px;
  }
`;

const ImageContainer = styled.div`
  border: none;
  flex: 1;

  img {
    height: 113.33px;
    width: 113.33px;

    @media screen and (min-width: 768px) {
      height: 163.33px;
      width: 163.33px;
    }
  }

  @media screen and (min-width: 768px) {
    margin-top: 0;
    padding-left: 45px;
  }
`;

const ModalBody = ({ data }) => {
  let imageDefault = "https://imagecdn.owenscorning.com/ocimages/image/upload/v1580239950/roofing/nail-zone/icon-pull-through.svg";

  return (
    <ModalContainer>
      <ModalContentContainer>
        <h3>
          <RichText content={data?.heading || ''} />
        </h3>
        <RichText content={data?.body || ''} />
      </ModalContentContainer>

      <ImageContainer>
        <img src={data?.image?.file || imageDefault} alt={data?.image?.alt} />
      </ImageContainer>
    </ModalContainer>
  );
};

const Performance = ({
  mainHeading = {},
  columnStats = [
    {
      statsHeaderRow: {},
      performanceStat: [{}],
    },
  ],
  parent
}) => {
  const handleClick = useCallback(({ data }) => {
    Board?.modal?.open({ body: <ModalBody data={data} />, });
  });

  return (
    <Container>
      <div>
        <h2>{mainHeading}</h2>
      </div>

      <Grid fullWidth={parent == "ModuleSet"}>
        {columnStats.map(( stat, x ) => (
          <Column key={x}>
            <StatsHeaderRow>
              <h3>
                {stat?.statsHeaderRow?.header}
                <span> {stat?.statsHeaderRow?.subHeader}</span>
              </h3>
            </StatsHeaderRow>

            <ul>
              {stat?.performanceStat?.map(( element, y ) => (
                <StatsBlock
                  key={'element' + y}
                  className={y != 0 ? 'add--border' : ''}
                >
                  {element?.prehead &&
                    <span>{element?.prehead}</span>
                  }
                  <StatsContainer>
                    <StatsValueContainer>
                      <span>{element?.value}X</span>{element?.posthead}
                    </StatsValueContainer>
                    <StatsLabelContainer>
                      <StatsLabel>
                        {element?.subject}
                        {element?.hasModal?.options == 'Yes' && (
                          <button
                            key={y}
                            onClick={() => handleClick({ data: element?.modal })}
                            data-target="modal-blow-through"
                          >
                            <i class="fa fa-info-circle fa-lg"></i>
                          </button>
                        )}
                      </StatsLabel>
                      {element?.scope}
                    </StatsLabelContainer>
                  </StatsContainer>
                </StatsBlock>
              ))}
            </ul>

            <ButtonContainer>
              <CTAButton
                href={stat?.explore?.link}
                class="oc-cta-button"
                data-track="learn-more"
                data-track-element-location="main section CTA"
                data-track-destination={stat?.explore?.buttonLabel}
              >
                {stat?.explore?.buttonLabel}
              </CTAButton>

              <CTAButton
                href={stat?.pdf?.link}
                class="oc-cta-button transparent"
                data-track="file-download"
                data-track-doc-type="Brochure"
                data-track-file-name="Performance Brochure"
                target="_blank"
              >
                <i class="fa fa-file-pdf-o icon"></i>
                {stat?.pdf?.buttonLabel}
              </CTAButton>
            </ButtonContainer>
          </Column>
        ))}
      </Grid>
    </Container>
  );
};

export default Performance;

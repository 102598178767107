import _ from 'lodash';

const truncateString = (text, limit) => {
  if (text.length <= limit) return text;

  let sliceIndex = limit;
  while (sliceIndex < text.length && /\b/.test(text[sliceIndex])) sliceIndex++;
  return text.substr(0, sliceIndex) + "\u2026";
};

const truncateDelta = (text, limit) => {
  let currentLength = 0;
  return {
    ops: text.ops.reduce((c, v) => {
      if (currentLength < limit) {
        if (_.isString(v.insert)) {
          const truncated = truncateString(v.insert, limit - currentLength);
          currentLength += truncated.length;
          c.push({ ...v, insert: truncated });
        } else {
          c.push(v);
        }
      }
      return c;
    }, []),
  };
};

export const truncateText = (text, limit = 320) => {
  if (_.isString(text)) return truncateString(text, limit);
  if (_.isPlainObject(text) && Array.isArray(text.ops)) return truncateDelta(text, limit);
  return text;
};

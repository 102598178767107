import { define, ui } from '@owenscorning/pcb.alpha';
import { Edit } from "../Page/TabbedContent";

export default define`Tabbed Content`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/TabbedContent`,
  view: ({ value }) => {
    return <UI.Modules.Page.TabbedContent value={ value } />
  },
  edit: Edit({ size: { default: "m" } })
});

import { useState } from 'react';
import { jsx, css } from '@emotion/react';

import { ui, select } from '@owenscorning/pcb.alpha';

export default () => {
  if (!Board.sidebar) Board.Set({ sidebar: 'builder' });
  return <div css={ css`
    padding: 30px 32px 0px 24px;
    width: 444px;
    height: 100%;
  ` } >
    <div css={ css`
      width: 100%;
      height: 100%;
      background-color: #FFF;
      filter: drop-shadow(0px 12px 15px rgba(0, 0, 0, 0.2));
    ` } >
      <Subschema>
        {
          ui`Tabs`.of({
            builder: {
              label: 'Builder',
              icon: 'file-text',
              pane: select`~definition/sidebar/builder`
            },
            settings: {
              label: 'Settings',
              icon: 'cogs',
              pane: select`~definition/sidebar/settings`
            }
          })({
            selected: select`~sidebar`,
            onSelect: (current) => Board.Set({ sidebar: current }, () => Board.onTab(current)),
            paneStyles: css` height: calc(100vh - 266px); `
          })
        }
      </Subschema>
    </div>
  </div>
};

import _ from 'lodash';
import { css } from '@emotion/react';
export default ({ item={}, onSubmit }) => {
  const All = UI.User.Groups[0].value;
  const Default = { show_for: [ All ], except_for: [] };

  if (_.isEmpty(item)) item = {};

  const { __visibility = Default } = item;

  const Icon = UI.List.Visibility.Icons[
    _.intersection(__visibility.show_for, [ All ]).length && _.isEmpty(__visibility.except_for) ? 'All'
      : _.intersection(__visibility.except_for, [ All ]).length || ( _.isEmpty(__visibility.show_for) && _.isEmpty(__visibility.except_for) )
        ? 'None' : 'Partial'
  ];

  return <UI.Icon
    image={ <Icon css={ css` display: block; ` } /> }
    action={
      () => Board.modal.open(
        'visibility',
        (visibility) => onSubmit(_.set(item, '__visibility', visibility)),
        _.cloneDeep( item.__visibility || Default )
      )
    }
  />;
};

import { css } from '@emotion/react';
import styled from '@emotion/styled';

// Components
import Cta from './oc-cta.jsx';
import Image from './oc-image';
import Picture from './oc-picture';
import { useInView } from "react-intersection-observer";
import anime from "animejs";
import { useEffect, useRef } from "react";
import AnimateTextContainer from "./shared/oc-animated-text-container";
import AnimatedAttentionBarWrapper from "./shared/oc-animate-wrapper";

// Utilities
import placeholder_url, { is_placeholder_url } from '../../placeholder';

// Styled Components
const AttentionBarWithImageContainer = styled.div`
  background: black;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  .img {
    img {
      display: block;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .content {
    color: #FFFFFF;
    padding: 24px;
    position: relative;

    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6,
    .prehead {
      color: white !important;
    }

    a {
      color: #FFFFFF;

      &:hover {
        color: #D30E7D;
      }
    }
  }

  ${props => props.ratio == '1-1' && css`
    .img, .content {
      flex-basis: 50%;
    }
  `};

  ${props => props.ratio == '1-2' && css`
    .img {
      flex-basis: 33.333%;
    }

    .content {
      flex-basis: 66.666%;
    }
  `};
`;

const imgPlaceholder = {
  "1-2": placeholder_url(570,320),
  "1-1": placeholder_url(570,274)
}

const AttentionBarWithImage = ({ children, img, alt, ratio = '1-1', className, crop, animationProps }) => {
  const {animate, flashAnimRef, slideAnimRef } = animationProps || {animate: false, flashAnimRef: useRef(null), slideAnimRef: useRef(null)};
  let image = img ? img : imgPlaceholder[ratio];
  if (!is_placeholder_url(image) && crop) {
    image = Picture.Transform(image, { crop: "crop", ...crop })
  }
  const [inViewRef, inView] = useInView({
    threshold: 1,
    triggerOnce: true, // set this flag to false if you want to willAnimate the text on every scroll into view
  });

  const animationTimeline = anime.timeline({
    autoplay: false,
  });

  useEffect(() => {
    if (animate) {
      AnimateTextContainer(inView, slideAnimRef.current, animationTimeline);
    }
  }, [inView, animate]);

  return (
    <AnimatedAttentionBarWrapper
      wrapperComponent={AttentionBarWithImageContainer}
      wrapperProps={{
        ratio: ratio,
        className: `${className} flash-bar-image`
      }}
      target={flashAnimRef}
      animate={animate}
      reference={inViewRef}
      inView={inView}
      animationTimeline={animationTimeline}
    >
      <div className="img">
        <Image src={image} alt={alt} />
      </div>

      <div className="content">
        {children}
      </div>
    </AnimatedAttentionBarWrapper>
  )
}

export default AttentionBarWithImage;

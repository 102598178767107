import { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import Theme from '../../../themes';
import _ from 'lodash';

const Tab = ({ pane, selected, icon, label, onOpen }) => (
  <div
    css={css`
      color: ${ pane == selected ? Theme.colors.brand : '#6D6D6D' };
      cursor: pointer;
      flex-grow: 1;
      font-size: 14px;
      padding: 16px;
      position: relative;
      text-align: center;
      transition: all 0.3s;
      user-select: none;

      i {
        font-size: 16px;
        padding-right: 6px;
      }

      &:after {
        background-color: ${ pane == selected ? Theme.colors.brand : '#6D6D6D' };
        bottom: 0px;
        content: '';
        height: ${ pane == selected ? 4 : 0 }px;
        left: 0px;
        position: absolute;
        transition: all 0.1s;
        width: 100%;
      }

      &:hover {
        &:after { height: 4px !important; }
      }
    `}
    onClick={ () => onOpen(pane) }
  >
    { icon && <UI.Icon type={ icon } /> } { label }
  </div>
);

export default ({ contents, Subschema, selected, onSelect, paneStyles }) => {
  const controlled = selected != undefined;
  const [ current, setCurrent ] = useState(_.keys(contents)[0]);
  useEffect(() => { !controlled && onSelect?.(current); }, [ current ]);
  return <div>
    <div css={ css` display: flex; height: 50px; ` } >{
      _.map(contents, (tab, key) => (
        <Tab
          key={ key }
          icon={ tab.icon }
          label={ tab.label }
          pane={ key }
          selected={ controlled ? selected : current }
          onOpen={ controlled ? onSelect : setCurrent }
        />
      ))
    }</div>
    <div
      id={"sidebar-pane"}
      css={ css`
      flex-grow: 1;
      overflow-y: auto;
      min-height: 104px;
      padding: 16px 16px 24px;
      ${ paneStyles }
    ` } >{
      _.map(contents, (tab, key) => (
        <div key={ key } css={ css` display: ${ key == ( controlled ? selected : current ) ? 'block' : 'none' }; ` } >
          <Subschema field={ key } >{ tab.pane }</Subschema>
        </div>
      ))
    }</div>
  </div>;
};

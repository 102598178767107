import produce from "immer";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const SET_DEVICE = 'CMS/editor/SET_DEVICE';

// shape of redux cms state:
/*
{
  editor: { device: undefined },
}
 */

const editorReducer = {
  editor: produce((draft, action) => {
    switch (action.type) {
      case SET_DEVICE: {
        draft.device = action.device;
        break;
      }
    }
  }, { device: undefined })
};

export default editorReducer;

const editorActionCreators = {
  setDevice: (device) => ({
    type: SET_DEVICE,
    device
  }),
}

export const editorConnector = connect(
  (state) => ({
    device: state.cms.editor.device
  }),
  (dispatch) => bindActionCreators(editorActionCreators, dispatch)
);


import styled from '@emotion/styled';
import { css } from '@emotion/css';
import NumberFormat from "react-number-format";

// Components
import Table from '../../ComponentLibrary/oc-table';
import {connectLocation} from "../../location/LocationConnectors";
import Image from "../../ComponentLibrary/oc-image";
import Text from '../../OC/oc-text';

const EstHeadingContainer = styled.div`
	margin-bottom: 5px;
  margin-top: 10px;
  
  .header {
    font-size: 31px;
    color: #000;
    line-height: 30px;
  }
`;

const EstimateProjectTime = styled.div`
  color: black;
`;

const PinkText = styled.css`
  color: #d40f7d;
`;

const InfoContainer = styled.div`
  margin-top: 40px;
`;


const PinkExpandingContainer = styled.div`
  margin-top: 50px;
	display: flex;
	flex-direction: column;
	gap: 24px;

	@media(min-width: 768px) {
		flex-direction: row;
	}
`;

const PinkExpandingImage = styled(Image)`
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  background-size: 250px;
	padding: 15px 15px 165px;
	width: 100%;
  outline: 0px;

	@media(min-width: 768px) {
		width: 20%;
		background-size: 150px;
		padding: 15px 15px 100px;
	}

	@media(min-width: 1200px) {
		background-size: 250px;
	}
`;

const PinkExpandingHeading = styled.div`
	width: 100%;
  .header {
    font-size: 20px;
    color: #000;
    line-height: 30px;
  }

	@media(min-width: 768px) {
		width: 80%
	}
`;

const CalculatorResults = connectLocation(({
  t,
	targetTotalInchesOfInsulation, 
	inchesOfInsulationNeeded, 
	bagsOfAttiCatNeeded,
  measurementSystem
}) => (
	<>
		<EstHeadingContainer>
      <span className="header"><strong>{t('atticat.estimates_heading')}</strong></span><br/>
		</EstHeadingContainer>

    <Table striped>
      <tbody>
      <tr>
        <td><strong>{ measurementSystem == 'metric' ? t('atticat.target_total_centimeters') : t('atticat.target_total_inches') }</strong></td>
        <td><PinkText><strong>{targetTotalInchesOfInsulation ? <><NumberFormat decimalScale={2} thousandSeparator={true} displayType="text" value={targetTotalInchesOfInsulation}/></> : '<x>'} { measurementSystem == 'metric' ? t('atticat.target_total_inches_unit_centimeter') : t('atticat.target_total_inches_unit_inch') }</strong></PinkText><br/>{t('atticat.target_total_inches_unit_helper')}</td>
      </tr>
      {/* <tr>
        <td><strong>{ measurementSystem == 'metric' ? t('atticat.centimeters_inculation_needed') : t('atticat.inches_inculation_needed') }</strong></td>
        <td><PinkText><strong>{inchesOfInsulationNeeded ? <><NumberFormat decimalScale={2} thousandSeparator={true} displayType="text" value={inchesOfInsulationNeeded}/></> : '<x>'} { measurementSystem == 'metric' ? t('atticat.target_total_inches_unit_centimeter') : t('atticat.target_total_inches_unit_inch') }</strong></PinkText><br/>{t('atticat.inches_inculation_needed_unit_helper')}</td>
      </tr> */}
      <tr>
        <td><strong>{t('atticat.bags_of_atticat')}</strong></td>
        <td><PinkText><strong>{bagsOfAttiCatNeeded ? <><NumberFormat decimalScale={0} thousandSeparator={true} displayType="text" value={bagsOfAttiCatNeeded}/></> : t('atticat.number_of_bags_helper')} {t('atticat.number_of_bags')}</strong></PinkText></td>
      </tr>
      </tbody>
    </Table>
	</>
));

export default CalculatorResults;

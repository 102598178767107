const filterDigits = phone => phone?.replace(/\D/g, '') || '';

const prependInternational = phone => {
  if (filterDigits(phone).length > 10) {
    // add the + for international numbers
    return `+${phone}`;
  }
  return phone;
};

const formatUsPhone = phone => {
  const numbers = filterDigits(phone);
  const sectioned = numbers?.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (sectioned) {
    return `(${sectioned[1]}) ${sectioned[2]}-${sectioned[3]}`;
  }
  return prependInternational(numbers);
};

const formatGenericPhone = phone => {
  const numbers = filterDigits(phone);
  return prependInternational(numbers);
};

export const formatPhone = (phone, localeCode) => {
  const [, cCode] = localeCode.split('-');
  switch (cCode) {
    case 'US':
    case 'CA':
      return formatUsPhone(phone);
    default:
      return formatGenericPhone(phone);
  }
};

export const phoneHref = phone =>
  `tel:${prependInternational(filterDigits(phone))}`;

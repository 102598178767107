import { define } from '@owenscorning/pcb.alpha';
import { Children } from 'react';
import Calendar from "react-multi-date-picker";
import styled from '@emotion/styled';
import moment from 'moment';

const Format = {
  In: (date, format) => {
    if (date) {
      return format
        ? moment(date, Format[format]).format(Format[format])
        : moment(date, Format.ISO.Time)?.format(Format.Local);
    }
    return '';
  },
  Out: (date) => date?.format(Format.ISO.Date) || '',
  Local: moment.localeData().longDateFormat('L').toUpperCase(),
  ISO: {
    Date: 'YYYY-MM-DDT12:00:00Z',
    Time: 'YYYY-MM-DDTHH:mm:ssZ',
  },
  ISODateOnly: 'YYYY-MM-DD'
};

const Container = styled.div`
  .rmdp-container {
    display: block !important;
    & > div:first-child {
      position: relative;
      z-index: 1000;
    }

    & > div:nth-child(2) {
      z-index: 999 !important;
    }
  }

  .rmdp-wrapper {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.198596);
    border-radius: 3px;
    border: 1px solid ${ () => UI.Theme.colors.greyTint1 };
  }

  .rmdp-calendar {
    padding: 0px;
    border-radius: 0px;
  }

  .rmdp-header-values {
    color: ${ () => UI.Theme.colors.grey };
    font-weight: bold;
    & span:first-child {
      padding-left: 0px;
    }
  }

  .rmdp-week-day {
    color: ${ () => UI.Theme.colors.grey };
    font-weight: bold;
  }

  .rmdp-week {
    background-color: transparent;
    &:nth-child(odd) { background-color: ${ () => UI.Theme.colors.greyBackWeb }; }
  }

  .rmdp-day {
    display: flex;
    justify-content: center;

    span {
      position: static;
      background-color: transparent !important;
      color: inherit !important;
      box-shadow: none !important;
    }

    &:not(.rmdp-day-hidden):hover {
      border-radius: 3px;
      background-color: ${ () => UI.Theme.colors.brand }19;
    }
  }

  .rmdp-today {
    border-radius: 3px;
    background-color: ${ () => UI.Theme.colors.brand }0A;
  }

  .rmdp-selected {
    border-radius: 3px;
    background-color: ${ () => UI.Theme.colors.brand } !important;
    color: ${ () => UI.Theme.colors.white };
  }

  .rmdp-day-picker, .rmdp-month-picker, .rmdp-year-picker { padding: 8px; }
  .rmdp-month-picker .rmdp-ym, .rmdp-year-picker .rmdp-ym { gap: 8px; }
`;

export default define`Date`('0.0.1')({
  view: ({ children }) => Children.count(children) > 0 ? Children.map(children, (child) => _.isString(child) ? Format.In(child) : child) : <></>,
  edit: ({ value, onChange, format }) => <Container>
    <Calendar
      value={ Format.In(value, format) }
      onChange={ (date) => onChange(Format.Out(date)) }
      format={ format ? Format[format] : Format.Local }
      offsetY={ -2 }
      arrow={ false }
      renderButton={ (direction, onClick) => (
        <UI.Icon
          onClick={ onClick }
          type={ `chevron-${ direction }` }
          interactive padding-0-2 greyShade2
        />
      ) }
      render={ (value, open, onChange) => (
        <UI.Text
          MODE="edit"
          icon="calendar-o"
          value={ value }
          onChange={ onChange }
          onClick={ open }
          controlled
        />
      ) }
    />
  </Container>
});

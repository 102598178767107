export default class Postie {
  static Mark = 'Postie';

  static Parent = (child) => new Postie(child.contentWindow ? child.contentWindow : child);
  static Child = () => new Postie(window.top);

  constructor(outgoing, incoming=window) {
    this.reactions = {};

    this.outgoing = outgoing;
    this.incoming = incoming;

    this.receive = this.receive.bind(this);
    this.attach();
  }

  attach() { this.incoming.addEventListener("message", this.receive); }
  detach() { this.incoming.removeEventListener("message", this.receive); }

  send(action, body={}) { this.outgoing.postMessage({ mark: Postie.Mark, action, body }); }

  receive({ data }) {
    if (data?.mark !== Postie.Mark) return;
    this.reactions[data.action]?.forEach((action) => action(data.body));
  }

  on(action, reaction) {
    if (!this.reactions[action]) this.reactions[action] = new Set();
    this.reactions[action].add(reaction);
  }

  off(action, reaction) { this.reactions[action].delete(reaction); }
}

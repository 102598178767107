import { jsx } from '@emotion/react';
import { connectLocation } from '../location/LocationConnectors';
import styled from "@emotion/styled";

const MobileFilterWrapper = styled.div`
  display: flex;
  margin: 16px 0;
  text-transform: uppercase;
  font-size: 18px;
  color: #585858;
  z-index: 10;

  @media(min-width: 1200px) {
    display: none;
  }

  a {
    cursor: pointer;
  }
`;

const FilterOpener = ({ t, className, onClick = null }) => (
  <MobileFilterWrapper className={className}>
    <a className="oc-icon" onClick={onClick}>
      <i className="icon filters"></i> {t('generic_locator.filters')}
    </a>
  </MobileFilterWrapper>
)

export default connectLocation(FilterOpener);
